import { observer } from 'mobx-react-lite';

import { ICrudTypeModalProps } from 'components/Modals/CRUDModal/CRUDModal';
import { useEffect } from 'react';
import { obrasciStore } from 'modules/obrasci/obrasci.store';
import styles from './Obrazac1OpsteWizardTab.module.scss';
import { Col } from 'antd';
import { OpsteGodinaPreduzecePostrojenje } from 'pages/ObrazacWizard/components/components/OpsteGodinaPreduzecePostrojenje/OpsteGodinaPreduzecePostrojenje';
import { obrazac1Store } from 'modules/obrasci/store/obrazac1/obrazac1.store';
import { useObrazacExistsCheck } from 'hooks/useObrazacExistsCheck';

export const Obrazac1OpsteWizardTab: React.FC<ICrudTypeModalProps> = observer(() => {
  useEffect(() => {
    if (
      obrasciStore.opste.godina &&
      obrasciStore.opste.preduzece_id !== '' &&
      obrasciStore.opste.postrojenje_id !== ''
    ) {
      obrasciStore.setDisabledTabButton(false);
    } else obrasciStore.setDisabledTabButton(true);
  }, [obrasciStore.opste]);

  const checkIfObrazacExists = useObrazacExistsCheck();

  return (
    <Col span={12} className={styles.wrapper}>
      <OpsteGodinaPreduzecePostrojenje
        withPostrojenje={true}
        store={obrazac1Store}
        onChangeGodina={async godina => {
          const obrazacExists = await checkIfObrazacExists();
          if (!obrazacExists) {
            obrazac1Store.handleChange('showIfSezonski', false);
            if (Number(godina) >= 2023) obrazac1Store.validateActivities(obrasciStore.opste.postrojenje_id);
          }
        }}
        // onChangePostrojenje={value => {
        //   obrazac1Store.handleChange('showIfSezonski', false);
        //   if (Number(obrasciStore.opste.godina) >= 2023) obrazac1Store.validateActivities(value);
        // }}
      />
    </Col>
  );
});
