/**
 *  For dynamic table height, we iterate trough array of elements whose height we need to subtract
 *  from 100vh, this way we don't care if elements change height on lower resolutions
 */
export const getTableScrollHeight = (selectorList: string[] = []) => {
  let takenHeight = 102; // pagination height
  const divsThatTakeSpace = [...selectorList, '.ant-table-thead'];

  divsThatTakeSpace.forEach(selector => {
    const element = document.querySelector(selector);
    if (!element) return;
    const styles = window.getComputedStyle(element);
    const margin = parseFloat(styles.marginTop) + parseFloat(styles.marginBottom);
    takenHeight = takenHeight + element.clientHeight + margin;
  });

  return takenHeight + 28;
};

/**
 * Sets height to ant-table-container, intended to be used with useOnScreen hook
 * to automatically set table height on screen resize
 */
export const setTableHeight = (scrollConfig?: string[], overrideHeight?: string) => {
  const scrollSize = getTableScrollHeight(scrollConfig);
  const table = document.querySelector('.ant-table-body');

  if (table) {
    if (overrideHeight) return (table.className = `ant-table-body tableHeightVH-${overrideHeight}`);
    if (scrollSize) return (table.className = `ant-table-body tableHeight-${scrollSize}`);
  }
};
