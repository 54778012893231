import { IRangePickerProps } from 'components/DayjsPicker/DayjsPicker';
import dayjs, { Dayjs } from 'dayjs';

export const disabledTodayAndFutureDates: IRangePickerProps['disabledDate'] = current => {
  // Can not select days before today and today
  return current && current > dayjs().endOf('day');
};

export const disabledTodayAndPreviousDates: IRangePickerProps['disabledDate'] = current => {
  return current && current < dayjs().endOf('day');
};

export const disabledDatesOutsideOfAllowedYear = (allowedYear: number): IRangePickerProps['disabledDate'] => {
  return current => {
    if (current && (current > dayjs().endOf('day') || current.year() !== allowedYear)) {
      return true;
    }
    return false;
  };
};

export const disabledDatesOutsideOfAllowedYears = (date: dayjs.Dayjs, allowedYears: number[]) => {
  return date && !allowedYears.includes(date.year());
};

export const disabledTomorrowAndPreviousDates: IRangePickerProps['disabledDate'] = current => {
  return current && current < dayjs().endOf('day').add(1, 'day');
};
export const disabledBefore30DayAfterNow: IRangePickerProps['disabledDate'] = current => {
  const isFirstDayOfYear =
    current && current.date() === 1 && current.month() === 0 && current.year() === dayjs().year();
  if (isFirstDayOfYear) return false;

  return (current && current < dayjs().add(-1, 'month').endOf('day')) || (current && current >= dayjs().endOf('day'));
};

export const disableDatesBefore = (specifiedDate: dayjs.ConfigType): IRangePickerProps['disabledDate'] => {
  return current => {
    const specified = dayjs(specifiedDate);
    return current && current < specified.startOf('day');
  };
};
export const disabledYearsBeforeSelectedYear: (previousYear: any) => IRangePickerProps['disabledDate'] =
  previousYear => current => {
    return current && current.year() <= previousYear;
  };

// export const disableYearsBeforeSelectedWithForm: (
//   form: FormInstance,
//   name: string
// ) => IRangePickerProps['disabledDate'] = (form, name) => current => {
//   console.log(current, 'current');
//   console.log(form.getFieldValue(name).$y, 'previousYear');
//   const previous = form.getFieldValue(name).$y;
//   return current && current.year() <= previous;
// };

export const disabledSelectedDateAndEnableNextDay: (selectedDate: Dayjs | null) => IRangePickerProps['disabledDate'] =
  selectedDate => current => {
    return selectedDate ? current && (current.isSame(selectedDate, 'day') || current.isBefore(selectedDate)) : false;
  };

export const enableDateOnlyForSelectedYearAndDisableSelectedDateInList = (
  currentDate: Dayjs,
  targetYear: number,
  existingDates: Dayjs[]
): boolean => {
  const selectedDate = dayjs(currentDate);
  const dateIsInList = existingDates.some(date => date.isSame(selectedDate, 'day'));

  return selectedDate.year() !== targetYear || dateIsInList;
};

export const defaultPickerDateForSelectedYear = (defaultYear: number) => {
  return dayjs().year(defaultYear).startOf('year');
};
