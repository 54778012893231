import React, { useState } from 'react';
import { FormInput } from 'components/FormInput/FormInput';
import styles from './NewPibForm.module.scss';
import { useTranslation } from 'react-i18next';
import { observer } from 'mobx-react-lite';

import {
  IDENTIFICATION_NUMBER_FIELD_RULE,
  PIB_FIELD_RULE,
  invalidCharactersForInputNumber,
} from 'modules/nriz/nriz.constants';
import { useForm } from 'antd/es/form/Form';
import { Form, message } from 'antd';

import { pibInitialState } from 'modules/company/company.constants';
import { companyStore } from 'modules/company/company.store';
import { IPib, IPibMaticniModalData, PibMaticniCheckEnum } from 'modules/company/company.types';
import { modalStore } from 'store';
import { Popconfirm } from 'components/Popconfirm/Popconfirm';
import { Button } from 'components/Button/Button';

export const NewPibForm: React.FC = observer(() => {
  const [form] = useForm();
  const { t } = useTranslation();

  const [showPopconfirm, setShowPopconfirm] = useState(false);

  const validatePib = (rule: any, value: string) => {
    if (!value && !form.getFieldValue('maticni_broj')) {
      return Promise.reject(t('AT_LEAST_ONE'));
    }
    return Promise.resolve();
  };

  const validateMaticni = (rule: any, value: string) => {
    if (!value && !form.getFieldValue('pib')) {
      return Promise.reject(t('AT_LEAST_ONE'));
    }
    return Promise.resolve();
  };

  const openPibMaticniInfoModal = (modalData: IPibMaticniModalData) => {
    companyStore.setPibMbInfoModalData(modalData);
    modalStore.changeModalName('pib-mb-info-modal');
  };

  const submitPibMaticniForm = async (values: IPib) => {
    const checkResponse = await companyStore.checkPibMaticni(values);

    if (!checkResponse) return;

    switch (checkResponse) {
      case PibMaticniCheckEnum.CompanyExists:
        return message.error('Već postoji preduzeće sa unetim podacima', 2);
      case PibMaticniCheckEnum.PibExists:
        return openPibMaticniInfoModal({ values, type: PibMaticniCheckEnum.PibExists });
      case PibMaticniCheckEnum.MbExists:
        return openPibMaticniInfoModal({ values, type: PibMaticniCheckEnum.MbExists });
      case PibMaticniCheckEnum.CheckSuccess:
        return companyStore.postPib(values);
    }
  };

  return (
    <Form
      form={form}
      initialValues={pibInitialState}
      name={'pib-form'}
      onFinish={submitPibMaticniForm}
      layout="horizontal"
      labelCol={{ span: 12 }}
      wrapperCol={{ span: 12 }}
      labelAlign="left"
      className={styles.form_pib_wrapper}
    >
      <FormInput
        name="pib"
        rules={[PIB_FIELD_RULE, { validator: validatePib }]}
        type="number"
        placeholder={t('COMPANY.PLACEHOLDER.ENTER_PIB')}
        label={`${t('COMPANY.PIB')} preduzeća`}
        inputName={'pib'}
        onInputChange={() => form.validateFields(['maticni_broj'])}
        colon={true}
        className={styles.inputNumber}
        invalidCharacters={invalidCharactersForInputNumber}
      />
      <FormInput
        invalidCharacters={invalidCharactersForInputNumber}
        name="maticni_broj"
        rules={[IDENTIFICATION_NUMBER_FIELD_RULE, { validator: validateMaticni }]}
        type="number"
        placeholder={t('COMPANY.PLACEHOLDER.ENTER_IDENTIFICATION_NUMBER')}
        label={t('COMPANY.ENTERPRISE_ID_NUMBER')}
        inputName={'maticni_broj'}
        onInputChange={() => form.validateFields(['pib'])}
        colon={true}
        className={styles.inputNumber}
      />

      <Popconfirm
        title={
          <>
            <p>Niste uneli jedan od podataka.</p>
            <p>Da li želite da nastavite sa registracijom?</p>
          </>
        }
        name="pib-mb-popconfirm"
        open={showPopconfirm}
        onConfirm={() => {
          setShowPopconfirm(false);
          return form.submit();
        }}
        placement="bottom"
        onCancel={() => setShowPopconfirm(false)}
        onOpenChange={newOpen => {
          if (newOpen) {
            const { pib, maticni_broj } = form.getFieldsValue(true);

            if (!pib && !maticni_broj) return;

            if (pib && maticni_broj) return form.submit();

            if (!pib || !maticni_broj) return setShowPopconfirm(true);
          }
        }}
      >
        <Button name="pib-mb-btn" htmlType="button">
          {t('SUBMIT')}
        </Button>
      </Popconfirm>
    </Form>
  );
});
