import { Form, FormInstance } from 'antd';
import { ColumnsType } from 'antd/es/table';
import { FormCheckbox } from 'components/FormCheckbox/FormCheckbox';
import { FormInputNumber } from 'components/FormInputNumber/FormInputNumber';
import { FormSearchInfiniteScroll } from 'components/FormSearchInfiniteScroll/FormSearchInfiniteScroll';
import { InsideContentWrapper } from 'components/InsideContentWrapper/InsideContentWrapper';
import { CRUDModal, ICrudTypeModalProps } from 'components/Modals/CRUDModal/CRUDModal';
import TableData from 'components/TableData/TableData';
import { WizardObrazacListHeader } from 'components/WizardObrazacListHeader/WizardObrazacListHeader';
import { observer } from 'mobx-react-lite';
import { MORE_THEN_0_IS_REQUIRED, REQUIRED_FIELD_RULE } from 'modules/nriz/nriz.constants';
import {
  DEO_6_PREUZET_OTPAD_INITIAL_STATE,
  PREUZET_CHECKBOX,
  PREUZET_OD_2020_OPTIONS,
  PREUZET_OD_OPTIONS,
} from 'modules/obrasci/store/deo6/deo6.constants';
import { deo6ObrazacStore } from 'modules/obrasci/store/deo6/deo6.store';
import {
  IGetPreuzetOtpad,
  IGetRazrediEEPreuzet,
  IPostPreuzetOtpad,
  PreuzetOdEnum,
} from 'modules/obrasci/store/deo6/deo6.types';
import { ISifarnikInitial } from 'modules/sifarnici/sifarnici.types';
import { IGetApr } from 'modules/sifarnici/store/apr/apr.types';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { IGetFacility } from 'modules/facility/facility.types';
import { resetFormFields } from 'utils/resetFormFieldValues';
import { IGetWasteCatalogue } from 'modules/sifarnici/store/wasteCatalogue/wasteCatalogue.types';
import { FormInput } from 'components/FormInput/FormInput';
import { formCleanPreuzet } from 'modules/obrasci/store/deo6/deo6.service';
import { obrasciStore } from 'modules/obrasci/obrasci.store';
import { Select } from 'components/Select/Select';
import { EditButtonProps, RowEditPreviewButton } from 'components/RowEditPreviewButton/RowEditPreviewButton';
import Decimal from 'decimal.js';

const EditDeo6PreuzetOtpadRazredButton: React.FC<EditButtonProps<IGetRazrediEEPreuzet>> = observer(({ record }) => {
  return (
    <RowEditPreviewButton
      name="deo6-razred-preuzet-otpad"
      modalName="deo6-razred-preuzet-otpad-modal"
      onClick={() => deo6ObrazacStore.handleChange('razred_preuzet_form', record)}
      preview={obrasciStore.zakljucanObrazac}
    />
  );
});

const TablePreuzetOtpadRazred: React.FC = observer(() => {
  const { t } = useTranslation();

  const baseColumns: ColumnsType<IGetRazrediEEPreuzet> = [
    {
      title: `${t('OBRASCI.GIO_3.CLASS_EE_WASTE')}`,
      width: 100,
      key: 'category_name',
      render: (_: any, record: IGetRazrediEEPreuzet) => (
        <>{`${record.razred_ee_otpada.the_order} ${record.razred_ee_otpada.category_name_sr}`}</>
      ),
    },
    {
      title: `${t('OBRASCI.DEO_6.KOLICINA_IZ_DOMACINSTAVA')}`,
      width: 100,
      dataIndex: 'kolicina_domacinstva',
      key: 'kolicina_domacinstva',
    },
    {
      title: `${t('OBRASCI.DEO_6.KOLICINA_IZ_DRUGIH_IZVORA')}`,
      width: 100,
      dataIndex: 'kolicina_drugi_izvor',
      key: 'kolicina_drugi_izvor',
    },
    {
      title: `${t('OBRASCI.DEO_6.UKUPNA_KOLICINA')}`,
      width: 100,
      dataIndex: 'ukupna_kolicina',
      key: 'ukupna_kolicina',
    },
    {
      title: '',
      key: 'operation',
      fixed: 'right',
      width: 40,
      render: (_, record: IGetRazrediEEPreuzet) => <EditDeo6PreuzetOtpadRazredButton record={record} />,
    },
  ];

  return (
    <TableData
      name="preuzet-otpad-razredi-deo6"
      columns={baseColumns}
      dataSource={[...deo6ObrazacStore.getPreuzetForm.razred]}
    />
  );
});

const NewPreuzetOtpadDeo6Form: React.FC<ICrudTypeModalProps> = observer(props => {
  const { t } = useTranslation();
  const [isRequire, setIsRequire] = useState(true);
  // const [preuzetOd, setPreuzetOd] = useState<PreuzetOdEnum | ''>(deo6ObrazacStore.getPreuzetForm.preuzet_od);

  const fieldsCheckBox = PREUZET_CHECKBOX.map(item => item.name);

  const handleCheckBoxChange = () => {
    setIsRequire(!Object.values(props.form.getFieldsValue(fieldsCheckBox)).some((item: any) => item === true));
    props.form.validateFields(fieldsCheckBox);
  };

  const preduzeceOptionMaker = (options: IGetApr[]) => {
    return options.map(company => ({
      label: company.full_company_name,
      value: (company as IGetApr).id,
    }));
  };
  const postrojenjeOptionMaker = (options: IGetFacility[]) => {
    return options.map(facility => ({
      label: facility.naziv_postrojenja,
      value: (facility as IGetFacility).id,
      disabled: !facility.is_active,
    }));
  };

  const defaultZemljaIzvozaOption: ISifarnikInitial | undefined = deo6ObrazacStore.getPreuzetForm.zemlja_izvoza_id
    ? {
        label: (deo6ObrazacStore.getPreuzetForm as IGetPreuzetOtpad).zemlja_izvoza.name,
        value: deo6ObrazacStore.getPreuzetForm.zemlja_izvoza_id,
      }
    : undefined;

  const defaultPreduzeceOption: ISifarnikInitial | undefined = deo6ObrazacStore.getPreuzetForm.preduzece_id
    ? {
        label: (deo6ObrazacStore.getPreuzetForm as IGetPreuzetOtpad).preduzece.full_company_name,
        value: deo6ObrazacStore.getPreuzetForm.preduzece_id,
      }
    : undefined;

  const defaultPostrojenjeOption: ISifarnikInitial | undefined = deo6ObrazacStore.getPreuzetForm.postrojenje_id
    ? {
        label: (deo6ObrazacStore.getPreuzetForm as IGetPreuzetOtpad).postrojenje.naziv_postrojenja,
        value: deo6ObrazacStore.getPreuzetForm.postrojenje_id,
      }
    : undefined;

  const defaultTypeOfWaste: ISifarnikInitial | undefined = deo6ObrazacStore.getPreuzetForm.indeksni_broj_otpada_id
    ? {
        value: deo6ObrazacStore.getPreuzetForm.indeksni_broj_otpada_id,
        label: `${(deo6ObrazacStore.getPreuzetForm as IGetPreuzetOtpad).indeksni_broj_otpada.index_number} ${(
          deo6ObrazacStore.getPreuzetForm as IGetPreuzetOtpad
        ).indeksni_broj_otpada.waste_sr.toUpperCase()}`,
      }
    : undefined;

  const wasteCatalogueOptions = (options: IGetWasteCatalogue[]) => {
    return options.map(wasteOption => ({
      label: `${wasteOption.index_number} ${wasteOption.waste_sr.toUpperCase()}`,
      value: wasteOption.id,
    }));
  };

  useEffect(() => {
    if (deo6ObrazacStore.weee_categories) {
      props.form.validateFields(['kolicina']);
    }
  }, [deo6ObrazacStore.razred_preuzet_form]);

  useEffect(() => {
    resetFormFields(props.form, formCleanPreuzet(props.form.getFieldValue('preuzet_od')));
  }, [
    Form.useWatch('preuzet_od', props.form),
    Form.useWatch('preduzece_id', props.form),
    Form.useWatch('naziv_preduzeca', props.form),
  ]);

  const readOnly = obrasciStore.zakljucanObrazac;

  return (
    <>
      <FormSearchInfiniteScroll
        showSearch={true}
        formName={'indeksni_broj_otpada_id'}
        fetchOptinsList={deo6ObrazacStore.fetchWasteCatalougeList}
        labelOptionAccessor="index_number"
        valueOptionAccessor="id"
        rules={[REQUIRED_FIELD_RULE(true)]}
        inputName={'indeksni_broj_otpada_id'}
        label={t('OBRASCI.KOM_1.MANAGEMENT.WASTE_INDEX_NUMBER_FROM_THE_WASTE_CATALOG')}
        colon={true}
        defaultOption={defaultTypeOfWaste}
        placeholder={''}
        disabled={deo6ObrazacStore.index_id !== ''}
        onChange={e => {
          deo6ObrazacStore.handleChange('index_id', e);
          deo6ObrazacStore.checkWeeeCategories(e, 'handlePreuzetForm');
        }}
        optionsMaker={wasteCatalogueOptions}
        readOnly={readOnly}
      />
      <FormInputNumber
        name="kolicina"
        label={t('OBRASCI.DEO_6.KOLICINA_PREUZETOG')}
        precision={4}
        rules={[
          ...MORE_THEN_0_IS_REQUIRED,
          {
            validator(_, value) {
              if (deo6ObrazacStore.weee_categories) {
                let sum = new Decimal(0);
                (deo6ObrazacStore.preuzet_form as IGetPreuzetOtpad).razred.map(
                  (item: IGetRazrediEEPreuzet) => (sum = sum.plus(item.ukupna_kolicina ?? 0))
                );
                if (sum.toNumber() !== value) {
                  return Promise.reject(new Error(t('OBRASCI.VALIDATION.JEDNAKA_RAZRED_UKUPNA')));
                }
              }
              return Promise.resolve();
            },
          },
        ]}
        placeholder={''}
        inputName={'kolicina'}
        colon={true}
        readOnly={readOnly}
      />

      <Form.Item
        rules={[REQUIRED_FIELD_RULE(true)]}
        labelCol={{ span: 12 }}
        name={'preuzet_od'}
        label={t('OBRASCI.DEO_6.OTPAD_PREUZET_OD')}
      >
        <Select
          name="preuzet_od"
          style={{ width: 100 }}
          // onChange={value => setPreuzetOd(value)}
          options={Number(deo6ObrazacStore.godina) >= 2020 ? PREUZET_OD_2020_OPTIONS : PREUZET_OD_OPTIONS}
          readOnly={readOnly}
        />
      </Form.Item>
      {props.form.getFieldValue('preuzet_od') === PreuzetOdEnum.PRAVNO_LICE && (
        <>
          <h4>{t('OBRASCI.DEO_6.PRAVNO_LICE_ILI_PREDUZETNIK')}</h4>
          {!props.form.getFieldValue('naziv_preduzeca') && (
            <FormSearchInfiniteScroll
              showSearch={true}
              label={t('COMPANY.FULL_NAME')}
              placeholder={t('COMPANY.PLACEHOLDER.ENTER_NAME')}
              formName={'preduzece_id'}
              inputName={'preduzece_id'}
              fetchOptinsList={deo6ObrazacStore.fetchAprPredatOtpad}
              disabledAccessor=""
              labelOptionAccessor="full_company_name"
              valueOptionAccessor="id"
              colon={true}
              defaultOption={defaultPreduzeceOption}
              rules={[REQUIRED_FIELD_RULE(true)]}
              optionsMaker={preduzeceOptionMaker}
              readOnly={readOnly}
            />
          )}

          {Number(deo6ObrazacStore.godina) <= 2019 && !props.form.getFieldValue('preduzece_id') && (
            <FormInput
              name="naziv_preduzeca"
              type="text"
              label={'Slobodan unos preduzeća'}
              rules={[REQUIRED_FIELD_RULE(true)]}
              placeholder={''}
              inputName={'naziv_preduzeca'}
              colon={true}
              readOnly={readOnly}
            />
          )}
          <h4>{t('OBRASCI.DEO_6.OTPAD_JE_PREUZET_OD_KOJE_JE')}</h4>

          {PREUZET_CHECKBOX.map((item: { label: string; name: string }, index) => {
            return (
              <FormCheckbox
                key={index}
                rules={[
                  REQUIRED_FIELD_RULE(isRequire),
                  {
                    validator(_: any, value: any) {
                      value;
                      const formFields = props.form.getFieldsValue(fieldsCheckBox);
                      if (!Object.values(formFields).some((item: any) => item === true)) {
                        return Promise.reject(t('VALIDATION.SELECT_A_FIELD'));
                      }
                      return Promise.resolve();
                    },
                  },
                ]}
                name={item.name}
                label={item.label}
                onCheckBoxChange={handleCheckBoxChange}
              />
            );
          })}
        </>
      )}

      {props.form.getFieldValue('preuzet_od') === PreuzetOdEnum.SOPSTVENI_UVOZ && (
        <>
          <FormSearchInfiniteScroll
            formName={'zemlja_izvoza_id'}
            fetchOptinsList={deo6ObrazacStore.fetchCountryCode}
            labelOptionAccessor="name"
            valueOptionAccessor="id"
            rules={[REQUIRED_FIELD_RULE(true)]}
            inputName={'zemlja_izvoza_id'}
            label={t('OBRASCI.GIO5.EXPORT_COUNTRY')}
            colon={true}
            placeholder={''}
            showSearch
            defaultOption={defaultZemljaIzvozaOption}
            readOnly={readOnly}
          />
        </>
      )}

      {props.form.getFieldValue('preuzet_od') === PreuzetOdEnum.SOPSTVENO_POSTROJENJE && (
        <>
          <FormSearchInfiniteScroll
            label={t('FACILITY.TITLE')}
            placeholder={t('FACILITY.PLACEHOLDER.ENTER_NAME')}
            formName={'postrojenje_id'}
            fetchOptinsList={deo6ObrazacStore.fetchPostrojenje}
            showSearch
            labelOptionAccessor="naziv_postrojenja"
            valueOptionAccessor="id"
            defaultOption={defaultPostrojenjeOption}
            rules={[REQUIRED_FIELD_RULE(true)]}
            optionsMaker={postrojenjeOptionMaker}
            readOnly={readOnly}
          />
        </>
      )}

      {deo6ObrazacStore.weee_categories && (
        <InsideContentWrapper
          header={
            <WizardObrazacListHeader
              title={t('OBRASCI.GIO_3.TYPE_OF_ELECTRICAL_AND_ELECTRONIC_WASTE')}
              name={'deo6-razred-preuzet-otpad-form'}
              modalName="deo6-razred-preuzet-otpad-modal"
            />
          }
        >
          <TablePreuzetOtpadRazred />
        </InsideContentWrapper>
      )}
    </>
  );
});

export const Deo6PreuzetOtpadModal: React.FC = observer(() => {
  const { t } = useTranslation();

  return (
    <CRUDModal
      width="50%"
      name="deo6-preuzet-otpad-modal"
      title={t('WIZARD_TABS.PREUZET_OTPAD')}
      store={deo6ObrazacStore}
      data={deo6ObrazacStore.getPreuzetForm}
      children={({ form }: { form: FormInstance<IPostPreuzetOtpad | IGetPreuzetOtpad> }) => (
        <NewPreuzetOtpadDeo6Form form={form} />
      )}
      submitForm={deo6ObrazacStore.submitPreuzetForm}
      initialState={DEO_6_PREUZET_OTPAD_INITIAL_STATE}
      dataToChange={'preuzet_form'}
      labelCol={{ span: 12 }}
      wrapperCol={{ span: 12 }}
      onCancel={deo6ObrazacStore.resetWeeeCategories}
    />
  );
});
