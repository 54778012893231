import { Row, Col } from 'antd';
import { AddNewButton } from 'components/Buttons/AddNewButton/AddNewButton';
import { observer } from 'mobx-react-lite';
import React from 'react';
import styles from './ExcelAndAddButton.module.scss';
import { ExcelButton } from 'components/Buttons/ExcelButton/ExcelButton';
import { authStore } from 'store';

export interface IExcelAndAddButtonProps {
  component?: React.ReactNode;
  addButtonLabel?: string;
  addButtonOnChange?: (() => any) | undefined;
  excelFunc: (() => any) | undefined;
  excelName?: string;
}

export const ExcelAndAddButton: React.FC<IExcelAndAddButtonProps> = observer(props => {
  return (
    <Row align={'top'} justify={'space-between'} className={styles.wrapper}>
      <Col span={19}>{props.component && props.component}</Col>
      <Col style={{ display: 'flex', justifyContent: 'flex-end' }} span={5}>
        {props.addButtonOnChange && (
          <AddNewButton
            name={'user'}
            label={props.addButtonLabel}
            onButtonClick={() => {
              props.addButtonOnChange && props.addButtonOnChange();
            }}
          />
        )}
        {props.excelFunc && authStore.isAdmin && (
          <div className={styles.excel}>
            <ExcelButton onClick={props.excelFunc} name={props.excelName ? props.excelName : ''} />
          </div>
        )}
      </Col>
    </Row>
  );
});
