import { ColumnsType } from 'antd/es/table';
import { EditButton } from 'components/Buttons/EditButton/EditButton';
import TableData from 'components/TableData/TableData';
import dayjs from 'dayjs';
import { observer } from 'mobx-react-lite';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { modalStore } from 'store';
import { IGetUnsanitaryLandfill } from 'modules/unsanitarylandfill/unsanitarylandfill.types';
import { unsanitaryLandfillStore } from 'modules/unsanitarylandfill/unsanitarylandfill.store';
import { IS_ACTIVE_TABLE } from 'modules/sifarnici/sifarniciTableObj.constants';

export const UnsanitaryLandfillListTable: React.FC = observer(() => {
  const { t } = useTranslation();

  const columns: ColumnsType<IGetUnsanitaryLandfill> = [
    { ...IS_ACTIVE_TABLE, fixed: 'left' } as any,
    {
      title: `${t('UNSANITARY_LANDFILL.MARK_OF_UNSANITARY_LANDFILL')}`,
      dataIndex: 'oznaka_nesanitarne_deponije',
      width: '100px',
      key: 'oznaka_nesanitarne_deponije',
      fixed: 'left',
    },
    {
      title: `${t('UNSANITARY_LANDFILL.NAME')}`,
      dataIndex: 'naziv',
      key: 'naziv',
      width: '150px',
    },
    {
      title: `${t('COMPANY.LOCATION')}`,
      dataIndex: ['mesto', 'display_name'],
      width: '200px',
      key: 'mesto_id',
    },
    {
      title: `${t('UNSANITARY_LANDFILL.OCCUPIED_PLOTS')}`,
      dataIndex: 'zauzete_katastarske_parcele',
      key: 'zauzete_katastarske_parcele',
      width: '200px',
    },
    {
      title: `${t('UNSANITARY_LANDFILL.LANDFILL_PERIOD')}`,
      key: 'godina_pocetka_deponovanja',
      width: '150px',
      render: (_, record) => (
        <p>
          {record.godina_pocetka_deponovanja}-{record.godina_zavrsetka_deponovanja}
        </p>
      ),
    },
    {
      title: '',
      key: 'operation',
      fixed: 'right',
      width: '50px',
      render: (_, record: IGetUnsanitaryLandfill) => {
        return (
          <EditButton
            onButtonClick={() => {
              unsanitaryLandfillStore.setUnsanitaryLandfill({
                ...record,
                period_deponovanja: [
                  dayjs(`${record.godina_pocetka_deponovanja}`),
                  dayjs(`${record.godina_zavrsetka_deponovanja}`),
                ],
              });
              modalStore.changeModalName('unsanitary-landfill-modal');
            }}
            name={'go-to-facility'}
          />
        );
      },
    },
  ];

  return (
    <TableData
      name="unsanitaryland"
      columns={columns}
      dataSource={[...unsanitaryLandfillStore.getUnsanitaryLandfillList]}
      refetchData={unsanitaryLandfillStore.fetchUnsanitaryLandfillList}
      storePagination={unsanitaryLandfillStore.unsanitaryLandfillPagination}
    />
  );
});
