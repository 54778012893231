import { InsideContentWrapper } from 'components/InsideContentWrapper/InsideContentWrapper';
import { WizardObrazacListHeader } from 'components/WizardObrazacListHeader/WizardObrazacListHeader';
import { observer } from 'mobx-react-lite';
import React from 'react';
import { TableKomponenteObrazac5 } from './TableKomponenteObrazac5/TableKomponenteObrazac5';
import { obrazac5Store } from 'modules/obrasci/store/obrazac5/obrazac5.store';

import styles from './Obrazac5KomponenteWizard.module.scss';
import { useTranslation } from 'react-i18next';
import { obrasciStore } from 'modules/obrasci/obrasci.store';

export const Obrazac5KomponenteWizardTab: React.FC = observer(() => {
  const { t } = useTranslation();

  return (
    <>
      <InsideContentWrapper
        header={
          <WizardObrazacListHeader
            name={'obrazac5-komponente-form'}
            modalName="obrazac5-komponente-modal"
            classNameTitle={styles.errorMessage}
            title={!obrazac5Store.getDangerousWaste ? t('OBRASCI.OBRAZAC5.UPOZORENJE_OPASAN_OTPAD_LISTA') : ''}
            disabled={!obrazac5Store.getDangerousWaste || obrasciStore.zakljucanObrazac}
          />
        }
      >
        <TableKomponenteObrazac5 />
      </InsideContentWrapper>
    </>
  );
});
