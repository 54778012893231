import { ColumnsType, ColumnType } from 'antd/es/table';
import { IPagination } from 'typescript';
import { IPageSizePagination, IPaginatedApiResponse } from 'typescript/NrizTypes';
import { IOTVRegistar } from './otv/otv.types';
import { ReactNode } from 'react';
import { FormInstance } from 'antd';
import { ISifarniciTypeOfForm } from 'modules/sifarnici/sifarnici.types';

export enum RegistriEnum {
  Otv = 'otv',
  Dozvole = 'dozvole',
  Lcp = 'lcp',
}

export type RegistriOptionType = {
  value: RegistriEnum;
  label: string;
};

//todo:tipiziraj
export type RegistriColumns = {
  [key in RegistriEnum]: ColumnType<any>[];
};

export type RegistarKeyProps = {
  registar: RegistriEnum;
};

export type Registar = IOTVRegistar;

export interface IBaseRegistriStore<Registar> {
  loadData: (id: string) => Promise<Registar | undefined>;
  fetchDataList: (pagination: IPageSizePagination) => IPaginatedApiResponse<Registar>;
  postData: (payload: Registar) => Promise<any>;
  putData: (payload: Registar) => Promise<any>;
  resetStates: () => void;
  initialState?: Registar;
  pagination: IPagination;
  dataSource: Registar[];
  // submitData: (payload: Registar) => void;
  // deleteData: (id: string) => void;
  // handleValidation?: () => any;
}
export interface RegistriConfig {
  label: string;
  key: RegistriEnum;
  routes: RegistriRoutes;
  store: IBaseRegistriStore<Registar>;
  tableData: RegistriTableData<Registar>;
}

export interface RegistriRoutes {
  urlRoute: string;
  component: ReactNode;
  apiRoute: string;
}

export interface RegistriTableData<Registar> {
  columns: ColumnsType<Registar>;
  filters: {
    components: (form: FormInstance<Registar>) => ISifarniciTypeOfForm[];
    initialState: Partial<Registar>;
  };
}

export enum RegistriLists {
  OTV = 1,
}
