import { Row } from 'antd';
import { AddNewButton } from 'components/Buttons/AddNewButton/AddNewButton';
import { observer } from 'mobx-react-lite';
import { obrasciStore } from 'modules/obrasci/obrasci.store';
import React from 'react';
import { modalStore } from 'store';

interface WizardListHeaderProps {
  name: string;
  modalName: string;
  disabled?: boolean;
  onAddMessage?: string;
  title?: string;
  classNameTitle?: string;
}

export const WizardObrazacListHeader: React.FC<WizardListHeaderProps> = observer(props => {
  return (
    <Row justify={'space-between'} id="obrazacTableHeader">
      <h3 className={props.classNameTitle}>{props.title}</h3>

      {!obrasciStore.zakljucanObrazac && (
        <AddNewButton
          label="Dodaj"
          disabled={props.disabled}
          name={props.name}
          onButtonClick={() => {
            modalStore.changeModalName(props.modalName);
          }}
        />
      )}
    </Row>
  );
});
