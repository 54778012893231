import { IAuditLogs, ITipPromenaEnum } from './istorijaPromena.types';
import {
  DateSifarniciFormProps,
  ISifarniciTypeOfForm,
  InputSifarniciFormProps,
  SIFARNICI_ROUTES,
  SearchScrollSifarniciFormProps,
  SelectSifarniciFormProps,
} from 'modules/sifarnici/sifarnici.types';
import { ColumnsType } from 'antd/es/table';
import { Row, Col, Tag } from 'antd';
import dayjs from 'dayjs';
import { HistoryDiffViewer } from 'pages/IstorijaPromena/components/IstorijaPromenaTable/components/HistoryDiffViewer/HistoryDiffViewer';
import { INewAuditLogs } from './istorijaPromena.store';
import { t } from 'i18next';

export const ISTORIJA_PROMENA_INITIAL_STATE: IAuditLogs = {
  after_payload: {},
  before_payload: {},
  action: null,
  timestamp: '',
  kreirao: null,
  session_id: '',
  table_name: '',
  model_config: {
    from_attributes: false,
  },
};

export const ISTORIJA_PROMENA_HEADER_DATA: ColumnsType<INewAuditLogs> = [
  {
    title: `Datum kreiranja`,
    dataIndex: 'timestamp',
    width: '110px',
    key: 'timestamp',
    render: (_, record: INewAuditLogs) => {
      return <div>{record.timestamp ? dayjs(record?.timestamp).format('DD.MM.YYYY. HH:mm:ss') : ''}</div>;
    },
  },
  {
    title: `Korisnik`,
    dataIndex: 'kreirao',
    width: '200px',
    key: 'kreirao',
    render: (_, record: INewAuditLogs) => {
      return (
        <div>
          {record?.kreirao?.ime} {record?.kreirao?.prezime}
        </div>
      );
    },
  },
];

export const ISTORIJA_PROMENA_TABLE_DATA: ColumnsType<IAuditLogs> = [
  {
    title: (
      <Row>
        <Col span={12}>Prethodno stanje</Col>
        <Col>Novo stanje</Col>
      </Row>
    ),
    dataIndex: 'promene',
    width: '1500px',
    key: 'promene',
    render: (_, record: IAuditLogs) => {
      return <HistoryDiffViewer oldValues={record.before_payload} newValues={record.after_payload} />;
    },
  },

  {
    title: `Mesto promene`,
    dataIndex: 'table_name',
    width: '200px',
    key: 'table_name',
  },
  {
    title: `Tip promene`,
    dataIndex: 'tip',
    key: 'tip',
    align: 'center',
    width: '200px',
    render: (_, record: IAuditLogs) => {
      if (record.action === ITipPromenaEnum.DELETE)
        return (
          <Tag style={{ padding: '10px', fontSize: '16px' }} color="red">
            Obrisan
          </Tag>
        );
      if (record.action === ITipPromenaEnum.UPDATE)
        return (
          <Tag style={{ padding: '10px', fontSize: '16px' }} color="gold">
            Izmena
          </Tag>
        );
      if (record.action === ITipPromenaEnum.INSERT)
        return (
          <Tag style={{ padding: '10px', fontSize: '16px' }} color="green">
            Kreiran
          </Tag>
        );
    },
  },
];

export const TIP_PROMENE_SELECT: { label: string; value: ITipPromenaEnum }[] = [
  {
    label: 'Kreiran',
    value: ITipPromenaEnum.INSERT,
  },
  {
    label: 'Izmena',
    value: ITipPromenaEnum.UPDATE,
  },
  {
    label: 'Obrisan',
    value: ITipPromenaEnum.DELETE,
  },
];

export const ISTORIJA_PROMENA_FILTERS_DATA: ISifarniciTypeOfForm[] = [
  {
    type_of_form: 'date',
    name: 'timestamp_od',
    label: 'Datum promene od',
    // format: 'DD-MM-YYYY',
    picker: 'date',
    showTime: true,
    format: 'DD-MM-YYYY HH:mm',
  } as DateSifarniciFormProps,
  {
    type_of_form: 'date',
    name: 'timestamp_do',
    label: 'Datum promene do',
    format: 'DD-MM-YYYY HH:mm',
    picker: 'date',
    showTime: true,
  } as DateSifarniciFormProps,
  {
    type_of_form: 'searchScroll',
    label: t('Kreirao'),
    formName: 'kreirao_id',
    fetchOptinsList: SIFARNICI_ROUTES.KORISNIK,
    filtersForFetch: {},
    labelOptionAccessor: ['ime', 'prezime'],
    valueOptionAccessor: 'id',
  } as SearchScrollSifarniciFormProps,
  {
    type_of_form: 'select',
    label: 'Tip promene',
    name: 'action',
    inputName: 'action',
    options: TIP_PROMENE_SELECT,
  } as SelectSifarniciFormProps,
  {
    type_of_form: 'input',
    label: 'Mesto promene',
    inputName: 'table_name',
    name: 'table_name',
  } as InputSifarniciFormProps,
];
