import to from 'await-to-js';
import { makeAutoObservable } from 'mobx';
import { IPagination } from 'typescript';
import { formStore } from '../../store/FormStore';
import { nrizService } from 'modules/nriz/nriz.service';
import { companyStore } from '../company/company.store';
import { modalStore } from '../../store/ModalStore';
import { sifarniciStore } from 'modules/sifarnici/sifarnici.store';
import { SIFARNICI_ROUTES } from 'modules/sifarnici/sifarnici.types';
import { handleSuccessNotify } from 'utils/succesHandling';

import { IGetCompany } from 'modules/company/company.types';
import { IGetUnsanitaryLandfill, IPostUnsanitaryLandfill } from 'modules/unsanitarylandfill/unsanitarylandfill.types';
import { unsanitaryLandfillInitialState } from './unsanitarylandfill.constants';
import { unsanitaryLandfillService } from './unsanitarylandfill.service';
import { IGetMesto } from 'modules/sifarnici/store/mesto/mesto.types';
import { ITablePagination } from 'components/TableData/TableData';
import { paginationInitialState } from 'modules/nriz/nriz.constants';
import { IEmptyResponse, IPaginatedResponse, IPostResponse } from 'typescript/NrizTypes';
import { filtersStore } from 'store/filteri.store';

class UnsanitaryLandfillStore {
  constructor() {
    makeAutoObservable(this);
  }

  unsanitaryLandfill: IPostUnsanitaryLandfill | IGetUnsanitaryLandfill = unsanitaryLandfillInitialState;
  unsanitaryLandfillList: IGetUnsanitaryLandfill[] = [];
  unsanitaryLandfillPagination: IPagination = paginationInitialState;

  get getUnsanitaryLandfill() {
    return this.unsanitaryLandfill;
  }

  get getUnsanitaryLandfillList() {
    return this.unsanitaryLandfillList;
  }

  handleChange(key: keyof UnsanitaryLandfillStore, value: any) {
    (this as any)[key] = value;
  }

  fetchUnsanitaryLandfillList = async (pagination?: ITablePagination) => {
    const [err, res] = await to<IPaginatedResponse<IGetUnsanitaryLandfill>>(
      unsanitaryLandfillService.fetchUnsanitaryLandfillList(
        nrizService.pickFields({
          preduzece_id: (companyStore.company as IGetCompany).id,
          page: pagination?.page || this.unsanitaryLandfillPagination.page,
          size: pagination?.size || this.unsanitaryLandfillPagination.size,
          ...filtersStore.filters.nesanitarne_deponije,
        })
      )
    );
    if (err || !res) return;
    const { items, page, size, total, pages } = res;
    this.setUnsanitaryLandfillList(items);
    this.handleChange('unsanitaryLandfillPagination', { total, page, size, pages });
    return res;
  };

  setUnsanitaryLandfill = (values: IPostUnsanitaryLandfill | IGetUnsanitaryLandfill) => {
    this.unsanitaryLandfill = values;
  };

  setUnsanitaryLandfillList = (values: IGetUnsanitaryLandfill[]) => {
    this.unsanitaryLandfillList = values;
  };

  changeUnsanitaryLandfill = (type: string, value: string) => {
    this.unsanitaryLandfill = { ...this.unsanitaryLandfill, [type]: value };
  };

  postUnsanitaryLandfill = async (unsanitaryLandfill: IPostUnsanitaryLandfill) => {
    const newObject = { ...unsanitaryLandfill, preduzece_id: (companyStore.getCompany as IGetCompany).id };
    const [err, res] = await to<IPostResponse>(
      unsanitaryLandfillService.postUnsanitaryLandfill(nrizService.pickFields(newObject))
    );
    if (err || !res) return;
    const { id } = res;
    const sifarnikMesto = nrizService.addSifarnikInObject({
      list: sifarniciStore.list[SIFARNICI_ROUTES.MESTO],
      name: 'mesto',
      id: newObject.mesto_id,
    }) as { mesto: IGetMesto };

    const newObjectWithSifarnik = {
      id,
      ...newObject,
      is_active: true,
      ...sifarnikMesto,
    } as IGetUnsanitaryLandfill;

    this.setUnsanitaryLandfillList([newObjectWithSifarnik, ...this.unsanitaryLandfillList]);
    this.handleChange(
      'unsanitaryLandfillPagination',
      nrizService.increaseTotalPagination(this.unsanitaryLandfillPagination)
    );
    handleSuccessNotify();
    modalStore.removeAllModals();
  };

  putUnsanitaryLandfill = async (unsanitaryLandfill: IGetUnsanitaryLandfill) => {
    const [err, res] = await to<IEmptyResponse>(
      unsanitaryLandfillService.putUnsanitaryLandfill(nrizService.pickFields(unsanitaryLandfill))
    );
    if (err || !res) return;
    let sifarnikMesto = {};
    if (this.unsanitaryLandfill.mesto_id) {
      sifarnikMesto = nrizService.addSifarnikInObject({
        exId: this.unsanitaryLandfill.mesto_id,
        exObj: (this.unsanitaryLandfill as IGetUnsanitaryLandfill).mesto,
        list: sifarniciStore.list[SIFARNICI_ROUTES.MESTO],
        name: 'mesto',
        id: unsanitaryLandfill.mesto_id,
      });
    }
    const newObj = { ...unsanitaryLandfill, ...sifarnikMesto, is_active: true };
    this.setUnsanitaryLandfill(newObj);
    this.setUnsanitaryLandfillList(nrizService.changeItemInList(this.unsanitaryLandfillList, newObj));
    modalStore.removeAllModals();
    handleSuccessNotify();
  };

  fetchMesto = async (pagination: IPagination, search: string): Promise<IPaginatedResponse<IGetMesto>> => {
    const response = await sifarniciStore.fetchSifarnikOptions<IGetMesto>(SIFARNICI_ROUTES.MESTO, {
      page: pagination.page,
      size: pagination.size,
      search: search,
      location_code__neq: 999999,
      order_by: '+display_name',
    });
    return response;
  };

  submitUnsanitaryLandfill = (unsanitaryLandfill: IPostUnsanitaryLandfill) => {
    const newPayload = {
      ...unsanitaryLandfill,
      godina_pocetka_deponovanja:
        unsanitaryLandfill.period_deponovanja && unsanitaryLandfill.period_deponovanja[0].year(),
      godina_zavrsetka_deponovanja:
        unsanitaryLandfill.period_deponovanja && unsanitaryLandfill.period_deponovanja[1].year(),
    };
    if (newPayload) {
      if (formStore.getCrudButtonType === 'submit') {
        return this.postUnsanitaryLandfill(newPayload);
      }
      const newObject = {
        id: (this.unsanitaryLandfill as IGetUnsanitaryLandfill).id,
        ...newPayload,
      } as IGetUnsanitaryLandfill;
      if (formStore.getCrudButtonType === 'change') {
        return this.putUnsanitaryLandfill(newObject);
      }
    }
  };

  aktivirajNeasnitarnuDeponiju = async () => {
    const [err, res] = await to<IEmptyResponse>(
      unsanitaryLandfillService.aktivirajNeasnitarnuDeponiju((this.unsanitaryLandfill as IGetUnsanitaryLandfill).id)
    );
    if (err || !res) return;
    const newObj = { ...this.unsanitaryLandfill, is_active: true };
    this.setUnsanitaryLandfill(newObj);
    this.setUnsanitaryLandfillList(nrizService.changeItemInList(this.unsanitaryLandfillList, newObj));
    handleSuccessNotify();
  };

  deaktivirajNeasnitarnuDeponiju = async () => {
    const [err, res] = await to<IEmptyResponse>(
      unsanitaryLandfillService.deaktivirajNeasnitarnuDeponiju((this.unsanitaryLandfill as IGetUnsanitaryLandfill).id)
    );
    if (err || !res) return;
    const newObj = { ...this.unsanitaryLandfill, is_active: false };

    this.setUnsanitaryLandfill(newObj);
    this.setUnsanitaryLandfillList(nrizService.changeItemInList(this.unsanitaryLandfillList, newObj));
    handleSuccessNotify();
  };
}

export const unsanitaryLandfillStore = new UnsanitaryLandfillStore();
