import { observer } from 'mobx-react-lite';
import styles from './ZakljucavanjePoTipuHeader.module.scss';
import { useTranslation } from 'react-i18next';
import { FormInstance, Segmented } from 'antd';
import { OtkljucaniZakljucaniTipoviObrazaca } from 'modules/zakljucavanje/zakljucavanje.types';
import { SegmentedLabeledOption, SegmentedValue } from 'antd/es/segmented';
import { LockOutlined, UnlockOutlined } from '@ant-design/icons';
import { zakljucavanjeStore } from 'modules/zakljucavanje/zakljucavanje.store';
import { paginationInitialState } from 'modules/nriz/nriz.constants';

interface PropsWithForm {
  form: FormInstance<any>;
}

export const ZakljucavanjePoTipuHeader: React.FC<PropsWithForm> = observer(({ form }) => {
  const { t } = useTranslation();

  // const form = useFormInstance();

  const segmentedOptions: SegmentedLabeledOption[] = [
    {
      icon: <LockOutlined />,
      label: 'Zaključavanje',
      value: OtkljucaniZakljucaniTipoviObrazaca.ZAKLJUCANO,
    },
    {
      icon: <UnlockOutlined />,
      label: 'Otključavanje',
      value: OtkljucaniZakljucaniTipoviObrazaca.OTKLJUCANO,
    },
  ];

  const onSegmentedChange = (value: SegmentedValue) => {
    zakljucavanjeStore.toggleOtkljucanoZakljucanoTipovi(value as OtkljucaniZakljucaniTipoviObrazaca);
    zakljucavanjeStore.setSelectedTipObrasca(null);
    zakljucavanjeStore.setPagination(paginationInitialState);
    form.resetFields();
  };

  return (
    <div className={styles.zakljucavanjePoTipuHeader}>
      <h1>{t('ZAKLJUCAVANJE_OBRAZACA.KOREKCIJA')}</h1>
      <div>
        <Segmented
          options={segmentedOptions}
          size="large"
          className={styles.zakljucavanjeSegment}
          onChange={onSegmentedChange}
        />
      </div>
    </div>
  );
});
