import { axios, ApiResponse } from 'modules/axios';
import { Pzv1Korekcije } from './pzv1.types';
import { ROUTES } from 'modules/nriz/nriz.constants';

const submitKorekcije = (payload: Pzv1Korekcije, id: string): ApiResponse<string> => {
  return axios.put(`${ROUTES.OBRAZAC}/pzv1/${id}/dozvoljene-korekcije`, payload);
};

export const pzv1Repo = {
  submitKorekcije,
};
