import React from 'react';
import styles from './Select.module.scss';

import { Select as ANTSelect, SelectProps } from 'antd';
import { localeEmpty } from 'modules/nriz/nriz.constants';

interface ISelectProps extends SelectProps {
  name: string;
  readOnly?: boolean;
}

export const Select: React.FC<ISelectProps> = props => {
  return (
    <ANTSelect
      {...props}
      className={`${props.className} ${props.readOnly ? styles.readOnly : ''}`}
      data-test={`input-${props.name}`}
      notFoundContent={localeEmpty.emptyText}
    />
  );
};
