import React from 'react';
import { InsideContentWrapper } from 'components/InsideContentWrapper/InsideContentWrapper';
import { WizardObrazacListHeader } from 'components/WizardObrazacListHeader/WizardObrazacListHeader';
import { observer } from 'mobx-react-lite';
import { ColumnsType } from 'antd/es/table';
import TableData from 'components/TableData/TableData';
import { useTranslation } from 'react-i18next';
import { gio4ObrazacStore } from 'modules/obrasci/store/gio4/gio4.store';
import { IGio4Country } from 'modules/obrasci/store/gio4/gio4.types';
import { EditButtonProps, RowEditPreviewButton } from 'components/RowEditPreviewButton/RowEditPreviewButton';
import { obrasciStore } from 'modules/obrasci/obrasci.store';
import styles from './Gio4ZemljeTranzitaTable.module.scss';

const EditGio4ZemljeTranzitaButton: React.FC<EditButtonProps<IGio4Country>> = observer(({ record }) => {
  return (
    <RowEditPreviewButton
      name="gio4-zemlje-tranzita"
      modalName="gio4-zemlje-tranzita-modal"
      onClick={() => gio4ObrazacStore.handleChange('zemljaTranzita', record)}
      preview={obrasciStore.zakljucanObrazac}
    />
  );
});

export const Gio4ZemljeTranzitaTable: React.FC = observer(() => {
  const { t } = useTranslation();
  t;

  const baseColumns: ColumnsType<IGio4Country> = [
    {
      title: 'Zemlja tranzita',
      width: 100,
      dataIndex: ['zemlja_tranzita', 'name'],
      key: 'category_name',
    },
    {
      title: '',
      key: 'operation',
      fixed: 'right',
      width: 40,
      render: (_, record: IGio4Country) => <EditGio4ZemljeTranzitaButton record={record} />,
    },
  ];

  return (
    <InsideContentWrapper
      className={styles.header}
      header={
        <WizardObrazacListHeader
          name={'gio4-zemlja-tranzita-form'}
          modalName="gio4-zemlje-tranzita-modal"
          title={
            gio4ObrazacStore.getImaTranzita && gio4ObrazacStore.getIzvozOtpada.zemlje_tranzita.length === 0
              ? t('OBRASCI.GIO5.VALIDATION.TRANSIT')
              : ''
          }
        />
      }
    >
      <TableData
        name="zemlje-tranzita-gio4"
        columns={baseColumns}
        dataSource={[...gio4ObrazacStore.izvozOtpada.zemlje_tranzita]}
      />
    </InsideContentWrapper>
  );
});
