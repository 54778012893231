import { InsideContentWrapper } from 'components/InsideContentWrapper/InsideContentWrapper';
import { EmisijeUTloTable } from './components/EmisijeUTloTable/EmisijeUTloTable';
import { WizardObrazacListHeader } from 'components/WizardObrazacListHeader/WizardObrazacListHeader';

export const EmisijeUTloWizardTab = () => {
  return (
    <InsideContentWrapper header={<WizardObrazacListHeader name={'emisije-u-tlo'} modalName={'emisije-u-tlo-modal'} />}>
      <EmisijeUTloTable />
    </InsideContentWrapper>
  );
};
