import { ColumnsType } from 'antd/es/table';
import { EditButtonProps, RowEditPreviewButton } from 'components/RowEditPreviewButton/RowEditPreviewButton';
import TableData from 'components/TableData/TableData';
import { observer } from 'mobx-react-lite';
import { obrasciStore } from 'modules/obrasci/obrasci.store';
import { obrazac1Store } from 'modules/obrasci/store/obrazac1/obrazac1.store';
import { IGetGoriva } from 'modules/obrasci/store/obrazac1/obrazac1.types';
import { useTranslation } from 'react-i18next';

const EditGorivaButton: React.FC<EditButtonProps<IGetGoriva>> = observer(({ record }) => {
  return (
    <RowEditPreviewButton
      name="obrazac1-goriva"
      modalName="goriva-modal"
      onClick={() => obrazac1Store.handleChange('goriva_form', record)}
      preview={obrasciStore.zakljucanObrazac}
    />
  );
});

export const TableGoriva: React.FC = observer(() => {
  const { t } = useTranslation();

  const baseColumns: ColumnsType<IGetGoriva> = [
    {
      title: `${t('OBRASCI.OBRAZAC_1.FUEL_NAME')}`,
      width: '100px',
      dataIndex: ['naziv_goriva', 'name_sr'],
      render(_, record) {
        return <span>{`${record.naziv_goriva.name_sr ?? ''} (${record.naziv_goriva.unit.code ?? ''})`}</span>;
      },
      key: 'name',
    },
    {
      title: `${t('OBRASCI.OBRAZAC_1.CONSUMPTION_PER_DAY')}`,
      width: '150px',
      dataIndex: 'potrosnja_na_dan',
      key: 'potrosnja_na_dan',
    },
    {
      title: `${t('OBRASCI.OBRAZAC_1.METHOD_OF_STORAGE')}`,
      dataIndex: 'nacin_lagerovanja',
      key: 'nacin_lagerovanja',
      width: '150px',
    },
    {
      title: `${t('OBRASCI.OBRAZAC_1.MAXIMUM_STORAGE_CAPACITY')}`,
      width: '150px',
      dataIndex: 'maksimalni_kapacitet_lagera',
      key: 'maksimalni_kapacitet_lagera',
    },
    {
      title: `${t('OBRASCI.OBRAZAC_1.AVERAGE_AMOUNT_OF_STORAGE')}`,
      dataIndex: 'prosecna_kolicina_na_lageru',
      key: 'prosecna_kolicina_na_lageru',
      width: '150px',
    },
    {
      title: ``,
      key: 'operation',
      fixed: 'right',
      width: '70px',
      align: 'center',
      render: (_, record: IGetGoriva) => <EditGorivaButton record={record} />,
    },
  ];

  return (
    <TableData
      scrollConfig={['#obrazacTableHeader', '#obrazacWizardHeader']}
      name="goriva-obrazac1"
      columns={baseColumns}
      dataSource={[...obrazac1Store.initialState.goriva]}
    />
  );
});
