import React from 'react';
import { observer } from 'mobx-react-lite';
import { ColumnsType } from 'antd/es/table';
import TableData from 'components/TableData/TableData';
import { useTranslation } from 'react-i18next';
import { gio3ObrazacStore } from 'modules/obrasci/store/gio3/gio3.store';
import { IGetRazrediEeOtpadaGio3 } from 'modules/obrasci/store/gio3/gio3.types';
import { EditButtonProps, RowEditPreviewButton } from 'components/RowEditPreviewButton/RowEditPreviewButton';
import { obrasciStore } from 'modules/obrasci/obrasci.store';

const EditGio3RazrediEEButton: React.FC<EditButtonProps<IGetRazrediEeOtpadaGio3>> = observer(({ record }) => {
  return (
    <RowEditPreviewButton
      name="gio3-razredi-ee"
      modalName="gio3-razredi-ee-modal"
      onClick={() => gio3ObrazacStore.handleChange('razredi_ee_form', record)}
      preview={obrasciStore.zakljucanObrazac}
    />
  );
});

export const TableRazrediEEGio3: React.FC = observer(() => {
  const { t } = useTranslation();

  const baseColumns: ColumnsType<IGetRazrediEeOtpadaGio3> = [
    {
      title: `${t('OBRASCI.GIO_3.CLASS_EE_WASTE')}`,
      width: '250px',
      key: 'category_name',
      render: (_: any, record: IGetRazrediEeOtpadaGio3) => (
        <>{`${record.razred_ee_otpada.the_order} ${record.razred_ee_otpada.category_name_sr}`}</>
      ),
    },
    {
      title: `${t('OBRASCI.GIO_3.QUANTITY_T')}`,
      width: '100px',
      dataIndex: 'kolicina',
      key: 'kolicina',
    },
    {
      title: '',
      key: 'operation',
      fixed: 'right',
      width: '50px',
      render: (_, record: IGetRazrediEeOtpadaGio3) => <EditGio3RazrediEEButton record={record} />,
    },
  ];

  return (
    <TableData
      name="razredi-gio3"
      columns={baseColumns}
      dataSource={[...gio3ObrazacStore.kolicine_form.razredi_ee_otpada]}
    />
  );
});
