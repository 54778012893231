import { ColumnsType } from 'antd/es/table';
import { InsideContentWrapper } from 'components/InsideContentWrapper/InsideContentWrapper';
import { EditButtonProps, RowEditPreviewButton } from 'components/RowEditPreviewButton/RowEditPreviewButton';
import TableData from 'components/TableData/TableData';
import { WizardObrazacListHeader } from 'components/WizardObrazacListHeader/WizardObrazacListHeader';
import { t } from 'i18next';
import { observer } from 'mobx-react-lite';
import { obrasciStore } from 'modules/obrasci/obrasci.store';
import { kom1ObrazacStore } from 'modules/obrasci/store/kom1/kom1.store';
import { IGetPredato, IGetUpravljanje } from 'modules/obrasci/store/kom1/kom1.types';
import React from 'react';

const EditKom1PredatoButton: React.FC<EditButtonProps<IGetPredato>> = observer(({ record }) => {
  return (
    <RowEditPreviewButton
      name="kom1-predato"
      modalName="predato-kom1-modal"
      onClick={() => kom1ObrazacStore.handleChange('predato_form', record)}
      preview={obrasciStore.zakljucanObrazac}
    />
  );
});

export const PredatoTable: React.FC = observer(() => {
  const baseColumns: ColumnsType<IGetPredato> = [
    {
      title: t('OBRASCI.KOM_1.MANAGEMENT.WAY_OF_HAND_OVER'),
      width: 100,
      dataIndex: ['way_of_hand_over', 'name_sr'],
      key: 'way_of_hand_over',
    },
    {
      title: t('OBRASCI.KOM_1.QUANTITY_T'),
      width: 100,
      dataIndex: 'kolicina',
      key: 'kolicina',
    },
    {
      title: t('OBRASCI.KOM_1.MANAGEMENT.D_OR_R_MARK'),
      width: 100,
      dataIndex: ['dr_list', 'name'],
      key: 'dr_list_id',
    },
    {
      title: t('OBRASCI.KOM_1.MANAGEMENT.NAME_AND_ADDRESS_OF_THE_OPERATOR'),
      width: 100,
      dataIndex: 'naziv_i_adresa_operatera',
      key: 'naziv_i_adresa_operatera',
    },
    {
      title: t('OBRASCI.KOM_1.MANAGEMENT.OPERATOR_LICENSE_NUMBER'),
      width: 100,
      dataIndex: 'broj_dozvole_operatera',
      key: 'broj_dozvole_operatera',
    },
    {
      title: '',
      key: 'operation',
      fixed: 'right',
      width: '50px',
      render: (_, record: IGetPredato) => <EditKom1PredatoButton record={record} />,
    },
  ];
  return (
    <InsideContentWrapper
      header={<WizardObrazacListHeader title={'Predato'} name={'predato-kom1'} modalName="predato-kom1-modal" />}
    >
      <TableData
        columns={baseColumns}
        dataSource={[...(kom1ObrazacStore.upravljanje_form as IGetUpravljanje).kom1_nacin_predaje_otpada]}
        name={'predato-kom1'}
      />
    </InsideContentWrapper>
  );
});
