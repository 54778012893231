import React, { KeyboardEvent } from 'react';
import { Form } from 'antd';
import { Input } from '../Input/Input';
import { FormInputProps } from 'typescript';
import { invalidCharactersFormInput } from 'modules/nriz/nriz.constants';
import styles from './FormInput.module.scss';

export const FormInput: React.FC<FormInputProps> = ({
  name,
  placeholder,
  rules,
  iconComponent,
  type,
  label,
  disabled,
  colon,
  inputName,
  step,
  className,
  onInputChange,
  defaultValue,
  readOnly,
  value,
  labelCol,
  wrapperCol,
  labelAlign,
  invalidCharacters,
}) => {
  return (
    <Form.Item
      labelCol={labelCol}
      wrapperCol={wrapperCol}
      name={name}
      rules={rules}
      label={label}
      colon={colon}
      labelAlign={labelAlign}
    >
      <Input
        step={step}
        type={type}
        prefix={iconComponent}
        placeholder={placeholder}
        onKeyDown={(e: KeyboardEvent<HTMLInputElement>) => {
          invalidCharacters
            ? (invalidCharacters.includes(e.key) || invalidCharactersFormInput.includes(e.key)) && e.preventDefault()
            : invalidCharactersFormInput.includes(e.key) && e.preventDefault();
        }}
        disabled={disabled}
        name={inputName}
        value={value}
        className={`${className} ${readOnly ? styles.readOnly : ''}`}
        onChange={e => onInputChange && onInputChange(e)}
        defaultValue={defaultValue}
        readOnly={readOnly}
      />
    </Form.Item>
  );
};
