import { FormInstance } from 'antd';
import { IGetMesto, IPostMesto } from './mesto.types';
import { t } from 'i18next';
import { ColumnsType } from 'antd/es/table';
import {
  InputNumberSifarniciFormProps,
  InputSifarniciFormProps,
  SIFARNICI_ROUTES,
  SearchScrollSifarniciFormProps,
} from 'modules/sifarnici/sifarnici.types';

export const MESTO_INITIAL_STATE: IPostMesto = {
  display_name: '',
  location_name: '',
  location_code: null,
  municipality_code_id: '',
  nuts1_code_id: '',
  nuts2_region_code_id: '',
  nuts3_area_code_id: '',
  is_active: true,
};
export const MESTO_TABLE_DATA: ColumnsType<IGetMesto> = [
  {
    title: `${t('UNSANITARY_LANDFILL.NAME')} mesta`,
    width: '200px',
    dataIndex: 'location_name',
    key: 'location_name',
  },
  {
    title: `${t('USER.PASSWORD')} mesta`,
    width: '200px',
    dataIndex: 'location_code',
    key: 'location_code',
  },
  {
    title: `Naslov`,
    width: '200px',
    dataIndex: 'display_name',
    key: 'display_name',
  },
  {
    title: `${t('OBRASCI.KOM_1.MUNICIPALITY')}`,
    width: '200px',
    dataIndex: ['municipality_code', 'name'],
    key: 'municipality_code',
  },
  {
    title: 'NUTS 1',
    width: '200px',
    dataIndex: ['nuts1_code', 'name'],
    key: 'nuts1_code',
  },
  {
    title: 'NUTS 2 regiona',
    width: '200px',
    dataIndex: ['nuts2_region_code', 'name'],
    key: 'nuts2_region_code',
  },
  {
    title: 'NUTS 3 oblasti',
    width: '200px',
    dataIndex: ['nuts2_region_code', 'name'],
    key: 'nuts2_region_code',
  },
];
export const MESTO_FORM_OPTIONS = (_: FormInstance<IGetMesto>) => {
  return [
    {
      type_of_form: 'input',
      label: `${t('UNSANITARY_LANDFILL.NAME')} mesta`,
      inputName: 'location_name',
      name: 'location_name',
    } as InputSifarniciFormProps,
    {
      type_of_form: 'input_number',
      label: `${t('USER.PASSWORD')} mesta`,
      inputName: 'location_code',
      name: 'location_code',
    } as InputNumberSifarniciFormProps,
    {
      type_of_form: 'input',
      label: `Naslov`,
      inputName: 'display_name',
      name: 'display_name',
    } as InputSifarniciFormProps,
    {
      type_of_form: 'searchScroll',
      label: `${t('OBRASCI.KOM_1.MUNICIPALITY')}`,
      formName: 'municipality_code_id',
      fetchOptinsList: SIFARNICI_ROUTES.MUNICIPALITY_CODE,
      filtersForFetch: {},
      labelOptionAccessor: ['name'],
      valueOptionAccessor: 'id',
      disabledAccessor: 'is_active',
    } as SearchScrollSifarniciFormProps,
    {
      type_of_form: 'searchScroll',
      label: 'NUTS 1',
      formName: 'nuts1_code_id',
      fetchOptinsList: SIFARNICI_ROUTES.NUTS1_CODE,
      filtersForFetch: {},
      labelOptionAccessor: ['name'],
      valueOptionAccessor: 'id',
      disabledAccessor: 'is_active',
    } as SearchScrollSifarniciFormProps,
    {
      type_of_form: 'searchScroll',
      label: 'NUTS 2 regiona',
      formName: 'nuts2_region_code_id',
      fetchOptinsList: SIFARNICI_ROUTES.NUTS2_REGION_CODE,
      filtersForFetch: {},
      labelOptionAccessor: ['name'],
      valueOptionAccessor: 'id',
      disabledAccessor: 'is_active',
    } as SearchScrollSifarniciFormProps,
    {
      type_of_form: 'searchScroll',
      label: 'NUTS 3 oblasti',
      formName: 'nuts3_area_code_id',
      fetchOptinsList: SIFARNICI_ROUTES.NUTS3_AREA_CODE,
      filtersForFetch: {},
      labelOptionAccessor: ['name'],
      valueOptionAccessor: 'id',
      disabledAccessor: 'is_active',
    } as SearchScrollSifarniciFormProps,
  ];
};
