import { DefaultOptionType } from 'antd/es/select';
import dayjs from 'dayjs';
import { omit, trim } from 'lodash';
import {
  ISifarniciTypeOfForm,
  InputSifarniciFormProps,
  SearchScrollSifarniciFormProps,
} from 'modules/sifarnici/sifarnici.types';
import { IPagination, ISifarnikFindProps } from 'typescript/NrizTypes';
import { arrayToObject } from 'utils/arrayToObject';

export const randomString = (id: string) => {
  const characters = 'abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789';
  let rndString = '';
  for (let i = 0; i < 5; i++) rndString += characters[Math.floor(Math.random() * characters.length)];
  return `${rndString}${id}`;
};

class NrizService {
  pickFields = (obj: any) => {
    const removedKeys = [];
    for (const key in obj) {
      if (typeof obj[key] === 'string') {
        obj[key] = trim(obj[key]);
      }
      if (obj[key] === null || obj[key] === undefined || obj[key] === '' || obj[key] === 'Invalid Date') {
        removedKeys.push(key);
      }
    }
    obj = omit(obj, removedKeys);
    return obj;
  };

  setEmptyValuesToNull = <T>(obj: T): T => {
    let newObj = {} as T;
    for (const key in obj) {
      if (obj[key] === undefined || obj[key] === '' || obj[key] === 'Invalid Date') {
        newObj = { ...newObj, [key]: null };
      } else {
        newObj = { ...newObj, [key]: obj[key] };
      }
    }
    return newObj;
  };

  removeBool = (obj: any) => {
    const removedKeys = [];
    for (const key in obj) {
      if (typeof obj[key] === 'string') {
        obj[key] = trim(obj[key]);
      }
      if (obj[key] === false) {
        removedKeys.push(key);
      }
    }
    obj = omit(obj, removedKeys);
    return obj;
  };

  addKey = (array: any) => {
    const newArray = array?.map((item: any) => {
      return {
        ...item,
        key: randomString(item.id),
      };
    });
    return newArray;
  };

  /** Map keys to children arrays for ANTD collapsible table row functionality */
  addKeyToChildrenData = (array: any) => {
    const newArray = array?.map((item: any) => {
      if (item.children) {
        item.children = this.addKeyToChildrenData(item.children);
      }
      return {
        ...item,
        key: randomString(item.id),
      };
    });
    return newArray;
  };

  addIdAndKey = (object: any) => {
    const randomNumber = Math.random().toString();
    return {
      ...object,
      id: randomNumber,
      key: randomNumber,
    };
  };

  changeColValue = (value: boolean) => {
    if (value) {
      return 12;
    }
    return 24;
  };

  addToList = (list: any[], item: any) => {
    const updatedList = [this.setEmptyValuesToNull(item), ...list];
    return updatedList;
  };

  removeFromList = (list: any[], id: string) => {
    const filterList = list.filter(item => item.id !== id);
    return filterList;
  };
  removeFromListSifarnik = (list: any[], id: string) => {
    const newList = list.filter(item => item.id !== id);

    return newList ? newList : [];
  };

  isItemInList = (list: any[], id: string) => {
    return list.some(item => item.id === id);
  };

  findItemInList = (list: any[], id: string) => {
    if (Array.isArray(list)) {
      return list.find(item => item.id === id);
    }
  };

  increaseTotalPagination = (pagination: IPagination) => {
    return { ...pagination, total: pagination.total + 1 };
  };

  decreaseTotalPagination = (pagination: IPagination) => {
    if (pagination.total === 0) return;
    return { ...pagination, total: pagination.total - 1 };
  };

  changeItemInList = (list: any[], newObject: any, accessor = 'id') => {
    const findIndex = list.findIndex(item => item[accessor] === newObject[accessor]);
    list[findIndex] = this.setEmptyValuesToNull(newObject);
    return list;
  };

  addItemToList = (list: any[], newObject: any) => {
    const newList = [{ ...this.setEmptyValuesToNull(this.addIdAndKey(newObject)) }, ...list];
    return newList;
  };

  addSifarnikInObject = ({ list, name, id, exId, exObj }: ISifarnikFindProps) => {
    if (exId === id) {
      return { [name]: exObj };
    }
    const objArray: any = arrayToObject(list);
    const object = objArray[id];

    return { [name]: object };
  };

  handleSearchOptions = (input: string, option: DefaultOptionType | undefined) => {
    return ((option?.label as string).toLowerCase() ?? '').includes(input.toLowerCase());
  };

  handleMonthsExport = (type: number) => {
    switch (type) {
      case 1:
        'Januar';
        break;
      case 2:
        'Februar';
        break;
      case 3:
        'Mart';
        break;
      case 4:
        'April';
        break;
      case 5:
        'May';
        break;
      case 6:
        'Jun';
        break;
      case 7:
        'Jul';
        break;
      case 8:
        'Avgust';
        break;
      case 9:
        'Septembar';
        break;
      case 10:
        'Oktobar';
        break;
      case 11:
        'Novembar';
        break;
      case 12:
        'Decembar';
        break;

      default:
        break;
    }
  };

  setBoolToNull = (initialState: ISifarniciTypeOfForm) => {
    return Object.fromEntries(
      Object.entries(initialState).map(([key, value]) => [key, typeof value === 'boolean' ? null : value])
    );
  };

  addSuffixToFilters = (options: ISifarniciTypeOfForm[], allValues: any) => {
    let newObj = {} as ISifarniciTypeOfForm;

    const updatedFormFields = options.map(field => {
      const fieldName = (field as InputSifarniciFormProps).name || (field as SearchScrollSifarniciFormProps).formName;
      if (typeof fieldName === 'string' && Object.prototype.hasOwnProperty.call(allValues, fieldName)) {
        return { ...field, inputValue: allValues[fieldName] };
      }
      return field;
    });

    updatedFormFields.map((item: ISifarniciTypeOfForm) => {
      const fieldName: string =
        ((item as InputSifarniciFormProps).name as string) ||
        ((item as SearchScrollSifarniciFormProps).formName as string);

      if (typeof item.inputValue === 'boolean') {
        return (newObj = { ...newObj, [fieldName]: item.inputValue });
      }

      if (item.inputValue) {
        // this is exceptions
        if (
          fieldName === 'pro_id' ||
          fieldName === 'postrojenje__nacionalni_id' ||
          fieldName === 'nacionalni_id' ||
          fieldName === 'postrojenje__istorijski_id'
        ) {
          return (newObj = { ...newObj, [`${fieldName}__ilike`]: item.inputValue });
        }

        if (!fieldName.endsWith('_id') && item.type_of_form !== 'date') {
          return (newObj = { ...newObj, [`${fieldName}__ilike`]: item.inputValue });
        }

        if (item.type_of_form === 'date') {
          if (fieldName === 'date_founding') {
            const date = dayjs(item.inputValue);
            return { ...newObj, [`${fieldName}__gte`]: date.format('YYYY-MM-DDT00:00:00.000[Z]') };
          }
          if (fieldName.includes('date') || fieldName.includes('datum')) {
            const date = dayjs(item.inputValue);
            if (fieldName.includes('to')) {
              return (newObj = { ...newObj, [`${fieldName}__lte`]: date.format('YYYY-MM-DDT23:59:59.999[Z]') });
            } else if (fieldName.includes('from') || fieldName === 'date_of_permit_issue') {
              return (newObj = { ...newObj, [`${fieldName}__gte`]: date.format('YYYY-MM-DDT00:00:00.000[Z]') });
            }
            return (newObj = { ...newObj, [fieldName]: date.format('YYYY-MM-DDT00:00:00.000[Z]') });
          } else if (fieldName.includes('year') || fieldName.includes('godina')) {
            const year = dayjs(item.inputValue)?.format('YYYY');
            if (fieldName === 'period_year') {
              return (newObj = { ...newObj, [`${fieldName}__ilike`]: year });
            }
            if (fieldName.includes('from') || fieldName.includes('to')) {
              return (newObj = { ...newObj, [`${fieldName}${fieldName.includes('from') ? '__gte' : '__lte'}`]: year });
            }
            return (newObj = { ...newObj, [fieldName]: year });
          }
        }
        return (newObj = { ...newObj, [fieldName]: item.inputValue });
      }
    });

    return newObj;
  };
}

export const nrizService = new NrizService();
