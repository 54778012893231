import { Empty, Form, FormInstance, message as ANTMessage } from 'antd';
import dayjs from 'dayjs';
import i18n from 'i18next';
import { IPagination, RuleConfig } from 'typescript';

export const emailRegexp = new RegExp(
  /^[a-zA-Z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-zA-Z0-9!#$%&'*+/=?^_`{|}~-]+)*@(?:[a-zA-Z0-9](?:[a-zA-Z0-9-]*[a-zA-Z0-9])?\.)+[a-zA-Z0-9](?:[a-zA-Z0-9-]*[a-zA-Z0-9])?$/
);
export const phoneRegexp = new RegExp(
  '(^[+][0-9]{3}[.\\s-]?[0-9]{2}[.\\s-]?[0-9]{6,7}$)|(^[0-9]{3}[.\\s-]?[0-9]{6,7}$)'
);

export const onlyNumbersAndLettersRegexp = new RegExp('^[A-Za-z0-9]{6}$');

export const languageOptions = [
  { value: 'en', label: 'english' },
  { value: 'rs', label: 'serbian' },
];

export const validateMessages = {
  required: '${label}' + ` ${i18n.t('VALIDATION.IS_REQUIRED')}`,
};

export const REQUIRED_FIELD_RULE = (required: boolean) => ({
  required: required,
  message: `${i18n.t('VALIDATION.IS_REQUIRED')}`,
});

export const REQUIRED_FIELD_RULE_NO_MSG: RuleConfig = {
  required: true,
  message: ``,
};

export const EMAIL_FIELD_RULE: RuleConfig = {
  message: `${i18n.t('VALIDATION.IS_NOT_VALID_EMAIL')}`,
  pattern: emailRegexp,
};

export const PHONE_FIELD_RULE: RuleConfig = {
  message: `${i18n.t('VALIDATION.IS_NOT_VALID_NUMBER')}`,
  pattern: phoneRegexp,
};
export const POST_NUMBER_FIELD_RULE: RuleConfig = {
  message: `Mora imati 5 karaktera`,
  min: 5,
  max: 5,
};

export const MORE_THEN_0_IS_REQUIRED = [
  REQUIRED_FIELD_RULE(true),
  {
    validator(_: any, value: number) {
      if (value <= 0) {
        return Promise.reject(new Error(i18n.t('VALIDATION.IS_MORE_THAN_0')));
      }
      return Promise.resolve();
    },
  },
];

export const PERCENTAGE_MORE_THAN_0_REQUIRED = [
  REQUIRED_FIELD_RULE(true),
  {
    validator(_: any, value: number) {
      if (value <= 0 || value > 100) {
        return Promise.reject(new Error(i18n.t('VALIDATION.SADRZAJ_PROCENTI')));
      }
      return Promise.resolve();
    },
  },
];

export const MORE_THEN_0 = [
  {
    validator(_: any, value: number) {
      if (!value && value !== 0) return Promise.resolve();
      if (value <= 0) {
        return Promise.reject(new Error(i18n.t('VALIDATION.IS_MORE_THAN_0')));
      }
      return Promise.resolve();
    },
  },
];
export const MORE_OR_EQUILE_0_IS_REQUIRED = [
  REQUIRED_FIELD_RULE(true),
  {
    validator(_: any, value: number) {
      if (value < 0) {
        // todo:prevod

        return Promise.reject(new Error(i18n.t('Broj mora biti veći ili jednak 0')));
      }
      return Promise.resolve();
    },
  },
];

export const MORE_THAN_OR_EQUAL_0_RULE = {
  validator(_: any, value: number) {
    if (value < 0) {
      return Promise.reject(new Error(i18n.t('VALIDATION.IS_MORE_THAN_OR_EQUALS_0')));
    }
    return Promise.resolve();
  },
};

export const MAX_LENGTH_RULE = (maxLength: number) => ({
  max: maxLength,
  message: `${i18n.t('VALIDATION.IS_TOO_LONG')}`,
});

export const MAX_AND_MIN_NUMBER = (min: number, max: number, operator: '' | '=' = '') => [
  {
    validator: (_: any, value: number) => {
      if (!value && value !== 0) return Promise.resolve();
      if (operator) {
        if (value <= min || value >= max) {
          return Promise.reject(new Error(`${i18n.t('BROJ_MORA_BITI_IZMEDJU')} ${min} ${i18n.t('AND')} ${max}`));
        }
      }
      if (value < min || value > max) {
        return Promise.reject(new Error(`${i18n.t('BROJ_MORA_BITI_IZMEDJU')} ${min} ${i18n.t('AND')} ${max}`));
      }
      return Promise.resolve();
    },
  },
];

export const DECIMAL_PLACES_RULE = (requiredDecimals: any) => ({
  validator(_: any, value: any) {
    if (!value || (value.toString().split('.')[1] || '').length >= requiredDecimals) {
      return Promise.resolve();
    }
    // todo: prevod
    return Promise.reject(new Error(`Broj mora imati minimum ${requiredDecimals} decimale`));
  },
});

export const LATITUDE_RULE = () => [...MAX_AND_MIN_NUMBER(41.78791525, 46.18879473), DECIMAL_PLACES_RULE(4)];

export const LONGITUDE_RULE = () => [...MAX_AND_MIN_NUMBER(18.81543959, 23.00527134), DECIMAL_PLACES_RULE(4)];

export const MIN_AND_MAX_YEAR = (min?: number, max?: number) => {
  return {
    validator: (_: any, value: dayjs.Dayjs) => {
      if (!value || !value.year()) return Promise.resolve();
      if (min !== undefined && value.year() < min) {
        return Promise.reject(new Error(`${i18n.t('Broj mora biti veći od ')} ${min}`));
      }
      if (max !== undefined && value.year() > max) {
        return Promise.reject(new Error(`${i18n.t('Broj mora biti manji od ')} ${max}`));
      }
      return Promise.resolve();
    },
  };
};

export const MAX_NUMBER_LENGTH_RULE = (max: number) => [
  {
    validator: (_: any, value: number) => {
      if (value && value.toString().length > max) {
        return Promise.reject(new Error(`${i18n.t('BROJ_CIFARA_NE_MOZE_BITI_VECI_OD')} ${max}`));
      }
      return Promise.resolve();
    },
  },
];

export const REUSABLE_RULE_REQ = (required: boolean, message: string) => {
  return {
    required: required,
    message: message,
  };
};

export const MIN_MAX_LENGTH_RULE = (min: number, max: number): RuleConfig => {
  return {
    min: min,
    max: max,
  };
};

export const SURVEY_VERIFICATION_CODE_RULE: RuleConfig = {
  pattern: onlyNumbersAndLettersRegexp,
  min: 6,
  max: 6,
  message: `${i18n.t('VALIDATION.IS_NOT_VALID_VERIFICATION_CODE')}`,
};

interface ListCheckInterface {
  previousValue: string | undefined;
  listItemAccessor: string;
  list: any[];
  message?: string;
}

export const checkIfItemExistsInList = ({
  previousValue,
  listItemAccessor,
  list,
  message,
}: ListCheckInterface): RuleConfig => {
  return {
    validator(_, value) {
      if (value !== previousValue && list.some(item => value === item[listItemAccessor]))
        return Promise.reject(new Error(message ?? i18n.t('VALIDATION.ALREADY_EXISTS')));
      return Promise.resolve();
    },
  };
};

interface ConditionalFieldsInterface {
  fieldNames: string[];
  message?: string;
}

export const CONDITIONALLY_REQUIRED_FIELDS_RULE = ({
  fieldNames,
  message = 'Potrebno je uneti barem jednu od vrednosti',
}: ConditionalFieldsInterface): RuleConfig => {
  const form = Form.useFormInstance();
  return {
    validator(_, _value) {
      if (fieldNames.some(fieldName => !!form.getFieldValue(fieldName) || form.getFieldValue(fieldName) === 0))
        return Promise.resolve();
      return Promise.reject(new Error(message));
    },
  };
};

export const SELECT_AT_LEAST_ONE_FIELD_RULE = (
  fieldNames: string[],
  form: FormInstance,
  message = 'Potrebno je uneti barem jednu od vrednosti'
): RuleConfig => {
  return {
    validator(_, _value) {
      if (fieldNames.some(fieldName => form.getFieldValue(fieldName))) {
        if (fieldNames.some(fieldName => form.getFieldError(fieldName)?.includes(message))) {
          form.validateFields();
        }
        return Promise.resolve();
      }
      return Promise.reject(new Error(message));
    },
  };
};

export const SELECT_GREATHER_DATE_RULE = (
  limitDate: { $d?: string },
  message = '',
  operator: '<' | '>' = '<'
): RuleConfig => {
  return {
    validator(_, _value: any) {
      if (limitDate?.$d && _value?.$d) {
        const valueYear: number = parseInt(dayjs(_value.$d).format('YYYY'));
        const limitDateYear: number = parseInt(dayjs(limitDate.$d).format('YYYY'));
        if ((operator === '<' && valueYear > limitDateYear) || (operator === '>' && valueYear < limitDateYear)) {
          ANTMessage.error(message);
          return Promise.reject();
        }
      }
      return Promise.resolve();
    },
  };
};

export const SELECT_DATE_FROM_EQUAL_YEAR_RULE = (selectedDate: { $y?: string }, message = ''): RuleConfig => {
  return {
    validator(_, _value: any) {
      if (selectedDate?.$y && _value?.$y && selectedDate?.$y !== _value?.$y) {
        ANTMessage.destroy();
        ANTMessage.error(message);
        return Promise.reject();
      }
      return Promise.resolve();
    },
  };
};

export const Azbuka = [
  'Љ',
  'љ',
  'њ',
  'њ',
  'З',
  'з',
  'У',
  'у',
  'И',
  'и',
  'П',
  'п',
  'Ш',
  'ш',
  'Ђ',
  'ђ',
  'Д',
  'д',
  'Ф',
  'ф',
  'Г',
  'г',
  'Л',
  'л',
  'Ч',
  'ч',
  'Ћ',
  'ћ',
  'Ж',
  'ж',
  'Џ',
  'џ',
  'Ц',
  'ц',
  'Б',
  'б',
];

export const invalidCharactersForInputNumber = ['e', 'E', ' ', '+', '-'];

export const invalidCharactersFormInput = [...Azbuka];

export const PIB_FIELD_RULE: RuleConfig = {
  min: 9,
  max: 9,
  message: `${i18n.t('VALIDATION.IS_NOT_VALID_PIB')}`,
};

export const JMBG_FIELD_RULE: RuleConfig = {
  min: 13,
  max: 13,
  message: `${i18n.t('VALIDATION.IS_NOT_VALID_IDENTIFICATION_NUMBER')}`,
};

export const IDENTIFICATION_NUMBER_FIELD_RULE: RuleConfig = {
  min: 8,
  max: 8,
  message: `${i18n.t('VALIDATION.IS_NOT_VALID_IDENTIFICATION_NUMBER')}`,
};

export const PASSPORT_NUMBER_FIELD_RULE: RuleConfig = {
  min: 10,
  max: 10,
  message: `${i18n.t('VALIDATION.IS_NOT_VALID_PASSPORT')}`,
};

export const PAK_NUMBER_FIELD_RULE: RuleConfig = {
  min: 10,
  max: 10,
  message: `${i18n.t('VALIDATION.IS_NOT_VALID_PAK')}`,
};

export const NUMBER_OF_DAYS_RULE = (maxNumber: number) => ({
  max: maxNumber,
  message: `${i18n.t('VALIDATION.IS_NOT_VALID_NUMBER')}`,
});

export const paginationInitialState: IPagination = {
  page: 1,
  size: 20,
  total: 0,
  pages: 0,
};

export const MAX_PAGINATION_SIZE = 100;

export const MonthsOptions = [
  { label: i18n.t('MONTHS.JANUARY'), value: '1' },
  { label: i18n.t('MONTHS.FEBRUARY'), value: '2' },
  { label: i18n.t('MONTHS.MARCH'), value: '3' },
  { label: i18n.t('MONTHS.APRIL'), value: '4' },
  { label: i18n.t('MONTHS.MAY'), value: '5' },
  { label: i18n.t('MONTHS.JUNE'), value: '6' },
  { label: i18n.t('MONTHS.JULY'), value: '7' },
  { label: i18n.t('MONTHS.AUGUST'), value: '8' },
  { label: i18n.t('MONTHS.SEPTEMBER'), value: '9' },
  { label: i18n.t('MONTHS.OCTOBER'), value: '10' },
  { label: i18n.t('MONTHS.NOVEMBER'), value: '11' },
  { label: i18n.t('MONTHS.DECEMBER'), value: '12' },
];

export const localeEmpty = {
  emptyText: <Empty description={i18n.t('NO_DATA')} image={Empty.PRESENTED_IMAGE_SIMPLE} />,
};

export const ROUTES = {
  PUBLIC_REGISTRATION: '/public/registracija/',
  CONNECTION_APR: '/preduzece/apr/',
  COMPANY: '/preduzece/',
  USER: '/korisnik',
  AIR: '/izvor-zagadjenja-vazduha/',
  SOURCE_TYPE: '/vrsta-izvora/',
  WATER: '/izvor-zagadjenja-vode/',
  TYPE_OF_RECIPIENT: '/vrsta-recipijenta/',
  SOIL: '/izvor-zagadjenja-tla/',
  SURVEY: '/upitnik/',
  FACILITY: '/postrojenje',
  APPROVE: 'odobri',
  UNSANITARY_LANDFILL: 'nesanitarna-deponija',
  LOCATION: 'mesto',
  PRETEZNE_DELATNOSTI: 'sifarnik-pretezne-delatnosti',
  ACTIVITY_LIST: '/lista-aktivnosti',
  ECONOMIC_ACTIVITY: '/privredna-delatnost',
  ACTIVITY: '/aktivnost',
  TIP_OBRASCA: 'tip-obrasca',
  OBRAZAC: 'obrazac',
  IZVESTAJ: 'izvestaj',
  SISTEM_ADMIN: 'sistem-administrator',
  KORISNIK_POSTROJENJE: 'korisnik/postrojenje',
  REGISTRI: 'registri',
  ZAKLJUCAVANJE: {
    PO_GODINI: 'otkljucavanje-zakljucavanje-godine',
    IZUZECI_GODINA: 'tabela-izuzetaka-otkljucavanja-zakljucavanja-godine',
    PO_TIPU: 'otkljucavanje-zakljucavanje-obrazaca',
  },
  REJECT: 'odbij',
};
