import { ColumnsType } from 'antd/es/table';
import { EditButtonProps, RowEditPreviewButton } from 'components/RowEditPreviewButton/RowEditPreviewButton';
import TableData from 'components/TableData/TableData';
import { observer } from 'mobx-react-lite';
import { obrasciStore } from 'modules/obrasci/obrasci.store';
import { pzv1ObrazacStore } from 'modules/obrasci/store/pzv1/pzv1.store';
import { IGetDjubrivo } from 'modules/obrasci/store/pzv1/pzv1.types';
import { useTranslation } from 'react-i18next';

// todo: make a separate edit table row button component
// was pulled out from the columns array render function because mobx didnt see the obrasciStore.zakljucanObrazac change

const EditDjubrivoButton: React.FC<EditButtonProps<IGetDjubrivo>> = observer(({ record }) => {
  return (
    <RowEditPreviewButton
      name="pzv1-djubrivo"
      modalName="djubriva-modal"
      onClick={() => pzv1ObrazacStore.handleChange('djubrivo', record)}
      preview={obrasciStore.zakljucanObrazac && !pzv1ObrazacStore.initialState.korekcija_mineralnog_djubriva}
      store={pzv1ObrazacStore}
    />
  );
});

export const DjubrivaTable: React.FC = observer(() => {
  const { t } = useTranslation();

  const columns: ColumnsType<IGetDjubrivo> = [
    {
      title: `${t('OBRASCI.PZV_1.FERTILIZERS.TYPE')}`,
      width: 50,
      align: 'center',
      dataIndex: ['products_pzv', 'name'],
    },
    {
      title: `${t('OBRASCI.PZV_1.FERTILIZERS.CONTENT')}`,
      width: 70,
      align: 'center',
      dataIndex: 'sadrzaj_azota',
    },
    {
      title: `${t('OBRASCI.PZV_1.FERTILIZERS.QUANTITY')}`,
      width: 70,
      align: 'center',
      dataIndex: 'kolicina',
    },
    {
      title: '',
      fixed: 'right',
      align: 'center',
      width: 20,
      render: (_, record) => <EditDjubrivoButton record={record} />,
    },
  ];

  return (
    <TableData
      name="djubriva-pzv1"
      columns={columns}
      dataSource={[...pzv1ObrazacStore.getInitialState.mineralna_djubriva]}
    />
  );
});
