import { observer } from 'mobx-react-lite';
import styles from './Registri.module.scss';
import { Content } from 'components/Content/Content';
import { Registar, RegistriEnum } from 'modules/registri/registri.types';
import { useNavigate, useParams } from 'react-router-dom';
import { RegistriHeader } from './components/RegistriHeader/RegistriHeader';
import { isEnumValue } from 'utils/isEnumValue';
import { REGISTRI_CONFIG } from 'modules/registri/registri.constants';
import { useEffect } from 'react';
import TableData from 'components/TableData/TableData';
import { RegistriFilters } from './components/RegistriFilters/RegistriFilters';
import { EditOutlined } from '@ant-design/icons';
import { ColumnType } from 'antd/es/table';
import { Button } from 'components/Button/Button';

export const Registri = observer(() => {
  const navigate = useNavigate();

  const { registar } = useParams();

  const registarIsInEnum = !!registar && isEnumValue(registar, Object.values(RegistriEnum));

  useEffect(() => {
    if (!registarIsInEnum) navigate(REGISTRI_CONFIG.otv.routes.urlRoute);
  }, []);

  const validRegistar = registarIsInEnum ? registar : RegistriEnum.Otv;

  const { columns } = REGISTRI_CONFIG[validRegistar].tableData;

  const { fetchDataList, dataSource, pagination } = REGISTRI_CONFIG[validRegistar].store;

  const tableName = `${REGISTRI_CONFIG[validRegistar].key}-table`;
  const baseColumns: ColumnType<Registar>[] = [
    ...columns,
    {
      align: 'center',
      width: '100px',
      key: 'registar-edit-btn',
      render(_, record) {
        if (!record.id) return;
        return (
          <Button
            icon={<EditOutlined />}
            name={'registar-edit-btn'}
            onClick={() => navigate(`${REGISTRI_CONFIG[validRegistar].routes.urlRoute}/${record.id}`)}
          />
        );
      },
    },
  ];

  useEffect(() => {
    fetchDataList(pagination);
  }, [validRegistar]);

  return (
    <Content header={<RegistriHeader registar={validRegistar} />}>
      <div className="wrapper">
        <div id="registriFilters">
          <RegistriFilters registar={validRegistar} />
        </div>
        <TableData
          scrollConfig={['#registriHeader', '#registriFilters']}
          name={tableName}
          columns={baseColumns}
          dataSource={dataSource}
          refetchData={fetchDataList}
          className={styles.registarTable}
          storePagination={pagination}
        />
      </div>
    </Content>
  );
});
