import { ApiResponse, axios } from 'modules/axios';
import { IGetActivity1, IPostActivity1, RequestActivityList } from './activityList1.types';
import { ROUTES } from 'modules/nriz/nriz.constants';
import { omit } from 'lodash';
import queryString from 'query-string';
import { IEmptyResponse, IPaginatedApiResponse, IPostResponse } from 'typescript/NrizTypes';

const fetchActivityList1 = (payload: RequestActivityList): IPaginatedApiResponse<IGetActivity1> => {
  const query = queryString.stringify(payload);
  return axios.get(`${ROUTES.ACTIVITY_LIST}1/?${query}`);
};

const postActivity1 = (payload: IPostActivity1): ApiResponse<IPostResponse> => {
  return axios.post(`${ROUTES.ACTIVITY_LIST}1/`, payload);
};

const deleteActivity1 = (id: string): ApiResponse<IEmptyResponse> => {
  return axios.delete(`${ROUTES.ACTIVITY_LIST}1/${id}`);
};

const putActivity1 = (activity: IGetActivity1): ApiResponse<IEmptyResponse> => {
  const putActivityObj = omit(activity, ['id']);
  return axios.put(`${ROUTES.ACTIVITY_LIST}1/${activity.id}`, putActivityObj);
};

export const activity1Repo = {
  deleteActivity1,
  putActivity1,
  postActivity1,
  fetchActivityList1,
};
