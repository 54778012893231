import { PlusOutlined } from '@ant-design/icons';
import { Button } from 'components/Button/Button';
import React from 'react';
import { ButtonProps } from 'typescript/NrizTypes';
import styles from './AddNewButton.module.scss';
export const AddNewButton: React.FC<ButtonProps> = props => {
  return (
    <div className={styles.plus}>
      <Button name={props.name} onClick={() => props.onButtonClick()} disabled={props.disabled}>
        {props.label && props.label}
        <PlusOutlined />
      </Button>
    </div>
  );
};
