import { FormSearchInfiniteScroll } from 'components/FormSearchInfiniteScroll/FormSearchInfiniteScroll';
import { observer } from 'mobx-react-lite';
import styles from './OTVRegistarForm.module.scss';
import { FormTable } from 'components/FormTable/FormTable';
import { REQUIRED_FIELD_RULE } from 'modules/nriz/nriz.constants';
import { otvStore } from 'modules/registri/otv/otv.store';
import { IOTVRegistarZagadjenje } from 'modules/registri/otv/otv.types';
import { FormListType } from 'typescript/NrizTypes';
import { ColumnType } from 'antd/es/table';
import { ISifarnikInitial } from 'modules/sifarnici/sifarnici.types';
import { useTranslation } from 'react-i18next';
import { RegistriLists } from 'modules/registri/registri.types';

export const OTVRegistarForm = observer(() => {
  const { t } = useTranslation();

  const defaultPreduzeceOption: ISifarnikInitial | undefined = otvStore.otvRegistar?.preduzece_id
    ? {
        label: otvStore.otvRegistar?.preduzece.naziv,
        value: otvStore.otvRegistar?.preduzece.id,
      }
    : undefined;

  const defaultPostrojenjeOption: ISifarnikInitial | undefined = otvStore.otvRegistar?.postrojenje_id
    ? {
        label: otvStore.otvRegistar?.postrojenje.naziv_postrojenja,
        value: otvStore.otvRegistar?.postrojenje.id,
      }
    : undefined;

  const defaultWaterPollutionSourceOption: ISifarnikInitial | undefined = otvStore.otvRegistar?.izvor_zagadjenja_vode_id
    ? {
        label: otvStore.otvRegistar?.izvor_zagadjenja_vode.naziv_ispusta,
        value: otvStore.otvRegistar?.izvor_zagadjenja_vode.id,
      }
    : undefined;

  const otvRegistarZagadjenjeColumns: ColumnType<IOTVRegistarZagadjenje>[] = [
    {
      title: 'Zagađujuća materija',
      dataIndex: ['naziv_zagadjujuce_materije', 'name_sr'],
    },
    {
      title: t('OBRASCI.OBRAZAC3.JEDINICA_MERE'),
      dataIndex: ['jedinica_mere'],
      // render(_, record) {
      //   if (record.jedinica_mere) {
      //     return <span>{OTVJediniceMere[record.jedinica_mere]}</span>;
      //   }
      // },
    },
    {
      title: 'Granična vrednost',
      dataIndex: ['granicna_vrednost'],
    },
  ];

  return (
    <div className={styles.otvFormWrapper}>
      <FormSearchInfiniteScroll
        showSearch={true}
        label={t('COMPANY.TITLE')}
        onChange={value => otvStore.handleOtvOpsteChange('preduzece_id', value)}
        placeholder={t('COMPANY.PLACEHOLDER.ENTER_NAME')}
        formName={'preduzece_id'}
        fetchOptinsList={otvStore.fetchPreduzecaOtv}
        labelOptionAccessor="naziv"
        valueOptionAccessor="id"
        readOnly={false}
        defaultOption={defaultPreduzeceOption}
        rules={[REQUIRED_FIELD_RULE(true)]}
        disabled={!!otvStore.otvOpsteInfo.preduzece_id}
      />
      <FormSearchInfiniteScroll
        showSearch={true}
        label={t('FACILITY.TITLE')}
        placeholder={t('FACILITY.PLACEHOLDER.ENTER_NAME')}
        formName={'postrojenje_id'}
        fetchOptinsList={otvStore.fetchPostrojenjaOtv}
        labelOptionAccessor="naziv_postrojenja"
        valueOptionAccessor="id"
        readOnly={false}
        defaultOption={defaultPostrojenjeOption}
        onChange={value => otvStore.handleOtvOpsteChange('postrojenje_id', value)}
        rules={[REQUIRED_FIELD_RULE(true)]}
        disabled={!otvStore.otvOpsteInfo.preduzece_id || !!otvStore.otvOpsteInfo.postrojenje_id}
      />
      <FormSearchInfiniteScroll
        showSearch={true}
        label={t('POLLUTION.WATER.TITLE')}
        placeholder={t('POLLUTION.WATER.TITLE')}
        formName={'izvor_zagadjenja_vode_id'}
        fetchOptinsList={otvStore.fetchIzvoriZagadjenjaVodeOtv}
        labelOptionAccessor="naziv_ispusta"
        disabledAccessor="aktivan"
        valueOptionAccessor="id"
        readOnly={false}
        defaultOption={defaultWaterPollutionSourceOption}
        onChange={value => otvStore.handleOtvOpsteChange('izvor_zagadjenja_vode_id', value)}
        rules={[REQUIRED_FIELD_RULE(true)]}
        disabled={
          !otvStore.otvOpsteInfo.postrojenje_id ||
          !otvStore.otvOpsteInfo.preduzece_id ||
          !!otvStore.otvOpsteInfo.izvor_zagadjenja_vode_id
        }
      />
      <FormTable<IOTVRegistarZagadjenje>
        listName="otv_registar_zagadjenje"
        tableColumns={otvRegistarZagadjenjeColumns}
        configPath={{ formListType: FormListType.Registri, formListSubType: RegistriLists.OTV }}
        title="Spisak zagađujućih materija"
      />
    </div>
  );
});
