import { observer } from 'mobx-react-lite';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { FormInput } from 'components/FormInput/FormInput';
import { FormSearchInfiniteScroll } from 'components/FormSearchInfiniteScroll/FormSearchInfiniteScroll';
import { dep1Store } from 'modules/obrasci/store/dep1/dep1.store';
import { MAX_LENGTH_RULE, REQUIRED_FIELD_RULE } from 'modules/nriz/nriz.constants';
import { ISifarnikInitial } from 'modules/sifarnici/sifarnici.types';
import { IGetDep1 } from 'modules/obrasci/store/dep1/dep1.types';

export const NewDep1OstaliPodaciForm: React.FC = observer(() => {
  const { t } = useTranslation();

  const defaultNesanitarnaSmetlisteOption: ISifarnikInitial | undefined = dep1Store.initialState
    .nesanitarna_deponija_smetliste_je_id
    ? {
        label: (dep1Store.getInitialState as IGetDep1).nesanitarna_deponija_smetliste_je.answer_sr,
        value: (dep1Store.getInitialState as IGetDep1).nesanitarna_deponija_smetliste_je_id,
      }
    : undefined;

  const defaulOperativniStatusNesanitarne: ISifarnikInitial | undefined = dep1Store.initialState
    .operativni_status_nesanitarne_deponije_id
    ? {
        label: (dep1Store.getInitialState as IGetDep1).operativni_status_nesanitarne_deponije.answer_sr,
        value: (dep1Store.getInitialState as IGetDep1).operativni_status_nesanitarne_deponije_id,
      }
    : undefined;

  const defaultOdlaganjeOtpadaNaNesanitarnoj: ISifarnikInitial | undefined = dep1Store.initialState
    .dalje_odlaganje_otpada_na_nesanitarnoj_deponiji_id
    ? {
        label: (dep1Store.getInitialState as IGetDep1).dalje_odlaganje_otpada_na_nesanitarnoj_deponiji.answer_sr,
        value: (dep1Store.getInitialState as IGetDep1).dalje_odlaganje_otpada_na_nesanitarnoj_deponiji_id,
      }
    : undefined;

  const defaultNacinOdlaganjaOtpada: ISifarnikInitial | undefined = dep1Store.initialState.nacin_odlaganja_otpada_id
    ? {
        label: (dep1Store.getInitialState as IGetDep1).nacin_odlaganja_otpada.answer_sr,
        value: (dep1Store.getInitialState as IGetDep1).nacin_odlaganja_otpada_id,
      }
    : undefined;

  const defaultPrekrivanjeInternimMaterijalom: ISifarnikInitial | undefined = dep1Store.initialState
    .prekrivanje_inertnim_materijalom_id
    ? {
        label: (dep1Store.getInitialState as IGetDep1).prekrivanje_inertnim_materijalom.answer_sr,
        value: (dep1Store.getInitialState as IGetDep1).prekrivanje_inertnim_materijalom_id,
      }
    : undefined;

  const defaultPeriodZaKojiSePredvidjaEksp: ISifarnikInitial | undefined = dep1Store.initialState
    .period_za_koji_se_predvidja_trajanje_i_eksploatacija_nesanitarne_deponije_id
    ? {
        label: (dep1Store.getInitialState as IGetDep1)
          .period_za_koji_se_predvidja_trajanje_i_eksploatacija_nesanitarne_deponije.answer_sr,
        value: (dep1Store.getInitialState as IGetDep1)
          .period_za_koji_se_predvidja_trajanje_i_eksploatacija_nesanitarne_deponije_id,
      }
    : undefined;

  const defaultVodjenjeEvidencijeOKolicinama: ISifarnikInitial | undefined = dep1Store.initialState
    .vodjenje_evidencije_o_kolicinama_i_vrstama_otpada_id
    ? {
        label: (dep1Store.getInitialState as IGetDep1).vodjenje_evidencije_o_kolicinama_i_vrstama_otpada.answer_sr,
        value: (dep1Store.getInitialState as IGetDep1).vodjenje_evidencije_o_kolicinama_i_vrstama_otpada_id,
      }
    : undefined;

  const defaultDaLiJeUPoplavnomPodrucju: ISifarnikInitial | undefined = dep1Store.initialState
    .deo_ili_cela_nesanitarna_deponija_u_poplavnom_podrucju_id
    ? {
        label: (dep1Store.getInitialState as IGetDep1).deo_ili_cela_nesanitarna_deponija_u_poplavnom_podrucju.answer_sr,
        value: (dep1Store.getInitialState as IGetDep1).deo_ili_cela_nesanitarna_deponija_u_poplavnom_podrucju_id,
      }
    : undefined;

  return (
    <>
      <h4 style={{ fontWeight: 'bold' }}>{t('OBRASCI.DEP1.PANELI.OSTALI_PODACI')}</h4>

      <FormInput
        label={t('OBRASCI.DEP1.KOJEM_REGIONU_PRIPADA_OPSTINA')}
        name="region_za_upravljanje_otpada_kom_pripada_opstina"
        inputName="region_za_upravljanje_otpada_kom_pripada_opstina"
        type="string"
        labelCol={{ span: 16 }}
        wrapperCol={{ span: 8 }}
        style={{ width: '30%' }}
        rules={[REQUIRED_FIELD_RULE(true), MAX_LENGTH_RULE(150)]}
        colon={false}
      />

      <FormInput
        label={t('OBRASCI.DEP1.SA_KOJIM_LOKALNIM_SAMOUPRAVAMA_IMATE_SPORAZUM')}
        name="lokalne_samouprave_sa_kojima_je_potpisan_sporazum_o_zajednickom_upravljanju_otpadom"
        inputName="lokalne_samouprave_sa_kojima_je_potpisan_sporazum_o_zajednickom_upravljanju_otpadom"
        type="string"
        style={{ width: '30%' }}
        labelCol={{ span: 18 }}
        wrapperCol={{ span: 6 }}
        colon={false}
        rules={[MAX_LENGTH_RULE(500)]}
      />

      <FormSearchInfiniteScroll
        showSearch={true}
        formName={'nesanitarna_deponija_smetliste_je_id'}
        fetchOptinsList={dep1Store.fetchDepFormAnswersPitanje1}
        labelOptionAccessor="answer_sr"
        valueOptionAccessor="id"
        label={t('OBRASCI.DEP1.NESANITARNA_DEPONIJA_SMETLISTE_JE')}
        readOnly={false}
        labelCol={{ span: 16 }}
        wrapperCol={{ span: 8 }}
        inputName={'nesanitarna_deponija_smetliste_je_id'}
        defaultOption={defaultNesanitarnaSmetlisteOption}
        placeholder={t('OBRASCI.DEP1.NESANITARNA_DEPONIJA_SMETLISTE_JE')}
        rules={[REQUIRED_FIELD_RULE(true)]}
      />
      <FormSearchInfiniteScroll
        showSearch={true}
        formName={'operativni_status_nesanitarne_deponije_id'}
        fetchOptinsList={dep1Store.fetchDepFormAnswersPitanje2}
        labelOptionAccessor="answer_sr"
        valueOptionAccessor="id"
        labelCol={{ span: 16 }}
        wrapperCol={{ span: 8 }}
        label={t('OBRASCI.DEP1.OPERATIVNI_STATUS_NESANITARNE_DEPONIJE')}
        readOnly={false}
        inputName={'operativni_status_nesanitarne_deponije_id'}
        defaultOption={defaulOperativniStatusNesanitarne}
        placeholder={t('OBRASCI.DEP1.OPERATIVNI_STATUS_NESANITARNE_DEPONIJE')}
        rules={[REQUIRED_FIELD_RULE(true)]}
      />
      <FormSearchInfiniteScroll
        showSearch={true}
        formName={'dalje_odlaganje_otpada_na_nesanitarnoj_deponiji_id'}
        fetchOptinsList={dep1Store.fetchDepFormAnswersPitanje3}
        labelOptionAccessor="answer_sr"
        valueOptionAccessor="id"
        labelCol={{ span: 16 }}
        wrapperCol={{ span: 8 }}
        label={t('OBRASCI.DEP1.DA_LI_SE_NA_NESANITARNOJ_DEPONIJI_I_DALJE_ODLAZE_OTPAD')}
        readOnly={false}
        inputName={'dalje_odlaganje_otpada_na_nesanitarnoj_deponiji_id'}
        defaultOption={defaultOdlaganjeOtpadaNaNesanitarnoj}
        placeholder={t('OBRASCI.DEP1.DA_LI_SE_NA_NESANITARNOJ_DEPONIJI_I_DALJE_ODLAZE_OTPAD')}
        rules={[REQUIRED_FIELD_RULE(true)]}
        colon={false}
      />
      <FormSearchInfiniteScroll
        showSearch={true}
        formName={'nacin_odlaganja_otpada_id'}
        fetchOptinsList={dep1Store.fetchDepFormAnswersPitanje4}
        labelOptionAccessor="answer_sr"
        valueOptionAccessor="id"
        labelCol={{ span: 16 }}
        wrapperCol={{ span: 8 }}
        label={t('OBRASCI.DEP1.NACIN_ODLAGANJA_OTPADA')}
        readOnly={false}
        inputName={'nacin_odlaganja_otpada_id'}
        defaultOption={defaultNacinOdlaganjaOtpada}
        placeholder={t('OBRASCI.DEP1.NACIN_ODLAGANJA_OTPADA')}
        rules={[REQUIRED_FIELD_RULE(true)]}
      />
      <FormSearchInfiniteScroll
        showSearch={true}
        formName={'prekrivanje_inertnim_materijalom_id'}
        fetchOptinsList={dep1Store.fetchDepFormAnswersPitanje5And10}
        labelOptionAccessor="answer_sr"
        valueOptionAccessor="id"
        label={t('OBRASCI.DEP1.DA_LI_SE_VRSI_PREKRIVANJE_INERTNIM_MATERIJALOM')}
        readOnly={false}
        labelCol={{ span: 16 }}
        wrapperCol={{ span: 8 }}
        inputName={'prekrivanje_inertnim_materijalom_id'}
        defaultOption={defaultPrekrivanjeInternimMaterijalom}
        placeholder={t('OBRASCI.DEP1.DA_LI_SE_VRSI_PREKRIVANJE_INERTNIM_MATERIJALOM')}
        rules={[REQUIRED_FIELD_RULE(true)]}
        colon={false}
      />
      <FormSearchInfiniteScroll
        showSearch={true}
        formName={'period_za_koji_se_predvidja_trajanje_i_eksploatacija_nesanitarne_deponije_id'}
        fetchOptinsList={dep1Store.fetchDepFormAnswersPitanje6}
        labelOptionAccessor="answer_sr"
        valueOptionAccessor="id"
        label={t('OBRASCI.DEP1.PERIOD_ZA_KOJI_SE_PREDVIDJA_TRAJANJE_SMETLISTA')}
        readOnly={false}
        labelCol={{ span: 16 }}
        wrapperCol={{ span: 8 }}
        inputName={'period_za_koji_se_predvidja_trajanje_i_eksploatacija_nesanitarne_deponije_id'}
        defaultOption={defaultPeriodZaKojiSePredvidjaEksp}
        placeholder={t('OBRASCI.DEP1.PERIOD_ZA_KOJI_SE_PREDVIDJA_TRAJANJE_SMETLISTA')}
        rules={[REQUIRED_FIELD_RULE(true)]}
      />
      <FormSearchInfiniteScroll
        showSearch={true}
        formName={'vodjenje_evidencije_o_kolicinama_i_vrstama_otpada_id'}
        fetchOptinsList={dep1Store.fetchDepFormAnswersPitanje7And8And12}
        labelOptionAccessor="answer_sr"
        valueOptionAccessor="id"
        label={t('OBRASCI.DEP1.DA_LI_SE_VRSI_EVIDENCIJA_O_KOLICINAMA_OTPADA')}
        readOnly={false}
        labelCol={{ span: 16 }}
        wrapperCol={{ span: 8 }}
        inputName={'vodjenje_evidencije_o_kolicinama_i_vrstama_otpada_id'}
        defaultOption={defaultVodjenjeEvidencijeOKolicinama}
        placeholder={t('OBRASCI.DEP1.DA_LI_SE_VRSI_EVIDENCIJA_O_KOLICINAMA_OTPADA')}
        rules={[REQUIRED_FIELD_RULE(true)]}
        colon={false}
      />
      <FormSearchInfiniteScroll
        showSearch={true}
        formName={'deo_ili_cela_nesanitarna_deponija_u_poplavnom_podrucju_id'}
        fetchOptinsList={dep1Store.fetchDepFormAnswersPitanje7And8And12}
        labelOptionAccessor="answer_sr"
        valueOptionAccessor="id"
        label={t('OBRASCI.DEP1.DA_LI_SE_NESANITARNA_NALAZI_U_POPLAVNOM')}
        readOnly={false}
        labelCol={{ span: 16 }}
        wrapperCol={{ span: 8 }}
        inputName={'deo_ili_cela_nesanitarna_deponija_u_poplavnom_podrucju_id'}
        defaultOption={defaultDaLiJeUPoplavnomPodrucju}
        placeholder={t('OBRASCI.DEP1.DA_LI_SE_NESANITARNA_NALAZI_U_POPLAVNOM')}
        rules={[REQUIRED_FIELD_RULE(true)]}
        colon={false}
      />
    </>
  );
});
