import { Form } from 'antd';
import { FormComponents } from 'components/FormComponents/FormComponents';
import { observer } from 'mobx-react-lite';
import { ISifarniciTypeOfForm } from 'modules/sifarnici/sifarnici.types';
import { WEEE_PRODUCTS_CATEGORY_FORM_OPTIONS } from 'modules/sifarnici/store/wasteCatalogue/wasteCatalogue.constants';
import {
  IWeeeProductsCategoryProps,
  IGetWeeeProductsCategory,
} from 'modules/sifarnici/store/wasteCatalogue/wasteCatalogue.types';

import React, { useMemo } from 'react';

export const WeeeProductsCategoyFormModal: React.FC<IWeeeProductsCategoryProps> = observer(
  ({ parentForm, formListName, selectedIndex }) => {
    const form = Form.useFormInstance<IGetWeeeProductsCategory>();

    const components = useMemo(() => {
      return WEEE_PRODUCTS_CATEGORY_FORM_OPTIONS({ parentForm, formListName, selectedIndex });
    }, [Form.useWatch([], form)]);

    return (
      <>
        {components.map((item: ISifarniciTypeOfForm, index) => {
          return <FormComponents data={item} key={index} />;
        })}
      </>
    );
  }
);
