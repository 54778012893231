import {
  IBaseObrazacStore,
  IEmptyResponse,
  IPaginatedResponse,
  IPagination,
  IPostResponse,
} from 'typescript/NrizTypes';
import { IGetDkoObrazac, IPostDkoObrazac } from './dko.types';
import { DKO_INITIAL_STATE, FILTER_APR, StringKeys, datesToFormat } from './dko.constants';
import { action, makeAutoObservable, observable } from 'mobx';
import { obrasciService } from 'modules/obrasci/obrasci.service';
import to from 'await-to-js';
import { TIPOVI_OBRAZACA } from 'modules/obrasci/obrasci.types';
import { formStore } from 'store';
import { nrizService } from 'modules/nriz/nriz.service';
import { sifarniciStore } from 'modules/sifarnici/sifarnici.store';
import { SIFARNICI_ROUTES } from 'modules/sifarnici/sifarnici.types';
import { handleSuccessNotify } from 'utils/succesHandling';
import dayjs from 'dayjs';
import { IGetApr } from 'modules/sifarnici/store/apr/apr.types';
import { IGetDRList } from 'modules/sifarnici/store/dRList/dRList.types';
import { IGetWasteCatalogue } from 'modules/sifarnici/store/wasteCatalogue/wasteCatalogue.types';
import { IGetQList } from 'modules/sifarnici/store/qList/qList.types';
import { obrasciStore } from 'modules/obrasci/obrasci.store';
import { IGetMesto } from 'modules/sifarnici/store/mesto/mesto.types';

import { IGetNList } from 'modules/sifarnici/store/nList/nList.types';
import { IGetCList } from 'modules/sifarnici/store/cList/cList.types';
import { IGetYList } from 'modules/sifarnici/store/yList/yList.types';
import { IGetWasteStateCategory } from 'modules/sifarnici/store/wasteStateCategory/wasteStateCategory.types';
import { IGetTypeMeansTransport } from 'modules/sifarnici/store/typeMeansTransport/typeMeansTransport.types';
import { IGetMethodOfPackagingWaste } from 'modules/sifarnici/store/methodOfPackaging/methodOfPackaging.types';
import { IGetTypeOfWaste } from 'modules/sifarnici/store/typeOfWaste/typeOfWaste.types';

class DkoObrazacStore implements IBaseObrazacStore<IGetDkoObrazac, IPostDkoObrazac> {
  initialState: IPostDkoObrazac | IGetDkoObrazac = DKO_INITIAL_STATE;
  bDatumPredaje = '';
  cDatumPredaje = '';
  currentDate = dayjs();
  vidPrevoza = '';
  trebaPotvrditi = false;

  constructor() {
    makeAutoObservable(this, {
      initialState: observable,
      cDatumPredaje: observable,
      bDatumPredaje: observable,
      currentDate: observable,
      vidPrevoza: observable,
      trebaPotvrditi: observable,
      setDatumB: action,
      setDatumC: action,
    });
  }

  get getInitialState() {
    return this.initialState;
  }

  handleChange(key: keyof DkoObrazacStore, value: any) {
    (this as any)[key] = value;
  }

  handleInitialStateChange(key: keyof IPostDkoObrazac, value: any) {
    (this as any).initialState[key] = value;
  }

  setDatumB(datum: any) {
    this.bDatumPredaje = datum;
  }
  setDatumC(datum: any) {
    this.cDatumPredaje = datum;
  }

  loadData = async (id: string) => {
    const [err, res] = await to(obrasciService.fetchSingleObrazac<IGetDkoObrazac>(TIPOVI_OBRAZACA.DKO, id));
    if (err || !res) return;
    const handleDatesLoad = (dates: StringKeys<IPostDkoObrazac>[], response: IGetDkoObrazac) => {
      const newResponse = response;
      dates.forEach(date => {
        if (newResponse[date]) {
          //@ts-ignore
          newResponse[date] = dayjs(newResponse[date]);
        }
      });
      return newResponse;
    };
    const newResponse = handleDatesLoad(datesToFormat, res);
    this.setDatumB(newResponse.datum_predaje_otpada);
    this.setDatumC(newResponse.datum_predaje_otpada_deo_c);
    const differenceInDays = Math.ceil(dayjs(newResponse.datum_predaje_otpada).diff(this.currentDate, 'day', true));
    if (
      this.currentDate > dayjs(newResponse.datum_predaje_otpada) &&
      !newResponse.potvrda_dokumenta &&
      !newResponse.odjava_kretanja &&
      !newResponse.admin_odjava_dokumenta
    )
      this.handleChange('trebaPotvrditi', true);
    if (
      differenceInDays <= 1 ||
      newResponse.odjava_kretanja ||
      newResponse.potvrda_dokumenta ||
      newResponse.admin_odjava_dokumenta
    ) {
      obrasciStore.setZakljucanObrazac(true);
    }
    this.handleChange('initialState', { ...newResponse, lokacija_istovara_id: newResponse.mesto.display_name });
    return newResponse;
  };

  async postData(payload: IPostDkoObrazac) {
    const [err, res] = await to<IPostResponse>(
      obrasciService.postSingleObrazac<IPostDkoObrazac>(TIPOVI_OBRAZACA.DKO, nrizService.setEmptyValuesToNull(payload))
    );
    if (err || !res) return;
    formStore.handleChange('goBack', true);
    handleSuccessNotify();
  }

  putData = async (payload: IGetDkoObrazac) => {
    const [err, res] = await to<IEmptyResponse>(
      obrasciService.putSingleObrazac(TIPOVI_OBRAZACA.DKO, payload.id, payload)
    );
    if (err || !res) return;
    formStore.handleChange('goBack', true);
    handleSuccessNotify();
  };

  putPotvrdaOdjava = async (params: string) => {
    const [err, res] = await to(
      obrasciService.putSingleObrazacWithParams(TIPOVI_OBRAZACA.DKO, (this.initialState as IGetDkoObrazac).id, params)
    );
    if (err || !res) return;
    formStore.handleChange('goBack', true);
    handleSuccessNotify();
  };

  async deleteData(id: string) {
    const [err, res] = await to(obrasciService.deleteSingleObrazac(TIPOVI_OBRAZACA.DKO, id));
    if (err || !res) return;
    formStore.handleChange('goBack', true);
    handleSuccessNotify();
  }

  resetStates = () => {
    this.handleChange('initialState', DKO_INITIAL_STATE);
    this.handleChange('bDatumPredaje', '');
    this.handleChange('cDatumPredaje', '');
    this.handleChange('vidPrevoza', '');
    this.handleChange('trebaPotvrditi', false);
  };

  submitData = (payload: IPostDkoObrazac | IGetDkoObrazac) => {
    const handleDatesSubmit = (dates: (keyof IPostDkoObrazac)[], payload: IPostDkoObrazac) => {
      const newPayload = payload;
      dates.forEach(date => {
        if (newPayload[date]) {
          //@ts-ignore
          newPayload[date] = dayjs(newPayload[date]).format('YYYY-MM-DD');
        }
      });
      return { ...newPayload, lokacija_istovara_id: payload.mesto_id };
    };
    const newPayload = handleDatesSubmit(datesToFormat, payload);
    const newInitialState = {
      id: (this.initialState as IGetDkoObrazac).id,
      ...DKO_INITIAL_STATE,
      ...newPayload,
    } as IGetDkoObrazac;

    obrasciService.submitFormObrazac({
      payload: newInitialState,
      postForm: this.postData,
      putForm: this.putData,
      deleteForm: this.deleteData,
    });
  };

  copyDKO = () => {
    const stateToCopy = this.initialState;
    this.resetStates();
    this.initialState = {
      ...stateToCopy,
      id: '',
      datum_predaje_otpada: '',
      datum_predaje_otpada_deo_c: '',
      datum_preuzimanja_otpada: '',
      datum_preuzimanja_otpada_deo_d: '',
    };
  };

  //   OPSTE

  fetchWasteCatalougeList = async (
    pagination: IPagination,
    search: string
  ): Promise<IPaginatedResponse<IGetWasteCatalogue>> => {
    const response = await sifarniciStore.fetchSifarnikOptions<IGetWasteCatalogue>(SIFARNICI_ROUTES.WASTE_CATALOGUE, {
      page: pagination.page,
      size: pagination.size,
      search: search,
      order_by: '+index_number',
      level: 3,
      index_number__ilike: '*',
    });
    return response;
  };

  //   DEO A
  fetchTypeOfWasteOptions = async (
    pagination: IPagination,
    search: string
  ): Promise<IPaginatedResponse<IGetTypeOfWaste>> => {
    const response = await sifarniciStore.fetchSifarnikOptions<IGetTypeOfWaste>(SIFARNICI_ROUTES.TYPE_OF_WASTE, {
      page: pagination.page,
      size: pagination.size,
      search: search,
    });
    return response;
  };

  fetchMethodOfPackagingWaste = async (
    pagination: IPagination,
    search: string
  ): Promise<IPaginatedResponse<IGetMethodOfPackagingWaste>> => {
    const response = await sifarniciStore.fetchSifarnikOptions<IGetMethodOfPackagingWaste>(
      SIFARNICI_ROUTES.METHOD_OF_PACKAGING_WASTE,
      {
        page: pagination.page,
        size: pagination.size,
        search: search,
        order_by: '+method_of_packaging_sr',
      }
    );
    return response;
  };

  fetchTypeMeansTransport = async (
    pagination: IPagination,
    search: string
  ): Promise<IPaginatedResponse<IGetTypeMeansTransport>> => {
    const response = await sifarniciStore.fetchSifarnikOptions<IGetTypeMeansTransport>(
      SIFARNICI_ROUTES.TYPE_MEANS_TRANSPORT,
      {
        page: pagination.page,
        size: pagination.size,
        search: search,
        order_by: '+type_of_means_of_transport_sr',
      }
    );
    return response;
  };

  fetchWasteStateCategory = async (
    pagination: IPagination,
    search: string
  ): Promise<IPaginatedResponse<IGetWasteStateCategory>> => {
    const response = await sifarniciStore.fetchSifarnikOptions<IGetWasteStateCategory>(
      SIFARNICI_ROUTES.WASTE_STATE_CATEGORY,
      {
        page: pagination.page,
        size: pagination.size,
        search: search,
      }
    );
    return response;
  };

  fetchYListOptions = async (pagination: IPagination, search: string): Promise<IPaginatedResponse<IGetYList>> => {
    const response = await sifarniciStore.fetchSifarnikOptions<IGetYList>(SIFARNICI_ROUTES.Y_LIST, {
      page: pagination.page,
      size: pagination.size,
      search: search,
      order_by: '+name',
      v2: true,
    });
    return response;
  };

  fetchCListOptions = async (pagination: IPagination, search: string): Promise<IPaginatedResponse<IGetCList>> => {
    const response = await sifarniciStore.fetchSifarnikOptions<IGetCList>(SIFARNICI_ROUTES.C_LIST, {
      page: pagination.page,
      size: pagination.size,
      search: search,
      order_by: '+name',
    });
    return response;
  };

  fetchNListOptions = async (pagination: IPagination, search: string): Promise<IPaginatedResponse<IGetNList>> => {
    const response = await sifarniciStore.fetchSifarnikOptions<IGetNList>(SIFARNICI_ROUTES.N_LIST, {
      page: pagination.page,
      size: pagination.size,
      search: search,
      order_by: '+name',
    });
    return response;
  };

  fetchQListOptions = async (pagination: IPagination, search: string): Promise<IPaginatedResponse<IGetQList>> => {
    const response = await sifarniciStore.fetchSifarnikOptions<IGetQList>(SIFARNICI_ROUTES.Q_LIST, {
      page: pagination.page,
      size: pagination.size,
      search: search,
      order_by: '+name',
    });
    return response;
  };

  fetchMesto = async (pagination: IPagination, search: string): Promise<IPaginatedResponse<IGetMesto>> => {
    const response = await sifarniciStore.fetchSifarnikOptions<IGetMesto>(SIFARNICI_ROUTES.MESTO, {
      page: pagination.page,
      size: pagination.size,
      search: search,
      order_by: '+display_name',
      location_code__neq: 999999,
    });
    return response;
  };
  //   DEO B

  fetchDrList = async (pagination: IPagination, search: string): Promise<IPaginatedResponse<IGetDRList>> => {
    const response = await sifarniciStore.fetchSifarnikOptions<IGetDRList>(SIFARNICI_ROUTES.DR_LIST, {
      search: search,
    });
    return response;
  };

  fetchApr = async (pagination: IPagination, search: string): Promise<IPaginatedResponse<IGetApr>> => {
    const response = await sifarniciStore.fetchSifarnikOptions<IGetApr>(SIFARNICI_ROUTES.APR, {
      page: pagination.page,
      size: pagination.size,
      search: search,
      order_by: '+full_company_name',
      status_id__in: FILTER_APR,
    });
    return response;
  };
}

export const dkoObrazacStore = new DkoObrazacStore();
