import { CheckOutlined, CloseOutlined } from '@ant-design/icons';
import { Col } from 'antd';
import { Button } from 'components/Button/Button';
import { EditButton } from 'components/Buttons/EditButton/EditButton';
import { Dispatch, SetStateAction } from 'react';
import styles from './RowEditIcon.module.scss';

interface RowEditIconProps {
  name: string;
  iconState: boolean;
  stateToggler: Dispatch<SetStateAction<boolean>>;
  onSaveClick: () => void;
  onCancelClick: () => void;
  colSpan?: number;
}

export const RowEditIcon: React.FC<RowEditIconProps> = ({
  colSpan = 2,
  iconState,
  stateToggler,
  onCancelClick,
  onSaveClick,
  name,
}) => {
  return (
    <Col span={colSpan} className={styles.rowIconWrapper}>
      {iconState ? (
        <div>
          <Button
            className={styles.saveIcon}
            icon={<CheckOutlined />}
            name={`${name}_row_save_icon`}
            onClick={() => {
              onSaveClick();
              stateToggler(false);
            }}
          />
          <Button
            className={styles.cancelIcon}
            icon={<CloseOutlined />}
            name={`${name}_row_cancel_icon`}
            onClick={() => {
              onCancelClick();
              stateToggler(false);
            }}
          />
        </div>
      ) : (
        <EditButton
          name={`${name}_row_edit_icon`}
          onButtonClick={() => {
            stateToggler(true);
          }}
        />
      )}
    </Col>
  );
};
