import { ColumnsType } from 'antd/es/table';
import { EditButtonProps, RowEditPreviewButton } from 'components/RowEditPreviewButton/RowEditPreviewButton';
import TableData from 'components/TableData/TableData';
import { observer } from 'mobx-react-lite';
import { obrasciStore } from 'modules/obrasci/obrasci.store';
import { kom1ObrazacStore } from 'modules/obrasci/store/kom1/kom1.store';
import { IGetKolicine } from 'modules/obrasci/store/kom1/kom1.types';
import { useTranslation } from 'react-i18next';

const EditKolicineButton: React.FC<EditButtonProps<IGetKolicine>> = observer(({ record }) => {
  return (
    <RowEditPreviewButton
      name="kom1-kolicine"
      modalName="kolicine-modal"
      onClick={() => kom1ObrazacStore.handleChange('kolicine_form', record)}
      preview={obrasciStore.zakljucanObrazac}
    />
  );
});

export const TableKolicine: React.FC = observer(() => {
  const { t } = useTranslation();

  const baseColumns: ColumnsType<IGetKolicine> = [
    {
      title: `${t('OBRASCI.KOM_1.TYPE_OF_WASTE')}`,
      width: '200px',
      dataIndex: ['kom1_type_of_waste', 'type_of_waste_sr'],
      key: 'type_of_waste_sr',
    },
    {
      title: `${t('OBRASCI.KOM_1.SPRING_ANALYSIS')}`,
      width: 100,
      dataIndex: 'prolecna_analiza',
      key: 'prolecna_analiza',
    },
    {
      title: `${t('OBRASCI.KOM_1.SUMMER_ANALYSIS')}`,
      dataIndex: 'letnja_analiza',
      key: 'letnja_analiza',
      width: 100,
    },
    {
      title: `${t('OBRASCI.KOM_1.AUTUMN_ANALYSIS')}`,
      dataIndex: 'jesenja_analiza',
      key: 'jesenja_analiza',
      width: 100,
    },
    {
      title: `${t('OBRASCI.KOM_1.WINTER_ANALYSIS')}`,
      dataIndex: 'zimska_analiza',
      key: 'zimska_analiza',
      width: 100,
    },
    {
      title: `${t('OBRASCI.KOM_1.MIDDLE_VALUE')}`,
      dataIndex: 'srednja_vrednost',
      key: 'srednja_vrednost',
      width: 100,
    },
    {
      title: ``,
      key: 'operation',
      fixed: 'right',
      width: '50px',
      align: 'center',
      render: (_, record: IGetKolicine) => <EditKolicineButton record={record} />,
    },
  ];

  return (
    <TableData
      scrollConfig={['#obrazacTableHeader', '#obrazacWizardHeader']}
      name="kolicine-kom1"
      columns={baseColumns}
      dataSource={[...kom1ObrazacStore.initialState.kolicine]}
    />
  );
});
