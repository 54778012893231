import { observer } from 'mobx-react-lite';
import React from 'react';
import { FacilityAirList } from './components/FacilityAirList/FacilityAirList';
import { FacilityWaterList } from './components/FacilityWaterList/FacilityWaterList';
import { FacilitySoilList } from './components/FacilitySoilList/FacilitySoilList';
import { Col, Row } from 'antd';
import { InsideContentWrapper } from 'components/InsideContentWrapper/InsideContentWrapper';
import styles from './FacilitySourcesOfPollution.module.scss';
import { useTranslation } from 'react-i18next';

export const SourcesOfPollutionHeader: React.FC = () => {
  const { t } = useTranslation();
  return (
    <Row justify={'space-between'} align={'middle'}>
      <h3>{t('POLLUTION.TITLE')}</h3>
    </Row>
  );
};

export const FacilitySourcesOfPollution: React.FC = observer(() => {
  return (
    <InsideContentWrapper header={<SourcesOfPollutionHeader />}>
      <Row className={styles.sources_of_pollution}>
        <Col span={24}>
          <FacilityAirList />
        </Col>
        <Col span={24}>
          <FacilityWaterList />
        </Col>
        <Col span={24}>
          <FacilitySoilList />
        </Col>
      </Row>
    </InsideContentWrapper>
  );
});
