import { ColumnsType } from 'antd/es/table';
import { EditButtonProps, RowEditPreviewButton } from 'components/RowEditPreviewButton/RowEditPreviewButton';
import TableData from 'components/TableData/TableData';
import { observer } from 'mobx-react-lite';
import { obrasciStore } from 'modules/obrasci/obrasci.store';
import { dep2ObrazacStore } from 'modules/obrasci/store/dep2/dep2.store';
import { IGetDivljeDeponije } from 'modules/obrasci/store/dep2/dep2.types';
import React from 'react';
import { useTranslation } from 'react-i18next';

export const TableDivljaDeponija: React.FC = observer(() => {
  const { t } = useTranslation();

  const EditDeponijaButton: React.FC<EditButtonProps<IGetDivljeDeponije>> = observer(({ record }) => {
    return (
      <RowEditPreviewButton
        name="dep2-divlja-deponija"
        modalName="divlja-deponija-modal"
        onClick={() => dep2ObrazacStore.handleChange('divlja_deponija', record)}
        preview={obrasciStore.zakljucanObrazac}
      />
    );
  });

  const baseColumns: ColumnsType<IGetDivljeDeponije> = [
    {
      title: `${t('OBRASCI.DEP_2.SETTLEMENT')}`,
      width: '150px',
      dataIndex: ['mesto', 'display_name'],
      key: 'display_name',
    },
    {
      title: `${t('OBRASCI.DEP_2.ESTIMATED_AMOUNT_OF_WASTE')}`,
      width: '150px',
      dataIndex: 'procenjena_kolicina_otpada',
      key: 'procenjena_kolicina_otpada',
    },
    {
      title: `${t('OBRASCI.DEP_2.ESTIMATED_OF_THE_AREA_OF_LANDFILLS')}`,
      dataIndex: 'procenjena_povrsina_smetlista',
      key: 'procenjena_povrsina_smetlista',
      width: '150px',
    },
    {
      title: `${t('OBRASCI.DEP_2.HOW_MANY_TIMES_CLEANED')}`,
      dataIndex: 'broj_ciscenja_prostora_u_okviru_godine',
      key: 'broj_ciscenja_prostora_u_okviru_godine',
      width: '150px',
    },
    {
      title: `${t('OBRASCI.DEP_2.IS_THERE_REPEATED_DUMPING')}`,
      dataIndex: ['dep_forms_answers', 'answer_sr'],
      key: 'answer',
      width: '150px',
    },
    {
      title: `${t('COMPANY.ADDITIONALLY')}`,
      key: 'operation',
      fixed: 'right',
      width: '100px',
      align: 'center',
      render: (_, record: IGetDivljeDeponije) => <EditDeponijaButton record={record} />,
    },
  ];

  return (
    <TableData
      scrollConfig={['#obrazacTableHeader', '#obrazacWizardHeader']}
      name="divlja-deponija-dep2"
      columns={baseColumns}
      dataSource={[...dep2ObrazacStore.initialState.divlje_deponije]}
    />
  );
});
