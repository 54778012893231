import { FormInstance } from 'antd';
import { CheckboxChangeEvent } from 'antd/es/checkbox';
import { IGetCompany, IPostCompany } from 'modules/company/company.types';
import { IGetFacility, IPostFacility } from 'modules/facility/facility.types';

export interface ITipObrascaCreate {
  naziv: string;
  skraceni_naziv: TIPOVI_OBRAZACA;
  nivo: string;
  period_izvestavanja: string;
  zakljucan: boolean;
}

export interface ITipObrasca extends ITipObrascaCreate {
  id: string;
}

export interface ITipoviObrascaReduceNivoResult {
  preduzece: ITipObrascaConfig[];
  postrojenje: ITipObrascaConfig[];
}

export interface ITipoviObrazacaMap {
  [shortName: string]: ITipObrascaConfig | ITipObrasca;
}

export type ITipObrascaCheckboxHandler = (event: CheckboxChangeEvent, form: FormInstance) => void;

export interface ITipObrascaHelpers {
  name: TIPOVI_OBRAZACA;
  disabled?: (selectedTipovi: ITipObrascaConfig[]) => boolean;
  required?: (selectedTipovi: ITipObrascaConfig[]) => boolean;
  alert?: (selectedTipovi: ITipObrascaConfig[]) => string;
  selected?: (selectedTipovi: ITipObrascaConfig[]) => boolean;
  onChange?: ITipObrascaCheckboxHandler; // for setting state
}

export interface ISubmitFormObrasca {
  payload: any;
  postForm: (obj: any) => void;
  putForm: (obj: any) => void;
  deleteForm: (id: string) => void;
}

export interface ITipObrascaConfig extends ITipObrascaHelpers, ITipObrasca {}

// eslint-disable-next-line @typescript-eslint/naming-convention
export enum TIPOVI_OBRAZACA {
  // preduzece
  DEP1 = 'DEP1',
  DEP2 = 'DEP2',
  PTP2 = 'PTP2',
  PZV1 = 'PZV1',
  AAO1 = 'AAO1',
  AAO2 = 'AAO2',

  // postrojenje
  GIO1 = 'GIO1',
  GIO2 = 'GIO2',
  GIO3 = 'GIO3',
  GIO4 = 'GIO4',
  GIO5 = 'GIO5',
  DEO6_GIO6 = 'DEO6/GIO6',
  DKO = 'DKO',
  GVE = 'GVE',
  KOM1 = 'KOM1',
  VOC = 'VOC',
  OTV = 'OTV',

  // izvori zagadjenja
  OBRAZAC_1 = 'OBRAZAC 1',
  OBRAZAC_2 = 'OBRAZAC 2',
  OBRAZAC_2_GVE = 'OBRAZAC 2 / GVE',
  OBRAZAC_3 = 'OBRAZAC 3',
  OBRAZAC_3_OTV = 'OBRAZAC 3 / OTV',
  OBRAZAC_4 = 'OBRAZAC 4',
  OBRAZAC_5 = 'OBRAZAC 5',

  LRIZ_OBRAZAC_1 = 'LRIZ OBRAZAC 1',
  LRIZ_OBRAZAC_2 = 'LRIZ OBRAZAC 2',
  LRIZ_OBRAZAC_2_GVE = 'LRIZ OBRAZAC 2 / GVE',
  LRIZ_OBRAZAC_3 = 'LRIZ OBRAZAC 3',
  LRIZ_OBRAZAC_3_OTV = 'LRIZ OBRAZAC 3 / OTV',
  LRIZ_OBRAZAC_4 = 'LRIZ OBRAZAC 4',
  LRIZ_OBRAZAC_5 = 'LRIZ OBRAZAC 5',
  LRIZ_OBRAZAC_5_GIO1 = 'LRIZ OBRAZAC 5 / GIO1',
}

export const GIO2to5Keys: string[] = [
  TIPOVI_OBRAZACA.GIO2,
  TIPOVI_OBRAZACA.GIO3,
  TIPOVI_OBRAZACA.GIO4,
  TIPOVI_OBRAZACA.GIO5,
];

export const LRIZ_KEYS: string[] = [
  TIPOVI_OBRAZACA.LRIZ_OBRAZAC_1,
  TIPOVI_OBRAZACA.LRIZ_OBRAZAC_2,
  TIPOVI_OBRAZACA.LRIZ_OBRAZAC_3,
  TIPOVI_OBRAZACA.LRIZ_OBRAZAC_4,
  TIPOVI_OBRAZACA.LRIZ_OBRAZAC_5,
];

export const LRIZ_2_TO_5_KEYS: string[] = [
  TIPOVI_OBRAZACA.LRIZ_OBRAZAC_2,
  TIPOVI_OBRAZACA.LRIZ_OBRAZAC_3,
  TIPOVI_OBRAZACA.LRIZ_OBRAZAC_4,
  TIPOVI_OBRAZACA.LRIZ_OBRAZAC_5,
];

export const OBRAZAC_KEYS: string[] = [
  TIPOVI_OBRAZACA.OBRAZAC_1,
  TIPOVI_OBRAZACA.OBRAZAC_2,
  TIPOVI_OBRAZACA.OBRAZAC_3,
  TIPOVI_OBRAZACA.OBRAZAC_4,
  TIPOVI_OBRAZACA.OBRAZAC_5,
];

export const OBRAZAC_2_TO_5_KEYS: string[] = [
  TIPOVI_OBRAZACA.OBRAZAC_2,
  TIPOVI_OBRAZACA.OBRAZAC_3,
  TIPOVI_OBRAZACA.OBRAZAC_4,
  TIPOVI_OBRAZACA.OBRAZAC_5,
];

export type CustomTipoviObrazaca =
  | TIPOVI_OBRAZACA.LRIZ_OBRAZAC_5_GIO1
  | TIPOVI_OBRAZACA.OBRAZAC_3_OTV
  | TIPOVI_OBRAZACA.LRIZ_OBRAZAC_3_OTV
  | TIPOVI_OBRAZACA.OBRAZAC_2_GVE
  | TIPOVI_OBRAZACA.LRIZ_OBRAZAC_2_GVE;

export const CUSTOM_OBRASCI_KEYS: TIPOVI_OBRAZACA[] = [
  TIPOVI_OBRAZACA.LRIZ_OBRAZAC_5_GIO1,
  TIPOVI_OBRAZACA.OBRAZAC_3_OTV,
  TIPOVI_OBRAZACA.LRIZ_OBRAZAC_3_OTV,
  TIPOVI_OBRAZACA.OBRAZAC_2_GVE,
  TIPOVI_OBRAZACA.LRIZ_OBRAZAC_2_GVE,
];

export const OTKLJUCANI_OBRASCI: string[] = [TIPOVI_OBRAZACA.PZV1, TIPOVI_OBRAZACA.PTP2, TIPOVI_OBRAZACA.AAO1];

export interface ITableColumns {
  [key: string]: any;
}

export interface IOpsteInitial {
  preduzece_id: string;
  godina: string;
  postrojenje_id: string;
  preduzece: IPostCompany | IGetCompany;
  postrojenje: IPostFacility | IGetFacility;
}

export interface IHiddenTabs {
  [tabName: string]: boolean;
}

interface IObrazac1FiltersSameObrazac {
  lriz_obrazac_1: boolean;
  obrazac_1: boolean;
}
interface IObrazac2FiltersSameObrazac {
  obrazac_gve: boolean;
  obrazac_2: boolean;
  lriz_obrazac_2: boolean;
}
interface IObrazac3FiltersSameObrazac {
  otv: boolean;
  obrazac3_lriz: boolean;
  obrazac3: boolean;
}
interface IObrazac4FiltersSameObrazac {
  lriz_obrazac_4: boolean;
  obrazac_4: boolean;
}

interface IObrazac5FiltersSameObrazac {
  lriz_obrazac_5: boolean;
  obrazac_5: boolean;
  obrazac_gio1: boolean;
}

export type IFiltersSameObrazac =
  | IObrazac1FiltersSameObrazac
  | IObrazac2FiltersSameObrazac
  | IObrazac3FiltersSameObrazac
  | IObrazac4FiltersSameObrazac
  | IObrazac5FiltersSameObrazac
  | null;

export enum ObrazacPeriodIzvestavanja {
  DNEVNO = 'dnevno',
  GODISNJE = 'godisnje',
}

export interface IZakljucaneGodinePoTipuObrasca {
  godina: number;
  zakljucana_godina: boolean;
}

export interface OpsteProvera {
  preduzece_id: string;
  tip_obrasca_id: string;
  datum?: string;
  godina?: number | string;
  postrojenje_id?: string;
  izvor_zagadjenja_vazduha_id?: string;
  izvor_zagadjenja_vode_id?: string;
  izvor_zagadjenja_tla_id?: string;
  indeksni_broj_otpada_id?: string;
  nesanitarna_deponija_id?: boolean;
}
