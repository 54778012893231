import { Content } from 'components/Content/Content';
import { observer } from 'mobx-react-lite';
import { zakljucavanjeStore } from 'modules/zakljucavanje/zakljucavanje.store';
import { useEffect } from 'react';
import { ZakljucavanjePoGodiniHeader } from './components/ZakljucavanjePoGodiniHeader/ZakljucavanjePoGodiniHeader';
import { ZakljucavanjePoGodiniObrasci } from './components/ZakljucavanjePoGodiniObrasci/ZakljucavanjePoGodiniObrasci';
import { ZakljucavanjeGodinaIzuzeciTable } from './components/ZakljucavanjeGodinaIzuzeciTable/ZakljucavanjeGodinaIzuzeciTable';
import { ZakljucavanjeGodinaIzuzeciModal } from './components/ZakljucavanjeGodinaIzuzeciModal/ZakljucavanjeGodinaIzuzeciModal';
import styles from './ZakljucavanjePoGodini.module.scss';
import { paginationInitialState } from 'modules/nriz/nriz.constants';

export const ZakljucavanjePoGodini = observer(() => {
  useEffect(() => {
    zakljucavanjeStore.fetchGodine();

    return () => {
      zakljucavanjeStore.setSelectedGodina(null);
      zakljucavanjeStore.setPagination(paginationInitialState);
    };
  }, []);

  return (
    <Content header={<ZakljucavanjePoGodiniHeader />}>
      {zakljucavanjeStore.selectedGodina ? (
        <div className={styles.zakljucavanjeGodinaWrapper}>
          <ZakljucavanjePoGodiniObrasci />
          <ZakljucavanjeGodinaIzuzeciTable />
          <ZakljucavanjeGodinaIzuzeciModal />
        </div>
      ) : (
        <h2>Izaberite godinu </h2>
      )}
    </Content>
  );
});
