import styles from './LogoWithForm.module.scss';
import { useEffect } from 'react';
import { observer } from 'mobx-react-lite';
import { Logo } from 'components/Logo/Logo';
import { userStore } from 'modules/user/user.store';
import { UserFormType } from 'modules/user/user.types';

type LogoWithFormProps = {
  component: JSX.Element;
  title: string;
};

export const LogoWithForm: React.FC<LogoWithFormProps> = observer(props => {
  useEffect(() => {
    userStore.setUserTypeForm(UserFormType.UserRegistration);
    return () => userStore.setUserTypeForm(null);
  }, []);

  return (
    <div className={styles.sectionWrapper}>
      <h1 className={styles.title}>{props.title}</h1>
      <Logo withPasus={true} />
      <div className={styles.componentWrapper}>{props.component}</div>
    </div>
  );
});
