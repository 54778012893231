import { ColumnsType } from 'antd/es/table';
import { EditButtonProps, RowEditPreviewButton } from 'components/RowEditPreviewButton/RowEditPreviewButton';
import TableData from 'components/TableData/TableData';
import { observer } from 'mobx-react-lite';
import { obrasciStore } from 'modules/obrasci/obrasci.store';
import { gveStore } from 'modules/obrasci/store/gve/gve.store';
import { IGetGorivaGve } from 'modules/obrasci/store/gve/gve.types';
import { useTranslation } from 'react-i18next';

const EditGveGorivaButton: React.FC<EditButtonProps<IGetGorivaGve>> = observer(({ record }) => {
  return (
    <RowEditPreviewButton
      name="gve-goriva"
      modalName="gve-gorivo-modal"
      onClick={() => gveStore.handleChange('goriva_form', record)}
      preview={obrasciStore.zakljucanObrazac}
    />
  );
});

export const GveGorivaTable: React.FC = observer(() => {
  const { t } = useTranslation();

  const baseColumns: ColumnsType<IGetGorivaGve> = [
    {
      title: `${t(['OBRASCI.GVE.GORIVO'])}`,
      width: 100,
      dataIndex: ['naziv_goriva', 'name_sr'],
      key: 'name_sr',
    },
    {
      title: `${t(['OBRASCI.GVE.UKUPNA_GODISNJA_POTROSNJA'])}`,
      width: 100,
      dataIndex: 'ukupna_godisnja_potrosnja',
      key: 'ukupna_godisnja_potrosnja',
    },

    {
      title: `${t('COMPANY.ADDITIONALLY')}`,
      key: 'operation',
      fixed: 'right',
      width: 150,
      align: 'center',
      render: (_, record: IGetGorivaGve) => <EditGveGorivaButton record={record} />,
    },
  ];

  //// storemyyyy TODO
  return (
    <TableData
      scrollConfig={['#obrazacTableHeader', '#obrazacWizardHeader']}
      name="gorivo-gve"
      columns={baseColumns}
      dataSource={[...gveStore.initialState.goriva]}
    />
  );
});
