import { Navigate, Route, Routes, useLocation } from 'react-router-dom';
import { authStore } from 'store';
import { RoutePathEnum, routes } from './router.constants';
import { observer } from 'mobx-react-lite';
import { flattenChildren } from 'utils/flattenChildren';
import RouteGuard from './RouteGuard';
import SidebarAnt from 'components/SidebarAnt/SidebarAnt';
import { UserRoles } from 'modules/user/user.types';
import { companyStore } from 'modules/company/company.store';
import { IGetCompany } from 'modules/company/company.types';

export const RoutesList: React.FC = observer(() => {
  const { pathname } = useLocation();

  const roleArray = authStore.roles;

  if (!roleArray.length && !(pathname === RoutePathEnum.REGISTRACIJA)) authStore.keycloak.login();

  const flatRoutes = flattenChildren(routes);

  const REDIRECT_ROUTES: { [key: string | UserRoles]: string } = {
    [UserRoles.SuperAdmin]: RoutePathEnum.LISTA_PREDUZECA,
    [UserRoles.SystemAdmin]: RoutePathEnum.LISTA_PREDUZECA,
    [UserRoles.CompanyOwner]: `${RoutePathEnum.PREDUZECE}/${(companyStore.company as IGetCompany)?.id}`,
    [UserRoles.CompanyUser]: `${RoutePathEnum.PREDUZECE}/${(companyStore.company as IGetCompany)?.id}`,
    [UserRoles.FacilityUser]: `${RoutePathEnum.PREDUZECE}/${(companyStore.company as IGetCompany)?.id}`,
  };

  return (
    <>
      {!(pathname.includes(RoutePathEnum.OBRAZAC) || pathname.includes(RoutePathEnum.POCETNA_STRANA_KORISNIK)) && (
        <SidebarAnt />
      )}
      <Routes>
        {flatRoutes.map(route => {
          if (!route.children)
            return (
              <Route
                path={route.path}
                element={<RouteGuard allowedRoles={route.allowedRoles}>{route.element}</RouteGuard>}
                key={route.key}
              />
            );
        })}
        {roleArray.length && (
          <Route
            path="*"
            element={
              <RouteGuard allowedRoles={roleArray}>
                <Navigate to={REDIRECT_ROUTES[roleArray[0]]} />
              </RouteGuard>
            }
          />
        )}
      </Routes>
    </>
  );
});
