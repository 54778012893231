import React from 'react';
import { observer } from 'mobx-react-lite';
import { TableNaseljeDep1 } from '../TableNaseljeDep1/TableNaseljeDep1';
import { InsideContentWrapper } from 'components/InsideContentWrapper/InsideContentWrapper';
import styles from './GradoviNaseljeWrapper.module.scss';
import { WizardObrazacListHeader } from 'components/WizardObrazacListHeader/WizardObrazacListHeader';
import { dep1Store } from 'modules/obrasci/store/dep1/dep1.store';
import { useTranslation } from 'react-i18next';

const HeaderNaseljeDep1: React.FC = observer(() => {
  const { t } = useTranslation();
  const hasError = dep1Store.initialState.obrazac_dep1_mesta.length === 0;

  return (
    <WizardObrazacListHeader
      classNameTitle={hasError ? styles.naselje_error : ''}
      title={hasError ? t('OBRASCI.DEP1.MORA_IMATI_MESTO') : ''}
      name={'dep1-naselja'}
      modalName={'naselje-modal'}
    />
  );
});

export const GradoviNaseljeWrapper: React.FC = observer(() => {
  return (
    <InsideContentWrapper header={<HeaderNaseljeDep1 />} className={styles.wrapperTable}>
      <TableNaseljeDep1 />
    </InsideContentWrapper>
  );
});
