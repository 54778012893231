import { Form } from 'antd';
import { FormInputNumber } from 'components/FormInputNumber/FormInputNumber';
import { FormSearchInfiniteScroll } from 'components/FormSearchInfiniteScroll/FormSearchInfiniteScroll';
import { ICrudTypeModalProps } from 'components/Modals/CRUDModal/CRUDModal';
import Decimal from 'decimal.js';
import { observer } from 'mobx-react-lite';
import {
  MORE_OR_EQUILE_0_IS_REQUIRED,
  MORE_THEN_0_IS_REQUIRED,
  REQUIRED_FIELD_RULE,
} from 'modules/nriz/nriz.constants';
import { ptp2ObrazacStore } from 'modules/obrasci/store/ptp2/ptp2.store';
import { IGetProizvod } from 'modules/obrasci/store/ptp2/ptp2.types';
import { ISifarnikInitial } from 'modules/sifarnici/sifarnici.types';
import { IGetProductsType } from 'modules/sifarnici/store/productsNr/productsNr.types';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

export const NewProizvodiForm: React.FC<ICrudTypeModalProps> = observer(({ form }) => {
  const { t } = useTranslation();

  const [isUlje, setisUlje] = useState(false);
  const [productTypeObj, setProductTypeObj] = useState<IGetProductsType>();

  useEffect(() => {
    const productNrId = form.getFieldValue('products_nr_id');
    if (productNrId) {
      ptp2ObrazacStore.handleChange('filterProductsTypeId', productNrId);
      setisUlje(ptp2ObrazacStore.checkIfUlje(productNrId));
    }
  }, [Form.useWatch('products_nr_id', form)]);

  useEffect(() => {
    const productTypeId = form.getFieldValue('products_type_id');
    if (productTypeId) {
      setProductTypeObj(ptp2ObrazacStore.findProductTypeObj(productTypeId));
    }
  }, [Form.useWatch('products_type_id', form)]);

  useEffect(() => {
    const kolicinaTrziste = new Decimal(form.getFieldValue('kolicina_proizvoda_stavljenih_na_trziste') ?? 0);
    if (isUlje && kolicinaTrziste) {
      const kolicinaZaUlja = kolicinaTrziste.dividedBy(9).toNumber();
      form.setFieldValue('ulje_kolicine', kolicinaTrziste.plus(kolicinaZaUlja).toNumber());
    }
  }, [Form.useWatch('kolicina_proizvoda_stavljenih_na_trziste', form)]);

  const readOnly = ptp2ObrazacStore.readOnly;

  useEffect(() => {
    return () => ptp2ObrazacStore.clearReadOnly();
  }, []);

  const defaultProductOption: ISifarnikInitial | undefined = ptp2ObrazacStore.getProizvodi.products_nr_id
    ? {
        label: (ptp2ObrazacStore.getProizvodi as IGetProizvod).products_nr.display_product_category,
        value: (ptp2ObrazacStore.getProizvodi as IGetProizvod).products_nr_id,
      }
    : undefined;

  const defaultProductTypeOption: ISifarnikInitial | undefined = ptp2ObrazacStore.getProizvodi.products_type_id
    ? {
        label: (ptp2ObrazacStore.getProizvodi as IGetProizvod)?.products_type?.display_product,
        value: (ptp2ObrazacStore.getProizvodi as IGetProizvod).products_type_id,
      }
    : undefined;

  return (
    <>
      <FormSearchInfiniteScroll
        showSearch={true}
        formName={'products_nr_id'}
        fetchOptinsList={ptp2ObrazacStore.fetchProductsNr}
        labelCol={{ span: 12 }}
        labelOptionAccessor="display_product_category"
        valueOptionAccessor="id"
        placeholder={t('OBRASCI.PTP2.VRSTE_PROIZVODA_PO_PERIODIMA')}
        label={t('OBRASCI.PTP2.VRSTE_PROIZVODA_PO_PERIODIMA')}
        inputName={'products_nr_id'}
        rules={[REQUIRED_FIELD_RULE(true)]}
        colon={true}
        notFoundContent={t('OBRASCI.PTP2.NE_POSTOJI_ZA_GODINU')}
        defaultOption={defaultProductOption}
        onChange={() => {
          form.resetFields([
            'products_type_id',
            'kolicina_proizvoda_stavljenih_na_trziste',
            'kolicina_proizvoda',
            'ulje_kolicine',
            'pdv',
          ]);
        }}
        readOnly={readOnly}
      />
      <FormSearchInfiniteScroll
        showSearch={true}
        formName={'products_type_id'}
        fetchOptinsList={ptp2ObrazacStore.fetchProductsTypes}
        labelCol={{ span: 12 }}
        labelOptionAccessor="display_product"
        valueOptionAccessor="id"
        placeholder={t('OBRASCI.PTP2.NAZIV_PROIZVODA')}
        label={t('OBRASCI.PTP2.NAZIV_PROIZVODA')}
        inputName={'products_type_id'}
        colon={true}
        notFoundContent={t('OBRASCI.PTP2.NE_POSTOJI_ZA_GODINU')}
        disabled={!ptp2ObrazacStore.filterProductsTypeId}
        resetOption={ptp2ObrazacStore.filterProductsTypeId}
        defaultOption={defaultProductTypeOption}
        onChange={(_: any) => {
          form.resetFields(['kolicina_proizvoda', 'ulje_kolicine', 'pdv']);
        }}
        rules={[
          REQUIRED_FIELD_RULE(true),
          {
            validator(_, value) {
              if (
                ptp2ObrazacStore.proizvodi.products_type_id !== value &&
                ptp2ObrazacStore.initialState.proizvodi.some(item => {
                  return value === item.products_type_id;
                })
              ) {
                return Promise.reject(new Error(t('OBRASCI.PTP2.VALIDATION.NE_MOZE_BITI_ISTI_PROIZVOD')));
              }
              return Promise.resolve();
            },
          },
        ]}
        readOnly={readOnly}
      />

      {/* todo prevod */}
      {isUlje && (
        <FormInputNumber
          label={t('Uneti količinu u litrima a u polju ispod će Vam biti prikazana količina u kg')}
          labelCol={{ span: 12 }}
          name="ulje_kolicine"
          onInputChange={value => {
            form.setFieldValue('kolicina_proizvoda_stavljenih_na_trziste', new Decimal(value ?? 0).mul(0.9).toNumber());
          }}
          inputName="ulje_kolicine"
          rules={MORE_THEN_0_IS_REQUIRED}
          precision={productTypeObj?.unit?.toLowerCase() === 'kom' ? 0 : undefined}
          readOnly={readOnly}
        />
      )}

      <h4 style={{ fontWeight: 900 }}>{t('OBRASCI.PTP2.KOLICINA_STAVLJENIH_NA_TRZISTE_PORUKA')}</h4>
      <FormInputNumber
        label={t('OBRASCI.PTP2.KOLICINA_PROIZVODA_STAVLJENIH_NA_TRZISTE')}
        labelCol={{ span: 12 }}
        name="kolicina_proizvoda_stavljenih_na_trziste"
        inputName="kolicina_proizvoda_stavljenih_na_trziste"
        type="number"
        rules={MORE_THEN_0_IS_REQUIRED}
        disabled={isUlje || !ptp2ObrazacStore.getDozvoljeneKorekcije}
        precision={productTypeObj?.unit?.toLowerCase() === 'kom' ? 0 : undefined}
        readOnly={readOnly}
      />
      {productTypeObj?.unit?.toLowerCase() !== 'kg' && (
        <>
          <h4 style={{ fontWeight: 900 }}>{t('OBRASCI.PTP2.KOLICINA_PROIZVODA_KG_PORUKA')}</h4>
          <FormInputNumber
            label={t('OBRASCI.PTP2.KOLICINA_PROIZVODA_KG')}
            labelCol={{ span: 12 }}
            name="kolicina_proizvoda"
            inputName="kolicina_proizvoda"
            precision={productTypeObj?.unit?.toLowerCase() === 'kom' ? 0 : undefined}
            type="number"
            rules={MORE_THEN_0_IS_REQUIRED}
            readOnly={readOnly}
          />
        </>
      )}

      {productTypeObj?.currencyor === '%' && (
        <>
          <h4 style={{ fontWeight: 900 }}>{t('OBRASCI.PTP2.KOLICINA_PDV_PORUKA')}</h4>
          <FormInputNumber
            label={t('OBRASCI.PDV')}
            labelCol={{ span: 12 }}
            name="pdv"
            inputName="pdv"
            type="number"
            rules={MORE_OR_EQUILE_0_IS_REQUIRED}
            readOnly={readOnly}
          />
        </>
      )}
    </>
  );
});
