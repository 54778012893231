import { Form } from 'antd';
import { observer } from 'mobx-react-lite';
import { useForm } from 'antd/es/form/Form';
import { FC, useEffect, useMemo } from 'react';

import { IzvestajiSubtypes } from 'modules/obrazacIzvestaji/obrazacIzvestaji.types';
import { FilterTableForm } from 'components/TableData/FilterTableForm/FilterTableForm';
import { OBRAZAC_IZVESTAJI_FILTERS_MAP } from 'modules/obrazacIzvestaji/obrazacIzvestajiFilters.constants';

import { IFilterTableProps } from 'typescript/NrizTypes';
import { obrazacIzvestajiStore } from 'modules/obrazacIzvestaji/obrazacIzvestaji.store';
import { paginationInitialState } from 'modules/nriz/nriz.constants';

export interface IObrazacIzvestajiFiltersParams {
  izvestajSubtype: IzvestajiSubtypes;
}

export const ObrazacIzvestajiFilters: FC<IObrazacIzvestajiFiltersParams> = observer(({ izvestajSubtype }) => {
  const [izvestajiForm] = useForm();

  const filtersByIzvestajType = useMemo(() => {
    return izvestajSubtype ? OBRAZAC_IZVESTAJI_FILTERS_MAP[izvestajSubtype](izvestajiForm) : [];
  }, [Form.useWatch([], izvestajiForm), izvestajSubtype]);

  // Should work without this if resetForm below works
  useEffect(() => {
    izvestajiForm.resetFields();
  }, [izvestajSubtype]);

  const fetchIzvestaji = () => {
    return obrazacIzvestajiStore.getObrazacIzvestaj(izvestajSubtype, paginationInitialState);
  };

  const filterConfig: IFilterTableProps = {
    form: izvestajiForm,
    initialState: {}, // todo: Set initial state when implementing query params state binding
    filterName: 'obrazacIzvestaji',
    dontAddIlike: true,
    resetForm: izvestajSubtype,
    filterOptions: filtersByIzvestajType,
    fetchTableList: fetchIzvestaji,
  } as IFilterTableProps;

  return <FilterTableForm data={filterConfig} />;
});
