import { Col, Form, Row, message } from 'antd';
import { observer } from 'mobx-react-lite';
import { otvStore } from 'modules/registri/otv/otv.store';
import { useEffect } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { IOTVRegistar } from 'modules/registri/otv/otv.types';
import { Content } from 'components/Content/Content';
import styles from './OTVRegistar.module.scss';
import { Button } from 'components/Button/Button';
import { REGISTRI_CONFIG } from 'modules/registri/registri.constants';
import { OTV_INITIAL_STATE } from 'modules/registri/otv/otv.constants';
import { OTVRegistarForm } from './OTVRegistarForm/OTVRegistarForm';
import { RegistriEnum } from 'modules/registri/registri.types';

export const OTVRegistar: React.FC = observer(() => {
  const { id } = useParams();

  const [form] = Form.useForm();

  const { t } = useTranslation();
  //todo: prevod
  t;

  const navigate = useNavigate();

  useEffect(() => {
    if (!id || id === 'novi') return;
    otvStore.loadData(id);
  }, []);

  useEffect(() => {
    return () => otvStore.resetStates();
  }, []);

  const OtvHeader = () => {
    return (
      <Row className={styles.otvHeader}>
        <Col span={8}>
          <h1>OTV Registar</h1>
        </Col>
        <Col className={styles.otvSubmitButtons}>
          <Button
            name="otv-back"
            className="ant-btn-dangerous"
            onClick={() => {
              navigate(REGISTRI_CONFIG[RegistriEnum.Otv].routes.urlRoute);
            }}
          >
            Nazad
          </Button>
          <Button name="otv-submit" htmlType="submit">
            {t('SUBMIT')}
          </Button>
        </Col>
      </Row>
    );
  };

  const handleFormSubmit = async (payload: IOTVRegistar) => {
    if (!id) return;
    try {
      if (id === 'novi') {
        await otvStore.postData(payload);
        message.success('Uspešno dodat OTV!');
      } else {
        await otvStore.putData({ ...payload, id });
        message.success('Uspešno izmenjen OTV!');
      }
      form.resetFields();
    } catch (error) {
      message.error('Došlo je do greške!');
    } finally {
      navigate(REGISTRI_CONFIG[RegistriEnum.Otv].routes.urlRoute);
    }
  };

  // probaj da izbegnes ovaj useeffect tako sto ces setovati initialValues forme

  useEffect(() => {
    form.setFieldsValue({ ...otvStore.otvRegistar });
  }, [otvStore.otvRegistar]);

  return (
    <Form
      form={form}
      onFinish={handleFormSubmit}
      initialValues={otvStore.otvRegistar ?? OTV_INITIAL_STATE}
      labelCol={{ span: 12 }}
      wrapperCol={{ span: 12 }}
      labelAlign="left"
    >
      <Content header={<OtvHeader />} className={styles.otvFormWrapper}>
        <OTVRegistarForm />
      </Content>
    </Form>
  );
});
