import { FormInputNumber } from 'components/FormInputNumber/FormInputNumber';
import { FormSearchInfiniteScroll } from 'components/FormSearchInfiniteScroll/FormSearchInfiniteScroll';

import { observer } from 'mobx-react-lite';
import { MORE_THEN_0_IS_REQUIRED, REQUIRED_FIELD_RULE } from 'modules/nriz/nriz.constants';
import { obrasciStore } from 'modules/obrasci/obrasci.store';
import { gio4ObrazacStore } from 'modules/obrasci/store/gio4/gio4.store';
import { IGio4RazrediEeOtpada } from 'modules/obrasci/store/gio4/gio4.types';
import { ISifarnikInitial } from 'modules/sifarnici/sifarnici.types';
import { IGetWeeeCategories } from 'modules/sifarnici/store/weeeCategories/weeeCategories.types';
import React from 'react';
import { useTranslation } from 'react-i18next';

export const NewGio4ElektricniOtpadForm: React.FC = observer(() => {
  const { t } = useTranslation();

  const defaultWeeeCategory: ISifarnikInitial | undefined = gio4ObrazacStore.getRazrediEeForm.razred_ee_otpada_id
    ? {
        value: gio4ObrazacStore.getRazrediEeForm.razred_ee_otpada_id,
        label: `${(gio4ObrazacStore.getRazrediEeForm as IGio4RazrediEeOtpada).razred_ee_otpada.the_order} ${
          (gio4ObrazacStore.getRazrediEeForm as IGio4RazrediEeOtpada).razred_ee_otpada.category_name_sr
        }`,
      }
    : undefined;

  const weeeCategoriesOptionMaker = (options: IGetWeeeCategories[]) => {
    return options.map(weeeCategory => ({
      label: `${weeeCategory.the_order} ${weeeCategory.category_name_sr}`,
      value: weeeCategory.id,
    }));
  };

  const readOnly = obrasciStore.zakljucanObrazac;

  return (
    <>
      <FormSearchInfiniteScroll
        showSearch={true}
        formName={'razred_ee_otpada_id'}
        fetchOptinsList={gio4ObrazacStore.fetchWeeeCategoriesList}
        labelOptionAccessor="category_name_sr"
        valueOptionAccessor="id"
        rules={[
          REQUIRED_FIELD_RULE(true),
          {
            validator(_, value) {
              if (
                gio4ObrazacStore.getRazrediEeForm.razred_ee_otpada_id !== value &&
                gio4ObrazacStore.izvozOtpada.razredi_ee_otpada.some(item => {
                  return value === item.razred_ee_otpada_id;
                })
              ) {
                return Promise.reject(new Error(t('OBRASCI.GIO4.VALIDATION.EE_EXISTS')));
              }
              return Promise.resolve();
            },
          },
        ]}
        inputName={'razred_ee_otpada_id'}
        label={t('OBRASCI.GIO_3.CLASS_EE_WASTE')}
        colon={true}
        defaultOption={defaultWeeeCategory}
        placeholder={''}
        optionsMaker={weeeCategoriesOptionMaker}
        readOnly={readOnly}
      />
      <FormInputNumber
        name="kolicina"
        label={t('OBRASCI.GIO_3.QUANTITY_T')}
        rules={MORE_THEN_0_IS_REQUIRED}
        placeholder={''}
        inputName={'kolicina'}
        colon={true}
        readOnly={readOnly}
      />
    </>
  );
});
