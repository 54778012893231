import { computed, makeAutoObservable, observable } from 'mobx';
import {
  IGetKolicine,
  IGetNaselja,
  IGetPredato,
  IGetSastav,
  IGetUpravljanje,
  IKolicine,
  IKom1ObrazacResponse,
  INaselja,
  IPostKom1Obraza,
  IPostPredato,
  ISastav,
  IUpravljanje,
} from './kom1.types';
import {
  INDEX_NUMBER_IN,
  KOLICINA_INITIAL_STATE,
  KOM1_INITIAL_STATE,
  NASELJE_INITIAL_STATE,
  PREDATO_INITIAL_STATE,
  SASTAV_INITIAL_STATE,
  UPRAVLJANJE_INITIAL_STATE,
} from './kom1.constants';
import { IBaseObrazacStore, IPaginatedResponse, IPagination } from 'typescript/NrizTypes';
import { obrasciService } from 'modules/obrasci/obrasci.service';
import { TIPOVI_OBRAZACA } from 'modules/obrasci/obrasci.types';
import to from 'await-to-js';
import dayjs from 'dayjs';
import { SIFARNICI_ROUTES } from 'modules/sifarnici/sifarnici.types';

import { formStore } from 'store';
import { nrizService } from 'modules/nriz/nriz.service';

import { sifarniciStore } from 'modules/sifarnici/sifarnici.store';
import { INITIAL_RESPONSE_SIFARNIK } from 'modules/sifarnici/sifarnici.constants.tsx';
import { obrasciStore } from 'modules/obrasci/obrasci.store';
import { handleSuccessNotify } from 'utils/succesHandling';
import { IGetCompositionCommunalWaste } from 'modules/sifarnici/store/compositionCommunalWaste/compositionCommunalWaste.types';
import { IGetDRList } from 'modules/sifarnici/store/dRList/dRList.types';
import { IGetKom1TypeOfWaste } from 'modules/sifarnici/store/kom1TypeOfWaste/kom1TypeOfWaste.types';
import { IGetMesto } from 'modules/sifarnici/store/mesto/mesto.types';
import { IGetMethodBasisCode } from 'modules/sifarnici/store/methodBasisCode/methodBasisCode.types';
import { IGetWasteCatalogue } from 'modules/sifarnici/store/wasteCatalogue/wasteCatalogue.types';
import { IGetWayOfHandOver } from 'modules/sifarnici/store/wayOfHandOver/wayOfHandOver.types';
import { IGetMunicipalityCode } from 'modules/sifarnici/store/municipalityCode/municipalityCode.types';

class Kom1ObrazacStore implements IBaseObrazacStore<IKom1ObrazacResponse, IPostKom1Obraza> {
  initialState: IPostKom1Obraza | IKom1ObrazacResponse = KOM1_INITIAL_STATE;
  naselja_form: INaselja | IGetNaselja = NASELJE_INITIAL_STATE;
  upravljanje_form: IUpravljanje | IGetUpravljanje = UPRAVLJANJE_INITIAL_STATE;
  sastav_form: ISastav | IGetSastav = SASTAV_INITIAL_STATE;
  kolicine_form: IKolicine | IGetKolicine = KOLICINA_INITIAL_STATE;
  predato_form: IPostPredato | IGetPredato = PREDATO_INITIAL_STATE;

  opstina_id = '';

  // SIFARNICI

  constructor() {
    makeAutoObservable(this, {
      initialState: observable,
      naselja_form: observable,
      upravljanje_form: observable,
      sastav_form: observable,
      kolicine_form: observable,
      opstina_id: observable,

      getNaseljeForm: computed,
      getUpravljanjeForm: computed,
      getSastavForm: computed,
      getKolicineForm: computed,
      getInitialState: computed,
      getPredatoForm: computed,
    });
  }

  get getNaseljeForm() {
    return this.naselja_form;
  }
  get getUpravljanjeForm() {
    return this.upravljanje_form;
  }
  get getSastavForm() {
    return this.sastav_form;
  }
  get getKolicineForm() {
    return this.kolicine_form;
  }

  get getInitialState() {
    return this.initialState;
  }
  get getPredatoForm() {
    return this.predato_form;
  }

  handleChange(key: string, value: any) {
    (this as any)[key] = value;
  }
  handleUpravljanje(key: string, value: any) {
    (this as any).upravljanje_form[key] = value;
  }

  handleInitialStateChange(key: string, value: any) {
    (this as any).initialState[key] = value;
  }

  loadData = async (id: string) => {
    const [err, res] = await to(obrasciService.fetchSingleObrazac<IKom1ObrazacResponse>(TIPOVI_OBRAZACA.KOM1, id));
    if (err) return;
    const newGodina = dayjs().year((res.godina as any) * 1);
    this.handleChange('opstina_id', res.opstina_id);
    this.handleChange('initialState', { ...res, godina: newGodina });
    return res;
  };

  setData?: ((res: IKom1ObrazacResponse) => void) | undefined;

  async postData(payload: IPostKom1Obraza) {
    const [err, res] = await to(
      obrasciService.postSingleObrazac<IPostKom1Obraza>(TIPOVI_OBRAZACA.KOM1, nrizService.setEmptyValuesToNull(payload))
    );
    if (err || !res) return;
    formStore.handleChange('goBack', true);
    handleSuccessNotify();
  }

  putData = async (payload: IKom1ObrazacResponse) => {
    const [err, res] = await to(
      obrasciService.putSingleObrazac<IKom1ObrazacResponse>(
        TIPOVI_OBRAZACA.KOM1,
        payload.id,
        nrizService.setEmptyValuesToNull(payload)
      )
    );
    if (err || !res) return;
    formStore.handleChange('goBack', true);
    handleSuccessNotify();
  };
  async deleteData(id: string) {
    const [err, res] = await to(obrasciService.deleteSingleObrazac(TIPOVI_OBRAZACA.DEP2, id));
    if (err || !res) return;
    formStore.handleChange('goBack', true);
    handleSuccessNotify();
  }

  resetStates = () => {
    this.handleChange('opstina_id', '');
    this.handleChange('initialState', KOM1_INITIAL_STATE);
  };

  submitData = (payload: IPostKom1Obraza) => {
    const newInitialState = {
      ...KOM1_INITIAL_STATE,
      ...payload,
      id: (this.initialState as IKom1ObrazacResponse).id,
      godina: obrasciStore.opste.godina,
      naselja: this.initialState.naselja,
      kolicine: this.initialState.kolicine,
      sastav: this.initialState.sastav,
      upravljanje: this.initialState.upravljanje,
    } as IKom1ObrazacResponse;

    obrasciService.submitFormObrazac({
      payload: newInitialState,
      postForm: this.postData,
      putForm: this.putData,
      deleteForm: this.deleteData,
    });
  };

  // OPSTE

  fetchMunicipalityCode = async (
    pagination: IPagination,
    search: string
  ): Promise<IPaginatedResponse<IGetMunicipalityCode>> => {
    const response = await sifarniciStore.fetchSifarnikOptions<IGetMunicipalityCode>(
      SIFARNICI_ROUTES.MUNICIPALITY_CODE,
      {
        page: pagination.page,
        size: pagination.size,
        search: search,
        order_by: '+name',
      }
    );
    return response;
  };
  // NASELJE

  submitNaseljeForm = (payload: INaselja) => {
    let newPayload = payload;
    let sifarnikNaselje = {};
    if (formStore.getCrudButtonType === 'submit' || formStore.getCrudButtonType === 'change') {
      if (newPayload.mesto_id) {
        sifarnikNaselje = nrizService.addSifarnikInObject({
          exId: (this.naselja_form as IGetNaselja).mesto_id,
          exObj: (this.naselja_form as IGetNaselja).mesto,
          list: sifarniciStore.list[SIFARNICI_ROUTES.MESTO],
          name: 'mesto',
          id: payload.mesto_id,
        });
      }
    }
    newPayload = { id: (this.naselja_form as IGetNaselja).id, ...newPayload, ...sifarnikNaselje } as IGetNaselja;
    const newList = obrasciService.submitMicroServiceForm(this.initialState.naselja, newPayload);
    this.handleInitialStateChange('naselja', newList);
    this.handleChange('naselja_form', NASELJE_INITIAL_STATE);
  };

  fetchMesto = async (pagination: IPagination, search: string): Promise<IPaginatedResponse<IGetMesto>> => {
    if (!this.opstina_id) return INITIAL_RESPONSE_SIFARNIK;

    const response = await sifarniciStore.fetchSifarnikOptions<IGetMesto>(SIFARNICI_ROUTES.MESTO, {
      page: pagination.page,
      size: pagination.size,
      municipality_code_id: this.opstina_id,
      search: search,
      location_code__neq: 999999,
      order_by: '+display_name',
    });
    return response;
  };

  // KOLICINA

  submitKolicinaForm = (payload: IKolicine) => {
    let newPayload = payload;
    let sifarnikVrstaOtpada = {};

    if (formStore.getCrudButtonType === 'submit' || formStore.getCrudButtonType === 'change') {
      if (newPayload.kom1_type_of_waste_id) {
        sifarnikVrstaOtpada = nrizService.addSifarnikInObject({
          exId: (this.kolicine_form as IGetKolicine).kom1_type_of_waste_id,
          exObj: (this.kolicine_form as IGetKolicine).kom1_type_of_waste,
          list: sifarniciStore.list[SIFARNICI_ROUTES.KOM1_TYPE_OF_WASTE],
          name: 'kom1_type_of_waste',
          id: payload.kom1_type_of_waste_id,
        });
      }
    }

    newPayload = { ...this.kolicine_form, ...newPayload, ...sifarnikVrstaOtpada };
    const newList = obrasciService.submitMicroServiceForm(this.initialState.kolicine, newPayload);
    // handleSuccessNotify();
    this.handleInitialStateChange('kolicine', newList);
    this.handleChange('kolicine_form', KOLICINA_INITIAL_STATE);
  };

  fetchKom1TypeOfWaste = async (
    pagination: IPagination,
    search: string
  ): Promise<IPaginatedResponse<IGetKom1TypeOfWaste>> => {
    const response = await sifarniciStore.fetchSifarnikOptions<IGetKom1TypeOfWaste>(
      SIFARNICI_ROUTES.KOM1_TYPE_OF_WASTE,
      {
        page: pagination.page,
        size: pagination.size,
        search: search,
        order_by: '+type_of_waste_sr',
      }
    );
    return response;
  };

  //  UPRAVLJANJE

  submitUpravljanjeForm = (payload: IUpravljanje) => {
    let newPayload = payload;
    let sifarnikKatalogOtpad = {};
    let sifarnikNacinOdredjivanjaKolicine = {};

    if (formStore.getCrudButtonType === 'submit' || formStore.getCrudButtonType === 'change') {
      if (newPayload.waste_catalogue_id) {
        sifarnikKatalogOtpad = nrizService.addSifarnikInObject({
          exId: (this.upravljanje_form as IGetUpravljanje).waste_catalogue_id,
          exObj: (this.upravljanje_form as IGetUpravljanje).waste_catalogue,
          list: sifarniciStore.list[SIFARNICI_ROUTES.WASTE_CATALOGUE],
          name: 'waste_catalogue',
          id: payload.waste_catalogue_id,
        });
      }
      if (newPayload.method_basis_code_id) {
        sifarnikNacinOdredjivanjaKolicine = nrizService.addSifarnikInObject({
          exId: (this.upravljanje_form as IGetUpravljanje).method_basis_code_id,
          exObj: (this.upravljanje_form as IGetUpravljanje).method_basis_code,
          list: sifarniciStore.list[SIFARNICI_ROUTES.METHOD_BASIS_CODE],
          name: 'method_basis_code',
          id: payload.method_basis_code_id,
        });
      }
    }
    newPayload = {
      ...newPayload,
      ...sifarnikKatalogOtpad,
      ...sifarnikNacinOdredjivanjaKolicine,
      kom1_nacin_predaje_otpada: this.upravljanje_form.kom1_nacin_predaje_otpada,
      id: (this.upravljanje_form as IGetUpravljanje).id,
    } as IGetUpravljanje;

    const newList = obrasciService.submitMicroServiceForm(this.initialState.upravljanje, newPayload);
    // handleSuccessNotify();
    this.handleInitialStateChange('upravljanje', newList);
    this.handleChange('upravljanje_form', UPRAVLJANJE_INITIAL_STATE);
  };

  submitPredatoForm = (payload: IPostPredato) => {
    let newPayload = payload;
    let sifarnikNacinPredaje = {};
    let sifarnikDr = {};
    if (newPayload.way_of_hand_over_id) {
      sifarnikNacinPredaje = nrizService.addSifarnikInObject({
        exId: (this.predato_form as IGetPredato).way_of_hand_over_id,
        exObj: (this.predato_form as IGetPredato).way_of_hand_over,
        list: sifarniciStore.list[SIFARNICI_ROUTES.WAY_OF_HAND_OVER],
        name: 'way_of_hand_over',
        id: payload.way_of_hand_over_id,
      });
    }
    if (newPayload.dr_list_id) {
      sifarnikDr = nrizService.addSifarnikInObject({
        exId: (this.predato_form as IGetPredato).dr_list_id,
        exObj: (this.predato_form as IGetPredato).dr_list,
        list: sifarniciStore.list[SIFARNICI_ROUTES.DR_LIST],
        name: 'dr_list',
        id: newPayload.dr_list_id,
      });
    }
    newPayload = {
      ...newPayload,
      ...sifarnikNacinPredaje,
      ...sifarnikDr,
      id: (this.predato_form as IGetPredato).id,
    } as IGetPredato;

    const newList = obrasciService.submitMicroServiceForm(
      this.upravljanje_form.kom1_nacin_predaje_otpada,
      newPayload,
      true,
      'predato-kom1-modal'
    );
    this.handleUpravljanje('kom1_nacin_predaje_otpada', newList);
    this.handleChange('predato_form', PREDATO_INITIAL_STATE);
  };

  fetchWasteCatalougeList = async (
    pagination: IPagination,
    search: string
  ): Promise<IPaginatedResponse<IGetWasteCatalogue>> => {
    const response = await sifarniciStore.fetchSifarnikOptions<IGetWasteCatalogue>(SIFARNICI_ROUTES.WASTE_CATALOGUE, {
      page: pagination.page,
      size: pagination.size,
      search: search,
      index_number__in: INDEX_NUMBER_IN,
      order_by: '+index_number',
      is_active: true,
    });
    return response;
  };

  fetchWayOfHandOver = async (
    pagination: IPagination,
    search: string
  ): Promise<IPaginatedResponse<IGetWayOfHandOver>> => {
    const response = await sifarniciStore.fetchSifarnikOptions<IGetWayOfHandOver>(SIFARNICI_ROUTES.WAY_OF_HAND_OVER, {
      page: pagination.page,
      size: pagination.size,
      search: search,
      order_by: '+name_sr',
    });
    return response;
  };

  fetchMethodBasisCode = async (
    pagination: IPagination,
    search: string
  ): Promise<IPaginatedResponse<IGetMethodBasisCode>> => {
    const response = await sifarniciStore.fetchSifarnikOptions<IGetMethodBasisCode>(
      SIFARNICI_ROUTES.METHOD_BASIS_CODE,
      {
        page: pagination.page,
        size: pagination.size,
        search: search,
        /// nema order ide name_sr
      }
    );
    return response;
  };

  fetchDrList = async (pagination: IPagination, search: string): Promise<IPaginatedResponse<IGetDRList>> => {
    const response = await sifarniciStore.fetchSifarnikOptions<IGetDRList>(SIFARNICI_ROUTES.DR_LIST, {
      search: search,
    });
    return response;
  };

  // SASTAV

  submitSastavForm = (payload: ISastav) => {
    let newPayload = payload;
    let sifarnikSastavKomunalnogOtpada = {};

    if (formStore.getCrudButtonType === 'submit' || formStore.getCrudButtonType === 'change') {
      if (newPayload.composition_communal_waste_id) {
        sifarnikSastavKomunalnogOtpada = nrizService.addSifarnikInObject({
          exId: (this.sastav_form as IGetSastav).composition_communal_waste_id,
          exObj: (this.sastav_form as IGetSastav).composition_communal_waste,
          list: sifarniciStore.list[SIFARNICI_ROUTES.COMPOSITION_COMMUNAL_WASTE],
          name: 'composition_communal_waste',
          id: payload.composition_communal_waste_id,
        });
      }
    }
    newPayload = {
      id: (this.sastav_form as IGetSastav).id,
      ...newPayload,
      ...sifarnikSastavKomunalnogOtpada,
    } as IGetSastav;

    const newList = obrasciService.submitMicroServiceForm(this.initialState.sastav, newPayload);
    // handleSuccessNotify();
    this.handleInitialStateChange('sastav', newList);
    this.handleChange('sastav_form', SASTAV_INITIAL_STATE);
  };

  fetchCompositionCommunalWaste = async (
    pagination: IPagination,
    search: string
  ): Promise<IPaginatedResponse<IGetCompositionCommunalWaste>> => {
    const response = await sifarniciStore.fetchSifarnikOptions<IGetCompositionCommunalWaste>(
      SIFARNICI_ROUTES.COMPOSITION_COMMUNAL_WASTE,
      {
        page: pagination.page,
        size: pagination.size,
        search: search,
        version__ilike: Number(obrasciStore.opste.godina) > 2024 ? '2' : '1',
        order_by: '+name_sr',
      }
    );
    return response;
  };
}

export const kom1ObrazacStore = new Kom1ObrazacStore();
