import { Col } from 'antd';
import { FormInput } from 'components/FormInput/FormInput';
import { FormSearchInfiniteScroll } from 'components/FormSearchInfiniteScroll/FormSearchInfiniteScroll';
import { ICrudTypeModalProps } from 'components/Modals/CRUDModal/CRUDModal';
import { observer } from 'mobx-react-lite';
import React from 'react';
import {
  EMAIL_FIELD_RULE,
  MAX_LENGTH_RULE,
  MORE_THEN_0,
  REQUIRED_FIELD_RULE,
  checkIfItemExistsInList,
  invalidCharactersForInputNumber,
} from 'modules/nriz/nriz.constants';
import { useTranslation } from 'react-i18next';
import { aao2Store } from 'modules/obrasci/store/aao2/aao2.store';
import { ISifarnikInitial } from 'modules/sifarnici/sifarnici.types';
import to from 'await-to-js';
import { AAO2_PRAVNO_LICE_FIELDS } from 'modules/obrasci/store/aao2/aao2.constants';
import { IGetCompany } from 'modules/company/company.types';
import { FormInputNumber } from 'components/FormInputNumber/FormInputNumber';
import { obrasciStore } from 'modules/obrasci/obrasci.store';

export const NewAao2PreduzetnikPravnoLiceForm: React.FC<ICrudTypeModalProps> = observer(({ form }) => {
  const { t } = useTranslation();

  const defaultCompanyOption: ISifarnikInitial | undefined = aao2Store.pravnoLiceIliPreduzetnik.naziv
    ? {
        label: (aao2Store.pravnoLiceIliPreduzetnik as IGetCompany).naziv,
        value: (aao2Store.pravnoLiceIliPreduzetnik as IGetCompany).naziv,
      }
    : undefined;

  const defaultMestoOption: ISifarnikInitial | undefined = aao2Store.pravnoLiceIliPreduzetnik.mesto_id
    ? {
        label: (aao2Store.pravnoLiceIliPreduzetnik as IGetCompany).mesto?.display_name,
        value: aao2Store.pravnoLiceIliPreduzetnik.mesto_id,
      }
    : undefined;

  const defaultPreteznaDelatnostOption: ISifarnikInitial | undefined = aao2Store.pravnoLiceIliPreduzetnik
    .sifarnik_pretezne_delatnosti_id
    ? {
        label: (aao2Store.pravnoLiceIliPreduzetnik as IGetCompany).sifarnik_pretezne_delatnosti?.name_sr,
        value: aao2Store.pravnoLiceIliPreduzetnik.sifarnik_pretezne_delatnosti_id,
      }
    : undefined;

  // treba prepraviti da se ne salje search nego odvojeno pib, maticni, naziv_preduzeca...

  const handleCompanyFields = async (search: string, type: string) => {
    const [err, res] = await to(aao2Store.fetchApr(search, type));
    if (err || !res) return;
    AAO2_PRAVNO_LICE_FIELDS.forEach((fieldName: string) => {
      if (res[fieldName]) form.setFieldValue(`${fieldName}`, res[fieldName]);
    });
  };

  const readOnly = obrasciStore.zakljucanObrazac;

  return (
    <Col span={24}>
      <FormInputNumber
        inputName={'pib'}
        name={'pib'}
        label={t('COMPANY.PIB')}
        colon={true}
        rules={[
          REQUIRED_FIELD_RULE(true),
          {
            validator(_, value) {
              if (value) {
                const stringInput = value.toString();
                if (stringInput.length !== 9 || value < 0)
                  return Promise.reject(new Error(t('VALIDATION.IS_NOT_VALID_PIB')));
              }
              return Promise.resolve();
            },
          },
          checkIfItemExistsInList({
            previousValue: aao2Store.pravnoLiceIliPreduzetnik.pib,
            listItemAccessor: 'pib',
            list: aao2Store.initialState.pravno_lice_ili_preduzetnik,
          }),
        ]}
        labelCol={{ span: 9 }}
        disabled={aao2Store.pravnoLiceIliPreduzetnik.pib !== ''}
        onInputChange={e => {
          const stringInput = e.toString();
          if (stringInput.length === 9) {
            handleCompanyFields(stringInput, 'pib');
          }
        }}
        invalidCharacters={invalidCharactersForInputNumber}
        readOnly={readOnly}
      />
      <FormInputNumber
        inputName={'maticni_broj'}
        name={'maticni_broj'}
        label={t('COMPANY.ENTERPRISE_ID_NUMBER')}
        colon={true}
        rules={[
          REQUIRED_FIELD_RULE(true),
          {
            validator(_, value) {
              if (value) {
                const stringInput = value.toString();
                if (stringInput.length !== 8 || value < 0)
                  return Promise.reject(new Error(t('VALIDATION.IS_NOT_VALID_IDENTIFICATION_NUMBER')));
              }
              return Promise.resolve();
            },
          },
          checkIfItemExistsInList({
            previousValue: aao2Store.pravnoLiceIliPreduzetnik.maticni_broj,
            listItemAccessor: 'maticni_broj',
            list: aao2Store.initialState.pravno_lice_ili_preduzetnik,
          }),
        ]}
        labelCol={{ span: 9 }}
        disabled={aao2Store.pravnoLiceIliPreduzetnik.maticni_broj !== ''}
        onInputChange={e => {
          const stringInput = e.toString();

          if (stringInput.length === 8) {
            handleCompanyFields(stringInput, 'maticni_broj');
          }
        }}
        invalidCharacters={invalidCharactersForInputNumber}
        readOnly={readOnly}
      />
      <FormSearchInfiniteScroll
        showSearch={true}
        formName={'naziv'}
        label={t('COMPANY.FULL_NAME')}
        labelCol={{ span: 9 }}
        labelOptionAccessor={'naziv'}
        valueOptionAccessor={'naziv'}
        fetchOptinsList={aao2Store.fetchCompaniesListWithSearch}
        placeholder={''}
        rules={[
          REQUIRED_FIELD_RULE(true),
          checkIfItemExistsInList({
            previousValue: aao2Store.pravnoLiceIliPreduzetnik.naziv,
            listItemAccessor: 'naziv',
            list: aao2Store.initialState.pravno_lice_ili_preduzetnik,
          }),
        ]}
        onChange={value => {
          handleCompanyFields(value, 'naziv_preduzeca');
        }}
        defaultOption={defaultCompanyOption}
        disabled={aao2Store.pravnoLiceIliPreduzetnik.naziv !== ''}
        readOnly={readOnly}
      />
      <FormSearchInfiniteScroll
        showSearch={true}
        formName={'mesto_id'}
        label={t('COMPANY.LOCATION')}
        labelCol={{ span: 9 }}
        labelOptionAccessor={'display_name'}
        valueOptionAccessor={'id'}
        fetchOptinsList={aao2Store.fetchMestoOptions}
        placeholder={''}
        rules={[REQUIRED_FIELD_RULE(true)]}
        defaultOption={defaultMestoOption}
        readOnly={readOnly}
      />
      <FormInputNumber
        inputName={'postanski_broj'}
        name={'postanski_broj'}
        label={t('COMPANY.POST_NUMBER')}
        colon={true}
        rules={[
          {
            validator(_, value) {
              if (value) {
                const stringInput = value.toString();
                if (stringInput.length !== 5 || value < 0) return Promise.reject(new Error('Mora imati 5 cifara'));
              }
              return Promise.resolve();
            },
          },
          ...MORE_THEN_0,
        ]}
        labelCol={{ span: 9 }}
        invalidCharacters={invalidCharactersForInputNumber}
        readOnly={readOnly}
      />
      <FormInput
        inputName={'ulica'}
        name={'ulica'}
        label={t('COMPANY.ADDRESS')}
        colon={true}
        rules={[MAX_LENGTH_RULE(50)]}
        labelCol={{ span: 9 }}
        readOnly={readOnly}
      />
      <FormInput
        inputName={'kucni_broj'}
        name={'kucni_broj'}
        label={t('COMPANY.ADDRESS_NUMBER')}
        colon={true}
        rules={[MAX_LENGTH_RULE(20)]}
        labelCol={{ span: 9 }}
        readOnly={readOnly}
      />
      <FormInput
        inputName={'telefon'}
        name={'telefon'}
        label={t('COMPANY.PHONE')}
        colon={true}
        rules={[REQUIRED_FIELD_RULE(true)]}
        type="text"
        labelCol={{ span: 9 }}
        readOnly={readOnly}
      />
      <FormInput
        inputName={'email'}
        name={'email'}
        label={t('COMPANY.EMAIL')}
        colon={true}
        rules={[EMAIL_FIELD_RULE]}
        labelCol={{ span: 9 }}
        readOnly={readOnly}
      />
      <FormSearchInfiniteScroll
        showSearch={true}
        formName={'sifarnik_pretezne_delatnosti_id'}
        label={t('COMPANY.PRINCIPAL_BUSINESS_ACTIVITY_CODE')}
        labelCol={{ span: 9 }}
        labelOptionAccessor={'name_sr'}
        valueOptionAccessor={'id'}
        rules={[REQUIRED_FIELD_RULE(true)]}
        defaultOption={defaultPreteznaDelatnostOption}
        fetchOptinsList={aao2Store.fetchPretezneDelatnosti}
        placeholder={''}
        readOnly={readOnly}
      />
    </Col>
  );
});
