import { ColumnsType } from 'antd/es/table';
import { EditButtonProps, RowEditPreviewButton } from 'components/RowEditPreviewButton/RowEditPreviewButton';
import TableData from 'components/TableData/TableData';
import { observer } from 'mobx-react-lite';
import { obrasciStore } from 'modules/obrasci/obrasci.store';
import { gio2ObrazacStore } from 'modules/obrasci/store/gio2/gio2.store';
import { IGetKolicineGio2, IGetRazrediEeOtpadaGio2 } from 'modules/obrasci/store/gio2/gio2.types';
import React from 'react';
import { useTranslation } from 'react-i18next';

const EditGio2RazrediEEButton: React.FC<EditButtonProps<IGetRazrediEeOtpadaGio2>> = observer(({ record }) => {
  return (
    <RowEditPreviewButton
      name="gio2-razredi-ee"
      modalName="gio2-razredi-ee-modal"
      onClick={() => gio2ObrazacStore.handleChange('razredi_ee_form', record)}
      preview={obrasciStore.zakljucanObrazac}
    />
  );
});

export const TableGio2RazrediEe: React.FC = observer(() => {
  const { t } = useTranslation();

  const baseColumns: ColumnsType<IGetRazrediEeOtpadaGio2> = [
    {
      title: `${t('OBRASCI.GIO_3.CLASS_EE_WASTE')}`,
      width: '200px',
      key: 'category_name',
      render: (_: any, record: IGetRazrediEeOtpadaGio2) => (
        <>{`${record.razred_ee_otpada.the_order} ${record.razred_ee_otpada.category_name_sr}`}</>
      ),
    },
    {
      title: `${t('OBRASCI.GIO_3.QUANTITY_T')}`,
      width: '150px',
      dataIndex: 'kolicina',
      key: 'kolicina',
    },
    {
      title: '',
      key: 'operation',
      align: 'center',
      fixed: 'right',
      width: '50px',
      render: (_, record: IGetRazrediEeOtpadaGio2) => <EditGio2RazrediEEButton record={record} />,
    },
  ];

  return (
    <TableData
      name="razredi-gio2"
      columns={baseColumns}
      dataSource={[...(gio2ObrazacStore.kolicine_form as IGetKolicineGio2).razredi_ee_otpada]}
    />
  );
});
