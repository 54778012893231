import { TIPOVI_OBRAZACA } from './obrasci.types';
import { dep2ObrazacStore } from './store/dep2/dep2.store';
import { gio2ObrazacStore } from './store/gio2/gio2.store';
import { gio3ObrazacStore } from './store/gio3/gio3.store';
import i18n from 'translations/i18n.config';
import { pzv1ObrazacStore } from './store/pzv1/pzv1.store';
import { aao1Store } from './store/aao1/aao1.store';
import { kom1ObrazacStore } from './store/kom1/kom1.store';
import { gveStore } from './store/gve/gve.store';
import { obrazac4Store } from './store/obrazac4/obrazac4.store';
import { deo6ObrazacStore } from './store/deo6/deo6.store';
import { gio4ObrazacStore } from './store/gio4/gio4.store';
import { gio5ObrazacStore } from './store/gio5/gio5.store';
import { obrasciStore } from './obrasci.store';
import { obrazac3Store } from './store/obrazac3/obrazac3.store';
import { ptp2ObrazacStore } from './store/ptp2/ptp2.store';
import { aao2Store } from './store/aao2/aao2.store';
import { dep1Store } from './store/dep1/dep1.store';
import { obrazac5Store } from './store/obrazac5/obrazac5.store';
import Decimal from 'decimal.js';
import { IGetObrazac5, IPostUpravljanjeObrazac5 } from './store/obrazac5/obrazac5.types';
import { IGetKolicine } from './store/kom1/kom1.types';

export interface IValidationObrazac {
  label: string;
  isValid: (obrazacPayload?: any) => boolean | null;
}

export const VALIDATION_OBRAZAC: {
  [key: string]: IValidationObrazac[];
} = {
  [TIPOVI_OBRAZACA.GIO2]: [
    {
      label: i18n.t('OBRASCI.VALIDATION.DODAJTE_DOZVOLU_ZA_UPRAVLJANJE'),
      isValid: () => gio2ObrazacStore.initialState.dozvole.length !== 0,
    },
    {
      label: i18n.t('OBRASCI.VALIDATION.DODATI_DEPONIJU_ZA_UPRAVLJANJE'),
      isValid: () => gio2ObrazacStore.initialState.deponije.length !== 0,
    },
    {
      label: i18n.t('OBRASCI.VALIDATION.DODATI_KOLICINU'),
      isValid: () => gio2ObrazacStore.initialState.kolicine.length !== 0,
    },
  ],
  [TIPOVI_OBRAZACA.GIO3]: [
    {
      label: i18n.t('OBRASCI.VALIDATION.DODAJTE_DOZVOLU_ZA_UPRAVLJANJE'),
      isValid: () => gio3ObrazacStore.initialState.dozvole.length !== 0,
    },
    {
      label: i18n.t('OBRASCI.VALIDATION.DODATI_POSTROJENJE'),
      isValid: () => gio3ObrazacStore.initialState.postrojenja.length !== 0,
    },
    {
      label: i18n.t('OBRASCI.VALIDATION.DODATI_KOLICINU'),
      isValid: () => gio3ObrazacStore.initialState.kolicine.length !== 0,
    },
  ],
  [TIPOVI_OBRAZACA.GIO4]: [
    {
      label: i18n.t('OBRASCI.VALIDATION.DODAJTE_DOZVOLU_ZA_UPRAVLJANJE'),
      isValid: () => gio4ObrazacStore.initialState.dozvole.length !== 0,
    },
    {
      label: i18n.t('OBRASCI.GIO4.VALIDATION.DODAJTE_IZVOZ'),
      isValid: () => gio4ObrazacStore.initialState.izvoz_otpada.length !== 0,
    },
  ],
  [TIPOVI_OBRAZACA.GIO5]: [
    {
      label: i18n.t('OBRASCI.VALIDATION.DODAJTE_DOZVOLU_ZA_UPRAVLJANJE'),
      isValid: () => gio5ObrazacStore.initialState.dozvole.length !== 0,
    },
    {
      label: i18n.t('Dodajte uvoz otpada'),
      isValid: () => gio5ObrazacStore.initialState.uvoz_otpada.length !== 0,
    },
  ],
  [TIPOVI_OBRAZACA.OBRAZAC_1]: [],
  [TIPOVI_OBRAZACA.DEP2]: [
    {
      label: i18n.t('OBRASCI.DEP_2.VALIDATION.DODAJ_DEPONIJU'),
      isValid: () => dep2ObrazacStore.initialState.divlje_deponije.length !== 0,
    },
  ],
  [TIPOVI_OBRAZACA.OBRAZAC_3]: [
    {
      label: i18n.t('OBRASCI.GVE.VALIDATION.KREIRAJ_BILANS'),
      isValid: () => {
        if (Number(obrasciStore.opste.godina) < 2023) {
          return true;
        } else if (typeof obrazac3Store.initialState.kreiranje_godisnjeg_bilansa === 'boolean') {
          return true;
        }
        return false;
      },
    },
    {
      label: i18n.t('OBRASCI.OBRAZAC3.VALIDACIJA_OTPADNE_VODE'),
      isValid: () => {
        if (Number(obrasciStore.opste.godina) >= 2023) {
          return true;
        } else if (obrazac3Store.initialState.analiza_otpadne_vode.length !== 0) {
          return true;
        }
        return false;
      },
    },
    {
      label: i18n.t('OBRASCI.OBRAZAC3.VALIDACIJA_OTPADNE_VODE_MERENJE'),
      isValid: () => {
        if (Number(obrasciStore.opste.godina) < 2023) {
          return true;
        }
        if (
          obrazac3Store.initialState.merenja_otpadne_vode.length !== 0 &&
          obrazac3Store.initialState.merenja_otpadne_vode.some(
            merenje => merenje.izmerene_vrednosti_otpadne_vode.length > 0
          )
        ) {
          return true;
        }
        return false;
      },
    },
  ],
  [TIPOVI_OBRAZACA.OBRAZAC_2]: [
    {
      label: i18n.t('OBRASCI.GVE.VALIDATION.MORA_IMATI_GORIVO'),
      isValid: () => {
        if (!gveStore.isIzvorZagadjenjaEnergetski) return true;
        else if (gveStore.initialState.goriva.length === 0) {
          return false;
        }
        return true;
      },
    },
    {
      label: i18n.t('OBRASCI.GVE.VALIDATION.VERIFIKUJ_MERENJE'),
      isValid: () => gveStore.izvorZagadjenjaObject.obaveza_merenja !== null,
    },
    {
      label: i18n.t('OBRASCI.GVE.VALIDATION.KREIRAJ_BILANS'),
      isValid: () => {
        if (!gveStore.showEmisijeUVazduh) return true;
        else if (typeof gveStore.initialState.kreiranje_godisnjeg_bilansa !== 'boolean') {
          return false;
        }
        return true;
      },
    },
  ],

  [TIPOVI_OBRAZACA.PZV1]: [
    {
      label: i18n.t('OBRASCI.VALIDATION.DODATI_JEDAN_PZV_PROIZVOD'),
      isValid: () =>
        pzv1ObrazacStore.initialState.deterdzenti.length !== 0 ||
        pzv1ObrazacStore.initialState.mineralna_djubriva.length !== 0 ||
        pzv1ObrazacStore.initialState.zastita_bilja.length !== 0,
    },
  ],
  [TIPOVI_OBRAZACA.AAO1]: [
    {
      label: 'Tabela u tabu Količina Ambalaže mora imati barem jedan unos',
      isValid: () => aao1Store.initialState.kolicina_ambalaze.length !== 0,
    },
    {
      label:
        'Za svaku vrstu ambalaže koja se ne prenosi na operatera u tabu Količina Ambalaže moraju se uneti odgovarajuće količine u tabu Upravljanje Ambalažnim Otpadom',
      isValid: () => {
        if (!aao1Store.isCompanyWithSelfManagment) return true;
        if (
          aao1Store.initialState.upravljanje_ambalaznim_otpadom.every(item => {
            const id = item.type_of_packing_id;
            const matchingObject = aao1Store.initialState.kolicina_ambalaze.find(obj => obj.type_of_packing_id === id);
            //@ts-ignore
            const povratna = Number(matchingObject.ukupna_kolicina_povratne_ambalaze)
              ? //@ts-ignore
                Number(matchingObject.ukupna_kolicina_povratne_ambalaze)
              : 0;
            //@ts-ignore
            const nepovratna = Number(matchingObject.ukupna_kolicina_nepovratne_ambalaze)
              ? //@ts-ignore
                Number(matchingObject.ukupna_kolicina_nepovratne_ambalaze)
              : 0;
            return Number(item?.kolicina_za_samostalno_upravljanje) <= povratna + nepovratna;
          })
        )
          return true;
        return false;
      },
    },
  ],
  [TIPOVI_OBRAZACA.DEP1]: [
    {
      label: i18n.t('OBRASCI.DEP1.MORA_IMATI_MESTO'),
      isValid: () => dep1Store.initialState.obrazac_dep1_mesta.length !== 0,
    },
  ],
  [TIPOVI_OBRAZACA.OBRAZAC_5]: [
    {
      label:
        'Ukupne količine se ne slažu. Računica: Godišnja količina proizvedenog otpada [ + ] Stanje privremenog skladišta na dan 1. januar [ - ] Zbir svih količina u tabeli upravljanje [ = ] Stanje privremenog skladišta na dan 31. decembra',
      isValid: (obrazacPayload: IGetObrazac5) => {
        let kolicineUpravljanja = new Decimal(0);
        obrazac5Store.initialState.upravljanje_otpadom?.map((item: IPostUpravljanjeObrazac5) => {
          if (item.kolicina_isporucenog_otpada_za_odlaganje) {
            kolicineUpravljanja = kolicineUpravljanja.plus(item.kolicina_isporucenog_otpada_za_odlaganje || 0);
          }
          if (item.kolicina_isporucenog_otpada_za_ponovno_iskoriscenje) {
            kolicineUpravljanja = kolicineUpravljanja.plus(
              item.kolicina_isporucenog_otpada_za_ponovno_iskoriscenje || 0
            );
          }
          if (item.kolicina_isporucenog_otpada_za_skladiste_otpada) {
            kolicineUpravljanja = kolicineUpravljanja.plus(item.kolicina_isporucenog_otpada_za_skladiste_otpada || 0);
          }
          if (item.kolicina_izvezenog_otpada) {
            kolicineUpravljanja = kolicineUpravljanja.plus(item.kolicina_izvezenog_otpada || 0);
          }
        });

        const racunica = new Decimal(obrazacPayload.godisnja_kolicina_proizvedenog_otpada || 0)
          .plus(obrazacPayload?.stanje_skladista_1_januar || 0)
          .minus(kolicineUpravljanja);

        const decembar = new Decimal(obrazacPayload?.stanje_skladista_31_decembar || 0);

        return racunica.toNumber() === decembar.toNumber();
      },
    },
  ],
  [TIPOVI_OBRAZACA.PTP2]: [
    {
      label: i18n.t('OBRASCI.PTP2.VALIDATION.MORA_IMATI_PROIZVOD'),
      isValid: () => ptp2ObrazacStore.initialState.proizvodi.length !== 0,
    },
  ],
  [TIPOVI_OBRAZACA.KOM1]: [
    {
      label: i18n.t('OBRASCI.KOM_1.VALIDATION.DODATI_NASELJE'),
      isValid: () => kom1ObrazacStore.initialState.naselja.length !== 0,
    },
    {
      label: i18n.t('OBRASCI.KOM_1.VALIDATION.DODATI_KOLICINU'),
      isValid: () => kom1ObrazacStore.initialState.kolicine.length !== 0,
    },
    {
      label: i18n.t('OBRASCI.KOM_1.VALIDATION.DODATI_SASTAV'),
      isValid: () => kom1ObrazacStore.initialState.sastav.length !== 0,
    },
    {
      label: i18n.t(
        'U delu "Količine" morate uneti količine za „Komunalni otpad iz domaćinstava, preduzeća i ustanova, osim kabastog otpada“'
      ),
      isValid: () => {
        return kom1ObrazacStore.initialState.kolicine.some(
          (item: IGetKolicine) => item.kom1_type_of_waste_id === '2b4e4233-86e9-49ff-838b-8079d656da5b'
        );
      },
    },
  ],
  [TIPOVI_OBRAZACA.DEO6_GIO6]: [
    {
      label: i18n.t('Morate uneti količine u tabelu skladište'),
      isValid: () => {
        const isFirst = deo6ObrazacStore.checkSkladisteTabVisible();
        if (!isFirst && deo6ObrazacStore.initialState.skladiste.length === 0) {
          return false;
        }
        return true;
      },
    },
    {
      label: i18n.t('OBRASCI.VALIDATION.DODATI_KOLICINU_NA_TAB'),
      isValid: () => {
        const isFirst = deo6ObrazacStore.checkSkladisteTabVisible();
        if (
          isFirst &&
          deo6ObrazacStore.initialState.predat_otpad.length === 0 &&
          deo6ObrazacStore.initialState.preuzet_otpad.length === 0 &&
          deo6ObrazacStore.initialState.ponovna_upotreba_otpada.length === 0
        ) {
          return false;
        }
        return true;
      },
    },
  ],
  [TIPOVI_OBRAZACA.OBRAZAC_4]: [
    {
      label: i18n.t('OBRASCI.OBRAZAC_4.VALIDATION.DODATI_EMISIJU'),
      isValid: () => obrazac4Store.initialState.emisije_u_tlo.length !== 0,
    },
  ],
  [TIPOVI_OBRAZACA.DKO]: [],
  [TIPOVI_OBRAZACA.AAO2]: [
    {
      label: i18n.t('OBRASCI.AAO2.VALIDATION.DODATI_STAVKU'),
      isValid: () => aao2Store.initialState.kolicina_ambalaze.length !== 0,
    },
    {
      label: i18n.t('OBRASCI.AAO2.VALIDATION.DODATI_STAVKU_PRAVNO_LICE'),
      isValid: () => aao2Store.initialState.pravno_lice_ili_preduzetnik.length !== 0,
    },
    // {
    //   label: i18n.t('OBRASCI.AAO2.VALIDATION.UNESI_ODGOVARAJUCU_KOLICINU'),

    //   isValid: () => {
    //     const isValid = aao2Store.initialState.kolicina_ambalaze.every(item => {
    //       const id = item.type_of_packing_id;
    //       const matchingObject = aao2Store.initialState.upravljanje_ambalaznim_otpadom.find(
    //         obj => obj.type_of_packing_id === id
    //       );
    //       const preuzetaKomunalni = matchingObject?.preuzeta_kolicina_komunalnog_otpada
    //         ? Number(matchingObject?.preuzeta_kolicina_komunalnog_otpada)
    //         : 0;
    //       const preuzetaNekomunalni = matchingObject?.preuzeta_kolicina_nekomunalnog_otpada
    //         ? Number(matchingObject?.preuzeta_kolicina_nekomunalnog_otpada)
    //         : 0;
    //       return (
    //         preuzetaKomunalni + preuzetaNekomunalni <=
    //         //@ts-ignore
    //         Number(item.ukupna_kolicina)
    //       );
    //     });
    //     return isValid;
    //   },
    // },
  ],
};
