import { useTranslation } from 'react-i18next';
import { FormTextArea } from 'components/FormTextArea/FormTextArea';

import style from './NapomenaPtp2.module.scss';
import { MAX_LENGTH_RULE } from 'modules/nriz/nriz.constants';
import { authStore } from 'store';

export const Ptp2NapomenaWizardTab = () => {
  const { t } = useTranslation();
  return (
    <div className={style.wizardPtpNapomena}>
      <FormTextArea
        label={t('WIZARD_TABS.NAPOMENA')}
        name="napomena"
        rows={6}
        labelCol={4}
        wrapperCol={20}
        rules={[MAX_LENGTH_RULE(3000)]}
      />
      {authStore.isAdmin && (
        <FormTextArea
          label={t('WIZARD_TABS.NAPOMENA_ADMINISTRATORA')}
          name="napomena_administratora"
          rows={6}
          labelCol={4}
          wrapperCol={20}
          rules={[MAX_LENGTH_RULE(3000)]}
        />
      )}
    </div>
  );
};
