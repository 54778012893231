import { ITipObrasca } from 'modules/obrasci/obrasci.types';

const storagePrefix = 'react_';

export enum StorageEnum {
  POSTROJENJE = 'postrojenje',
  PREDUZECE = 'preduzece',
  TIPOVI_OBRAZACA = 'tipovi_obrazaca',
}

const getTipoviObrazaca = (): ITipObrasca[] => {
  const storageTipovi = window.sessionStorage.getItem(`${storagePrefix}${StorageEnum.TIPOVI_OBRAZACA}`);
  if (storageTipovi) return JSON.parse(storageTipovi);
  return [];
};

const storage = {
  setItem: (key: string, value: string) => {
    return window.sessionStorage.setItem(`${storagePrefix}${key}`, value);
  },

  getItem: (key: string) => {
    return window.sessionStorage.getItem(`${storagePrefix}${key}`);
  },

  setData: (key: string, value: any | null) => {
    return window.sessionStorage.setItem(`${storagePrefix}${key}`, JSON.stringify(value));
  },

  getData: (key: string) => {
    return JSON.parse(window.sessionStorage.getItem(`${storagePrefix}${key}`) as string);
  },

  removeData: (key: string) => {
    return window.sessionStorage.removeItem(`${storagePrefix}${key}`);
  },

  getTipoviObrazaca,
};

export default storage;
