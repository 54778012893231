import { Col, Form, Popover, Row } from 'antd';
import React, { useEffect } from 'react';
import styles from './NewCompany.module.scss';
import { FormInput } from 'components/FormInput/FormInput';
import { useTranslation } from 'react-i18next';
import { observer } from 'mobx-react-lite';
import { IGetCompany, IPostCompany } from 'modules/company/company.types';
import { authStore, formStore } from 'store';

import {
  EMAIL_FIELD_RULE,
  IDENTIFICATION_NUMBER_FIELD_RULE,
  MAX_LENGTH_RULE,
  MORE_THEN_0,
  PIB_FIELD_RULE,
  POST_NUMBER_FIELD_RULE,
  REQUIRED_FIELD_RULE,
  invalidCharactersForInputNumber,
} from 'modules/nriz/nriz.constants';
import { FormInstance, useForm } from 'antd/es/form/Form';
import { FormSearchInfiniteScroll } from 'components/FormSearchInfiniteScroll/FormSearchInfiniteScroll';
import { FormTextArea } from 'components/FormTextArea/FormTextArea';
import { companyStore } from 'modules/company/company.store';
import { companyInitialState } from 'modules/company/company.constants';
import { IGetSifarnikPretezneDelatnosti } from 'modules/sifarnici/store/sifarnikPretezneDelatnosti/sifarnikPretezneDelatnosti.types';
import { useParams } from 'react-router-dom';

interface IPropsCompany {
  readOnly?: boolean;
  passForm?: (form: FormInstance<IPostCompany>) => void;
}

export const NewCompany: React.FC<IPropsCompany> = observer(props => {
  const [companyForm] = useForm();
  const { t } = useTranslation();

  const onFinishFailed = () => {
    const newValues = companyForm.validateFields().then(() => {
      companyForm.setFields(
        companyForm.getFieldsValue().reduce((acc: any, value: any, key: any) => {
          acc[key] = {
            value,
            error: [],
            rules: [],
          };
          return acc;
        }, {})
      );
    });
    return companyStore.submitCompany(newValues as any);
  };

  const pretezneOptionMaker = (options: IGetSifarnikPretezneDelatnosti[]) => {
    return options.map(option => ({
      label: `${option.code} ${option.name_sr}`,
      value: option.id,
    }));
  };

  useEffect(() => {
    if (props.passForm) {
      props.passForm(companyForm);
    }
    return companyForm.setFieldsValue({ ...companyStore.getCompany });
  }, [companyStore.company]);

  const defaultMestoOption = companyStore.company.mesto_id
    ? { label: (companyStore.company as IGetCompany).mesto.display_name, value: companyStore.company.mesto_id }
    : undefined;

  const defaultPreteznaDelatnostOption = companyStore.company.sifarnik_pretezne_delatnosti_id
    ? {
        value: companyStore.company.sifarnik_pretezne_delatnosti_id,
        label: `${(companyStore.company as IGetCompany).sifarnik_pretezne_delatnosti.code} ${
          (companyStore.company as IGetCompany).sifarnik_pretezne_delatnosti.name_sr
        }`,
      }
    : undefined;

  const { id } = useParams();

  return (
    <Form
      form={companyForm}
      initialValues={id ? companyStore.company : companyInitialState}
      name={'company'}
      onFinish={(values: IPostCompany) => {
        companyStore.submitCompany(values);
      }}
      labelCol={{ span: 11 }}
      wrapperCol={{ span: 13 }}
      labelAlign="left"
      className={styles.company_form}
      onFinishFailed={() => {
        if (formStore.crudButtonType === 'delete') {
          return onFinishFailed();
        }
      }}
    >
      <Row gutter={20}>
        <Col span={12}>
          <Popover trigger="hover" content={companyStore.company?.naziv} placement="topRight">
            <div>
              <FormInput
                name="naziv"
                rules={[REQUIRED_FIELD_RULE(!props.readOnly), MAX_LENGTH_RULE(200)]}
                type="text"
                placeholder={t('COMPANY.PLACEHOLDER.ENTER_NAME')}
                label={t('COMPANY.NAME')}
                inputName={'naziv'}
                colon={true}
                readOnly={props.readOnly}
              />
            </div>
          </Popover>
        </Col>
        <Col span={12}>
          <FormInput
            name="kraci_naziv"
            rules={[REQUIRED_FIELD_RULE(!props.readOnly), MAX_LENGTH_RULE(50)]}
            type="text"
            placeholder={t('COMPANY.PLACEHOLDER.ENTER_SHORT_NAME')}
            label={t('COMPANY.SHORT_NAME')}
            inputName={'kraci_naziv'}
            colon={true}
            readOnly={props.readOnly}
          />
        </Col>
        <Col span={12}>
          <FormInput
            name="pib"
            rules={[PIB_FIELD_RULE]}
            type="text"
            placeholder={t('COMPANY.PLACEHOLDER.ENTER_PIB')}
            label={t('COMPANY.PIB')}
            inputName={'pib'}
            colon={true}
            readOnly={props.readOnly}
            disabled={authStore.isSuperAdmin ? companyForm.getFieldValue('pib') : true}
          />
        </Col>
        <Col span={12}>
          <FormInput
            name="maticni_broj"
            rules={[IDENTIFICATION_NUMBER_FIELD_RULE]}
            type="number"
            placeholder={t('COMPANY.PLACEHOLDER.ENTER_IDENTIFICATION_NUMBER')}
            label={t('COMPANY.ENTERPRISE_ID_NUMBER')}
            inputName={'maticni_broj'}
            colon={true}
            readOnly={props.readOnly}
            disabled={authStore.isSuperAdmin ? companyForm.getFieldValue('maticni_broj') : true}
          />
        </Col>
        <Col span={12}>
          <FormInput
            name="telefon"
            rules={[REQUIRED_FIELD_RULE(!props.readOnly)]}
            type="text"
            readOnly={props.readOnly}
            placeholder="+381 60666555"
            label={t('COMPANY.PHONE')}
            inputName={'telefon'}
            colon={true}
            className={styles.removeArrow}
          />
        </Col>
        <Col span={12}>
          <FormInput
            label={t('Telefaks')}
            readOnly={props.readOnly}
            name={'telefax'}
            type="number"
            inputName={'telefax'}
            placeholder=""
            rules={[...MORE_THEN_0, MAX_LENGTH_RULE(50)]}
          />
        </Col>
        <Col span={12}>
          <FormInput
            name="email"
            rules={[REQUIRED_FIELD_RULE(!props.readOnly), EMAIL_FIELD_RULE, MAX_LENGTH_RULE(100)]}
            type="email"
            readOnly={props.readOnly}
            placeholder={t('COMPANY.PLACEHOLDER.ENTER_EMAIL')}
            label={t('COMPANY.EMAIL')}
            inputName={'email'}
            colon={true}
          />
        </Col>

        <Col span={12}>
          <FormSearchInfiniteScroll
            formName={'mesto_id'}
            fetchOptinsList={companyStore.fetchMesto}
            labelOptionAccessor="display_name"
            valueOptionAccessor="id"
            placeholder={t('COMPANY.PLACEHOLDER.ENTER_CITY')}
            label={t('COMPANY.LOCATION')}
            showSearch
            readOnly={props.readOnly}
            inputName={'mesto_id'}
            rules={[REQUIRED_FIELD_RULE(!props.readOnly)]}
            colon={true}
            defaultOption={defaultMestoOption}
          />
        </Col>
        <Col span={12}>
          <FormInput
            name="postanski_broj"
            type="number"
            rules={[REQUIRED_FIELD_RULE(!props.readOnly), POST_NUMBER_FIELD_RULE, ...MORE_THEN_0]}
            placeholder={t('COMPANY.PLACEHOLDER.ENTER_POST_NUMBER')}
            readOnly={props.readOnly}
            label={t('COMPANY.POST_NUMBER')}
            inputName={'postanski_broj'}
            colon={true}
            className={styles.removeArrow}
            invalidCharacters={invalidCharactersForInputNumber}
          />
        </Col>

        <Col span={12}>
          <FormInput
            name="ulica"
            rules={[REQUIRED_FIELD_RULE(!props.readOnly), MAX_LENGTH_RULE(50)]}
            type="text"
            readOnly={props.readOnly}
            placeholder={t('COMPANY.PLACEHOLDER.ENTER_ADDRESS')}
            label={t('COMPANY.ADDRESS')}
            inputName={'ulica'}
            colon={true}
          />
        </Col>
        <Col span={12}>
          <FormInput
            name="ime_odgovorne_osobe"
            rules={[REQUIRED_FIELD_RULE(!props.readOnly), MAX_LENGTH_RULE(20)]}
            type="text"
            placeholder={t('COMPANY.PLACEHOLDER.ENTER_USER_NAME')}
            readOnly={props.readOnly}
            label={t('COMPANY.USER_NAME')}
            inputName={'ime_odgovorne_osobe'}
            colon={true}
          />
        </Col>
        <Col span={12}>
          <FormInput
            name="kucni_broj"
            rules={[REQUIRED_FIELD_RULE(!props.readOnly), MAX_LENGTH_RULE(20)]}
            type="text"
            readOnly={props.readOnly}
            placeholder={t('COMPANY.PLACEHOLDER.ENTER_ADDRESS_NUMBER')}
            label={t('COMPANY.ADDRESS_NUMBER')}
            inputName={'kucni_broj'}
            colon={true}
            className={styles.removeArrow}
          />
        </Col>
        <Col span={12}>
          <FormInput
            name="prezime_odgovorne_osobe"
            rules={[REQUIRED_FIELD_RULE(!props.readOnly), MAX_LENGTH_RULE(20)]}
            type="text"
            readOnly={props.readOnly}
            placeholder={t('COMPANY.PLACEHOLDER.ENTER_USER_SURNAME')}
            label={t('COMPANY.USER_SURNAME')}
            inputName={'prezime_odgovorne_osobe'}
            colon={true}
          />
        </Col>
        <Col span={12}>
          <FormSearchInfiniteScroll
            formName={'sifarnik_pretezne_delatnosti_id'}
            fetchOptinsList={companyStore.fetchPretezneDelatnostiList}
            labelOptionAccessor="name_sr"
            showSearch
            valueOptionAccessor="id"
            placeholder={t('COMPANY.PLACEHOLDER.ENTER_PRINCIPAL_BUSINESS_ACTIVITY_CODE')}
            label={t('COMPANY.PRINCIPAL_BUSINESS_ACTIVITY_CODE')}
            readOnly={props.readOnly}
            inputName={'sifarnik_pretezne_delatnosti_id'}
            rules={[REQUIRED_FIELD_RULE(!authStore.isAdmin && !props.readOnly)]}
            colon={true}
            defaultOption={defaultPreteznaDelatnostOption}
            optionsMaker={pretezneOptionMaker}
          />
        </Col>
        <Col span={24}>
          <FormTextArea
            disabled={props.readOnly}
            labelCol={4}
            wrapperCol={20}
            name="napomena"
            rows={2}
            label={t('COMPANY.NOTE')}
          />
        </Col>
      </Row>
    </Form>
  );
});
