import { observer } from 'mobx-react-lite';
import { obrasciStore } from 'modules/obrasci/obrasci.store';
import { useEffect } from 'react';
import styles from './Gio2OpsteWizardTab.module.scss';
import { OpsteGodinaPreduzecePostrojenje } from 'pages/ObrazacWizard/components/components/OpsteGodinaPreduzecePostrojenje/OpsteGodinaPreduzecePostrojenje';
import { Col } from 'antd';
import { gio2ObrazacStore } from 'modules/obrasci/store/gio2/gio2.store';
import { useObrazacExistsCheck } from 'hooks/useObrazacExistsCheck';

export const Gio2OpsteWizardTab: React.FC = observer(() => {
  useEffect(() => {
    if (
      obrasciStore.opste.godina &&
      obrasciStore.opste.preduzece_id !== '' &&
      obrasciStore.opste.postrojenje_id !== ''
    ) {
      obrasciStore.setDisabledTabButton(false);
    } else obrasciStore.setDisabledTabButton(true);
  }, [obrasciStore.opste]);

  const checkIfObrazacExists = useObrazacExistsCheck();

  return (
    <Col span={12} className={styles.wrapper}>
      <OpsteGodinaPreduzecePostrojenje
        withPostrojenje={true}
        store={gio2ObrazacStore}
        onChangeGodina={() => checkIfObrazacExists()}
      />
    </Col>
  );
});
