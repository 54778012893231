import { ptp2Repo } from './ptp2.repo';
import { IGetPtp2Obrazac } from './ptp2.types';

class Ptp2Service {
  submitDozvoljeneKorekcije = (obrazacState: IGetPtp2Obrazac) => {
    const proizvodi = obrazacState.proizvodi.map(({ products_type_id, dozvoljena_korekcija_proizvoda }) => ({
      products_type_id,
      dozvoljena_korekcija_proizvoda,
    }));
    const { dozvoljene_korekcije, id } = obrazacState;

    const payload = { proizvodi, dozvoljene_korekcije };

    return ptp2Repo.submitDozvoljeneKorekcije(payload, id);
  };
}

export const ptp2Service = new Ptp2Service();
