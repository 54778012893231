import { ColumnsType } from 'antd/es/table';
import { EditButtonProps, RowEditPreviewButton } from 'components/RowEditPreviewButton/RowEditPreviewButton';
import TableData from 'components/TableData/TableData';
import { observer } from 'mobx-react-lite';
import { obrasciStore } from 'modules/obrasci/obrasci.store';
import { kom1ObrazacStore } from 'modules/obrasci/store/kom1/kom1.store';
import { IGetSastav } from 'modules/obrasci/store/kom1/kom1.types';
import { useTranslation } from 'react-i18next';

const EditSastavButton: React.FC<EditButtonProps<IGetSastav>> = observer(({ record }) => {
  return (
    <RowEditPreviewButton
      name="kom1-sastav"
      modalName="sastav-modal"
      onClick={() => kom1ObrazacStore.handleChange('sastav_form', record)}
      preview={obrasciStore.zakljucanObrazac}
    />
  );
});

export const TableSastav: React.FC = observer(() => {
  const { t } = useTranslation();
  // todo:prevod
  const baseColumns: ColumnsType<IGetSastav> = [
    {
      title: `${t('OBRASCI.KOM_1.COMPOSITION_OF_MUNICIPAL_WASTE')}`,
      width: '200px',
      dataIndex: ['composition_communal_waste', 'name_sr'],
      key: 'name_sr',
    },
    {
      title: `Prolećna analiza - ${t('OBRASCI.KOM_1.QUANTITY_FRACTIONS')}`,
      width: 100,
      dataIndex: 'prolecna_analiza',
      key: 'prolecna_analiza',
    },
    {
      title: `Letnja analiza - ${t('OBRASCI.KOM_1.QUANTITY_FRACTIONS')}`,
      dataIndex: 'letnja_analiza',
      key: 'letnja_analiza',
      width: 100,
    },
    {
      title: `Jesenja analiza - ${t('OBRASCI.KOM_1.QUANTITY_FRACTIONS')}`,
      dataIndex: 'jesenja_analiza',
      key: 'jesenja_analiza',
      width: 100,
    },
    {
      title: `Zimska analiza - ${t('OBRASCI.KOM_1.QUANTITY_FRACTIONS')}`,
      dataIndex: 'zimska_analiza',
      key: 'zimska_analiza',
      width: 100,
    },
    {
      title: `${t('Srednja godišnja vrednost (t)')}`,
      dataIndex: 'srednja_vrednost',
      key: 'srednja_vrednost',
      width: 100,
    },
    {
      title: ``,
      key: 'operation',
      fixed: 'right',
      width: '50px',
      align: 'center',
      render: (_, record: IGetSastav) => <EditSastavButton record={record} />,
    },
  ];

  return (
    <TableData
      scrollConfig={['#obrazacTableHeader', '#obrazacWizardHeader']}
      name="sastav-kom1"
      columns={baseColumns}
      dataSource={[...kom1ObrazacStore.initialState.sastav]}
    />
  );
});
