import { t } from 'i18next';
import { obrasciStore } from './obrasci.store';

import { FormInstance } from 'antd';
import {
  DateSifarniciFormProps,
  ISifarniciFormData,
  ISifarniciTypeOfForm,
  InputSifarniciFormProps,
  SIFARNICI_ROUTES,
  SearchScrollSifarniciFormProps,
  SwitchSifarniciFormProps,
} from 'modules/sifarnici/sifarnici.types';
import { TIPOVI_OBRAZACA } from './obrasci.types';
import { authStore } from 'store';

const formFieldNamesConnectedByPreduzece = [
  'postrojenje_id',
  'nesanitarna_deponija_id',
  'izvor_zagadjenja_vazduha_id',
  'izvor_zagadjenja_vode_id',
  'izvor_zagadjenja_tla_id',
  'indeksni_broj_otpada_id',
];

const PREDUZECE_FILTER_OBRAZAC = (form: FormInstance<any>): SearchScrollSifarniciFormProps => {
  return {
    type_of_form: 'searchScroll',
    label: t('COMPANY.TITLE'),
    formName: 'preduzece_id',
    fetchOptinsList: SIFARNICI_ROUTES.PREDUZECE_POSTROJENJE_TIP_OBRASCA_UPITNIK_PREDUZECE,
    labelOptionAccessor: ['naziv'],
    valueOptionAccessor: 'id',
    disabledAccessor: 'is_active',
    filtersForFetch: {
      tip_obrasca_id: obrasciStore.real_tip_obrasca_id,
    },
    readOnly: false,
    resetOption: obrasciStore.real_obrazac_type,
    sortName: 'naziv_preduzeca',
    onChange: value => {
      formFieldNamesConnectedByPreduzece.forEach(field => form.setFieldValue(field, ''));
      obrasciStore.handleOpste('preduzece_id', value);
    },
    hideInput: !authStore.isAdmin,
  };
};

const POSTROJENJE_FILTER_OBRAZAC = (form: FormInstance<any>): SearchScrollSifarniciFormProps => {
  return {
    type_of_form: 'searchScroll',
    filtersForFetch: {
      tip_obrasca_id: obrasciStore.real_tip_obrasca_id,
      preduzece_id: authStore.isAdmin ? form.getFieldValue('preduzece_id') : obrasciStore.opste.preduzece_id,
    },
    formName: 'postrojenje_id',
    fetchOptinsList: SIFARNICI_ROUTES.PREDUZECE_POSTROJENJE_TIP_OBRASCA_UPITNIK_POSTROJENJE,
    labelOptionAccessor: ['naziv_postrojenja'],
    valueOptionAccessor: 'id',
    resetOption: obrasciStore.opste.preduzece_id,
    label: t('FACILITY.NAME'),
    sortName: 'naziv_postrojenja',
    hideInput: !(
      obrasciStore.obrasciPoNivou.postrojenje.some(item => item.skraceni_naziv === obrasciStore.real_obrazac_type) &&
      authStore.isAdmin
    ),
  };
};

const NACIONALNI_ID_FILTER_DATA = (): InputSifarniciFormProps => {
  return {
    type_of_form: 'input',
    label: `${t('FACILITY.NACIONAL_ID')} postrojenja`,
    name: 'postrojenje__nacionalni_id',
    inputName: 'postrojenje__nacionalni_id',
    sortName: 'nacionalni_id',
    hideInput: !(
      authStore.isAdmin &&
      obrasciStore.obrasciPoNivou.postrojenje.some(item => item.skraceni_naziv === obrasciStore.real_obrazac_type)
    ),
  };
};

const ISTORIJSKI_ID_FILTER_DATA = (): InputSifarniciFormProps => {
  return {
    type_of_form: 'input',
    label: `${t('FACILITY.HISTORICAL_ID')} postrojenja`,
    name: 'postrojenje__istorijski_id',
    inputName: 'postrojenje__istorijski_id',
    sortName: 'postrojenje__istorijski_id',
    hideInput: !(
      authStore.isAdmin &&
      obrasciStore.obrasciPoNivou.postrojenje.some(item => item.skraceni_naziv === obrasciStore.real_obrazac_type)
    ),
  };
};

const NESANITARNA_DEPONIJA_FILTER_DATA = (form: FormInstance<any>): SearchScrollSifarniciFormProps => {
  return {
    type_of_form: 'searchScroll',
    filtersForFetch: {
      preduzece_id: authStore.isAdmin ? form.getFieldValue('preduzece_id') : obrasciStore.opste.preduzece_id,
    },
    formName: 'nesanitarna_deponija_id',
    fetchOptinsList: SIFARNICI_ROUTES.NESANITARNA_DEPONIJA,
    labelOptionAccessor: ['naziv'],
    valueOptionAccessor: 'id',
    disabledAccessor: 'is_active',
    resetOption: obrasciStore.opste.preduzece_id,
    label: `${t('UNSANITARY_LANDFILL.NAME')} nesanitarne deponije`,
    sortName: 'naziv_nesanitarne_deponije',
  };
};

const IZVOR_VAZDUH_FILTER_DATA = (form: FormInstance<any>): SearchScrollSifarniciFormProps => {
  return {
    type_of_form: 'searchScroll',
    filtersForFetch: {
      postrojenje_id: authStore.isAdmin ? form.getFieldValue('postrojenje_id') : obrasciStore.opste.postrojenje_id,
    },
    formName: 'izvor_zagadjenja_vazduha_id',
    fetchOptinsList: SIFARNICI_ROUTES.IZVOR_ZAGADJENJA_VAZDUHA,
    labelOptionAccessor: ['naziv_izvora'],
    valueOptionAccessor: 'id',
    disabledAccessor: 'is_active',
    resetOption: obrasciStore.opste.postrojenje_id,
    label: t('OBRASCI.GVE.IZVOR_ZAGADJIVANJA'),
    sortName: 'izvor_zagadjenja_vazduha',
  };
};

const BROJ_IZVOR_VAZDUH_FILTER_OBRAZAC: InputSifarniciFormProps = {
  type_of_form: 'input',
  label: t('POLLUTION.AIR.SOURCE_NUMBER'),
  name: 'broj_izvora',
  inputName: 'broj_izvora',
  sortName: 'broj_izvora',
};

const IZVOR_VODA_FILTER_DATA = (form: FormInstance<any>): SearchScrollSifarniciFormProps => {
  return {
    type_of_form: 'searchScroll',
    filtersForFetch: {
      postrojenje_id: authStore.isAdmin ? form.getFieldValue('postrojenje_id') : obrasciStore.opste.postrojenje_id,
    },
    formName: 'izvor_zagadjenja_vode_id',
    fetchOptinsList: SIFARNICI_ROUTES.IZVOR_ZAGADJENJA_VODE,
    labelOptionAccessor: ['naziv_ispusta'],
    valueOptionAccessor: 'id',
    disabledAccessor: 'is_active',
    resetOption: obrasciStore.opste.postrojenje_id,
    label: t('POLLUTION.WATER.TITLE'),
    sortName: 'izvor_zagadjenja_vode',
  };
};

const BROJ_ISPUST_VODA_FILTER_OBRAZAC: InputSifarniciFormProps = {
  type_of_form: 'input',
  label: t('POLLUTION.WATER.SEWER_NUMBER'),
  name: 'broj_ispusta',
  inputName: 'broj_ispusta',
  sortName: 'broj_ispusta',
};

const IZVOR_TLO_FILTER_DATA = (form: FormInstance<any>): SearchScrollSifarniciFormProps => {
  return {
    type_of_form: 'searchScroll',
    filtersForFetch: {
      postrojenje_id: authStore.isAdmin ? form.getFieldValue('postrojenje_id') : obrasciStore.opste.postrojenje_id,
    },
    formName: 'izvor_zagadjenja_tla_id',
    fetchOptinsList: SIFARNICI_ROUTES.IZVOR_ZAGADJENJA_VODE,
    labelOptionAccessor: ['naziv_ispusta'],
    valueOptionAccessor: 'id',
    disabledAccessor: 'is_active',
    resetOption: obrasciStore.opste.postrojenje_id,
    label: t('POLLUTION.SOIL.TITLE'),
    sortName: 'izvor_zagadjenja_tla',
    placeholder: '',
  };
};

const BROJ_LOKACIJE_TLO_FILTER_OBRAZAC: InputSifarniciFormProps = {
  type_of_form: 'input',
  label: t('POLLUTION.SOIL.LOCATION_NUMBER'),
  name: 'broj_lokacije',
  inputName: 'broj_lokacije',
  sortName: 'broj_lokacije',
};

const BROJ_DOKUMENTA_FILTER_OBRAZAC: InputSifarniciFormProps = {
  type_of_form: 'input',
  label: 'Broj dokumenta',
  name: 'broj_dokumenta',
  inputName: 'broj_dokumenta',
  sortName: 'broj_dokumenta',
};

const INDEX_NUMBER_FILTER_DATA: SearchScrollSifarniciFormProps = {
  type_of_form: 'searchScroll',
  formName: 'indeksni_broj_otpada_id',
  fetchOptinsList: SIFARNICI_ROUTES.WASTE_CATALOGUE,
  labelOptionAccessor: ['index_number', 'waste_sr'],
  valueOptionAccessor: 'id',
  inputName: 'indeksni_broj_otpada_id',
  label: t('OBRASCI.KOM_1.TYPE_OF_WASTE'),
  sortName: 'indeksni_broj_otpada',
  placeholder: '',
};

const DATUM_FILTER_DATA: DateSifarniciFormProps = {
  type_of_form: 'date',
  label: 'Datum',
  name: 'datum',
  format: 'DD-MM-YYYY',
  picker: 'date',
  sortName: 'datum',
};

const DATUM_PREDAJE_OTPADA_FILTER_DATA: DateSifarniciFormProps = {
  type_of_form: 'date',
  label: 'Datum',
  name: 'datum_predaje_otpada',
  format: 'DD-MM-YYYY',
  picker: 'date',
  sortName: 'datum_predaje_otpada',
};

const GODINA_FILTER_DATA: DateSifarniciFormProps = {
  type_of_form: 'date',
  label: 'Godina',
  name: 'godina',
  format: 'YYYY',
  picker: 'year',
  sortName: 'godina',
};

const PIB_FILTER_DATA = (): InputSifarniciFormProps => {
  return {
    type_of_form: 'input',
    label: t('COMPANY.PIB'),
    name: 'preduzece__pib',
    inputName: 'preduzece__pib',
    sortName: 'pib',
    hideInput: !authStore.isAdmin,
  };
};

const MATICNI_BROJ_FILTER_DATA = (): InputSifarniciFormProps => {
  return {
    type_of_form: 'input',
    label: t('COMPANY.ENTERPRISE_ID_NUMBER'),
    name: 'preduzece__maticni_broj',
    inputName: 'preduzece__maticni_broj',
    sortName: 'maticni_broj',
    hideInput: !authStore.isAdmin,
  };
};

const BASE_FILTER_DATA = (form: FormInstance<any>): ISifarniciTypeOfForm[] => {
  const newArray: ISifarniciTypeOfForm[] = [];

  if (obrasciStore.real_obrazac_type === TIPOVI_OBRAZACA.OBRAZAC_3) {
    newArray.push(
      PREDUZECE_FILTER_OBRAZAC(form),
      PIB_FILTER_DATA(),
      POSTROJENJE_FILTER_OBRAZAC(form),
      NACIONALNI_ID_FILTER_DATA()
    );
  } else {
    newArray.push(
      PREDUZECE_FILTER_OBRAZAC(form),
      PIB_FILTER_DATA(),
      MATICNI_BROJ_FILTER_DATA(),
      POSTROJENJE_FILTER_OBRAZAC(form),
      NACIONALNI_ID_FILTER_DATA()
    );
  }

  return newArray;
};

const GVE_FILTER_DATA: SwitchSifarniciFormProps = {
  type_of_form: 'switch',
  label: TIPOVI_OBRAZACA.GVE,
  name: 'obrazac_gve',
};

const OBRAZAC_2_FILTER_DATA: SwitchSifarniciFormProps = {
  type_of_form: 'switch',
  label: t('Obrazac 2'),
  name: 'obrazac_2',
};

const LRIZ_OBRAZAC_2_FILTER_DATA: SwitchSifarniciFormProps = {
  type_of_form: 'switch',
  label: t('Lriz obrazac 2'),
  name: 'lriz_obrazac_2',
};

const OTV_FILTER_DATA: SwitchSifarniciFormProps = {
  type_of_form: 'switch',
  label: TIPOVI_OBRAZACA.OTV,
  name: 'otv',
};

const OBRAZAC_3_FILTER_DATA: SwitchSifarniciFormProps = {
  type_of_form: 'switch',
  label: t('Obrazac 3'),
  name: 'obrazac_3',
};

const LRIZ_OBRAZAC_3_FILTER_DATA: SwitchSifarniciFormProps = {
  type_of_form: 'switch',
  label: t('Lriz obrazac 3'),
  name: 'lriz_obrazac_3',
};

const LRIZ_OBRAZAC_5_FILTER_DATA: SwitchSifarniciFormProps = {
  type_of_form: 'switch',
  label: t('Lriz obrazac 5'),
  name: 'lriz_obrazac_5',
};

const GIO_1_FILTER_DATA: SwitchSifarniciFormProps = {
  type_of_form: 'switch',
  label: t('Gio 1'),
  name: 'obrazac_gio1',
};

const BASE_INITIAL_STATE = {
  preduzece_id: '',
  postrojenje_id: '',
  preduzece__pib: '',
  preduzece__maticni_broj: '',
  postrojenje__nacionalni_id: '',
};

const BASE_WITH_GODINA_INITIAL_STATE = {
  ...BASE_INITIAL_STATE,
  godina: '',
};

export const OBRASCI_FILTERS_DATA: { [key: string]: ISifarniciFormData } = {
  [TIPOVI_OBRAZACA.DKO]: {
    initialState: { ...BASE_INITIAL_STATE, datum_predaje_otpada: '', indeksni_broj_otpada_id: '', broj_dokumenta: '' },
    components: (form: FormInstance<any>) => {
      return [
        BROJ_DOKUMENTA_FILTER_OBRAZAC,
        DATUM_PREDAJE_OTPADA_FILTER_DATA,
        ...BASE_FILTER_DATA(form),
        INDEX_NUMBER_FILTER_DATA,
      ];
    },
  },

  [TIPOVI_OBRAZACA.DEO6_GIO6]: {
    initialState: { ...BASE_INITIAL_STATE, datum: '' },
    components: (form: FormInstance<any>) => {
      return [DATUM_FILTER_DATA, ...BASE_FILTER_DATA(form)];
    },
  },

  [TIPOVI_OBRAZACA.AAO1]: {
    initialState: { ...BASE_WITH_GODINA_INITIAL_STATE, godina: '' },
    components: (form: FormInstance<any>) => {
      return [GODINA_FILTER_DATA, ...BASE_FILTER_DATA(form)];
    },
  },

  [TIPOVI_OBRAZACA.AAO2]: {
    initialState: { ...BASE_WITH_GODINA_INITIAL_STATE },
    components: (form: FormInstance<any>) => {
      return [GODINA_FILTER_DATA, ...BASE_FILTER_DATA(form)];
    },
  },

  [TIPOVI_OBRAZACA.OBRAZAC_1]: {
    initialState: { ...BASE_WITH_GODINA_INITIAL_STATE },
    components: (form: FormInstance<any>) => {
      return [GODINA_FILTER_DATA, ...BASE_FILTER_DATA(form), ISTORIJSKI_ID_FILTER_DATA()];
    },
  },

  [TIPOVI_OBRAZACA.LRIZ_OBRAZAC_1]: {
    initialState: { ...BASE_WITH_GODINA_INITIAL_STATE },
    components: (form: FormInstance<any>) => {
      return [GODINA_FILTER_DATA, ...BASE_FILTER_DATA(form)];
    },
  },

  [TIPOVI_OBRAZACA.OBRAZAC_2]: {
    initialState: { ...BASE_WITH_GODINA_INITIAL_STATE, izvor_zagadjenja_vazduha_id: '' },
    components: (form: FormInstance<any>) => {
      return [
        GODINA_FILTER_DATA,
        ...BASE_FILTER_DATA(form),
        ISTORIJSKI_ID_FILTER_DATA(),
        IZVOR_VAZDUH_FILTER_DATA(form),
        BROJ_IZVOR_VAZDUH_FILTER_OBRAZAC,
        GVE_FILTER_DATA,
      ];
    },
  },

  [TIPOVI_OBRAZACA.OBRAZAC_2_GVE]: {
    initialState: { ...BASE_WITH_GODINA_INITIAL_STATE, izvor_zagadjenja_vazduha_id: '' },
    components: (form: FormInstance<any>) => {
      return [
        GODINA_FILTER_DATA,
        ...BASE_FILTER_DATA(form),
        IZVOR_VAZDUH_FILTER_DATA(form),
        BROJ_IZVOR_VAZDUH_FILTER_OBRAZAC,
        OBRAZAC_2_FILTER_DATA,
        GVE_FILTER_DATA,
      ];
    },
  },

  [TIPOVI_OBRAZACA.LRIZ_OBRAZAC_2]: {
    initialState: { ...BASE_WITH_GODINA_INITIAL_STATE, izvor_zagadjenja_vazduha_id: '' },
    components: (form: FormInstance<any>) => {
      return [
        GODINA_FILTER_DATA,
        ...BASE_FILTER_DATA(form),
        IZVOR_VAZDUH_FILTER_DATA(form),
        BROJ_IZVOR_VAZDUH_FILTER_OBRAZAC,
        GVE_FILTER_DATA,
      ];
    },
  },

  [TIPOVI_OBRAZACA.LRIZ_OBRAZAC_2_GVE]: {
    initialState: { ...BASE_WITH_GODINA_INITIAL_STATE, izvor_zagadjenja_vazduha_id: '' },
    components: (form: FormInstance<any>) => {
      return [
        GODINA_FILTER_DATA,
        ...BASE_FILTER_DATA(form),
        IZVOR_VAZDUH_FILTER_DATA(form),
        BROJ_IZVOR_VAZDUH_FILTER_OBRAZAC,
        LRIZ_OBRAZAC_2_FILTER_DATA,
        GVE_FILTER_DATA,
      ];
    },
  },

  [TIPOVI_OBRAZACA.GVE]: {
    initialState: { ...BASE_WITH_GODINA_INITIAL_STATE, izvor_zagadjenja_vazduha_id: '' },
    components: (form: FormInstance<any>) => {
      return [
        GODINA_FILTER_DATA,
        ...BASE_FILTER_DATA(form),
        IZVOR_VAZDUH_FILTER_DATA(form),
        BROJ_IZVOR_VAZDUH_FILTER_OBRAZAC,
        OBRAZAC_2_FILTER_DATA,
        LRIZ_OBRAZAC_2_FILTER_DATA,
      ];
    },
  },

  [TIPOVI_OBRAZACA.OBRAZAC_3]: {
    initialState: { ...BASE_WITH_GODINA_INITIAL_STATE, izvor_zagadjenja_vode_id: '' },
    components: (form: FormInstance<any>) => {
      return [
        GODINA_FILTER_DATA,
        ...BASE_FILTER_DATA(form),
        ISTORIJSKI_ID_FILTER_DATA(),
        IZVOR_VODA_FILTER_DATA(form),
        BROJ_ISPUST_VODA_FILTER_OBRAZAC,
        OTV_FILTER_DATA,
      ];
    },
  },

  [TIPOVI_OBRAZACA.OBRAZAC_3_OTV]: {
    initialState: { ...BASE_WITH_GODINA_INITIAL_STATE, izvor_zagadjenja_vode_id: '' },
    components: (form: FormInstance<any>) => {
      return [
        GODINA_FILTER_DATA,
        ...BASE_FILTER_DATA(form),
        IZVOR_VODA_FILTER_DATA(form),
        BROJ_ISPUST_VODA_FILTER_OBRAZAC,
        OBRAZAC_3_FILTER_DATA,
        OTV_FILTER_DATA,
      ];
    },
  },

  [TIPOVI_OBRAZACA.LRIZ_OBRAZAC_3]: {
    initialState: { ...BASE_WITH_GODINA_INITIAL_STATE, izvor_zagadjenja_vode_id: '' },
    components: (form: FormInstance<any>) => {
      return [
        GODINA_FILTER_DATA,
        ...BASE_FILTER_DATA(form),
        IZVOR_VODA_FILTER_DATA(form),
        BROJ_ISPUST_VODA_FILTER_OBRAZAC,
        OTV_FILTER_DATA,
      ];
    },
  },

  [TIPOVI_OBRAZACA.LRIZ_OBRAZAC_3_OTV]: {
    initialState: { ...BASE_WITH_GODINA_INITIAL_STATE, izvor_zagadjenja_vode_id: '' },
    components: (form: FormInstance<any>) => {
      return [
        GODINA_FILTER_DATA,
        ...BASE_FILTER_DATA(form),
        IZVOR_VODA_FILTER_DATA(form),
        BROJ_ISPUST_VODA_FILTER_OBRAZAC,
        LRIZ_OBRAZAC_3_FILTER_DATA,
        OTV_FILTER_DATA,
      ];
    },
  },

  [TIPOVI_OBRAZACA.OTV]: {
    initialState: { ...BASE_WITH_GODINA_INITIAL_STATE, izvor_zagadjenja_vode_id: '' },
    components: (form: FormInstance<any>) => {
      return [
        GODINA_FILTER_DATA,
        ...BASE_FILTER_DATA(form),
        IZVOR_VODA_FILTER_DATA(form),
        BROJ_ISPUST_VODA_FILTER_OBRAZAC,
        OBRAZAC_3_FILTER_DATA,
        LRIZ_OBRAZAC_3_FILTER_DATA,
      ];
    },
  },

  [TIPOVI_OBRAZACA.OBRAZAC_4]: {
    initialState: { ...BASE_WITH_GODINA_INITIAL_STATE, izvor_zagadjenja_tla_id: '' },
    components: (form: FormInstance<any>) => {
      return [
        GODINA_FILTER_DATA,
        ...BASE_FILTER_DATA(form),
        ISTORIJSKI_ID_FILTER_DATA(),
        IZVOR_TLO_FILTER_DATA(form),
        BROJ_LOKACIJE_TLO_FILTER_OBRAZAC,
      ];
    },
  },

  [TIPOVI_OBRAZACA.LRIZ_OBRAZAC_4]: {
    initialState: { ...BASE_WITH_GODINA_INITIAL_STATE, izvor_zagadjenja_tla_id: '' },
    components: (form: FormInstance<any>) => {
      return [
        GODINA_FILTER_DATA,
        ...BASE_FILTER_DATA(form),
        IZVOR_TLO_FILTER_DATA(form),
        BROJ_LOKACIJE_TLO_FILTER_OBRAZAC,
      ];
    },
  },

  [TIPOVI_OBRAZACA.OBRAZAC_5]: {
    initialState: { ...BASE_WITH_GODINA_INITIAL_STATE, indeksni_broj_otpada_id: '' },
    components: (form: FormInstance<any>) => {
      return [GODINA_FILTER_DATA, ...BASE_FILTER_DATA(form), ISTORIJSKI_ID_FILTER_DATA(), INDEX_NUMBER_FILTER_DATA];
    },
  },

  [TIPOVI_OBRAZACA.LRIZ_OBRAZAC_5]: {
    initialState: { ...BASE_WITH_GODINA_INITIAL_STATE, indeksni_broj_otpada_id: '' },
    components: (form: FormInstance<any>) => {
      return [GODINA_FILTER_DATA, ...BASE_FILTER_DATA(form), INDEX_NUMBER_FILTER_DATA, GIO_1_FILTER_DATA];
    },
  },

  [TIPOVI_OBRAZACA.GIO1]: {
    initialState: { ...BASE_WITH_GODINA_INITIAL_STATE, indeksni_broj_otpada_id: '' },
    components: (form: FormInstance<any>) => {
      return [GODINA_FILTER_DATA, ...BASE_FILTER_DATA(form), INDEX_NUMBER_FILTER_DATA, LRIZ_OBRAZAC_5_FILTER_DATA];
    },
  },

  [TIPOVI_OBRAZACA.LRIZ_OBRAZAC_5_GIO1]: {
    initialState: { ...BASE_WITH_GODINA_INITIAL_STATE, indeksni_broj_otpada_id: '' },
    components: (form: FormInstance<any>) => {
      return [
        GODINA_FILTER_DATA,
        ...BASE_FILTER_DATA(form),
        INDEX_NUMBER_FILTER_DATA,
        LRIZ_OBRAZAC_5_FILTER_DATA,
        GIO_1_FILTER_DATA,
      ];
    },
  },

  [TIPOVI_OBRAZACA.DEP1]: {
    initialState: { ...BASE_WITH_GODINA_INITIAL_STATE, nesanitarna_deponija_id: '' },
    components: (form: FormInstance<any>) => {
      return [GODINA_FILTER_DATA, ...BASE_FILTER_DATA(form), NESANITARNA_DEPONIJA_FILTER_DATA(form)];
    },
  },

  [TIPOVI_OBRAZACA.DEP2]: {
    initialState: { ...BASE_WITH_GODINA_INITIAL_STATE },
    components: (form: FormInstance<any>) => {
      return [GODINA_FILTER_DATA, ...BASE_FILTER_DATA(form)];
    },
  },

  [TIPOVI_OBRAZACA.GIO2]: {
    initialState: { ...BASE_WITH_GODINA_INITIAL_STATE },
    components: (form: FormInstance<any>) => {
      return [GODINA_FILTER_DATA, ...BASE_FILTER_DATA(form)];
    },
  },

  [TIPOVI_OBRAZACA.GIO3]: {
    initialState: { ...BASE_WITH_GODINA_INITIAL_STATE },
    components: (form: FormInstance<any>) => {
      return [GODINA_FILTER_DATA, ...BASE_FILTER_DATA(form)];
    },
  },

  [TIPOVI_OBRAZACA.GIO4]: {
    initialState: { ...BASE_WITH_GODINA_INITIAL_STATE },
    components: (form: FormInstance<any>) => {
      return [GODINA_FILTER_DATA, ...BASE_FILTER_DATA(form)];
    },
  },

  [TIPOVI_OBRAZACA.GIO5]: {
    initialState: { ...BASE_WITH_GODINA_INITIAL_STATE },
    components: (form: FormInstance<any>) => {
      return [GODINA_FILTER_DATA, ...BASE_FILTER_DATA(form)];
    },
  },

  [TIPOVI_OBRAZACA.PTP2]: {
    initialState: { ...BASE_WITH_GODINA_INITIAL_STATE },
    components: (form: FormInstance<any>) => {
      return [GODINA_FILTER_DATA, ...BASE_FILTER_DATA(form)];
    },
  },

  [TIPOVI_OBRAZACA.PZV1]: {
    initialState: { ...BASE_WITH_GODINA_INITIAL_STATE },
    components: (form: FormInstance<any>) => {
      return [GODINA_FILTER_DATA, ...BASE_FILTER_DATA(form)];
    },
  },

  [TIPOVI_OBRAZACA.KOM1]: {
    initialState: { ...BASE_WITH_GODINA_INITIAL_STATE },
    components: (form: FormInstance<any>) => {
      return [GODINA_FILTER_DATA, ...BASE_FILTER_DATA(form)];
    },
  },
};
