import { observer } from 'mobx-react-lite';
import { InsideContentWrapper } from 'components/InsideContentWrapper/InsideContentWrapper';
import { TableProizvodi } from './components/TableProizvodi/TableProizvodi';
import { Row } from 'antd';
import { AddNewButton } from 'components/Buttons/AddNewButton/AddNewButton';
import { authStore, modalStore } from 'store';
import { ptp2ObrazacStore } from 'modules/obrasci/store/ptp2/ptp2.store';
import { FormCheckbox } from 'components/FormCheckbox/FormCheckbox';
import { useTranslation } from 'react-i18next';
import { obrasciStore } from 'modules/obrasci/obrasci.store';
import styles from './ProizvodiWizardTab.module.scss';

export const HeaderProizvodi: React.FC = observer(() => {
  const { t } = useTranslation();

  const onCheckboxClick = () => {
    ptp2ObrazacStore.handleChange('dozvoljeneKorekcije', !ptp2ObrazacStore.dozvoljeneKorekcije);

    const updatedProizvodiList = ptp2ObrazacStore.initialState.proizvodi.map(proizvod => ({
      ...proizvod,
      dozvoljena_korekcija_proizvoda: false,
    }));

    ptp2ObrazacStore.handleInitialStateChange('proizvodi', updatedProizvodiList);
  };

  return (
    <Row id="ptp2ProizvodiTabelHeader" className={styles.proizvodiTableHeader}>
      {obrasciStore.zakljucanObrazac && authStore.isAdmin && (
        <>
          <FormCheckbox
            name="dozvoljene_korekcije"
            label={t('OBRASCI.PTP2.DOZVOLJENE_KOREKCIJE')}
            labelCol={{ span: 21 }}
            onClick={onCheckboxClick}
            disabled={false}
          />
        </>
      )}
      {ptp2ObrazacStore.getDozvoljeneKorekcije && (
        <AddNewButton
          label="Dodaj"
          name="proizvodi-form"
          onButtonClick={() => {
            modalStore.changeModalName('proizvodi-modal');
          }}
          disabled={!ptp2ObrazacStore.getDozvoljeneKorekcije}
        />
      )}
    </Row>
  );
});

export const ProizvodiWizardTab: React.FC = observer(() => {
  return (
    <InsideContentWrapper header={<HeaderProizvodi />}>
      <TableProizvodi />
    </InsideContentWrapper>
  );
});
