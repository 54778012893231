import { EditOutlined } from '@ant-design/icons';
import { ColumnsType } from 'antd/es/table';
import { Button } from 'components/Button/Button';
import TableData from 'components/TableData/TableData';
import { observer } from 'mobx-react-lite';
import { aao1Store } from 'modules/obrasci/store/aao1/aao1.store';
import { useTranslation } from 'react-i18next';
import { WizardObrazacListHeader } from 'components/WizardObrazacListHeader/WizardObrazacListHeader';
import { InsideContentWrapper } from 'components/InsideContentWrapper/InsideContentWrapper';
import { modalStore } from 'store';
import { IOperateri } from 'modules/obrasci/store/aao1/aao1.types';

export const OperatorsTable: React.FC = observer(() => {
  const { t } = useTranslation();

  const columns: ColumnsType<IOperateri> = [
    {
      title: t('OBRASCI.AAO1.PACKAGING_AMOUNT.OPERATOR'),
      width: 60,
      dataIndex: ['operators_package_waste_manage', 'preduzece', 'naziv'],
    },
    {
      title: t('OBRASCI.PZV_1.QUANTITY'),
      width: 60,
      dataIndex: 'kolicina',
    },
    {
      title: t('OBRASCI.AAO1.PACKAGING_AMOUNT.PERMIT_NUMBER'),
      width: 20,
      dataIndex: ['operators_package_waste_manage', 'permit_number'],
    },
    {
      align: 'center',
      width: 20,
      render: (_, record: IOperateri) => {
        return (
          <Button
            icon={<EditOutlined />}
            name={'edit-aao1-operateri'}
            onClick={() => {
              modalStore.changeModalName('aao1-operateri-modal');
              aao1Store.handleChange('operater_form', record);
            }}
          />
        );
      },
    },
  ];

  return (
    <InsideContentWrapper
      header={<WizardObrazacListHeader name={'aao1-operateri-form'} modalName="aao1-operateri-modal" />}
    >
      <TableData name="operatori" columns={columns} dataSource={[...aao1Store.kolicinaAmbalaze.operateri]} />
    </InsideContentWrapper>
  );
});
