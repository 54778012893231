import { t } from 'i18next';
import {
  DateSifarniciFormProps,
  InputSifarniciFormProps,
  SwitchSifarniciFormProps,
  TextAreaSifarniciFormProps,
} from './sifarnici.types';
import { MORE_THEN_0 } from 'modules/nriz/nriz.constants';
import { FormInstance } from 'antd';

export const THE_ORDER_FORM = {
  type_of_form: 'input_number',
  name: 'the_order',
  // todo: prevod
  label: 'Redosled',
  precision: 0,
  inputName: 'the_order',
  rules: MORE_THEN_0,
} as InputSifarniciFormProps;

export const TITLE_FORM = {
  type_of_form: 'input',
  name: 'title',
  label: 'Naslov',
  inputName: 'title',
  // placeholder: t('COMPANY.PLACEHOLDER.ENTER_SHORT_NAME'),
} as InputSifarniciFormProps;

export const DESCRIPTION_FORM = {
  type_of_form: 'area',
  name: 'description',
  label: t('SIFARNICI.OPIS'),
  // rules: [MAX_LENGTH_RULE(100)],
  rows: 3,
  labelCol: 10,
  wrapperCol: 14,
} as TextAreaSifarniciFormProps;

export const DESCRIPTION_SR_FORM = {
  type_of_form: 'area',
  name: 'description_sr',
  label: `${t('SIFARNICI.OPIS')} ${t('NA_SRPSKOM')}`,
  // rules: [MAX_LENGTH_RULE(100)],
  rows: 3,
  labelCol: 10,
  wrapperCol: 14,
} as TextAreaSifarniciFormProps;

export const VERSION_FORM = {
  type_of_form: 'input_number',
  name: 'version',
  // todo: prevod
  label: t('Verzija'),
  inputName: 'version',
} as InputSifarniciFormProps;

export const CODE_FORM = {
  type_of_form: 'input_number',
  name: 'code',
  // todo: prevod
  label: t('USER.PASSWORD'),
  inputName: 'code',
} as InputSifarniciFormProps;

export const CODE_2_FORM = {
  type_of_form: 'input',
  name: 'code2',
  // todo: prevod
  label: `${t('USER.PASSWORD')} 2`,
  inputName: 'code2',
} as InputSifarniciFormProps;

export const NAME_FORM = {
  type_of_form: 'input',
  name: 'name',
  // todo: prevod
  label: t('UNSANITARY_LANDFILL.NAME'),
  inputName: 'name',
  // rules: [REQUIRED_FIELD_RULE(true)],
} as InputSifarniciFormProps;

export const NAME_SR_FORM = {
  type_of_form: 'input',
  name: 'name_sr',
  // todo: prevod
  // rules: [REQUIRED_FIELD_RULE(true)],
  label: `${t('UNSANITARY_LANDFILL.NAME')} ${t('NA_SRPSKOM')}`,
  inputName: 'name_sr',
} as InputSifarniciFormProps;

export const APPROVED_FORM = {
  type_of_form: 'switch',
  name: 'approved',
  label: `Odobreno`,
} as SwitchSifarniciFormProps;

export const VALIDITY_FROM_DATE_FORM = (form: FormInstance<any>) => {
  return {
    type_of_form: 'date',
    name: 'validity_from_date',
    label: t('SIFARNICI.OD_DATUMA'),
    // rules: [REQUIRED_FIELD_RULE(true)],
    format: 'DD-MM-YYYY',
    picker: 'date',
    onChange: () => {
      form.setFieldValue('validity_to_date', '');
    },
  } as DateSifarniciFormProps;
};
export const VALIDITY_TO_DATE_FORM = (form: FormInstance<any>) => {
  const queryParams = new URLSearchParams(location.search);
  const sifarnik_type = queryParams.get('sifarnik_type');

  return {
    type_of_form: 'date',
    name: 'validity_to_date',
    label: t('SIFARNICI.DO_DATUMA'),
    // rules: [REQUIRED_FIELD_RULE(true)],
    format: 'DD-MM-YYYY',
    disabled: !!(sifarnik_type && !form.getFieldValue('validity_from_date')),
    picker: 'date',
    disabledDate: (current: any) => {
      if (form.getFieldValue('validity_from_date')) {
        return current && current.endOf('day') < form.getFieldValue('validity_from_date');
      }
    },
    // hideInput: true,
  } as DateSifarniciFormProps;
};

export const INDEX_NUMBER_FORM = {
  type_of_form: 'input',
  name: 'index_number',
  label: 'Indeksni broj',
  inputName: 'index_number',
} as InputSifarniciFormProps;

export const IS_ACTIVE_SWITCH = {
  type_of_form: 'switch',
  name: 'is_active',
  label: t('COMPANY.ACTIVE'),
} as SwitchSifarniciFormProps;
