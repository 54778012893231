import { Form, FormInstance } from 'antd';
import { FormComponents } from 'components/FormComponents/FormComponents';
import { observer } from 'mobx-react-lite';
import { ISifarniciTypeOfForm } from 'modules/sifarnici/sifarnici.types';
import { APR_ADDRESS_DATA_FORM_OPTIONS } from 'modules/sifarnici/store/aprAddressData/aprAddressData.constants';
import { IGetAprAddressData } from 'modules/sifarnici/store/aprAddressData/aprAddressData.types';
import React, { useMemo } from 'react';

interface ProductCategoriesProps {
  parentForm: FormInstance<any>;
  formListName: string;
}

export const AprAddressDataFormModal: React.FC<ProductCategoriesProps> = observer(() => {
  const form = Form.useFormInstance<IGetAprAddressData>();

  const components = useMemo(() => {
    return APR_ADDRESS_DATA_FORM_OPTIONS(form);
  }, [Form.useWatch([], form)]);

  return (
    <div>
      {components.map((item: ISifarniciTypeOfForm, index) => {
        return <FormComponents data={item} key={index} />;
      })}
    </div>
  );
});
