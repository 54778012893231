import { useForm } from 'antd/es/form/Form';
import { FilterTableForm } from 'components/TableData/FilterTableForm/FilterTableForm';
import { observer } from 'mobx-react-lite';
import { paginationInitialState } from 'modules/nriz/nriz.constants';
import { unsanitaryLandfillStore } from 'modules/unsanitarylandfill/unsanitarylandfill.store';
import React from 'react';
import { IFilterTableProps } from 'typescript/NrizTypes';

export const FilterUnsanitaryLandfillList: React.FC = observer(() => {
  const [unsanitaryForm] = useForm();

  const fetchUnsanitaryLandfillList = () => {
    return unsanitaryLandfillStore.fetchUnsanitaryLandfillList(paginationInitialState);
  };

  const unsanitaryFilterOptions = [
    {
      type_of_form: 'input',
      inputName: 'oznaka_nesanitarne_deponije',
      name: 'oznaka_nesanitarne_deponije',
      label: 'Oznaka',
      sortName: 'oznaka_nesanitarne_deponije',
    },
    {
      type_of_form: 'input',
      inputName: 'naziv',
      name: 'naziv',
      label: 'Naziv',
      sortName: 'naziv',
    },
  ];
  const data = {
    form: unsanitaryForm,
    initialState: { naziv: '', oznaka_nesanitarne_deponije: '', order_by: '' },
    filterName: 'nesanitarne_deponije',
    filterOptions: unsanitaryFilterOptions,
    fetchTableList: fetchUnsanitaryLandfillList,
  } as IFilterTableProps;

  return <FilterTableForm data={data} />;
});
