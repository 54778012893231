import { ColumnProps } from 'antd/es/table';
import { ColumnGroupProps } from 'antd/es/table/ColumnGroup';
import { DateCell } from 'components/CallRenderers/DateCell/DateCell';
import { YesNoCell } from 'components/CallRenderers/YesNoCell/YesNoCell';
import { obrazacIzvestajiService } from './obrazacIzvestaji.service';
import {
  AAO2IzvestajForBook,
  Aao1IzvestajiEnum,
  Aao1NedostavljeniObrasci,
  Aao1PregledPoGodinama,
  Aao1Prikaz1,
  Aao1SviPodaci,
  Aao2IzvestajiEnum,
  Deo6IzvestajiEnum,
  Deo6PredatOtpadPostrojenjaIndeksniBroj,
  Deo6PreuzetOtpadPostrojenjaIndeksniBroj,
  Deo6PrimopredajaOtpadaIzmedjuPreduzeca,
  Deo6Rekapitulacija,
  Deo6TransakcijePoIndeksnimBrojevima,
  Deo6TransakcijeZaRazredeElektricnihIElektronskihProizvoda,
  Deo6UkupnoPredatOtpad,
  Deo6UkupnoPreuzetOtpad,
  Dep1IzvestajiEnum,
  Dep1SviPodaci,
  Dep2IzvestajiEnum,
  Dep2SviPodaci,
  Gio2ElektricniIElektronskiOtpad,
  Gio2GodisnjeOdlaganjeOtpada,
  Gio2GrupaPodgrupaIndeksniBroj,
  Gio2IzvestajiEnum,
  Gio2OdlaganjeOtpadaKontrolaUnosa,
  Gio2PostrojenjaIndeksniBroj,
  Gio3Deo6PoPreduzecu,
  Gio3ElektricniIElektronskiOtpad,
  Gio3GrupaPodgrupaIndeksniBroj,
  Gio3IzvestajiEnum,
  Gio3PostrojenjaIndeksniBroj,
  Gio3TretmanOtpadaKontrolaUnosa,
  Gio3TretmanOtpadaPregledPoGodinama,
  Gio4ElektricniIElektronskiOtpad,
  Gio4GrupaPodgrupaIndeksniBroj,
  Gio4IzvestajiEnum,
  Gio4IzvozOtpadaKontrolaUnosa,
  Gio4KolicinaIzvezenogOtpadaPoVrstiOtpada,
  Gio4PostrojenjaIndeksniBroj,
  Gio4PregledPoGodinama,
  Gio5GrupaPodgrupaIndeksniBroj,
  Gio5IzvestajiEnum,
  Gio5KolicinaUvezenogOtpadaPoVrstiOtpada,
  Gio5PostrojenjaIndeksniBroj,
  Gio5PregledPoGodinama,
  Gio5UvozOtpadaKontrolaUnosa,
  IzvestajiSubtypes,
  Kom1IzvestajiEnum,
  Kom1KolicinaKomunalnogOtpadaPregledPoGodinama,
  Kom1ObuhvatPrikupljanjaKomunalnogOtpadaPregledPoGodinama,
  Kom1SastavKomunalnogOtpadaPregledPoGodinama,
  Kom1UpravljanjeKomunalnimOtpadom,
  Obrazac1Goriva,
  Obrazac1IzvestajiEnum,
  Obrazac1Opste,
  Obrazac1Proizvodi,
  Obrazac1Sirovine,
  Obrazac2Gorivo,
  Obrazac2IzvestajiEnum,
  Obrazac2IzvoriPoGodinama,
  Obrazac2KontrolaUnosa,
  Obrazac2KontrolaUnosaGrupaKolona,
  Obrazac2Opste,
  Obrazac2PRTRKategorije,
  Obrazac2PregledPoGodinama,
  Obrazac2PrtrPregledPoGodinama,
  Obrazac2ZagadljiveMaterije,
  Obrazac3IspustPoGodinama,
  Obrazac3IzvestajiEnum,
  Obrazac3KontrolaUnosa,
  Obrazac3Opste,
  Obrazac3PregledPoGodinama,
  Obrazac3PrtrPregledPoGodinama,
  Obrazac3ZagadjujuceMaterije,
  Obrazac5GrupaPodgrupaIndeksniBroj,
  Obrazac5IzvestajiEnum,
  Obrazac5KontrolaUnosa,
  Obrazac5NeOpasan,
  Obrazac5PRTRPregledPoGodinama,
  Obrazac5Postrojenja,
  Obrazac5PostrojenjaIndeksniBroj,
  Obrazac5PostrojenjaZaPlacanje,
  Obrazac5PregledPoGodinama,
  Ptp2IzvestajiEnum,
  Ptp2KontrolaUnosa,
  Ptp2NedostavljeniPodaciOGumama,
  Ptp2NedostavljeniPodaciOVozilima,
  Ptp2ObracunZaNaplatu,
  Ptp2UvozGumaUporedjivanje,
  Ptp2UvozGumaUporedjivanjeGrupaKolona,
  Ptp2VozilaUporedjivanje,
  Ptp2VozilaUporedjivanjeGrupaKolona,
  PzvIzvestajiEnum,
  PzvKolicineProizvodaPoGrupiIPreduzecu,
  PzvKolicineProizvodaPoPreduzecu,
} from './obrazacIzvestaji.types';

export interface IIzvestajiColumnProps<RecordType = unknown> extends Omit<ColumnProps<RecordType>, 'dataIndex'> {
  dataIndex?: keyof RecordType;
}

export interface IIzvestajiColumnGroupProps<RecordType = unknown>
  extends Omit<ColumnGroupProps<RecordType>, 'children'> {
  children?: IIzvestajiColumnOrGroupProps<RecordType> | IIzvestajiColumnOrGroupProps<RecordType>[];
}

export type IIzvestajiColumnOrGroupProps<RecordType = unknown> =
  | IIzvestajiColumnGroupProps<RecordType>
  | IIzvestajiColumnProps<RecordType>;

//#region OBRAZAC 1

export const OBRAZAC_1_GORIVA_COLUMNS: IIzvestajiColumnProps<Obrazac1Goriva>[] = [
  { width: 100, dataIndex: 'godina', title: 'Godina' },
  { width: 150, dataIndex: 'pib', title: 'PIB' },
  { width: 250, dataIndex: 'preduzece', title: 'Preduzeće' },
  {
    width: 150,
    dataIndex: 'nacionalni_id',
    title: 'Nacionalni ID',
  },
  {
    width: 200,
    dataIndex: 'postrojenje',
    title: 'Postrojenje',
  },
  {
    width: 200,
    dataIndex: 'opstina_postrojenja',
    title: 'Opština postrojenja',
  },
  { width: 200, dataIndex: 'naziv_goriva', title: 'Naziv goriva' },
  { width: 200, dataIndex: 'potrosnja_na_dan', title: 'Potrošnja na dan' },
  { width: 150, dataIndex: 'jed_mere', title: 'Jed. mere' },
  {
    render: (record: Obrazac1Goriva) => <DateCell date={record.kreirano} showTime={true} />,
    width: 200,
    title: 'Kreirano',
  },
  {
    render: (record: Obrazac1Goriva) => <DateCell date={record.azurirano} showTime={true} />,
    width: 200,
    title: 'Ažurirano',
  },
  { width: 300, dataIndex: 'napomena', title: 'Napomena' },
];

export const OBRAZAC_1_PROIZVODI_COLUMNS: IIzvestajiColumnProps<Obrazac1Proizvodi>[] = [
  { width: 100, dataIndex: 'godina', title: 'Godina' },
  { width: 150, dataIndex: 'pib', title: 'PIB' },
  { width: 250, dataIndex: 'preduzece', title: 'Preduzeće' },
  {
    width: 150,
    dataIndex: 'nacionalni_id',
    title: 'Nacionalni ID',
  },
  {
    width: 200,
    dataIndex: 'postrojenje',
    title: 'Postrojenje',
  },
  { width: 150, dataIndex: 'prtr_kod', title: 'PRTR kod' },
  {
    width: 200,
    dataIndex: 'opstina_postrojenja',
    title: 'Opština postrojenja',
  },
  { width: 250, dataIndex: 'naziv_proizvoda', title: 'Naziv proizvoda' },
  { width: 150, dataIndex: 'kolicina_proizvoda', title: 'Količina proizvoda' },
  { width: 150, dataIndex: 'jed_mere', title: 'Jed. mere' },
  {
    render: (record: Obrazac1Proizvodi) => <DateCell date={record.kreirano} showTime={true} />,
    width: 200,
    title: 'Kreirano',
  },
  {
    render: (record: Obrazac1Proizvodi) => <DateCell date={record.azurirano} showTime={true} />,
    width: 200,
    title: 'Ažurirano',
  },
  { width: 300, dataIndex: 'napomena', title: 'Napomena' },
];

export const OBRAZAC_1_SIROVINE_COLUMNS: IIzvestajiColumnProps<Obrazac1Sirovine>[] = [
  { width: 100, dataIndex: 'godina', title: 'Godina' },
  { width: 150, dataIndex: 'pib', title: 'PIB' },
  { width: 250, dataIndex: 'preduzece', title: 'Preduzeće' },
  {
    width: 150,
    dataIndex: 'nacionalni_id',
    title: 'Nacionalni ID',
  },
  {
    width: 200,
    dataIndex: 'postrojenje',
    title: 'Postrojenje',
  },
  {
    width: 200,
    dataIndex: 'opstina_postrojenja',
    title: 'Opština postrojenja',
  },
  { width: 150, dataIndex: 'iupac', title: 'IUPAC' },
  { width: 200, dataIndex: 'naziv_sirovine', title: 'Naziv sirovine' },
  { width: 200, dataIndex: 'potrosnja_na_dan', title: 'Potrošnja na dan' },
  { width: 150, dataIndex: 'jed_mere', title: 'Jed. mere' },
  {
    render: (record: Obrazac1Sirovine) => <DateCell date={record.kreirano} showTime={true} />,
    width: 200,
    title: 'Kreirano',
  },
  {
    render: (record: Obrazac1Sirovine) => <DateCell date={record.azurirano} showTime={true} />,
    width: 200,
    title: 'Ažurirano',
  },
  { width: 300, dataIndex: 'napomena', title: 'Napomena' },
];

export const OBRAZAC_1_OPSTE_COLUMNS: IIzvestajiColumnProps<Obrazac1Opste>[] = [
  { width: 100, dataIndex: 'godina', title: 'Godina' },
  { width: 250, dataIndex: 'preduzece', title: 'Preduzeće' },
  { width: 150, dataIndex: 'pib', title: 'PIB' },
  { width: 150, dataIndex: 'mb', title: 'Matični broj preduzeća' },
  { width: 200, dataIndex: 'sifra_pretezne_delatnosti', title: 'Šifra pretezne delatnosti' },
  { width: 200, dataIndex: 'opstina_preduzeca', title: 'Opština preduzeća' },
  {
    width: 200,
    dataIndex: 'postrojenje',
    title: 'Postrojenje',
  },
  {
    width: 150,
    dataIndex: 'nacionalni_id',
    title: 'Nacionalni ID',
  },
  {
    width: 200,
    dataIndex: 'opstina_postrojenja',
    title: 'Opština postrojenja',
  },
  { width: 200, dataIndex: 'prtr_kod', title: 'PRTR aktivnost' },
  { width: 200, dataIndex: 'broj_ispusta_u_vazduh', title: 'Broj ispusta u vazduh' },
  { width: 200, dataIndex: 'broj_ispusta_u_vodu', title: 'Broj ispusta u vodu' },
  { width: 200, dataIndex: 'broj_ispusta_u_tlo', title: 'Broj ispusta na/u tlo' },
  { width: 200, dataIndex: 'broj_vrsta_otpada', title: 'Broj vrsta otpada' },
  { width: 200, dataIndex: 'rezim_rada', title: 'Režim rada' },
  { width: 200, dataIndex: 'pocetak_sezone', title: 'Početak sezone' },
  { width: 200, dataIndex: 'kraj_sezone', title: 'Kraj sezone' },
  { width: 200, dataIndex: 'broj_smena', title: 'Broj smena' },
  { width: 200, dataIndex: 'broj_radnih_dana_nedeljno', title: 'Broj radnih dana nedeljno' },
  { width: 200, dataIndex: 'broj_radnih_dana_godisnje', title: 'Broj radnih dana godisnje' },
  { width: 200, dataIndex: 'broj_radnih_dana_sezonski', title: 'Broj radnih dana sezonski' },
  { width: 200, dataIndex: 'broj_stalno_zaposlenih', title: 'Broj stalno zaposlenih' },
  { width: 200, dataIndex: 'broj_privremeno_zaposlenih', title: 'Broj privremeno zaposlenih' },
  { width: 200, dataIndex: 'broj_zaposlenih_u_prvoj_smeni', title: 'Broj zaposlenih u prvoj smeni' },
  { width: 200, dataIndex: 'broj_zaposlenih_u_drugoj_smeni', title: 'Broj zaposlenih u drugoj smeni' },
  { width: 200, dataIndex: 'broj_zaposlenih_u_trecoj_smeni', title: 'Broj zaposlenih u trećoj smeni' },
];

//#endregion OBRAZAC 1

//#region OBRAZAC 2

export const OBRAZAC_2_ZAGADLJIVE_MATERIJE_COLUMNS: IIzvestajiColumnProps<Obrazac2ZagadljiveMaterije>[] = [
  { width: 100, dataIndex: 'godina', title: 'Godina' },
  { width: 150, dataIndex: 'pib', title: 'PIB' },
  { width: 250, dataIndex: 'preduzece', title: 'Preduzeće' },
  {
    width: 150,
    dataIndex: 'nacionalni_id',
    title: 'Nacionalni ID',
  },
  {
    width: 200,
    dataIndex: 'postrojenje',
    title: 'Postrojenje',
  },
  {
    width: 200,
    dataIndex: 'opstina_postrojenja',
    title: 'Opština postrojenja',
  },
  {
    width: 200,
    dataIndex: 'istorijski_id',
    title: 'Istorijski ID',
  },
  { render: (record: Obrazac2ZagadljiveMaterije) => <YesNoCell yesNo={record.prtr} />, title: 'PRTR', width: 100 },
  { render: (record: Obrazac2ZagadljiveMaterije) => <YesNoCell yesNo={record.ippc} />, title: 'IPPC', width: 100 },
  { width: 200, dataIndex: 'aktivnosti', title: 'Aktivnosti' },
  { width: 200, dataIndex: 'naziv_izvora', title: 'Naziv izvora' },
  { width: 100, dataIndex: 'broj_izvora', title: 'Broj izvora' },
  { width: 200, dataIndex: 'protok', title: 'Protok (Nm3/h)' },
  { width: 200, dataIndex: 'ukupan_broj_radnih_sati', title: 'Ukupan broj radnih sati' },
  { width: 200, dataIndex: 'zagadjujuca_materija', title: 'Zagadjujuća materija' },
  { width: 150, dataIndex: 'srednja_godisnja_koncentracija', title: 'Srednja godišnja koncentracija (mg/Nm3)' },
  { width: 150, dataIndex: 'kolicina_u_normalnim_uslovima_g_h', title: 'Količina u normalnim uslovima (g/h)' },
  { width: 150, dataIndex: 'kolicina_u_normalnim_uslovima_kg_god', title: 'Količina u normalnim uslovima (kg/god)' },
  { width: 200, dataIndex: 'kolicina_u_akcidentnim_situacijama', title: 'Količina u akcidentnim situacijama (kg/god)' },
  {
    render: (record: Obrazac2ZagadljiveMaterije) => <YesNoCell yesNo={record.obrazac2} />,
    title: 'Obrazac 2',
    width: 100,
  },
  { render: (record: Obrazac2ZagadljiveMaterije) => <YesNoCell yesNo={record.gve} />, title: 'GVE', width: 100 },
  {
    render: (record: Obrazac2ZagadljiveMaterije) => <DateCell date={record.kreirano} showTime={true} />,
    width: 200,
    title: 'Kreirano',
  },
  {
    render: (record: Obrazac2ZagadljiveMaterije) => <DateCell date={record.azurirano} showTime={true} />,
    width: 200,
    title: 'Ažurirano',
  },
  { width: 250, dataIndex: 'napomena', title: 'Napomena' },
];

export const OBRAZAC_2_GORIVO_COLUMNS: IIzvestajiColumnProps<Obrazac2Gorivo>[] = [
  { width: 100, dataIndex: 'godina', title: 'Godina' },
  { width: 150, dataIndex: 'pib', title: 'PIB' },
  { width: 250, dataIndex: 'preduzece', title: 'Preduzeće' },
  { width: 200, dataIndex: 'sifra_pretezne_delatnosti', title: 'Šifra pretežne delatnosti' },
  {
    width: 150,
    dataIndex: 'nacionalni_id',
    title: 'Nacionalni ID',
  },
  {
    width: 200,
    dataIndex: 'postrojenje',
    title: 'Postrojenje',
  },
  {
    width: 200,
    dataIndex: 'opstina_postrojenja',
    title: 'Opština postrojenja',
  },
  { render: (record: Obrazac2Gorivo) => <YesNoCell yesNo={record.prtr} />, title: 'PRTR', width: 100 },
  { width: 200, dataIndex: 'aktivnosti', title: 'Aktivnosti' },
  { width: 200, dataIndex: 'naziv_izvora', title: 'Naziv izvora' },
  { width: 150, dataIndex: 'broj_izvora', title: 'Broj izvora' },
  { width: 150, dataIndex: 'tip_izvora', title: 'Tip izvora' },
  { width: 150, dataIndex: 'naziv_goriva', title: 'Naziv goriva' },
  { width: 200, dataIndex: 'godisnja_potrosnja_goriva', title: 'Godišnja potrošnja goriva' },
  { width: 200, dataIndex: 'jed_mere', title: 'Jed. mere' },
  { width: 200, dataIndex: 'donja_toplotna_moc_goriva', title: 'Donja toplotna moć goriva' },
  {
    render: (record: Obrazac2Gorivo) => <YesNoCell yesNo={record.obrazac2} />,
    title: 'Obrazac 2',
    width: 100,
  },
  { render: (record: Obrazac2Gorivo) => <YesNoCell yesNo={record.gve} />, title: 'GVE', width: 100 },
  {
    render: (record: Obrazac2Gorivo) => <DateCell date={record.kreirano} showTime={true} />,
    width: 200,
    title: 'Kreirano',
  },
  {
    render: (record: Obrazac2Gorivo) => <DateCell date={record.azurirano} showTime={true} />,
    width: 200,
    title: 'Ažurirano',
  },
  { width: 250, dataIndex: 'napomena', title: 'Napomena' },
];

export const OBRAZAC_2_OPSTE_COLUMNS: IIzvestajiColumnProps<Obrazac2Opste>[] = [
  { width: 100, dataIndex: 'godina', title: 'Godina' },
  { width: 150, dataIndex: 'pib', title: 'PIB' },
  { width: 250, dataIndex: 'preduzece', title: 'Preduzeće' },
  {
    width: 150,
    dataIndex: 'nacionalni_id',
    title: 'Nacionalni ID',
  },
  {
    width: 200,
    dataIndex: 'postrojenje',
    title: 'Postrojenje',
  },
  {
    width: 200,
    dataIndex: 'opstina_postrojenja',
    title: 'Opština postrojenja',
  },
  { render: (record: Obrazac2Opste) => <YesNoCell yesNo={record.prtr} />, title: 'PRTR', width: 100 },
  { width: 200, dataIndex: 'aktivnosti', title: 'Aktivnosti' },
  { width: 200, dataIndex: 'naziv_izvora', title: 'Naziv izvora' },
  { width: 150, dataIndex: 'broj_izvora', title: 'Broj izvora' },
  { width: 150, dataIndex: 'vrsta_izvora', title: 'Vrsta izvora' },
  { width: 150, dataIndex: 'geografska_sirina', title: 'Geografska širina' },
  { width: 150, dataIndex: 'geografska_duzina', title: 'Geografska dužina' },
  { width: 150, dataIndex: 'nadmorska_visina', title: 'Nadmorska visina (m)' },
  { width: 150, dataIndex: 'instalisana_toplotna_snaga_na_ulazu', title: 'Instalisana toplotna snaga na ulazu (MW)' },
  { width: 150, dataIndex: 'godisnja_iskoriscenost_izvora', title: 'Godišnja iskorišćenost kapaciteta (%)' },
  { width: 150, dataIndex: 'visina_izvora', title: 'Visina izvora (m)' },
  { width: 150, dataIndex: 'unutrasnji_precnik_izvora_na_vrhu', title: 'Unutrašnji prečnik izvora na vrh' },
  {
    width: 150,
    dataIndex: 'srednja_godisnja_temperatura_izlaznih_gasova',
    title: 'Srednja godišnja temperatura izlaznih gasova',
  },
  {
    width: 150,
    dataIndex: 'srednja_godisnja_brzina_izlaznih_gasova',
    title: 'Srednja godišnja brzina izlaznih gasova',
  },
  {
    width: 150,
    dataIndex: 'srednji_godisnji_protok_na_mernom_mestu',
    title: 'Srednji godišnji protok na mernom mestu',
  },
  { width: 150, dataIndex: 'ukupan_broj_radnih_sati_godisnje', title: 'Ukupan broj radnih sati godišnje' },
  { width: 150, dataIndex: 'raspodela_emisija_zima', title: 'Raspodela emisija - Zima' },
  { width: 150, dataIndex: 'raspodela_emisija_prolece', title: 'Raspodela emisija - Proleće' },
  { width: 150, dataIndex: 'raspodela_emisija_leto', title: 'Emisije u vazduh - Leto' },
  { width: 150, dataIndex: 'raspodela_emisija_jesen', title: 'Raspodela emisija - Jesen' },
  {
    render: (record: Obrazac2Opste) => <YesNoCell yesNo={record.obrazac_2} />,
    title: 'Obrazac 2',
    width: 100,
  },
  { render: (record: Obrazac2Opste) => <YesNoCell yesNo={record.gve} />, title: 'GVE', width: 100 },
];

export const OBRAZAC_2_PREGLED_PO_GODINAMA_COLUMNS: (
  data: Obrazac2PregledPoGodinama[]
) => IIzvestajiColumnProps<Obrazac2PregledPoGodinama>[] = data => [
  { width: 150, dataIndex: 'pib', title: 'PIB' },
  { width: 250, dataIndex: 'preduzece', title: 'Preduzeće' },
  {
    width: 150,
    dataIndex: 'nacionalni_id',
    title: 'Nacionalni ID',
  },
  {
    width: 200,
    dataIndex: 'postrojenje',
    title: 'Postrojenje',
  },
  { width: 200, dataIndex: 'mesto_postrojenja', title: 'Mesto postrojenja' },
  { width: 200, dataIndex: 'opstina_postrojenja', title: 'Opština postrojenja' },
  { width: 200, dataIndex: 'zagadjujuca_materija', title: 'Zagađujuća materija' },
  ...obrazacIzvestajiService.getYearlyColumns(data),
];

export const OBRAZAC_2_IZVORI_PO_GODINAMA_COLUMNS: (
  data: Obrazac2IzvoriPoGodinama[]
) => IIzvestajiColumnProps<Obrazac2IzvoriPoGodinama>[] = data => [
  { width: 150, dataIndex: 'pib', title: 'PIB' },
  { width: 250, dataIndex: 'preduzece', title: 'Preduzeće' },
  {
    width: 150,
    dataIndex: 'nacionalni_id',
    title: 'Nacionalni ID',
  },
  {
    width: 200,
    dataIndex: 'postrojenje',
    title: 'Postrojenje',
  },
  { width: 200, dataIndex: 'oznaka_izvora', title: 'Oznaka izvora' },
  { width: 200, dataIndex: 'izvor_zagadjivanja', title: 'Izvor zagađivanja' },
  { width: 200, dataIndex: 'zagadjujuca_materija', title: 'Zagađujuća materija' },
  ...obrazacIzvestajiService.getYearlyColumns(data),
];

export const OBRAZAC_2_PRTR_KATEGORIJE_COLUMNS: IIzvestajiColumnProps<Obrazac2PRTRKategorije>[] = [
  { width: 150, dataIndex: 'redni_broj', title: 'Redni broj' },
  { width: 300, dataIndex: 'kategorija', title: 'Kategorija' },
  { width: 150, dataIndex: 'nh3', title: 'NH3' },
  { width: 150, dataIndex: 'nox', title: 'NOX' },
  { width: 150, dataIndex: 'pm10', title: 'PM10' },
  { width: 150, dataIndex: 'sox', title: 'SOX' },
];

export const OBRAZAC_2_KONTROLA_UNOSA_COLUMNS: (
  data: Obrazac2KontrolaUnosa[]
) => IIzvestajiColumnGroupProps<Obrazac2KontrolaUnosa>[] = data => [
  ...obrazacIzvestajiService.getComparisonByYearGroupColumns<Obrazac2KontrolaUnosa, Obrazac2KontrolaUnosaGrupaKolona>(
    data,

    [
      { width: 150, dataIndex: 'izmena_za_godinu', title: 'Izmena za godinu' },
      { width: 150, dataIndex: 'pib', title: 'PIB' },
      { width: 250, dataIndex: 'preduzece', title: 'Preduzeće' },
      { width: 150, dataIndex: 'nacionalni_id', title: 'Nacionalni ID' },
      { width: 250, dataIndex: 'postrojenje', title: 'Postrojenje' },
      { width: 150, dataIndex: 'prtr', title: 'PRTR' },
      { width: 150, dataIndex: 'oznaka_izvora', title: 'Oznaka izvora' },
      { width: 250, dataIndex: 'naziv_izvora', title: 'Naziv izvora' },
      { width: 150, dataIndex: 'protok', title: 'Protok' },
      { width: 150, dataIndex: 'broj_radnih_sati_godisnje', title: 'Broj radnih sati godišnje' },
      { width: 250, dataIndex: 'zagadjujuca_materija', title: 'Zagađujuća materija' },
      { width: 150, dataIndex: 'obrazac_2', title: 'Obrazac 2' },
      { width: 150, dataIndex: 'gve', title: 'GVE' },
    ],
    [
      { valueAccessor: 'emitovano', title: 'Emitovano (kg/god)' },
      { valueAccessor: 'koncentracija', title: 'Koncentracija (mg/Nm3)' },
    ]
  ),
];

export const OBRAZAC_2_PRTR_PREGLED_PO_GODINAMA_COLUMNS: (
  data: Obrazac2PrtrPregledPoGodinama[]
) => IIzvestajiColumnOrGroupProps<Obrazac2PrtrPregledPoGodinama>[] = data => [
  ...obrazacIzvestajiService.renderYearlyColumnsWithTotal(
    [
      { width: 150, dataIndex: 'pib', title: 'PIB' },
      { width: 250, dataIndex: 'preduzece', title: 'Preduzeće' },
      { width: 150, dataIndex: 'nacionalni_id', title: 'Nacionalni ID' },
      { width: 250, dataIndex: 'postrojenje', title: 'Postrojenje' },
      { width: 150, dataIndex: 'istorijski_id', title: 'Istorijski ID' },
      { width: 200, dataIndex: 'mesto_postrojenja', title: 'Mesto postrojenja' },
      { width: 250, dataIndex: 'opstina_postrojenja', title: 'Opština postrojenja' },
      { width: 250, dataIndex: 'zagadjujuca_materija', title: 'Zagađujuća materija' },
    ],
    data
  ),
];
//#endregion OBRAZAC 2

//#region OBRAZAC 3

export const OBRAZAC_3_ZAGADLJIVE_MATERIJE_COLUMNS: IIzvestajiColumnProps<Obrazac3ZagadjujuceMaterije>[] = [
  { width: 100, dataIndex: 'godina', title: 'Godina' },
  { width: 150, dataIndex: 'pib', title: 'PIB' },
  { width: 250, dataIndex: 'preduzece', title: 'Preduzeće' },
  {
    width: 150,
    dataIndex: 'nacionalni_id',
    title: 'Nacionalni ID',
  },
  {
    width: 200,
    dataIndex: 'postrojenje',
    title: 'Postrojenje',
  },
  {
    width: 200,
    dataIndex: 'opstina_postrojenja',
    title: 'Opština postrojenja',
  },
  { render: (record: Obrazac3ZagadjujuceMaterije) => <YesNoCell yesNo={record.prtr} />, title: 'PRTR', width: 100 },
  { width: 200, dataIndex: 'aktivnosti', title: 'Aktivnosti' },
  { width: 200, dataIndex: 'naziv_ispusta', title: 'Naziv ispusta' },
  { width: 100, dataIndex: 'broj_ispusta', title: 'Broj ispusta' },
  { width: 200, dataIndex: 'vrsta_ispusta', title: 'Vrsta recipijenta' },
  { width: 200, dataIndex: 'naziv_recipijenta', title: 'Naziv recipijenta' },
  { width: 200, dataIndex: 'sliv', title: 'Sliv' },
  {
    width: 150,
    dataIndex: 'vremenski_period_ispustanja_vode_dan_god',
    title: 'Vremenski period ispuštanja vode (dan/god)',
  },
  { width: 150, dataIndex: 'ukupna_kolicina_ispustene_vode_m3_god', title: 'Ukupna količina ispuštene vode (m3/god)' },
  {
    width: 150,
    dataIndex: 'srednji_dnevni_protok_otpdane_vode_m3_dan',
    title: 'Srednji dnevni protok otpadne vode (m3/dan)',
  },
  { width: 200, dataIndex: 'zagadjujuca_materija', title: 'Zagađujuća materija' },
  {
    width: 150,
    dataIndex: 'srednja_godisnja_izmerena_vrednost_mg_l',
    title: 'Srednja godišnja izmerena vrednost (mg/l)',
  },
  { width: 150, dataIndex: 'kolicina_pri_redovnom_radu_kg_god', title: 'Količina pri redovnom radu (kg/god)' },
  {
    width: 150,
    dataIndex: 'kolicina_u_akcidentnim_situacijama_kg_god',
    title: 'Količina u akcidentnim situacijama (kg/god)',
  },
  {
    render: (record: Obrazac3ZagadjujuceMaterije) => <DateCell date={record.kreirano} showTime={true} />,
    width: 200,
    title: 'Kreirano',
  },
  {
    render: (record: Obrazac3ZagadjujuceMaterije) => <DateCell date={record.azurirano} showTime={true} />,
    width: 200,
    title: 'Ažurirano',
  },
  { width: 250, dataIndex: 'napomena', title: 'Napomena' },
];

export const OBRAZAC_3_PREGLED_PO_GODINAMA_COLUMNS: (
  data: Obrazac3PregledPoGodinama[]
) => IIzvestajiColumnProps<Obrazac3PregledPoGodinama>[] = data => [
  { width: 150, dataIndex: 'pib', title: 'PIB' },
  { width: 250, dataIndex: 'preduzece', title: 'Preduzeće' },
  {
    width: 150,
    dataIndex: 'nacionalni_id',
    title: 'Nacionalni ID',
  },
  {
    width: 200,
    dataIndex: 'postrojenje',
    title: 'Postrojenje',
  },
  { width: 200, dataIndex: 'zagadjujuca_materija', title: 'Zagađujuća materija' },

  ...obrazacIzvestajiService.getYearlyColumns(data),
];

export const OBRAZAC_3_ISPUST_PO_GODINAMA_COLUMNS: (
  data: Obrazac3IspustPoGodinama[]
) => IIzvestajiColumnProps<Obrazac3IspustPoGodinama>[] = data => [
  { width: 150, dataIndex: 'pib', title: 'PIB' },
  { width: 250, dataIndex: 'preduzece', title: 'Preduzeće' },
  {
    width: 150,
    dataIndex: 'nacionalni_id',
    title: 'Nacionalni ID',
  },
  {
    width: 200,
    dataIndex: 'postrojenje',
    title: 'Postrojenje',
  },
  { width: 100, dataIndex: 'oznaka_ispusta', title: 'Oznaka ispusta' },
  { width: 200, dataIndex: 'naziv_ispusta', title: 'Naziv ispusta' },
  { width: 200, dataIndex: 'zagadjujuca_materija', title: 'Zagađujuća materija' },
  ...obrazacIzvestajiService.getYearlyColumns(data),
];

export const OBRAZAC_3_OPSTE_COLUMNS: IIzvestajiColumnProps<Obrazac3Opste>[] = [
  { width: 100, dataIndex: 'godina', title: 'Godina' },
  { width: 150, dataIndex: 'pib', title: 'PIB' },
  { width: 250, dataIndex: 'preduzece', title: 'Preduzeće' },
  {
    width: 150,
    dataIndex: 'nacionalni_id',
    title: 'Nacionalni ID',
  },
  {
    width: 200,
    dataIndex: 'postrojenje',
    title: 'Postrojenje',
  },
  {
    width: 200,
    dataIndex: 'opstina_postrojenja',
    title: 'Opština postrojenja',
  },
  { render: (record: Obrazac3Opste) => <YesNoCell yesNo={record.prtr} />, title: 'PRTR', width: 100 },
  { width: 200, dataIndex: 'aktivnosti', title: 'Aktivnosti' },
  { width: 200, dataIndex: 'naziv_ispusta', title: 'Naziv ispusta' },
  { width: 100, dataIndex: 'oznaka_ispusta', title: 'Oznaka ispusta' },
  { width: 200, dataIndex: 'vrsta_recipijenta', title: 'Vrsta recipijenta' },
  { width: 200, dataIndex: 'naziv_recipijenta', title: 'Naziv recipijenta' },
  { width: 150, dataIndex: 'geografska_sirina', title: 'Geografska širina' },
  { width: 150, dataIndex: 'geografska_duzina', title: 'Geografska dužina' },
  {
    render: (record: Obrazac3Opste) => <YesNoCell yesNo={record.rashladne} />,
    title: 'Rashladne',
    width: 150,
  },
  {
    render: (record: Obrazac3Opste) => <YesNoCell yesNo={record.atmosferske} />,
    title: 'Atmosferske',
    width: 150,
  },
  {
    render: (record: Obrazac3Opste) => <YesNoCell yesNo={record.sanitarne} />,
    title: 'Sanitarne',
    width: 150,
  },
  {
    render: (record: Obrazac3Opste) => <YesNoCell yesNo={record.tehnoloske} />,
    title: 'Tehnološke',
    width: 150,
  },
  { width: 150, dataIndex: 'period_ispustanja', title: 'Period ispuštanja' },
  { width: 150, dataIndex: 'rezim_rada', title: 'Režim rada' },
  { width: 150, dataIndex: 'ukupna_kolicina_ispustene_vode', title: 'Ukupna količina ispuštene vode' },
  {
    render: (record: Obrazac3Opste) => <DateCell date={record.kreirano} showTime={true} />,
    width: 200,
    title: 'Kreirano',
  },
  {
    render: (record: Obrazac3Opste) => <DateCell date={record.azurirano} showTime={true} />,
    width: 200,
    title: 'Ažurirano',
  },
];

export const OBRAZAC_3_KONTROLA_UNOSA_COLUMNS: (
  data: Obrazac3KontrolaUnosa[]
) => IIzvestajiColumnProps<Obrazac3KontrolaUnosa>[] = data => [
  { width: 150, dataIndex: 'godina', title: 'Izmena za godinu' },
  { width: 150, dataIndex: 'pib', title: 'PIB' },
  { width: 250, dataIndex: 'preduzece', title: 'Preduzeće' },
  {
    width: 150,
    dataIndex: 'nacionalni_id',
    title: 'Nacionalni ID',
  },
  {
    width: 200,
    dataIndex: 'postrojenje',
    title: 'Postrojenje',
  },
  { render: (record: Obrazac3KontrolaUnosa) => <YesNoCell yesNo={record.prtr} />, title: 'PRTR', width: 100 },
  { width: 100, dataIndex: 'oznaka_ispusta', title: 'Oznaka ispusta' },
  { width: 200, dataIndex: 'naziv_ispusta', title: 'Naziv ispusta' },
  { width: 200, dataIndex: 'zagadjujuca_materija', title: 'Zagađujuća materija' },

  ...obrazacIzvestajiService.getYearlyColumns(data),
];

export const OBRAZAC_3_PRTR_PREGLED_PO_GODINAMA_COLUMNS: (
  data: Obrazac3PrtrPregledPoGodinama[]
) => IIzvestajiColumnOrGroupProps<Obrazac3PrtrPregledPoGodinama>[] = data => {
  return obrazacIzvestajiService.renderYearlyColumnsWithTotal(
    [
      { width: 150, dataIndex: 'pib', title: 'PIB' },
      { width: 250, dataIndex: 'preduzece', title: 'Preduzeće' },
      {
        width: 150,
        dataIndex: 'nacionalni_id',
        title: 'Nacionalni ID',
      },
      {
        width: 150,
        dataIndex: 'istorijski_id',
        title: 'Istorijski ID',
      },
      {
        width: 200,
        dataIndex: 'postrojenje',
        title: 'Postrojenje',
      },
      { width: 200, dataIndex: 'zagadjujuca_materija', title: 'Zagađujuća materija' },
    ],
    data
  );
};

//#endregion OBRAZAC 3

//#region OBRAZAC 5
export const OBRAZAC_5_POSTROJENJA_COLUMNS: IIzvestajiColumnProps<Obrazac5Postrojenja>[] = [
  { dataIndex: 'godina', title: 'Godina', width: '100px' },
  { dataIndex: 'pib', title: 'PIB', width: '150px' },
  { dataIndex: 'preduzece', title: 'Preduzeće', width: '300px' },
  { dataIndex: 'nacionalni_id', title: 'Nacionalni ID', width: '150px' },
  { dataIndex: 'postrojenje', title: 'Postrojenje', width: '300px' },
  { dataIndex: 'opstina_postrojenja', title: 'Opština postrojenja', width: '200px' },
  { dataIndex: 'aktivnosti', title: 'Aktivnosti', width: '150px' },
  { dataIndex: 'preneto_iz_prethodnog_perioda', title: 'Preneto iz prethodnog perioda (t)', width: '150px' },
  {
    dataIndex: 'godisnja_kolicina_proizvedenog_otpada',
    title: 'Godišnja količina proizvedenog otpada (t)',
    width: '150px',
  },
  {
    dataIndex: 'kolicina_otpada_predata_na_skladistenje',
    title: 'Količina otpada predata na skladištenje (t)',
    width: '150px',
  },
  {
    dataIndex: 'kolicina_otpada_predata_na_odlaganje',
    title: 'Količina otpada predata na odlaganje (t)',
    width: '150px',
  },
  { dataIndex: 'kolicina_otpada_predata_na_tretman', title: 'Količina otpada predata na tretman (t)', width: '150px' },
  { dataIndex: 'kolicina_izvezenog_otpada', title: 'Količina izvezenog otpada (t)', width: '150px' },
  { dataIndex: 'ostalo_na_lokaciji', title: 'Ostalo na lokaciji (t)', width: '150px' },
];

export const OBRAZAC_5_POSTROJENJA_INDEKSNI_BROJ_COLUMNS: IIzvestajiColumnProps<Obrazac5PostrojenjaIndeksniBroj>[] = [
  { dataIndex: 'godina', title: 'Godina', width: '100px' },
  { dataIndex: 'pib', title: 'PIB', width: '150px' },
  { dataIndex: 'preduzece', title: 'Preduzeće', width: '300px' },
  { dataIndex: 'sifra_pretezne_delatnosti', title: 'Šifra pretežne delatnosti', width: '150px' },
  { dataIndex: 'nacionalni_id', title: 'Nacionalni ID', width: '150px' },
  { dataIndex: 'postrojenje', title: 'Postrojenje', width: '300px' },
  { dataIndex: 'opstina_postrojenja', title: 'Opština postrojenja', width: '200px' },
  { dataIndex: 'aktivnosti', title: 'Aktivnosti', width: '150px' },
  { dataIndex: 'indeksni_broj', title: 'Indeksni broj', width: '100px' },
  {
    title: 'Opasan',
    render: (record: Obrazac5PostrojenjaIndeksniBroj) => <YesNoCell yesNo={record.opasan} />,
    width: '100px',
  },
  { dataIndex: 'naziv_otpada', title: 'Naziv otpada', width: '200px' },
  { dataIndex: 'vrsta_otpada', title: 'Vrsta otpada', width: '300px' },
  {
    dataIndex: 'godisnja_kolicina_proizvedenog_otpada',
    title: 'Godišnja količina proizvedenog otpada (t)',
    width: '150px',
  },
  {
    dataIndex: 'kolicina_otpada_predata_na_skladistenje',
    title: 'Količina otpada predata na skladištenje (t)',
    width: '150px',
  },
  {
    dataIndex: 'kolicina_otpada_predata_na_odlaganje',
    title: 'Količina otpada predata na odlaganje (t)',
    width: '150px',
  },
  { dataIndex: 'kolicina_otpada_predata_na_tretman', title: 'Količina otpada predata na tretman (t)', width: '150px' },
  { dataIndex: 'kolicina_izvezenog_otpada', title: 'Količina izvezenog otpada (t)', width: '150px' },
  { dataIndex: 'y1', title: 'Y1', width: '100px' },
  { dataIndex: 'y2', title: 'Y2', width: '100px' },
  { dataIndex: 'y3', title: 'Y3', width: '100px' },
];

export const OBRAZAC_5_GRUPA_PODGRUPA_INDEKSNI_BROJ_COLUMNS: IIzvestajiColumnProps<Obrazac5GrupaPodgrupaIndeksniBroj>[] =
  [
    { dataIndex: 'indeksni_broj', title: 'Indeksni broj' },
    { dataIndex: 'opis', title: 'Opis' },
    { dataIndex: 'kolicina_t', title: 'Količina (t)' },
  ];

export const OBRAZAC_5_OTPAD_NE_OPASAN_COLUMNS: IIzvestajiColumnProps<Obrazac5NeOpasan>[] = [
  { dataIndex: 'grupa', title: 'Grupa' },
  { dataIndex: 'kolicina_neopasan_t', title: 'Količina neopasnog otpada (t)' },
  { dataIndex: 'kolicina_opasan_t', title: 'Količina opasnog otpada (t)' },
];

export const OBRAZAC_5_GENERISANJE_OTPADA_PREGLED_PO_GODINAMA_COLUMNS: (
  data: Obrazac5PregledPoGodinama[]
) => IIzvestajiColumnProps<Obrazac5PregledPoGodinama>[] = data => [
  { dataIndex: 'pib', title: 'PIB', width: '150px' },
  { dataIndex: 'preduzece', title: 'Preduzeće', width: '300px' },
  { dataIndex: 'nacionalni_id', title: 'Nacionalni ID', width: '150px' },
  { dataIndex: 'postrojenje', title: 'Postrojenje', width: '300px' },
  { dataIndex: 'indeksni_broj', title: 'Indeksni broj', width: '150px' },
  { dataIndex: 'opis_otpada', title: 'Opis otpada', width: '300px' },
  ...obrazacIzvestajiService.getYearlyColumns(data),
];

export const OBRAZAC_5_POSTROJENJA_ZA_PLACANJE_COLUMNS: IIzvestajiColumnProps<Obrazac5PostrojenjaZaPlacanje>[] = [
  { dataIndex: 'godina', title: 'Godina', width: '150px' },
  { dataIndex: 'pib', title: 'PIB', width: '150px' },
  { dataIndex: 'preduzece', title: 'Preduzeće', width: '300px' },
  { dataIndex: 'nacionalni_id', title: 'Nacionalni ID', width: '150px' },
  { dataIndex: 'postrojenje', title: 'Postrojenje', width: '300px' },
  { dataIndex: 'opstina_postrojenja', title: 'Opština postrojenja', width: '200px' },
  {
    render: (record: Obrazac5PostrojenjaZaPlacanje) => <YesNoCell yesNo={record.ippc} />,
    title: 'IPPC',
    width: '100px',
  },
  { dataIndex: 'aktivnosti', title: 'Aktivnosti', width: '150px' },
  { dataIndex: 'preneto_iz_prethodnog_perioda', title: 'Preneto iz prethodnog perioda (t)', width: '150px' },
  {
    dataIndex: 'godisnja_kolicina_proizvedenog_otpada',
    title: 'Godišnja količina proizvedenog otpada (t)',
    width: '150px',
  },
  {
    dataIndex: 'kolicina_otpada_predata_na_skladistenje',
    title: 'Količina otpada predata na skladištenje (t)',
    width: '150px',
  },
  {
    dataIndex: 'kolicina_otpada_predata_na_odlaganje',
    title: 'Količina otpada predata na odlaganje (t)',
    width: '150px',
  },
  { dataIndex: 'kolicina_otpada_predata_na_tretman', title: 'Količina otpada predata na tretman (t)', width: '150px' },
  { dataIndex: 'kolicina_izvezenog_otpada', title: 'Količina izvezenog otpada (t)', width: '150px' },
  { dataIndex: 'ostalo_na_lokaciji', title: 'Ostalo na lokaciji (t)', width: '150px' },
];

export const OBRAZAC_5_KONTROLA_UNOSA_COLUMNS: (
  data: Obrazac5KontrolaUnosa[]
) => IIzvestajiColumnProps<Obrazac5KontrolaUnosa>[] = data => [
  { width: 150, dataIndex: 'godina', title: 'Izmena za godinu' },
  { width: 150, dataIndex: 'pib', title: 'PIB' },
  { width: 250, dataIndex: 'preduzece', title: 'Preduzeće' },
  { width: 150, dataIndex: 'nacionalni_id', title: 'Nacionalni ID' },
  { width: 250, dataIndex: 'postrojenje', title: 'Postrojenje' },
  { width: 200, dataIndex: 'indeksni_broj', title: 'Indeksni broj' },

  { width: 250, dataIndex: 'opis_otpada', title: 'Opis otpada' },
  ...obrazacIzvestajiService.getYearlyColumns(data),
];

export const OBRAZAC_5_PRTR_PREGLED_PO_GODINAMA_COLUMNS: (
  data: Obrazac5PRTRPregledPoGodinama[]
) => IIzvestajiColumnOrGroupProps<Obrazac5PRTRPregledPoGodinama>[] = data => [
  ...obrazacIzvestajiService.renderYearlyColumnsWithTotal(
    [
      { width: 150, dataIndex: 'pib', title: 'PIB' },
      { width: 250, dataIndex: 'preduzece', title: 'Preduzeće' },
      { width: 150, dataIndex: 'nacionalni_id', title: 'Nacionalni ID' },
      { width: 150, dataIndex: 'istorijski_id', title: 'Istorijski ID' },
      { width: 250, dataIndex: 'postrojenje', title: 'Postrojenje' },
      { width: 200, dataIndex: 'indeksni_broj', title: 'Indeksni broj' },
      { width: 250, dataIndex: 'opis_otpada', title: 'Opis otpada' },
    ],
    data
  ),
];
//#endregion OBRAZAC 5

//#region GIO 2

export const GIO_2_POSTROJENJA_INDEKSNI_BROJ_COLUMNS: IIzvestajiColumnProps<Gio2PostrojenjaIndeksniBroj>[] = [
  { width: 100, dataIndex: 'godina', title: 'Godina' },
  { width: 150, dataIndex: 'pib', title: 'PIB' },
  { width: 250, dataIndex: 'preduzece', title: 'Preduzeće' },
  {
    width: 150,
    dataIndex: 'nacionalni_id',
    title: 'Nacionalni ID',
  },
  {
    width: 200,
    dataIndex: 'postrojenje',
    title: 'Postrojenje',
  },
  {
    width: 200,
    dataIndex: 'opstina_postrojenja',
    title: 'Opština postrojenja',
  },
  { width: 120, dataIndex: 'indeksni_broj', title: 'Indeksni broj' },
  {
    render: (record: Gio2PostrojenjaIndeksniBroj) => <YesNoCell yesNo={record.opasan} />,
    title: 'Opasan',
    width: 150,
  },
  { width: 200, dataIndex: 'naziv_otpada', title: 'Naziv otpada' },
  { width: 200, dataIndex: 'ukupno_preuzeto', title: 'Ukupno preuzeto (t)' },
  { width: 200, dataIndex: 'ukupno_odlozeno', title: 'Ukupno odloženo (t)' },
  { width: 200, dataIndex: 'stanje_na_skladistu_1_jan', title: 'Stanje na skladištu 1.jan (t)' },
  { width: 200, dataIndex: 'stanje_na_skladistu_31_dec', title: 'Stanje na skladištu 31.dec (t)' },
  { width: 200, dataIndex: 'kolicina_za_d1_postupak', title: 'Količina za D1 postupak (t)' },
  { width: 200, dataIndex: 'kolicina_za_d10_postupak', title: 'Količina za D10 postupak (t)' },
  { width: 200, dataIndex: 'drugi_d_postupak_1', title: 'Drugi D postupak 1' },
  { width: 200, dataIndex: 'kolicina_za_drugi_d_postupak_1', title: 'Količina za drugi D postupak 1' },
  { width: 200, dataIndex: 'drugi_d_postupak_2', title: 'Drugi D postupak 2' },
  { width: 200, dataIndex: 'kolicina_za_drugi_d_postupak_2', title: 'Količina za drugi D postupak 2' },
];

export const GIO_2_GRUPA_PODGRUPA_INDEKSNI_BROJ_COLUMNS: IIzvestajiColumnProps<Gio2GrupaPodgrupaIndeksniBroj>[] = [
  { width: 150, dataIndex: 'indeksni_broj', title: 'Indeksni broj' },
  { width: 250, dataIndex: 'naziv_otpada', title: 'Opis' },
  { width: 300, dataIndex: 'kolicina', title: 'Količina (t)' },
];

export const GIO_2_ODLAGANJE_OTPADA_PREGLED_PO_GODINAMA_COLUMNS: (
  data: Gio2GodisnjeOdlaganjeOtpada[]
) => IIzvestajiColumnProps<Gio2GodisnjeOdlaganjeOtpada>[] = data => [
  { width: 150, dataIndex: 'pib', title: 'PIB' },
  { width: 250, dataIndex: 'preduzece', title: 'Preduzeće' },
  {
    width: 150,
    dataIndex: 'nacionalni_id',
    title: 'Nacionalni ID',
  },
  {
    width: 200,
    dataIndex: 'postrojenje',
    title: 'Postrojenje',
  },
  { width: 120, dataIndex: 'indeksni_broj', title: 'Indeksni broj' },
  { width: 200, dataIndex: 'opis_otpada', title: 'Opis otpada' },
  ...obrazacIzvestajiService.getYearlyColumns(data),
];

export const GIO_2_ODLAGANJE_OTPADA_KONTROLA_UNOSA_COLUMNS: (
  data: Gio2OdlaganjeOtpadaKontrolaUnosa[]
) => IIzvestajiColumnProps<Gio2OdlaganjeOtpadaKontrolaUnosa>[] = data => [
  { width: 150, dataIndex: 'izmena_za_godinu', title: 'Izmena za godinu' },
  { width: 150, dataIndex: 'pib', title: 'PIB' },
  { width: 250, dataIndex: 'preduzece', title: 'Preduzeće' },
  {
    width: 150,
    dataIndex: 'nacionalni_id',
    title: 'Nacionalni ID',
  },
  {
    width: 200,
    dataIndex: 'postrojenje',
    title: 'Postrojenje',
  },
  { width: 120, dataIndex: 'indeksni_broj', title: 'Indeksni broj' },
  ...obrazacIzvestajiService.getYearlyColumns(data),
];

export const GIO_2_ELEKTRICNI_I_ELEKTRONSKI_OTPAD_COLUMNS: IIzvestajiColumnProps<Gio2ElektricniIElektronskiOtpad>[] = [
  { width: 100, dataIndex: 'godina', title: 'Godina' },
  { width: 150, dataIndex: 'pib', title: 'PIB' },
  { width: 250, dataIndex: 'preduzece', title: 'Preduzeće' },
  {
    width: 150,
    dataIndex: 'nacionalni_id',
    title: 'Nacionalni ID',
  },
  {
    width: 200,
    dataIndex: 'postrojenje',
    title: 'Postrojenje',
  },
  { width: 120, dataIndex: 'indeksni_broj', title: 'Indeksni broj' },
  { width: 200, dataIndex: 'ukupno_preuzeto', title: 'Ukupno preuzeto (t)' },
  { width: 200, dataIndex: 'ukupno_odlozeno', title: 'Ukupno odloženo (t)' },
  { width: 200, dataIndex: 'stanje_na_deponiji_1_jan', title: 'Stanje na deponiji 1.jan (t)' },
  { width: 200, dataIndex: 'stanje_na_deponiji_31_dec', title: 'Stanje na deponiji 31.dec (t)' },
  { width: 150, dataIndex: 'd_oznaka_1', title: 'D oznaka 1' },
  { width: 200, dataIndex: 'kolicina_d_oznaka_1', title: 'Količina D oznaka 1 (t)' },
  { width: 150, dataIndex: 'd_oznaka_2', title: 'Drugi D postupak 2' },
  { width: 200, dataIndex: 'kolicina_d_oznaka_2', title: 'Količina za drugi D postupak 2' },
  { width: 200, dataIndex: 'razred', title: 'Razred' },
  { width: 300, dataIndex: 'vrsta_ee_otpada', title: 'Vrsta EE otpada' },
  { width: 200, dataIndex: 'kolicina', title: 'Količina EE (t)' },
];

//#endregion

//#region GIO 3
export const GIO_3_POSTROJENJA_INDEKSNI_BROJ_COLUMNS: IIzvestajiColumnProps<Gio3PostrojenjaIndeksniBroj>[] = [
  { width: 100, dataIndex: 'godina', title: 'Godina' },
  { width: 150, dataIndex: 'pib', title: 'PIB' },
  { width: 250, dataIndex: 'preduzece', title: 'Preduzeće' },
  {
    width: 150,
    dataIndex: 'nacionalni_id',
    title: 'Nacionalni ID',
  },
  {
    width: 200,
    dataIndex: 'postrojenje',
    title: 'Postrojenje',
  },
  {
    width: 200,
    dataIndex: 'opstina_postrojenja',
    title: 'Opština postrojenja',
  },
  { width: 120, dataIndex: 'indeksni_broj', title: 'Indeksni broj' },
  {
    render: (record: Gio3PostrojenjaIndeksniBroj) => <YesNoCell yesNo={record.opasan} />,
    title: 'Opasan',
    width: 150,
  },
  { width: 200, dataIndex: 'naziv_otpada', title: 'Naziv otpada' },
  { width: 200, dataIndex: 'ukupno_preuzeto', title: 'Ukupno preuzeto (t)' },
  { width: 200, dataIndex: 'ukupno_tretirano', title: 'Ukupno tretirano (t)' },
  { width: 300, dataIndex: 'stanje_privremenog_skladista_na_dan_1_januar', title: 'Stanje na skladištu 1.jan (t)' },
  { width: 300, dataIndex: 'stanje_privremenog_skladista_na_dan_31_decembar', title: 'Stanje na skladištu 31.dec (t)' },
  { width: 300, dataIndex: 'kolicina_za_r1_tretman', title: 'Količina za R1 tretman (t)' },
  { width: 300, dataIndex: 'kolicina_za_r3_tretman', title: 'Količina za R3 tretman (t)' },
  { width: 300, dataIndex: 'drugi_r_tretman_1', title: 'Drugi R tretman 1' },
  { width: 300, dataIndex: 'kolicina_za_drugi_r_tretman_1', title: 'Količina za drugi R tretman 1 (t)' },
  { width: 300, dataIndex: 'drugi_r_tretman_2', title: 'Drugi R tretman 2' },
  { width: 300, dataIndex: 'kolicina_za_drugi_r_tretman_2', title: 'Količina za drugi R tretman 2 (t)' },
];

export const GIO_3_GRUPA_PODGRUPA_INDEKSNI_BROJ_COLUMNS: IIzvestajiColumnProps<Gio3GrupaPodgrupaIndeksniBroj>[] = [
  { width: 150, dataIndex: 'indeksni_broj', title: 'Indeksni broj' },
  { width: 250, dataIndex: 'opis', title: 'Opis' },
  { width: 300, dataIndex: 'kolicina_preuzetog_otpada', title: 'Količina preuzetog otpada (t)' },
  { width: 300, dataIndex: 'kolicina_tretiranog_otpada', title: 'Količina tretiranog otpada (t)' },
];

export const GIO_3_TRETMAN_OTPADA_PREGLED_PO_GODINAMA_COLUMNS: (
  data: Gio3TretmanOtpadaPregledPoGodinama[]
) => IIzvestajiColumnProps<Gio3TretmanOtpadaPregledPoGodinama>[] = data => [
  { width: 150, dataIndex: 'pib', title: 'PIB' },
  { width: 250, dataIndex: 'preduzece', title: 'Preduzeće' },
  {
    width: 150,
    dataIndex: 'nacionalni_id',
    title: 'Nacionalni ID',
  },
  {
    width: 200,
    dataIndex: 'postrojenje',
    title: 'Postrojenje',
  },
  { width: 120, dataIndex: 'indeksni_broj', title: 'Indeksni broj' },
  { width: 200, dataIndex: 'opis_otpada', title: 'Opis otpada' },
  ...obrazacIzvestajiService.getYearlyColumns(data),
];

export const GIO_3_TRETMAN_OTPADA_KONTROLA_UNOSA_COLUMNS: (
  data: Gio3TretmanOtpadaKontrolaUnosa[]
) => IIzvestajiColumnProps<Gio3TretmanOtpadaKontrolaUnosa>[] = data => [
  { width: 150, dataIndex: 'izmena_za_godinu', title: 'Izmena za godinu' },
  { width: 150, dataIndex: 'pib', title: 'PIB' },
  { width: 250, dataIndex: 'preduzece', title: 'Preduzeće' },
  {
    width: 150,
    dataIndex: 'nacionalni_id',
    title: 'Nacionalni ID',
  },
  {
    width: 200,
    dataIndex: 'postrojenje',
    title: 'Postrojenje',
  },
  { width: 120, dataIndex: 'indeksni_broj', title: 'Indeksni broj' },
  ...obrazacIzvestajiService.getYearlyColumns(data),
];

export const GIO_3_ELEKTRICNI_I_ELEKTRONSKI_OTPAD_COLUMNS: IIzvestajiColumnProps<Gio3ElektricniIElektronskiOtpad>[] = [
  { width: 100, dataIndex: 'godina', title: 'Godina' },
  { width: 150, dataIndex: 'pib', title: 'PIB' },
  { width: 250, dataIndex: 'preduzece', title: 'Preduzeće' },
  {
    width: 150,
    dataIndex: 'nacionalni_id',
    title: 'Nacionalni ID',
  },
  {
    width: 200,
    dataIndex: 'postrojenje',
    title: 'Postrojenje',
  },
  { width: 120, dataIndex: 'indeksni_broj', title: 'Indeksni broj' },
  { width: 300, dataIndex: 'preuzeto', title: 'Preuzeto (t)' },
  { width: 300, dataIndex: 'ponovo_iskorisceno', title: 'Ponovno iskorišćeno (t)' },
  { width: 300, dataIndex: 'stanje_privremenog_skladista_na_dan_1_januar', title: 'Stanje na skladištu 1.jan (t)' },
  { width: 300, dataIndex: 'stanje_privremenog_skladista_na_dan_31_decembar', title: 'Stanje na skladištu 31.dec (t)' },
  { width: 300, dataIndex: 'r_oznaka_1', title: 'R oznaka 1' },
  { width: 300, dataIndex: 'kolicina_r_oznaka_1', title: 'Količina R oznaka 1 (t)' },
  { width: 300, dataIndex: 'r_oznaka_2', title: 'R oznaka 2' },
  { width: 300, dataIndex: 'kolicina_r_oznaka_2', title: 'Količina R oznaka 2 (t)' },
  { width: 300, dataIndex: 'razred', title: 'Razred' },
  { width: 300, dataIndex: 'vrsta_ee_otpada', title: 'Vrsta EE otpada' },
  { width: 300, dataIndex: 'kolicina_ee', title: 'Količina EE (t)' },
];
//#endregion GIO 3

//#region GIO 4
export const GIO_4_POSTROJENJA_INDEKSNI_BROJ_COLUMNS: IIzvestajiColumnProps<Gio4PostrojenjaIndeksniBroj>[] = [
  { width: 100, dataIndex: 'godina', title: 'Godina' },
  { width: 150, dataIndex: 'pib', title: 'PIB' },
  { width: 250, dataIndex: 'preduzece', title: 'Preduzeće' },
  {
    width: 150,
    dataIndex: 'nacionalni_id',
    title: 'Nacionalni ID',
  },
  {
    width: 200,
    dataIndex: 'postrojenje',
    title: 'Postrojenje',
  },
  {
    width: 200,
    dataIndex: 'opstina_postrojenja',
    title: 'Opština postrojenja',
  },
  { width: 120, dataIndex: 'indeksni_broj', title: 'Indeksni broj' },
  {
    render: (record: Gio4PostrojenjaIndeksniBroj) => <YesNoCell yesNo={record.opasan} />,
    title: 'Opasan',
    width: 150,
  },
  { width: 200, dataIndex: 'naziv_otpada', title: 'Naziv otpada' },
  { width: 200, dataIndex: 'izvezeno', title: 'Ukupno izvezeno (t)' },
  { width: 200, dataIndex: 'zemlja_izvoza', title: 'Zemlja izvoza' },
  { width: 200, dataIndex: 'zemlja_tranzita', title: 'Zemlja tranzita' },
  { width: 100, dataIndex: 'y1', title: 'Y1' },
  { width: 100, dataIndex: 'y2', title: 'Y2' },
  { width: 100, dataIndex: 'y3', title: 'Y3' },
  { width: 100, dataIndex: 'c_oznaka', title: 'C oznaka' },
  { width: 100, dataIndex: 'h1', title: 'H1' },
  { width: 100, dataIndex: 'h2', title: 'H2' },
  { width: 100, dataIndex: 'h3', title: 'H3' },
  { width: 100, dataIndex: 'ia_oznaka', title: 'IA oznaka' },
  { width: 100, dataIndex: 'iib_oznaka', title: 'IIB oznaka' },
  { width: 100, dataIndex: 'd_oznaka', title: 'D oznaka' },
  { width: 100, dataIndex: 'r_oznaka', title: 'R oznaka' },
];

export const GIO_4_GRUPA_PODGRUPA_INDEKSNI_BROJ_COLUMNS: IIzvestajiColumnProps<Gio4GrupaPodgrupaIndeksniBroj>[] = [
  { width: 150, dataIndex: 'indeksni_broj', title: 'Indeksni broj' },
  { width: 250, dataIndex: 'opis', title: 'Opis' },
  { width: 200, dataIndex: 'kolicina', title: 'Količina (t)' },
];

export const GIO_4_PREGLED_PO_GODINAMA_COLUMNS: (
  data: Gio4PregledPoGodinama[]
) => IIzvestajiColumnProps<Gio4PregledPoGodinama>[] = data => [
  { width: 150, dataIndex: 'pib', title: 'PIB' },
  { width: 250, dataIndex: 'preduzece', title: 'Preduzeće' },
  {
    width: 150,
    dataIndex: 'nacionalni_id',
    title: 'Nacionalni ID',
  },
  {
    width: 200,
    dataIndex: 'postrojenje',
    title: 'Postrojenje',
  },
  { width: 120, dataIndex: 'indeksni_broj', title: 'Indeksni broj' },
  { width: 200, dataIndex: 'opis_otpada', title: 'Opis otpada' },

  ...obrazacIzvestajiService.getYearlyColumns(data),
];

export const GIO_4_IZVOZ_OTPADA_KONTROLA_UNOSA_COLUMNS: (
  data: Gio4IzvozOtpadaKontrolaUnosa[]
) => IIzvestajiColumnProps<Gio4IzvozOtpadaKontrolaUnosa>[] = data => [
  { width: 150, dataIndex: 'izmena_za_godinu', title: 'Izmena za godinu' },
  { width: 150, dataIndex: 'pib', title: 'PIB' },
  { width: 250, dataIndex: 'preduzece', title: 'Preduzeće' },
  {
    width: 150,
    dataIndex: 'nacionalni_id',
    title: 'Nacionalni ID',
  },
  {
    width: 200,
    dataIndex: 'postrojenje',
    title: 'Postrojenje',
  },
  { width: 120, dataIndex: 'indeksni_broj', title: 'Indeksni broj' },

  ...obrazacIzvestajiService.getYearlyColumns(data),
];

export const GIO_4_KOLICINA_IZVEZENOG_OTPADA_PO_VRSTI_OTPADA_COLUMNS: IIzvestajiColumnProps<Gio4KolicinaIzvezenogOtpadaPoVrstiOtpada>[] =
  [
    { width: 120, dataIndex: 'indeksni_broj', title: 'Indeksni broj' },
    { width: 200, dataIndex: 'otpad', title: 'Otpad' },
    { width: 150, dataIndex: 'kolicina_izvezenog_otpada', title: 'Količina izvezenog otpada (t)' },
    { width: 150, dataIndex: 'zemlja_izvoza', title: 'Zemlja izvoza' },
  ];

export const GIO_4_ELEKTRICNI_I_ELEKTRONSKI_OTPAD_COLUMNS: IIzvestajiColumnProps<Gio4ElektricniIElektronskiOtpad>[] = [
  { width: 100, dataIndex: 'godina', title: 'Godina' },
  { width: 150, dataIndex: 'pib', title: 'PIB' },
  { width: 250, dataIndex: 'preduzece', title: 'Preduzeće' },
  {
    width: 150,
    dataIndex: 'nacionalni_id',
    title: 'Nacionalni ID',
  },
  {
    width: 200,
    dataIndex: 'postrojenje',
    title: 'Postrojenje',
  },
  { width: 120, dataIndex: 'indeksni_broj', title: 'Indeksni broj' },
  { width: 150, dataIndex: 'kolicina_izvezenog_otpada', title: 'Količina izvezenog otpada (t)' },
  { width: 150, dataIndex: 'broj_dozvole', title: 'Broj dozvole' },
  { width: 150, dataIndex: 'zemlja_izvoza', title: 'Zemlja izvoza' },
  { width: 150, dataIndex: 'zemlja_tranzita', title: 'Zemlja tranzita' },
  {
    width: 200,
    dataIndex: 'naziv_adresa_postrojenja_u_inostranstvu',
    title: 'Naziv i adresa postrojenja u inostranstvu',
  },
  { width: 100, dataIndex: 'y1', title: 'Y1' },
  { width: 100, dataIndex: 'y2', title: 'Y2' },
  { width: 100, dataIndex: 'y3', title: 'Y3' },
  { width: 100, dataIndex: 'c_oznaka', title: 'C oznaka' },
  { width: 100, dataIndex: 'h1', title: 'H1' },
  { width: 100, dataIndex: 'h2', title: 'H2' },
  { width: 100, dataIndex: 'h3', title: 'H3' },
  { width: 100, dataIndex: 'ia_oznaka', title: 'IA' },
  { width: 100, dataIndex: 'iib_oznaka', title: 'IIB' },
  { width: 100, dataIndex: 'd_oznaka', title: 'D oznaka' },
  { width: 100, dataIndex: 'r_oznaka', title: 'R oznaka' },
  { width: 100, dataIndex: 'razred', title: 'Razred' },
  { width: 150, dataIndex: 'vrsta_ee_otpada', title: 'Vrsta EE otpada' },
  { width: 100, dataIndex: 'kolicina_ee', title: 'Količina EE (t)' },
];
//#endregion GIO 4

//#region GIO 5
export const GIO_5_POSTROJENJA_INDEKSNI_BROJ_COLUMNS: IIzvestajiColumnProps<Gio5PostrojenjaIndeksniBroj>[] = [
  { dataIndex: 'godina', title: 'Godina', width: '100px' },
  { dataIndex: 'pib', title: 'PIB', width: '150px' },
  { dataIndex: 'preduzece', title: 'Preduzeće', width: '300px' },
  { dataIndex: 'nacionalni_id', title: 'Nacionalni ID', width: '150px' },
  { dataIndex: 'postrojenje', title: 'Postrojenje', width: '300px' },
  { dataIndex: 'opstina_postrojenja', title: 'Opština postrojenja', width: '200px' },
  { dataIndex: 'indeksni_broj', title: 'Indeksni broj', width: '100px' },
  {
    render: (record: Gio5PostrojenjaIndeksniBroj) => <YesNoCell yesNo={record.opasan} />,
    title: 'Opasan',
    width: '100px',
  },
  { dataIndex: 'naziv_otpada', title: 'Naziv otpada', width: '200px' },
  { dataIndex: 'ukupno_uvezeno', title: 'Ukupno uvezeno (t)', width: '150px' },
  { dataIndex: 'zemlja_izvoza', title: 'Zemlja Izvoza', width: '150px' },
  { dataIndex: 'zemlja_tranzita', title: 'Zemlje tranzita', width: '150px' },
  { dataIndex: 'y1', title: 'Y1', width: '100px' },
  { dataIndex: 'y2', title: 'Y2', width: '100px' },
  { dataIndex: 'y3', title: 'Y3', width: '100px' },
  { dataIndex: 'c_oznaka', title: 'C Oznaka', width: '100px' },
  { dataIndex: 'h1', title: 'H1', width: '100px' },
  { dataIndex: 'h2', title: 'H2', width: '100px' },
  { dataIndex: 'h3', title: 'H3', width: '100px' },
  { dataIndex: 'ia_oznaka', title: 'IA Oznaka', width: '100px' },
  { dataIndex: 'iib_oznaka', title: 'IIB Oznaka', width: '100px' },
];

export const GIO_5_GRUPA_PODGRUPA_INDEKSNI_BROJ_COLUMNS: IIzvestajiColumnProps<Gio5GrupaPodgrupaIndeksniBroj>[] = [
  { dataIndex: 'indeksni_broj', title: 'Indeksni broj' },
  { dataIndex: 'naziv_otpada', title: 'Opis' },
  { dataIndex: 'kolicina', title: 'Količina (t)' },
];

export const GIO_5_UVOZ_OTPADA_PREGLED_PO_GODINI_COLUMNS: (
  data: Gio5PregledPoGodinama[]
) => IIzvestajiColumnProps<Gio5PregledPoGodinama>[] = data => [
  { dataIndex: 'pib', title: 'PIB', width: '150px' },
  { dataIndex: 'preduzece', title: 'Preduzeće', width: '300px' },
  { dataIndex: 'nacionalni_id', title: 'Nacionalni ID', width: '150px' },
  { dataIndex: 'postrojenje', title: 'Postrojenje', width: '300px' },
  { dataIndex: 'indeksni_broj', title: 'Indeksni broj', width: '150px' },
  { dataIndex: 'opis_otpada', title: 'Opis otpada', width: '300px' },
  ...obrazacIzvestajiService.getYearlyColumns(data),
];

export const GIO_5_KOLICINA_UVEZENOG_OTPADA_PO_VRSTI_COLUMNS: IIzvestajiColumnProps<Gio5KolicinaUvezenogOtpadaPoVrstiOtpada>[] =
  [
    { dataIndex: 'indeksni_broj', title: 'Indeksni broj' },
    { dataIndex: 'otpad', title: 'Opis' },
    { dataIndex: 'ukupno_uvezeno', title: 'Količina uvezenog otpada(t)' },
    { dataIndex: 'zemlja_izvoza', title: 'Zemlja izvoza' },
  ];

export const GIO_5_UVOZ_OTPADA_KONTROLA_UNOSA_COLUMNS: (
  data: Gio5UvozOtpadaKontrolaUnosa[]
) => IIzvestajiColumnProps<Gio5UvozOtpadaKontrolaUnosa>[] = data => [
  { width: 150, dataIndex: 'izmena_za_godinu', title: 'Izmena za godinu' },
  { width: 150, dataIndex: 'pib', title: 'PIB' },
  { width: 250, dataIndex: 'preduzece', title: 'Preduzeće' },
  {
    width: 150,
    dataIndex: 'nacionalni_id',
    title: 'Nacionalni ID',
  },
  {
    width: 200,
    dataIndex: 'postrojenje',
    title: 'Postrojenje',
  },
  { width: 120, dataIndex: 'indeksni_broj', title: 'Indeksni broj' },
  ...obrazacIzvestajiService.getYearlyColumns(data),
];
//#endregion GIO 5

//#region DEO 6

export const DEO_6_UKUPNO_PREUZET_OTPAD_COLUMNS: IIzvestajiColumnProps<Deo6UkupnoPreuzetOtpad>[] = [
  { width: 200, dataIndex: 'indeksni_broj', title: 'Indeksni broj otpada' },
  { width: 400, dataIndex: 'opis', title: 'Otpad' },
  { width: 300, dataIndex: 'kolicina_preuzeta_od_pravnog_lica', title: 'Količina preuzeta od pravnih lica (t)' },
  { width: 300, dataIndex: 'kolicina_preuzeta_od_fizickog_lica', title: 'Količina preuzeta od fizičkog lica (t)' },
  { width: 300, dataIndex: 'ukupno_preuzeto', title: 'Ukupno preuzeto (t)' },
];

export const DEO_6_PREUZET_OTPAD_POSTROJENJA_INDEKSNI_BROJ_COLUMNS: IIzvestajiColumnProps<Deo6PreuzetOtpadPostrojenjaIndeksniBroj>[] =
  [
    { width: 200, dataIndex: 'pib', title: 'Pib' },
    { width: 400, dataIndex: 'preduzece', title: 'Preduzeće' },
    { width: 200, dataIndex: 'nacionalni_id', title: 'Nacionalni ID' },
    { width: 400, dataIndex: 'postrojenje', title: 'Postrojenje' },
    { width: 200, dataIndex: 'indeksni_broj', title: 'Indeksni broj' },
    { width: 400, dataIndex: 'otpad', title: 'Otpad' },
    { width: 300, dataIndex: 'kolicina_preuzeta_od_pravnog_lica', title: 'Količina preuzeta od pravnog lica (t)' },
    { width: 300, dataIndex: 'kolicina_preuzeta_od_fizickog_lica', title: 'Količina preuzeta od fizičkog lica (t)' },
    { width: 300, dataIndex: 'ukupno_preuzeto', title: 'Ukupno preuzeto (t)' },
  ];

export const DEO_6_UKUPNO_PREDAT_OTPAD_COLUMNS: IIzvestajiColumnProps<Deo6UkupnoPredatOtpad>[] = [
  { width: 300, dataIndex: 'indeksni_broj', title: 'Indeksni broj otpada' },
  { width: 500, dataIndex: 'opis', title: 'Otpad' },
  { width: 300, dataIndex: 'ukupno_predato', title: 'Ukupno predato (t)' },
];

export const DEO_6_PREDAT_OTPAD_POSTROJENJA_INDEKSNI_BROJ_COLUMNS: IIzvestajiColumnProps<Deo6PredatOtpadPostrojenjaIndeksniBroj>[] =
  [
    { width: 200, dataIndex: 'pib', title: 'Pib' },
    { width: 400, dataIndex: 'preduzece', title: 'Preduzeće' },
    { width: 300, dataIndex: 'nacionalni_id', title: 'Nacionalni ID' },
    { width: 400, dataIndex: 'postrojenje', title: 'Postrojenje' },
    { width: 400, dataIndex: 'indeksni_broj', title: 'Indeksni broj' },
    { width: 400, dataIndex: 'otpad', title: 'Otpad' },
    { width: 300, dataIndex: 'ukupno_predato', title: 'Ukupno predato (t)' },
  ];

export const DEO_6_REKAPITULACIJA_COLUMNS: (
  data: Deo6Rekapitulacija[]
) => IIzvestajiColumnGroupProps<Deo6Rekapitulacija>[] = data => {
  const columns: IIzvestajiColumnGroupProps<Deo6Rekapitulacija>[] = [
    {
      title: '',
      children: [
        { width: 300, dataIndex: 'nacionalni_id', title: 'Nacionalni ID' },
        { width: 400, dataIndex: 'postrojenje', title: 'Postrojenje' },
        { width: 200, dataIndex: 'datum', title: 'Datum' },
        { width: 300, dataIndex: 'transakcija', title: 'Transakcija' },
        { width: 300, dataIndex: 'indeksni_broj', title: 'Indeksni broj' },
        { width: 300, dataIndex: 'kolicina', title: 'Količina otpada (t)' },
        { width: 400, dataIndex: 'opis_transakcije', title: 'Opis transakcije' },
        { width: 300, dataIndex: 'pib', title: 'PIB' },
        { width: 400, dataIndex: 'naziv_preduzece', title: 'Preduzeće' },
        {
          width: 300,
          dataIndex: 'naziv_postrojenja',
          title: 'Drugo postojenje sopstvenog preduzeća',
        },
      ],
    },
  ];
  if (data?.[0]?.ee_razred) {
    columns.push({
      title: 'Količina električnog i elektronskog otpada razvrstana po razredima (t)',
      className: 'border-left',
      children: Object.keys(data?.[0]?.ee_razred).map((key: string) => ({
        width: 400,
        render: (record: Deo6Rekapitulacija) => record.ee_razred[key as keyof Deo6Rekapitulacija['ee_razred']],
        title: key,
      })),
    });
  }
  return columns;
};

export const DEO_6_TRANSAKCIJE_PO_INDEKSNIM_BROJEVIMA_COLUMNS: IIzvestajiColumnProps<Deo6TransakcijePoIndeksnimBrojevima>[] =
  [
    { width: 400, dataIndex: 'indeksni_broj_otpada', title: 'Indeksni broj' },
    { width: 300, dataIndex: 'kolicina_prvi_januar', title: 'Stanje na skladištu 1. januara (t)' },
    { width: 300, dataIndex: 'kolicina_preuzetog', title: 'Preuzet otpad (t)' },
    { width: 300, dataIndex: 'kolicina_predatog', title: 'Predat otpad (t)' },
    { width: 300, dataIndex: 'kolicina_ponovne_upotrebe', title: 'Ponovna upotreba otpada (t)' },
    { width: 300, dataIndex: 'ostalo_na_skladistu', title: 'Ostalo na skladištu (t)' },
  ];

export const DEO_6_TRANSAKCIJE_ZA_RAZREDE_ELEKTRICNIH_I_ELEKTRONSKIH_PROIZVODA_COLUMNS: IIzvestajiColumnProps<Deo6TransakcijeZaRazredeElektricnihIElektronskihProizvoda>[] =
  [
    { width: 400, dataIndex: 'razred_ee_otpada', title: 'Razred električnih i elektronskih proizvoda' },
    { width: 300, dataIndex: 'kolicina_prvi_januar', title: 'Stanje na skladištu 1. januara (t)' },
    { width: 300, dataIndex: 'kolicina_preuzetog', title: 'Preuzeto (t)' },
    { width: 300, dataIndex: 'kolicina_predatog', title: 'Predato (t)' },
    { width: 300, dataIndex: 'kolicina_ponovne_upotrebe', title: 'Ponovna upotreba (t)' },
    { width: 300, dataIndex: 'ostalo_na_skladistu', title: 'Ostalo na skladištu (t)' },
  ];

export const DEO_6_PRIMOPREDAJA_OTPADA_IZMEDJU_PREDUZECA_COLUMNS: IIzvestajiColumnGroupProps<Deo6PrimopredajaOtpadaIzmedjuPreduzeca>[] =
  [
    {
      title: '',
      children: [
        { width: 300, dataIndex: 'godina', title: 'Godina' },
        { width: 300, dataIndex: 'indeksni_broj', title: 'Indeksni broj' },
      ],
    },
    {
      title: 'Podaci o preduzeću koje je predalo otpad i količinama predatog otpada',
      className: 'border-left',
      children: [
        { width: 300, dataIndex: 'pib', title: 'PIB' },
        { width: 300, dataIndex: 'maticni_broj_preduzeca', title: 'Matični broj preduzeća' },
        { width: 500, dataIndex: 'preduzece', title: 'Preduzeće' },
        { width: 300, dataIndex: 'predata_kolicina_otpada', title: 'Predata količina otpada (t)' },
      ],
    },
    {
      title: 'Otpad je predat operateru koji je:',
      className: 'border-left',
      children: [
        {
          width: 300,
          render: (record: Deo6PrimopredajaOtpadaIzmedjuPreduzeca) => <YesNoCell yesNo={record.sakupljac} />,
          title: 'Sakupljač',
        },
        {
          width: 300,
          render: (record: Deo6PrimopredajaOtpadaIzmedjuPreduzeca) => <YesNoCell yesNo={record.operator_na_deponiji} />,
          title: 'Operater na deponiji',
        },
        {
          width: 300,
          render: (record: Deo6PrimopredajaOtpadaIzmedjuPreduzeca) => <YesNoCell yesNo={record.operator_za_tretman} />,
          title: 'Operater za tretman',
        },
        {
          width: 300,
          render: (record: Deo6PrimopredajaOtpadaIzmedjuPreduzeca) => <YesNoCell yesNo={record.skladistar} />,
          title: 'Skladištar',
        },
        {
          width: 300,
          render: (record: Deo6PrimopredajaOtpadaIzmedjuPreduzeca) => <YesNoCell yesNo={record.izvoznik} />,
          title: 'Izvoznik',
        },
        {
          width: 300,
          render: (record: Deo6PrimopredajaOtpadaIzmedjuPreduzeca) => <YesNoCell yesNo={record.drugi_vlasnik} />,
          title: 'Drugi vlasnik',
        },
      ],
    },
    {
      title: 'Podaci o preduzeću koje je preuzelo otpad i količinama preuzetog otpada',
      className: 'border-left',
      children: [
        { width: 300, dataIndex: 'pib_preuzeto', title: 'PIB' },
        { width: 300, dataIndex: 'maticni_broj_preduzeca_preuzeto', title: 'Matični broj preduzeća' },
        { width: 500, dataIndex: 'preduzece_preuzeto', title: 'Preduzeće' },
        { width: 300, dataIndex: 'predata_kolicina_otpada_preuzeto', title: 'Preuzeta količina otpada (t)' },
      ],
    },
    {
      title: 'Otpad je preuzet od preduzeća koje je:',
      className: 'border-left',
      children: [
        {
          width: 300,
          render: (record: Deo6PrimopredajaOtpadaIzmedjuPreduzeca) => <YesNoCell yesNo={record.proizvodjac} />,
          title: 'Proizvođač',
        },
        {
          width: 300,
          render: (record: Deo6PrimopredajaOtpadaIzmedjuPreduzeca) => <YesNoCell yesNo={record.sakupljac_preuzeto} />,
          title: 'Sakupljač',
        },
        {
          width: 300,
          render: (record: Deo6PrimopredajaOtpadaIzmedjuPreduzeca) => (
            <YesNoCell yesNo={record.operator_na_deponiji_preuzeto} />
          ),
          title: 'Operater na deponiji',
        },
        {
          width: 300,
          render: (record: Deo6PrimopredajaOtpadaIzmedjuPreduzeca) => (
            <YesNoCell yesNo={record.operator_za_tretman_preuzeto} />
          ),
          title: 'Operater za tretman',
        },
        {
          width: 300,
          render: (record: Deo6PrimopredajaOtpadaIzmedjuPreduzeca) => <YesNoCell yesNo={record.skladistar_preuzeto} />,
          title: 'Skladištar',
        },
        {
          width: 300,
          render: (record: Deo6PrimopredajaOtpadaIzmedjuPreduzeca) => <YesNoCell yesNo={record.uvoznik} />,
          title: 'Uvoznik',
        },
        {
          width: 300,
          render: (record: Deo6PrimopredajaOtpadaIzmedjuPreduzeca) => (
            <YesNoCell yesNo={record.drugi_vlasnik_preuzeto} />
          ),
          title: 'Drugi vlasnik',
        },
      ],
    },
  ];

export const DEO_6_GIO_3_DEO6_PO_PREDUZECU_COLUMNS: IIzvestajiColumnGroupProps<Gio3Deo6PoPreduzecu>[] = [
  {
    title: '',
    children: [
      { width: 300, dataIndex: 'godina', title: 'Godina' },
      { width: 300, dataIndex: 'pib', title: 'PIB' },
      { width: 300, dataIndex: 'maticni_broj', title: 'Matični broj' },
      { width: 500, dataIndex: 'preduzece', title: 'Preduzeće' },
      { width: 300, dataIndex: 'indeksni_broj', title: 'Indeksni broj' },
    ],
  },
  {
    title: 'GIO3',
    className: 'border-left',
    children: [
      { width: 300, dataIndex: 'preuzet_otpad', title: 'Preuzeto (t)' },
      { width: 300, dataIndex: 'tretiran_otpad', title: 'Tretirano (t)' },
    ],
  },
  {
    title: 'DEO 6',
    className: 'border-left',
    children: [
      { width: 300, dataIndex: 'stanje_na_skladistu_1_januara', title: 'Stanje na skladištu 1. januara' },
      { width: 300, dataIndex: 'preuzet_otpad2', title: 'Preuzeto (t)' },
      { width: 300, dataIndex: 'tretiran_otpad2', title: 'Tretirano (t)' },
    ],
  },
];
//#endregion DEO 6

//#region KOM 1
export const KOM_1_SASTAV_KOMUNALNOG_OTPADA_PO_GODINAMA_COLUMNS: (
  data: Kom1SastavKomunalnogOtpadaPregledPoGodinama[]
) => IIzvestajiColumnGroupProps<Kom1SastavKomunalnogOtpadaPregledPoGodinama>[] = data => {
  return obrazacIzvestajiService.getYearsGroupedByCategoryColumns(
    [
      { width: 200, dataIndex: 'opstina', title: 'Opština' },
      {
        width: 300,
        dataIndex: 'preduzece',
        title: 'Preduzeće',
      },
    ],
    data?.[0]?.vrsta_otpada ?? [],
    'vrsta_otpada'
  );
};

export const KOM_1_KOLICINA_KOMUNALNOG_OTPADA_PO_GODINAMA_COLUMNS: (
  data: Kom1KolicinaKomunalnogOtpadaPregledPoGodinama[]
) => IIzvestajiColumnProps<Kom1KolicinaKomunalnogOtpadaPregledPoGodinama>[] = data => [
  { width: 200, dataIndex: 'opstina', title: 'Opština' },
  {
    width: 300,
    dataIndex: 'preduzece',
    title: 'Preduzeće',
  },
  ...obrazacIzvestajiService.getYearlyColumns(data),
];

export const KOM_1_OBUHVAT_PRIKUPLJENOG_OTPADA_PO_GODINAMA_COLUMNS: (
  data: Kom1ObuhvatPrikupljanjaKomunalnogOtpadaPregledPoGodinama[]
) => IIzvestajiColumnProps<Kom1ObuhvatPrikupljanjaKomunalnogOtpadaPregledPoGodinama>[] = data => [
  { width: 200, dataIndex: 'opstina', title: 'Opština' },
  {
    width: 300,
    dataIndex: 'preduzece',
    title: 'Preduzeće',
  },
  ...obrazacIzvestajiService.getYearlyColumns(data),
];

export const KOM_1_UPRAVLJANJE_KOMUNALNIM_OTPADOM_COLUMNS: IIzvestajiColumnProps<Kom1UpravljanjeKomunalnimOtpadom>[] = [
  { width: 100, dataIndex: 'godina', title: 'Godina' },
  { width: 150, dataIndex: 'pib', title: 'PIB' },
  { width: 250, dataIndex: 'preduzece', title: 'Preduzeće' },
  {
    width: 150,
    dataIndex: 'nacionalni_id',
    title: 'Nacionalni ID',
  },
  {
    width: 200,
    dataIndex: 'postrojenje',
    title: 'Postrojenje',
  },
  {
    width: 200,
    dataIndex: 'opstina',
    title: 'Opština',
  },
  { width: 120, dataIndex: 'indeksni_broj', title: 'Indeksni broj' },
  { width: 300, dataIndex: 'ukupna_kolicina_otpada', title: 'Ukupna količina otpada (t)' },
  { width: 300, dataIndex: 'nacin_odredjivanja', title: 'Način određivanja' },
  { width: 300, dataIndex: 'kolicina_iz_domacinstva', title: 'Količina iz domaćinstva (t)' },
  {
    width: 300,
    dataIndex: 'kolicina_iz_kontejnera',
    title: 'Količina iz kontejnera za primarnu selektivnu separaciju (t)',
  },
  { width: 300, dataIndex: 'kolicina_iz_reciklaznog_dvorista', title: 'Količina iz reciklažnog dvorišta (t)' },
  { width: 300, dataIndex: 'kolicina_iz_drugih_sabirnih_mesta', title: 'Količina iz drugih sabirnih mesta (t)' },
  { width: 300, dataIndex: 'vrsta_predaje', title: 'Vrsta predaje' },
  { width: 300, dataIndex: 'predata_kolicina', title: 'Predata količina (t)' },
  { width: 300, dataIndex: 'd_ili_r_oznaka', title: 'D ili R oznaka' },
];
//#endregion KOM 1

//#region PTP 2
export const PTP_2_OBRACUN_ZA_NAPLATU_COLUMNS: (
  data: Ptp2ObracunZaNaplatu[]
) => IIzvestajiColumnProps<Ptp2ObracunZaNaplatu>[] = data => [
  { width: 200, dataIndex: 'vrsta_proizvoda', title: 'Vrsta proizvoda' },
  ...obrazacIzvestajiService.getYearlyColumns(data),
];

export const PTP_2_VOZILA_UPOREDJIVANJE_COLUMNS: (
  data: Ptp2VozilaUporedjivanje[]
) => IIzvestajiColumnGroupProps<Ptp2VozilaUporedjivanje>[] = data => [
  ...obrazacIzvestajiService.getComparisonByYearGroupColumns<
    Ptp2VozilaUporedjivanje,
    Ptp2VozilaUporedjivanjeGrupaKolona
  >(
    data,
    [
      { width: 150, dataIndex: 'pib', title: 'PIB' },
      { width: 250, dataIndex: 'preduzece', title: 'Preduzeće' },
    ],
    [
      {
        valueAccessor: 'prijavljena_kolicina',
        title: 'Prijavljena količina',
      },
      {
        valueAccessor: 'kolicina_na_carini',
        title: 'Količina na carini',
      },
      {
        valueAccessor: 'razlika',
        title: 'Razlika',
      },
    ]
  ),
];

export const PTP_2_NEDOSTAVLJENI_PODACI_O_VOZILIMA_COLUMNS: (
  data: Ptp2NedostavljeniPodaciOGumama[]
) => IIzvestajiColumnProps<Ptp2NedostavljeniPodaciOVozilima>[] = data => [
  { width: 150, dataIndex: 'pib', title: 'PIB' },
  { width: 250, dataIndex: 'preduzece', title: 'Preduzeće' },
  ...obrazacIzvestajiService.getYearlyColumns(data),
];

export const PTP_2_UVOZ_GUMA_UPOREDJIVANJE_COLUMNS: (
  data: Ptp2UvozGumaUporedjivanje[]
) => IIzvestajiColumnGroupProps<Ptp2UvozGumaUporedjivanje>[] = data => [
  ...obrazacIzvestajiService.getComparisonByYearGroupColumns<
    Ptp2UvozGumaUporedjivanje,
    Ptp2UvozGumaUporedjivanjeGrupaKolona
  >(
    data,
    [
      { width: 150, dataIndex: 'pib', title: 'PIB' },
      { width: 250, dataIndex: 'preduzece', title: 'Preduzeće' },
    ],
    [
      {
        valueAccessor: 'prijavljena_kolicina',
        title: 'Prijavljena količina',
      },
      {
        valueAccessor: 'kolicina_na_carini',
        title: 'Količina na carini',
      },
      {
        valueAccessor: 'razlika',
        title: 'Razlika',
      },
    ]
  ),
];

export const PTP_2_NEDOSTAVLJENI_PODACI_O_GUMAMA_COLUMNS: (
  data: Ptp2NedostavljeniPodaciOGumama[]
) => IIzvestajiColumnProps<Ptp2NedostavljeniPodaciOGumama>[] = data => [
  { width: 150, dataIndex: 'pib', title: 'PIB' },
  { width: 250, dataIndex: 'preduzece', title: 'Preduzeće' },
  ...obrazacIzvestajiService.getYearlyColumns(data),
];

export const PTP_2_KONTROLA_UNOSA_COLUMNS: (
  data: Ptp2KontrolaUnosa[]
) => IIzvestajiColumnProps<Ptp2KontrolaUnosa>[] = data => [
  { width: 150, dataIndex: 'godina', title: 'Izmena za godinu' },
  { width: 150, dataIndex: 'pib', title: 'PIB' },
  { width: 250, dataIndex: 'preduzece', title: 'Preduzeće' },
  { width: 200, dataIndex: 'vrsta_proizvoda', title: 'Vrsta proizvoda' },
  { width: 200, dataIndex: 'naziv_proizvoda', title: 'Naziv proizvoda' },
  { width: 150, dataIndex: 'jedinica', title: 'Jedinica' },
  { width: 200, dataIndex: 'klasa', title: 'Klasa' },
  ...obrazacIzvestajiService.getYearlyColumns(data),
];
//#endregion PTP 2

//#region AAO 1
export const AAO_1_PREGLED_PO_GODINAMA_COLUMNS: (
  data: Aao1PregledPoGodinama[]
) => IIzvestajiColumnProps<Aao1PregledPoGodinama>[] = data => [
  { dataIndex: 'pib', title: 'PIB', width: '150px' },
  { dataIndex: 'preduzece', title: 'Preduzeće', width: '300px' },
  { dataIndex: 'email', title: 'Email', width: '300px' },
  { dataIndex: 'vrsta_ambalaze', title: 'Vrsta ambalaže', width: '300px' },
  ...obrazacIzvestajiService.getYearlyColumns<Aao1PregledPoGodinama>(data),
];

export const AAO_1_PRIKAZ_1_COLUMNS: IIzvestajiColumnProps<Aao1Prikaz1>[] = [
  { dataIndex: 'preduzece', title: 'Preduzeće', width: '300px' },
  { dataIndex: 'pib', title: 'PIB', width: '150px' },
  { dataIndex: 'mb', title: 'Matični broj', width: '300px' },
  { dataIndex: 'ulica', title: 'Ulica', width: '300px' },
  { dataIndex: 'kucni_broj', title: 'Kućni broj', width: '100px' },
  { dataIndex: 'opstina', title: 'Naziv opštine', width: '300px' },
  { dataIndex: 'vrsta_ambalaze', title: 'Vrsta ambalaže', width: '150px' },
  { dataIndex: 'ukupno_nepovratne_ambalaze', title: 'Ukupno nepovratne ambalaže', width: '150px' },
  { dataIndex: 'ukupno_povratne_ambalaze', title: 'Ukupno povratne ambalaže', width: '150px' },
  { dataIndex: 'operater1', title: 'Operater 1', width: '300px' },
  { dataIndex: 'operater2', title: 'Operater 2', width: '300px' },
  {
    render: (record: Aao1Prikaz1) => <DateCell date={record.datum_kreiranja} showTime={true} />,
    width: 200,
    title: 'Kreirano',
  },
  {
    render: (record: Aao1Prikaz1) => <DateCell date={record.datum_azuriranja} showTime={true} />,
    width: 200,
    title: 'Ažurirano',
  },
];

export const AAO_1_SVI_PODACI_COLUMNS: (data: Aao1SviPodaci[]) => IIzvestajiColumnProps<Aao1SviPodaci>[] = data => [
  { dataIndex: 'godina', title: 'Godina', width: '150px' },
  { dataIndex: 'pib', title: 'PIB', width: '150px' },
  { dataIndex: 'naziv', title: 'Preduzeće', width: '300px' },
  {
    render: (record: Aao1SviPodaci) => <YesNoCell yesNo={record.proizvodjac_ambalaze} />,
    title: 'Proizvođač ambalaže',
    width: 300,
  },
  {
    render: (record: Aao1SviPodaci) => <YesNoCell yesNo={record.paker_proizvoda_u_ambalazu} />,
    title: 'Paker proizvoda u ambalaži',
    width: 300,
  },
  {
    render: (record: Aao1SviPodaci) => <YesNoCell yesNo={record.isporucilac_ambalaze} />,
    title: 'Isporučilac ambalaže',
    width: 300,
  },
  {
    render: (record: Aao1SviPodaci) => <YesNoCell yesNo={record.uvoznik_ambalaze} />,
    title: 'Uvoznik ambalaže',
    width: 300,
  },
  {
    render: (record: Aao1SviPodaci) => <YesNoCell yesNo={record.izvoznik_ambalaze} />,
    title: 'Izvoznik ambalaže',
    width: 300,
  },
  {
    render: (record: Aao1SviPodaci) => <YesNoCell yesNo={record.krajnji_korisnik_ambalaze} />,
    title: 'Krajnji korisnik ambalaže',
    width: 300,
  },
  { dataIndex: 'oznaka_grupe', title: 'Oznaka grupe', width: '150px' },
  { dataIndex: 'oznaka_podgrupe', title: 'Oznaka podgrupe', width: '150px' },
  { dataIndex: 'vrsta_ambalaze', title: 'Vrsta ambalaže', width: '150px' },
  {
    dataIndex: 'nepovratna_ambalaza_proizvedena_kolicina',
    title: 'Nepovratna ambalaža - Proizvedena količina (t)',
    width: '200px',
  },
  {
    dataIndex: 'nepovratna_ambalaza_uvezena_kolicina',
    title: 'Nepovratna ambalaža - Uvezena količina (t)',
    width: '200px',
  },
  {
    dataIndex: 'nepovratna_ambalaza_izvezena_kolicina',
    title: 'Nepovratna ambalaža - Izvezena količina (t)',
    width: '200px',
  },
  {
    dataIndex: 'nepovratna_ambalaza_ukupna_kolicina',
    title: 'Nepovratna ambalaža - Ukupna količina (t)',
    width: '200px',
  },
  {
    dataIndex: 'povratna_ambalaza_proizvedena_kolicina',
    title: 'Povratna ambalaža - Proizvedena količina (t)',
    width: '200px',
  },
  {
    dataIndex: 'povratna_ambalaza_uvezena_kolicina',
    title: 'Povratna ambalaža - Uvezena količina (t)',
    width: '200px',
  },
  {
    dataIndex: 'povratna_ambalaza_izvezena_kolicina',
    title: 'Povratna ambalaža - Izvezena količina (t)',
    width: '200px',
  },
  {
    dataIndex: 'povratna_ambalaza_ukupna_kolicina',
    title: 'Povratna ambalaža - Ukupna količina (t)',
    width: '200px',
  },
  {
    dataIndex: 'kolicina_ambalaze_preneta_na_operatere',
    title: 'Količina ambalaže preneta na operatere (t)',
    width: '320px',
  },
  ...obrazacIzvestajiService.getOperaterInfoColumns(data),
  {
    dataIndex: 'kolicina_ambalaze_na_samostalnom_upravljanju',
    title: 'Količina ambalaže na samostalnom upravljanju (t)',
    width: '300px',
  },
  {
    dataIndex: 'procenat_ambalaze_prenet_na_samostalno_upravljanje',
    title: 'Procenat ambalaže prenet na samostalno upravljanje (%)',
    width: '300px',
  },
];

export const AAO_1_NEDOSTAVLJENI_OBRASCI_COLUMNS: IIzvestajiColumnProps<Aao1NedostavljeniObrasci>[] = [
  { dataIndex: 'godina', title: 'Godina', width: '150px' },
  { dataIndex: 'pib_operatera', title: 'PIB operatera', width: '150px' },
  { dataIndex: 'naziv_operatera', title: 'Naziv operatera', width: '300px' },
  { dataIndex: 'pib', title: 'PIB', width: '150px' },
  { dataIndex: 'mb', title: 'Matični broj', width: '300px' },
  { dataIndex: 'preduzece', title: 'Preduzeće', width: '300px' },
  { dataIndex: 'adresa', title: 'Adresa', width: '300px' },
  { dataIndex: 'mesto', title: 'Mesto', width: '300px' },
  { dataIndex: 'postanski_broj', title: 'Poštanski broj', width: '300px' },
  { dataIndex: 'telefon', title: 'Telefon', width: '300px' },
  { dataIndex: 'email', title: 'E-mail', width: '300px' },
];
//#endregion AAO 1

//#region AAO 2
export const AAO2_IZVESTAJ_FOR_BOOK_COLUMNS: IIzvestajiColumnProps<AAO2IzvestajForBook>[] = [
  { width: 300, dataIndex: 'temp_id', title: 'TempId' },
  { width: 300, dataIndex: 'reporting_year', title: 'ReportingYear' },
  { width: 300, dataIndex: 'company', title: 'Company' },
  { width: 300, dataIndex: 'permit_number', title: 'PermitNumber' },
  { width: 300, dataIndex: 'group_number', title: 'GroupNumber' },
  { width: 300, dataIndex: 'sub_group_number', title: 'SubGroupNumber' },
  { width: 300, dataIndex: 'display_type_sr', title: 'TypeofPacking' },
  { width: 300, dataIndex: 'index_number', title: 'IndexNumber' },
  { width: 300, dataIndex: 'produced_quantity', title: 'ProducedQuantity' },
  { width: 300, dataIndex: 'imported_quantity', title: 'ImportedQuantity' },
  { width: 300, dataIndex: 'exported_quantity', title: 'ExportedQuantity' },
  { width: 300, dataIndex: 'total_quantity', title: 'TotalQuantity' },
  { width: 300, dataIndex: 'take_quantity_communal', title: 'TakenQuantityCommunal' },
  { width: 300, dataIndex: 'taken_quantity_noncommunal', title: 'TakenQuantityNoncommunal' },
  { width: 300, dataIndex: 'quantity_operation_r1', title: 'QuantityOperationR1' },
  { width: 300, dataIndex: 'other_r_operation', title: 'OtherROperation' },
  { width: 300, dataIndex: 'quantity_other_r_operation', title: 'QuantityOtherROperation' },
  { width: 300, dataIndex: 'quantity_operation_d1', title: 'QuantityOperationD1' },
  { width: 300, dataIndex: 'other_d_operation', title: 'OtherDOperation' },
  { width: 300, dataIndex: 'quantity_other_d_operation', title: 'QuantityOtherDOperation' },
  { width: 300, dataIndex: 'total_reused', title: 'TotalReused' },
  { width: 300, dataIndex: 'total_disposal', title: 'TotalDisposal' },
  { width: 300, dataIndex: 'client_numbers', title: 'ClientNumbers' },
];
//#endregion AAO 2

//#region DEP 1, DEP 2
export const DEP_1_SVI_PODACI_COLUMNS: () => IIzvestajiColumnGroupProps<Dep1SviPodaci>[] = () => [
  {
    title: 'PODACI O JKP ILI PREDUZEĆU KOJE UPRAVLJA NESANITARNOM DEPONIJOM - SMETLIŠTEM',
    className: 'border-left',
    children: [
      { width: 100, dataIndex: 'godina', title: 'Godina' },
      { width: 300, dataIndex: 'preduzece', title: 'Naziv preduzeća' },
      { width: 300, dataIndex: 'pib', title: 'PIB' },
      { width: 300, dataIndex: 'mb', title: 'Matični broj' },
      { width: 300, dataIndex: 'opstina_preduzeca', title: 'Opština' },
      { width: 300, dataIndex: 'mesto_preduzeca', title: 'Mesto' },
      { width: 300, dataIndex: 'sifra_mesta_preduzeca', title: 'Šifra mesta' },
      { width: 300, dataIndex: 'postanski_broj', title: 'Poštanski broj' },
      { width: 300, dataIndex: 'adresa', title: 'Ulica i broj' },
      { width: 300, dataIndex: 'telefon', title: 'Telefon' },
      { width: 300, dataIndex: 'telefax', title: 'Telefaks' },
      { width: 300, dataIndex: 'email', title: 'Email' },
      { width: 300, dataIndex: 'sifra_pretezne_delatnosti', title: 'Šifra pretežne delatnosti' },
    ],
  },
  {
    title: 'PODACI O ODGOVORNOM LICU U JKP ILI PREDUZEĆU KOJE UPRAVLJA NESANITARNOM DEPONIJOM',
    className: 'border-left',
    children: [
      { width: 300, dataIndex: 'olp_ime_prezime', title: 'Ime i prezime' },
      { width: 300, dataIndex: 'olp_funkcija', title: 'Funkcija' },
      { width: 300, dataIndex: 'olp_telefon', title: 'Telefon' },
    ],
  },
  {
    title: 'PODACI O LICU ODGOVORNOM ZA UPRAVLJANJE OTPADOM',
    className: 'border-left',
    children: [
      { width: 300, dataIndex: 'olo_ime_prezime', title: 'Ime i prezime' },
      { width: 300, dataIndex: 'olo_funkcija', title: 'Funkcija' },
      { width: 300, dataIndex: 'olo_telefon', title: 'Telefon' },
      { width: 300, dataIndex: 'olo_email', title: 'Email' },
    ],
  },
  {
    title: 'OSNOVNI PODACI O NESANITARNOJ DEPONIJI - SMETLIŠTU',
    className: 'border-left',
    children: [
      { width: 300, dataIndex: 'deponija_lokacija', title: 'Lokacija (mesto, naselje)' },
      { width: 300, dataIndex: 'deponija_naziv', title: 'Naziv' },
      { width: 300, dataIndex: 'geografska_sirina', title: 'Geo. širina' },
      { width: 300, dataIndex: 'geografska_duzina', title: 'Geo. dužina' },
      { width: 300, dataIndex: 'zauzete_katastarske_parcele', title: 'Zauzete katastarske parcele' },
      { width: 300, dataIndex: 'godina_pocetka_deponovanja', title: 'Godina početka deponovanja' },
      { width: 300, dataIndex: 'godina_zavrsetka_deponovanja', title: 'Godina završetka deponovanja' },
    ],
  },
  {
    title: 'KOLIČINE OTPADA I OBUHVAT PRIKUPLJANJA OTPADA NA NESANITARNOJ DEPONIJI - SMETLIŠTU',
    className: 'border-left',
    children: [
      {
        width: 300,
        dataIndex: 'prosecne_godisnje_kolicine_otpada',
        title: 'Prosečne godišnje količine otpada, koji se odlaže na nesanitarnu deponiju - smetlište (t)',
      },
      {
        width: 500,
        dataIndex: 'naselja_za_odlaganje_otpada',
        title: 'Gradovi / opštine ili naselja čiji otpad se odlaže na nesanitarnu deponiju - smetlište',
      },
    ],
  },
  {
    title: 'FIZIČKA ZAŠTITA I OPREMLJENOST NESANITARNE DEPONIJE - SMETLIŠTA',
    className: 'border-left',
    children: [
      {
        render: (record: Dep1SviPodaci) => <YesNoCell yesNo={record.ograda_oko_nesanitarne_deponije} />,
        title: 'Ograda oko nesanitarne deponije - smetlišta',
        width: 300,
      },
      {
        render: (record: Dep1SviPodaci) => <YesNoCell yesNo={record.kapija_rampa_na_ulazu} />,
        title: 'Kapija / rampa na ulazu',
        width: 300,
      },
      {
        render: (record: Dep1SviPodaci) => <YesNoCell yesNo={record.cuvarska_sluzba} />,
        title: 'Čuvarska služba',
        width: 300,
      },
      {
        render: (record: Dep1SviPodaci) => <YesNoCell yesNo={record.kolska_vaga} />,
        title: 'Kolska vaga',
        width: 300,
      },
      {
        render: (record: Dep1SviPodaci) => <YesNoCell yesNo={record.drenazni_sistem} />,
        title: 'Drenažni sistem za prikupljanje procednih otpadnih voda',
        width: 300,
      },
      {
        render: (record: Dep1SviPodaci) => <YesNoCell yesNo={record.sistem_za_preciscavanje} />,
        title: 'Sistem za prečišćavanje procednih voda sa smetlišta',
        width: 300,
      },
      {
        render: (record: Dep1SviPodaci) => <YesNoCell yesNo={record.sistem_za_otplinjavanje} />,
        title: 'Sistem za otplinjavanje deponijskog gasa',
        width: 300,
      },
    ],
  },
  {
    title: 'OSTALI PODACI O NESANITARNOJ DEPONIJI - SMETLIŠTU',
    className: 'border-left',
    children: [
      {
        width: 300,
        dataIndex: 'region_za_upravljanje_otpada',
        title: 'Kojem regionu za upravljanje otpadom pripada vaša opština?',
      },
      {
        width: 300,
        dataIndex: 'lok_samouprave_upravljanje_otpadom',
        title: 'Sa kojim lokalnim samoupravama imate potpisan sporazum o zajedničkom regionalnom upravljanju otpadom?',
      },
      {
        width: 300,
        dataIndex: 'nesanitarna_deponija_smetliste_je',
        title: 'Nesanitarna deponija - smetlište je:',
      },
      {
        width: 300,
        dataIndex: 'operativni_status_nesanitarne_deponije',
        title: 'Operativni status nesanitarne deponije - smetlišta',
      },
      {
        width: 300,
        dataIndex: 'dalje_odlaganje_otpada',
        title: 'Da li se na nesanitarnoj deponiji - smetlištu i dalje odlaže otpad?',
      },
      {
        width: 300,
        dataIndex: 'nacin_odlaganja_otpada',
        title: 'Način odlaganja otpada',
      },
      {
        width: 300,
        dataIndex: 'prekrivanje_inertnim_materijalom',
        title: 'Da li se vrši prekrivanje inertnim materijalom?',
      },
      {
        width: 300,
        dataIndex: 'period_trajanje_i_eksploatacije_deponije',
        title: 'Period za koji se predviđa trajanje i eksploatacija nesanitarne deponije - smetlišta',
      },
      {
        dataIndex: 'evid_o_kolicinama_vrstama_otpada',
        title: 'Da li se vrši i vodi evidencija o količinama i vrstama otpada, koji se odlaže?',
        width: 300,
      },
      {
        dataIndex: 'dep_u_poplavnom_podrucju',
        title: 'Da li se nesanitarna deponija - smetlište ili njen deo nalazi u poplavnom području?',
        width: 300,
      },
    ],
  },
  {
    title: 'PROJEKAT SANACIJE, REKULTIVACIJE I ZATVARANJA NESANITARNE DEPONIJE - SMETLIŠTA',
    className: 'border-left',
    children: [
      {
        render: (record: Dep1SviPodaci) => <YesNoCell yesNo={record.izradjen_projekat_sanacije} />,
        title: 'Da li je za nesanitarno smetlište izrađen Projekat sanacije, zatvaranja i rekultivacije?',
        width: 300,
      },
      {
        width: 300,
        dataIndex: 'godina_projekta_sanacije',
        title: 'Koje godine?',
      },
      {
        render: (record: Dep1SviPodaci) => <YesNoCell yesNo={record.pribavljena_saglasnost_na_projekat} />,
        title: 'Da li je pribavljena saglasnost na Projekat sanacije, zatvaranja i rekultivacije?',
        width: 300,
      },
      {
        width: 300,
        dataIndex: 'godina_pribavljanja_saglasnosti_na_projekat',
        title: 'Koje godine?',
      },
      {
        width: 300,
        dataIndex: 'izvodjenje_radova_po_projektu_sanacije',
        title: 'Da li se izvode radovi po Projektu sanacije, zatvaranja i rekultivacije?',
      },
      {
        width: 300,
        dataIndex: 'radovi_koji_se_izvode_po_projektu',
        title: 'Koji se radovi izvode po Projektu sanacije, zatvaranja i rekultivacije?',
      },
      {
        dataIndex: 'sprovodjenje_mera_zzs',
        title:
          'Da li se sprovode mere zaštite životne sredine propisane Projektom sanacije, zatvaranja i rekultivacije?',
        width: 300,
      },
      {
        width: 300,
        dataIndex: 'sprovodjenje_monitoringa',
        title: 'Da li se sprovodi monitoring propisan Projektom sanacije, zatvaranja i rekultivacije?',
      },
      {
        dataIndex: 'potreba_izrada_azuriranje_projekta',
        title:
          'Da li je potrebna izrada novog ili ažuriranje postojećeg Projekta sanacije, zatvaranja i rekultivacije nesanitarnog smetlišta?',
        width: 300,
      },
    ],
  },
];

export const DEP_2_SVI_PODACI_COLUMNS: () => IIzvestajiColumnGroupProps<Dep2SviPodaci>[] = () => [
  {
    title: 'PODACI O JKP ILI PREDUZEĆU KOJE UPRAVLJA DEPONIJOM - SMETLIŠTEM',
    className: 'border-left',
    children: [
      { width: 100, dataIndex: 'godina', title: 'Godina' },
      { width: 300, dataIndex: 'preduzece', title: 'Naziv preduzeća' },
      { width: 300, dataIndex: 'pib', title: 'PIB' },
      { width: 300, dataIndex: 'mb', title: 'Matični broj' },
      { width: 300, dataIndex: 'opstina_preduzeca', title: 'Opština' },
      { width: 300, dataIndex: 'mesto_preduzeca', title: 'Mesto' },
      { width: 300, dataIndex: 'sifra_mesta_preduzeca', title: 'Šifra mesta' },
      { width: 300, dataIndex: 'postanski_broj', title: 'Poštanski broj' },
      { width: 300, dataIndex: 'adresa', title: 'Ulica i broj' },
      { width: 300, dataIndex: 'telefon', title: 'Telefon' },
      { width: 300, dataIndex: 'telefax', title: 'Telefaks' },
      { width: 300, dataIndex: 'email', title: 'Email' },
      { width: 300, dataIndex: 'sifra_pretezne_delatnosti', title: 'Šifra pretežne delatnosti' },
    ],
  },
  {
    title: 'PODACI O ODGOVORNOM LICU U JKP ILI PREDUZEĆU KOJE UPRAVLJA DEPONIJOM',
    className: 'border-left',
    children: [
      { width: 300, dataIndex: 'olp_ime_prezime', title: 'Ime i prezime' },
      { width: 300, dataIndex: 'olp_funkcija', title: 'Funkcija' },
      { width: 300, dataIndex: 'olp_telefon', title: 'Telefon' },
    ],
  },
  {
    title: 'PODACI O LICU ODGOVORNOM ZA UPRAVLJANJE OTPADOM',
    className: 'border-left',
    children: [
      { width: 300, dataIndex: 'olo_ime_prezime', title: 'Ime i prezime' },
      { width: 300, dataIndex: 'olo_funkcija', title: 'Funkcija' },
      { width: 300, dataIndex: 'olo_telefon', title: 'Telefon' },
      { width: 300, dataIndex: 'olo_email', title: 'Email' },
    ],
  },
  {
    title: 'PODACI O DIVLJIM DEPONIJAMA',
    className: 'border-left',
    children: [
      {
        width: 300,
        dataIndex: 'deponija_opstina',
        title: 'Opština',
      },
      {
        width: 300,
        dataIndex: 'deponija_naselje',
        title: 'Naselje',
      },
      {
        width: 300,
        dataIndex: 'geografska_sirina',
        title: 'Geo. širina',
      },
      {
        width: 300,
        dataIndex: 'geografska_duzina',
        title: 'Geo. dužina',
      },
      {
        width: 300,
        dataIndex: 'procenjena_kolicina_otpada',
        title: 'Procenjena količina otpada (t)',
      },
      {
        width: 300,
        dataIndex: 'procenjena_povrsina_smetlista',
        title: 'Procenjena površina smetlišta (m2)',
      },
      {
        width: 300,
        dataIndex: 'broj_ciscenja_prostora_u_okviru_godine',
        title: 'Koliko je puta čišćen prostor divlje deponije u toku izveštajne godine?',
      },
      {
        dataIndex: 'ponavlja_se_divlje_odlaganje_otpada',
        title: 'Da li se na istom mestu ponavlja divlje odlaganje otpada?',
        width: 300,
      },
    ],
  },
];
//#endregion DEP 1, DEP 2

//#region PZV
export const PZV_KOLICINE_PROIZVODA_PO_PREDUZECU_COLUMNS: IIzvestajiColumnProps<PzvKolicineProizvodaPoPreduzecu>[] = [
  { width: 100, dataIndex: 'godina', title: 'Godina' },
  { width: 150, dataIndex: 'pib', title: 'PIB' },
  { width: 250, dataIndex: 'preduzece', title: 'Preduzeće' },
  { width: 200, dataIndex: 'grupa_proizvoda', title: 'Grupa proizvoda' },
  { width: 200, dataIndex: 'naziv_proizvoda', title: 'Proizvod' },
  { width: 200, dataIndex: 'opis', title: 'Opis proizvoda' },
  { width: 150, dataIndex: 'kolicina_proizvoda', title: 'Količina proizvoda (kg)' },
  {
    width: 350,
    dataIndex: 'sadrzaj',
    title: () => (
      <div>
        Sadržaj (%):
        <br />- fosfata u deterdžentu
        <br />- azota u mineralnom đubrivu
        <br />- aktivne materije u sredstvu za zaštitu bilja
      </div>
    ),
  },
  {
    width: 350,
    dataIndex: 'predefinisani_sadrzaj',
    title: () => (
      <div>
        Predefinisani sadržaj (%):
        <br />- fosfata u deterdžentu
        <br />- azota u mineralnom đubrivu
        <br />- aktivne materije u sredstvu za zaštitu bilja
      </div>
    ),
  },
  {
    width: 350,
    dataIndex: 'ukupna_kolicina',
    title: () => (
      <div>
        Ukupna količina (kg):
        <br /> - fosfata u deterdžentu
        <br /> - azota u mineralnom đubrivu
        <br /> - aktivne materije u sredstvu za zaštitu bilja
      </div>
    ),
  },
];

export const PZV_KOLICINE_PROIZVODA_PO_GRUPI_I_PREDUZECU_COLUMNS: IIzvestajiColumnProps<PzvKolicineProizvodaPoGrupiIPreduzecu>[] =
  [
    { width: 100, dataIndex: 'godina', title: 'Godina' },
    { width: 150, dataIndex: 'pib', title: 'PIB' },
    { width: 150, dataIndex: 'mb', title: 'Matični broj' },
    { width: 250, dataIndex: 'preduzece', title: 'Preduzeće' },
    { width: 150, dataIndex: 'mesto_preduzeca', title: 'Mesto' },
    { width: 150, dataIndex: 'opstina_preduzeca', title: 'Opština' },
    {
      width: 150,
      dataIndex: 'ulica',
      title: 'Ulica',
    },
    {
      width: 150,
      dataIndex: 'broj',
      title: 'Broj',
    },
    {
      width: 100,
      dataIndex: 'grupa_proizvoda',
      title: 'Grupa proizvoda',
    },
    {
      width: 150,
      dataIndex: 'kolicina_proizvoda',
      title: 'Ukupna količina grupe proizvoda (kg)',
    },
    {
      width: 350,
      dataIndex: 'ukupna_kolicina',
      title: () => (
        <div>
          Ukupna količina (kg):
          <br />- fosfata u deterdžentu
          <br />- azota u mineralnom đubrivu
          <br />- aktivne materije u sredstvu za zaštitu bilja
        </div>
      ),
    },
    {
      width: 150,
      dataIndex: 'ukupna_kolicina_na_carini',
      title: 'Ukupna količina grupe proizvoda na Carini (kg)',
    },
  ];
//#endregion PZV

// Todo: figure out How to explicitly define types for dataIndex?
export const OBRAZAC_IZVESTAJI_COLUMN_MAP: {
  [key in IzvestajiSubtypes]: (data: any) => IIzvestajiColumnOrGroupProps<any>[];
} = {
  [Obrazac1IzvestajiEnum.GORIVA]: () => OBRAZAC_1_GORIVA_COLUMNS,
  [Obrazac1IzvestajiEnum.PROIZVODI]: () => OBRAZAC_1_PROIZVODI_COLUMNS,
  [Obrazac1IzvestajiEnum.SIROVINE]: () => OBRAZAC_1_SIROVINE_COLUMNS,
  [Obrazac1IzvestajiEnum.OPSTE]: () => OBRAZAC_1_OPSTE_COLUMNS,
  [Obrazac2IzvestajiEnum.ZAGADLJIVE_MATERIJE]: () => OBRAZAC_2_ZAGADLJIVE_MATERIJE_COLUMNS,
  [Obrazac2IzvestajiEnum.GORIVO]: () => OBRAZAC_2_GORIVO_COLUMNS,
  [Obrazac2IzvestajiEnum.PREGLED_PO_GODINAMA]: data => OBRAZAC_2_PREGLED_PO_GODINAMA_COLUMNS(data),
  [Obrazac2IzvestajiEnum.IZVORI_PO_GODINAMA]: data => OBRAZAC_2_IZVORI_PO_GODINAMA_COLUMNS(data),
  [Obrazac2IzvestajiEnum.OPSTE]: () => OBRAZAC_2_OPSTE_COLUMNS,
  [Obrazac2IzvestajiEnum.PRTR_KATEGORIJE]: () => OBRAZAC_2_PRTR_KATEGORIJE_COLUMNS,
  [Obrazac2IzvestajiEnum.KONTROLA_UNOSA]: data => OBRAZAC_2_KONTROLA_UNOSA_COLUMNS(data),
  [Obrazac2IzvestajiEnum.PRTR_PREGLED_PO_GODINAMA]: data => OBRAZAC_2_PRTR_PREGLED_PO_GODINAMA_COLUMNS(data),
  [Obrazac3IzvestajiEnum.ISPUST_PO_GODINAMA]: data => OBRAZAC_3_ISPUST_PO_GODINAMA_COLUMNS(data),
  [Obrazac3IzvestajiEnum.ZAGADLJIVE_MATERIJE]: () => OBRAZAC_3_ZAGADLJIVE_MATERIJE_COLUMNS,
  [Obrazac3IzvestajiEnum.PREGLED_PO_GODINAMA]: data => OBRAZAC_3_PREGLED_PO_GODINAMA_COLUMNS(data),
  [Obrazac3IzvestajiEnum.OPSTE]: () => OBRAZAC_3_OPSTE_COLUMNS,
  [Obrazac3IzvestajiEnum.KONTROLA_UNOSA]: data => OBRAZAC_3_KONTROLA_UNOSA_COLUMNS(data),
  [Obrazac3IzvestajiEnum.PRTR_PREGLED_PO_GODINAMA]: data => OBRAZAC_3_PRTR_PREGLED_PO_GODINAMA_COLUMNS(data),
  [Obrazac5IzvestajiEnum.POSTROJENJA]: () => OBRAZAC_5_POSTROJENJA_COLUMNS,
  [Obrazac5IzvestajiEnum.POSTROJENJA_INDEKSNI_BROJ]: () => OBRAZAC_5_POSTROJENJA_INDEKSNI_BROJ_COLUMNS,
  [Obrazac5IzvestajiEnum.GRUPA_PODGRUPA_INDEKSNI_BROJ]: () => OBRAZAC_5_GRUPA_PODGRUPA_INDEKSNI_BROJ_COLUMNS,
  [Obrazac5IzvestajiEnum.OTPAD_NE_OPASAN]: () => OBRAZAC_5_OTPAD_NE_OPASAN_COLUMNS,
  [Obrazac5IzvestajiEnum.GENERISANJE_OTPADA_PREGLED_PO_GODINAMA]: data =>
    OBRAZAC_5_GENERISANJE_OTPADA_PREGLED_PO_GODINAMA_COLUMNS(data),
  [Obrazac5IzvestajiEnum.OTPAD_POSTROJENJA_ZA_PLACANJE]: () => OBRAZAC_5_POSTROJENJA_ZA_PLACANJE_COLUMNS,
  [Obrazac5IzvestajiEnum.GENERISANJE_OTPADA_KONTROLA_UNOSA]: data => OBRAZAC_5_KONTROLA_UNOSA_COLUMNS(data),
  [Obrazac5IzvestajiEnum.PRTR_GENERISANJE_OTPADA_PREGLED_PO_GODINAMA]: data =>
    OBRAZAC_5_PRTR_PREGLED_PO_GODINAMA_COLUMNS(data),
  [Gio2IzvestajiEnum.POSTROJENJA_INDEKSNI_BROJ]: () => GIO_2_POSTROJENJA_INDEKSNI_BROJ_COLUMNS,
  [Gio2IzvestajiEnum.GRUPA_PODGRUPA_INDEKSNI_BROJ]: () => GIO_2_GRUPA_PODGRUPA_INDEKSNI_BROJ_COLUMNS,
  [Gio2IzvestajiEnum.ODLAGANJE_OTPADA_PREGLED_PO_GODINI]: data =>
    GIO_2_ODLAGANJE_OTPADA_PREGLED_PO_GODINAMA_COLUMNS(data),
  [Gio2IzvestajiEnum.ODLAGANJE_OTPADA_KONTROLA_UNOSA]: data => GIO_2_ODLAGANJE_OTPADA_KONTROLA_UNOSA_COLUMNS(data),
  [Gio2IzvestajiEnum.ELEKTRICNI_I_ELEKTRONSKI_OTPAD]: () => GIO_2_ELEKTRICNI_I_ELEKTRONSKI_OTPAD_COLUMNS,
  [Gio3IzvestajiEnum.POSTROJENJA_INDEKSNI_BROJ]: () => GIO_3_POSTROJENJA_INDEKSNI_BROJ_COLUMNS,
  [Gio3IzvestajiEnum.GRUPA_PODGRUPA_INDEKSNI_BROJ]: () => GIO_3_GRUPA_PODGRUPA_INDEKSNI_BROJ_COLUMNS,
  [Gio3IzvestajiEnum.TRETMAN_OTPADA_PREGLED_PO_GODINI]: data => GIO_3_TRETMAN_OTPADA_PREGLED_PO_GODINAMA_COLUMNS(data),
  [Gio3IzvestajiEnum.TRETMAN_OTPADA_KONTROLA_UNOSA]: data => GIO_3_TRETMAN_OTPADA_KONTROLA_UNOSA_COLUMNS(data),
  [Gio3IzvestajiEnum.ELEKTRICNI_I_ELEKTRONSKI_OTPAD]: () => GIO_3_ELEKTRICNI_I_ELEKTRONSKI_OTPAD_COLUMNS,
  [Gio4IzvestajiEnum.POSTROJENJA_INDEKSNI_BROJ]: () => GIO_4_POSTROJENJA_INDEKSNI_BROJ_COLUMNS,
  [Gio4IzvestajiEnum.GRUPA_PODGRUPA_INDEKSNI_BROJ]: () => GIO_4_GRUPA_PODGRUPA_INDEKSNI_BROJ_COLUMNS,
  [Gio4IzvestajiEnum.ELEKTRICNI_I_ELEKTRONSKI_OTPAD]: () => GIO_4_ELEKTRICNI_I_ELEKTRONSKI_OTPAD_COLUMNS,
  [Gio4IzvestajiEnum.IZVOZ_OTPADA_PREGLED_PO_GODINI]: data => GIO_4_PREGLED_PO_GODINAMA_COLUMNS(data),
  [Gio4IzvestajiEnum.IZVOZ_OTPADA_KONTROLA_UNOSA]: data => GIO_4_IZVOZ_OTPADA_KONTROLA_UNOSA_COLUMNS(data),
  [Gio4IzvestajiEnum.KOLICINA_IZVEZENOG_OTPADA_PO_VRSTI]: () => GIO_4_KOLICINA_IZVEZENOG_OTPADA_PO_VRSTI_OTPADA_COLUMNS,
  [Gio5IzvestajiEnum.POSTROJENJA_INDEKSNI_BROJ]: () => GIO_5_POSTROJENJA_INDEKSNI_BROJ_COLUMNS,
  [Gio5IzvestajiEnum.GRUPA_PODGRUPA_INDEKSNI_BROJ]: () => GIO_5_GRUPA_PODGRUPA_INDEKSNI_BROJ_COLUMNS,
  [Gio5IzvestajiEnum.UVOZ_OTPADA_PREGLED_PO_GODINI]: data => GIO_5_UVOZ_OTPADA_PREGLED_PO_GODINI_COLUMNS(data),
  [Gio5IzvestajiEnum.KOLICINA_UVEZENOG_OTPADA_PO_VRSTI]: () => GIO_5_KOLICINA_UVEZENOG_OTPADA_PO_VRSTI_COLUMNS,
  [Gio5IzvestajiEnum.UVOZ_OTPADA_KONTROLA_UNOSA]: data => GIO_5_UVOZ_OTPADA_KONTROLA_UNOSA_COLUMNS(data),
  [Deo6IzvestajiEnum.UKUPNO_PREUZET_OTPAD]: () => DEO_6_UKUPNO_PREUZET_OTPAD_COLUMNS,
  [Deo6IzvestajiEnum.PREUZET_OTPAD_POSTROJENJA_INDEKSNI_BROJ]: () =>
    DEO_6_PREUZET_OTPAD_POSTROJENJA_INDEKSNI_BROJ_COLUMNS,
  [Deo6IzvestajiEnum.UKUPNO_PREDAT_OTPAD]: () => DEO_6_UKUPNO_PREDAT_OTPAD_COLUMNS,
  [Deo6IzvestajiEnum.PREDAT_OTPAD_POSTROJENJA_INDEKSNI_BROJ]: () =>
    DEO_6_PREDAT_OTPAD_POSTROJENJA_INDEKSNI_BROJ_COLUMNS,
  [Deo6IzvestajiEnum.REKAPITULACIJA]: data => DEO_6_REKAPITULACIJA_COLUMNS(data),
  [Deo6IzvestajiEnum.REKALKULACIJA]: () => DEO_6_TRANSAKCIJE_PO_INDEKSNIM_BROJEVIMA_COLUMNS,
  [Deo6IzvestajiEnum.PRIMOPREDAJA_OTPADA_IZMEDJU_PREDUZECA]: () => DEO_6_PRIMOPREDAJA_OTPADA_IZMEDJU_PREDUZECA_COLUMNS,
  [Deo6IzvestajiEnum.GIO_3_DEO6_PO_PREDUZECU]: () => DEO_6_GIO_3_DEO6_PO_PREDUZECU_COLUMNS,
  [Kom1IzvestajiEnum.SASTAV_KOMUNALNOG_OTPADA_PO_GODINAMA]: data =>
    KOM_1_SASTAV_KOMUNALNOG_OTPADA_PO_GODINAMA_COLUMNS(data),
  [Kom1IzvestajiEnum.KOLICINA_KOMUNALNOG_OTPADA_PO_GODINAMA]: data =>
    KOM_1_KOLICINA_KOMUNALNOG_OTPADA_PO_GODINAMA_COLUMNS(data),
  [Kom1IzvestajiEnum.OBUHVAT_PRIKUPLJENOG_OTPADA_PO_GODINAMA]: data =>
    KOM_1_OBUHVAT_PRIKUPLJENOG_OTPADA_PO_GODINAMA_COLUMNS(data),
  [Kom1IzvestajiEnum.UPRAVLJANJE_KOMUNALNIM_OTPADOM]: () => KOM_1_UPRAVLJANJE_KOMUNALNIM_OTPADOM_COLUMNS,
  [Ptp2IzvestajiEnum.OBRACUN_ZA_NAPLATU]: PTP_2_OBRACUN_ZA_NAPLATU_COLUMNS,
  [Ptp2IzvestajiEnum.VOZILA_UPOREDJIVANJE]: PTP_2_VOZILA_UPOREDJIVANJE_COLUMNS,
  [Ptp2IzvestajiEnum.NEDOSTAVLJENI_PODACI_O_VOZILIMA]: PTP_2_NEDOSTAVLJENI_PODACI_O_VOZILIMA_COLUMNS,
  [Ptp2IzvestajiEnum.UVOZ_GUMA_UPOREDJIVANJE]: PTP_2_UVOZ_GUMA_UPOREDJIVANJE_COLUMNS,
  [Ptp2IzvestajiEnum.NEDOSTAVLJENI_PODACI_O_GUMAMA]: PTP_2_NEDOSTAVLJENI_PODACI_O_GUMAMA_COLUMNS,
  [Ptp2IzvestajiEnum.KONTROLA_UNOSA]: PTP_2_KONTROLA_UNOSA_COLUMNS,
  [Aao1IzvestajiEnum.PREGLED_PO_GODINAMA]: data => AAO_1_PREGLED_PO_GODINAMA_COLUMNS(data),
  [Aao1IzvestajiEnum.PRIKAZ_1]: () => AAO_1_PRIKAZ_1_COLUMNS,
  [Aao1IzvestajiEnum.SVI_PODACI]: data => AAO_1_SVI_PODACI_COLUMNS(data),
  [Aao1IzvestajiEnum.NEDOSTAVLJENI_OBRASCI]: () => AAO_1_NEDOSTAVLJENI_OBRASCI_COLUMNS,
  [Dep1IzvestajiEnum.SVI_PODACI]: () => DEP_1_SVI_PODACI_COLUMNS(),
  [Dep2IzvestajiEnum.SVI_PODACI]: () => DEP_2_SVI_PODACI_COLUMNS(),
  [Aao2IzvestajiEnum.IZVESTAJ_FOR_BOOK]: () => AAO2_IZVESTAJ_FOR_BOOK_COLUMNS,
  [PzvIzvestajiEnum.KOLICINE_PROIZVODA_PO_PREDUZECU]: () => PZV_KOLICINE_PROIZVODA_PO_PREDUZECU_COLUMNS,
  [PzvIzvestajiEnum.KOLICINE_PROIZVODA_PO_GRUPI_I_PREDUZECU]: () => PZV_KOLICINE_PROIZVODA_PO_GRUPI_I_PREDUZECU_COLUMNS,
};

export type IObrazacIzvestajAdditionalTableMap = {
  [Deo6IzvestajiEnum.REKALKULACIJA]: (data: any) => IIzvestajiColumnProps<any>[] | IIzvestajiColumnGroupProps<any>[];
};

export const OBRAZAC_DRUGI_IZVESTAJI_COLUMN_MAP: IObrazacIzvestajAdditionalTableMap = {
  [Deo6IzvestajiEnum.REKALKULACIJA]: () => DEO_6_TRANSAKCIJE_ZA_RAZREDE_ELEKTRICNIH_I_ELEKTRONSKIH_PROIZVODA_COLUMNS,
};
