import { useForm } from 'antd/es/form/Form';
import { FilterTableForm } from 'components/TableData/FilterTableForm/FilterTableForm';
import { observer } from 'mobx-react-lite';
import { facilityStore } from 'modules/facility/facility.store';
import { paginationInitialState } from 'modules/nriz/nriz.constants';
import { useTranslation } from 'react-i18next';
import { authStore } from 'store';

import { IFilterTableProps } from 'typescript/NrizTypes';

export const FacilityFiltersTable: React.FC = observer(() => {
  const { t } = useTranslation();

  const fetchFacilityCompanyList = () => {
    return facilityStore.fetchFacilityCompanyList(paginationInitialState);
  };

  const [facilityForm] = useForm();
  const facilityFilterOptions = [
    {
      label: t('FACILITY.TITLE'),
      name: 'naziv_postrojenja',
      type_of_form: 'input',
      inputName: 'naziv_postrojenja',
      sortName: 'naziv_postrojenja',
    },
    {
      label: t('FACILITY.NACIONAL_ID'),
      name: 'nacionalni_id',
      type_of_form: 'input',
      inputName: 'nacionalni_id',
      sortName: 'nacionalni_id',
    },
  ];
  const data = {
    form: facilityForm,
    initialState: { naziv_postrojenja: '', nacionalni_id: '', order_by: '' },
    filterName: 'postrojenja',
    filterOptions: facilityFilterOptions,
    fetchTableList: fetchFacilityCompanyList,
    // todo: proveriti da li svuda treba ilike
    dontAddIlike: !!authStore.isOnlyFacilityUser,
  } as IFilterTableProps;

  return <FilterTableForm data={data} />;
});
