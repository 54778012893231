import { observer } from 'mobx-react-lite';
import { modalStore } from 'store';
import styles from './FacilityUserModal.module.scss';
import { Modal } from 'components/Modal/Modal';
import { useTranslation } from 'react-i18next';
import { Segmented } from 'antd';
import { SegmentedLabeledOption, SegmentedValue } from 'antd/es/segmented';
import { userStore } from 'modules/user/user.store';
import { AddFacilityUsers, UserFormType } from 'modules/user/user.types';
import { FacilityAddUsers } from 'pages/Facility/components/FacilityAddUsers/FacilityAddUsers';

export const FacilityUserModal: React.FC = observer(() => {
  const facilityUsersSegmentedOptions: SegmentedLabeledOption[] = [
    {
      label: 'Postojeći korisnici',
      value: AddFacilityUsers.Existing,
    },
    {
      label: 'Novi korisnik',
      value: AddFacilityUsers.New,
    },
  ];

  const onFacilityUsersSegmentedChange = (value: SegmentedValue) => {
    userStore.setAddFacilityUser(value as AddFacilityUsers);
    if (value === AddFacilityUsers.New) userStore.setUserTypeForm(UserFormType.UserFacility);
  };

  const { t } = useTranslation();
  return (
    <Modal
      name="facility-user-modal"
      open={modalStore.getCurrentOpenedModal.includes('facility-user-modal')}
      className={`.and-modal-wrap .ant-modal ${styles.facilityUserModal}`}
      okButtonProps={{ style: { display: 'none' } }}
      cancelButtonProps={{ style: { display: 'none' } }}
      closable={true}
      onCancel={() => modalStore.removeAllModals()}
      title={t('USER.ADD_TO_FACILITY')}
      width={userStore.addFacilityUsers === AddFacilityUsers.Existing ? '80%' : '40%'}
      footer={null}
      destroyOnClose={true}
      maskClosable={true}
    >
      <div className={styles.facilityUsersWrapper}>
        <Segmented
          options={facilityUsersSegmentedOptions}
          onChange={onFacilityUsersSegmentedChange}
          className={styles.facilityUsersSegmented}
        />
        <FacilityAddUsers />
      </div>
    </Modal>
  );
});
