import to from 'await-to-js';
import { makeAutoObservable } from 'mobx';
import { nrizService } from 'modules/nriz/nriz.service';
import { IPagination } from 'typescript';
import { formStore } from '../../store/FormStore';
import { modalStore } from '../../store/ModalStore';
import { handleSuccessNotify } from 'utils/succesHandling';
import { sifarniciStore } from 'modules/sifarnici/sifarnici.store';
import { SIFARNICI_ROUTES } from 'modules/sifarnici/sifarnici.types';
import { facilityStore } from 'modules/facility/facility.store';
import { IGetFacility } from 'modules/facility/facility.types';
import { IGetActivity2, IPostActivity2 } from './activityList2.types';
import { activityInitialState } from 'modules/activityList1/activityList1.constants';
import { activity2InitialState } from './activityList2.constants';
import { activity2Service } from './activityList2.service';
import { activityList1Store } from 'modules/activityList1/activityList1.store';
import { deleteTipoveObrazacaIfActiveListIsEmpty } from 'modules/obrasci/tipoviObrasci.service';
import { paginationInitialState } from 'modules/nriz/nriz.constants';
import { IEmptyResponse, IPaginatedResponse, IPostResponse } from 'typescript/NrizTypes';
import { IGetPrivrednaDelatnost2 } from 'modules/sifarnici/store/privrednaDelatnost2/privrednaDelatnost2.types';
import { ITablePagination } from 'components/TableData/TableData';
import { IGetAktivnost2 } from 'modules/sifarnici/store/aktivnost2/aktivnost2.types';

export class ActivityList2Store {
  constructor() {
    makeAutoObservable(this);
  }

  activityList: IGetActivity2[] = [];
  activity: IPostActivity2 | IGetActivity2 = activity2InitialState;
  activityList2Pagination: IPagination = paginationInitialState;
  privredna_delatnost_id = '';

  get getActivityList() {
    return this.activityList;
  }

  get getActivity() {
    return this.activity;
  }

  handleChange(key: keyof ActivityList2Store, value: any) {
    (this as any)[key] = value;
  }

  setActivityList(activityList: IGetActivity2[]) {
    this.activityList = activityList;
  }

  setActivity(activity: IGetActivity2) {
    this.activity = activity;
  }

  fetchAktivnost2 = async (pagination: IPagination): Promise<IPaginatedResponse<IGetAktivnost2>> => {
    const response = await sifarniciStore.fetchSifarnikOptions<IGetAktivnost2>(SIFARNICI_ROUTES.AKTIVNOST_2, {
      page: pagination.page,
      size: pagination.size,
      privredna_delatnost_id: this.privredna_delatnost_id,
      order_by: '+code',
    });
    return response;
  };

  fetchPrivrednaDelatnost2 = async (pagination: IPagination): Promise<IPaginatedResponse<IGetPrivrednaDelatnost2>> => {
    const response = await sifarniciStore.fetchSifarnikOptions<IGetPrivrednaDelatnost2>(
      SIFARNICI_ROUTES.PRIVREDNA_DELATNOST_2,
      {
        page: pagination.page,
        size: pagination.size,
        order_by: '+code',
      }
    );
    return response;
  };

  fetchActivityList = async (pagination?: ITablePagination) => {
    const [err, res] = await to<IPaginatedResponse<IGetActivity2>>(
      activity2Service.fetchActivityList2({
        postrojenje_id: (facilityStore.facility as IGetFacility).id,
        page: pagination?.page || this.activityList2Pagination.page,
        size: pagination?.size || this.activityList2Pagination.size,
      })
    );
    if (err || !res) return;
    const { items, total, page, size, pages } = res;
    this.handleChange('activityList2Pagination', { total, page, size, pages });
    this.setActivityList(items);
    return res;
  };

  postActivity = async (payload: IPostActivity2) => {
    const validPayload = nrizService.setEmptyValuesToNull({
      ...payload,
      postrojenje_id: (facilityStore.facility as IGetFacility).id,
    });

    const [err, res] = await to<IPostResponse>(activity2Service.postActivity2(validPayload as IGetActivity2));
    if (err || !res) return;
    const { id } = res;

    let aktivnostSifarnik = {};
    let privrednaDelatnostSifarnik = {};

    aktivnostSifarnik = nrizService.addSifarnikInObject({
      list: sifarniciStore.list[SIFARNICI_ROUTES.AKTIVNOST_2],
      name: 'aktivnost2',
      id: payload.aktivnost_id,
    }) as { aktivnost2: IGetActivity2 };

    privrednaDelatnostSifarnik = nrizService.addSifarnikInObject({
      list: sifarniciStore.list[SIFARNICI_ROUTES.PRIVREDNA_DELATNOST_2],
      name: 'privredna_delatnost2',
      id: payload.privredna_delatnost_id,
    }) as { privredna_delatnost2: IGetPrivrednaDelatnost2 };

    const newPayload = {
      ...payload,
      ...aktivnostSifarnik,
      ...privrednaDelatnostSifarnik,
      id,
    } as IGetActivity2;

    const newActivityList = [newPayload, ...this.activityList];

    this.setActivityList(newActivityList);
    this.handleChange('activityList2Pagination', nrizService.increaseTotalPagination(this.activityList2Pagination));
    this.handleChange('privredna_delatnost_id', '');
    this.handleChange('activity', activityInitialState);
    modalStore.removeAllModals();
    handleSuccessNotify();
  };

  putActivity = async (payload: IGetActivity2) => {
    const validPayload = { ...payload, postrojenje_id: (facilityStore.facility as IGetFacility).id };

    const [err, res] = await to<IEmptyResponse>(activity2Service.putActivity2(validPayload));
    if (err || !res) return;

    let aktivnostSifarnik = {};
    let privrednaDelatnostSifarnik = {};

    aktivnostSifarnik = nrizService.addSifarnikInObject({
      exId: this.activity.aktivnost_id,
      exObj: (this.activity as IGetActivity2).aktivnost2,
      list: sifarniciStore.list[SIFARNICI_ROUTES.AKTIVNOST_2],
      name: 'aktivnost2',
      id: payload.aktivnost_id,
    });

    privrednaDelatnostSifarnik = nrizService.addSifarnikInObject({
      exId: this.activity.privredna_delatnost_id,
      exObj: (this.activity as IGetActivity2).privredna_delatnost2,
      list: sifarniciStore.list[SIFARNICI_ROUTES.PRIVREDNA_DELATNOST_2],
      name: 'privredna_delatnost2',
      id: payload.privredna_delatnost_id,
    });

    const newPayload = { ...payload, ...aktivnostSifarnik, ...privrednaDelatnostSifarnik };
    this.setActivityList([...nrizService.changeItemInList(this.activityList, newPayload)]);
    this.handleChange('privredna_delatnost_id', '');
    this.handleChange('activity', activityInitialState);
    modalStore.removeAllModals();
    handleSuccessNotify();
  };

  deleteActivity = async (id: string) => {
    const [err, res] = await to<IEmptyResponse>(activity2Service.deleteActivity2(id));
    if (err || !res) return;

    const newActivityList = this.activityList.filter(activity => activity.id !== id);
    this.setActivityList(newActivityList);
    this.handleChange('activityList2Pagination', nrizService.decreaseTotalPagination(this.activityList2Pagination));
    this.handleChange('activity', activityInitialState);
    if (this.activityList2Pagination.total === 0 && activityList1Store.activityList1Pagination.total === 0) {
      deleteTipoveObrazacaIfActiveListIsEmpty();
    }
    modalStore.removeAllModals();
    handleSuccessNotify();
  };

  submitActivity = (values: IPostActivity2) => {
    if (formStore.getCrudButtonType === 'submit') {
      return this.postActivity(values);
    }
    const newObject = { ...this.activity, ...values } as IGetActivity2;
    if (formStore.getCrudButtonType === 'change') {
      return this.putActivity(newObject);
    }
    if (formStore.getCrudButtonType === 'delete') {
      return this.deleteActivity(newObject.id);
    }
  };
}

export const activityList2Store = new ActivityList2Store();
