import to from 'await-to-js';
import { makeAutoObservable } from 'mobx';
import { obrasciService } from './obrasci.service';
import {
  CUSTOM_OBRASCI_KEYS,
  CustomTipoviObrazaca,
  IOpsteInitial,
  ITipObrasca,
  ITipObrascaConfig,
  ITipoviObrascaReduceNivoResult,
  ITipoviObrazacaMap,
  OpsteProvera,
  TIPOVI_OBRAZACA,
} from './obrasci.types';
import { arrayToObject } from 'utils/arrayToObject';
import { IPagination } from 'typescript';
import { nrizService } from 'modules/nriz/nriz.service';
import { sifarniciStore } from 'modules/sifarnici/sifarnici.store';
import { SIFARNICI_ROUTES } from 'modules/sifarnici/sifarnici.types';
import { opsteInitial } from './obrasci.constants';
import { authStore } from 'store';
import dayjs from 'dayjs';
import { filtersStore } from 'store/filteri.store';
import { IGetCompany } from 'modules/company/company.types';
import { facilityService } from 'modules/facility/facility.service';
import { companyService } from 'modules/company/company.service';
import storage, { StorageEnum } from 'store/storage';
import { INITIAL_RESPONSE_SIFARNIK } from 'modules/sifarnici/sifarnici.constants';
import { saveBlob } from 'utils/fileDownload';
import { Deo6Gio6Enum } from './store/deo6/deo6.types';
import { IExportDeo6Gio6PdfForm } from 'components/Modals/ExportDeo6Gio6PdfModal/ExportDeo6Gio6PdfModal';
import { CUSTOM_OBRASCI_MAKER } from './tipoviObrasci.service';
import { IBaseObrazacStore, IEmptyResponse, IPaginatedResponse } from 'typescript/NrizTypes';
import { deo6ObrazacStore } from './store/deo6/deo6.store';
import { ITablePagination } from 'components/TableData/TableData';
import { paginationInitialState } from 'modules/nriz/nriz.constants';
import { IGetFacility } from 'modules/facility/facility.types';

export interface IObrazac {
  name: string;
}

class ObrasciStore {
  tipoviObrazaca: ITipObrascaConfig[] = [];
  obrasciList: IObrazac[] = [];
  disabled_tab_button = true;
  opste: IOpsteInitial = opsteInitial;
  obrazac_type: TIPOVI_OBRAZACA | '' = '';
  real_obrazac_type: TIPOVI_OBRAZACA | '' = '';
  tip_obrasca_id = '';
  real_tip_obrasca_id = '';
  isValidationNotificationOpen = false;
  isDeo6OrGio6: Deo6Gio6Enum | null = null;
  otkljucaneGodine: number[] = [];
  zakljucanObrazac = false;
  javno_dostupno = false;
  obrasciPagination: IPagination = paginationInitialState;

  constructor() {
    makeAutoObservable(this);
  }

  get obrasciPoNivou(): ITipoviObrascaReduceNivoResult {
    return obrasciService.filterByNivo(obrasciService.buildTipoviConfig(storage.getTipoviObrazaca()));
  }

  get obrasciShortNameMap(): ITipoviObrazacaMap {
    return obrasciService.makeObrasciShortNameMap(obrasciService.buildTipoviConfig(storage.getTipoviObrazaca()));
  }

  get obrasciMap() {
    return arrayToObject(obrasciService.buildTipoviConfig(storage.getTipoviObrazaca()));
  }

  get allTipoviObrazacaMapPoId() {
    return arrayToObject(storage.getTipoviObrazaca());
  }

  get selectedObrasci() {
    return (obrasciIds: string[]) => obrasciIds?.map(id => this.obrasciMap[id]);
  }

  handleChange(key: keyof ObrasciStore, value: any) {
    (this as any)[key] = value;
  }

  handleOpste(type: string, value: any) {
    this.opste = { ...this.opste, [type]: value };
  }

  setTipoviObrazaca = (tipoviObrazaca: ITipObrasca[]) => {
    this.tipoviObrazaca = obrasciService.buildTipoviConfig(tipoviObrazaca);
  };

  setZakljucanObrazac = (disabled: boolean) => {
    this.zakljucanObrazac = disabled;
  };

  setObrasciList = (obrasciList: IObrazac[]) => {
    this.obrasciList = obrasciList;
  };

  setDisabledTabButton = (value: boolean) => {
    this.disabled_tab_button = value;
  };

  setOtkljucaneGodine = (godine: number[]) => {
    this.otkljucaneGodine = godine;
  };

  fetchTipoviObrazaca = async () => {
    const [err, res] = await to(obrasciService.fetchTipoviObrasca());
    if (err || !res) return;

    storage.setData(StorageEnum.TIPOVI_OBRAZACA, res.items);
    const firstItem = res.items[0] as ITipObrasca;
    if (!this.real_obrazac_type && authStore.isAdmin) this.handleSameObrazac(firstItem.skraceni_naziv);
    this.setTipoviObrazaca(res.items);
  };

  fetchTipoviObrazacaPoPreduzecu = async () => {
    const [err, res] = await to(companyService.fetchCompanyTipObrasca(this.opste.preduzece_id));
    if (err || !res) return;
    const previouslySelectedObrazac = res.find(tip => this.real_obrazac_type === tip.skraceni_naziv);
    const firstItem = res[0];
    this.handleSameObrazac(previouslySelectedObrazac?.skraceni_naziv ?? firstItem?.skraceni_naziv);
    this.setTipoviObrazaca(res);
  };

  fetchTipoviObrazacaPoPostrojenju = async () => {
    const [err, res] = await to(facilityService.fetchFacilityTipObrasca(this.opste.postrojenje_id));
    if (err || !res) return;
    const newArray = obrasciService.combineTwoSameObrazacTypeIntoOne(res);
    const previouslySelectedObrazac = newArray.find(tip => this.real_obrazac_type === tip.skraceni_naziv);
    const firstItem = newArray[0];
    if (firstItem) {
      this.handleSameObrazac(previouslySelectedObrazac?.skraceni_naziv ?? firstItem?.skraceni_naziv);
    }
    this.setTipoviObrazaca(res);
  };

  fetchListObrazac = async (pagination?: ITablePagination): Promise<IPaginatedResponse<any>> => {
    if (!this.obrazac_type || !this.real_obrazac_type) return Promise.reject();
    const realObrazac = obrasciService.obrasciListFiltersSameObrazac(this.real_obrazac_type);
    const [err, res] = await to<IPaginatedResponse>(
      obrasciService.fetchListObrazac(this.obrazac_type, {
        page: pagination?.page || this.obrasciPagination.page,
        size: pagination?.size || this.obrasciPagination.size,
        ...realObrazac,
        ...nrizService.pickFields({
          preduzece_id: this.opste.preduzece_id,
          postrojenje_id: this.opste.postrojenje_id,
          ...filtersStore.filters.obrasci,
        }),
      })
    );
    if (err || !res) return Promise.reject();
    const { total, page, pages, size, items } = res;
    this.setObrasciList(items);
    this.handleChange('obrasciPagination', { total, page, size, pages });
    return res;
  };

  fetchPostrojenje = async (pagination: IPagination, search: string): Promise<IPaginatedResponse<IGetFacility>> => {
    if (!this.opste.preduzece_id) return INITIAL_RESPONSE_SIFARNIK;

    const response = await sifarniciStore.fetchSifarnikOptions<IGetFacility>(SIFARNICI_ROUTES.POSTROJENJE, {
      page: pagination.page,
      size: pagination.size,
      preduzece_id: this.opste.preduzece_id,
      search: search,
    });
    return response;
  };

  fetchPostrojenjePoObrascu = async (
    pagination: IPagination,
    search: string
  ): Promise<IPaginatedResponse<IGetFacility>> => {
    const response = await sifarniciStore.fetchSifarnikOptions<IGetFacility>(
      SIFARNICI_ROUTES.PREDUZECE_POSTROJENJE_TIP_OBRASCA_UPITNIK_POSTROJENJE,
      {
        page: pagination.page,
        size: pagination.size,
        preduzece_id: this.opste.preduzece_id,
        tip_obrasca_id: this.real_tip_obrasca_id,
        search: search,
      }
    );
    return response;
  };

  fetchPostrojenjaByUser = async (pagination: IPagination): Promise<IPaginatedResponse<IGetFacility>> => {
    const response = await sifarniciStore.fetchSifarnikOptions<IGetFacility>(SIFARNICI_ROUTES.KORISNIK_POSTROJENJE, {
      page: pagination.page,
      size: pagination.size,
      user_id: authStore.userId,
    });
    return response;
  };

  fetchPreduzece = async (pagination: IPagination, search: string): Promise<IPaginatedResponse<IGetCompany>> => {
    const response = await sifarniciStore.fetchSifarnikOptions<IGetCompany>(SIFARNICI_ROUTES.PREDUZECE, {
      page: pagination.page,
      size: pagination.size,
      search: search,
    });
    return response;
  };

  fetchPreduzecaPoObrascu = async (
    pagination: IPagination,
    search: string
  ): Promise<IPaginatedResponse<IGetCompany>> => {
    const response = await sifarniciStore.fetchSifarnikOptions<IGetCompany>(
      SIFARNICI_ROUTES.PREDUZECE_POSTROJENJE_TIP_OBRASCA_UPITNIK_PREDUZECE,
      {
        page: pagination.page,
        size: pagination.size,
        tip_obrasca_id: this.real_tip_obrasca_id,
        search: search,
      }
    );
    return response;
  };

  loadSingleObrazacData = async (storeFunc: (id: string) => any, id: string): Promise<any> => {
    const res = await storeFunc(id);

    if (res.godina) {
      this.handleChange('opste', { ...this.opste, godina: res.godina });
    }
    if (res.preduzece_id) {
      this.handleChange('opste', { ...this.opste, preduzece_id: res.preduzece_id, preduzece: res.preduzece });
    }
    if (res.postrojenje_id) {
      this.handleChange('opste', { ...this.opste, postrojenje_id: res.postrojenje_id, postrojenje: res.postrojenje });
    }
  };

  exportPdfSingleObrazac = async (id: string) => {
    if (!this.obrazac_type || !this.real_obrazac_type) return;
    const combineObrazac = CUSTOM_OBRASCI_KEYS.includes(this.real_obrazac_type as TIPOVI_OBRAZACA);

    const sameObrazacFilters = combineObrazac
      ? obrasciService.firstExportPdfFilters(this.real_obrazac_type as TIPOVI_OBRAZACA)
      : obrasciService.obrasciListFiltersSameObrazac(this.real_obrazac_type as TIPOVI_OBRAZACA);

    let filters = { javno_dostupno: this.javno_dostupno };
    if (sameObrazacFilters) {
      filters = { ...filters, ...sameObrazacFilters };
    }
    const [err, res] = await to<Blob>(obrasciService.exportPdfSingleObrazac(this.obrazac_type, id, filters));
    if (err || !res) return Promise.reject();
    const downloadName = `${obrasciService.nameForFirstExportPdf(this.real_obrazac_type)}_${dayjs().format(
      'DD-MM-YYYY'
    )}`;
    saveBlob(res, downloadName);

    if (combineObrazac) {
      this.exportPdfSecondObrazac(id);
    }
  };

  exportPdfSecondObrazac = async (id: string) => {
    if (!this.obrazac_type || !this.real_obrazac_type) return;
    const filters = {
      ...obrasciService.secondExportPdfFilters(this.real_obrazac_type as TIPOVI_OBRAZACA),
      javno_dostupno: this.javno_dostupno,
    };
    const [err, res] = await to<Blob>(obrasciService.exportPdfSingleObrazac(this.obrazac_type, id, filters));
    if (err || !res) return Promise.reject();
    const downloadName = `${obrasciService.nameForSecondExportPdfFile(this.real_obrazac_type)}_${dayjs().format(
      'DD-MM-YYYY'
    )}`;
    saveBlob(res, downloadName);
  };

  exportDeo6Gio6Pdf = async (filters: IExportDeo6Gio6PdfForm) => {
    if (!this.isDeo6OrGio6) return;
    const [err, res] = await to<Blob>(obrasciService.exportPdfDeo6Gio6(this.isDeo6OrGio6, filters));
    if (err || !res) return;
    const downloadName = `${this.isDeo6OrGio6}_${dayjs().format('DD-MM-YYYY')}`;
    saveBlob(res, downloadName);
  };

  checkGodinaZakljucanaForPreduzece = async (preduzece_id: string) => {
    if (!this.tip_obrasca_id) return [];
    // size zbog svih dostupnih godina u sistemu
    const filters = { page: 1, size: 50, preduzece_id, tip_obrasca_id: this.real_tip_obrasca_id };
    try {
      const otkljucaneGodine = await obrasciService.checkGodinaZakljucana(filters);
      this.setOtkljucaneGodine(otkljucaneGodine);
    } catch {
      this.setOtkljucaneGodine([]);
    }
  };

  checkGodinaZakljucanaForPostrojenje = async (tipObrasca: CustomTipoviObrazaca | TIPOVI_OBRAZACA | undefined) => {
    if (!tipObrasca) return;
    const isCustomTipObrasca = obrasciService.checkIfCustomTipObrasca(tipObrasca);

    const storageTipovi = storage.getTipoviObrazaca().reduce((tipoviDict, tip) => {
      return { ...tipoviDict, [tip.skraceni_naziv]: tip };
    }, {} as { [key in TIPOVI_OBRAZACA]: ITipObrasca });

    if (isCustomTipObrasca) {
      const baseTipovi = obrasciService.separateCustomTipObrasca(tipObrasca);
      const nizoviOtkljucanihGodina = baseTipovi.map(async (tip): Promise<number[]> => {
        const tip_obrasca_id = storageTipovi[tip].id;
        const filters = { page: 1, size: 50, preduzece_id: this.opste.preduzece_id, tip_obrasca_id };
        try {
          const otkljucaneGodine = await obrasciService.checkGodinaZakljucana(filters);
          return otkljucaneGodine;
        } catch {
          return [];
        }
      });
      const [niz1, niz2] = await Promise.all(nizoviOtkljucanihGodina);

      const otkljucaneGodine = niz1.filter(godina => niz2.includes(godina));
      this.setOtkljucaneGodine(otkljucaneGodine);
    } else {
      const tip_obrasca_id = storageTipovi[tipObrasca].id;
      const filters = { page: 1, size: 50, preduzece_id: this.opste.preduzece_id, tip_obrasca_id };
      try {
        const otkljucaneGodine = await obrasciService.checkGodinaZakljucana(filters);
        this.setOtkljucaneGodine(otkljucaneGodine);
      } catch {
        this.setOtkljucaneGodine([]);
      }
    }
  };

  exportObrazacListExcel = async () => {
    const [err, res] = await to(
      obrasciService.exportObrazacListExcel(this.obrazac_type as TIPOVI_OBRAZACA, {
        ...filtersStore.filters.obrasci,
        ...obrasciService.obrasciListFiltersSameObrazac(this.real_obrazac_type as TIPOVI_OBRAZACA),
      })
    );
    if (err || !res) return;
    const downloadName = `${this.real_obrazac_type}_${dayjs().format('DD-MM-YYYY')}`;
    saveBlob(res, downloadName);
  };

  setOpsteObrazacWizardUser = (store: IBaseObrazacStore) => {
    const preduzecePostrojenje = obrasciService.setOpsteObrazacWizardUser();
    const newInitialState = {
      ...store.initialState,
      ...preduzecePostrojenje,
    };

    this.handleChange('opste', preduzecePostrojenje);
    store.handleChange('initialState', newInitialState);
  };

  resetTipObrasca = () => {
    this.handleChange('obrazac_type', '');
    this.handleChange('real_obrazac_type', '');
    this.handleChange('tip_obrasca_id', '');
    this.handleChange('real_tip_obrasca_id', '');
  };

  handleSameObrazac = (type: TIPOVI_OBRAZACA) => {
    const tip = obrasciService.switchObrasciType(type);
    const isObrazacCustom = obrasciService.checkIfCustomTipObrasca(type);

    const originalTipObrascaObj = isObrazacCustom ? CUSTOM_OBRASCI_MAKER(type) : this.obrasciShortNameMap[type];

    const tipObrascaObj = this.obrasciShortNameMap[tip];

    this.handleChange('tip_obrasca_id', tipObrascaObj.id);
    this.handleChange('obrazac_type', tip);
    this.handleChange('real_tip_obrasca_id', originalTipObrascaObj.id);
    this.handleChange('real_obrazac_type', type);
  };

  checkIfObrazacLocked = async (id: string) => {
    const [err, res] = await to(obrasciService.checkIfObrazacLocked(id));
    if (err || !res) return;
    if (res.some(obrazac => obrazac.zakljucan_obrazac)) return true;
  };

  checkIfObrazacDeo6Locked = async () => {
    const godina = dayjs(deo6ObrazacStore.datum).year();
    const { postrojenje_id, preduzece_id } = this.opste;

    const payload = { godina, postrojenje_id, preduzece_id, tip_obrasca_id: this.tip_obrasca_id };

    const [err, res] = await to(obrasciService.checkIfObrazacDeo6Locked(payload));
    if (err || !res) return Promise.reject();
    const [obrazac] = res.items;
    if (obrazac?.zakljucan_obrazac) return true;
  };

  deleteObrazac = async (id: string) => {
    const [err, res] = await to<IEmptyResponse>(obrasciService.deleteObrazac(this.obrazac_type as TIPOVI_OBRAZACA, id));
    if (err || !res) return Promise.reject();
    return true;
  };

  checkIfObrazacExists = async (params?: Partial<OpsteProvera>) => {
    const { preduzece_id } = this.opste;

    const [err, res] = await to(
      obrasciService.checkIfObrazacExists({
        tip_obrasca_id: this.real_tip_obrasca_id,
        preduzece_id,
        ...params,
      })
    );

    if (err || !res) return false;

    const { obrazac_postoji } = res;

    if (obrazac_postoji && this.opste.godina !== '') this.handleOpste('godina', '');

    return obrazac_postoji;
  };
}

export const obrasciStore = new ObrasciStore();
