import { ColumnsType } from 'antd/es/table';
import TableData from 'components/TableData/TableData';
import { observer } from 'mobx-react-lite';
import { gio4ObrazacStore } from 'modules/obrasci/store/gio4/gio4.store';
import { IGetDozvoleGio4 } from 'modules/obrasci/store/gio4/gio4.types';
import { useTranslation } from 'react-i18next';

import dayjs from 'dayjs';
import { Checkbox } from 'antd';
import { EditButtonProps, RowEditPreviewButton } from 'components/RowEditPreviewButton/RowEditPreviewButton';
import { obrasciStore } from 'modules/obrasci/obrasci.store';

const EditGio4DozvoleButton: React.FC<EditButtonProps<IGetDozvoleGio4>> = observer(({ record }) => {
  return (
    <RowEditPreviewButton
      name="gio4-dozvole"
      modalName="gio4-dozvole-modal"
      onClick={() => gio4ObrazacStore.handleChange('dozvoleInitial', record)}
      preview={obrasciStore.zakljucanObrazac}
    />
  );
});

export const Gio4TableDozvole: React.FC = observer(() => {
  const { t } = useTranslation();

  const baseColumns: ColumnsType<IGetDozvoleGio4> = [
    {
      title: `${t(['OBRASCI.GIO2.BROJ_DOZVOLE'])}`,
      width: 100,
      dataIndex: 'broj_dozvole',
      key: 'broj_dozvole',
    },
    {
      title: `${t('OBRASCI.GIO2.DATUM_IZDAVANJA')}`,
      width: 100,
      render: (_, record) => <span>{dayjs(record.datum_izdavanja).format('DD. MM. YYYY.')}</span>,
      key: 'datum_izdavanja',
    },
    {
      title: `${t('OBRASCI.GIO2.DATUM_ISTEKA')}`,
      key: 'datum_isteka',
      width: 100,
      render: (_, record) => <span>{dayjs(record.datum_isteka).format('DD. MM. YYYY.')}</span>,
    },
    {
      title: `${t('OBRASCI.GIO2.SAKUPLJANJE')}`,
      render: (_, record) => <Checkbox checked={!!record.sakupljanje} disabled={true} />,
      key: 'sakupljanje',
      width: 100,
      align: 'center',
    },
    {
      title: `${t('OBRASCI.GIO2.TRANSPORT')}`,
      render: (_, record) => <Checkbox checked={!!record.transport} disabled={true} />,
      key: 'transport',
      width: 100,
      align: 'center',
    },
    {
      title: `${t('OBRASCI.GIO2.SKLADISTENJE')}`,
      render: (_, record) => <Checkbox checked={!!record.skladistenje} disabled={true} />,
      key: 'skladistenje',
      width: 100,
      align: 'center',
    },
    {
      title: `${t('OBRASCI.GIO2.TRETMAN')}`,
      render: (_, record) => <Checkbox checked={!!record.tretman} disabled={true} />,
      key: 'tretman',
      width: 100,
      align: 'center',
    },
    {
      title: `${t('OBRASCI.GIO2.ODLAGANJE')}`,
      render: (_, record) => <Checkbox checked={!!record.odlaganje} disabled={true} />,
      key: 'odlaganje',
      width: 100,
      align: 'center',
    },
    {
      title: ``,
      key: 'operation',
      fixed: 'right',
      width: '70px',
      align: 'center',
      render: (_, record: IGetDozvoleGio4) => <EditGio4DozvoleButton record={record} />,
    },
  ];
  return (
    <TableData
      scrollConfig={['#obrazacTableHeader', '#obrazacWizardHeader']}
      name="dozvole-gio4"
      columns={baseColumns}
      dataSource={[...gio4ObrazacStore.initialState.dozvole]}
    />
  );
});
