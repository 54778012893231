import { ColumnsType } from 'antd/es/table';
import TableData from 'components/TableData/TableData';
import { observer } from 'mobx-react-lite';
import { gio2ObrazacStore } from 'modules/obrasci/store/gio2/gio2.store';
import { IGetDozvoleGio2 } from 'modules/obrasci/store/gio2/gio2.types';
import { useTranslation } from 'react-i18next';
import dayjs from 'dayjs';
import { Checkbox } from 'antd';
import { EditButtonProps, RowEditPreviewButton } from 'components/RowEditPreviewButton/RowEditPreviewButton';
import { obrasciStore } from 'modules/obrasci/obrasci.store';

const EditGio2DozvoleButton: React.FC<EditButtonProps<IGetDozvoleGio2>> = observer(({ record }) => {
  return (
    <RowEditPreviewButton
      name="gio2-dozvole"
      modalName="gio2-dozvole-modal"
      onClick={() => gio2ObrazacStore.handleChange('dozvole_form', record)}
      preview={obrasciStore.zakljucanObrazac}
    />
  );
});

export const Gio2TableDozvole: React.FC = observer(() => {
  const { t } = useTranslation();

  const baseColumns: ColumnsType<IGetDozvoleGio2> = [
    {
      title: `${t(['OBRASCI.GIO2.BROJ_DOZVOLE'])}`,
      width: 100,
      dataIndex: 'broj_dozvole',
      key: 'broj_dozvole',
    },
    {
      title: `${t('OBRASCI.GIO2.DATUM_IZDAVANJA')}`,
      width: 100,
      render: (_, record) => <span>{dayjs(record.datum_izdavanja).format('DD. MM. YYYY.')}</span>,
      key: 'datum_izdavanja',
    },
    {
      title: `${t('OBRASCI.GIO2.DATUM_ISTEKA')}`,
      key: 'datum_isteka',
      width: 100,
      render: (_, record) => <span>{dayjs(record.datum_isteka).format('DD. MM. YYYY.')}</span>,
    },
    {
      title: `${t('OBRASCI.GIO2.SAKUPLJANJE')}`,
      render: (_, record) => <Checkbox checked={!!record.sakupljanje} disabled={true} />,
      key: 'sakupljanje',
      width: 100,
      align: 'center',
    },
    {
      title: `${t('OBRASCI.GIO2.TRANSPORT')}`,
      render: (_, record) => <Checkbox checked={!!record.transport} disabled={true} />,
      key: 'transport',
      width: 100,
      align: 'center',
    },
    {
      title: `${t('OBRASCI.GIO2.SKLADISTENJE')}`,
      render: (_, record) => <Checkbox checked={!!record.skladistenje} disabled={true} />,
      key: 'skladistenje',
      width: 100,
      align: 'center',
    },
    {
      title: `${t('OBRASCI.GIO2.TRETMAN')}`,
      render: (_, record) => <Checkbox checked={!!record.tretman} disabled={true} />,
      key: 'tretman',
      width: 100,
      align: 'center',
    },
    {
      title: `${t('OBRASCI.GIO2.ODLAGANJE')}`,
      render: (_, record) => <Checkbox checked={!!record.odlaganje} disabled={true} />,
      key: 'odlaganje',
      width: 100,
      align: 'center',
    },
    {
      title: `${t('COMPANY.ADDITIONALLY')}`,
      key: 'operation',
      fixed: 'right',
      width: '100px',
      align: 'center',
      render: (_, record: IGetDozvoleGio2) => <EditGio2DozvoleButton record={record} />,
    },
  ];

  return (
    <TableData
      scrollConfig={['#obrazacTableHeader', '#obrazacWizardHeader']}
      name="dozvole-gio2"
      columns={baseColumns}
      dataSource={[...gio2ObrazacStore.initialState.dozvole]}
    />
  );
});
