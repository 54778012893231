import { Col } from 'antd';
import { FormDatePicker } from 'components/FormDatePicker/FormDatePicker';
import { FormInput } from 'components/FormInput/FormInput';
import { FormSearchInfiniteScroll } from 'components/FormSearchInfiniteScroll/FormSearchInfiniteScroll';
import { InsideContentWrapper } from 'components/InsideContentWrapper/InsideContentWrapper';
import { WizardObrazacListHeader } from 'components/WizardObrazacListHeader/WizardObrazacListHeader';
import { observer } from 'mobx-react-lite';
import { REQUIRED_FIELD_RULE } from 'modules/nriz/nriz.constants';
import { obrasciStore } from 'modules/obrasci/obrasci.store';
import { obrazac3Store } from 'modules/obrasci/store/obrazac3/obrazac3.store';
import { IGetObrazac3MerenjeOtpadneVode } from 'modules/obrasci/store/obrazac3/obrazac3.types';
import { ISifarnikInitial } from 'modules/sifarnici/sifarnici.types';
import { Obrazac3AnalizaOtpadneVodeIzmereneVrednostiTable } from 'pages/ObrazacWizard/components/obrazac3/components/Obrazac3AnalizaOtpadneVodeWizardTab/components/Obrazac3AnalizaOtpadneVodeIzmereneVrednostiTable/Obrazac3AnalizaOtpadneVodeIzmereneVrednostiTable';
import { useTranslation } from 'react-i18next';
import { enableDateOnlyForSelectedYearAndDisableSelectedDateInList } from 'utils/formatDate';
import styles from './NewObrazac3AnalizaOtpadneVodeMerenjeForm.module.scss';
import dayjs from 'dayjs';

export const NewObrazac3AnalizaOtpadneVodeMerenjeForm: React.FC = observer(() => {
  const { t } = useTranslation();

  const existingDates = obrazac3Store.initialState.merenja_otpadne_vode.map(item => dayjs(item.datum_merenja));

  const defaultLaboratoriesAirWaterOption: ISifarnikInitial | undefined = obrazac3Store.otpadne_vode_merenje_form
    .naziv_strucne_laboratorije_id
    ? {
        label: (obrazac3Store.otpadne_vode_merenje_form as IGetObrazac3MerenjeOtpadneVode).naziv_strucne_laboratorije
          .name_of_laboratory,
        value: obrazac3Store.otpadne_vode_merenje_form.naziv_strucne_laboratorije_id,
      }
    : undefined;

  const readOnly = obrasciStore.zakljucanObrazac;

  return (
    <Col span={24}>
      <FormDatePicker
        name={'datum_merenja'}
        label={t('OBRASCI.OBRAZAC3.DATUM_MERENJA')}
        rules={[REQUIRED_FIELD_RULE(true)]}
        disabledDate={currentDate =>
          enableDateOnlyForSelectedYearAndDisableSelectedDateInList(
            currentDate,
            Number(obrasciStore.opste.godina),
            existingDates
          )
        }
        labelCol={{ span: 12 }}
        wrapperCol={{ span: 12 }}
        readOnly={readOnly}
      />
      <FormInput
        name="indetifikacioni_broj_izvestaja"
        label={t('OBRASCI.OBRAZAC3.IDENTIFIKACIONI_BROJ_IZVESTAJA')}
        type="text"
        placeholder={''}
        inputName={'indetifikacioni_broj_izvestaja'}
        colon={true}
        rules={[REQUIRED_FIELD_RULE(true)]}
        labelCol={{ span: 12 }}
        wrapperCol={{ span: 12 }}
        readOnly={readOnly}
      />
      <FormSearchInfiniteScroll
        showSearch={true}
        formName={'naziv_strucne_laboratorije_id'}
        fetchOptinsList={obrazac3Store.fetchLaboratoriesAirWater}
        labelOptionAccessor="name_of_laboratory"
        valueOptionAccessor="id"
        inputName={'naziv_strucne_laboratorije_id'}
        label={t('OBRASCI.OBRAZAC3.NAZIV_LABORATORIJE')}
        labelCol={{ span: 12 }}
        wrapperCol={{ span: 12 }}
        rules={[REQUIRED_FIELD_RULE(true)]}
        defaultOption={defaultLaboratoriesAirWaterOption}
        placeholder={''}
        readOnly={readOnly}
      />

      {obrazac3Store.otpadne_vode_merenje_form.izmerene_vrednosti_otpadne_vode.length === 0 && (
        <h4 className={styles.error}>{t('OBRASCI.OBRAZAC3.BAR_JEDNO_MERENJE')}</h4>
      )}

      <InsideContentWrapper
        header={
          <WizardObrazacListHeader
            name={'obrazac3-analiza-otpadne-vode-izmerene-vrednosti-form'}
            modalName="obrazac3-analiza-otpadne-vode-izmerene-vrednosti-modal"
          />
        }
      >
        <Obrazac3AnalizaOtpadneVodeIzmereneVrednostiTable />
      </InsideContentWrapper>
    </Col>
  );
});
