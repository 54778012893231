/**
 * Array helper class
 */
export class ArrayHelper {
  /**
   * Method that sums the values from the keys (objectKey),
   * which are the keys in the object that is the item in the array
   * @param { Array<T> } array
   * @param { string } objectKey
   * @return number
   */
  static sumValuesFromObjectKeysInArray<T>(array: Array<T>, objectKey: string): number {
    return array.reduce((prevValue: number, item: T) => prevValue + (item[objectKey as keyof T] as number), 0);
  }
}
