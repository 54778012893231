import dayjs from 'dayjs';
import { IPostProductsNr, IGetProductsNr, IGetProductsType, IPostProductsType } from './productsNr.types';
import { t } from 'i18next';
import { FormInstance } from 'antd';
import { MAX_LENGTH_RULE, REQUIRED_FIELD_RULE } from 'modules/nriz/nriz.constants';
import {
  DateSifarniciFormProps,
  ISifarniciObjectProps,
  InputSifarniciFormProps,
  SelectSifarniciFormProps,
  SifarniciLists,
  TableSifarniciFormProps,
} from 'modules/sifarnici/sifarnici.types';
import { productNrService } from './productsNr.service';
import { ColumnsType } from 'antd/es/table';
import {
  NAME_FORM,
  DESCRIPTION_FORM,
  THE_ORDER_FORM,
  IS_ACTIVE_SWITCH,
} from 'modules/sifarnici/sifarniciFormObj.constants';
import {
  NAME_TABLE,
  DESCRIPTION_TABLE,
  THE_ORDER_TABLE,
  IS_ACTIVE_TABLE,
} from 'modules/sifarnici/sifarniciTableObj.constants';

import { FormListType } from 'typescript/NrizTypes';
export const PRODUCTS_TYPE_INITIAL_STATE: IPostProductsType = {
  name: '',
  description: '',
  unit: '',
  priceor_percentageof_pdv: '',
  currencyor: '',
  p_class: '',
  display_product: '',
  is_active: true,
};

export const CURRENCYOR_OPTIONS = [
  { value: 'RSD', label: 'RSD' },
  { value: '%', label: '%' },
];

export const UNIT_OPTIONS = [
  { value: 'KOM', label: 'KOM' },
  { value: 'KG', label: 'KG' },
  { value: 'T', label: 'T' },
  { value: 'L', label: 'L' },
];

export const P_CLASS_OPTIONS = [
  { value: 'Razred I', label: `${t('SIFARNICI.RAZRED')} I` },
  { value: 'Razred II', label: `${t('SIFARNICI.RAZRED')} II` },
  { value: 'Razred III', label: `${t('SIFARNICI.RAZRED')} III` },
  { value: 'Razred IV', label: `${t('SIFARNICI.RAZRED')} IV` },
  { value: 'Razred V', label: `${t('SIFARNICI.RAZRED')} V` },
  { value: 'Razred Va', label: `${t('SIFARNICI.RAZRED')} Va` },
  { value: 'Razred Vb', label: `${t('SIFARNICI.RAZRED')} Vb` },
  { value: 'Razred VI', label: `${t('SIFARNICI.RAZRED')} VI` },
  { value: 'Razred VII', label: `${t('SIFARNICI.RAZRED')} VII` },
  { value: 'Razred VIII', label: `${t('SIFARNICI.RAZRED')} VIII` },
  { value: 'Razred IX', label: `${t('SIFARNICI.RAZRED')} IX` },
  { value: 'Razred X', label: `${t('SIFARNICI.RAZRED')} X` },
];

export const PRODUCTS_TYPE_FORM_OPTIONS = (form: FormInstance<IGetProductsType>) => {
  return [
    IS_ACTIVE_SWITCH,
    {
      ...NAME_FORM,
      onInputChange: () => productNrService.makeProizvodiInput(form),
      rules: [REQUIRED_FIELD_RULE(true), MAX_LENGTH_RULE(200)],
      labelCol: { span: 8 },
    } as InputSifarniciFormProps,

    { ...DESCRIPTION_FORM, labelCol: 8, wrapperCol: 16, onChange: () => productNrService.makeProizvodiInput(form) },

    {
      type_of_form: 'select',
      name: 'p_class',
      label: t('SIFARNICI.RAZRED'),
      options: P_CLASS_OPTIONS,
      labelCol: { span: 8 },
      onChange: () => productNrService.makeProizvodiInput(form),
    } as SelectSifarniciFormProps,
    {
      type_of_form: 'select',
      name: 'unit',
      label: t('SIFARNICI.JEDINICA'),
      options: UNIT_OPTIONS,
      onChange: () => productNrService.makeProizvodiInput(form),
      labelCol: { span: 8 },
    } as SelectSifarniciFormProps,
    {
      type_of_form: 'input_number',
      name: 'priceor_percentageof_pdv',
      onInputChange: () => productNrService.makeProizvodiInput(form),
      label: t('SIFARNICI.CENA_ILI_PROCENAT_PDV'),
      inputName: 'priceor_percentageof_pdv',
      labelCol: { span: 8 },
    } as InputSifarniciFormProps,
    {
      type_of_form: 'select',
      name: 'currencyor',
      label: t('SIFARNICI.DINARA_ILI_PDV'),
      onChange: () => productNrService.makeProizvodiInput(form),
      options: CURRENCYOR_OPTIONS,
      hideInput: true,
      labelCol: { span: 8 },
    } as SelectSifarniciFormProps,
    {
      type_of_form: 'input',
      name: 'display_product',
      label: 'Proizvod/cena',
      inputName: 'display_product',
      disabled: true,
      hideInput: true,
      labelCol: { span: 8 },
    } as InputSifarniciFormProps,

    { ...THE_ORDER_FORM, labelCol: { span: 8 } },
  ];
};

const PRODUCTS_TYPE_TABLE_DATA: ColumnsType<IGetProductsType> = [
  IS_ACTIVE_TABLE as any,
  NAME_TABLE,
  DESCRIPTION_TABLE,
  {
    title: `${t('SIFARNICI.RAZRED')}`,
    width: '100px',
    dataIndex: 'p_class',
    key: 'p_class',
  },
  {
    title: `${t('SIFARNICI.JEDINICA')}`,
    width: '100px',
    dataIndex: 'unit',
    key: 'unit',
  },
  {
    title: `${t('SIFARNICI.CENA_ILI_PROCENAT_PDV')}`,
    width: '150px',
    dataIndex: 'priceor_percentageof_pdv',
    key: 'priceor_percentageof_pdv',
  },
  {
    title: `${t('SIFARNICI.DINARA_ILI_PDV')}`,
    width: '100px',
    dataIndex: 'currencyor',
    key: 'currencyor',
  },

  THE_ORDER_TABLE,
];

export const PRODUCTS_NR_INITIAL_STATE: IPostProductsNr = {
  period_from_date: '',
  period_to_date: '',
  products_category: '',
  display_product_category: '',
  is_active: true,
  products_type: [],
};

export const PRODUCTS_NR_TABLE_DATA = [
  {
    title: `${t('SIFARNICI.OD_DATUMA')}`,
    width: '100px',
    dataIndex: 'period_from_date',
    key: 'period_from_date',
    render: (_: any, record: IGetProductsNr) => (
      <span>{record.period_from_date ? dayjs(record.period_from_date).format('DD. MM. YYYY.') : ''}</span>
    ),
  },
  {
    title: `${t('SIFARNICI.DO_DATUMA')}`,
    width: '100px',
    dataIndex: 'period_to_date',
    key: 'period_to_date',
    render: (_: any, record: IGetProductsNr) => (
      <span>{record.period_to_date ? dayjs(record.period_to_date).format('DD. MM. YYYY.') : ''}</span>
    ),
  },
  {
    title: `${t('SIFARNICI.VRSTA_PROIZVODA')}`,
    width: '200px',
    dataIndex: 'products_category',
    key: 'products_category',
  },
  {
    title: `${t('SIFARNICI.VRSTA_PROIZVODA')}`,
    width: '300px',
    dataIndex: 'display_product_category',
    key: 'display_product_category',
  },
];

export const PRODUCTS_NR_FORM_OPTIONS = (form: FormInstance<IGetProductsNr>) => {
  const queryParams = new URLSearchParams(location.search);
  const sifarnik_type = queryParams.get('sifarnik_type');

  return [
    {
      type_of_form: 'title',
      title: 'Vrsta proizvoda',
    } as ISifarniciObjectProps,
    {
      type_of_form: 'date',
      name: 'period_from_date',
      label: t('SIFARNICI.OD_DATUMA'),
      format: 'DD-MM-YYYY',
      picker: 'date',
      onChange: () => {
        form.setFieldValue('period_to_date', '');
        form.setFieldValue('period_to_date__gte', '');
        productNrService.makeVrsteProizvodaInput(form);
      },
    } as DateSifarniciFormProps,
    {
      type_of_form: 'date',
      name: 'period_to_date',
      label: t('SIFARNICI.DO_DATUMA'),
      format: 'DD-MM-YYYY',
      picker: 'date',
      onChange: () => {
        productNrService.makeVrsteProizvodaInput(form);
      },
      disabled: !!(sifarnik_type && !form.getFieldValue('period_from_date')),
      disabledDate: (current: any) => {
        const formItem = form.getFieldValue('period_from_date__gte')
          ? form.getFieldValue('period_from_date__gte')
          : form.getFieldValue('period_from_date');
        return current && current.endOf('day') < formItem;
      },
    } as DateSifarniciFormProps,
    {
      type_of_form: 'input',
      label: t('SIFARNICI.VRSTA_PROIZVODA'),
      name: 'products_category',
      inputName: 'products_category',
      rules: [REQUIRED_FIELD_RULE(true), MAX_LENGTH_RULE(100)],
      onInputChange: () => {
        productNrService.makeVrsteProizvodaInput(form);
      },
    } as InputSifarniciFormProps,

    {
      type_of_form: 'input',
      name: 'display_product_category',
      hideInput: true,
      label: t('SIFARNICI.VRSTA_PROIZVODA'),
      inputName: 'display_product_category',
      disabled: true,
    } as InputSifarniciFormProps,
    {
      type_of_form: 'table',
      tableColumns: PRODUCTS_TYPE_TABLE_DATA,
      listName: 'products_type',
      configPath: { formListType: FormListType.Sifarnici, formListSubType: SifarniciLists.PRODUCTS_NR },
      title: <div style={{ textDecoration: 'underline' }}>Proizvodi</div>,
      formModalInitialValues: PRODUCTS_TYPE_INITIAL_STATE,
    } as TableSifarniciFormProps,
  ];
};
