import dayjs from 'dayjs';
import React from 'react';

type DateCellProps = {
  date: string | number | Date | dayjs.Dayjs | null | undefined;
  showTime?: boolean;
};

export const DateCell: React.FC<DateCellProps> = props => {
  if (props.date === undefined || props.date === null) {
    return <></>;
  }

  const template = props.showTime ? 'DD. MM. YYYY. HH:mm:ss' : 'DD. MM. YYYY.';

  return <>{dayjs(props.date).format(template)}</>;
};
