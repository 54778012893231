import { ColumnsType } from 'antd/es/table';
import { EditButtonProps, RowEditPreviewButton } from 'components/RowEditPreviewButton/RowEditPreviewButton';
import TableData from 'components/TableData/TableData';
import { observer } from 'mobx-react-lite';
import { obrasciStore } from 'modules/obrasci/obrasci.store';
import { obrazac5Store } from 'modules/obrasci/store/obrazac5/obrazac5.store';
import { IGetUpravljanjeObrazac5 } from 'modules/obrasci/store/obrazac5/obrazac5.types';
import React from 'react';
import { useTranslation } from 'react-i18next';

const EditObrazac5UpravljanjeButton: React.FC<EditButtonProps<IGetUpravljanjeObrazac5>> = observer(({ record }) => {
  return (
    <RowEditPreviewButton
      name="obrazac5-upravljanje"
      modalName="obrazac5-upravljanje-modal"
      onClick={() => obrazac5Store.handleChange('upravljanje_form', record)}
      preview={obrasciStore.zakljucanObrazac}
    />
  );
});

export const TableUpravljanjeObrazac5: React.FC = observer(() => {
  const { t } = useTranslation();

  const baseColumns: ColumnsType<IGetUpravljanjeObrazac5> = [
    {
      title: `${t('OBRASCI.OBRAZAC5.KOLICINA_NA_SKLADISTENJE')}`,
      width: '200px',
      dataIndex: 'kolicina_isporucenog_otpada_za_skladiste_otpada',
      key: 'kolicina_isporucenog_otpada_za_skladiste_otpada',
    },
    {
      title: `${t('OBRASCI.OBRAZAC5.KOLICINA_NA_ODLAGANJE')}`,
      width: '200px',
      dataIndex: 'kolicina_isporucenog_otpada_za_odlaganje',
      key: 'kolicina_isporucenog_otpada_za_odlaganje',
    },
    {
      title: `${t('OBRASCI.OBRAZAC5.KOLICINA_NA_PONOVNO_ISKORISCENJE')}`,
      dataIndex: 'kolicina_isporucenog_otpada_za_ponovno_iskoriscenje',
      key: 'kolicina_isporucenog_otpada_za_ponovno_iskoriscenje',
      width: '250px',
    },
    {
      title: `${t('OBRASCI.OBRAZAC5.KOLICINA_IZVEZENOG_OTPADA')}`,
      dataIndex: 'kolicina_izvezenog_otpada',
      key: 'kolicina_izvezenog_otpada',
      width: '200px',
    },

    {
      title: ``,
      key: 'operation',
      fixed: 'right',
      width: '50px',
      align: 'center',
      render: (_, record: IGetUpravljanjeObrazac5) => <EditObrazac5UpravljanjeButton record={record} />,
    },
  ];

  return (
    <TableData
      scrollConfig={['#obrazacTableHeader', '#obrazacWizardHeader']}
      name="upravljanje-obrazac5"
      columns={baseColumns}
      dataSource={[...obrazac5Store.initialState.upravljanje_otpadom]}
    />
  );
});
