import React from 'react';
import { InsideContentWrapper } from 'components/InsideContentWrapper/InsideContentWrapper';
import { WizardObrazacListHeader } from 'components/WizardObrazacListHeader/WizardObrazacListHeader';
import { observer } from 'mobx-react-lite';
import { ColumnsType } from 'antd/es/table';
import TableData from 'components/TableData/TableData';
import { useTranslation } from 'react-i18next';
import { gio5ObrazacStore } from 'modules/obrasci/store/gio5/gio5.store';
import { IGio5Country } from 'modules/obrasci/store/gio5/gio5.types';
import styles from './Gio5ZemljeTranzitaTable.module.scss';
import { EditButtonProps, RowEditPreviewButton } from 'components/RowEditPreviewButton/RowEditPreviewButton';
import { obrasciStore } from 'modules/obrasci/obrasci.store';

const EditGio5ZemljeTranzitaButton: React.FC<EditButtonProps<IGio5Country>> = observer(({ record }) => {
  return (
    <RowEditPreviewButton
      name="gio5-zemlje-tranzita"
      modalName="gio5-zemlje-tranzita-modal"
      onClick={() => gio5ObrazacStore.handleChange('zemljaTranzita', record)}
      preview={obrasciStore.zakljucanObrazac}
    />
  );
});

export const Gio5ZemljeTranzitaTable: React.FC = observer(() => {
  const { t } = useTranslation();
  t;

  const baseColumns: ColumnsType<IGio5Country> = [
    {
      title: 'Zemlja tranzita',
      width: '95%',
      dataIndex: ['zemlja_tranzita', 'name'],
      key: 'category_name',
    },
    {
      title: '',
      key: 'operation',
      fixed: 'right',
      align: 'center',
      width: '5%',
      render: (_, record: IGio5Country) => <EditGio5ZemljeTranzitaButton record={record} />,
    },
  ];

  return (
    <InsideContentWrapper
      header={
        <WizardObrazacListHeader
          name={'gio5-zemlja-tranzita-form'}
          modalName="gio5-zemlje-tranzita-modal"
          title={
            gio5ObrazacStore.getImaTranzita && gio5ObrazacStore.getUvozOtpada.zemlje_tranzita.length === 0
              ? t('OBRASCI.GIO5.VALIDATION.TRANSIT')
              : ''
          }
          classNameTitle={styles.header}
        />
      }
    >
      <TableData
        name="zemlje-tranzita-gio5"
        columns={baseColumns}
        dataSource={[...gio5ObrazacStore.uvozOtpada.zemlje_tranzita]}
      />
    </InsideContentWrapper>
  );
});
