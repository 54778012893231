import { observer } from 'mobx-react-lite';
import { OpsteGodinaPreduzecePostrojenje } from 'pages/ObrazacWizard/components/components/OpsteGodinaPreduzecePostrojenje/OpsteGodinaPreduzecePostrojenje';
import { Col, Row } from 'antd';
import styles from './PZVOpsteWizardTab.module.scss';
import { useEffect } from 'react';
import { obrasciStore } from 'modules/obrasci/obrasci.store';
import { pzv1ObrazacStore } from 'modules/obrasci/store/pzv1/pzv1.store';
import { FormCheckbox } from 'components/FormCheckbox/FormCheckbox';
import { Button } from 'components/Button/Button';
import useFormInstance from 'antd/es/form/hooks/useFormInstance';
import { useParams } from 'react-router-dom';
import { authStore } from 'store';
import { useObrazacExistsCheck } from 'hooks/useObrazacExistsCheck';

export const PZVOpsteWizardTab: React.FC = observer(() => {
  useEffect(() => {
    if (obrasciStore.opste.godina && obrasciStore.opste.preduzece_id !== '') {
      obrasciStore.setDisabledTabButton(false);
    } else obrasciStore.setDisabledTabButton(true);
  }, [obrasciStore.opste]);

  const form = useFormInstance();

  const { id } = useParams();

  const handleKorekcijeSubmit = () => {
    if (!id) return;
    const korekcije = form.getFieldsValue([
      'korekcija_deterdzenta',
      'korekcija_mineralnog_djubriva',
      'korekcija_zastite_bilja',
    ]);

    pzv1ObrazacStore.submitKorekcije(korekcije, id);
  };

  const checkIfObrazacExists = useObrazacExistsCheck();

  return (
    <Col span={12} className={styles.wrapper}>
      <OpsteGodinaPreduzecePostrojenje
        withPostrojenje={false}
        store={pzv1ObrazacStore}
        onChangeGodina={() => checkIfObrazacExists()}
      />
      {obrasciStore.zakljucanObrazac && authStore.isAdmin && (
        <Row>
          <Col span={9}>
            <Row>
              <FormCheckbox
                className={styles.korekcijaCheckbox}
                name="korekcija_deterdzenta"
                label="Korekcija deterdženta"
                labelCol={{ span: 23 }}
                disabled={!obrasciStore.zakljucanObrazac}
              />
            </Row>
            <Row>
              <FormCheckbox
                className={styles.korekcijaCheckbox}
                name="korekcija_mineralnog_djubriva"
                label="Korekcija đubriva"
                labelCol={{ span: 23 }}
                disabled={!obrasciStore.zakljucanObrazac}
              />
            </Row>
            <Row>
              <FormCheckbox
                className={styles.korekcijaCheckbox}
                name="korekcija_zastite_bilja"
                label="Korekcija zaštite bilja"
                labelCol={{ span: 23 }}
                disabled={!obrasciStore.zakljucanObrazac}
              />
            </Row>
          </Col>
          <Col className={styles.korekcijaBtnCol} span={15}>
            <Button
              name="dozvola_korekcije_btn"
              htmlType="button"
              onClick={handleKorekcijeSubmit}
              disabled={!obrasciStore.zakljucanObrazac}
            >
              Sačuvaj korekcije
            </Button>
          </Col>
        </Row>
      )}
    </Col>
  );
});
