import { Row } from 'antd';
import { Switch } from 'components/Switch/Switch';
import React from 'react';
import { useTranslation } from 'react-i18next';

interface IPtp2IzjavaOdgovornostiProps {
  izjava: boolean;
  setIzjava: React.Dispatch<React.SetStateAction<any>>;
}

export const Ptp2IzjavaOdgovornosti: React.FC<IPtp2IzjavaOdgovornostiProps> = props => {
  const { t } = useTranslation();
  return (
    <div style={{ maxWidth: '700px' }}>
      {t('IZJAVA_ODGOVORNOSTI.NACIN_I_OBAVEZE')}
      <br />
      {t('IZJAVA_ODGOVORNOSTI.DOSTAVLJENI_PODACI')}
      <br />
      <Row justify={'start'} style={{ marginTop: '10px' }}>
        <div style={{ marginRight: '20px' }}>{t('IZJAVA_ODGOVORNOSTI.PRIHVATANJE')}</div>
        <Switch
          name={'izjava-odgovornosti'}
          disabled={false}
          checked={props.izjava}
          onChange={(value: boolean) => props.setIzjava(value)}
        />
      </Row>
    </div>
  );
};
