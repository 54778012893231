import { FormSearchInfiniteScroll } from 'components/FormSearchInfiniteScroll/FormSearchInfiniteScroll';

import { observer } from 'mobx-react-lite';
import { REQUIRED_FIELD_RULE } from 'modules/nriz/nriz.constants';
import { obrasciStore } from 'modules/obrasci/obrasci.store';
import { gio5ObrazacStore } from 'modules/obrasci/store/gio5/gio5.store';
import { IGio5Country } from 'modules/obrasci/store/gio5/gio5.types';
import { ISifarnikInitial } from 'modules/sifarnici/sifarnici.types';
import React from 'react';
import { useTranslation } from 'react-i18next';

export const NewGio5ZemljeTranzitaForm: React.FC = observer(() => {
  const { t } = useTranslation();

  const defaultZemljaTranzitaOption: ISifarnikInitial | undefined = gio5ObrazacStore.getZemljaTranzita
    .zemlja_tranzita_id
    ? {
        value: (gio5ObrazacStore.getZemljaTranzita as IGio5Country).zemlja_tranzita_id,
        label: (gio5ObrazacStore.getZemljaTranzita as IGio5Country).zemlja_tranzita.name,
      }
    : undefined;

  const readOnly = obrasciStore.zakljucanObrazac;
  return (
    <>
      <FormSearchInfiniteScroll
        showSearch={true}
        formName={'zemlja_tranzita_id'}
        fetchOptinsList={gio5ObrazacStore.fetchCountryOptions}
        labelOptionAccessor="name"
        valueOptionAccessor="id"
        rules={[
          REQUIRED_FIELD_RULE(true),
          {
            validator(_, value) {
              if (gio5ObrazacStore.zemljaIzvoza === value)
                return Promise.reject(new Error(t('OBRASCI.GIO4.VALIDATION.TRANSIT_SAME')));
              if (
                gio5ObrazacStore.zemljaTranzita.zemlja_tranzita_id !== value &&
                gio5ObrazacStore.uvozOtpada.zemlje_tranzita.some(item => {
                  return value === item.zemlja_tranzita_id;
                })
              ) {
                return Promise.reject(new Error(t('OBRASCI.GIO4.VALIDATION.COUNTRY_EXISTS')));
              }
              return Promise.resolve();
            },
          },
        ]}
        inputName={'zemlja_tranzita_id'}
        label={'Zemlje tranzita'}
        colon={true}
        defaultOption={defaultZemljaTranzitaOption}
        placeholder={''}
        readOnly={readOnly}
      />
    </>
  );
});
