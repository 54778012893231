import { Col } from 'antd';

import { observer } from 'mobx-react-lite';

import styles from './Dep2OpsteWizardTab.module.scss';

import { useEffect } from 'react';
import { obrasciStore } from 'modules/obrasci/obrasci.store';
import { OpsteGodinaPreduzecePostrojenje } from 'pages/ObrazacWizard/components/components/OpsteGodinaPreduzecePostrojenje/OpsteGodinaPreduzecePostrojenje';
import { ICrudTypeModalProps } from 'components/Modals/CRUDModal/CRUDModal';
import { dep2ObrazacStore } from 'modules/obrasci/store/dep2/dep2.store';
import { useObrazacExistsCheck } from 'hooks/useObrazacExistsCheck';

export const Dep2OpsteWizardTab: React.FC<ICrudTypeModalProps> = observer(() => {
  useEffect(() => {
    if (obrasciStore.opste.godina && obrasciStore.opste.preduzece_id !== '') {
      obrasciStore.setDisabledTabButton(false);
    } else obrasciStore.setDisabledTabButton(true);
  }, [obrasciStore.opste]);

  const checkIfObrazacExists = useObrazacExistsCheck();

  return (
    <Col span={12} className={styles.dep2Opste}>
      <OpsteGodinaPreduzecePostrojenje
        withPostrojenje={false}
        store={dep2ObrazacStore}
        onChangeGodina={() => checkIfObrazacExists()}
      />
    </Col>
  );
});
