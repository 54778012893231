import { ColumnsType } from 'antd/es/table';
import { EditButtonProps, RowEditPreviewButton } from 'components/RowEditPreviewButton/RowEditPreviewButton';
import TableData from 'components/TableData/TableData';
import { observer } from 'mobx-react-lite';
import { obrasciStore } from 'modules/obrasci/obrasci.store';
import { gio2ObrazacStore } from 'modules/obrasci/store/gio2/gio2.store';
import { IGetDeponijeGio2 } from 'modules/obrasci/store/gio2/gio2.types';
import { useTranslation } from 'react-i18next';

const EditGio2DeponijeButton: React.FC<EditButtonProps<IGetDeponijeGio2>> = observer(({ record }) => {
  return (
    <RowEditPreviewButton
      name="gio2-deponije"
      modalName="gio2-deponije-modal"
      onClick={() => gio2ObrazacStore.handleChange('deponije_form', record)}
      preview={obrasciStore.zakljucanObrazac}
    />
  );
});

export const Gio2TableDeponije: React.FC = observer(() => {
  const { t } = useTranslation();

  const baseColumns: ColumnsType<IGetDeponijeGio2> = [
    {
      title: `${t('OBRASCI.GIO2.ADRESA_DEPONIJE')}`,
      width: '150px',
      dataIndex: 'adresa_deponije',
      key: 'adresa_deponije',
    },
    {
      title: `${t('OBRASCI.GIO2.MESTO_DEPONIJE')}`,
      width: '150px',
      dataIndex: ['mesto_deponije', 'display_name'],
      key: 'display_name',
    },
    {
      title: `${t('COMPANY.LATITUDE')}`,
      dataIndex: 'geografska_sirina',
      key: 'geografska_sirina',
      width: '150px',
    },
    {
      title: `${t('COMPANY.LONGITUDE')}`,
      dataIndex: 'geografska_duzina',
      key: 'geografska_duzina',
      width: '150px',
    },
    {
      title: `${t('OBRASCI.GIO2.VRSTA_DEPONIJE')}`,
      dataIndex: ['vrsta_deponije', 'name_sr'],
      key: 'name',
      width: '150px',
    },
    {
      title: `${t('COMPANY.ADDITIONALLY')}`,
      key: 'operation',
      fixed: 'right',
      width: '100px',
      align: 'center',
      render: (_, record: IGetDeponijeGio2) => <EditGio2DeponijeButton record={record} />,
    },
  ];

  return (
    <TableData
      scrollConfig={['#obrazacTableHeader', '#obrazacWizardHeader']}
      name="deponije-gio2"
      columns={baseColumns}
      dataSource={[...gio2ObrazacStore.initialState.deponije]}
    />
  );
});
