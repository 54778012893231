import { FormInput } from 'components/FormInput/FormInput';
import { ICrudTypeModalProps } from 'components/Modals/CRUDModal/CRUDModal';
import { observer } from 'mobx-react-lite';
import { useTranslation } from 'react-i18next';
import { OperatorsTable } from './OperatorsTable/OperatorsTable';
import { aao1Store } from 'modules/obrasci/store/aao1/aao1.store';
import styles from './Aao1Operateri.module.scss';
import { useEffect } from 'react';
import { IGetKolicinaAmbalaze, IOperateri } from 'modules/obrasci/store/aao1/aao1.types';
import Decimal from 'decimal.js';

export const Aao1Operateri: React.FC<ICrudTypeModalProps> = observer(({ form }) => {
  const { t } = useTranslation();

  const handleOperateriPrenetaKolicina = () => {
    const ukupnaPovratna = new Decimal(form.getFieldValue('ukupna_kolicina_povratne_ambalaze') ?? 0);
    const ukupnaNepovratna = Number(form.getFieldValue('ukupna_kolicina_nepovratne_ambalaze') ?? 0);
    const preneta = ukupnaPovratna.plus(ukupnaNepovratna).toNumber();

    form.setFieldValue('kolicina_preneta_na_operatera', preneta);
    aao1Store.handleChange('prenetaKolicina', preneta);
  };

  useEffect(() => {
    if (aao1Store.kolicinaAmbalaze.da_li_je_preneta_kolicina_ambalaze_na_operatera) handleOperateriPrenetaKolicina();
  }, []);

  useEffect(() => {
    form.validateFields(['kolicina_preneta_na_operatera']);
  }, [aao1Store.operater_form]);

  return (
    <>
      <FormInput
        label={t('OBRASCI.AAO1.PACKAGING_AMOUNT.OPERATOR_AMOUNT')}
        labelCol={{ span: 17 }}
        inputName={'kolicina_preneta_na_operatera'}
        disabled={true}
        rules={[
          {
            validator(_, value) {
              if (aao1Store.kolicinaAmbalaze.da_li_je_preneta_kolicina_ambalaze_na_operatera) {
                let sum = new Decimal(0);
                (aao1Store.kolicinaAmbalaze as IGetKolicinaAmbalaze).operateri.map(
                  (item: IOperateri) => (sum = sum.plus(item.kolicina ? item.kolicina : 0))
                );
                if (sum.toNumber() !== value) {
                  return Promise.reject(new Error(t('OBRASCI.VALIDATION.JEDNAKA_RAZRED_UKUPNA')));
                }
              }
              return Promise.resolve();
            },
          },
        ]}
        name={'kolicina_preneta_na_operatera'}
      />
      <OperatorsTable />
      {aao1Store.kolicinaAmbalaze.operateri.length === 0 && (
        <div className={styles.tableErrorDiv}>
          <p>{t('OBRASCI.AAO1.PACKAGING_AMOUNT.ERROR.OPERATOR_VALIDATION')}</p>
        </div>
      )}
    </>
  );
});
