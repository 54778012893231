import { obrazacIzvestajiTranslations } from './obrazacIzvestaji.srpski';
import { ITranslation } from './translation.types';
import { zakljucavanjeObrazacaTranslations } from './zakljucavanjeObrazaca/zakljucavanjeObrazaca.engleski';

const translations: ITranslation = {
  LOGIN_PAGE: {
    LOGIN: 'Login',
    SIGNUP: 'Sing Up',
  },

  IZJAVA_ODGOVORNOSTI: {
    TITLE: 'DISCLAIMER OF RESPONSIBILITY',
    NACIN_I_OBAVEZE:
      'The manner and obligations of submitting data on products that become special waste streams after use are prescribed by Article 140 - 147 of the Law on Fees for the Use of Public Goods and the Regulation on Products that After Use Become Special Waste Streams, the daily record form on the amount and type of manufactured and imported products and the annual report, the method and deadlines for submitting the annual report, the persons liable to pay the fee, the criteria for calculation, the amount and method of calculating and paying the fee. Control of data submission and control of the accuracy of data submission for the National Register of Pollution Sources is prescribed by Article 75a of the Law on Environmental Protection.',
    DOSTAVLJENI_PODACI:
      'The provided data on products that become special waste streams after use are considered accurate under the criminal and material liability of the responsible person in the company.',
    PRIHVATANJE: 'I accept the terms of the DISCLAIMER',
  },

  ISTORIJA_PROMENA: {
    TITLE: 'History of changes',
  },

  SIFARNICI: {
    IZVESTAJ_CARINE: 'Customs report / General',
    IZVESTAJ_CARINE_KATEGORIJE_PROIZVODA: 'Customs report / Product categories',
    PZV_PROIZVODI: 'Pzv / Products',
    CARINA_I_PROIZVODJAC: 'Customs and manufacturer / General',
    CARINA_I_PROIZVODJAC_PROIZVODI: 'Customs and manufacturer / Products',
    LABORATORJE_VAZDUH_VODA: 'Laboratories air - water ',
    LISTA_PROIZVODA_PTP2_VRSTA: 'Product list for ptp2 / Product type',
    LISTA_PROIZVODA_PTP2_PROIZVODI: 'Product list for ptp2 / Products',
    APR: 'Business registration / General',
    APR_ADRESNI_PODACI: 'Business registration / Address data',
    APR_ZASTUPNICI_OSNIVACI: 'Business registration / Representatives - Founders',
    PROIZVOD: 'Product',
    NETO_MASA: 'Net mass',
    OPIS: 'Description',
    OSLOBODJENI: 'Freed',
    NAZIV_STRUCNE_LABORATORIJE: 'Name of the specialized laboratory',
    AIR: 'Air',
    WATER: 'Water',
    LOCATION: 'Location',
    OD_GODINE: 'From year',
    DO_GODINE: 'To year',
    DETERDZENT: 'Detergent',
    DJUBRIVO: 'Fertilizer',
    SADRZAJ_FOSFATA_AZOTA: 'Phosphate or nitrogen or active substance content (%)',
    DODATNE_INFORMACIJE: 'Additional information',
    PROIZVOD_VAZI_ZA_IZVESTAJNE_GODINE: 'Product is valid for reporting years',
    OD: 'From',
    DO: 'To',
    OD_DATUMA: 'From date',
    DO_DATUMA: 'To date',
    VRSTA_PROIZVODA: 'Type of product',
    ZA_PERIOD: 'for period',
    NAZIV: 'Name',
    RAZRED: 'Class',
    JEDINICA: 'Unit',
    CENA_ILI_PROCENAT_PDV: 'Price or percentage of VAT',
    DINARA_ILI_PDV: 'RSD or % VAT',
    REDOSLED: 'Order',
    CARINA_PROIZVODJAC: 'Customs and manufacturer',
    IZVESTAJ_CARINE_KATEGORIJE: 'Product categories',
    UNESITE_NAZIV_PREDUZECA: 'Enter company name',
    UNESITE_LOKACIJU: 'Enter location',
    UNESITE_PRO_ID: 'Enter pro_id',
    ORTACKO_PREDUZECE: 'Entrepreneurial company is a partnership',
    BANKRUPCY_NUMBER: 'Registration number of the bankruptcy debtor',
    OPERATERI_ZA_AMBALAZNI_OTPAD: 'Operators for packaging waste',
    SAMOSTALNO_UPRAVLJANJE_OTPADOM: 'Independent management of packaging waste.',
    SIFRA_TIPA_METODE: 'Method type code',
    OZNAKA_METODE: 'Method designation',
    SREDNJI_KOD: 'Medium code',
    OSNOVA_METODE: 'Method basic code',
  },

  REGISTRI: {
    TITLE: 'Registry',
    DOZVOLE: {
      TITLE: 'Permits for waste management',
    },
    SELECT_PLACEHOLDER: 'Select a registry',
  },

  NA_SRPSKOM: 'in Serbian',
  NAZIV: 'Title',
  YES: 'Yes',
  NO: 'No',
  AND: 'i',
  APPROVE: 'Approve',
  SUBMIT: 'Submit',
  DELETE: 'Delete',
  CHANGE: 'Change',
  DISCARD: 'Discard',
  ENGLISH: 'English',
  SERBIAN: 'Serbian',
  NO_DATA: 'No data',
  BACK: 'Back',
  YEAR: 'Year',
  SELECT_YEAR: 'Select year',
  SELECT_DATE: 'Select date',
  NO_OPTIONS_FOUND: 'No options found',
  ADDRESS: 'Address',
  SUCCESS: 'Success',
  AT_LEAST_ONE: 'It is necessary to enter either PIB or personal identification number',
  KOORDINATE_TEXT: 'You can find the coordinates of the location at the address:',
  BROJ_MORA_BITI_IZMEDJU: 'The number must be between',
  BROJ_CIFARA_NE_MOZE_BITI_VECI_OD: 'The number of digits cannot be greater than',
  BROJ_DOKUMENTA: 'Document number',

  MONTHS: {
    JANUARY: 'January',
    FEBRUARY: 'February',
    MARCH: 'March',
    APRIL: 'April',
    MAY: 'May',
    JUNE: 'June',
    JULY: 'July',
    AUGUST: 'August',
    SEPTEMBER: 'September',
    OCTOBER: 'October',
    NOVEMBER: 'November',
    DECEMBER: 'December',
  },

  COMPANY: {
    TITLE: 'Company',
    COMPANIES: 'Companies',
    PIB: 'PIB',
    ENTERPRISE_ID_NUMBER: 'Enterprise id number',
    NAME: 'Company name',
    SHORT_NAME: 'Company short name',
    FULL_NAME: 'Company full name',
    STATUS: 'Company status',
    ACTIVE: 'Active',
    POST_NUMBER: 'Post number',
    LOCATION: 'Location',
    LOCATION_NAME: 'Location name',
    PRINCIPAL_BUSINESS_ACTIVITY_CODE: 'Principal business activity code',
    ADDRESS: 'Address',
    ADDRESS_NUMBER: 'Address Number',
    USER_NAME: 'Name authorized user',
    USER_SURNAME: 'Surname authorized user',
    PHONE: 'Phone number',
    EMAIL: 'Email',
    DESIRED_LANGUAGE: 'Desired language',
    LIST: 'Companies List',
    LATITUDE: 'Latitude',
    LONGITUDE: 'Longitude',
    NOTE: 'Note',
    GENERAL_INFORMATION: 'General informations',
    ADDITIONALLY: 'Additionally',
    DATE_FOUNDING: 'Date of establishment',
    LEGAL_FORM: 'Legal form',
    CONDITION_OF_WORK_END: 'Condition of termination',
    END_OF_WORK: 'End of company existance date',
    DATE_DELETED: 'Deletion date',
    ADDRESS_TYPE: 'Address type',
    FLOOR: 'Floor',
    APARTMENT: 'Apartment',
    LETTER: 'Leter',
    NAME_OF_POST_OFFICE: 'Name of the post office',
    TYPE_SHAPE_ORGANIZING: 'Type of Organizational Structure',
    SIZE_BUSINESS_ENTITY: 'Size of Business Entity',
    TEMPORARY_INTERUPTION_FROM: 'Temporary Interruption From',
    TEMPORARY_INTERUPTION_TO: 'Temporary Interruption To',
    DEBTOR_IN_POSSESSION: 'Registration Number of the Debtor-in-possession',
    DATA_SOURCE: 'Data Source',
    COMPANY_PARTNERSHIP: 'Entrepreneurial Company is a Partnership',
    ADDITIONAL_ADDRESS_INFO: 'Additional address info',
    BUSINESS_ENTITY_VALIDATION: 'You have to select the business entity type',

    BUSINESS_ENTITY_TYPES: {
      BUSINESS_COMPANY: 'Business company',
      ENTREPRENEUR: 'Entrepreneur',
      ASSOCIATION: 'Association',
      BANKRUPTCY_ESTATE: 'Bankruptcy estate',
      FOUNDATION_ENDOWMENT: 'Foundation/ endowment',
      SPORTS_ASSOCIATION: 'Sports association',
      CHAMBER: 'Chamber',
      HOUSING_COMMUNITY: 'Housing community',
      OTHER: 'Others',
    },

    APR_TITLES: {
      TITLE: 'APR',
      TYPE_BUSINESS_ENTITY: 'Type of business entity',
      BASIC_INFO: 'Basic information',
      SPECIFIC_DATA_TYPE_BUSINESS_ENTITY: 'Specific Data for the Type of Business Entity',
      SOURCE: 'Source',
      REPRESENTATIVE_FOUNDER: 'Representative/Founder - Natural Person',
      REPRESENTATIVE_LEGAL_ENTITY: 'Representative - Legal Entity',
      REPRESENTATIVE_ASSIGNEE: 'Representative of a legal entity that is an assignee',
      FUNCTION: 'Function',
      OTHER_INFO: 'Other information',
    },

    PLACEHOLDER: {
      ENTER_NAME: 'Enter company name',
      ENTER_SHORT_NAME: 'Enter company short name',
      ENTER_PIB: 'Enter company PIB',
      ENTER_IDENTIFICATION_NUMBER: 'Enter national ID',
      ENTER_POST_NUMBER: 'Enter post number',
      ENTER_CITY: 'Enter city',
      ENTER_ADDRESS: 'Enter address',
      ENTER_ADDRESS_NUMBER: 'Enter address number',
      ENTER_USER_NAME: 'Enter authorized user name',
      ENTER_USER_SURNAME: 'Enter authorized user surname',
      ENTER_PHONE: 'Enter phone number',
      ENTER_EMAIL: 'Enter email',
      ENTER_PRINCIPAL_BUSINESS_ACTIVITY_CODE: 'Enter principal business activity code',
      CHOOSE_FROM_LIST: 'Choose a company',
      ENTER_YEAR: 'Enter a year',
      ENTER_PHONE_NUMBER: 'Enter phone number',
    },
  },

  USER: {
    USERNAME: 'Username',
    TITLE: 'User',
    USERS: 'Users',
    LIST: 'Users list',
    NAME: 'Name',
    SURNAME: 'Surname',
    EMAIL: 'Email',
    LANGUAGE: 'Language',
    PHONE: 'Phone',
    PASSWORD: 'Password',
    DELETE: 'Delete user',
    ADD: 'Add user',
    ADD_BUTTON: 'Add',
    SET_RESPONSIBLE: 'Change responsible person',
    ADD_TO_FACILITY: 'Add user to facility',
    JMBG: 'Public identification number',
    NUMBER_OF_PASSPORT: 'Number of passport/ Refugee documentation number',
    COUNTRY: 'Country',
    TYPE_ASSIGNEE: 'Type of Assignee',
    TYPE_LEGAL_ENTITY: 'Type of Legal Entity',
    TYPE_PERSON: 'Type of Entity',
    MASTER_NUMBER_ASSIGNEE: 'Assignee Master Number',
    ASSIGNEE_NAME: 'Assignee Name',
    TYPE_REPRESENTATIVE: 'Type of Representative',
    REPRESENTATIVE_NAME: 'Representative Name',
    REPRESENTATIVE_SURNAME: 'Representative Surname',
    REPRESENTATIVE_JMBG: 'Representative Public Identification Number',
    REPRESENTATIVE_PASSPORT: 'Representative Passport Number/ Refugee documentation number',
    REPRESENTATIVE_COUNTRY: 'Representative Country',
    ASSIGNEE_FUNCTION: 'Assignee Functions',
    ASSIGNEE_FUNCTION_OTHER: 'Other Assignee Function',
    UNIQUE_IDENTIFIER: 'Unique Identifier of the Entity',
    REPRESENTS_INDEPENDANTLY: 'Represents independantly',
    HAS_SIGNING_RESTRICTIONS: 'Has signing restrictions with co-signature',
    IME_ILI_PREZIME: 'First name or last name',

    ALERTS: {
      INFO: 'Please wait for admin approval. You will receive an e-mail of confirmation',
      WARNING: 'An unexpected error has occured. Pleace contact your admin by calling : 35246576879',
      REGISTRATION_SUCCESS:
        "Registration successful. Setup your password by clicking on the link we sent to your company's e-mail.",
      DELETE: 'Are you sure you want to delete this user?',
      DELETE_SUCCESS: 'User successfully deleted!',
      RESPONSIBLE: 'Are you sure you want to change the responsible person?',
      RESPONSIBLE_SUCCESS: 'Responsible person successfully changed!',
    },
  },

  POLLUTION: {
    TITLE: 'Source of pollution',
    AIR: {
      TITLE: 'Source of pollution / Air',
      LIST: 'Air list',
      SOURCE_NUMBER: 'Source number',
      SOURCE_NAME: 'Source name',
      ACTIVE: 'Aktive',
      SOURCE_TYPE: 'Type of source',
      ELEVATION: 'Elevation (mnv)',
      INTALLED_HEAT_POWER_AT_ENTRANCE: 'Installed heat power at the entrance (MWth)²',
      SOURCE_HEIGHT: 'Source height (m)',
      INNER_DIAMETER_OF_SOURCE_ON_TOP: 'Inner diameter of the source at the top (m)',
      OBLIGATION_TO_MEASURE: 'Obligation to measure:',
      COORDINATE_CONCLUSION: 'Coordinate conclusion',
    },
    SOIL: {
      TITLE: 'Source of pollution / Soil',
      LIST: 'Soil list',
      LOCATION_NUMBER: 'Location number',
      LOCATION_NAME: 'Location name',
    },
    WATER: {
      TITLE: 'Source of pollution / Water',
      LIST: 'Water list',
      GENERAL_WATER: 'Water - general',
      SEWER_NUMBER: 'Sewer number ',
      SEWER_NAME: 'Sewer name',
      PROJECTED_CAPACITY_OF_SEWER: 'Projected capacity of sewer (l/s)',
      TYPE_OF_RECIPIENT: 'Recipient type',
      NAME_RECIPIENT: 'Recipient name',
      BASIN: 'Basin',
      TYPE_OF_DISCHARGED_WASTEWATER: ' Type of discharged wastewater',
      SANITARY: 'Sanitary',
      TECHNOLOGICAL: 'Technological',
      COOLING: 'Cooling',
      ATMOSPHERIC: 'Atmospheric',
      FACILITY_FOR_WASTEWATER_TREATMENT: 'Facility for wastewater treatment',
      IS_THERE_A_TREATMENT_FACILITY_WASTEWATER: 'Is there a treatment facility for waste water',
      DEVICES_IN_THE_INSTALLATION_OF_WASTEWATER_TREATMENT_FACILITY:
        ' Devices in the installation of wastewater treatment facility (PPOV)',

      MECHANICAL_TREATMENT: 'Mechanical treatment',
      GRID: 'Grid',
      SIEVE_MECHANICAL_FILTER: 'Sieve , mechanical filter',
      SAND_CATCHER: 'Sand catcher',
      AERATED_SAND_CATCHER: 'Aerated sand catcher',
      PRECIPITATOR_LONGITUDINAL: 'Precipitator - longitudinal',
      PRECIPITATOR_LAMINAR: 'Precipitator - laminar',
      PRECIPITATOR_RADIAL: 'Precipitator - radial',
      GREASE_AND_OIL_SEPARATOR: 'Grease and oil separator',
      FLOTATOR: 'Flotator',
      SAND_FILTER: 'Sand filter',

      CHEMICAL_TREATMENT: 'Chemical treatment',
      NEUTRALISATION_DEVICES: 'Neutralisation devices',
      DETOXIFICATION_DEVICES: 'Detoxification devices',
      ION_EXCHANGE: 'Ion exchange',
      CHLORINATION: 'Chlorination',
      OZONATION: 'Ozonation',

      BIOLOGICAL_TREATMENT: 'Biological treatment',
      LAGOON: 'Lagoon',
      AERATION_POOL: 'Aeration pool',
      BIO_FILTER: 'Bio-filter',
      BIO_DISC: 'Bio-disc',
      NITRIFICATION: 'Nitrification',
      DENITRIFICATION: 'Denitrification',
      SLUDGE_FERMENTATION: 'Sludge fermentation',

      DEVICES_FOR_CHANGING_THE_HEAT: 'Devices for changing the heat',
      NATURAL_HEAT_INTERCHANGE_POOL_LAGOON: 'Natural heat interchange - pool, lagoon',
      COOLING_TOWER_NATURAL_AIR_CIRCULATION: 'Cooling tower - natural air circulation',
      COOLING_TOWER_FORCED_AIR_CIRCULATION: 'Cooling tower - forced air circulation',
      CLOSED_COOLING_DEVICES: 'Closed colling devices',
      WATER_BODY: 'Water body',
    },
  },

  SURVEY: {
    TITLE: 'Survey',
    STEP_1_QUESTION_1: 'Does your company generate waste in its business process?',
    STEP_1_QUESTION_2:
      'Does your company accept waste from natural persons or other legal entities or an entrepreneur?',
    STEP_1_QUESTION_3: 'Does your company have a waste disposal permit?',
    STEP_1_QUESTION_4: 'Does your company have a waste recycling permit?',
    STEP_1_QUESTION_5: 'Does your company have a waste export permit?',
    STEP_1_QUESTION_6: 'Does your company have a permit to import waste?',
    STEP_2_QUESTION_1: 'Does your company manufacture or import products from the following categories:',
    CATEGORIES_1: ' electrical and electronic products , accumulators and batteries , oil , tires , vehicles  , bags',

    STEP_2_QUESTION_2: 'Does your company manufacture or import products from the following categories: ',
    CATEGORIES_2: 'mineral fertilizers , detergents , means for plant protection',
    STEP_3_QUESTION_1:
      'Is your company a manufacturer, importer, packer/filler, the supplier of the packaging or packaged product and/or the company that manages packaging waste independently?',
    STEP_4_QUESTION_1: 'Does your company emit pollutants into the air and/or water and/or soil?',
  },

  FACILITY: {
    TITLE: 'Facility',
    LIST: 'Facility list',
    NAME: 'Facility name',
    VERIFICATION_CODE: 'Verification code',
    PREVIOUS_NATIONAL_ID: 'Previous national ID',
    HISTORICAL_ID: 'Historical ID',
    NACIONAL_ID: 'Nacional ID',
    PLACEHOLDER: {
      ENTER_NAME: 'Enter facility name',
      ENTER_LATITUDE: 'Enter latitude',
      ENTER_LONGITUDE: 'Enter longitude',
      ENTER_VERIFICATION_CODE: 'Enter verification code from company email',
      ENTER_PREVIOUS_NATIONAL_ID: 'Enter previous national id',
      ENTER_HISTORICAL_ID: 'Enter historical id',
      ENTER_NATIONAL_ID: 'Enter national id',
      ENTER_PHONE_NUMBER: 'Enter phone number',
      CHOOSE_FROM_LIST: 'Select a facility',
      CHOOSE_POLLUTION_SOIL: 'Select the source of soil pollution',
    },
  },

  ACTIVITIES: {
    TITLE: 'List of activities',
    SUBTITLE: 'Activity list',
    SUBTITLE1: 'Activity list 1',
    SUBTITLE2: 'Activity list 2',
    NEW_ACTIVITY_TITLE: 'New activity',
    ECONOMIC_ACTIVITY: 'Economic activity',
    MAIN: 'Main activity',
    ACTIVITY: 'Activity',
    ECONOMIC_ACTIVITY_PLACEHOLDER: 'Search economic activities',
    ACTIVITY_PLACEHOLDER: 'Search activities',
    ECONOMIC_ACTIVITY_VALIDATION: 'Choose an economic activity',
    ACTIVITY_VALIDATION: 'Izaberite an activity',
    MODAL_INFO:
      "While choosing an activity, pay attention if the chosen activity meets the requirements of the minimum limit value of the facility's capacity.",
    ALREADY_EXISTS: 'Activity is already in the list',
    NO_ECONOMIC_ACTIVITY_CHOSEN: 'You need to chose an economic activity first',
  },

  UNSANITARY_LANDFILL: {
    TITLE: 'Unsanitary landfill',
    LIST: 'Unsanitary landfill list',
    MARK_OF_UNSANITARY_LANDFILL: 'Mark',
    NAME: 'Name',
    OCCUPIED_PLOTS: 'Occupied plots',
    LANDFILL_PERIOD: 'Landfill period',
  },

  VALIDATION: {
    IS_REQUIRED: 'Required field!',
    IS_NOT_VALID_EMAIL: 'Email is not a valid!',
    IS_NOT_VALID_NUMBER: 'Is not a valid number!',
    IS_NOT_VALID_PIB: 'Is not valid PIB',
    IS_NOT_VALID_PAK: 'Is not valid PAK',
    IS_NOT_VALID_IDENTIFICATION_NUMBER: 'Is not valid identification number',
    IS_NOT_VALID_PASSPORT: 'Is not valid passport number',
    IS_NOT_VALID_YEAR: 'Is not a valid year',
    IS_NOT_VALID_VERIFICATION_CODE: 'Is not valid verification code',
    IS_TOO_LONG: 'Input too long',
    BETWEEN: 'must be between',
    SELECT_A_FIELD: 'select a field',
    IS_MORE_THAN_0: 'must be greater than 0',
    IS_MORE_THAN_OR_EQUALS_0: 'must be greater than or equal to 0',
    ALREADY_EXISTS: 'already exists in list',
    SADRZAJ_PROCENTI: 'Content percentage must be a value between 0 and 100 and cannot be less than 0',
  },
  POPCONFIRM: {
    APROVECOMPANY: 'Do you want to approve this company?',
    REJECT_COMPANY: 'Do you want to reject this company?',
  },

  OBRASCI: {
    TIPOVI_OBRAZACA: 'Form types',
    OBRASCI: 'Forms',
    DEP_2: {
      TITLE: 'Wild dump',
      SETTLEMENT: 'Settlement',
      ESTIMATED_AMOUNT_OF_WASTE: 'Estimated amount of waste(t)',
      ESTIMATED_OF_THE_AREA_OF_LANDFILLS: 'Estimated of the area of landfills (m²)',
      HOW_MANY_TIMES_CLEANED: 'How many times was the area of ​​the wild dump cleaned during the year',
      IS_THERE_REPEATED_DUMPING: 'Is there repeated illegal dumping of waste in the same place?',
      VALIDATION: {
        DODAJ_DEPONIJU: 'At least one illegal dumpsite must be added to preserve the form.',
      },
    },
    PZV_1: {
      TITLE: 'PZV1 Form',
      QUANTITY: 'Quantity',
      DETERGENTS: {
        TITLE: 'Detergents',
        TYPE: 'Detergent type',
        QUANTITY: 'The amount of detergent placed on the market (kg)',
        NAME: 'Detergent name',
        CONTENT: 'Phosphate content in detergent (%)',
        PLACEHOLDERS: {
          TYPE: 'Select detergent type',
          NAME: 'Enter detergent name',
          QUANTITY: 'Enter amount spent',
          CONTENT: 'Enter phosphate content',
        },
        ERROR: {
          TYPE: 'This type of detergent is already in the list',
          NAME: 'Detergent under this name is already in the list',
        },
      },
      FERTILIZERS: {
        TITLE: 'Mineral Fertilizers',
        TYPE: 'Name mineral fertilizer',
        QUANTITY: 'The amount of mineral fertilizer placed on the market (kg)',
        CONTENT: 'Nitrogen content in mineral fertilizer (%)',
        PLACEHOLDERS: {
          TYPE: 'Select mineral fertilizer type',
          QUANTITY: 'Enter amount spent',
          CONTENT: 'Enter nitrogen content',
        },
        ERROR: {
          TYPE: 'This type of fertilizer is already in the list',
        },
      },
      PLANT_PROTECTION: {
        TITLE: 'Plant protection',
        TYPE: 'The name of the chemical plant protection product',
        QUANTITY: 'The amount of chemical plant protection product placed on the market (kg)',
        CONTENT: 'The content of the active substance in the plant protection product (%)',
        PLACEHOLDERS: {
          TYPE: 'Select plant protection product',
          QUANTITY: 'Enter amount spent',
          CONTENT: 'Enter active substance content',
        },
        ERROR: {
          TYPE: 'This type of plant protection product is already in the list',
        },
      },
      VREDNOST_VECA_OD_0: 'Value must be greater than 0',
      NOT_FOUND: 'No products for selected year',
    },
    OBRAZAC_4: {
      TITLE: 'Obrazac 4: Emissions into the soil',
      ADDITIONAL_INFO: {
        TITLE: 'Location and disposal operation data',
        WASTE_TYPE: 'Type of disposed waste',
        WASTE_INDEX: 'Index number of disposed waste',
        AMOUNT_YEAR: 'Annual amount of disposed waste (t/year)',
        AMOUNT_TOTAL: 'Total amount of disposed waste (t)',
        OPERATION: 'Waste disposal operation',
      },
      SOIL_EMISSION: {
        TITLE: 'Polluting supstance',
        WASTE_NAME: 'Name of polluting supstance',
        CONCENTRATION: 'Concentration of pollutant in waste (kg/mg s.m.)',
        QUANTITY: 'Amount of pollutant in disposed waste (kg/year)',
        WAY: 'Way of disposal',
        METHOD: 'Method of disposal',
      },
      VALIDATION: {
        DODATI_EMISIJU: 'Add emission',
      },
    },
    KOM_1: {
      TITLE: '',
      QUANTITY: 'Quantity',
      QUANTITY_T: 'Quantity (t)',
      MUNICIPALITY: 'Municipality',
      QUANTITY_FRACTIONS: 'Quantity fractions (t)',
      COMPOSITION_OF_MUNICIPAL_WASTE: 'Composition of municipal waste',
      COMPOSITION: 'Composition',
      TYPE_OF_WASTE: 'Type of waste',
      SPRING_ANALYSIS: 'Spring analysis (t/per week)',
      AUTUMN_ANALYSIS: 'Autumn analysis (t/per week)',
      SUMMER_ANALYSIS: 'Summer analysis (t/per week)',
      WINTER_ANALYSIS: 'Winter analysis (t/per week)',
      MIDDLE_VALUE: 'Middle value (t/per week)',
      TOTAL_NUMBER_OF_HOUSEHOLDS_IN_SETTLEMENT: 'Total number of households in settlement',
      NUMBER_OF_HOUSEHOLDS_COVERED_BY_WASTE_COLLECTION: 'Number of households covered by waste collection',
      KOLICINE_SAKUPLJENOG_OTPADA: 'Quantities of collected waste during seasonal analyses:',

      EQUIPMENT_AND_MECHANISATION: {
        TITLE: '',

        WASTE_COLLECTION_EQUIPMENT: 'Waste collection equipment',
        NUMBER: 'Number',
        UTILITY_CONTAINER_OF_5_M3: 'Utility container of 5 m3',
        UTILITY_CONTAINER_OF_7_M3: 'Utility container of 7 m3',
        UTILITY_CONTAINER_OF_10_M3: 'Utility container of 10 m3',
        PRESS_OR_ROLL_CONTAINERS: 'Press or roll containers',
        CONTAINERS_OF_1_1_M3: 'Containers of 1,1 m3',
        LARGE_CONTAINERS: 'Large containers',
        OTHER_TYPES_OF_CONTAINERS: 'Other types of containers',
        BUCKETS: 'Buckets',
        CONTAINERS_FOR_SEPARATE_COLLECTION_OF_SECONDARY_RAWARE:
          'Containers for separate collection of secondary raware',
        BAGS: 'Bags',

        WASTE_TRANSPORT_EQUIPMENT: 'Waste transport equipment',
        VEHICLE_NUMBER: 'Vehicle number',
        GARBAGE_TRUCK: 'Garbage truck',
        ROTO_PRESS: 'Roto press',
        CAR_LIFTER: 'Car lifter',
        TRUCK: 'Truck',
        DUMP_TRACK: 'Dump track',
        TRACTOR_WITH_TRAILER: 'Tractor with trailer',
        CARTED_VEHICLE: 'Carted vehicle',

        NUMBER_OF_MACHINES: 'Number of machines',

        EQUIPMENT_ON_LANDFILL: 'Equipment of landfill',
        CRAWLER_TRACTOR: 'Crawler tractor',
        COMPACTOR: 'Compactor',

        OTHER_EQUIPMENT: 'Other equipment',
        DRINKING_WATER_TANKS: 'Drinking water tanks',
        STREET_CLEANING_TANKS: 'Street cleaning tanks',
        CONTAINER_WASHING_VEHICLES: 'Container washing vehicles',
        VEHICLES_FOR_CLEANING_SEPTIC_TANKS: 'Vehicles for cleaning septic tanks',
      },

      MANAGEMENT: {
        TITLE: 'Managment',
        MUNICIPAL_WASTE_MANAGEMENT: 'Municipal waste managment',
        WASTE_INDEX_NUMBER_FROM_THE_WASTE_CATALOG: 'Waste index number from the waste catalog',
        THE_TOTAL_AMOUNT_OF_COLLECTED_OR_ACCEPTED_WASTE_IN_REPORTING_YEAR:
          'The total amount of collected or accepted waste in reporting year (t)',
        WAY_OF_DETERMINING_THE_QUANTITY: 'Way of determining the quantity',
        THE_AMOUNT_OF_WASTE_COLLECTED_FROM_HOUSEHOLDS: 'The amount of waste collected from households',

        THE_AMOUNT_OF_COLLECTED_WASTE_FROM_PRIMARY_RECICLING: 'The amount of collected waste from primary recicling',
        FROM_THE_PRIMARY_SELECTION: 'From the primary selection',
        FROM_RECYCLING_YARDS: 'From recycling yards',
        OTHER_COLLECTION_POINTS: 'Other collection points',

        HANDED_OVER: 'Handed over',
        WAY_OF_HAND_OVER: 'Way of hand over',
        D_OR_R_MARK: 'D or R mark:',
        NAME_AND_ADDRESS_OF_THE_OPERATOR: 'Name and address of the operator',
        OPERATOR_LICENSE_NUMBER: 'Operator license number',
      },
      VALIDATION: {
        DODATI_NASELJE: 'Add settlement',
        DODATI_SASTAV: 'Add composition',
        DODATI_KOLICINU: 'Add quantity',
        ISTO_NASELJE: 'The same settlement cannot be repeated at the form level',
        BAR_JEDNO_POLJE: 'Fill in at least one field.',
      },
    },
    OBRAZAC_1: {
      TITLE: 'Form 1',
      FUEL_NAME: 'Fuel name',

      CONSUMPTION_PER_DAY: 'Consumption per day',
      METHOD_OF_STORAGE: 'Method of storage',
      MAXIMUM_STORAGE_CAPACITY: 'Maximum storage capacity',
      AVERAGE_AMOUNT_OF_STORAGE: 'Average amount of storage',

      DATA_ON_THE_RESUME_OF_WORK_IN_FACILITY: 'Data on the resume of work in facility',
      WORK_SCHEDULE: 'Work schedule',
      START_OF_SEASON: 'Start of season (month)',
      END_OF_SEASON: 'End of season (month)',
      NUMBERS_OF_SHIFTS_PER_DAY: 'Number of shifts per day',
      NUMBER_OF_WORKING_DAYS_PER_WEEK: 'Number of working days - per week',
      NUMBER_OF_WORKING_DAYS_PER_YEAR: 'Number of working days - per year',
      NUMBER_OF_WORKING_DAYS_PER_SEASON: 'Number of working days - per season',
      NUMBER_OF_OPERATING_H0URS: 'Number of operating hours',
      DATA_ON_EMPLOYEES_IN_THE_FACILITY: 'Data on employees in the plant',
      NUMBER_OF_PERMANENT_EMPLOYEES: 'Number of permanent employees',
      NUMBER_OF_TEMPORARY_EMPLOYEES: 'Number of temporary employees',
      NUMBER_OF_EMPLOYEES_IN_FIRST_SHIFT: 'Number of employees in the first shift',
      NUMBER_OF_EMPLOYEES_IN_SECOND_SHIFT: 'Number of employees in the second shift',
      NUMBER_OF_EMPLOYEES_IN_THIRD_SHIFT: 'Number of employees in the third shift',

      RECAPITULATION_OF_WASTE_PRODUCED_IN_FACILITY: 'Recapitulation of waste produced in the plant',
      TOTAL_NUMBER_OF_TYPES_OF_WASTE: 'Total number of types of waste',
      RAW_MATERIALS: 'Raw materials in the plant',
      PRODUCT_KEY: 'Product key',
      PRODUCT_NAME: 'Product name',
      DESCRIPTION: 'description',
      UNIT_OF_MEASURE: 'Unit of measure',
      ANNUAL_PRODUCTION: 'Annual production',
      INSTALLED_CAPACITIES: 'Installed capacities',
      AVERAGE_ENGAGED_CAPACITIES: 'Average engaged capacities',

      CHEMICAL_NAME_IUPAC: 'Chemical name (according to IUPAC)',
      COMMERCIAL_NAME: 'Commercial name',
      AGGREGATE_CONDITION_WHEN_IN_STORAGE: 'Aggregate condition when in storage',

      KONTINUALAN: 'Continuous',
      SEMIKONTINUALAN: 'Semi-continuous',
      SEZONSKI: 'Seasonal',

      GORIVO_VEC_POSTOJI: 'Fuel already exists in the list.',
    },
    CHOSE_GIO_CHOSE_DEO: 'DEO6/GIO6 form should be selected',
    PDV: 'PDV',

    AAO1: {
      TITLE: 'packaging waste',
      ZBIR_PONOVNO_ISKORISCENJE_PORUKA:
        'The sum of the taken quantities must be greater than 0 and cannot be less than the sum of the Total quantity of reused waste and the Total quantity of disposed waste.',
      ZBIR_VRSTA_AMBALAZE_PORUKA:
        'The sum of the taken quantities cannot be greater than the total quantity for the corresponding type of packaging in the Quantity of packaging table.',
      CHECKBOX: {
        TITLE: 'Legal entity or entrepreneur (check)',
        PACKAGING_MANUFACTURER: 'Packaging manufacturer',
        PACKER_OF_PRODUCTS: 'Packer of products in packaging or filler of packaging',
        PACKAGING_SUPPLIER: 'A supplier who places packaging or a packaged product on the market',
        IMPORTER_OF_PACKAGING: 'Importer of packaging',
        EXPORTER_OF_PACKAGING: 'Exportet of packaging',
        END_USER: 'End user',
      },
      PACKAGING_AMOUNT: {
        TITLE: 'Packaging amount',
        PACKAGING_TYPE: 'Type of packaging',
        NON_RETURNABLE_PACKAGING: 'Non returnable packaging',
        NON_RETURNABLE_PACKAGING_AMOUNT: 'Amount of non returnable packaging (t)',
        RETURN_PACKAGING: 'Return packaging',
        RETURN_PACKAGING_AMOUNT: 'Amount of return packaging (t)',
        MANUFACTURED: 'Manufactured',
        MANUFACTURED_AMOUNT: 'Manufactured amount',
        IMPORTED: 'Imported',
        IMPORTED_AMOUNT: 'Imported amount',
        EXPORTED: 'Exported',
        EXPORTED_AMOUNT: 'Exported amount',
        TOTAL: 'Total',
        TOTAL_AMOUNT: 'Total amount',
        OPERATOR_AMOUNT: 'Amount transferred to the operator',
        OPERATOR: 'Operator',
        OPERATORS: 'Operators',
        IF_OPERATOR:
          'Select if in this year you transferred your packaging management responsibilities to the operator for this type of packaging',
        ADD_OPERATOR: 'Add operator',
        PERMIT_NUMBER: 'Permit number',
        ERROR: {
          OPERATOR_AMOUNT: 'Amount transferred cannot be bigger than the total waste amount',
          OPERATOR_VALIDATION: 'You have to select at least one operator',
          LESS_THAN_0: 'Total amount cannot be less than 0',
          TYPE_OF_PACKAGING_EXISTS: 'This type of packaging is already in the list',
          OPERATOR_EXISTS: 'This operator is already in the list',
          OPERATOR_AMOUNT_SUM:
            'The sum of the amounts in the table cannot be greater than the total amount transferred to the operators',
        },
        PLACEHOLDERS: {
          PACKAGING_TYPE: 'Select packaging type',
        },
      },
      MANAGEMENT_OF_PACKAGING_WASTE: {
        TITLE: 'Management of packaging waste',
        PERMIT_NUMBER: 'Permit number',
        DATE_OF_ISSUE: 'Date of issue',
        PACKAGING_TYPE: 'Packaging type',
        SELF_MANAGEMENT_AMOUNT: 'Self management amount (t)',
        AMOUNT_TAKEN: 'Amount taken (t)',
        RECYCLED_PACKAGING_WASTE: 'Recycled packaging waste',
        R1_OPERATION_AMOUNT: 'R1 operation amount (t)',
        SECOND_R_OPERATION: 'Second R operation',
        R2_OPERATION_AMOUNT: 'Second R operation amount (t)',
        R3_OPERATION_AMOUNT: 'R3 operation amount (t)',
        TOTAL_RECYCLED: 'Total amount of recycled waste (t)',
        DISCARDED_PACKAGING_WASTE: 'Discarded packaging waste',
        D1_OPERATION_AMOUNT: 'D1 operation amount (t)',
        SECOND_D_OPERATION: 'Second D operation',
        D2_OPERATION_AMOUNT: 'Second D operation amount (t)',
        TOTAL_DISCARDED: 'Total amount of discarded waste (t)',
        PLACEHOLDERS: {
          PACKAGING_TYPE: 'Select packaging type',
          PERMIT_NUMBER: 'Enter permit number',
          DATE_OF_ISSUE: 'Enter the date of issuance of the license',
        },
      },
    },

    AAO2: {
      TITLE: 'Packaging waste',
      PACKAGING_AMOUNT: {
        TITLE: 'Packaging amount (t)',
        MANUFACTURED_AMOUNT: 'Manufactured amount (t)',
        IMPORTED_AMOUNT: 'Imported amount (t)',
        EXPORTED_AMOUNT: 'Exported amount (t)',
        TOTAL_AMOUNT: 'Total amount (t)',
      },
      MANAGEMENT_OF_PACKAGING_WASTE: {
        MUNICIPAL_WASTE: 'Collected amount of municipal waste',
        NON_MUNICIPAL_WASTE: 'Collected amount of non municipal waste',
      },
      PRAVNO_LICE_TITLE: 'Data on the legal entity or entrepreneur who transferred the obligation to the operator',
      VALIDATION: {
        DODATI_STAVKU: 'You must add at least one item in the Packaging Quantity tab',
        DODATI_STAVKU_PRAVNO_LICE: 'You must add at least one item in the Legal Entity or Entrepreneur tab',
        UNESI_ODGOVARAJUCU_KOLICINU:
          'For each type of packaging in the Packaging Quantity tab, appropriate quantities must be entered in the Waste Packaging Management tab',
      },
    },
    PLACEHOLDER: { CHOOSE_TIP_OBRASCA: 'Select form type' },

    PTP2: {
      KOLICINA_PROIZVODA_STAVLJENIH_NA_TRZISTE: 'Quantity of products put on the market',
      KOLICINA_PROIZVODA_KG: 'Quantity of products (kg)',
      VRSTE_PROIZVODA_PO_PERIODIMA: 'Types of product by period',
      NAZIV_PROIZVODA: 'Product name',
      DODAJ_PROIZVOD: 'Add product',
      DOZVOLJENE_KOREKCIJE: 'Allowed corrections',
      NE_POSTOJI_ZA_GODINU: 'There are no results for the selected year',
      VALIDATION: {
        MORA_IMATI_PROIZVOD: 'The list of products must have at least one piece of data',
        NE_MOZE_BITI_ISTI_PROIZVOD:
          'The same combination of product type, periods, and name cannot be repeated at the template level.',
      },
      KOLICINA_STAVLJENIH_NA_TRZISTE_PORUKA:
        "The quantity of products placed on the market is expressed in the unit of measure specified in the 'Product Name' field.",
      KOLICINA_PROIZVODA_KG_PORUKA:
        "The quantity of products (in kilograms) is entered only for products where the unit of measure in the 'Product Name' field is not kilograms.",
      KOLICINA_PDV_PORUKA: 'The value of VAT is entered only for products defined as such by the regulation.',
    },

    GIO_3: {
      AMOUNT_OF_ACCEPTED_WASTE_FOR_REUSE_AND_REUSE_OF_ACCEPTED_WASTE:
        'Amount of accepted waste for reuse and reuse of accepted waste',
      AMOUNT_OF_COLLECTED_WASTE_IN_REPORTING_YEAR: 'Amount of collected waste in the reporting year (t)',
      THE_TOTAL_AMOUNT_OF_THIS_TYPE_OF_WASTE_REUSED_IN_REPORTING_YEAR:
        'The total amount of this type of waste reused in the reporting year (t)',
      BALANCE_OF_THE_TEMPORARY_WAREHOUSE_JANUARY: 'Balance of the temporary warehouse on January 1 (t)',
      BALANCE_OF_THE_TEMPORARY_WAREHOUSE_DECEMBER: 'Balance of the temporary warehouse on December 31 (t)',
      RECYCLING_PROCESS: 'The recycling process',
      ANOTHER_RECYCLING_PROCESS: 'Another recycling process',
      TYPE_OF_ELECTRICAL_AND_ELECTRONIC_WASTE: 'Type of electrical and electronic waste',
      CLASS_EE_WASTE: 'Class EE waste',
      QUANTITY_T: 'Quantity(t)',
      R_MARK: 'R mark',
      D_MARK: 'D mark',
      QUANTITIES_OF_INCINERATED_WASTE_WITH_ENERGY:
        'Quantities of incinerated waste with energy production (label R) (t)',
      AMOUNT_OF_COMPOSTED_WASTE: 'Amount of composted waste (label R3) (t)',

      DATA_ON_THE_WASTE_RECYCLING_FACILITY_OWNED_BY_COMPANY:
        'Data on the waste recycling facility owned by the company',
    },

    DEP1: {
      PANELI: {
        KOLICINE_OTPADA: 'Amounts of waste and scope of waste collection at the unsanitary landfill - landfill',
        GRADOVI_OPSTINE:
          'Cities/municipalities or settlements whose waste is disposed of in an unsanitary landfill - landfill',
        FIZICKA_ZASTITA: 'Physical protection and equipment of an unsanitary landfill - garbage dump',
        OSTALI_PODACI: 'Other information about the unsanitary landfill',
        PROJEKAT_SANACIJE:
          'The project of rehabilitation, recultivation and closure of an unsanitary landfill - garbage dump',
      },

      DELIMICNO: 'Partially',
      PROSECNE_GODISNJE_KOLICINE_OTPADA:
        'Average annual amounts of waste, which is disposed of in an unsanitary landfill - landfill (t)',

      OGRADA_OKO_NESANITARNE_DEPONIJE: 'The fence around the unsanitary landfill - garbage dump',
      KAPIJA_RAMPA_NA_ULAZU: 'Gate / ramp at the entrance',
      CUVARSKA_SLUZBA: 'Guard service',
      KOLSKA_VAGA: 'Weighbridge',
      DRENAZNI_SISTEM_ZA_PRIKUPLJANJE: 'Drainage system for collecting leachate waste water',
      SISTEM_ZA_PRECISCAVANJE_PROCEDNIH_OTPADNIH_VODA: 'A system for the purification of leachate from the landfill',
      SISTEM_ZA_OTPLINJAVANJA_DEPONIJSKOG_GASA: 'Landfill gas degassing system',

      KOJEM_REGIONU_PRIPADA_OPSTINA: 'Which waste management region does your municipality belong to?',
      SA_KOJIM_LOKALNIM_SAMOUPRAVAMA_IMATE_SPORAZUM:
        'With which local self-governments do you have a signed agreement on joint regional waste management?',
      NESANITARNA_DEPONIJA_SMETLISTE_JE: 'The unsanitary landfill is:',
      OPERATIVNI_STATUS_NESANITARNE_DEPONIJE: 'Operational status of unsanitary landfill - garbage dump',
      DA_LI_SE_NA_NESANITARNOJ_DEPONIJI_I_DALJE_ODLAZE_OTPAD:
        'Does waste continue to be disposed of at the unsanitary landfill - dump?',
      NACIN_ODLAGANJA_OTPADA: 'Waste disposal method',
      DA_LI_SE_VRSI_PREKRIVANJE_INERTNIM_MATERIJALOM: 'Is it covered with internal material?',
      PERIOD_ZA_KOJI_SE_PREDVIDJA_TRAJANJE_SMETLISTA:
        'Period for which the duration and exploitation of the unsanitary landfill - garbage dump is predicted',
      DA_LI_SE_VRSI_EVIDENCIJA_O_KOLICINAMA_OTPADA: 'Are records of the quantities and types of waste disposed of?',
      DA_LI_SE_NESANITARNA_NALAZI_U_POPLAVNOM:
        'Is the unsanitary landfill - garbage dump or part of it located in a flood area?',

      DA_LI_JE_IZRADJEN_PROJEKAT_SANACIJE_ZATVARANJA:
        'Has a project for rehabilitation, closure and recultivation been drawn up for the unsanitary landfill?',
      KOJE_GODINE: 'What year?',
      DA_LI_JE_PRIBAVLJENA_SAGLASNOST_PROJEKAT_SANACIJE_ZATVARANJA:
        'Has consent been obtained for the Rehabilitation, Closure and Recultivation Project?',
      DA_LI_SE_IZVODE_RADOVI_PO_PROJEKTU_SANACIJE_ZATVARANJA:
        'Are works being carried out according to the Rehabilitation, Closure and Recultivation Project?',
      KOJI_RADOVI_SE_IZVODE_PO_PROJEKTU_SANACIJE_ZATVARANJA:
        'What works are being carried out under the Rehabilitation, Closure and Recultivation Project?',
      DA_LI_SE_SPROVODE_MERE_ZASTITE_ZIVOTNE_SREDINE:
        'Are the environmental protection measures prescribed by the Rehabilitation, Closure and Recultivation Project implemented?',
      DA_LI_SE_SPROVODI_MONITORING:
        'Is the monitoring prescribed by the Rehabilitation, Closure and Recultivation Project carried out?',
      DA_LI_JE_POTREBNA_IZRADA_NOVOG_ILI_AZURIRANJE:
        'Is it necessary to create a new or update the existing Project for rehabilitation, closure and recultivation of an unsanitary landfill',
      MORA_IMATI_MESTO: 'The table must have at least one space',
    },

    GIO2: {
      PODACI_O_DOZVOLI_ZA_UPRAVLJANJE: 'Data on the permit for waste management',
      DOZVOLA_VAZI_ZA: 'The license is valid for:',
      BROJ_DOZVOLE: 'License number',
      DATUM_IZDAVANJA: 'Date of issue',
      DATUM_ISTEKA: 'Expiry date',
      SAKUPLJANJE: 'Collecting',
      TRANSPORT: 'Transportation',
      SKLADISTENJE: 'Storage',
      TRETMAN: 'Treatment',
      ODLAGANJE: 'Postponement',
      ADRESA_DEPONIJE: 'Landfill address',
      MESTO_DEPONIJE: 'Landfill site',
      VRSTA_DEPONIJE: 'Type of landfill',
      PODACI_O_DEPONIJI: 'Landfill information',
      VALIDACIJA_CHECKBOX: 'You must select at least one item',
      KOLICINA_OTPADA_ZA_ODLAGANJE: 'Amount of waste for disposal (label D) (t)',
      KOLICINA_OTPADA_ZA_SPALJIVANJE: 'Amount of waste for incineration (without energy production) (code D10) (t)',
      POSTUPAK_ODLAGANJA: 'Disposal procedure',
      DRUGI_POSTUPAK_ODLAGANJA: 'Another disposal procedure',
      KOLICINA_PREUZETOG_OTPADA: 'Amount of collected waste and disposal of collected waste',
      UKUPNA_KOLICINA_ODLOZENOG: 'The total amount of this type of waste disposed of in the reporting year',
    },

    GIO4: {
      WASTE_EXPORT: 'Waste export',
      EXPORT_AMOUNT: 'Amount of exported waste (t)',
      EXPORT_LICENSE: 'Export license number',
      IMPORT_COUNTRY: 'Country of export',
      NAME_ADDRESS: 'Name and address of the waste disposal or treatment facility',
      WASTE_EXPORT_LISTS: 'Waste export - lists',
      Y_LIST: 'Y list',
      C_LIST: 'C list',
      N_LIST: 'Hazardous characteristics of the waste (H list)',
      SHIPPING_LIST: 'List of waste for cross-border transport',
      IA_LIST: 'IA list',
      IIB_LIST: 'IIB list',
      R_LIST: 'Recycled waste R operation',
      D_LIST: 'Discarded waste D operation',
      EE_TYPES: 'Types of electrical and electronic waste',
      EE_CLASS: 'Class of EE waste',
      AMOUNT: 'Amount (t)',
      TRANSIT: 'Countries of transit',
      VALIDATION: {
        WASTE_CATALOGUE_EXISTS: 'Waste index number is already in the list',
        COUNTRY_EXISTS: 'This country is already in the list',
        WASTE_EXISTS: 'The waste index already exists in the list',
        TRANSIT:
          'If the country of export does not border the Republic of Serbia, you must specify the countries of transit',
        TRANSIT_SAME: 'The country of export cannot also be the country of transit',
        EE_AMOUNT:
          'The sum of the quantities in the table must be equal to the quantity entered in the field Amount of exported waste',
        EE_EXISTS: 'Class of EE waste is already in the list',
        DODAJTE_IZVOZ: 'Add waste export',
      },
    },

    GIO5: {
      WASTE_IMPORT: 'Waste import',
      IMPORT_AMOUNT: 'Amount of imported waste (t)',
      IMPORT_LICENSE: 'Import license number',
      EXPORT_COUNTRY: 'Country of export',
      NAME_ADDRESS: 'Name and address of the company for whose needs the waste was imported',
      WASTE_IMPORT_LISTS: 'Waste import lists',
      VALIDATION: {
        TRANSIT:
          'If the country of import does not border the Republic of Serbia, you must specify the countries of transit',
      },
    },
    OBRAZAC5: {
      TRANSPORT_OTPADA: 'Waste transport',
      NAZIV_PREVOZNIKA_OTPADA: 'Waste carrier name',
      BROJ_DOZVOLE_ZA_PREVOZ_OPASNIH_MATERIJA: 'Permit number for transporting hazardous materials',
      TIP_VOZILA: 'Vehicle type',
      INDEKSNI_BROJ: 'Waste catalog index number',
      KLASIFIKACIJA_OTPADA: 'Waste classification',
      MESTO_NASTANKA_OTPADA: 'Waste generation place',
      VRSTA_OTPADA: 'Waste type',
      OPIS_OTPADA: 'Waste description',
      KARAKTER_OTPADA: 'Waste character',
      KATEGORIJA_OTPADA_Q_LISTA: 'Waste category - Q list',
      IZVESTAJ_O_ISPITIVANJU: 'Examination report of waste',
      BROJ_IZVESTAJA: 'Report number',
      DATUM_IZDAVANJA: 'Issue date',
      OPASAN_OTPAD_PORUKA:
        'If the waste is hazardous, select the hazardous waste characteristic code (from the H list) and select the hazardous waste code by origin and composition (from the Y list)',
      OZNAKA_OPASNE_KARAKTERISTIKE: 'Hazardous waste characteristic label',
      KATEGORIJA_PO_POREKLU: 'Category of hazardous waste by origin and composition',
      FIZICKO_STANJE_OTPADA: 'Physical state of waste',
      KOMPONENTE_KOJE_OTPAD_CINE_OPASNIM: 'Components that make waste hazardous',
      CAS_BROJ: 'CAS number',
      HEMIJSKI_NAZIV: 'Chemical name',
      SADRZAJ_OPASNE_MATERIJE: 'Hazardous substance content (kg w.m. / kg of waste)',
      KOLICINA_OTPADA: 'Waste quantity',
      GODISNJA_KOLICINA: 'Annual quantity of generated waste',
      STANJE_1_JANUAR: 'State of the temporary storage on January 1st (t)',
      STANJE_31_DECEMBAR: 'State of the temporary storage on December 31st (t)',
      NACIN_ODREDJIVANJA: 'Method of determining waste quantities',
      KOLICINA_NA_SKLADISTENJE: 'Quantity of waste delivered to storage (t)',
      KOLICINA_NA_ODLAGANJE: 'Quantity of waste delivered for disposal (t)',
      KOLICINA_NA_PONOVNO_ISKORISCENJE: 'Quantity of waste delivered for recycling (t)',
      KOLICINA_IZVEZENOG_OTPADA: 'Quantity of exported waste (t)',
      SKLADISTE_OTPADA: 'Waste storage',
      NAZIV_ZA_SKLADISTENJE: 'Name of the waste storage facility',
      BROJ_DOZVOLE: 'Permit number',
      D_ILI_R_OZNAKA: 'D or R label',
      ODLAGANJE_OTPADA: 'Waste disposal',
      PONOVNO_ISKORISCENJE_OTPADA: 'Waste recycling',
      NAZIV_ZA_ODLAGANJE: 'Name of the waste disposal facility',
      NAZIV_ZA_PONOVNO_ISKORISCENJE: 'Name of the waste recycling facility',
      NAZIV_ZA_TRETMAN: 'Name of the waste treatment facility',
      D_OZNAKA: 'D label',
      R_OZNAKA: 'R label',
      IZVOZ_OTPADA: 'Waste export',
      ZEMLJA_IZVOZA: 'Export country',
      ADRESA_ZA_TRETMAN: 'Address of the waste treatment facility',
      ULICA_ZA_TRETMAN: 'Street of the waste treatment facility',
      KUCNI_BROJ_ZA_TRETMAN: 'House number of the waste treatment facility',
      MESTO_ZA_TRETMAN: 'City of the waste treatment facility',
      POSTANSKI_ZA_TRETMAN: 'Postal code of the waste treatment facility',
      IZABERI_NACIN_UPRAVLJANJA: 'Choose a waste management method',
      UPOZORENJE_OPASAN_OTPAD_LISTA: 'This list should be filled out only if hazardous waste is in question',
      VALIDATION: {
        DODATI_KOMPONENTE: 'Components need to be added to preserve the pattern',
      },
    },

    DKO: {
      PODACI_O_OTPADU: 'Data about waste',
      KLASIFIKACIJA_OTPADA: 'Waste classification',
      Q_LIST: 'Q list',
      UN_BROJ_I_KLASA: 'UN number and class',
      MASA_OTPADA: 'Waste mass',
      NACIN_PAKOVANJA_OTPADA: 'Method of waste packaging',
      FIZICKO_STANJE_OTPADA: 'Physical state of waste',
      IZVESTAJ_O_ISPITIVANJU_OTPADA: 'Waste testing report',
      BROJ_IZVESTAJA: 'Report number',
      VID_PREVOZA: 'Method of transportation',
      ODREDISTE: 'Destination',
      ADRESA_ODREDISTA: 'Destination address',
      POSEBNA_NAPOMENA: 'Special notes for handling and additional information',
      PODACI_O_PROIZVODJACU_VLASNIKU: 'Data about the waste producer/owner',
      PREDUZECE_PRIMALAC: 'Receiver company',
      PROIZVODJAC_VLASNIK_OTPADA_JE: 'The waste producer/owner is',
      PREDVIDJENI_NACIN_POSTUPANJA: 'Planned handling method',
      BROJ_DOZVOLE: `Driver's license number`,
      DATUM_IZDAVANJA_DOZVOLE: 'Date of issuance of the permit',
      DOZVOLA_ZA_UPRAVLJANJE: 'Waste management permit',
      DATUM_PREDAJE_OTPADA: 'Waste submission date',
      ODGOVORNO_LICE_PROIZVODJAC: 'Responsible person for the waste producer/owner',
      IME_PREZIME: 'Name and surname',
      BROJ_MOBILNOG_TELEFONA: 'Mobile phone number',
      PODACI_O_PREVOZNIKU_OTPADA: 'Data on the waste carrier',
      PREDUZECE_PREVOZNIK: 'Transportation company',
      TELEFON_PREVOZNIKA: 'Transporter phone',
      TELEFAKS_PREVOZNIKA: 'Transporter fax',
      EMAIL_PREVOZNIK: 'Transporter email',
      VRASTA_PREVOZNOG_SREDSTVA: 'Type of transport vehicle',
      REGISTARSKI_BROJ_PREVOZNOG_SREDSTVA: 'Vehicle registration number',
      RUTA_KRETANJA_OTPADA: 'Waste movement route',
      LOKACIJA_UTOVAR: 'Loading location',
      ADRESA_UTOVAR: 'Loading address',
      PREKO: 'Via',
      LOKACIJA_ISTOVARA: 'Unloading location',
      ADRESA_ISTOVARA: 'Unloading address',
      DATUM_PREUZIMANJA: 'Waste pickup date',
      ODGOVORNO_LICE_PREVOZNIKA_PREUZEO: 'Responsible person for the transporter (received waste)',
      ODGOVORNO_LICE_PREVOZNIKA_PREDAO: 'Responsible person for the transporter (delivered waste)',
      PODACI_O_PRIMAOCU_OTPADA: 'Data about the waste receiver',
      TELEFON_PRIMALAC: 'Receiver phone',
      TELEFAKS_PRIMALAC: 'Receiver fax',
      EMAIL_PRIMALAC: 'Receiver email',
      PRIMALAC_OTPADA_JE: 'The waste receiver is',
      ODGOVORNO_LICE_PRIMALAC: 'Responsible person for the waste receiver',
      PREDUZECE_PROIZVODJAC: 'Company - producer/owner',
      POTREBNA_POTVRDA: 'The waste submission date has passed. Confirmation of the form is required.',
      POTVRDA_PITANJE: 'Are you sure you want to confirm the form?',
      ODJAVA_PITANJE: 'Are you sure you want to log out of the form?',
      MASA_OTPADA_PROVERA: 'Check if the waste mass entry is correct.',
      Y_LIST_REQUIRED: 'Filling in at least one field of the Y list is mandatory.',
      Y_LIST_EXISTS: 'This Y list has already been selected.',
      N_LIST_REQUIRED: 'Filling in at least one field of the N list is mandatory.',
      N_LIST_EXISTS: 'This N list has already been selected.',
      PREKO_REQUIRED: 'Entering at least one location is mandatory.',
      PREKO_EXISTS: 'This location has already been chosen.',
      ODREDISTE_REQUIRED: 'You must fill in the destination in section A.',
      POSTROJENJE_SKLADISTE: 'Waste storage facility',
      POSTROJENJE_TRETMAN: 'Waste treatment facility',
      POSTROJENJE_ODLAGANJE: 'Waste disposal facility',
      DRUMSKI: 'Road',
      VODENI: 'Water',
      ZELEZNICKI: 'Railway',
      OPERATER: 'Operator',
      PROIZVODJAC: 'Manufacturer',
      VLASNIK: 'Owner',
    },

    OBRAZAC3: {
      ISPUST_INFO: 'Discharge Information',
      RADNI_REZIM_ISPUSTA: 'Operating Regime of Discharge',
      VREMENSKI_PERIOD_ISPUSTANJA: 'Time Period of Discharge (day/year)',
      UKUPNA_KOLICINA_ISPUSTENE:
        'Total Quantity of Discharged Wastewater in the Reporting Year at the Discharge Point (m3/year)',
      VODOVOD_ILI_KANALIZACIJA: 'Is your Public Utility Company responsible for',
      VODOVODNI_SISTEMI: 'Water Supply System',
      KANALIZACIONI_SISTEMI: 'Sewage System',
      GODISNJI_BILANS: 'Annual Balance',
      GODISNJI_PITANJE: 'Do you want to generate an annual report?',
      GODISNJI_NE: 'No, not at this time.',
      GODISNJI_DA: 'Yes, all measurements for the reporting year have been entered.',
      NAZIV_ZAGADJUJUCE_MATERIJE: 'Name of Pollutant',
      JEDINICA_MERE: 'Unit of Measurement',
      PRE_ISPUSTA: 'Before Wastewater Discharge',
      POSLE_ISPUSTA: 'After Wastewater Discharge',
      IZMERENA_VREDNOST: 'Method of Determination',
      NACIN_ODREDJIVANJA: 'Method of Determination',
      METOD_ODREDJIVANJA: 'Determination Method',
      EMITOVANE_KOLICINE: 'Emission Quantities',
      EMITOVANE_REDOVNI: 'Emission Quantities in Regular Operation (kg/year)',
      EMITOVANE_AKCIDENTNE: 'Emission Quantity in Accident Situations (kg/year)',
      EMITOVANE_AKCIDENTNE_GOD: 'Emission Quantity in Accident Situations (kg/year)',
      SREDNJA_GODISNJA_IZMERENA: 'Average Annual Measured Value of Pollutants in Wastewater (mg/l)',
      DATUM_MERENJA: 'Measurement Date',
      IDENTIFIKACIONI_BROJ_IZVESTAJA: 'Report Identification Number',
      NAZIV_LABORATORIJE: 'Name of Professional Laboratory',
      UKUPNA_POVRSINA_OPSTINA: 'Total Territory Area of the Municipality (ha)',
      PROCENAT_UKUPNOG_STANOVNISTVA_VODOVOD: 'Percentage of the Total Population Connected to Water Supply (%)',
      KOLICINA_PROIZVEDENE_VODE: 'Quantity of Produced Water (m3/year)',
      UKUPNA_KOLICINA_POTROSACIMA: 'Total Quantity Delivered to All Consumers (m3/year)',
      UKUPNA_KOLICINA_STANOVNISTVU: 'Total Quantity Delivered to the Population (m3/year)',
      UKUPNA_KOLICINA_INDUSTRIJI: 'Total Quantity Delivered to Industry and Other Institutions (m3/year)',
      GLAVNI_INDUSTRIJSKI: 'Main Industrial Water Consumers',
      NAJVECI_INDUSTRIJSKI: 'Largest industrial sources of pollution that discharge wastewater into the sewer network',
      PROCENAT_UKUPNOG_STANOVNISTVA_KANALIZACIJA: 'Percentage of the Total Population Connected to Sewage (%)',
      DA_LI_SE_VRSE_MERENJA: 'Are measurements of wastewater quantities carried out on the sewage system?',
      NE_VRSE_SE: 'Not carried out',
      VOLUMETRIJSKI: 'Volumetric',
      MERNOM_OPREMOM: 'With Measuring Equipment',
      DA_LI_KONTROLA_KVALITETA: 'Is quality control of wastewater performed? (enter the number of times per year)',
      NA_SISTEMU: 'On the system',
      NA_IZLIVU: 'At the discharge point',
      KOLICINA_ISPORUCENE: 'Quantity of Delivered Water (m3/year)',
      KOLICINA_ISPUSTENE: 'Quantity of discharged water (m3/year)',
      NAZIV_PREDUZECA: 'Company Name',
      BAR_JEDNO_MERENJE: 'You must enter at least one measurement',
      NACIN_ODREDJIVANJA_ISPUSTENE: 'Method for determining the amount of discharged water',
      UKUPAN_KAPACITET_PROIZVODNJE: 'Total production capacity (t/year)',
      UKUPAN_KAPACITET_PROIZVODNJE_DESCRIPTION:
        'Total production capacity shall be entered in accordance with the Regulation on emission limit values of pollutants into water and deadlines for their achievement ("Official Gazette of RS", No. 67/2011, 48/2012 and 1/2016)',
      VALIDACIJA_OTPADNE_VODE: 'You must enter at least one measurement in the Wastewater Analysis section.',
      VALIDACIJA_OTPADNE_VODE_MERENJE:
        'You must enter at least one measurement and at least one pollutant inside of it, in the Wastewater Analysis section.',
    },
    GVE: {
      IZVOR_ZAGADJIVANJA: 'Pollution source',
      PODACI_O_IZVORU: 'Data about the source',
      GODISNJA_ISKORISCENOST_KAPACITETA: 'Annual capacity utilization (%)',
      SREDNJA_GODISNJA_TEMPERATURA: 'Average annual temperature of exhaust gases at the measuring point (℃)',
      SREDNJA_GODISNJA_BRZINA: 'Average annual speed of exhaust gases at the measuring point (m/s)',
      SREDNJI_GODISNJI_PROTOK: 'Average annual flow rate at the measuring point (Nm3/h)',
      RADNI_REZIM_IZVORA: 'Operational mode of the source',
      PODACI_O_RADU: 'Operating data',
      BROJ_RADNIH_DANA: 'Number of working days of the source per year',
      BROJ_RADNIH_SATA: 'Number of working hours of the source per day',
      UKUPAN_BROJ_RADNIH_SATI: 'Total number of working hours per year',
      RASPODELA_EMISIJA: 'Distribution of annual emissions by seasons (%)',
      ZIMA: 'Winter (Dec, Jan, Feb)',
      PROLECE: 'Spring (Mar, Apr, May)',
      LETO: 'Summer (Jun, Jul, Aug)',
      JESEN: 'Autumn (Sep, Oct, Nov)',
      PODACI_O_KORISCENIM_GORIVIMA: 'Data on used fuels',
      GORIVO: 'Fuel',
      UKUPNA_GODISNJA_POTROSNJA: 'Total annual consumption (t or m3)',
      DONJA_TOPLOTNA_MOC_GORIVA: 'Lower heating value of fuel (KJ/kg or KJ/m3)',
      SASTAV_GORIVA: 'Fuel composition',
      SUMPOR: 'Sulfur',
      AZOT: 'Nitrogen',
      HLOR: 'Chlorine',
      NAZIV_DODATNOG_1: 'Name of additional content 1',
      DODATNI_1: 'Additional content 1',
      NAZIV_DODATNOG_2: 'Name of additional content 2',
      DODATNI_2: 'Additional content 2',
      REDNI_BROJ_MERENJA: 'Serial number of measurement',
      LOKACIJA_MERENJA: 'Measurement location',
      DATUM_MERENJA: 'Measurement date',
      IDENTIFIKACIONI_BROJ: 'Identification number of the report',
      NAZIV_STRUCNE_LABO: 'Name of the professional laboratory',
      ZAGADJUJUCA_MATERIJA: 'Pollutant',
      NAZIV_ZAGADJUJUCE_MATERIJE: 'Name of the pollutant',
      MASENA_KONCENTRACIJA_ZAGAJUJUCE: 'Mass concentration of the pollutant',
      IZMERENA_VREDNOST: 'Measured value (mg/Nm3)',
      METODA_UTVRDJIVANJA: 'Determination method',
      GVE_MG_NM: 'ACE (mg/Nm3)',
      PROTOK: 'Flow rate',
      PROTOK_OTPADNOG_GASA: 'Waste gas flow rate at the measuring point (Nm3/h)',
      MASENI_PROTOK_ZAGADJUJUCE: 'Mass flow of the pollutant',
      MASENI_PROTOK_MATERIJE_G_H: 'Mass flow of the pollutant (g/h)',
      EMITOVANE_U_AKCIDENTNIM: 'Emitted quantities in ACCIDENTAL situations',
      EMITOVANE_KOLICINE: 'Emitted quantities (kg)',
      KONCENTRACIJA_ZAGADJUJUCIH_MATERIJA: 'Concentration of pollutants in flue gas',
      SREDNJA_GODISNJA_VREDNOST: 'Average annual measured value (mg/Nm3)',
      NACIN_ODREDJIVANJA: 'Method of determination',
      EMITOVANE_KOLICINE_NORMALNI_RAD: 'Emitted quantities during NORMAL operation of the plant',
      EMITOVANE_G_H: 'Emitted quantities (g/h)',
      EMITOVANE_KG_GOD: 'Emitted quantities (kg/year)',
      GODISNJI_BILANS_EMISIJA: 'Annual emissions balance of pollutants',
      MERENJA: 'Measurements',
      MERENJA_VAZDUH: 'Air measurements',
      IZMERENE_VREDNOSTI: 'Measured values',
      VREDNOST_ZA_PRORACUN_BILANSA: 'Value for balance calculation (mg/Nm3)',
      ZBIR_UPOZORENJE: 'The sum must be 100%.',
      ISTA_ZAGADJUJUCA_UPOZORENJE: 'Pollutant already exists in the list',
      IZMERENE_VREDNOSTI_UPOZORENJE:
        'You cannot add a measurement if no measured value of pollutants has been added within the measurement.',
      MERENJE: 'Measurement',
      MERENJE_NASLOV: 'Measurement of Pollutant Emissions',
      GODISNJI_NASLOV: 'Annual Balance of Pollutant Emissions',
      BILANS_GENERISI: 'Generate Annual Balance',
      REKALKULACIJA_BILANSA: 'Recalculation of the Annual Balance is performed after',
      BILANS_PITANJE: 'Are you sure? Selecting this option deletes all Annual Balance data.',
      VALIDATION: {
        ZBIR_JEDNAK_100: 'The annual distribution by seasons must add up to 100%',
        MORA_IMATI_GORIVO: 'The fuel list must contain at least one data entry',
        VERIFIKUJ_MERENJE: 'The selected source of pollution is not verified. Please contact the administrator',
        KREIRAJ_BILANS: 'You must choose whether you want to generate an annual balance',
      },
    },

    DEO_6: {
      // Reuse
      KOLICINA_PONOVNO_UPOTREBLJENOG: 'Quantity of unused reusable waste (t)',
      PONOVNA_UPOTREBA_ODNOSI_SE_SAMO: 'Reuse refers only to waste that can be reused without prior treatment',
      PONOVNA_UPOTREBA_PRIMER:
        'Example: using packaging that can be refilled for the same or other purposes (using glass packaging for homemade preserves), refilled waste toner cartridges, using old newspapers as decorative solutions, etc...',

      // Waste Delivered
      KOLICINA_PREDATOG_OTPADA: 'Quantity of delivered waste (t)',
      NACIN_POSTUPANJA_OTPADOM: 'Waste handling method',
      OTPAD_JE_PREDAT_OPERATERU: 'Waste was delivered to the operator who',
      BROJ_DOZVOLE_ZA_UPRAVLJANJE: 'Waste management permit number',
      NOVE_VRSTE_OTPADA: 'New types of waste',
      BROJ_DOZVOLE_ZA_PONOVNO_ISKORISCENJE: 'Permit number for reuse',
      BROJ_DOZVOLE_ZA_ODLAGANJE: 'Disposal permit number',
      PREDTRETMAN: 'New types of waste are generated during pre-treatment',
      BROJ_DOZVOLE_UPRAVLJANJE_OTPADOM: 'Waste management permit number',
      PREDAT_DRUGOM_OPERATERU: 'Handed over to another operator',
      UKLJUCEN_U_SOPSTVENI_PROCES: 'Included in our own recycling process',
      ODLOZENO_NA_SOPSTVENOJ_LOKACIJI: 'Deferred at own location',
      PREDATO_DRUGOM_POSTROJENJU: 'Handed over to another plant of his own company',
      IZVEZENO: 'Exported out of the country',
      // Received
      PRAVNO_LICE_ILI_PREDUZETNIK: 'Legal entity or entrepreneur from whom the waste was received',
      OTPAD_JE_PREUZET_OD_KOJE_JE: 'Waste was taken from the legal entity or entrepreneur who',
      PROIZVODJAC_OTPADA: 'Waste producer',
      SAKUPLJAC: 'Collector',
      OPERATER_NA_DEPONIJI: 'Operator at the landfill',
      OPERATER_TRETMANA_OTPADA: 'Waste treatment operator',
      SKLADISTAR: 'Storekeeper',
      UVOZNIK_OTPADA: 'Waste importer',
      DRUGI_VLASNIK_OTPADA: 'Other waste owner',
      KOLICINA_PREUZETOG: 'Quantity of received waste (t)',
      OTPAD_PREUZET_OD: 'Waste received from',
      KOLICINA_IZ_DOMACINSTAVA: 'Quantity collected from households (t)',
      KOLICINA_IZ_DRUGIH_IZVORA: 'Quantity collected from other sources',
      UKUPNA_KOLICINA: 'Total collected quantity',
      FIZICKO_LICE: 'Individual',
      PRAVNO_LICE: 'Legal entity or entrepreneur',
      DRUGOG_POSTROJENJA: 'Another plant of his own company',
      SOPSTVENI_UVOZ: 'Own import',

      // Storage
      UKUPNA_KOLICINA_OVD_VRSTE_OTPADA: 'Total quantity of this type of waste in the warehouse on January 1st (t)',
      OVAJ_DEO_POPUNJAVA: 'THIS SECTION IS FILLED IN ONLY FOR COMPANIES WITH WASTE STORAGE FACILITIES',
      ZATECENO_STANJE_NA_PRIVREMENOM:
        'The current state at the temporary storage, filled in only once a year in the daily report on January 1st of the current year with quantities and types of waste present on that day. To enter the state at the temporary storage, it is necessary to select the date January 1st.',
      DATUM_IZVESTAJA: 'Date of the report',
      RAZREDI_ERROR: 'Class of electricity already exists in the list',
      INDEX_ERROR: 'Index number already exists in the list',
    },
    VALIDATION: {
      DODAJTE_DOZVOLU_ZA_UPRAVLJANJE: 'At least one management license must be added',
      DODATI_DEPONIJU_ZA_UPRAVLJANJE: 'At least one management dump must be added',
      DODATI_KOLICINU: 'At least one quantity must be added',
      DODATI_POSTROJENJE: 'At least one plant must be added',
      POPUNITI_SVA_OBAVEZNA_POLJA: 'You need to fill in all required fields',
      DODATI_JEDAN_PZV_PROIZVOD: 'At least one product must be added',
      JEDNAKA_RAZRED: 'It must be equal to the sum of all quantities from the table of type ee',
      VECA_ILI_JEDNAKA_NOVE_VRSTE:
        'It must be greater than or equal to the sum of all quantities from the table of the new type',
      JEDNAKA_RAZRED_UKUPNA: 'It must be equal to the sum of all total quantities from the table of type ee',
      DODATI_KOLICINU_NA_TAB: 'Add quantity on one of the tabs',
    },
  },

  WIZARD_TABS: {
    OPSTE: 'General',
    PROIZVODI: 'Products',
    NAPOMENA: 'Note',
    DIVLJE_DEPONIJE: 'Illegal Landfills',
    DETERDZENTI: 'Detergents',
    DJUBRIVA: 'Fertilizers',
    ZASTITA_BILJA: 'Plant protection',
    NAPOMENA_ADMINISTRATORA: 'Admin note',
    DODATNI_PODACI: 'Additional info',
    EMISIJE_U_TLO: 'Emissions into the soil',
    EMISIJE_U_VAZDUH: 'Air emissions',
    GORIVA: 'Fuel',
    SIROVINE: 'Raw materials',
    QUANTITY: 'Quantity',
    NESANITARNA_DEPONIJA: 'Unsanitary landfill',
    KOLICINA_AMBALAZE: 'Packaging amount',
    UPRAVLJANJE_AMBALAZNIM_OTPADOM: 'Management of packaging waste',
    PRAVNO_LICE_ILI_PREDUZETNIK: 'Legal entity or entrepreneur',
    DOZVOLE: 'Permits',
    DEPONIJE: 'Landfills',
    KLASIFIKACIJE: 'Classification',
    KOMPONENTE: 'Components',
    UPRAVLJANJE: 'Management',
    IZVOZ_OTPADA: 'Waste export',
    UVOZ_OTPADA: 'Waste import',
    DEO_A: 'Part A',
    DEO_B: 'Part B',
    DEO_C: 'Part C',
    DEO_D: 'Part D',
    ANALIZA_OTPADNE_VODE: 'Waste water analysis',
    ANALIZA_RECIPIJENATA: 'Analysis of recipients',
    VODOVODNI_SISTEMI: 'Water supply systems',
    KANALIZACIONI_SISTEMI: 'Sewer systems',
    STANJE_NA_SKLADISTU: 'Stock balance on January 1',
    PREUZET_OTPAD: 'Collected waste',
    PREDAT_OTPAD: 'Submitted waste',
    PONOVNA_UPOTREBA_OTPADA: 'Reuse of waste',
    UKUPNA_KOLICINA_PO_AKTIVNOSTIMA: 'Total amount by activity',
  },
  OBRAZAC_IZVESTAJI: obrazacIzvestajiTranslations,
  ZAKLJUCAVANJE_OBRAZACA: zakljucavanjeObrazacaTranslations,
};
export default translations;
