import { Form } from 'antd';
import { FormInstance, useForm } from 'antd/es/form/Form';
import { FilterTableForm } from 'components/TableData/FilterTableForm/FilterTableForm';
import { observer } from 'mobx-react-lite';
import { paginationInitialState } from 'modules/nriz/nriz.constants';
import { obrasciService } from 'modules/obrasci/obrasci.service';
import { obrasciStore } from 'modules/obrasci/obrasci.store';
import React, { useMemo } from 'react';

import { IFilterTableProps } from 'typescript/NrizTypes';

interface IObrasciFiltersInitialState {
  preduzece_id: string;
  postrojenje_id: string;
  indeksni_broj_otpada_id: string;
  broj_dokumenta: string;
  order_by: string;
  datum: string;
  godina: string;
  datum_predaje_otpada: string;
  nesanitarna_deponija_id: string;
  izvor_zagadjenja_vazduha_id: string;
  izvor_zagadjenja_vode_id: string;
  izvor_zagadjenja_tla_id: string;
  preduzece__pib: string;
  preduzece__maticni_broj: string;
  postrojenje__nacionalni_id: string;
  gve: boolean;
  obrazac_2: boolean;
  lriz_obrazac_2: boolean;
}

export const ObrasciFilters: React.FC = observer(() => {
  const [obrasciFilterForm] = useForm();

  const components = useMemo(() => {
    return obrasciService.filtersObrasciFinder(obrasciStore.real_obrazac_type).components(obrasciFilterForm);
  }, [Form.useWatch([], obrasciFilterForm), obrasciStore.real_obrazac_type]);

  const fetchObrazacList = () => {
    return obrasciStore.fetchListObrazac(paginationInitialState);
  };

  const data = {
    form: obrasciFilterForm as FormInstance<IObrasciFiltersInitialState>,
    initialState: obrasciService.filtersObrasciFinder(obrasciStore.real_obrazac_type).initialState,
    filterOptions: components,
    filterName: 'obrasci',
    resetForm: obrasciStore.real_obrazac_type,
    modifyPayload: obrasciService.obrasciFilterModifyPayload,
    fetchTableList: fetchObrazacList,
  } as IFilterTableProps;

  return (
    <div id="obrasciFilters">
      <FilterTableForm data={data} />
    </div>
  );
});
