import { Col } from 'antd';
import { FormInputNumber } from 'components/FormInputNumber/FormInputNumber';
import { FormSearchInfiniteScroll } from 'components/FormSearchInfiniteScroll/FormSearchInfiniteScroll';
import { observer } from 'mobx-react-lite';
import {
  LATITUDE_RULE,
  LONGITUDE_RULE,
  MORE_OR_EQUILE_0_IS_REQUIRED,
  MORE_THEN_0,
  REQUIRED_FIELD_RULE,
} from 'modules/nriz/nriz.constants';
import { obrasciStore } from 'modules/obrasci/obrasci.store';
import { dep2ObrazacStore } from 'modules/obrasci/store/dep2/dep2.store';
import { IGetDivljeDeponije } from 'modules/obrasci/store/dep2/dep2.types';
import { ISifarnikInitial } from 'modules/sifarnici/sifarnici.types';
import React from 'react';
import { useTranslation } from 'react-i18next';

export const NewDivljeDeponijeForm: React.FC = observer(() => {
  const { t } = useTranslation();

  const defaultMestoOption: ISifarnikInitial | undefined = dep2ObrazacStore.getDivljaDeponija.mesto_id
    ? {
        label: (dep2ObrazacStore.divlja_deponija as IGetDivljeDeponije).mesto.display_name,
        value: dep2ObrazacStore.getDivljaDeponija.mesto_id,
      }
    : undefined;
  const defaultDepFormOption: ISifarnikInitial | undefined = dep2ObrazacStore.getDivljaDeponija.dep_forms_answers_id
    ? {
        label: (dep2ObrazacStore.divlja_deponija as IGetDivljeDeponije).dep_forms_answers.answer_sr,
        value: dep2ObrazacStore.getDivljaDeponija.dep_forms_answers_id,
      }
    : undefined;

  const readOnly = obrasciStore.zakljucanObrazac;

  return (
    <>
      <Col span={24}>
        <FormSearchInfiniteScroll
          showSearch={true}
          formName={'mesto_id'}
          fetchOptinsList={dep2ObrazacStore.fetchMesto}
          labelOptionAccessor="display_name"
          valueOptionAccessor="id"
          label={t('OBRASCI.DEP_2.SETTLEMENT')}
          inputName={'mesto_id'}
          rules={[REQUIRED_FIELD_RULE(true)]}
          colon={true}
          defaultOption={defaultMestoOption}
          placeholder={''}
          readOnly={readOnly}
        />
        <FormInputNumber
          name="koordinate_lat"
          rules={[REQUIRED_FIELD_RULE(true), ...LATITUDE_RULE()]}
          label={t('COMPANY.LATITUDE')}
          inputName={'koordinate_lat'}
          colon={true}
          readOnly={readOnly}
        />

        <FormInputNumber
          name="koordinate_long"
          rules={[REQUIRED_FIELD_RULE(true), ...LONGITUDE_RULE()]}
          label={t('COMPANY.LONGITUDE')}
          inputName={'koordinate_long'}
          colon={true}
          readOnly={readOnly}
        />
        <h4 style={{ marginBlock: '30px', fontWeight: 'bold' }}>
          {t('KOORDINATE_TEXT')}
          <a target="_blank" href="http://www.nrizgis.sepa.gov.rs/nrizgis/kor/">
            http//www.nrizgis.sepa.gov.rs/nrizgis/kor/
          </a>
        </h4>

        <FormInputNumber
          name="procenjena_kolicina_otpada"
          rules={[REQUIRED_FIELD_RULE(true), ...MORE_THEN_0]}
          placeholder=""
          label={t('OBRASCI.DEP_2.ESTIMATED_AMOUNT_OF_WASTE')}
          inputName={'procenjena_kolicina_otpada'}
          colon={true}
          precision={0}
          readOnly={readOnly}
        />
        <FormInputNumber
          name="procenjena_povrsina_smetlista"
          rules={[REQUIRED_FIELD_RULE(true), ...MORE_THEN_0]}
          placeholder=""
          label={t('OBRASCI.DEP_2.ESTIMATED_OF_THE_AREA_OF_LANDFILLS')}
          inputName={'procenjena_povrsina_smetlista'}
          colon={true}
          precision={0}
          readOnly={readOnly}
        />
        <FormInputNumber
          name="broj_ciscenja_prostora_u_okviru_godine"
          rules={MORE_OR_EQUILE_0_IS_REQUIRED}
          placeholder=""
          label={t('OBRASCI.DEP_2.HOW_MANY_TIMES_CLEANED')}
          inputName={'broj_ciscenja_prostora_u_okviru_godine'}
          colon={true}
          precision={0}
          readOnly={readOnly}
        />
        <FormSearchInfiniteScroll
          showSearch={true}
          formName={'dep_forms_answers_id'}
          fetchOptinsList={dep2ObrazacStore.fetchDepFormAnswers}
          rules={[REQUIRED_FIELD_RULE(true)]}
          labelOptionAccessor="answer_sr"
          valueOptionAccessor="id"
          label={t('OBRASCI.DEP_2.IS_THERE_REPEATED_DUMPING')}
          inputName={'dep_forms_answers_id'}
          colon={true}
          defaultOption={defaultDepFormOption}
          placeholder={''}
          readOnly={readOnly}
        />
      </Col>
    </>
  );
});
