import { Col, Form, Row } from 'antd';

import { FormInput } from 'components/FormInput/FormInput';
import { observer } from 'mobx-react-lite';
import { MAX_LENGTH_RULE, MORE_THEN_0_IS_REQUIRED, REQUIRED_FIELD_RULE } from 'modules/nriz/nriz.constants';
import { gio4ObrazacStore } from 'modules/obrasci/store/gio4/gio4.store';
import { useTranslation } from 'react-i18next';

import styles from './NewGio4IzvozOtpadaForm.module.scss';
import { useEffect, useState } from 'react';
import { FormSearchInfiniteScroll } from 'components/FormSearchInfiniteScroll/FormSearchInfiniteScroll';
import { ISifarnikInitial } from 'modules/sifarnici/sifarnici.types';
import { Gio4ZemljeTranzitaTable } from './components/Gio4ZemljeTranzitaTable/Gio4ZemljeTranzitaTable';
import { Gio4ElektricniOtpadTable } from './components/Gio4ElektricniOtpadTable/Gio4ElektricniOtpadTable';
import { IGetIzvozOtpadaGio4, IGio4RazrediEeOtpada } from 'modules/obrasci/store/gio4/gio4.types';
import { ICrudTypeModalProps } from 'components/Modals/CRUDModal/CRUDModal';

import { FormInputNumber } from 'components/FormInputNumber/FormInputNumber';
import { obrasciStore } from 'modules/obrasci/obrasci.store';
import { isEmpty } from 'lodash';
import {
  cListOptions,
  drListOptions,
  iaListOptions,
  iibListOptions,
  nListOptions,
  yListOptions,
} from 'utils/optionMakers';
import { IGetWasteCatalogue } from 'modules/sifarnici/store/wasteCatalogue/wasteCatalogue.types';
import Decimal from 'decimal.js';

export const NewGio4IzvozOtpadaForm: React.FC<ICrudTypeModalProps> = observer(({ form }) => {
  const { t } = useTranslation();
  styles;

  useEffect(() => {
    gio4ObrazacStore.izvozOtpada.zemlja_izvoza_id
      ? gio4ObrazacStore.handleChange(
          'imaTranzita',
          !(gio4ObrazacStore.izvozOtpada as IGetIzvozOtpadaGio4).zemlja_izvoza.granici_se_srbija
        )
      : gio4ObrazacStore.handleChange('imaTranzita', false);
  }, [gio4ObrazacStore.izvozOtpada]);

  const defaultIndexOtpadaOption: ISifarnikInitial | undefined = gio4ObrazacStore.getIzvozOtpada.indeksni_broj_otpada_id
    ? {
        label: `${(gio4ObrazacStore.getIzvozOtpada as IGetIzvozOtpadaGio4).indeksni_broj_otpada.index_number} ${(
          gio4ObrazacStore.getIzvozOtpada as IGetIzvozOtpadaGio4
        ).indeksni_broj_otpada.waste_sr.toUpperCase()}`,
        value: gio4ObrazacStore.getIzvozOtpada.indeksni_broj_otpada_id,
      }
    : undefined;

  const defaultZemljaIzvozaOption: ISifarnikInitial | undefined = gio4ObrazacStore.getIzvozOtpada.zemlja_izvoza_id
    ? {
        label: (gio4ObrazacStore.getIzvozOtpada as IGetIzvozOtpadaGio4).zemlja_izvoza.name,
        value: gio4ObrazacStore.getIzvozOtpada.zemlja_izvoza_id,
      }
    : undefined;

  const defaultYlistOption: ISifarnikInitial | undefined = gio4ObrazacStore.getIzvozOtpada.izvoz_otpada_y_lista_id
    ? {
        value: gio4ObrazacStore.getIzvozOtpada.izvoz_otpada_y_lista_id,
        label: `${(gio4ObrazacStore.getIzvozOtpada as IGetIzvozOtpadaGio4).izvoz_otpada_y_lista.name} ${
          (gio4ObrazacStore.getIzvozOtpada as IGetIzvozOtpadaGio4).izvoz_otpada_y_lista.description_sr
        }`,
      }
    : undefined;

  const defaultYlist2Option: ISifarnikInitial | undefined = gio4ObrazacStore.getIzvozOtpada.izvoz_otpada_y_lista2_id
    ? {
        value: gio4ObrazacStore.getIzvozOtpada.izvoz_otpada_y_lista2_id,
        label: `${(gio4ObrazacStore.getIzvozOtpada as IGetIzvozOtpadaGio4).izvoz_otpada_y_lista2.name} ${
          (gio4ObrazacStore.getIzvozOtpada as IGetIzvozOtpadaGio4).izvoz_otpada_y_lista2.description_sr
        }`,
      }
    : undefined;

  const defaultYlist3Option: ISifarnikInitial | undefined = gio4ObrazacStore.getIzvozOtpada.izvoz_otpada_y_lista3_id
    ? {
        value: gio4ObrazacStore.getIzvozOtpada.izvoz_otpada_y_lista3_id,
        label: `${(gio4ObrazacStore.getIzvozOtpada as IGetIzvozOtpadaGio4).izvoz_otpada_y_lista3.name} ${
          (gio4ObrazacStore.getIzvozOtpada as IGetIzvozOtpadaGio4).izvoz_otpada_y_lista3.description_sr
        }`,
      }
    : undefined;

  const defaultClistOption: ISifarnikInitial | undefined = gio4ObrazacStore.getIzvozOtpada.izvoz_otpada_c_lista_id
    ? {
        value: gio4ObrazacStore.getIzvozOtpada.izvoz_otpada_c_lista_id,
        label: `${(gio4ObrazacStore.getIzvozOtpada as IGetIzvozOtpadaGio4).izvoz_otpada_c_lista.name} ${
          (gio4ObrazacStore.getIzvozOtpada as IGetIzvozOtpadaGio4).izvoz_otpada_c_lista.description_sr
        }`,
      }
    : undefined;

  const defaultNlistOption: ISifarnikInitial | undefined = gio4ObrazacStore.getIzvozOtpada.izvoz_otpada_n_lista_id
    ? {
        value: gio4ObrazacStore.getIzvozOtpada.izvoz_otpada_n_lista_id,
        label: `${(gio4ObrazacStore.getIzvozOtpada as IGetIzvozOtpadaGio4).izvoz_otpada_n_lista.name} ${
          (gio4ObrazacStore.getIzvozOtpada as IGetIzvozOtpadaGio4).izvoz_otpada_n_lista.description_sr
        }`,
      }
    : undefined;

  const defaultNlist2Option: ISifarnikInitial | undefined = gio4ObrazacStore.getIzvozOtpada.izvoz_otpada_n_lista2_id
    ? {
        value: gio4ObrazacStore.getIzvozOtpada.izvoz_otpada_n_lista2_id,
        label: `${(gio4ObrazacStore.getIzvozOtpada as IGetIzvozOtpadaGio4).izvoz_otpada_n_lista2.name} ${
          (gio4ObrazacStore.getIzvozOtpada as IGetIzvozOtpadaGio4).izvoz_otpada_n_lista2.description_sr
        }`,
      }
    : undefined;

  const defaultNlist3Option: ISifarnikInitial | undefined = gio4ObrazacStore.getIzvozOtpada.izvoz_otpada_n_lista3_id
    ? {
        value: gio4ObrazacStore.getIzvozOtpada.izvoz_otpada_n_lista3_id,
        label: `${(gio4ObrazacStore.getIzvozOtpada as IGetIzvozOtpadaGio4).izvoz_otpada_n_lista3.name} ${
          (gio4ObrazacStore.getIzvozOtpada as IGetIzvozOtpadaGio4).izvoz_otpada_n_lista3.description_sr
        }`,
      }
    : undefined;

  const defaultIalistOption: ISifarnikInitial | undefined = gio4ObrazacStore.getIzvozOtpada.ia_lista_id
    ? {
        value: gio4ObrazacStore.getIzvozOtpada.ia_lista_id,
        label: `${(gio4ObrazacStore.getIzvozOtpada as IGetIzvozOtpadaGio4).ia_lista.name} ${
          (gio4ObrazacStore.getIzvozOtpada as IGetIzvozOtpadaGio4).ia_lista.description_sr ?? ''
        }`,
      }
    : undefined;

  const defaultIiblistOption: ISifarnikInitial | undefined = gio4ObrazacStore.getIzvozOtpada.iib_lista_id
    ? {
        value: gio4ObrazacStore.getIzvozOtpada.iib_lista_id,
        label: `${(gio4ObrazacStore.getIzvozOtpada as IGetIzvozOtpadaGio4).iib_lista.name} ${
          (gio4ObrazacStore.getIzvozOtpada as IGetIzvozOtpadaGio4).iib_lista.description_sr ?? ''
        }`,
      }
    : undefined;

  const defaultROption: ISifarnikInitial | undefined = gio4ObrazacStore.getIzvozOtpada.ponovno_iskoriscenje_r_oznaka_id
    ? {
        value: gio4ObrazacStore.getIzvozOtpada.ponovno_iskoriscenje_r_oznaka_id,
        label: `${(gio4ObrazacStore.getIzvozOtpada as IGetIzvozOtpadaGio4).ponovno_iskoriscenje_r_oznaka.name} ${
          (gio4ObrazacStore.getIzvozOtpada as IGetIzvozOtpadaGio4).ponovno_iskoriscenje_r_oznaka.description_sr
        }`,
      }
    : undefined;

  const defaultDOption: ISifarnikInitial | undefined = gio4ObrazacStore.getIzvozOtpada.odlaganje_d_oznaka_id
    ? {
        value: gio4ObrazacStore.getIzvozOtpada.odlaganje_d_oznaka_id,
        label: `${(gio4ObrazacStore.getIzvozOtpada as IGetIzvozOtpadaGio4).odlaganje_d_oznaka.name} ${
          (gio4ObrazacStore.getIzvozOtpada as IGetIzvozOtpadaGio4).odlaganje_d_oznaka.description_sr
        }`,
      }
    : undefined;

  const wasteCatalogueOptions = (options: IGetWasteCatalogue[]) => {
    return options.map(wasteOption => ({
      label: `${wasteOption.index_number} ${wasteOption.waste_sr.toUpperCase()}`,
      value: wasteOption.id,
    }));
  };

  const [indexStar, setIndexStar] = useState(false);
  const [weee_categories, setWeeeCategories] = useState(false);

  useEffect(() => {
    if (weee_categories) {
      form.validateFields(['kolicina_izvezenog_otpada']);
    }
  }, [gio4ObrazacStore.getRazrediEeForm]);

  useEffect(() => {
    gio4ObrazacStore.handleChange('index_id', form.getFieldValue('indeksni_broj_otpada_id'));
    if (!isEmpty((gio4ObrazacStore.izvozOtpada as IGetIzvozOtpadaGio4).indeksni_broj_otpada)) {
      if (Number(obrasciStore.opste.godina) >= 2019) {
        setWeeeCategories((gio4ObrazacStore.izvozOtpada as IGetIzvozOtpadaGio4).indeksni_broj_otpada.weee);
      }
      setIndexStar(
        (gio4ObrazacStore.izvozOtpada as IGetIzvozOtpadaGio4).indeksni_broj_otpada.index_number.includes('*')
      );
      return;
    }
    if (form.getFieldValue('indeksni_broj_otpada_id') !== '') {
      const indexObj = gio4ObrazacStore.indexMap(form.getFieldValue('indeksni_broj_otpada_id'));
      if (Number(obrasciStore.opste.godina) >= 2019) {
        setWeeeCategories(indexObj.weee);
      }
      setIndexStar(indexObj.index_number.includes('*'));
    }
  }, [Form.useWatch('indeksni_broj_otpada_id', form)]);

  useEffect(() => {
    return () => gio4ObrazacStore.handleChange('zemljaIzvoza', undefined);
  }, []);

  const readOnly = obrasciStore.zakljucanObrazac;

  return (
    <Col span={24}>
      <Col>
        <FormSearchInfiniteScroll
          showSearch={true}
          formName={'indeksni_broj_otpada_id'}
          fetchOptinsList={gio4ObrazacStore.fetchWasteCatalogueList}
          labelOptionAccessor="index_number"
          valueOptionAccessor="id"
          optionsMaker={wasteCatalogueOptions}
          rules={[REQUIRED_FIELD_RULE(true)]}
          inputName={'indeksni_broj_otpada_id'}
          label={t('OBRASCI.KOM_1.MANAGEMENT.WASTE_INDEX_NUMBER_FROM_THE_WASTE_CATALOG')}
          labelCol={{ span: 10 }}
          wrapperCol={{ span: 14 }}
          colon={true}
          // rules={[
          //   REQUIRED_FIELD_RULE(true),
          //   {
          //     validator(_, value) {
          //       if (
          //         gio4ObrazacStore.izvozOtpada.indeksni_broj_otpada_id !== value &&
          //         gio4ObrazacStore.initialState.izvoz_otpada.some(item => {
          //           return value === item.indeksni_broj_otpada_id;
          //         })
          //       ) {
          //         setWeeeCategories(false);
          //         gio4ObrazacStore.handleChange('index_id', '');
          //         return Promise.reject(new Error(t('VALIDATION.ALREADY_EXISTS')));
          //       }
          //       return Promise.resolve();
          //     },
          //   },
          // ]}
          defaultOption={defaultIndexOtpadaOption}
          placeholder={''}
          disabled={!!gio4ObrazacStore.index_id}
          readOnly={readOnly}
        />
      </Col>
      <Col>
        <FormInputNumber
          name="kolicina_izvezenog_otpada"
          label={t('OBRASCI.GIO4.EXPORT_AMOUNT')}
          placeholder={''}
          inputName={'kolicina_izvezenog_otpada'}
          colon={true}
          rules={[
            ...MORE_THEN_0_IS_REQUIRED,
            {
              validator(_, value) {
                if (weee_categories && Number(obrasciStore.opste.godina) >= 2019) {
                  let sum = new Decimal(0);
                  (gio4ObrazacStore.izvozOtpada as IGetIzvozOtpadaGio4).razredi_ee_otpada.map(
                    (item: IGio4RazrediEeOtpada) => (sum = sum.plus(item.kolicina ?? 0))
                  );
                  if (sum.toNumber() !== value) {
                    return Promise.reject(new Error(t('OBRASCI.VALIDATION.JEDNAKA_RAZRED_UKUPNA')));
                  }
                }
                return Promise.resolve();
              },
            },
          ]}
          labelCol={{ span: 10 }}
          wrapperCol={{ span: 14 }}
          readOnly={readOnly}
        />
      </Col>
      <Col>
        <FormInput
          name="broj_dozvole_za_izvoz"
          label={t('OBRASCI.GIO4.EXPORT_LICENSE')}
          rules={[REQUIRED_FIELD_RULE(true), MAX_LENGTH_RULE(30)]}
          placeholder={''}
          inputName={'broj_dozvole_za_izvoz'}
          colon={true}
          labelCol={{ span: 10 }}
          wrapperCol={{ span: 14 }}
          readOnly={readOnly}
        />
      </Col>
      <Col>
        <FormSearchInfiniteScroll
          showSearch={true}
          formName={'zemlja_izvoza_id'}
          fetchOptinsList={gio4ObrazacStore.fetchCountryOptions}
          labelOptionAccessor="name"
          valueOptionAccessor="id"
          rules={[REQUIRED_FIELD_RULE(true)]}
          inputName={'zemlja_izvoza_id'}
          label={t('OBRASCI.GIO5.EXPORT_COUNTRY')}
          labelCol={{ span: 10 }}
          wrapperCol={{ span: 14 }}
          colon={true}
          placeholder={''}
          defaultOption={defaultZemljaIzvozaOption}
          onChange={zemljaId => {
            gio4ObrazacStore.fetchGranica(zemljaId);
            gio4ObrazacStore.handleChange('zemljaIzvoza', zemljaId);
          }}
          readOnly={readOnly}
        />
      </Col>
      <Col>
        <FormInput
          name="naziv_i_adresa_postrojenja_za_odlaganje_ili_tretman_otpada"
          type="text"
          label={t('OBRASCI.GIO4.NAME_ADDRESS')}
          placeholder={''}
          inputName={'naziv_i_adresa_postrojenja_za_odlaganje_ili_tretman_otpada'}
          colon={true}
          labelCol={{ span: 10 }}
          wrapperCol={{ span: 14 }}
          rules={[MAX_LENGTH_RULE(200)]}
          readOnly={readOnly}
        />
      </Col>
      {gio4ObrazacStore.getImaTranzita && <Gio4ZemljeTranzitaTable />}
      <h3>{t('OBRASCI.GIO4.WASTE_EXPORT_LISTS')}</h3>
      <Row>
        <Col span={9}>
          <h4>{t('OBRASCI.GIO4.Y_LIST')}</h4>
        </Col>
        <Col span={5}>
          <FormSearchInfiniteScroll
            formName={'izvoz_otpada_y_lista_id'}
            fetchOptinsList={gio4ObrazacStore.fetchYListOptions}
            labelOptionAccessor="name"
            valueOptionAccessor="id"
            inputName={'izvoz_otpada_y_lista_id'}
            label={'Y'}
            labelCol={{ span: 5 }}
            wrapperCol={{ span: 18 }}
            defaultOption={defaultYlistOption}
            placeholder={''}
            disabled={!indexStar}
            optionsMaker={yListOptions}
            readOnly={readOnly}
          />
        </Col>
        <Col span={5}>
          <FormSearchInfiniteScroll
            showSearch
            formName={'izvoz_otpada_y_lista2_id'}
            fetchOptinsList={gio4ObrazacStore.fetchYListOptions}
            labelOptionAccessor="name"
            valueOptionAccessor="id"
            inputName={'izvoz_otpada_y_lista2_id'}
            label={'/ Y'}
            labelCol={{ span: 5 }}
            wrapperCol={{ span: 18 }}
            defaultOption={defaultYlist2Option}
            placeholder={''}
            disabled={!indexStar}
            optionsMaker={yListOptions}
            readOnly={readOnly}
          />
        </Col>
        <Col span={5}>
          <FormSearchInfiniteScroll
            showSearch
            formName={'izvoz_otpada_y_lista3_id'}
            fetchOptinsList={gio4ObrazacStore.fetchYListOptions}
            labelOptionAccessor="name"
            valueOptionAccessor="id"
            inputName={'izvoz_otpada_y_lista3_id'}
            label={'/ Y'}
            labelCol={{ span: 5 }}
            wrapperCol={{ span: 19 }}
            defaultOption={defaultYlist3Option}
            placeholder={''}
            disabled={!indexStar}
            optionsMaker={yListOptions}
            readOnly={readOnly}
          />
        </Col>
      </Row>
      <Row>
        <Col span={9}>
          <h4>{t('OBRASCI.GIO4.C_LIST')}</h4>
        </Col>
        <Col span={5}>
          <FormSearchInfiniteScroll
            showSearch
            formName={'izvoz_otpada_c_lista_id'}
            fetchOptinsList={gio4ObrazacStore.fetchCListOptions}
            labelOptionAccessor="name"
            valueOptionAccessor="id"
            inputName={'izvoz_otpada_c_lista_id'}
            placeholder={''}
            wrapperCol={{ span: 18, offset: 5 }}
            defaultOption={defaultClistOption}
            optionsMaker={cListOptions}
            readOnly={readOnly}
          />
        </Col>
      </Row>
      <Row>
        <Col span={9}>
          <h4>{t('OBRASCI.GIO4.N_LIST')}</h4>
        </Col>
        <Col span={5}>
          <FormSearchInfiniteScroll
            showSearch
            formName={'izvoz_otpada_n_lista_id'}
            fetchOptinsList={gio4ObrazacStore.fetchNListOptions}
            labelOptionAccessor="name"
            valueOptionAccessor="id"
            inputName={'izvoz_otpada_n_lista_id'}
            label={'H'}
            labelCol={{ span: 5 }}
            wrapperCol={{ span: 18 }}
            defaultOption={defaultNlistOption}
            placeholder={''}
            disabled={!indexStar}
            optionsMaker={nListOptions}
            readOnly={readOnly}
          />
        </Col>
        <Col span={5}>
          <FormSearchInfiniteScroll
            showSearch
            formName={'izvoz_otpada_n_lista2_id'}
            fetchOptinsList={gio4ObrazacStore.fetchNListOptions}
            labelOptionAccessor="name"
            valueOptionAccessor="id"
            inputName={'izvoz_otpada_n_lista2_id'}
            label={'/ H'}
            labelCol={{ span: 5 }}
            wrapperCol={{ span: 18 }}
            defaultOption={defaultNlist2Option}
            placeholder={''}
            disabled={!indexStar}
            optionsMaker={nListOptions}
            readOnly={readOnly}
          />
        </Col>
        <Col span={5}>
          <FormSearchInfiniteScroll
            showSearch
            formName={'izvoz_otpada_n_lista3_id'}
            fetchOptinsList={gio4ObrazacStore.fetchNListOptions}
            labelOptionAccessor="name"
            valueOptionAccessor="id"
            inputName={'izvoz_otpada_n_lista3_id'}
            label={'/ H'}
            labelCol={{ span: 5 }}
            wrapperCol={{ span: 19 }}
            defaultOption={defaultNlist3Option}
            placeholder={''}
            disabled={!indexStar}
            optionsMaker={nListOptions}
            readOnly={readOnly}
          />
        </Col>
      </Row>
      <h3>{t('OBRASCI.GIO4.SHIPPING_LIST')}</h3>
      <Col>
        <FormSearchInfiniteScroll
          showSearch={true}
          formName={'ia_lista_id'}
          fetchOptinsList={gio4ObrazacStore.fetchIaListOptions}
          labelOptionAccessor="name"
          valueOptionAccessor="id"
          inputName={'ia_lista_id'}
          label={t('OBRASCI.GIO4.IA_LIST')}
          rules={[REQUIRED_FIELD_RULE(indexStar)]}
          labelCol={{ span: 10 }}
          wrapperCol={{ span: 14 }}
          defaultOption={defaultIalistOption}
          colon={true}
          placeholder={''}
          disabled={!indexStar}
          optionsMaker={iaListOptions}
          readOnly={readOnly}
        />
      </Col>
      <Col>
        <FormSearchInfiniteScroll
          showSearch={true}
          rules={[REQUIRED_FIELD_RULE(!indexStar)]}
          formName={'iib_lista_id'}
          fetchOptinsList={gio4ObrazacStore.fetchIibListOptions}
          disabled={indexStar}
          labelOptionAccessor="name"
          valueOptionAccessor="id"
          inputName={'iib_lista_id'}
          label={t('OBRASCI.GIO4.IIB_LIST')}
          labelCol={{ span: 10 }}
          wrapperCol={{ span: 14 }}
          defaultOption={defaultIiblistOption}
          colon={true}
          placeholder={''}
          optionsMaker={iibListOptions}
          readOnly={readOnly}
        />
      </Col>
      <Col>
        <FormSearchInfiniteScroll
          showSearch={true}
          formName={'ponovno_iskoriscenje_r_oznaka_id'}
          fetchOptinsList={gio4ObrazacStore.fetchROptions}
          labelOptionAccessor="name"
          valueOptionAccessor="id"
          inputName={'ponovno_iskoriscenje_r_oznaka_id'}
          label={t('OBRASCI.GIO4.R_LIST')}
          labelCol={{ span: 10 }}
          wrapperCol={{ span: 14 }}
          colon={true}
          onChange={() => {
            form.setFieldValue('odlaganje_d_oznaka_id', '');
          }}
          placeholder={''}
          defaultOption={defaultROption}
          readOnly={readOnly}
        />
      </Col>
      <Col>
        <FormSearchInfiniteScroll
          showSearch={true}
          formName={'odlaganje_d_oznaka_id'}
          fetchOptinsList={gio4ObrazacStore.fetchDOptions}
          labelOptionAccessor="name"
          valueOptionAccessor="id"
          onChange={() => {
            form.setFieldValue('ponovno_iskoriscenje_r_oznaka_id', '');
          }}
          inputName={'odlaganje_d_oznaka_id'}
          label={t('OBRASCI.GIO4.D_LIST')}
          labelCol={{ span: 10 }}
          wrapperCol={{ span: 14 }}
          colon={true}
          placeholder={''}
          defaultOption={defaultDOption}
          optionsMaker={drListOptions}
          readOnly={readOnly}
        />
      </Col>

      {weee_categories && Number(obrasciStore.opste.godina) >= 2019 && <Gio4ElektricniOtpadTable />}
    </Col>
  );
});
