import { EditOutlined } from '@ant-design/icons';
import { Checkbox } from 'antd';
import { ColumnsType } from 'antd/es/table';
import { Button } from 'components/Button/Button';
import TableData from 'components/TableData/TableData';
import { observer } from 'mobx-react-lite';
import { waterStore } from 'modules/sourcesOfPollution/water/water.store';
import { IGetWater } from 'modules/sourcesOfPollution/water/water.types';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { modalStore } from 'store';

export const WaterListTable: React.FC = observer(() => {
  const { t } = useTranslation();

  const columns: ColumnsType<IGetWater> = [
    {
      title: `${t('COMPANY.ACTIVE')}`,
      width: '100px',
      key: 'aktivan',
      align: 'center',
      render: (_: any, record: IGetWater) => <Checkbox checked={!!record?.aktivan} disabled={true} />,
    },
    {
      title: `${t('POLLUTION.WATER.SEWER_NUMBER')}`,
      width: '150px',
      dataIndex: 'broj_ispusta',
      key: 'broj_ispusta',
    },
    {
      title: `${t('POLLUTION.WATER.SEWER_NAME')}`,
      width: '300px',
      dataIndex: 'naziv_ispusta',
      key: 'naziv_ispusta',
    },
    {
      title: '',
      key: 'operation',
      fixed: 'right',
      width: '50px',
      render: (_, record) => {
        return (
          <Button
            icon={<EditOutlined />}
            name={'edit-water'}
            onClick={() => {
              waterStore.setWater(record);
              modalStore.changeModalName('water-modal');

              if (!waterStore?.water?.postojanje_postrojenja_za_preciscavanje) {
                waterStore.waterShowDevices = false;
              }
              waterStore.checkIfWaterTypeOfDischargedTrue(record);
            }}
          />
        );
      },
    },
  ];
  return (
    <TableData
      name="water"
      columns={columns}
      dataSource={[...waterStore.getWaterList]}
      refetchData={waterStore.fetchWaterList}
      storePagination={waterStore.waterListPagination}
    />
  );
});
