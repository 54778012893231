import { IEmptyResponse, IPaginatedApiResponse, IPostResponse } from 'typescript/NrizTypes';
import { waterRepo } from './water.repo';
import { IGetWater, IPostWater, RequestwaterList } from './water.types';
import { ApiResponse } from 'modules/axios';

class WaterService {
  fetchWaterList = (payload: RequestwaterList): IPaginatedApiResponse<IGetWater> => {
    return waterRepo.fetchWaterList(payload);
  };

  postWater = (payload: IPostWater): ApiResponse<IPostResponse> => {
    return waterRepo.postWater(payload);
  };

  putWater = (payload: IGetWater): ApiResponse<IEmptyResponse> => {
    return waterRepo.putWater(payload);
  };

  aktivirajVodu = (id: string): ApiResponse<IEmptyResponse> => {
    return waterRepo.aktivirajVodu(id);
  };
  deaktivirajVodu = (id: string): ApiResponse<IEmptyResponse> => {
    return waterRepo.deaktivirajVodu(id);
  };
}

export const waterService = new WaterService();
