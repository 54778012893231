import { observer } from 'mobx-react-lite';
import { useTranslation } from 'react-i18next';
import { FormSearchInfiniteScroll } from 'components/FormSearchInfiniteScroll/FormSearchInfiniteScroll';
import { kom1ObrazacStore } from 'modules/obrasci/store/kom1/kom1.store';
import { IKom1ObrazacResponse } from 'modules/obrasci/store/kom1/kom1.types';
import { ISifarnikInitial } from 'modules/sifarnici/sifarnici.types';
import { ICrudTypeModalProps } from 'components/Modals/CRUDModal/CRUDModal';
import { useEffect } from 'react';
import { REQUIRED_FIELD_RULE } from 'modules/nriz/nriz.constants';
import { obrasciStore } from 'modules/obrasci/obrasci.store';
import styles from './Kom1OpsteWizardTab.module.scss';
import { Col } from 'antd';
import { OpsteGodinaPreduzecePostrojenje } from 'pages/ObrazacWizard/components/components/OpsteGodinaPreduzecePostrojenje/OpsteGodinaPreduzecePostrojenje';
import { useParams } from 'react-router-dom';
import { useObrazacExistsCheck } from 'hooks/useObrazacExistsCheck';

export const Kom1OpsteWizardTab: React.FC<ICrudTypeModalProps> = observer(() => {
  const { t } = useTranslation();
  const { id } = useParams();

  const checkIfObrazacExists = useObrazacExistsCheck();

  const defaultOpstinaOption: ISifarnikInitial | undefined = kom1ObrazacStore.getInitialState.opstina_id
    ? {
        label: (kom1ObrazacStore.initialState as IKom1ObrazacResponse).opstina.name,
        value: kom1ObrazacStore.initialState.opstina_id,
      }
    : undefined;

  useEffect(() => {
    if (
      obrasciStore.opste.godina &&
      kom1ObrazacStore.opstina_id !== '' &&
      obrasciStore.opste.preduzece_id !== '' &&
      obrasciStore.opste.postrojenje_id !== ''
    ) {
      obrasciStore.setDisabledTabButton(false);
    } else obrasciStore.setDisabledTabButton(true);
  }, [kom1ObrazacStore.opstina_id, obrasciStore.opste]);

  return (
    <Col span={12} className={styles.wrapper}>
      <OpsteGodinaPreduzecePostrojenje
        withPostrojenje={true}
        store={kom1ObrazacStore}
        onChangeGodina={() => checkIfObrazacExists()}
      />
      <FormSearchInfiniteScroll
        showSearch={true}
        formName={'opstina_id'}
        label={t('OBRASCI.KOM_1.MUNICIPALITY')}
        labelCol={{ span: 9 }}
        wrapperCol={{ span: 15 }}
        placeholder=""
        wait={!!id}
        rules={[REQUIRED_FIELD_RULE(true)]}
        fetchOptinsList={kom1ObrazacStore.fetchMunicipalityCode}
        labelOptionAccessor="name"
        valueOptionAccessor="id"
        readOnly={false}
        onChange={value => {
          kom1ObrazacStore.handleChange('opstina_id', value);
        }}
        defaultOption={defaultOpstinaOption}
        disabled={
          obrasciStore.opste.godina === '' || kom1ObrazacStore.opstina_id !== '' || !obrasciStore.disabled_tab_button
        }
      />
    </Col>
  );
});
