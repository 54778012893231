import { observer } from 'mobx-react-lite';
import { FC } from 'react';

import styles from './Logo.module.scss';
import logo from '../../assets/image/logo.png';

interface ILogoProps {
  withPasus?: boolean;
}

export const Logo: FC<ILogoProps> = observer(props => {
  return (
    <div className={styles.logoWrapper}>
      <img src={logo} alt="Nriz logo" className={styles.cover_image} />
      {props.withPasus && (
        <p>
          Republika Srbija <br /> Ministarstvo zaštite životne sredine <br /> Agencija za zaštitu životne sredine
        </p>
      )}
      <h2>
        NACIONALNI REGISTAR <br /> IZVORA ZAGAĐIVANJA
      </h2>
    </div>
  );
});
