import { Col } from 'antd';
import { FormDatePicker } from 'components/FormDatePicker/FormDatePicker';
import { FormInput } from 'components/FormInput/FormInput';
import { FormSearchInfiniteScroll } from 'components/FormSearchInfiniteScroll/FormSearchInfiniteScroll';
import { InsideContentWrapper } from 'components/InsideContentWrapper/InsideContentWrapper';
import { WizardObrazacListHeader } from 'components/WizardObrazacListHeader/WizardObrazacListHeader';
import dayjs from 'dayjs';
import { observer } from 'mobx-react-lite';
import { REQUIRED_FIELD_RULE } from 'modules/nriz/nriz.constants';
import { obrasciStore } from 'modules/obrasci/obrasci.store';
import { gveStore } from 'modules/obrasci/store/gve/gve.store';
import { IGetEmisijeUVazduh } from 'modules/obrasci/store/gve/gve.types';
import { ISifarnikInitial } from 'modules/sifarnici/sifarnici.types';
import { GveTableIzmereneVrednosti } from 'pages/ObrazacWizard/components/gve/components/GveEmisijeUVazduhWizardTab/components/TableIzmereneVrednosti/TableIzmereneVrednosti';
import { useTranslation } from 'react-i18next';
import {
  defaultPickerDateForSelectedYear,
  enableDateOnlyForSelectedYearAndDisableSelectedDateInList,
} from 'utils/formatDate';

import styles from './NewGveEmisijeMerenjeForm.module.scss';

export const NewGveEmisijeMerenje: React.FC = observer(() => {
  const { t } = useTranslation();

  const existingDates = gveStore.initialState.emisije_u_vazduh.map(item => dayjs(item.datum_merenja));

  const defaultStrucnaLaboratorija: ISifarnikInitial | undefined = gveStore.getEmisijeUVazduhForm
    .naziv_strucne_laboratorije_id
    ? {
        label: (gveStore.getEmisijeUVazduhForm as IGetEmisijeUVazduh).naziv_strucne_laboratorije.name_of_laboratory,
        value: (gveStore.getEmisijeUVazduhForm as IGetEmisijeUVazduh).naziv_strucne_laboratorije_id,
      }
    : undefined;

  const readOnly = obrasciStore.zakljucanObrazac;

  return (
    <Col span={24}>
      <h4 style={{ fontWeight: 'bold' }}>{t('OBRASCI.GVE.MERENJA')}</h4>
      <FormDatePicker
        name="datum_merenja"
        label={t('OBRASCI.GVE.DATUM_MERENJA')}
        labelCol={{ span: 12 }}
        wrapperCol={{ span: 12 }}
        format="DD-MM-YYYY"
        picker={'date'}
        rules={[REQUIRED_FIELD_RULE(true)]}
        placeholder={t('OBRASCI.GVE.DATUM_MERENJA')}
        disabledDate={currentDate =>
          enableDateOnlyForSelectedYearAndDisableSelectedDateInList(
            currentDate,
            Number(obrasciStore.opste.godina),
            existingDates
          )
        }
        defaultPickerValue={() => defaultPickerDateForSelectedYear(Number(obrasciStore.opste.godina))}
        readOnly={readOnly}
      />
      <FormInput
        name="indetifikacioni_broj_izvestaja"
        label={t('OBRASCI.GVE.IDENTIFIKACIONI_BROJ')}
        type="text"
        placeholder={t('OBRASCI.GVE.IDENTIFIKACIONI_BROJ')}
        inputName={'indetifikacioni_broj_izvestaja'}
        colon={true}
        labelCol={{ span: 12 }}
        wrapperCol={{ span: 12 }}
        rules={[REQUIRED_FIELD_RULE(true)]}
        readOnly={readOnly}
      />
      <FormSearchInfiniteScroll
        showSearch={true}
        formName={'naziv_strucne_laboratorije_id'}
        fetchOptinsList={gveStore.fetchLaboratoriesAirWater}
        labelOptionAccessor="name_of_laboratory"
        valueOptionAccessor="id"
        label={t('OBRASCI.GVE.NAZIV_STRUCNE_LABO')}
        labelCol={{ span: 12 }}
        wrapperCol={{ span: 12 }}
        inputName={'naziv_strucne_laboratorije_id'}
        defaultOption={defaultStrucnaLaboratorija}
        placeholder={t('OBRASCI.GVE.NAZIV_STRUCNE_LABO')}
        rules={[REQUIRED_FIELD_RULE(true)]}
        readOnly={readOnly}
      />
      {gveStore.emisije_u_vazduh_form.merenja_izmerene_vrednosti_emisija_u_vazduhu.length === 0 && (
        <h4 className={styles.error}>{t('OBRASCI.GVE.IZMERENE_VREDNOSTI_UPOZORENJE')}</h4>
      )}

      <InsideContentWrapper
        header={
          <WizardObrazacListHeader name={'gve-izmerene-vrednosti-form'} modalName="gve-izmerene-vrednosti-modal" />
        }
      >
        <div style={{ border: '1px solid #659ad1', padding: '10px', borderRadius: '10px' }}>
          <h4 style={{ fontWeight: 'bold' }}>{t('OBRASCI.GVE.IZMERENE_VREDNOSTI')}</h4>
          <GveTableIzmereneVrednosti />
        </div>
      </InsideContentWrapper>
    </Col>
  );
});
