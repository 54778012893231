import { ColumnsType } from 'antd/es/table';
import { EditButtonProps, RowEditPreviewButton } from 'components/RowEditPreviewButton/RowEditPreviewButton';
import TableData from 'components/TableData/TableData';
import { observer } from 'mobx-react-lite';
import { obrasciStore } from 'modules/obrasci/obrasci.store';
import { obrazac4Store } from 'modules/obrasci/store/obrazac4/obrazac4.store';
import { IEmisijeUTlo } from 'modules/obrasci/store/obrazac4/obrazac4.types';
import { useTranslation } from 'react-i18next';

const EditEmisijeUTloButton: React.FC<EditButtonProps<IEmisijeUTlo>> = observer(({ record }) => {
  return (
    <RowEditPreviewButton
      name="emisije-u-tlo"
      modalName="emisije-u-tlo-modal"
      onClick={() => {
        obrazac4Store.handleChange('filterNacinOdredjivanjaId', record.method_basis_code_id);
        obrazac4Store.handleChange('emisija', record);
      }}
      preview={obrasciStore.zakljucanObrazac}
    />
  );
});

export const EmisijeUTloTable: React.FC = observer(() => {
  const { t } = useTranslation();

  const columns: ColumnsType<IEmisijeUTlo> = [
    {
      title: `${t('OBRASCI.OBRAZAC_4.SOIL_EMISSION.WASTE_NAME')}`,
      width: '150px',
      dataIndex: ['pollutant_code', 'name_sr'],
    },
    {
      title: `${t('OBRASCI.OBRAZAC_4.SOIL_EMISSION.CONCENTRATION')}`,
      width: '200px',
      dataIndex: 'koncetracija_zagadjujuce_materije_u_otpadu',
    },
    {
      title: `${t('OBRASCI.OBRAZAC_4.SOIL_EMISSION.QUANTITY')}`,
      width: '200px',
      dataIndex: 'kolicina_zagadjujuce_materije_u_odlozenom_otpadu',
    },
    {
      title: `${t('OBRASCI.OBRAZAC_4.SOIL_EMISSION.METHOD')}`,
      width: '150px',
      dataIndex: ['method_types', 'name_sr'],
    },
    {
      title: `${t('OBRASCI.OBRAZAC_4.SOIL_EMISSION.WAY')}`,
      width: '150px',
      dataIndex: ['method_basis_code', 'name_sr'],
    },
    {
      title: '',
      align: 'center',
      fixed: 'right',
      width: '70px',
      render: (_, record: IEmisijeUTlo) => <EditEmisijeUTloButton record={record} />,
    },
  ];

  return (
    <TableData
      scrollConfig={['#obrazacTableHeader', '#obrazacWizardHeader']}
      name="emisije-u-tlo-obrazac4"
      columns={columns}
      dataSource={[...obrazac4Store.getInitialState.emisije_u_tlo]}
    />
  );
});
