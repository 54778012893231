import { Col, Row, SelectProps } from 'antd';
import { Content } from 'components/Content/Content';
import { Select } from 'components/Select/Select';
import TableData, { ITablePagination } from 'components/TableData/TableData';
import { observer } from 'mobx-react-lite';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSearchParams, useLocation } from 'react-router-dom';
import { isEmpty } from 'lodash';

import { Button } from 'components/Button/Button';
import {
  OBRAZAC_IZVESTAJI_SUBTYPE_OPTIONS_MAP,
  OBRAZAC_IZVESTAJI_TYPE_OPTIONS,
} from 'modules/obrazacIzvestaji/obrazacIzvestaji.constants';
import { obrazacIzvestajiStore } from 'modules/obrazacIzvestaji/obrazacIzvestaji.store';
import {
  Deo6IzvestajiEnum,
  IzvestajiSubtypes,
  Obrazac1IzvestajiEnum,
  Obrazac5IzvestajiEnum,
  ObrazacIzvestajTypeEnum,
} from 'modules/obrazacIzvestaji/obrazacIzvestaji.types';
import {
  OBRAZAC_IZVESTAJI_COLUMN_MAP,
  OBRAZAC_DRUGI_IZVESTAJI_COLUMN_MAP,
} from 'modules/obrazacIzvestaji/obrazacIzvestajiColumns.constants';
import { filtersStore } from 'store/filteri.store';
import { ObrazacIzvestajiFilters } from './components/ObrazacIzvestajiFilters/ObrazacIzvestajiFilters';

import { ColumnProps } from 'antd/es/table';
import styles from './ObrazacIzvestaji.module.scss';

export const ObrazacIzvestaji = observer(() => {
  const { pathname } = useLocation();
  const [searchParams, setSearchParams] = useSearchParams();
  const { t } = useTranslation();
  const getIzvestajType = () =>
    (searchParams.get('type') as ObrazacIzvestajTypeEnum) || ObrazacIzvestajTypeEnum.OBRAZAC_1;
  const getIzvestajSubtype = () => (searchParams.get('subtype') as IzvestajiSubtypes) || Obrazac1IzvestajiEnum.GORIVA;

  const [izvestajType, setIzvestajType] = useState<ObrazacIzvestajTypeEnum>(getIzvestajType());
  const [izvestajSubtype, setIzvestajSubtype] = useState<IzvestajiSubtypes>(getIzvestajSubtype());

  const selectedIzvestajSubtypeOptions = OBRAZAC_IZVESTAJI_SUBTYPE_OPTIONS_MAP[izvestajType];
  const columnsByIzvestajType = izvestajSubtype
    ? OBRAZAC_IZVESTAJI_COLUMN_MAP[izvestajSubtype](obrazacIzvestajiStore.obrazacIzvestaj)
    : [];
  const columnsBySecondIzvestajType = izvestajSubtype
    ? OBRAZAC_DRUGI_IZVESTAJI_COLUMN_MAP?.[izvestajSubtype as Deo6IzvestajiEnum.REKALKULACIJA]?.(
        obrazacIzvestajiStore.obrazacIzvestaj
      )
    : [];

  const hasFilters = !isEmpty(filtersStore.filters.obrazacIzvestaji);

  const handleChangeIzvestajType: SelectProps['onChange'] = (izvestajType: ObrazacIzvestajTypeEnum) => {
    const newObrazacFirstSubtype = OBRAZAC_IZVESTAJI_SUBTYPE_OPTIONS_MAP[izvestajType]?.[0]?.value;
    setIzvestajType(izvestajType);
    setIzvestajSubtype(newObrazacFirstSubtype);
    setSearchParams({ type: izvestajType, subtype: newObrazacFirstSubtype });
  };

  const handleChangeIzvestajSubtype: SelectProps['onChange'] = izvestajSubtype => {
    obrazacIzvestajiStore.clearObrazacIzvestaj(izvestajSubtype);
    setIzvestajSubtype(izvestajSubtype);
    setSearchParams({ type: izvestajType, subtype: izvestajSubtype });
  };

  const refetchData = (pagination: ITablePagination) => {
    if (!hasFilters) return Promise.reject();
    if (!izvestajSubtype) return Promise.reject();
    return obrazacIzvestajiStore.getObrazacIzvestaj(izvestajSubtype, pagination);
  };

  const handleExportPdf = () => {
    obrazacIzvestajiStore.exportToPDF(izvestajSubtype);
  };

  const handleExportExcel = () => {
    obrazacIzvestajiStore.exportToExcel(izvestajSubtype);
  };

  /*
* This useEffect is only for Deo6IzvestajiEnum.REKALKULACIJA report.
* In this way we don't use the loadData method from TableData
component to call api,
* but call the api directly when the filters change.
*/

  useEffect(() => {
    obrazacIzvestajiStore.clearObrazacIzvestaj(izvestajSubtype);
  }, [pathname]);

  return (
    <Content
      header={
        <Row gutter={16}>
          <Col span={6}>
            <h1>{t('OBRAZAC_IZVESTAJI.OBRAZAC_IZVESTAJI')}</h1>
          </Col>
          <Col span={4}>
            <label>{t('OBRAZAC_IZVESTAJI.TIP_OBRASCA')}:</label>
            <Select
              value={izvestajType}
              options={OBRAZAC_IZVESTAJI_TYPE_OPTIONS}
              name="tip-obrasca"
              onChange={handleChangeIzvestajType}
            />
          </Col>
          <Col span={6}>
            <label>{t('OBRAZAC_IZVESTAJI.IZVESTAJ')}:</label>
            <Select
              value={izvestajSubtype}
              options={selectedIzvestajSubtypeOptions}
              name="pod-tip-obrasca"
              onChange={handleChangeIzvestajSubtype}
            />
          </Col>
          <Col span={8} className={styles.printButtonContainer}>
            <Row gutter={12}>
              <Col span={12}>
                <Button disabled={!hasFilters} type="primary" name="izvestaj-export-pdf" onClick={handleExportPdf}>
                  {t('OBRAZAC_IZVESTAJI.EXPORT_PDF')}
                </Button>
              </Col>
              <Col span={12}>
                <Button disabled={!hasFilters} type="primary" name="izvestaj-export-excel" onClick={handleExportExcel}>
                  {t('OBRAZAC_IZVESTAJI.EXPORT_EXCEL')}
                </Button>
              </Col>
            </Row>
          </Col>
        </Row>
      }
    >
      <ObrazacIzvestajiFilters izvestajSubtype={izvestajSubtype} />
      <TableData
        name="izvestaji"
        columns={columnsByIzvestajType as ColumnProps<unknown>[]}
        dataSource={obrazacIzvestajiStore.obrazacIzvestaj}
        refetchData={refetchData}
        storePagination={
          izvestajSubtype !== Obrazac5IzvestajiEnum.OTPAD_NE_OPASAN && !obrazacIzvestajiStore.obrazacDrugiIzvestaj
            ? obrazacIzvestajiStore.obrazacIzvestajPagination
            : undefined
        }
        rowKey="key"
      />
      {obrazacIzvestajiStore.obrazacDrugiIzvestaj && (
        <div style={{ marginTop: '3rem' }}>
          <TableData
            name="izvestaji"
            columns={columnsBySecondIzvestajType as ColumnProps<unknown>[]}
            dataSource={obrazacIzvestajiStore.obrazacDrugiIzvestaj}
          />
        </div>
      )}
    </Content>
  );
});
