import { Modal } from 'components/Modal/Modal';
import { observer } from 'mobx-react-lite';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { modalStore } from 'store';
import styles from './SoilModal.module.scss';
import { NewSoilPollution } from 'components/NewForms';
import { soilInitialState } from 'modules/sourcesOfPollution/soil/soil.constants';
import { soilStore } from 'modules/sourcesOfPollution/soil/soil.store';
import { Row } from 'antd';
import { IGetSoil } from 'modules/sourcesOfPollution/soil/soil.types';
import { IsActiveSwitch } from 'components/IsActiveSwitch/IsActiveSwitch';
import { facilityStore } from 'modules/facility/facility.store';

export const SoilModal: React.FC = observer(() => {
  const { t } = useTranslation();

  return (
    <Modal
      name="soil-modal"
      open={modalStore.getCurrentOpenedModal.includes('soil-modal')}
      className={`${styles.soil_modal}`}
      okButtonProps={{ style: { display: 'none' } }}
      cancelButtonProps={{ style: { display: 'none' } }}
      closable={true}
      onCancel={() => {
        soilStore.setSoil(soilInitialState);
        modalStore.removeAllModals();
      }}
      title={
        <Row justify={'space-between'} style={{ marginRight: '10px' }}>
          <h3>{t('POLLUTION.SOIL.TITLE')}</h3>
          {(soilStore.getSoil as IGetSoil).id && (
            <IsActiveSwitch
              isActive={soilStore.getSoil.aktivan}
              aktiviraj={soilStore.aktivirajTlo}
              deaktiviraj={soilStore.deaktivirajTlo}
              disabled={!facilityStore.getFacility.is_active}
            />
          )}
        </Row>
      }
      footer={null}
      destroyOnClose={true}
      maskClosable={true}
      width={'40%'}
    >
      <NewSoilPollution readOnly={true} />
    </Modal>
  );
});
