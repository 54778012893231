import { Form, FormInstance } from 'antd';
import { FormComponents } from 'components/FormComponents/FormComponents';
import { observer } from 'mobx-react-lite';
import { ISifarniciTypeOfForm } from 'modules/sifarnici/sifarnici.types';
import { PZV_PRODUCTS_FORM_OPTIONS } from 'modules/sifarnici/store/pzvCustomsAndProducers/pzvCustomsAndProducers.constants';
import { IGetPzvProducts } from 'modules/sifarnici/store/pzvCustomsAndProducers/pzvCustomsAndProducers.types';
import React, { useMemo } from 'react';

interface PzvProductsProps {
  parentForm: FormInstance<any>;
  formListName: string;
  selectedIndex: number;
}

export const PzvProductsFormModal: React.FC<PzvProductsProps> = observer(
  ({ parentForm, formListName, selectedIndex }) => {
    const form = Form.useFormInstance<IGetPzvProducts>();

    const components = useMemo(() => {
      return PZV_PRODUCTS_FORM_OPTIONS({ parentForm, formListName, selectedIndex });
    }, [Form.useWatch([], form)]);

    return (
      <>
        {components.map((item: ISifarniciTypeOfForm, index) => {
          return <FormComponents data={item} key={index} />;
        })}
      </>
    );
  }
);
