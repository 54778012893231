import TableData from 'components/TableData/TableData';
import type { ColumnsType } from 'antd/es/table';
import { useTranslation } from 'react-i18next';
import { EditOutlined } from '@ant-design/icons';
import { Button } from 'components/Button/Button';
import { observer } from 'mobx-react-lite';
import { IGetCompany } from 'modules/company/company.types';
import { useNavigate } from 'react-router-dom';
import { Tag } from 'antd';
import { CompanyStatus, companyInitialState } from 'modules/company/company.constants';
import { companyStore } from 'modules/company/company.store';
import { RoutePathEnum } from 'router/router.constants';

export const CompanyListTable: React.FC = observer(() => {
  const { t } = useTranslation();
  const navigate = useNavigate();

  const columns: ColumnsType<IGetCompany> = [
    {
      title: `${t('COMPANY.NAME')}`,
      dataIndex: 'naziv',
      width: '200px',
      key: 'naziv',
      fixed: 'left',
    },
    {
      title: `${t('COMPANY.PIB')}`,
      dataIndex: 'pib',
      width: '200px',
      key: 'pib',
    },
    {
      title: `${t('COMPANY.ENTERPRISE_ID_NUMBER')}`,
      dataIndex: 'maticni_broj',
      width: '200px',
      key: 'maticni_broj',
    },

    {
      title: `${t('COMPANY.LOCATION')}`,
      dataIndex: ['mesto', 'display_name'],
      key: 'mesto',
      width: '200px',
    },
    // {
    //   title: `${t('COMPANY.POST_NUMBER')}`,
    //   dataIndex: 'postanski_broj',
    //   key: 'postanski_broj',
    //   width: '200px',
    // },
    {
      title: `${t('COMPANY.ADDRESS')}`,
      dataIndex: 'ulica',
      key: 'ulica',
      width: '200px',
    },
    {
      title: `${t('COMPANY.ADDRESS_NUMBER')}`,
      dataIndex: 'kucni_broj',
      key: 'kucni_broj',
      width: '200px',
    },
    {
      title: `${t('COMPANY.USER_NAME')}`,
      dataIndex: 'ime_odgovorne_osobe',
      key: 'ime_odgovorne_osobe',
      width: '200px',
    },
    {
      title: `${t('COMPANY.USER_SURNAME')}`,
      dataIndex: 'prezime_odgovorne_osobe',
      key: 'prezime_odgovorne_osobe',
      width: '200px',
    },
    {
      title: `${t('COMPANY.PHONE')}`,
      dataIndex: 'telefon',
      key: 'telefon',
      width: '200px',
    },
    { title: `${t('COMPANY.EMAIL')}`, dataIndex: 'email', key: 'email', width: '200px' },
    {
      title: `${t('COMPANY.STATUS')}`,
      dataIndex: 'status',
      key: 'status',
      width: '200px',
      render: (_, company) => {
        return (
          <>
            {company?.status === CompanyStatus.U_PRIPREMI && (
              <Tag color="blue" style={{ alignItems: 'center', justifyContent: 'center', display: 'flex' }}>
                U pripremi
              </Tag>
            )}
            {company?.status === CompanyStatus.AKTIVAN && !company.popunjen_upitnik && (
              <Tag color="gold" style={{ alignItems: 'center', justifyContent: 'center', display: 'flex' }}>
                Aktivno / Upitnik
              </Tag>
            )}
            {company?.status === CompanyStatus.AKTIVAN && company.popunjen_upitnik && (
              <Tag color="green" style={{ alignItems: 'center', justifyContent: 'center', display: 'flex' }}>
                Aktivno
              </Tag>
            )}
          </>
        );
      },
    },

    {
      title: ``,
      key: 'operation',
      fixed: 'right',
      width: '75px',
      align: 'center',
      render: (_, company) => {
        return (
          <Button
            icon={<EditOutlined />}
            onClick={() => {
              navigate(`${RoutePathEnum.PREDUZECE}/${company.id}`);
              companyStore.setCompany(companyInitialState);
            }}
            name={'edit-company'}
          />
        );
      },
    },
  ];

  return (
    <TableData
      name="company"
      columns={columns}
      scrollConfig={['#companyHeader', '#companyFilters']}
      dataSource={[...companyStore.getCompaniesList]}
      refetchData={companyStore.fetchCompaniesList}
      storePagination={companyStore.companyPagination}
    />
  );
});
