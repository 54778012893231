import { ColumnsType } from 'antd/es/table';
import { InsideContentWrapper } from 'components/InsideContentWrapper/InsideContentWrapper';
import { EditButtonProps, RowEditPreviewButton } from 'components/RowEditPreviewButton/RowEditPreviewButton';
import TableData from 'components/TableData/TableData';
import { WizardObrazacListHeader } from 'components/WizardObrazacListHeader/WizardObrazacListHeader';
import { observer } from 'mobx-react-lite';
import { obrasciStore } from 'modules/obrasci/obrasci.store';
import { deo6ObrazacStore } from 'modules/obrasci/store/deo6/deo6.store';
import { IGetSkladistaDeo6 } from 'modules/obrasci/store/deo6/deo6.types';
import React from 'react';
import { useTranslation } from 'react-i18next';

const EditDeo6SkladisteButton: React.FC<EditButtonProps<IGetSkladistaDeo6>> = observer(({ record }) => {
  return (
    <RowEditPreviewButton
      name="deo6-skladiste"
      modalName="deo6-skladiste-modal"
      onClick={() => {
        deo6ObrazacStore.handleChange('index_id', record.indeksni_broj_otpada_id);
        if (record.indeksni_broj_otpada.weee && Number(deo6ObrazacStore.godina) >= 2020) {
          deo6ObrazacStore.handleChange('weee_categories', true);
        }
        deo6ObrazacStore.handleChange('skladiste_form', record);
      }}
      preview={obrasciStore.zakljucanObrazac}
    />
  );
});

export const Deo6SkladistaWizardTab: React.FC = observer(() => {
  const { t } = useTranslation();

  const baseColumns: ColumnsType<IGetSkladistaDeo6> = [
    {
      title: `${t('OBRASCI.KOM_1.MANAGEMENT.WASTE_INDEX_NUMBER_FROM_THE_WASTE_CATALOG')}`,
      width: 200,
      key: 'waste',
      render: (_: any, record: IGetSkladistaDeo6) => (
        <>{`${record.indeksni_broj_otpada.index_number} ${record.indeksni_broj_otpada.waste_sr.toUpperCase()}`}</>
      ),
    },
    {
      title: `${t('OBRASCI.DEO_6.UKUPNA_KOLICINA_OVD_VRSTE_OTPADA')}`,
      width: 200,
      dataIndex: 'kolicina_prvi_januar',
      key: 'kolicina_prvi_januar',
    },
    {
      title: `${t('COMPANY.ADDITIONALLY')}`,
      key: 'operation',
      fixed: 'right',
      width: 150,
      align: 'center',
      render: (_, record: IGetSkladistaDeo6) => <EditDeo6SkladisteButton record={record} />,
    },
  ];

  return (
    <>
      <div id="skladistePoruka">
        <h3 style={{ fontWeight: 'bold' }}>{t('OBRASCI.DEO_6.OVAJ_DEO_POPUNJAVA')}</h3>
        <h4 style={{ fontWeight: 'bold' }}>{t('OBRASCI.DEO_6.ZATECENO_STANJE_NA_PRIVREMENOM')}</h4>
      </div>
      <InsideContentWrapper
        header={<WizardObrazacListHeader name={'deo6-skladiste-form'} modalName="deo6-skladiste-modal" />}
      >
        <TableData
          scrollConfig={['#obrazacWizardHeader', '#obrazacTableHeader', '#skladistePoruka']}
          name="skladista-deo6"
          columns={baseColumns}
          dataSource={[...deo6ObrazacStore.initialState.skladiste]}
        />
      </InsideContentWrapper>
    </>
  );
});
