import { Col, Form } from 'antd';
import { FormInput } from 'components/FormInput/FormInput';
import { FormInputNumber } from 'components/FormInputNumber/FormInputNumber';
import { FormSearchInfiniteScroll } from 'components/FormSearchInfiniteScroll/FormSearchInfiniteScroll';
import { observer } from 'mobx-react-lite';
import { MORE_THEN_0_IS_REQUIRED, REQUIRED_FIELD_RULE } from 'modules/nriz/nriz.constants';
import { obrazac3Store } from 'modules/obrasci/store/obrazac3/obrazac3.store';
import {
  IGetObrazac3GlavniIndustrijskiPotrosaciVode,
  Obrazac3Sistem,
} from 'modules/obrasci/store/obrazac3/obrazac3.types';
import { ISifarnikInitial } from 'modules/sifarnici/sifarnici.types';
import { useTranslation } from 'react-i18next';
import { GlavniIndustrijskiPotrosaciProps } from '../../NewObrazac3VodovodniSistemiForm/Obrazac3GlavniIndustrijskiPotrosaciTable/Obrazac3GlavniIndustrijskiPotrosaciTable';
import { FormCheckbox } from 'components/FormCheckbox/FormCheckbox';
import { useEffect } from 'react';
import { obrasciStore } from 'modules/obrasci/obrasci.store';

export const NewObrazac3GlavniIndustrijskiPotrosaciForm: React.FC<GlavniIndustrijskiPotrosaciProps> = observer(
  ({ sistem }) => {
    const { t } = useTranslation();
    const form = Form.useFormInstance();

    const defaultSifarnikPretezneDelatnosntiOption: ISifarnikInitial | undefined = obrazac3Store
      .industrijski_potrosaci_form.sifra_pretezne_delatnosti_id
      ? {
          label: (obrazac3Store.industrijski_potrosaci_form as IGetObrazac3GlavniIndustrijskiPotrosaciVode)
            .sifra_pretezne_delatnosti.name_sr,
          value: obrazac3Store.industrijski_potrosaci_form.sifra_pretezne_delatnosti_id,
        }
      : undefined;

    const renderIsporucenaVodaLabel = () => {
      switch (sistem) {
        case Obrazac3Sistem.VODOVODNI:
          return t('OBRASCI.OBRAZAC3.KOLICINA_ISPORUCENE');
        case Obrazac3Sistem.KANALIZACIONI:
          return t('OBRASCI.OBRAZAC3.KOLICINA_ISPUSTENE');
      }
    };

    useEffect(() => {
      form.setFieldValue(sistem, true);
    }, []);

    const readOnly = obrasciStore.zakljucanObrazac;

    return (
      <Col span={24}>
        <FormInput
          inputName={'naziv'}
          name={'naziv'}
          label={t('OBRASCI.OBRAZAC3.NAZIV_PREDUZECA')}
          labelCol={{ span: 12 }}
          wrapperCol={{ span: 12 }}
          rules={[REQUIRED_FIELD_RULE(true)]}
          readOnly={readOnly}
        />
        <FormSearchInfiniteScroll
          showSearch={true}
          formName={'sifra_pretezne_delatnosti_id'}
          fetchOptinsList={obrazac3Store.fetchSifarnikPretezneDelatnostni}
          labelOptionAccessor="name_sr"
          valueOptionAccessor="id"
          inputName={'sifra_pretezne_delatnosti_id'}
          label={t('COMPANY.PRINCIPAL_BUSINESS_ACTIVITY_CODE')}
          labelCol={{ span: 12 }}
          wrapperCol={{ span: 12 }}
          rules={[REQUIRED_FIELD_RULE(true)]}
          defaultOption={defaultSifarnikPretezneDelatnosntiOption}
          placeholder={''}
          readOnly={readOnly}
        />
        <FormInputNumber
          name="kolicina_isporucene_vode"
          label={renderIsporucenaVodaLabel()}
          placeholder={''}
          inputName={'kolicina_isporucene_vode'}
          colon={true}
          rules={MORE_THEN_0_IS_REQUIRED}
          labelCol={{ span: 12 }}
          wrapperCol={{ span: 12 }}
          readOnly={readOnly}
        />
        <FormCheckbox name={sistem} hidden />
      </Col>
    );
  }
);
