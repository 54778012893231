import { Row } from 'antd';
import React from 'react';
import { AddNewButton } from 'components/Buttons/AddNewButton/AddNewButton';
import { useTranslation } from 'react-i18next';
import styles from './ZastitaBiljaHeader.module.scss';
import { modalStore } from 'store';
import { pzv1ObrazacStore } from 'modules/obrasci/store/pzv1/pzv1.store';
import { obrasciStore } from 'modules/obrasci/obrasci.store';
import { observer } from 'mobx-react-lite';

export const ZastitaBiljaHeader = observer(() => {
  const { t } = useTranslation();

  return (
    <Row justify={'space-between'} align={'bottom'} className={styles.header}>
      <h3>{t('OBRASCI.PZV_1.PLANT_PROTECTION.TITLE')}</h3>
      {(!obrasciStore.zakljucanObrazac || pzv1ObrazacStore.getDozvoljeneKorekcijePoTipu('korekcija_zastite_bilja')) && (
        <AddNewButton
          label="Dodaj"
          name="zastita-bilja"
          disabled={
            obrasciStore.zakljucanObrazac && !pzv1ObrazacStore.getDozvoljeneKorekcijePoTipu('korekcija_zastite_bilja')
          }
          onButtonClick={() => {
            modalStore.changeModalName(`zastita-bilja-modal`);
          }}
        />
      )}
    </Row>
  );
});
