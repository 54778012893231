import { FormInstance } from 'antd';

import {
  MIN_AND_MAX_YEAR,
  REQUIRED_FIELD_RULE_NO_MSG,
  SELECT_AT_LEAST_ONE_FIELD_RULE,
  SELECT_DATE_FROM_EQUAL_YEAR_RULE,
  SELECT_GREATHER_DATE_RULE,
} from 'modules/nriz/nriz.constants';
import {
  CheckboxSifarniciFormProps,
  DateSifarniciFormProps,
  ISifarniciTypeOfForm,
  InputSifarniciFormProps,
  SIFARNICI_ROUTES,
  SearchScrollSifarniciFormProps,
  SelectSifarniciFormProps,
  SwitchSifarniciFormProps,
} from 'modules/sifarnici/sifarnici.types';
import { IGetWeeeCategories } from 'modules/sifarnici/store/weeeCategories/weeeCategories.types';
import { RuleConfig } from '../../typescript';
import { IGetOperatorsPackageWasteManage } from '../sifarnici/store/operatorsPackageWasteManage/operatorsPackageWasteManage.types';
import {
  Aao1IzvestajiEnum,
  Aao2IzvestajiEnum,
  Deo6IzvestajiEnum,
  Dep1IzvestajiEnum,
  Dep2IzvestajiEnum,
  Gio2IzvestajiEnum,
  Gio3IzvestajiEnum,
  Gio4IzvestajiEnum,
  Gio5IzvestajiEnum,
  IzvestajiSubtypes,
  Kom1IzvestajiEnum,
  Obrazac1IzvestajiEnum,
  Obrazac2IzvestajiEnum,
  Obrazac3IzvestajiEnum,
  Obrazac5IzvestajiEnum,
  Ptp2IzvestajiEnum,
  PzvIzvestajiEnum,
} from './obrazacIzvestaji.types';

// Todo: replace with filter component specific type (remove one from sifarnici.types, make componetn specific)
export type FormConfig = (form: FormInstance) => ISifarniciTypeOfForm[];
export type FormConfigItemWithRules<FormConfigType = ISifarniciTypeOfForm> = (rules?: RuleConfig[]) => FormConfigType;
export type FormConfigItemWithForm<FormConfigType = ISifarniciTypeOfForm> = (
  form: FormInstance,
  rules?: RuleConfig[]
) => FormConfigType;

// declaration constants for filters
const YEAR_FILTER: FormConfigItemWithRules<DateSifarniciFormProps> = (rules = []) => ({
  type_of_form: 'date',
  name: 'godina',
  label: 'Godina',
  sortName: 'godina',
  picker: 'year',
  rules: [REQUIRED_FIELD_RULE_NO_MSG, ...rules],
});

const YEAR_FROM_FILTER: FormConfigItemWithForm<DateSifarniciFormProps> = (form, rules = []) => ({
  type_of_form: 'date',
  name: 'godina_od',
  label: 'Period od godine',
  picker: 'year',
  rules: [
    REQUIRED_FIELD_RULE_NO_MSG,
    SELECT_GREATHER_DATE_RULE(
      form.getFieldValue('godina_do'),
      'Period od godine mora biti manji ili jednak vrednosti Period do godine'
    ),
    ...rules,
  ],
});

const YEAR_TO_FILTER: FormConfigItemWithForm<DateSifarniciFormProps> = (form, rules = []) => ({
  type_of_form: 'date',
  name: 'godina_do',
  label: 'Period do godine',
  picker: 'year',
  rules: [
    REQUIRED_FIELD_RULE_NO_MSG,
    SELECT_GREATHER_DATE_RULE(
      form.getFieldValue('godina_od'),
      'Period do godine mora biti veći ili jednak vrednosti Period od godine',
      '>'
    ),
    ...rules,
  ],
});

const DATE_FILTER: FormConfigItemWithForm<DateSifarniciFormProps> = (form, rules = []) => ({
  type_of_form: 'date',
  name: 'datum',
  label: 'Datum unosa',
  sortName: 'datum',
  picker: 'date',
  format: 'DD-MM-YYYY',
  rules: [REQUIRED_FIELD_RULE_NO_MSG, ...rules],
});

const DATE_FROM_FILTER: FormConfigItemWithForm<DateSifarniciFormProps> = (form, rules = []) => ({
  type_of_form: 'date',
  name: 'datum_od',
  label: 'Datum od',
  sortName: 'datum_od',
  picker: 'date',
  format: 'DD-MM-YYYY',
  rules: [...rules],
  onChange: () => form.getFieldValue('datum_do') && form.validateFields(['datum_do']),
});

const DATE_TO_FILTER: FormConfigItemWithForm<DateSifarniciFormProps> = (form, rules = []) => ({
  type_of_form: 'date',
  name: 'datum_do',
  label: 'Datum do',
  sortName: 'datum_do',
  picker: 'date',
  format: 'DD-MM-YYYY',
  rules: [...rules],
});

const PREDUZECE_FILTER: FormConfigItemWithForm<SearchScrollSifarniciFormProps> = (
  form: FormInstance,
  rules: RuleConfig[] = []
) => ({
  type_of_form: 'searchScroll',
  name: 'preduzece_id',
  formName: 'preduzece_id',
  label: 'Preduzeće',
  sortName: 'preduzece_id',
  labelOptionAccessor: ['naziv'],
  valueOptionAccessor: 'id',
  fetchOptinsList: SIFARNICI_ROUTES.PREDUZECE,
  placeholder: 'Preduzeće',
  onChange: () => form.resetFields(['postrojenje_id']),
  ...(rules && { rules }),
});

const POSTROJENJE_FILTER: FormConfigItemWithForm<SearchScrollSifarniciFormProps> = form => ({
  hideInput: !form.getFieldValue('preduzece_id'),
  type_of_form: 'searchScroll',
  name: 'postrojenje_id',
  formName: 'postrojenje_id',
  label: 'Postrojenje',
  sortName: 'postrojenje_id',
  labelOptionAccessor: ['naziv_postrojenja'],
  valueOptionAccessor: 'id',
  fetchOptinsList: SIFARNICI_ROUTES.POSTROJENJE,
  filtersForFetch: {
    preduzece_id: form.getFieldValue('preduzece_id'),
  },
  placeholder: 'Postrojenje',
  onChange: () => form.resetFields(['izvor_zagadjenja_vode_id', 'izvor_zagadjenja_vazduha_id']),
});

const OPSTINA_FILTER: SearchScrollSifarniciFormProps = {
  type_of_form: 'searchScroll',
  formName: 'opstina_id',
  fetchOptinsList: SIFARNICI_ROUTES.MUNICIPALITY_CODE,
  labelOptionAccessor: ['name'],
  valueOptionAccessor: 'id',
  name: 'opstina_id',
  label: 'Opština',
  sortName: 'opstina_id',
  placeholder: 'Opstina',
};

const PRTR_FILTER: CheckboxSifarniciFormProps = {
  type_of_form: 'switch',
  name: 'prtr',
  label: 'PRTR',
  sortName: 'prtr',
};

const VRSTA_RECIPIJENTA_FILTER: SearchScrollSifarniciFormProps = {
  type_of_form: 'searchScroll',
  inputName: 'vrsta_recipijenta_id',
  formName: 'vrsta_recipijenta_id',
  fetchOptinsList: SIFARNICI_ROUTES.RECIPIENT_TYPE,
  labelOptionAccessor: ['type_sr'],
  valueOptionAccessor: 'id',
  name: 'vrsta_recipijenta_id',
  label: 'Vrsta recipijenta',
  sortName: 'vrsta_recipijenta_id',
  placeholder: 'Vrsta recipijenta',
};

const INDEKSNI_BROJ_FILTER: SearchScrollSifarniciFormProps = {
  type_of_form: 'searchScroll',
  formName: 'indeksni_broj_id',
  fetchOptinsList: SIFARNICI_ROUTES.WASTE_CATALOGUE,
  labelOptionAccessor: ['index_number', 'waste_sr'],
  valueOptionAccessor: 'id',
  inputName: 'indeksni_broj_otpada_id',
  label: 'Indeksni broj',
  placeholder: 'Indeksni broj',
  sortName: 'indeksni_indeksni_broj_otpada',
};

const ZAGADJUJUCA_MATERIJA_FILTER: FormConfigItemWithForm<SearchScrollSifarniciFormProps> = () => ({
  sortName: 'zagadjujuca_materija_id',
  type_of_form: 'searchScroll',
  label: 'Zagađujuća materija',
  formName: 'zagadjujuca_materija_id',
  inputName: 'zagadjujuca_materija_id',
  fetchOptinsList: SIFARNICI_ROUTES.POLLUTANT_CODE,
  filtersForFetch: {},
  labelOptionAccessor: ['name_sr'],
  valueOptionAccessor: 'id',
  placeholder: 'Zagađujuća materija',
});

const IZVOR_ZAGADJENJA_VODE_FILTER: FormConfigItemWithForm<SearchScrollSifarniciFormProps> = form => ({
  hideInput: !form.getFieldValue('postrojenje_id'),
  type_of_form: 'searchScroll',
  name: 'izvor_zagadjenja_vode_id',
  formName: 'izvor_zagadjenja_vode_id',
  label: 'Ispust',
  sortName: 'izvor_zagadjenja_vode',
  labelOptionAccessor: ['naziv_ispusta'],
  valueOptionAccessor: 'id',
  fetchOptinsList: SIFARNICI_ROUTES.IZVOR_ZAGADJENJA_VODE,
  filtersForFetch: {
    postrojenje_id: form.getFieldValue('postrojenje_id'),
  },
  placeholder: 'Ispust',
});

const SIFRA_PRETEZNE_DELATNOSTI_FILTER: SearchScrollSifarniciFormProps = {
  type_of_form: 'searchScroll',
  formName: 'sifra_pretezne_delatnosti_id',
  fetchOptinsList: SIFARNICI_ROUTES.SIFARNIK_PRETEZNE_DELATNOSTI,
  labelOptionAccessor: ['name_sr'],
  valueOptionAccessor: 'id',
  inputName: 'sifra_pretezne_delatnosti_id',
  label: 'Šifra pretežne delatnosti',
  placeholder: 'Šifra pretežne delatnosti',
  sortName: 'sifra_pretezne_delatnosti',
};

const NIVO_FILTER: SelectSifarniciFormProps = {
  type_of_form: 'select',
  name: 'nivo',
  label: 'Nivo',
  options: [
    {
      label: 1,
      value: 1,
    },
    {
      label: 2,
      value: 2,
    },
    {
      label: 3,
      value: 3,
    },
  ],
};

const KATEGORIJA_EE_OTPADA_FILTER: SearchScrollSifarniciFormProps = {
  type_of_form: 'searchScroll',
  formName: 'kategorija_ee_otpada_id',
  fetchOptinsList: SIFARNICI_ROUTES.WEEE_CATEGORIES,
  labelOptionAccessor: ['the_order', 'category_name_sr'],
  valueOptionAccessor: 'id',
  inputName: 'kategorija_ee_otpada_id',
  label: 'Kategorija EE otpada',
  placeholder: 'Kategorija EE otpada',
  optionsMaker: (options: IGetWeeeCategories[]) => {
    return options.map(weeeCategory => ({
      label: `${weeeCategory.the_order} ${weeeCategory.category_name_sr}`,
      value: weeeCategory.id,
    }));
  },
};

export const PZV_DETERDZENT_DJUBRIVA_SREDSTVA_FILTER_GROUP: (
  form: FormInstance
) => CheckboxSifarniciFormProps[] = form => [
  {
    name: 'deterdzenti',
    type_of_form: 'switch',
    label: 'Deterdženti',
    rules: [
      SELECT_AT_LEAST_ONE_FIELD_RULE(
        ['deterdzenti', 'mineralna_djubriva', 'zastita_bilja'],
        form,
        'Potrebno je odabrati barem jedno polje'
      ),
    ],
  },
  {
    name: 'mineralna_djubriva',
    type_of_form: 'switch',
    label: 'Mineralna đubriva',
    rules: [
      SELECT_AT_LEAST_ONE_FIELD_RULE(
        ['deterdzenti', 'mineralna_djubriva', 'zastita_bilja'],
        form,
        'Potrebno je odabrati barem jedno polje'
      ),
    ],
  },
  {
    name: 'zastita_bilja',
    type_of_form: 'switch',
    label: 'Sredstva za zaštitu bilja',
    rules: [
      SELECT_AT_LEAST_ONE_FIELD_RULE(
        ['deterdzenti', 'mineralna_djubriva', 'zastita_bilja'],
        form,
        'Potrebno je odabrati barem jedno polje'
      ),
    ],
  },
];

const PIB_PREDUZECA_FILTER: SearchScrollSifarniciFormProps = {
  type_of_form: 'searchScroll',
  name: 'pib_id',
  formName: 'pib_id',
  label: 'Pib preduzeća',
  sortName: 'pib_id',
  labelOptionAccessor: ['pib'],
  valueOptionAccessor: 'pib',
  fetchOptinsList: SIFARNICI_ROUTES.PREDUZECE,
  placeholder: 'Pib preduzeća',
};

const PIB_ILI_MB_PREDUZECA_FUNC_FILTER = (
  label: string,
  formName: string,
  field: string
): SearchScrollSifarniciFormProps => ({
  type_of_form: 'searchScroll',
  name: formName,
  formName,
  label,
  labelOptionAccessor: [field],
  valueOptionAccessor: field,
  fetchOptinsList: SIFARNICI_ROUTES.PREDUZECE,
  placeholder: label,
});

const OPASAN_FILTER: SwitchSifarniciFormProps = {
  type_of_form: 'switch',
  name: 'opasan',
  label: 'Opasan',
};

const Y_LIST_FILTER: SearchScrollSifarniciFormProps = {
  type_of_form: 'searchScroll',
  inputName: 'y_oznaka_id',
  formName: 'y_oznaka_id',
  fetchOptinsList: SIFARNICI_ROUTES.Y_LIST,
  labelOptionAccessor: ['description_sr'],
  valueOptionAccessor: 'id',
  name: 'y_oznaka_id',
  label: 'Y oznaka',
  sortName: 'y_oznaka_id',
  placeholder: 'Y oznaka',
};

const OPERATER_FILTER: SelectSifarniciFormProps = {
  type_of_form: 'select',
  name: 'operater',
  label: 'Operater',
  placeholder: 'Operater',
  options: [
    { label: 'Količine prenete na operatera', value: 'kolicine_prenete_na_operatera' },
    { label: 'Količine koje nisu prenete na operatera', value: 'kolicine_koje_nisu_prenete_na_operatera' },
    { label: 'Sve vrste ambalaže', value: 'sve_vrste_ambalaze' },
  ],
};

const OPERATER_SEARCH_FILTER: SearchScrollSifarniciFormProps = {
  type_of_form: 'searchScroll',
  formName: 'operater_id',
  fetchOptinsList: SIFARNICI_ROUTES.OPERATORS_PACKAGE_WASTE_MANAGE,
  labelOptionAccessor: ['naziv'],
  valueOptionAccessor: 'company_id',
  inputName: 'operater_id',
  label: 'Operater',
  placeholder: 'Operater',
  optionsMaker: (options: IGetOperatorsPackageWasteManage[]) => {
    return options.map((item: IGetOperatorsPackageWasteManage) => ({
      label: item?.preduzece?.naziv || '',
      value: item.company_id,
    }));
  },
  customParamsForSearch: 'preduzece__search',
};

const DOSTAVLJENO_OD_FILTERS: DateSifarniciFormProps = {
  type_of_form: 'date',
  name: 'dostavljeno_od',
  label: 'Dostavljeno od',
  sortName: 'dostavljeno_od',
  picker: 'date',
};

const DOSTAVLJENO_DO_FILTERS: DateSifarniciFormProps = {
  type_of_form: 'date',
  name: 'dostavljeno_do',
  label: 'Dostavljeno do',
  sortName: 'dostavljeno_do',
  picker: 'date',
};

const PIB_OPERATERA_FILTER: SearchScrollSifarniciFormProps = {
  type_of_form: 'searchScroll',
  formName: 'pib_operatera_id',
  fetchOptinsList: SIFARNICI_ROUTES.OPERATORS_PACKAGE_WASTE_MANAGE,
  labelOptionAccessor: ['pib'],
  valueOptionAccessor: 'company_id',
  inputName: 'pib_operatera_id',
  label: 'PIB operatera',
  placeholder: 'Pib operatera',
  sortName: 'pib_operatera_id',
  optionsMaker: (options: IGetOperatorsPackageWasteManage[]) => {
    return options.map((item: IGetOperatorsPackageWasteManage) => ({
      label: item?.preduzece?.pib,
      value: item.company_id,
    }));
  },
};

const OBRAZAC_1_FILTER: (form: FormInstance) => SwitchSifarniciFormProps = form => ({
  type_of_form: 'switch',
  name: 'obrazac1',
  label: 'Obrazac 1',
  onChange: (value: boolean) => value && form.setFieldValue('lriz', false),
});

const OBRAZAC_2_FILTER: (form: FormInstance) => SwitchSifarniciFormProps = form => ({
  type_of_form: 'switch',
  name: 'obrazac2',
  label: 'Obrazac 2',
  onChange: (value: boolean) => value && form.setFieldValue('lriz', false),
});

const OBRAZAC_3_FILTER: (form: FormInstance) => SwitchSifarniciFormProps = form => ({
  type_of_form: 'switch',
  name: 'obrazac3',
  label: 'Obrazac 3',
  onChange: (value: boolean) => value && form.setFieldValue('lriz', false),
});

const OBRAZAC_5_FILTER: (form: FormInstance) => SwitchSifarniciFormProps = form => ({
  type_of_form: 'switch',
  name: 'obrazac5',
  label: 'Obrazac 5',
  onChange: (value: boolean) => value && form.setFieldValue('lriz', false),
});

const LRIZ_FILTER: (form: FormInstance) => SwitchSifarniciFormProps = form => ({
  type_of_form: 'switch',
  name: 'lriz',
  label: 'LRIZ',
  onChange: (value: boolean) => {
    if (value) {
      form.setFieldValue('obrazac1', false);
      form.setFieldValue('obrazac2', false);
      form.setFieldValue('obrazac3', false);
      form.setFieldValue('obrazac5', false);
    }
  },
});

const GVE_FILTER: SwitchSifarniciFormProps = {
  type_of_form: 'switch',
  name: 'gve',
  label: 'GVE',
};

const OTV_FILTER: SwitchSifarniciFormProps = {
  type_of_form: 'switch',
  name: 'otv',
  label: 'OTV',
};

const GIO_1_FILTER: SwitchSifarniciFormProps = {
  type_of_form: 'switch',
  name: 'gio1',
  label: 'GIO 1',
};

const IZVOR_OBRAZAC_2_FILTER: FormConfigItemWithForm<SearchScrollSifarniciFormProps> = form => ({
  hideInput: !form.getFieldValue('postrojenje_id'),
  type_of_form: 'searchScroll',
  name: 'izvor_zagadjenja_vazduha_id',
  formName: 'izvor_zagadjenja_vazduha_id',
  label: 'Izvor',
  sortName: 'izvor_zagadjenja_vazduha',
  labelOptionAccessor: ['naziv_izvora'],
  valueOptionAccessor: 'id',
  fetchOptinsList: SIFARNICI_ROUTES.IZVOR_ZAGADJENJA_VAZDUHA,
  filtersForFetch: {
    postrojenje_id: form.getFieldValue('postrojenje_id'),
  },
  placeholder: 'Izvor',
});

const AKTIVNOST_FILTER: SearchScrollSifarniciFormProps = {
  type_of_form: 'searchScroll',
  formName: 'aktivnost_id',
  fetchOptinsList: SIFARNICI_ROUTES.AKTIVNOST_1_AKTIVNOST_2,
  labelOptionAccessor: ['name'],
  valueOptionAccessor: 'id',
  inputName: 'aktivnost_id',
  label: 'Aktivnost',
  placeholder: 'Aktivnost',
  sortName: 'aktivnost_id',
};

const R_OZNAKA_FILTER: SearchScrollSifarniciFormProps = {
  type_of_form: 'searchScroll',
  name: 'r_oznaka_id',
  formName: 'r_oznaka_id',
  label: 'R oznaka',
  sortName: 'r_oznaka_id',
  labelOptionAccessor: ['name'],
  valueOptionAccessor: 'id',
  fetchOptinsList: SIFARNICI_ROUTES.DR_LIST,
  placeholder: 'R oznaka',
  filtersForFetch: {
    name__ilike: 'R',
  },
};

const D_OZNAKA_FILTER: SearchScrollSifarniciFormProps = {
  type_of_form: 'searchScroll',
  name: 'd_oznaka_id',
  formName: 'd_oznaka_id',
  label: 'D oznaka',
  sortName: 'd_oznaka_id',
  labelOptionAccessor: ['name'],
  valueOptionAccessor: 'id',
  fetchOptinsList: SIFARNICI_ROUTES.DR_LIST,
  placeholder: 'D oznaka',
  filtersForFetch: {
    name__ilike: 'D',
  },
};

const R_ILI_D_OZNAKA_FILTER: SearchScrollSifarniciFormProps = {
  type_of_form: 'searchScroll',
  name: 'd_ili_r_oznaka_id',
  formName: 'd_ili_r_oznaka_id',
  label: 'D ili R Oznaka',
  sortName: 'd_ili_r_oznaka_id',
  labelOptionAccessor: ['name'],
  valueOptionAccessor: 'id',
  fetchOptinsList: SIFARNICI_ROUTES.DR_LIST,
  placeholder: 'D ili R Oznaka',
  filtersForFetch: {
    name__like: 'R,D',
  },
};

const OPSTINA_BY_PREDUZECE_AND_POSTRIJENJE_FILTER: FormConfigItemWithForm<SearchScrollSifarniciFormProps> = form => ({
  type_of_form: 'searchScroll',
  formName: 'opstina_id',
  fetchOptinsList: SIFARNICI_ROUTES.MUNICIPALITY_CODE,
  labelOptionAccessor: ['name'],
  valueOptionAccessor: 'id',
  name: 'opstina_id',
  label: 'Opština',
  sortName: 'opstina_id',
  placeholder: 'Opstina',
  filtersForFetch: {
    // TODO check this again
    preduzece_id: form.getFieldValue('preduzece_id'),
    postrojenje_id: form.getFieldValue('postrojenje_id'),
  },
});

const NESANITARNA_DEPONIJA_FILTER: FormConfigItemWithForm<SearchScrollSifarniciFormProps> = form => ({
  type_of_form: 'searchScroll',
  filtersForFetch: {
    preduzece_id: form.getFieldValue('preduzece_id'),
  },
  formName: 'nesanitarna_deponija_id',
  fetchOptinsList: SIFARNICI_ROUTES.NESANITARNA_DEPONIJA,
  labelOptionAccessor: ['naziv'],
  valueOptionAccessor: 'id',
  disabledAccessor: 'is_active',
  label: `Nesanitarna deponije`,
  sortName: 'nesanitarna_deponija_id',
  placeholder: 'Nesanitarna deponije',
  hideInput: !form.getFieldValue('preduzece_id'),
});

const TRANSAKCIJE_FILTER: SelectSifarniciFormProps = {
  type_of_form: 'select',
  name: 'transakcije',
  label: 'Transakcije',
  placeholder: 'Transakcije',
  options: [
    { label: 'Stanje na skladištu 1. januara', value: 'stanje_na_skladistu' },
    { label: 'Preuzet otpad', value: 'preuzet_otpad' },
    { label: 'Predat otpad', value: 'predat_otpad' },
    { label: 'Ponovna upotreba otpada', value: 'ponovna_upotreba_otpada' },
  ],
  rules: [REQUIRED_FIELD_RULE_NO_MSG],
  mode: 'multiple',
};

const GORIVO_FILTER: SearchScrollSifarniciFormProps = {
  type_of_form: 'searchScroll',
  name: 'gorivo_id',
  formName: 'gorivo_id',
  label: 'Gorivo',
  sortName: 'gorivo',
  labelOptionAccessor: ['name_sr'],
  valueOptionAccessor: 'id',
  fetchOptinsList: SIFARNICI_ROUTES.FUEL_TYPE,
  placeholder: 'Gorivo',
};

const SLIV_FILTER: SearchScrollSifarniciFormProps = {
  type_of_form: 'searchScroll',
  name: 'sliv_id',
  formName: 'sliv_id',
  label: 'Sliv',
  sortName: 'sliv',
  labelOptionAccessor: ['basin_name'],
  valueOptionAccessor: 'id',
  fetchOptinsList: SIFARNICI_ROUTES.SLIV,
  placeholder: 'Sliv',
};

const NAZIV_RECIPIJENTA_FILTER: InputSifarniciFormProps = {
  type_of_form: 'input',
  name: 'naziv_recipijenta',
  inputName: 'naziv_recipijenta',
  label: 'Naziv recipijenta',
  placeholder: 'Naziv recipijenta',
};

// filters

//#region OBRAZAC 1
export const OBRAZAC_1_GORIVA_FILTERS: FormConfig = form => [
  YEAR_FILTER(),
  PREDUZECE_FILTER(form),
  POSTROJENJE_FILTER(form),
  OPSTINA_FILTER,
  GORIVO_FILTER,
  OBRAZAC_1_FILTER(form),
  LRIZ_FILTER(form),
];

export const OBRAZAC_1_PROIZVODI_FILTERS: FormConfig = form => [
  YEAR_FILTER(),
  PREDUZECE_FILTER(form),
  POSTROJENJE_FILTER(form),
  OPSTINA_FILTER,
  AKTIVNOST_FILTER,
  OBRAZAC_1_FILTER(form),
  LRIZ_FILTER(form),
];

export const OBRAZAC_1_SIROVINE_FILTERS: FormConfig = form => [
  YEAR_FILTER(),
  PREDUZECE_FILTER(form),
  POSTROJENJE_FILTER(form),
  OPSTINA_FILTER,
  OBRAZAC_1_FILTER(form),
  LRIZ_FILTER(form),
];

export const OBRAZAC_1_OPSTE_FILTERS: FormConfig = form => [
  YEAR_FILTER(),
  PREDUZECE_FILTER(form),
  POSTROJENJE_FILTER(form),
  OPSTINA_FILTER,
  AKTIVNOST_FILTER,
  SIFRA_PRETEZNE_DELATNOSTI_FILTER,
  OBRAZAC_1_FILTER(form),
  LRIZ_FILTER(form),
];

//#endregion OBRAZAC 1

//#region OBRAZAC 2
export const OBRAZAC_2_ZAGADLJIVE_MATERIJE_FILTERS: FormConfig = form => [
  YEAR_FILTER(),
  PREDUZECE_FILTER(form),
  POSTROJENJE_FILTER(form),
  PRTR_FILTER,
  OPSTINA_FILTER,
  IZVOR_OBRAZAC_2_FILTER(form),
  ZAGADJUJUCA_MATERIJA_FILTER(form),
  AKTIVNOST_FILTER,
  OBRAZAC_2_FILTER(form),
  LRIZ_FILTER(form),
  GVE_FILTER,
];

export const OBRAZAC_2_GORIVO_FILTERS: FormConfig = form => [
  YEAR_FILTER(),
  PREDUZECE_FILTER(form),
  POSTROJENJE_FILTER(form),
  PRTR_FILTER,
  OPSTINA_FILTER,
  IZVOR_OBRAZAC_2_FILTER(form),
  AKTIVNOST_FILTER,
  GORIVO_FILTER,
  OBRAZAC_2_FILTER(form),
  LRIZ_FILTER(form),
  GVE_FILTER,
];

export const OBRAZAC_2_OPSTE_FILTERS: FormConfig = form => [
  YEAR_FILTER(),
  PREDUZECE_FILTER(form),
  POSTROJENJE_FILTER(form),
  PRTR_FILTER,
  OPSTINA_FILTER,
  IZVOR_OBRAZAC_2_FILTER(form),
  AKTIVNOST_FILTER,
  OBRAZAC_2_FILTER(form),
  LRIZ_FILTER(form),
  GVE_FILTER,
];

export const OBRAZAC_2_PREGLED_PO_GODINAMA_FILTERS: FormConfig = form => [
  YEAR_FROM_FILTER(form),
  YEAR_TO_FILTER(form),
  PREDUZECE_FILTER(form),
  POSTROJENJE_FILTER(form),
  PRTR_FILTER,
  OPSTINA_FILTER,
  ZAGADJUJUCA_MATERIJA_FILTER(form),
  OBRAZAC_2_FILTER(form),
  LRIZ_FILTER(form),
  GVE_FILTER,
];

export const OBRAZAC_2_IZVORI_PO_GODINAMA_FILTERS: FormConfig = form => [
  YEAR_FROM_FILTER(form),
  YEAR_TO_FILTER(form),
  PREDUZECE_FILTER(form),
  POSTROJENJE_FILTER(form),
  PRTR_FILTER,
  OPSTINA_FILTER,
  ZAGADJUJUCA_MATERIJA_FILTER(form),
  OBRAZAC_2_FILTER(form),
  LRIZ_FILTER(form),
  GVE_FILTER,
];

export const OBRAZAC_2_PRTR_KATEGORIJE_FILTERS = [YEAR_FILTER()];

export const OBRAZAC_2_KONTROLA_UNOSA_FILTERS: FormConfig = form => [
  DATE_FROM_FILTER(form),
  DATE_TO_FILTER(form),
  YEAR_FROM_FILTER(form),
  YEAR_TO_FILTER(form),
];

export const OBRAZAC_2_PRTR_PREGLED_PO_GODINAMA_FILTERS: FormConfig = form => [
  DATE_FROM_FILTER(form),
  DATE_TO_FILTER(form),
];
//#endregion OBRAZAC 2

//#region OBRAZAC 3
export const OBRAZAC_3_ZAGADLJIVE_MATERIJE_FILTERS: FormConfig = form => [
  YEAR_FILTER(),
  PREDUZECE_FILTER(form),
  POSTROJENJE_FILTER(form),
  PRTR_FILTER,
  OPSTINA_FILTER,
  IZVOR_ZAGADJENJA_VODE_FILTER(form),
  ZAGADJUJUCA_MATERIJA_FILTER(form),
  VRSTA_RECIPIJENTA_FILTER,
  NAZIV_RECIPIJENTA_FILTER,
  SLIV_FILTER,
  OBRAZAC_3_FILTER(form),
  LRIZ_FILTER(form),
  OTV_FILTER,
];

export const OBRAZAC_3_PREGLED_PO_GODINAMA_FILTERS: FormConfig = form => [
  YEAR_FROM_FILTER(form),
  YEAR_TO_FILTER(form),
  PREDUZECE_FILTER(form),
  POSTROJENJE_FILTER(form),
  PRTR_FILTER,
  OPSTINA_FILTER,
  ZAGADJUJUCA_MATERIJA_FILTER(form),
  OBRAZAC_3_FILTER(form),
  LRIZ_FILTER(form),
  OTV_FILTER,
];

export const OBRAZAC_3_ISPUST_PO_GODINAMA_FILTERS: FormConfig = form => [
  YEAR_FROM_FILTER(form),
  YEAR_TO_FILTER(form),
  PREDUZECE_FILTER(form),
  POSTROJENJE_FILTER(form),
  PRTR_FILTER,
  OPSTINA_FILTER,
  ZAGADJUJUCA_MATERIJA_FILTER(form),
  OBRAZAC_3_FILTER(form),
  LRIZ_FILTER(form),
  OTV_FILTER,
];

export const OBRAZAC_3_OPSTE_FILTERS: FormConfig = form => [
  YEAR_FILTER(),
  PREDUZECE_FILTER(form),
  POSTROJENJE_FILTER(form),
  PRTR_FILTER,
  OPSTINA_FILTER,
  IZVOR_ZAGADJENJA_VODE_FILTER(form),
  {
    type_of_form: 'switch',
    name: 'ppov',
    label: 'PPOV',
  },
  OBRAZAC_3_FILTER(form),
  LRIZ_FILTER(form),
  OTV_FILTER,
];

export const OBRAZAC_3_KONTROLA_UNOSA_FILTERS: FormConfig = form => [
  DATE_FROM_FILTER(form),
  DATE_TO_FILTER(form),
  YEAR_FROM_FILTER(form),
  YEAR_TO_FILTER(form),
];

export const OBRAZAC_3_PRTR_PREGLED_PO_GODINAMA_FILTERS: FormConfig = form => [
  YEAR_FROM_FILTER(form),
  YEAR_TO_FILTER(form),
];

//#endregion OBRAZAC 3

//#region OBRAZAC 5
export const OBRAZAC_5_POSTROJENJA_FILTERS: FormConfig = form => [
  YEAR_FILTER(),
  PREDUZECE_FILTER(form),
  POSTROJENJE_FILTER(form),
  OPSTINA_FILTER,
  OPASAN_FILTER,
  OBRAZAC_5_FILTER(form),
  LRIZ_FILTER(form),
  GIO_1_FILTER,
];

export const OBRAZAC_5_POSTROJENJA_INDEKSNI_BROJ_FILTERS: FormConfig = form => [
  YEAR_FILTER(),
  PREDUZECE_FILTER(form),
  POSTROJENJE_FILTER(form),
  OPSTINA_FILTER,
  SIFRA_PRETEZNE_DELATNOSTI_FILTER,
  INDEKSNI_BROJ_FILTER,
  OPASAN_FILTER,
  Y_LIST_FILTER,
  OBRAZAC_5_FILTER(form),
  LRIZ_FILTER(form),
  GIO_1_FILTER,
];

export const OBRAZAC_5_GRUPA_PODGRUPA_INDEKSNI_BROJ_FILTERS: FormConfig = form => [
  YEAR_FILTER(),
  INDEKSNI_BROJ_FILTER,
  NIVO_FILTER,
  OBRAZAC_5_FILTER(form),
  LRIZ_FILTER(form),
  GIO_1_FILTER,
];

export const OBRAZAC_5_OTPAD_NE_OPASAN_FILTERS: FormConfig = form => [
  YEAR_FILTER(),
  OBRAZAC_5_FILTER(form),
  LRIZ_FILTER(form),
  GIO_1_FILTER,
];

export const OBRAZAC_5_GENERISANJE_OTPADA_PREGLED_PO_GODINAMA_FILTERS: FormConfig = form => [
  YEAR_FROM_FILTER(form),
  YEAR_TO_FILTER(form),
  PREDUZECE_FILTER(form),
  POSTROJENJE_FILTER(form),
  INDEKSNI_BROJ_FILTER,
  OPSTINA_FILTER,
  OPASAN_FILTER,
  OBRAZAC_5_FILTER(form),
  LRIZ_FILTER(form),
  GIO_1_FILTER,
];

export const OBRAZAC_5_POSTROJENJA_ZA_PLACANJE_FILTERS: FormConfig = form => [
  YEAR_FILTER(),
  PREDUZECE_FILTER(form),
  POSTROJENJE_FILTER(form),
  OPSTINA_FILTER,
  OPASAN_FILTER,
  OBRAZAC_5_FILTER(form),
  LRIZ_FILTER(form),
  GIO_1_FILTER,
];

export const OBRAZAC_5_KONTROLA_UNOSA_FILTERS: FormConfig = form => [
  DATE_FILTER(form),
  YEAR_FROM_FILTER(form),
  YEAR_TO_FILTER(form),
  OBRAZAC_5_FILTER(form),
  LRIZ_FILTER(form),
  GIO_1_FILTER,
];

export const OBRAZAC_5_PRTR_PREGLED_PO_GODINAMA_FILTERS: FormConfig = form => [
  YEAR_FROM_FILTER(form),
  YEAR_TO_FILTER(form),
];
//#endregion OBRAZAC 5

//#region GIO 2

export const GIO_2_POSTROJENJA_INDEKSNI_BROJ_FILTERS: FormConfig = form => [
  YEAR_FILTER(),
  PREDUZECE_FILTER(form),
  SIFRA_PRETEZNE_DELATNOSTI_FILTER,
  POSTROJENJE_FILTER(form),
  OPSTINA_FILTER,
  INDEKSNI_BROJ_FILTER,
  OPASAN_FILTER,
  D_OZNAKA_FILTER,
];

export const GIO_2_GRUPA_PODGRUPA_INDEKSNI_BROJ_FILTERS: FormConfig = () => [
  YEAR_FILTER(),
  NIVO_FILTER,
  INDEKSNI_BROJ_FILTER,
];

export const GIO_2_PREGLED_PO_GODINAMA_FILTERS: FormConfig = form => [
  YEAR_FROM_FILTER(form),
  YEAR_TO_FILTER(form),
  PREDUZECE_FILTER(form),
  POSTROJENJE_FILTER(form),
  INDEKSNI_BROJ_FILTER,
  OPSTINA_FILTER,
  OPASAN_FILTER,
];

export const GIO_2_ODLAGANJE_OTPADA_KONTROLA_UNOSA_FILTERS: FormConfig = form => [
  DATE_FROM_FILTER(form, [REQUIRED_FIELD_RULE_NO_MSG]),
  DATE_TO_FILTER(form, [REQUIRED_FIELD_RULE_NO_MSG]),
  YEAR_FROM_FILTER(form),
  YEAR_TO_FILTER(form),
];

export const GIO_2_ELEKRICNI_I_ELEKTRONSKI_OTPAD_FILTERS: FormConfig = form => [
  YEAR_FILTER(),
  PREDUZECE_FILTER(form),
  POSTROJENJE_FILTER(form),
  INDEKSNI_BROJ_FILTER,
  KATEGORIJA_EE_OTPADA_FILTER,
];

//#endregion GIO 2

//#region GIO 3

export const GIO_3_POSTROJENJA_INDEKSNI_BROJ_FILTERS: FormConfig = form => [
  YEAR_FILTER(),
  PREDUZECE_FILTER(form),
  SIFRA_PRETEZNE_DELATNOSTI_FILTER,
  POSTROJENJE_FILTER(form),
  OPSTINA_FILTER,
  INDEKSNI_BROJ_FILTER,
  OPASAN_FILTER,
  R_OZNAKA_FILTER,
];

export const GIO_3_GRUPA_PODGRUPA_INDEKSNI_BROJ_FILTERS: FormConfig = () => [
  YEAR_FILTER(),
  NIVO_FILTER,
  INDEKSNI_BROJ_FILTER,
];

export const GIO_3_TRETMAN_OTPADA_PREGLED_PO_GODINAMA_FILTERS: FormConfig = form => [
  YEAR_FROM_FILTER(form),
  YEAR_TO_FILTER(form),
  PREDUZECE_FILTER(form),
  POSTROJENJE_FILTER(form),
  INDEKSNI_BROJ_FILTER,
  OPSTINA_FILTER,
  OPASAN_FILTER,
];

export const GIO_3_TRETMAN_OTPADA_KONTROLA_UNOSA_FILTERS: FormConfig = form => [
  DATE_FROM_FILTER(form),
  DATE_TO_FILTER(form),
  YEAR_FROM_FILTER(form),
  YEAR_TO_FILTER(form),
];

export const GIO_3_ELEKRICNI_I_ELEKTRONSKI_FILTERS: FormConfig = form => [
  YEAR_FILTER(),
  PREDUZECE_FILTER(form),
  POSTROJENJE_FILTER(form),
  INDEKSNI_BROJ_FILTER,
  KATEGORIJA_EE_OTPADA_FILTER,
];
//#endregion GIO 3

//#region GIO 4
export const GIO_4_POSTROJENJA_INDEKSNI_BROJ_FILTERS: FormConfig = form => [
  YEAR_FILTER(),
  PREDUZECE_FILTER(form),
  SIFRA_PRETEZNE_DELATNOSTI_FILTER,
  POSTROJENJE_FILTER(form),
  OPSTINA_FILTER,
  INDEKSNI_BROJ_FILTER,
  {
    type_of_form: 'switch',
    name: 'opasan',
    label: 'Opasan',
  },
];

export const GIO_4_KOLICINA_IZVEZENOG_OTPADA_PO_VRSTI_OTPADA_FILTERS: FormConfig = () => [
  YEAR_FILTER(),
  INDEKSNI_BROJ_FILTER,
];

export const GIO_4_ELEKTRICNI_I_ELEKTRONSKI_OTPAD_FILTERS: FormConfig = form => [
  YEAR_FILTER(),
  PREDUZECE_FILTER(form),
  POSTROJENJE_FILTER(form),
  INDEKSNI_BROJ_FILTER,
  KATEGORIJA_EE_OTPADA_FILTER,
];

export const GIO_4_PREGLED_PO_GODINAMA_FILTERS: FormConfig = form => [
  YEAR_FROM_FILTER(form),
  YEAR_TO_FILTER(form),
  PREDUZECE_FILTER(form),
  POSTROJENJE_FILTER(form),
  INDEKSNI_BROJ_FILTER,
  OPSTINA_FILTER,
  {
    type_of_form: 'switch',
    name: 'opasan',
    label: 'Opasan',
  },
];

export const GIO_4_GRUPA_PODGRUPA_INDEKSNI_BROJ_FILTERS: FormConfig = () => [
  YEAR_FILTER(),
  NIVO_FILTER,
  INDEKSNI_BROJ_FILTER,
];

export const GIO_4_IZVOZ_OTPADA_KONTROLA_UNOSA_FILTERS: FormConfig = form => [
  DATE_FROM_FILTER(form),
  DATE_TO_FILTER(form),
  YEAR_FROM_FILTER(form),
  YEAR_TO_FILTER(form),
];
//#endregion GIO 4

//#region GIO 5
export const GIO_5_POSTROJENJA_INDEKSNI_BROJ_FILTERS: FormConfig = form => [
  YEAR_FILTER(),
  PREDUZECE_FILTER(form),
  SIFRA_PRETEZNE_DELATNOSTI_FILTER,
  POSTROJENJE_FILTER(form),
  OPSTINA_FILTER,
  INDEKSNI_BROJ_FILTER,
  OPASAN_FILTER,
];

export const GIO_5_GRUPA_PODGRUPA_INDEKSNI_BROJ_FILTERS: FormConfig = () => [
  YEAR_FILTER(),
  NIVO_FILTER,
  INDEKSNI_BROJ_FILTER,
];

export const GIO_5_UVOZ_OTPADA_PREGLED_PO_GODINI_FILTERS: FormConfig = form => [
  YEAR_FROM_FILTER(form),
  YEAR_TO_FILTER(form),
  PREDUZECE_FILTER(form),
  POSTROJENJE_FILTER(form),
  INDEKSNI_BROJ_FILTER,
  OPSTINA_FILTER,
  OPASAN_FILTER,
];

export const GIO_5_KOLICINA_UVEZENOG_OTPADA_PO_VRSTI_FILTERS: FormConfig = () => [YEAR_FILTER(), INDEKSNI_BROJ_FILTER];

export const GIO_5_UVOZ_OTPADA_KONTROLA_UNOSA_FILTERS: FormConfig = form => [
  DATE_FROM_FILTER(form, [REQUIRED_FIELD_RULE_NO_MSG]),
  DATE_TO_FILTER(form, [REQUIRED_FIELD_RULE_NO_MSG]),
  YEAR_FROM_FILTER(form),
  YEAR_TO_FILTER(form),
];
//#endregion GIO 5

//#region DEO 6
export const DEO_6_UKUPNO_REUZET_OTPAD_FILTERS: FormConfig = () => [YEAR_FILTER(), INDEKSNI_BROJ_FILTER];

export const DEO_6_PREUZET_OTPAD_POSTROJENJA_INDEKSNI_BROJ_FILTERS: FormConfig = form => [
  YEAR_FILTER(),
  PREDUZECE_FILTER(form),
  POSTROJENJE_FILTER(form),
  INDEKSNI_BROJ_FILTER,
];

export const DEO_6_UKUPNO_PREDAT_OTPAD_FILTERS: FormConfig = () => [YEAR_FILTER(), INDEKSNI_BROJ_FILTER];

export const DEO_6_PREDAT_OTPAD_POSTROJENJA_INDEKSNI_BROJ_FILTERS: FormConfig = form => [
  YEAR_FILTER(),
  PREDUZECE_FILTER(form),
  POSTROJENJE_FILTER(form),
  INDEKSNI_BROJ_FILTER,
];

export const DEO_6_REKAPITULACIJA_FILTERS: FormConfig = form => [
  PREDUZECE_FILTER(form, [REQUIRED_FIELD_RULE_NO_MSG]),
  DATE_FROM_FILTER(form, [REQUIRED_FIELD_RULE_NO_MSG]),
  DATE_TO_FILTER(form, [
    REQUIRED_FIELD_RULE_NO_MSG,
    SELECT_DATE_FROM_EQUAL_YEAR_RULE(
      form.getFieldValue('datum_od'),
      'Datum od i datum do moraju biti u u okviru iste godine'
    ),
  ]),
  POSTROJENJE_FILTER(form),
  INDEKSNI_BROJ_FILTER,
  TRANSAKCIJE_FILTER,
];

export const DEO_6_REKALKULACIJA_FILTERS: FormConfig = form => [
  PREDUZECE_FILTER(form, [REQUIRED_FIELD_RULE_NO_MSG]),
  DATE_FROM_FILTER(form, [REQUIRED_FIELD_RULE_NO_MSG]),
  DATE_TO_FILTER(form, [
    REQUIRED_FIELD_RULE_NO_MSG,
    SELECT_DATE_FROM_EQUAL_YEAR_RULE(
      form.getFieldValue('datum_od'),
      'Datum od i datum do moraju biti u u okviru iste godine'
    ),
  ]),
  POSTROJENJE_FILTER(form),
];

export const DEO_6_PRIMOPREDAJA_OTPADA_IZMEDJU_PREDUZECA_FILTERS: FormConfig = () => [
  YEAR_FILTER(),
  PIB_ILI_MB_PREDUZECA_FUNC_FILTER(
    'PIB preduzeća koje je predalo otpad',
    'pib_preduzeca_koje_je_predalo_otpad_id',
    'pib'
  ),
  PIB_ILI_MB_PREDUZECA_FUNC_FILTER(
    'MB preduzeća koje je predalo otpad',
    'mb_preduzeca_koje_je_predalo_otpad_id',
    'maticni_broj'
  ),
  PIB_ILI_MB_PREDUZECA_FUNC_FILTER(
    'PIB preduzeća koje je preuzelo otpad',
    'pib_preduzeca_koje_je_preuzelo_otpad_id',
    'pib'
  ),
  PIB_ILI_MB_PREDUZECA_FUNC_FILTER(
    'MB preduzeća koje je preuzelo otpad',
    'mb_preduzeca_koje_je_pruzelo_otpad_id',
    'maticni_broj'
  ),
  INDEKSNI_BROJ_FILTER,
];

export const DEO_6_GIO_3_DEO6_PO_PREDUZECU_FILTERS: FormConfig = () => [
  YEAR_FILTER(),
  PIB_PREDUZECA_FILTER,
  INDEKSNI_BROJ_FILTER,
];
//#endregion DEO 6

//#region KOM 1
export const KOM_1_SASTAV_KOMUNALNOG_OTPADA_PO_GODINAMA_FILTERS: FormConfig = form => [
  YEAR_FROM_FILTER(form, [MIN_AND_MAX_YEAR(2019)]),
  YEAR_TO_FILTER(form, [MIN_AND_MAX_YEAR(2019)]),
  OPSTINA_FILTER,
];

export const KOM_1_KOLICINA_KOMUNALNOG_OTPADA_PO_GODINAMA_FILTERS: FormConfig = form => [
  YEAR_FROM_FILTER(form, [MIN_AND_MAX_YEAR(2019)]),
  YEAR_TO_FILTER(form, [MIN_AND_MAX_YEAR(2019)]),
  OPSTINA_FILTER,
];

export const KOM_1_OBUHVAT_PRIKUPLJENOG_OTPADA_PO_GODINAMA_FILTERS: FormConfig = form => [
  YEAR_FROM_FILTER(form, [MIN_AND_MAX_YEAR(2019)]),
  YEAR_TO_FILTER(form, [MIN_AND_MAX_YEAR(2019)]),
  OPSTINA_FILTER,
];

export const KOM_1_UPRAVLJANJE_KOMUNALNIM_OTPADOM_FILTERS: FormConfig = form => [
  YEAR_FILTER([MIN_AND_MAX_YEAR(2019)]),
  PREDUZECE_FILTER(form),
  POSTROJENJE_FILTER(form),
  OPSTINA_BY_PREDUZECE_AND_POSTRIJENJE_FILTER(form),
  INDEKSNI_BROJ_FILTER,
  R_ILI_D_OZNAKA_FILTER,
];
//#endregion KOM 1

//#region PTP 2
export const PTP_2_OBRACUN_ZA_NAPLATU_FILTERS: FormConfig = form => [YEAR_FROM_FILTER(form), YEAR_TO_FILTER(form)];
export const PTP_2_VOZILA_UPOREDJIVANJE_FILTERS: FormConfig = form => [YEAR_FROM_FILTER(form), YEAR_TO_FILTER(form)];
export const PTP_2_NEDOSTAVLJENI_PODACI_O_VOZILIMA_FILTERS: FormConfig = form => [
  YEAR_FROM_FILTER(form),
  YEAR_TO_FILTER(form),
];
export const PTP_2_UVOZ_GUMA_UPOREDJIVANJE_FILTERS: FormConfig = form => [YEAR_FROM_FILTER(form), YEAR_TO_FILTER(form)];
export const PTP_2_NEDOSTAVLJENI_PODACI_O_GUMAMA_FILTERS: FormConfig = form => [
  YEAR_FROM_FILTER(form),
  YEAR_TO_FILTER(form),
];
export const PTP_2_KONTROLA_UNOSA_FILTERS: FormConfig = form => [
  DATE_FROM_FILTER(form),
  DATE_TO_FILTER(form),
  YEAR_FROM_FILTER(form),
  YEAR_TO_FILTER(form),
];

//#endregion PTP 2

//#region AAO 1
export const AAO_1_PREGLED_PO_GODINAMA_FILTERS: FormConfig = form => [
  YEAR_FROM_FILTER(form),
  YEAR_TO_FILTER(form),
  PREDUZECE_FILTER(form),
];

export const AAO_1_PRIKAZ_1_FILTERS: FormConfig = () => [
  YEAR_FILTER(),
  OPERATER_FILTER,
  PIB_PREDUZECA_FILTER,
  OPERATER_SEARCH_FILTER,
  DOSTAVLJENO_OD_FILTERS,
  DOSTAVLJENO_DO_FILTERS,
];

export const AAO_1_SVI_PODACI_FILTERS: FormConfig = () => [YEAR_FILTER(), PIB_PREDUZECA_FILTER];

export const AAO_1_NEDOSTAVLJENI_OBRASCI_FILTERS: FormConfig = () => [YEAR_FILTER(), PIB_OPERATERA_FILTER];

//#endregion AAO 1

//#regioN AAO 2
export const AAO2_IZVESTAJ_FOR_BOOK_FILTERS: FormConfig = () => [YEAR_FILTER()];
//#endregion AAO 2

//#region DEP 1, DEP 2
export const DEP_1_SVI_PODACI_FILTERS: FormConfig = form => [
  YEAR_FILTER(),
  PREDUZECE_FILTER(form),
  NESANITARNA_DEPONIJA_FILTER(form),
];

export const DEP_2_SVI_PODACI_FILTERS: FormConfig = form => [YEAR_FILTER(), PREDUZECE_FILTER(form)];
//#endregion DEP 1, DEP 2

//#region PZV
export const PZV_KOLICINE_PROIZVODA_PO_PREDUZECU_FILTERS: FormConfig = form => [
  YEAR_FILTER(),
  PREDUZECE_FILTER(form),
  ...PZV_DETERDZENT_DJUBRIVA_SREDSTVA_FILTER_GROUP(form),
];
export const PZV_KOLICINE_PROIZVODA_PO_GRUPI_I_PREDUZECU_FILTERS: FormConfig = form => [
  YEAR_FILTER(),
  PREDUZECE_FILTER(form),
  ...PZV_DETERDZENT_DJUBRIVA_SREDSTVA_FILTER_GROUP(form),
];
//#endregion PZV

export const OBRAZAC_IZVESTAJI_FILTERS_MAP: {
  [key in IzvestajiSubtypes]: (form: FormInstance) => ISifarniciTypeOfForm[];
} = {
  [Obrazac1IzvestajiEnum.GORIVA]: OBRAZAC_1_GORIVA_FILTERS,
  [Obrazac1IzvestajiEnum.PROIZVODI]: OBRAZAC_1_PROIZVODI_FILTERS,
  [Obrazac1IzvestajiEnum.SIROVINE]: OBRAZAC_1_SIROVINE_FILTERS,
  [Obrazac1IzvestajiEnum.OPSTE]: OBRAZAC_1_OPSTE_FILTERS,
  [Obrazac2IzvestajiEnum.ZAGADLJIVE_MATERIJE]: OBRAZAC_2_ZAGADLJIVE_MATERIJE_FILTERS,
  [Obrazac2IzvestajiEnum.GORIVO]: OBRAZAC_2_GORIVO_FILTERS,
  [Obrazac2IzvestajiEnum.PREGLED_PO_GODINAMA]: OBRAZAC_2_PREGLED_PO_GODINAMA_FILTERS,
  [Obrazac2IzvestajiEnum.IZVORI_PO_GODINAMA]: OBRAZAC_2_IZVORI_PO_GODINAMA_FILTERS,
  [Obrazac2IzvestajiEnum.OPSTE]: OBRAZAC_2_OPSTE_FILTERS,
  [Obrazac2IzvestajiEnum.PRTR_KATEGORIJE]: () => OBRAZAC_2_PRTR_KATEGORIJE_FILTERS,
  [Obrazac2IzvestajiEnum.KONTROLA_UNOSA]: OBRAZAC_2_KONTROLA_UNOSA_FILTERS,
  [Obrazac2IzvestajiEnum.PRTR_PREGLED_PO_GODINAMA]: OBRAZAC_2_PRTR_PREGLED_PO_GODINAMA_FILTERS,
  [Obrazac3IzvestajiEnum.ISPUST_PO_GODINAMA]: OBRAZAC_3_ISPUST_PO_GODINAMA_FILTERS,
  [Obrazac3IzvestajiEnum.ZAGADLJIVE_MATERIJE]: OBRAZAC_3_ZAGADLJIVE_MATERIJE_FILTERS,
  [Obrazac3IzvestajiEnum.PREGLED_PO_GODINAMA]: OBRAZAC_3_PREGLED_PO_GODINAMA_FILTERS,
  [Obrazac3IzvestajiEnum.OPSTE]: OBRAZAC_3_OPSTE_FILTERS,
  [Obrazac3IzvestajiEnum.KONTROLA_UNOSA]: OBRAZAC_3_KONTROLA_UNOSA_FILTERS,
  [Obrazac3IzvestajiEnum.PRTR_PREGLED_PO_GODINAMA]: OBRAZAC_3_PRTR_PREGLED_PO_GODINAMA_FILTERS,
  [Obrazac5IzvestajiEnum.POSTROJENJA]: OBRAZAC_5_POSTROJENJA_FILTERS,
  [Obrazac5IzvestajiEnum.POSTROJENJA_INDEKSNI_BROJ]: OBRAZAC_5_POSTROJENJA_INDEKSNI_BROJ_FILTERS,
  [Obrazac5IzvestajiEnum.GRUPA_PODGRUPA_INDEKSNI_BROJ]: OBRAZAC_5_GRUPA_PODGRUPA_INDEKSNI_BROJ_FILTERS,
  [Obrazac5IzvestajiEnum.OTPAD_NE_OPASAN]: OBRAZAC_5_OTPAD_NE_OPASAN_FILTERS,
  [Obrazac5IzvestajiEnum.GENERISANJE_OTPADA_PREGLED_PO_GODINAMA]:
    OBRAZAC_5_GENERISANJE_OTPADA_PREGLED_PO_GODINAMA_FILTERS,
  [Obrazac5IzvestajiEnum.OTPAD_POSTROJENJA_ZA_PLACANJE]: OBRAZAC_5_POSTROJENJA_ZA_PLACANJE_FILTERS,
  [Obrazac5IzvestajiEnum.GENERISANJE_OTPADA_KONTROLA_UNOSA]: OBRAZAC_5_KONTROLA_UNOSA_FILTERS,
  [Obrazac5IzvestajiEnum.PRTR_GENERISANJE_OTPADA_PREGLED_PO_GODINAMA]: OBRAZAC_5_PRTR_PREGLED_PO_GODINAMA_FILTERS,
  [Gio2IzvestajiEnum.POSTROJENJA_INDEKSNI_BROJ]: GIO_2_POSTROJENJA_INDEKSNI_BROJ_FILTERS,
  [Gio2IzvestajiEnum.GRUPA_PODGRUPA_INDEKSNI_BROJ]: GIO_2_GRUPA_PODGRUPA_INDEKSNI_BROJ_FILTERS,
  [Gio2IzvestajiEnum.ODLAGANJE_OTPADA_PREGLED_PO_GODINI]: GIO_2_PREGLED_PO_GODINAMA_FILTERS,
  [Gio2IzvestajiEnum.ODLAGANJE_OTPADA_KONTROLA_UNOSA]: GIO_2_ODLAGANJE_OTPADA_KONTROLA_UNOSA_FILTERS,
  [Gio2IzvestajiEnum.ELEKTRICNI_I_ELEKTRONSKI_OTPAD]: GIO_2_ELEKRICNI_I_ELEKTRONSKI_OTPAD_FILTERS,
  [Gio3IzvestajiEnum.POSTROJENJA_INDEKSNI_BROJ]: GIO_3_POSTROJENJA_INDEKSNI_BROJ_FILTERS,
  [Gio3IzvestajiEnum.GRUPA_PODGRUPA_INDEKSNI_BROJ]: GIO_3_GRUPA_PODGRUPA_INDEKSNI_BROJ_FILTERS,
  [Gio3IzvestajiEnum.TRETMAN_OTPADA_PREGLED_PO_GODINI]: GIO_3_TRETMAN_OTPADA_PREGLED_PO_GODINAMA_FILTERS,
  [Gio3IzvestajiEnum.TRETMAN_OTPADA_KONTROLA_UNOSA]: GIO_3_TRETMAN_OTPADA_KONTROLA_UNOSA_FILTERS,
  [Gio3IzvestajiEnum.ELEKTRICNI_I_ELEKTRONSKI_OTPAD]: GIO_3_ELEKRICNI_I_ELEKTRONSKI_FILTERS,
  [Gio4IzvestajiEnum.POSTROJENJA_INDEKSNI_BROJ]: GIO_4_POSTROJENJA_INDEKSNI_BROJ_FILTERS,
  [Gio4IzvestajiEnum.GRUPA_PODGRUPA_INDEKSNI_BROJ]: GIO_4_GRUPA_PODGRUPA_INDEKSNI_BROJ_FILTERS,
  [Gio4IzvestajiEnum.IZVOZ_OTPADA_PREGLED_PO_GODINI]: GIO_4_PREGLED_PO_GODINAMA_FILTERS,
  [Gio4IzvestajiEnum.ELEKTRICNI_I_ELEKTRONSKI_OTPAD]: GIO_4_ELEKTRICNI_I_ELEKTRONSKI_OTPAD_FILTERS,
  [Gio4IzvestajiEnum.KOLICINA_IZVEZENOG_OTPADA_PO_VRSTI]: GIO_4_KOLICINA_IZVEZENOG_OTPADA_PO_VRSTI_OTPADA_FILTERS,
  [Gio4IzvestajiEnum.IZVOZ_OTPADA_KONTROLA_UNOSA]: GIO_4_IZVOZ_OTPADA_KONTROLA_UNOSA_FILTERS,
  [Gio5IzvestajiEnum.POSTROJENJA_INDEKSNI_BROJ]: GIO_5_POSTROJENJA_INDEKSNI_BROJ_FILTERS,
  [Gio5IzvestajiEnum.GRUPA_PODGRUPA_INDEKSNI_BROJ]: GIO_5_GRUPA_PODGRUPA_INDEKSNI_BROJ_FILTERS,
  [Gio5IzvestajiEnum.UVOZ_OTPADA_PREGLED_PO_GODINI]: GIO_5_UVOZ_OTPADA_PREGLED_PO_GODINI_FILTERS,
  [Gio5IzvestajiEnum.KOLICINA_UVEZENOG_OTPADA_PO_VRSTI]: GIO_5_KOLICINA_UVEZENOG_OTPADA_PO_VRSTI_FILTERS,
  [Gio5IzvestajiEnum.UVOZ_OTPADA_KONTROLA_UNOSA]: GIO_5_UVOZ_OTPADA_KONTROLA_UNOSA_FILTERS,
  [Deo6IzvestajiEnum.UKUPNO_PREUZET_OTPAD]: DEO_6_UKUPNO_REUZET_OTPAD_FILTERS,
  [Deo6IzvestajiEnum.PREUZET_OTPAD_POSTROJENJA_INDEKSNI_BROJ]: DEO_6_PREUZET_OTPAD_POSTROJENJA_INDEKSNI_BROJ_FILTERS,
  [Deo6IzvestajiEnum.UKUPNO_PREDAT_OTPAD]: DEO_6_UKUPNO_PREDAT_OTPAD_FILTERS,
  [Deo6IzvestajiEnum.PREDAT_OTPAD_POSTROJENJA_INDEKSNI_BROJ]: DEO_6_PREDAT_OTPAD_POSTROJENJA_INDEKSNI_BROJ_FILTERS,
  [Deo6IzvestajiEnum.REKAPITULACIJA]: DEO_6_REKAPITULACIJA_FILTERS,
  [Deo6IzvestajiEnum.REKALKULACIJA]: DEO_6_REKALKULACIJA_FILTERS,
  [Deo6IzvestajiEnum.PRIMOPREDAJA_OTPADA_IZMEDJU_PREDUZECA]: DEO_6_PRIMOPREDAJA_OTPADA_IZMEDJU_PREDUZECA_FILTERS,
  [Deo6IzvestajiEnum.GIO_3_DEO6_PO_PREDUZECU]: DEO_6_GIO_3_DEO6_PO_PREDUZECU_FILTERS,
  [Kom1IzvestajiEnum.SASTAV_KOMUNALNOG_OTPADA_PO_GODINAMA]: KOM_1_SASTAV_KOMUNALNOG_OTPADA_PO_GODINAMA_FILTERS,
  [Kom1IzvestajiEnum.KOLICINA_KOMUNALNOG_OTPADA_PO_GODINAMA]: KOM_1_KOLICINA_KOMUNALNOG_OTPADA_PO_GODINAMA_FILTERS,
  [Kom1IzvestajiEnum.OBUHVAT_PRIKUPLJENOG_OTPADA_PO_GODINAMA]: KOM_1_OBUHVAT_PRIKUPLJENOG_OTPADA_PO_GODINAMA_FILTERS,
  [Kom1IzvestajiEnum.UPRAVLJANJE_KOMUNALNIM_OTPADOM]: KOM_1_UPRAVLJANJE_KOMUNALNIM_OTPADOM_FILTERS,
  [Ptp2IzvestajiEnum.OBRACUN_ZA_NAPLATU]: PTP_2_OBRACUN_ZA_NAPLATU_FILTERS,
  [Ptp2IzvestajiEnum.VOZILA_UPOREDJIVANJE]: PTP_2_VOZILA_UPOREDJIVANJE_FILTERS,
  [Ptp2IzvestajiEnum.NEDOSTAVLJENI_PODACI_O_VOZILIMA]: PTP_2_NEDOSTAVLJENI_PODACI_O_VOZILIMA_FILTERS,
  [Ptp2IzvestajiEnum.UVOZ_GUMA_UPOREDJIVANJE]: PTP_2_UVOZ_GUMA_UPOREDJIVANJE_FILTERS,
  [Ptp2IzvestajiEnum.NEDOSTAVLJENI_PODACI_O_GUMAMA]: PTP_2_NEDOSTAVLJENI_PODACI_O_GUMAMA_FILTERS,
  [Ptp2IzvestajiEnum.KONTROLA_UNOSA]: PTP_2_KONTROLA_UNOSA_FILTERS,
  [Dep1IzvestajiEnum.SVI_PODACI]: DEP_1_SVI_PODACI_FILTERS,
  [Dep2IzvestajiEnum.SVI_PODACI]: DEP_2_SVI_PODACI_FILTERS,
  [Aao1IzvestajiEnum.PREGLED_PO_GODINAMA]: AAO_1_PREGLED_PO_GODINAMA_FILTERS,
  [Aao1IzvestajiEnum.PRIKAZ_1]: AAO_1_PRIKAZ_1_FILTERS,
  [Aao1IzvestajiEnum.SVI_PODACI]: AAO_1_SVI_PODACI_FILTERS,
  [Aao1IzvestajiEnum.NEDOSTAVLJENI_OBRASCI]: AAO_1_NEDOSTAVLJENI_OBRASCI_FILTERS,
  [Aao2IzvestajiEnum.IZVESTAJ_FOR_BOOK]: AAO2_IZVESTAJ_FOR_BOOK_FILTERS,
  [PzvIzvestajiEnum.KOLICINE_PROIZVODA_PO_PREDUZECU]: PZV_KOLICINE_PROIZVODA_PO_PREDUZECU_FILTERS,
  [PzvIzvestajiEnum.KOLICINE_PROIZVODA_PO_GRUPI_I_PREDUZECU]: PZV_KOLICINE_PROIZVODA_PO_GRUPI_I_PREDUZECU_FILTERS,
};
