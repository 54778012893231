import { Button } from 'components/Button/Button';
import { Row } from 'antd';
import { formStore, modalStore } from 'store';
import { CRUDButtonProps } from 'typescript/NrizTypes';
import styles from './CRUDButton.module.scss';
import { useTranslation } from 'react-i18next';
import { observer } from 'mobx-react-lite';
import { useEffect } from 'react';
import { Popconfirm } from 'components/Popconfirm/Popconfirm';

export const CRUDButton: React.FC<CRUDButtonProps> = observer(props => {
  const { t } = useTranslation();

  useEffect(() => {
    return () => formStore.setCrudButtonType('');
  }, []);

  return (
    <Row justify={'end'}>
      {!props.showButton ? (
        <Row justify={'end'}>
          <Button
            disabled={modalStore.isLoading}
            onClick={() => {
              formStore.setCrudButtonType('submit');
              props.form.submit();
            }}
            name="submit-button"
          >
            {t('SUBMIT')}
          </Button>
        </Row>
      ) : (
        <Row justify={'end'}>
          {!props.withOutDelete && (
            <Popconfirm
              title={'Da li ste sigurni da želite da obrišete ?'}
              okText={t('Da')}
              cancelText={t('Odbaci')}
              onConfirm={() => {
                formStore.setCrudButtonType('delete');
                props.form.submit();
              }}
              placement="top"
              name={'delete-button'}
            >
              <Button
                disabled={modalStore.isLoading}
                className={`ant-btn-dangerous ${styles.detete_btn}`}
                name="delete-button"
              >
                {t('DELETE')}
              </Button>
            </Popconfirm>
          )}
          <Button
            onClick={() => {
              formStore.setCrudButtonType('change');
              props.form.submit();
            }}
            name="change-button"
            disabled={modalStore.isLoading}
          >
            {t('SUBMIT')}
          </Button>
        </Row>
      )}
    </Row>
  );
});
