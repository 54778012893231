import { ColumnsType } from 'antd/es/table';
import { EditButtonProps, RowEditPreviewButton } from 'components/RowEditPreviewButton/RowEditPreviewButton';
import TableData from 'components/TableData/TableData';
import { observer } from 'mobx-react-lite';
import { obrasciStore } from 'modules/obrasci/obrasci.store';
import { dep1Store } from 'modules/obrasci/store/dep1/dep1.store';
import { IObrazacDep1Mesto } from 'modules/obrasci/store/dep1/dep1.types';
import React from 'react';
import { useTranslation } from 'react-i18next';

const EditNaseljeDep1Button: React.FC<EditButtonProps<IObrazacDep1Mesto>> = observer(({ record }) => {
  return (
    <RowEditPreviewButton
      name="dep1-naselje"
      modalName="naselje-modal"
      onClick={() => dep1Store.handleChange('mesto_form', record)}
      preview={obrasciStore.zakljucanObrazac}
    />
  );
});

export const TableNaseljeDep1: React.FC = observer(() => {
  const { t } = useTranslation();

  const baseColumns: ColumnsType<IObrazacDep1Mesto> = [
    {
      title: `${t('COMPANY.LOCATION')}`,
      width: '90%',
      dataIndex: ['mesto', 'display_name'],
      key: 'display_name',
    },

    {
      title: `${t('COMPANY.ADDITIONALLY')}`,
      key: 'operation',
      fixed: 'right',
      width: '10%',
      align: 'center',
      render: (_, record: IObrazacDep1Mesto) => <EditNaseljeDep1Button record={record} />,
    },
  ];
  return (
    <TableData name="naselje-dep1" columns={baseColumns} dataSource={[...dep1Store.initialState.obrazac_dep1_mesta]} />
  );
});
