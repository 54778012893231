import { IGetCompany } from 'modules/company/company.types';
import { IGetFacility } from 'modules/facility/facility.types';
import { IGetWater } from 'modules/sourcesOfPollution/water/water.types';
import { IGetLaboratoriesAirWater } from 'modules/sifarnici/store/laboratoriesAirWater/laboratoriesAirWater.types';
import { IGetMethodBasisCode } from 'modules/sifarnici/store/methodBasisCode/methodBasisCode.types';
import { IGetMethodTypes } from 'modules/sifarnici/store/methodTypes/methodTypes.types';
import { IGetPollutantCode } from 'modules/sifarnici/store/pollutantCode/pollutantCode.types';
import { IGetWorkingRegime } from 'modules/sifarnici/store/workingRegime/workingRegime.types';
import { IGetUnitCode } from 'modules/sifarnici/store/unitCode/unitCode.types';
import { IGetNaceActivityCode } from 'modules/sifarnici/store/naceActivityCode/naceActivityCode.types';
import { IGetUser } from 'modules/user/user.types';

export interface IPostObrazac3 {
  godina: number | string;
  preduzece_id: string;
  postrojenje_id: string;
  obrazac3: boolean;
  obrazac3_lriz: boolean;
  otv: boolean;
  izvor_zagadjenja_vode_id: string;
  radni_rezim_ispusta_id: string;
  vremenski_period_ispustanja: number | string;
  ukupna_kolicina_ispustene_otpadne_vode_u_izvestajnoj_godini_na_ispustu: number | string;
  ukupan_kapacitet_proizvodnje_t_god: number | string;
  nacin_odredjivanja_kolicine_ispustene_vode: number | string;
  vodovodni_sistem: boolean;
  kanalizacioni_sistem: boolean;
  ukupna_povrsina_teritorije_opstine: number | string;
  procenat_ukupnog_stanovnistva_prikljucenog_na_vodovod: number | string;
  kolicina_proizvedene_vode: number | string;
  ukupna_kolicina_isporucene_vode_svim_potrosacima: number | string;
  ukupna_kolicina_isporucene_vode_stanovnistvu: number | string;
  ukupna_kolicina_isporucene_vode_industriji_i_drugim_institucijama: number | string;
  procenat_ukupnog_stanovnistva_prikacenog_na_kanalizaciju: number | string;
  da_li_se_vrse_merenja_kolicina_otpadnih_voda_na_kanalizacionom_sistemu: string;
  na_sistemu: number | string;
  na_izlivu: number | string;
  napomena: string;
  kreiranje_godisnjeg_bilansa: boolean | null;
  analiza_otpadne_vode: IPostObrazac3AnalizaOtpadneVode[];
  merenja_otpadne_vode: IPostObrazac3MerenjeOtpadneVode[];
  analiza_recipijenta: IPostObrazac3AnalizaRecipijenata[];
  merenja_recipijenta: IPostObrazac3MerenjeRecipijenta[];
  glavni_industrijski_potrosaci_vode: IPostObrazac3GlavniIndustrijskiPotrosaciVode[];
  godisnji_bilans: IGetObrazac3GodisnjiBilans[];
}

export interface IGetObrazac3 extends IPostObrazac3 {
  preduzece: IGetCompany;
  postrojenje: IGetFacility;
  izvor_zagadjenja_vode: IGetWater;
  radni_rezim_ispusta: IGetWorkingRegime;
  id: string;
  kreirao: IGetUser;
  izmenio: IGetUser;
  datum_izmene: string;
  datum_kreiranja: string;
}

export interface IPostObrazac3AnalizaOtpadneVode {
  naziv_zagadjujuce_materije_id: string;
  srednja_godisnja_izmerena_vrednost: number | string;
  emitovane_kolicine: number | string;
  nacin_odredjivanja_id: string;
  metod_odredjivanja_id: string;
  emitovane_kolicine_u_akcidentnim_situacijama: number | string;
}

export interface IGetObrazac3AnalizaOtpadneVode extends IPostObrazac3AnalizaOtpadneVode {
  naziv_zagadjujuce_materije: IGetPollutantCode;
  nacin_odredjivanja: IGetMethodBasisCode;
  metod_odredjivanja: IGetMethodTypes;
  id: string;
}

export interface IPostObrazac3MerenjeOtpadneVode {
  datum_merenja: string;
  indetifikacioni_broj_izvestaja: string;
  naziv_strucne_laboratorije_id: string;
  izmerene_vrednosti_otpadne_vode: IPostObrazac3IzmereneVrednostiOtpadneVode[];
}

export interface IGetObrazac3MerenjeOtpadneVode extends IPostObrazac3MerenjeOtpadneVode {
  naziv_strucne_laboratorije: IGetLaboratoriesAirWater;
  id: string;
}

export interface IPostObrazac3IzmereneVrednostiOtpadneVode {
  naziv_zagadjujuce_materije_id: string;
  jedinica_mere_id: string;
  izmerene_vrednosti: number | string;
  predznak_izmerene_vrednosti: string;
  metod_odredjivanja_id: string;
  emitovana_kolicina_u_akcidentnoj_situaciji: number | string;
  // emitovana_kolicina_u_akcidentnoj_situaciji_znak: number | string;
}

export interface IGetObrazac3IzmereneVrednostiOtpadneVode extends IPostObrazac3IzmereneVrednostiOtpadneVode {
  naziv_zagadjujuce_materije: IGetPollutantCode;
  jedinica_mere: IGetUnitCode;
  metod_odredjivanja: IGetMethodTypes;
  id: string;
}

export interface IPostObrazac3MerenjeRecipijenta {
  datum_merenja: string;
  indetifikacioni_broj_izvestaja: string;
  naziv_strucne_laboratorije_id: string;
  izmerene_vrednosti_recipijenta: IPostObrazac3IzmereneVrednostiRecipijenta[];
}

export interface IGetObrazac3MerenjeRecipijenta extends IPostObrazac3MerenjeRecipijenta {
  naziv_strucne_laboratorije: IGetLaboratoriesAirWater;
  id: string;
}
export interface IPostObrazac3IzmereneVrednostiRecipijenta {
  naziv_zagadjujuce_materije_id: string;
  jedinica_mere_id: string;
  izmerene_vrednosti_pre_ispusta: number | string;
  predznak_izmerene_vrednosti_pre_ispusta: string;
  izmerene_vrednosti_posle_ispusta: number | string;
  predznak_izmerene_vrednosti_posle_ispusta: string;
  metod_odredjivanja_id: string;
}

export interface IGetObrazac3IzmereneVrednostiRecipijenta extends IPostObrazac3IzmereneVrednostiRecipijenta {
  naziv_zagadjujuce_materije: IGetPollutantCode;
  jedinica_mere: IGetUnitCode;
  metod_odredjivanja: IGetMethodTypes;
  id: string;
}

export interface IGetObrazac3GodisnjiBilans {
  naziv_zagadjujuce_materije_id: string;
  jedinica_mere: string;
  jedinica_mere_id: string;
  emitovana_kolicina_u_akcidentnoj_situaciji: number | string;
  emitovana_kolicina_pri_redovnom_radu: number | string;
  srednja_godisnja_izmerena_vrednost: number | string;
  metod_odredjivanja_id: string;
}

export interface IPostObrazac3AnalizaRecipijenata {
  naziv_zagadjujuce_materije_id: string;
  jedinica_mere_id: string;
  pre_ispusta_otpadne_vode: number | string;
  posle_ispusta_otpadne_vode: number | string;
  nacin_odredjivanja_id: string;
  metod_odredjivanja_id: string;
}

export interface IGetObrazac3AnalizaRecipijenata extends IPostObrazac3AnalizaRecipijenata {
  naziv_zagadjujuce_materije: IGetPollutantCode;
  jedinica_mere: IGetUnitCode;
  nacin_odredjivanja: IGetMethodBasisCode;
  metod_odredjivanja: IGetMethodTypes;
  id: string;
}

export interface IPostObrazac3GlavniIndustrijskiPotrosaciVode {
  naziv: string;
  sifra_pretezne_delatnosti_id: string;
  kolicina_isporucene_vode: number | string;
  vodovodni_sistem?: boolean;
  kanalizacioni_sistem?: boolean;
}
export interface IGetObrazac3GlavniIndustrijskiPotrosaciVode extends IPostObrazac3GlavniIndustrijskiPotrosaciVode {
  sifra_pretezne_delatnosti: IGetNaceActivityCode;
  id: string;
}

export enum Obrazac3Sistem {
  VODOVODNI = 'vodovodni_sistem',
  KANALIZACIONI = 'kanalizacioni_sistem',
}
