import { ColumnsType } from 'antd/es/table';
import { EditButtonProps, RowEditPreviewButton } from 'components/RowEditPreviewButton/RowEditPreviewButton';
import TableData from 'components/TableData/TableData';
import { observer } from 'mobx-react-lite';
import { obrasciStore } from 'modules/obrasci/obrasci.store';
import { pzv1ObrazacStore } from 'modules/obrasci/store/pzv1/pzv1.store';
import { IGetZastitaBilja } from 'modules/obrasci/store/pzv1/pzv1.types';
import { useTranslation } from 'react-i18next';

// todo: make a separate edit table row button component
// was pulled out from the columns array render function because mobx didnt see the obrasciStore.zakljucanObrazac change

const EditZastitaBiljaButton: React.FC<EditButtonProps<IGetZastitaBilja>> = observer(({ record }) => {
  return (
    <RowEditPreviewButton
      name="pzv1-zastita-bilja"
      modalName="zastita-bilja-modal"
      onClick={() => pzv1ObrazacStore.handleChange('zastitaBilja', record)}
      preview={obrasciStore.zakljucanObrazac && !pzv1ObrazacStore.initialState.korekcija_zastite_bilja}
      store={pzv1ObrazacStore}
    />
  );
});

export const ZastitaBiljaTable: React.FC = observer(() => {
  const { t } = useTranslation();

  const columns: ColumnsType<IGetZastitaBilja> = [
    {
      title: `${t('OBRASCI.PZV_1.PLANT_PROTECTION.TYPE')}`,
      width: 50,
      align: 'center',
      dataIndex: ['products_pzv', 'name'],
    },
    {
      title: `${t('OBRASCI.PZV_1.PLANT_PROTECTION.CONTENT')}`,
      width: 70,
      align: 'center',
      dataIndex: 'sadrzaj_aktivne_materije',
    },
    {
      title: `${t('OBRASCI.PZV_1.PLANT_PROTECTION.QUANTITY')}`,
      width: 70,
      align: 'center',
      dataIndex: 'kolicina',
    },
    {
      title: '',
      fixed: 'right',
      align: 'center',
      width: 20,
      render: (_, record) => <EditZastitaBiljaButton record={record} />,
    },
  ];

  return (
    <TableData
      name="zastita-bilja-pzv1"
      columns={columns}
      dataSource={[...pzv1ObrazacStore.getInitialState.zastita_bilja]}
    />
  );
});
