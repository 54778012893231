import { ColumnsType } from 'antd/es/table';
import TableData from 'components/TableData/TableData';
import { observer } from 'mobx-react-lite';
import { aao1Store } from 'modules/obrasci/store/aao1/aao1.store';
import { useTranslation } from 'react-i18next';
import style from './Aao1KolicinaAmbalazeTable.module.scss';
import { IGetKolicinaAmbalaze } from 'modules/obrasci/store/aao1/aao1.types';
import { useEffect } from 'react';
import { message } from 'antd';
import { EditButtonProps, RowEditPreviewButton } from 'components/RowEditPreviewButton/RowEditPreviewButton';
import { obrasciStore } from 'modules/obrasci/obrasci.store';

const EditAao1KolicinaAmbalazeButton: React.FC<EditButtonProps<IGetKolicinaAmbalaze>> = observer(({ record }) => {
  return (
    <RowEditPreviewButton
      name="aao1-kolicina-ambalaze"
      modalName="kolicina-ambalaze-modal"
      onClick={() => {
        if (record.da_li_je_preneta_kolicina_ambalaze_na_operatera) {
          aao1Store.handleChange('showOperateri', true);
        }
        aao1Store.handleChange('kolicinaAmbalaze', record);
      }}
      preview={obrasciStore.zakljucanObrazac}
    />
  );
});

export const Aao1KolicinaAmbalazeTable: React.FC = observer(() => {
  const { t } = useTranslation();

  useEffect(() => {
    if (aao1Store.kolicinaWarning && aao1Store.isCompanyWithSelfManagment) {
      aao1Store.handleChange('kolicinaWarning', false);
      message.warning('Za vrstu ambalaže količine nisu dodeljene ni operateru ni za samostalno upravljanje');
    }
  }, [aao1Store.kolicinaWarning]);

  const columns: ColumnsType<any> = [
    {
      title: t('OBRASCI.AAO1.PACKAGING_AMOUNT.PACKAGING_TYPE'),
      width: 150,
      align: 'center',
      dataIndex: ['type_of_packing', 'display_type_sr'],
    },
    {
      title: t('OBRASCI.AAO1.PACKAGING_AMOUNT.NON_RETURNABLE_PACKAGING_AMOUNT'),
      children: [
        {
          title: t('OBRASCI.AAO1.PACKAGING_AMOUNT.MANUFACTURED'),
          width: 120,
          align: 'center',
          dataIndex: 'proizvedena_kolicina_nepovratne_ambalaze',
        },
        {
          title: t('OBRASCI.AAO1.PACKAGING_AMOUNT.IMPORTED'),
          width: 120,
          align: 'center',
          dataIndex: 'uvezena_kolicina_nepovratne_ambalaze',
        },
        {
          title: t('OBRASCI.AAO1.PACKAGING_AMOUNT.EXPORTED'),
          width: 120,
          align: 'center',
          dataIndex: 'izvezena_kolicina_nepovratne_ambalaze',
        },
        {
          title: t('OBRASCI.AAO1.PACKAGING_AMOUNT.TOTAL'),
          width: 120,
          align: 'center',
          dataIndex: 'ukupna_kolicina_nepovratne_ambalaze',
        },
      ],
    },
    {
      title: t('OBRASCI.AAO1.PACKAGING_AMOUNT.RETURN_PACKAGING_AMOUNT'),
      children: [
        {
          title: t('OBRASCI.AAO1.PACKAGING_AMOUNT.MANUFACTURED'),
          width: 120,
          align: 'center',
          dataIndex: 'proizvedena_kolicina_povratne_ambalaze',
        },
        {
          title: t('OBRASCI.AAO1.PACKAGING_AMOUNT.IMPORTED'),
          width: 120,
          align: 'center',
          dataIndex: 'uvezena_kolicina_povratne_ambalaze',
        },
        {
          title: t('OBRASCI.AAO1.PACKAGING_AMOUNT.EXPORTED'),
          width: 120,
          align: 'center',
          dataIndex: 'izvezena_kolicina_povratne_ambalaze',
        },
        {
          title: t('OBRASCI.AAO1.PACKAGING_AMOUNT.TOTAL'),
          width: 120,
          align: 'center',
          dataIndex: 'ukupna_kolicina_povratne_ambalaze',
        },
      ],
    },
    {
      title: t('OBRASCI.AAO1.PACKAGING_AMOUNT.OPERATOR_AMOUNT'),
      width: 150,
      align: 'center',
      dataIndex: 'kolicina_preneta_na_operatera',
    },
    {
      title: t('OBRASCI.AAO1.PACKAGING_AMOUNT.OPERATORS'),
      width: 400,
      align: 'center',
      render: (text, record: IGetKolicinaAmbalaze) => {
        if (record.operateri)
          return record.operateri.map((operater, index: number) => (
            <p key={index}>{operater.operators_package_waste_manage?.preduzece?.naziv}</p>
          ));
      },
    },
    {
      title: '',
      fixed: 'right',
      align: 'center',
      width: 70,
      render: (_, record: IGetKolicinaAmbalaze) => <EditAao1KolicinaAmbalazeButton record={record} />,
    },
  ];

  return (
    <div className={style.tableDiv}>
      <TableData name="kolicine-aao1" columns={columns} dataSource={[...aao1Store.getInitialState.kolicina_ambalaze]} />
    </div>
  );
});
