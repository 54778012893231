import { IEmptyResponse, IPaginatedApiResponse, IPostResponse } from 'typescript/NrizTypes';
import { soilRepo } from './soil.repo';
import { IGetSoil, IPostSoil, RequestSoilList } from './soil.types';
import { ApiResponse } from 'modules/axios';

class SoilService {
  fetchSoilList = (payload: RequestSoilList): IPaginatedApiResponse<IGetSoil> => {
    return soilRepo.fetchSoilList(payload);
  };

  postSoil = (payload: IPostSoil): ApiResponse<IPostResponse> => {
    return soilRepo.postSoil(payload);
  };

  putSoil = (payload: IGetSoil): ApiResponse<IEmptyResponse> => {
    return soilRepo.putSoil(payload);
  };

  aktivirajTlo = (id: string): ApiResponse<IEmptyResponse> => {
    return soilRepo.aktivirajTlo(id);
  };
  deaktivirajTlo = (id: string): ApiResponse<IEmptyResponse> => {
    return soilRepo.deaktivirajTlo(id);
  };
}

export const soilService = new SoilService();
