import to from 'await-to-js';
import { otvRepo } from './otv.repo';
import { IOTVRegistar } from './otv.types';
import { paginationInitialState } from 'modules/nriz/nriz.constants';

class OTVService {
  async fetchOtvList(filters: any) {
    const [err, res] = await to(otvRepo.fetchOtvList(filters));
    if (err) return { items: [], ...paginationInitialState };
    return res;
  }

  async fetchOtvById(id: string) {
    const [err, res] = await to(otvRepo.fetchOtvById(id));
    if (err) return undefined;
    return res;
  }

  async postOtv(payload: IOTVRegistar) {
    const [err, res] = await to(otvRepo.postOtv(payload));
    if (err) return undefined;
    return res;
  }

  async updateOtv(payload: IOTVRegistar) {
    const [err, res] = await to(
      otvRepo.updateOtv({ otv_registar_zagadjenje: payload.otv_registar_zagadjenje }, payload.id)
    );
    if (err) return undefined;
    return res;
  }
}

export const otvService = new OTVService();
