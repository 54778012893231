import { IGetMesto } from 'modules/sifarnici/store/mesto/mesto.types';
import { IPagination } from 'typescript';

export interface IGetCompany extends IPostCompany {
  id: string;
  ima_deo6: boolean;
  mesto: IGetMesto;
  sifarnik_pretezne_delatnosti: IPreteznaDelatnost;
}
export interface IPostCompany {
  pib: string;
  naziv: string;
  kraci_naziv: string;
  postanski_broj: string;
  mesto_id: string;
  ulica: string;
  kucni_broj: string;
  ime_odgovorne_osobe: string;
  prezime_odgovorne_osobe: string;
  telefon: string;
  email: string;
  maticni_broj: string;
  popunjen_upitnik: boolean;
  sifarnik_pretezne_delatnosti_id: string;
  status: string;
  verifikacioni_kod: string | null;
  upitnik?: boolean;
  napomena?: string;
  is_active: boolean;
}

export interface ResponseCompany extends IPagination {
  items: IGetCompany[];
}

export interface IPreteznaDelatnost {
  name: string;
  name_sr: string;
  code: string;
}

export interface ILocation {
  display_name: string;
  location_name: string;
  location_code: string;
  municipality_code: string;
  nuts1_code: string;
  nuts2_region_code: string;
  nuts3_area_code: string;
}

export interface IPib {
  pib: string;
  maticni_broj: string;
}

export interface IPibMaticniCheckResponse {
  pib: string[];
  maticni_broj: string[];
}

export enum PibMaticniCheckEnum {
  CheckSuccess = 1,
  PibExists,
  MbExists,
  CompanyExists,
}

export interface IPibMaticniModalData {
  type: PibMaticniCheckEnum;
  values: IPib;
}

export interface ResponseCheckCompany {
  preduzece: IGetCompany;
  korisnik_id: string;
  upitnik: boolean;
}

export interface ResponseLocationsList extends IPagination {
  items: ILocation[];
}

export interface ICompanyTipoviObrasca {
  tipovi_obrazaca: string[];
}
