import { ColumnsType } from 'antd/es/table';
import { EditButtonProps, RowEditPreviewButton } from 'components/RowEditPreviewButton/RowEditPreviewButton';
import TableData from 'components/TableData/TableData';
import { observer } from 'mobx-react-lite';
import { obrasciStore } from 'modules/obrasci/obrasci.store';
import { pzv1ObrazacStore } from 'modules/obrasci/store/pzv1/pzv1.store';
import { IGetDeterdzent } from 'modules/obrasci/store/pzv1/pzv1.types';
import { useTranslation } from 'react-i18next';

// todo: make a separate edit table row button component
// was pulled out from the columns array render function because mobx didnt see the obrasciStore.zakljucanObrazac change

const EditDeterdzentiButton: React.FC<EditButtonProps<IGetDeterdzent>> = observer(({ record }) => {
  return (
    <RowEditPreviewButton
      name="pzv1-deterdzent"
      modalName="deterdzenti-modal"
      onClick={() => pzv1ObrazacStore.handleChange('deterdzent', record)}
      preview={obrasciStore.zakljucanObrazac && !pzv1ObrazacStore.initialState.korekcija_deterdzenta}
      store={pzv1ObrazacStore}
    />
  );
});

export const DeterdzentiTable: React.FC = observer(() => {
  const { t } = useTranslation();

  const columns: ColumnsType<IGetDeterdzent> = [
    {
      title: `${t('OBRASCI.PZV_1.DETERGENTS.TYPE')}`,
      width: 50,
      align: 'center',
      dataIndex: ['products_pzv', 'name'],
    },
    {
      title: `${t('OBRASCI.PZV_1.DETERGENTS.NAME')}`,
      width: 50,
      align: 'center',
      dataIndex: 'naziv',
    },
    {
      title: `${t('OBRASCI.PZV_1.DETERGENTS.CONTENT')}`,
      width: 70,
      align: 'center',
      dataIndex: 'sadrzaj_fosfata',
    },
    {
      title: `${t('OBRASCI.PZV_1.DETERGENTS.QUANTITY')}`,
      width: 70,
      align: 'center',
      dataIndex: 'kolicina',
    },
    {
      title: '',
      fixed: 'right',
      align: 'center',
      width: 20,
      render: (_, record) => <EditDeterdzentiButton record={record} />,
    },
  ];

  return (
    <TableData
      name="deterdzenti-pzv1"
      columns={columns}
      dataSource={[...pzv1ObrazacStore.getInitialState.deterdzenti]}
    />
  );
});
