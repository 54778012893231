import { FormInputNumber } from 'components/FormInputNumber/FormInputNumber';
import { FormSearchInfiniteScroll } from 'components/FormSearchInfiniteScroll/FormSearchInfiniteScroll';
import { ICrudTypeModalProps } from 'components/Modals/CRUDModal/CRUDModal';
import Decimal from 'decimal.js';
import { t } from 'i18next';
import { observer } from 'mobx-react-lite';
import { MORE_THEN_0, REQUIRED_FIELD_RULE } from 'modules/nriz/nriz.constants';
import { obrasciStore } from 'modules/obrasci/obrasci.store';
import { kom1ObrazacStore } from 'modules/obrasci/store/kom1/kom1.store';
import { IGetPredato, IGetUpravljanje } from 'modules/obrasci/store/kom1/kom1.types';
import { ISifarnikInitial } from 'modules/sifarnici/sifarnici.types';
import { IGetWasteCatalogue } from 'modules/sifarnici/store/wasteCatalogue/wasteCatalogue.types';
import React, { useEffect } from 'react';

export const UpravljanjeKomunalnimOtpadom: React.FC<ICrudTypeModalProps> = observer(props => {
  const defaultWayOfDeterminingTheQuantity: ISifarnikInitial | undefined = (
    kom1ObrazacStore.getUpravljanjeForm as IGetUpravljanje
  ).method_basis_code_id
    ? {
        label: (kom1ObrazacStore.getUpravljanjeForm as IGetUpravljanje).method_basis_code.name_sr,
        value: (kom1ObrazacStore.getUpravljanjeForm as IGetUpravljanje).method_basis_code_id,
      }
    : undefined;

  const defaultIndexNumberWaste: ISifarnikInitial | undefined = (kom1ObrazacStore.getUpravljanjeForm as IGetUpravljanje)
    .waste_catalogue_id
    ? {
        label: `${(kom1ObrazacStore.getUpravljanjeForm as IGetUpravljanje).waste_catalogue.index_number} ${(
          kom1ObrazacStore.getUpravljanjeForm as IGetUpravljanje
        ).waste_catalogue.waste_sr.toUpperCase()}`,
        value: (kom1ObrazacStore.getUpravljanjeForm as IGetUpravljanje).waste_catalogue_id,
      }
    : undefined;

  const indexNumberWasteOptions = (options: IGetWasteCatalogue[]) =>
    options.map(option => ({
      label: `${option.index_number} ${option.waste_sr.toUpperCase()}`,
      value: option.id,
    }));

  useEffect(() => {
    if (kom1ObrazacStore.upravljanje_form.kom1_nacin_predaje_otpada.length) {
      props.form.validateFields(['ukupna_kolicina_sakupljenog_ili_preuzetog_otpada_u_izvestajnoj_godini']);
    }
  }, [kom1ObrazacStore.predato_form]);

  const readOnly = obrasciStore.zakljucanObrazac;

  return (
    <div>
      <div style={{ fontWeight: 'bold' }}>{t('OBRASCI.KOM_1.MANAGEMENT.MUNICIPAL_WASTE_MANAGEMENT')}</div>
      <FormSearchInfiniteScroll
        showSearch={true}
        formName={'waste_catalogue_id'}
        fetchOptinsList={kom1ObrazacStore.fetchWasteCatalougeList}
        labelOptionAccessor="index_number"
        valueOptionAccessor="id"
        inputName={'waste_catalogue_id'}
        label={t('OBRASCI.KOM_1.MANAGEMENT.WASTE_INDEX_NUMBER_FROM_THE_WASTE_CATALOG')}
        rules={[
          REQUIRED_FIELD_RULE(true),
          // {
          //   validator(_, value) {
          //     if (
          //       kom1ObrazacStore.getUpravljanjeForm.waste_catalogue_id !== value &&
          //       kom1ObrazacStore.initialState.upravljanje.some(item => {
          //         return value === item.waste_catalogue_id;
          //       })
          //     ) {
          //       return Promise.reject(new Error(t('OBRASCI.GIO4.VALIDATION.WASTE_CATALOGUE_EXISTS')));
          //     }
          //     return Promise.resolve();
          //   },
          // },
        ]}
        colon={true}
        defaultOption={defaultIndexNumberWaste}
        optionsMaker={indexNumberWasteOptions}
        placeholder={''}
        readOnly={readOnly}
      />
      <FormInputNumber
        name="ukupna_kolicina_sakupljenog_ili_preuzetog_otpada_u_izvestajnoj_godini"
        rules={[
          REQUIRED_FIELD_RULE(true),
          ...MORE_THEN_0,
          {
            validator(_, value) {
              let sum = new Decimal(0);
              (kom1ObrazacStore.upravljanje_form as IGetUpravljanje).kom1_nacin_predaje_otpada.map(
                (item: IGetPredato) => (sum = sum.plus(item.kolicina ?? 0))
              );
              if (sum.toNumber() > value) {
                return Promise.reject(new Error('Mora biti veća ili jednaka zbiru svih količina iz tabele'));
              }

              return Promise.resolve();
            },
          },
        ]}
        label={t('OBRASCI.KOM_1.MANAGEMENT.THE_TOTAL_AMOUNT_OF_COLLECTED_OR_ACCEPTED_WASTE_IN_REPORTING_YEAR')}
        placeholder={''}
        inputName={'ukupna_kolicina_sakupljenog_ili_preuzetog_otpada_u_izvestajnoj_godini'}
        colon={true}
        readOnly={readOnly}
      />
      <FormSearchInfiniteScroll
        showSearch={true}
        formName={'method_basis_code_id'}
        rules={[REQUIRED_FIELD_RULE(true)]}
        fetchOptinsList={kom1ObrazacStore.fetchMethodBasisCode}
        labelOptionAccessor="name_sr"
        valueOptionAccessor="id"
        inputName={'method_basis_code_id'}
        label={t('OBRASCI.KOM_1.MANAGEMENT.WAY_OF_DETERMINING_THE_QUANTITY')}
        colon={true}
        defaultOption={defaultWayOfDeterminingTheQuantity}
        placeholder={''}
        readOnly={readOnly}
      />
      <FormInputNumber
        rules={[REQUIRED_FIELD_RULE(true), ...MORE_THEN_0]}
        name="kolicina_sakupljenog_otpada_iz_domacinstva"
        label={t('OBRASCI.KOM_1.MANAGEMENT.THE_AMOUNT_OF_WASTE_COLLECTED_FROM_HOUSEHOLDS')}
        placeholder={''}
        inputName={'kolicina_sakupljenog_otpada_iz_domacinstva'}
        colon={true}
        readOnly={readOnly}
      />
    </div>
  );
});
