import { IBaseObrazacStore, IPaginatedResponse, IPagination } from 'typescript/NrizTypes';
import {
  IGetGoriva,
  IGetProizvodiObrazac1,
  IGetSirovine,
  IGoriva,
  IGetObrazac1,
  IPostObrazac1,
  IProizvodiObrazac1,
  ISirovine,
  IGetAktivnostiObrazac1,
} from './obrazac1.types';
import {
  GORIVO_INITIAL_STATE,
  OBRAZAC_1_INITIAL_STATE,
  PROIZVODI_OBRAZAC_1_INITIAL_STATE,
  SIROVINE_INITIAL_STATE,
} from './obrazac1.constants';
import { computed, makeAutoObservable, observable } from 'mobx';
import { obrasciService } from 'modules/obrasci/obrasci.service';
import to from 'await-to-js';
import { TIPOVI_OBRAZACA } from 'modules/obrasci/obrasci.types';
import dayjs from 'dayjs';
import { formStore } from 'store';
import { obrasciStore } from 'modules/obrasci/obrasci.store';
import { nrizService } from 'modules/nriz/nriz.service';
import { sifarniciStore } from 'modules/sifarnici/sifarnici.store';
import { SIFARNICI_ROUTES } from 'modules/sifarnici/sifarnici.types';

import { arrayToObject } from 'utils/arrayToObject';
import { handleSuccessNotify } from 'utils/succesHandling';
import { IGetFuelType } from 'modules/sifarnici/store/fuelType/fuelType.types';
import { IGetUnitCode } from 'modules/sifarnici/store/unitCode/unitCode.types';
import { IGetWorkingRegime } from 'modules/sifarnici/store/workingRegime/workingRegime.types';
import { activity1Service } from 'modules/activityList1/activityList1.service';
import { obrazac1Service } from './obrazac1.service';

class Obrazac1Store implements IBaseObrazacStore<IGetObrazac1, IPostObrazac1> {
  initialState: IPostObrazac1 | IGetObrazac1 = OBRAZAC_1_INITIAL_STATE;
  goriva_form: IGoriva | IGetGoriva = GORIVO_INITIAL_STATE;
  proizvodi_form: IProizvodiObrazac1 | IGetProizvodiObrazac1 = PROIZVODI_OBRAZAC_1_INITIAL_STATE;
  sirovine_form: ISirovine | IGetSirovine = SIROVINE_INITIAL_STATE;
  showIfSezonski = false;
  aktivnosti: IGetAktivnostiObrazac1[] = [];
  invalidActivities = false;

  constructor() {
    makeAutoObservable(this, {
      initialState: observable,
      goriva_form: observable,
      proizvodi_form: observable,
      sirovine_form: observable,
      showIfSezonski: observable,

      getGorivoForm: computed,
      getProizvodiForm: computed,
      getSirovineForm: computed,
    });
  }

  get getGorivoForm() {
    return this.goriva_form;
  }

  get getProizvodiForm() {
    return this.proizvodi_form;
  }
  get getSirovineForm() {
    return this.sirovine_form;
  }

  handleChange(key: string, value: any) {
    (this as any)[key] = value;
  }

  handleInitialStateChange(key: string, value: any) {
    (this as any).initialState[key] = value;
  }

  validateActivities = async (postrojenje_id: string = obrasciStore.opste.postrojenje_id) => {
    const [err, res] = await to(
      activity1Service.fetchActivityList1({
        postrojenje_id: postrojenje_id,
        page: 1,
        size: 50,
      })
    );
    if (err || !res) return false;

    const { areActivitiesValid, activities } = obrazac1Service.validateLocalActivities(this.aktivnosti, res.items);

    if (!areActivitiesValid) this.handleChange('aktivnosti', activities);

    return areActivitiesValid;
  };

  loadData = async (id: string) => {
    const [err, res] = await to(obrasciService.fetchSingleObrazac<IGetObrazac1>(TIPOVI_OBRAZACA.OBRAZAC_1, id));
    if (err) return;

    if (res.radni_rezim.name_sr === 'Sezonski') {
      this.handleChange('showIfSezonski', true);
    } else {
      this.handleChange('showIfSezonski', false);
    }
    if (Number(res.godina) >= 2023) this.handleChange('aktivnosti', res.ukupna_kolicina_po_aktivnostima);

    const newPocetakSezone = res.pocetak_sezone_mesec?.toString();
    const newKrajSezone = res.kraj_sezone_mesec?.toString();

    const newGodina = dayjs().year((res.godina as any) * 1);
    this.handleChange('initialState', {
      ...res,
      godina: newGodina,
      pocetak_sezone_mesec: newPocetakSezone,
      kraj_sezone_mesec: newKrajSezone,
    });
    return res;
  };

  setData?: ((res: IGetObrazac1) => void) | undefined;

  postData = async (payload: IPostObrazac1) => {
    if (Number(obrasciStore.opste.godina) >= 2023)
      payload = { ...payload, ukupna_kolicina_po_aktivnostima: this.aktivnosti };
    const validObrazac = obrasciService.filtersSameObrazac(obrasciStore.real_obrazac_type as TIPOVI_OBRAZACA);
    const validPayload = { ...payload, ...validObrazac };
    const [err, res] = await to(
      obrasciService.postSingleObrazac<IPostObrazac1>(
        TIPOVI_OBRAZACA.OBRAZAC_1,
        nrizService.setEmptyValuesToNull(validPayload)
      )
    );
    if (err || !res) return;

    formStore.handleChange('goBack', true);
    handleSuccessNotify();
  };

  putData = async (payload: any) => {
    if (Number(obrasciStore.opste.godina) >= 2023) {
      const [err, res] = await to(this.validateActivities());
      if (err || !res) return this.handleChange('invalidActivities', true);
      if (res) payload = { ...payload, ukupna_kolicina_po_aktivnostima: this.aktivnosti };
    }
    const [err, res] = await to(
      obrasciService.putSingleObrazac<IPostObrazac1>(TIPOVI_OBRAZACA.OBRAZAC_1, payload.id, payload)
    );
    if (err || !res) return;
    formStore.handleChange('goBack', true);
    handleSuccessNotify();
  };

  async deleteData(id: string) {
    const [err, res] = await to(obrasciService.deleteSingleObrazac(TIPOVI_OBRAZACA.OBRAZAC_1, id));
    if (err || !res) return;
    formStore.handleChange('goBack', true);
    handleSuccessNotify();
  }

  resetStates = () => {
    this.handleChange('initialState', OBRAZAC_1_INITIAL_STATE);
    this.handleChange('aktivnosti', []);
  };

  submitData = (payload: IPostObrazac1) => {
    const newInitialState = {
      ...OBRAZAC_1_INITIAL_STATE,
      ...payload,
      id: (this.initialState as IGetObrazac1).id,
      godina: obrasciStore.opste.godina,
      goriva: this.initialState.goriva,
      proizvodi: this.initialState.proizvodi,
      sirovine: this.initialState.sirovine,
    } as IGetObrazac1;

    obrasciService.submitFormObrazac({
      payload: newInitialState,
      postForm: this.postData,
      putForm: this.putData,
      deleteForm: this.deleteData,
    });
  };

  //   GORIVO

  submitGorivoForm = (payload: IGoriva) => {
    let newPayload = payload;
    let sifarnikNazivGotiva = {};

    if (formStore.getCrudButtonType === 'submit' || formStore.getCrudButtonType === 'change') {
      if (newPayload.naziv_goriva_id) {
        sifarnikNazivGotiva = nrizService.addSifarnikInObject({
          exId: (this.goriva_form as IGetGoriva).naziv_goriva_id,
          exObj: (this.goriva_form as IGetGoriva).naziv_goriva,
          list: sifarniciStore.list[SIFARNICI_ROUTES.FUEL_TYPE],
          name: 'naziv_goriva',
          id: payload.naziv_goriva_id,
        });
      }
    }

    newPayload = { id: (this.goriva_form as IGetGoriva).id, ...newPayload, ...sifarnikNazivGotiva } as IGetGoriva;

    const newList = obrasciService.submitMicroServiceForm(this.initialState.goriva, newPayload);
    // handleSuccessNotify();
    this.handleChange('goriva_form', GORIVO_INITIAL_STATE);
    this.handleInitialStateChange('goriva', newList);
  };

  fetchFuelType = async (pagination: IPagination, search: string): Promise<IPaginatedResponse<IGetFuelType>> => {
    const response = await sifarniciStore.fetchSifarnikOptions<IGetFuelType>(SIFARNICI_ROUTES.FUEL_TYPE, {
      page: pagination.page,
      size: pagination.size,
      search: search,
      approved: true,
      order_by: '+name_sr',
    });
    return response;
  };

  // DODATNI PODACI

  fetchWorkingRegime = async (
    pagination: IPagination,
    search: string
  ): Promise<IPaginatedResponse<IGetWorkingRegime>> => {
    const response = await sifarniciStore.fetchSifarnikOptions<IGetWorkingRegime>(SIFARNICI_ROUTES.WORKING_REGIME, {
      page: pagination.page,
      size: pagination.size,
      search: search,
      name__in: 'Continual,Semi-continual,Seasonal',
      order_by: 'name_sr',
    });

    return response;
  };

  changeShowIfSezonski = (id: string) => {
    const obj = arrayToObject(sifarniciStore.list[SIFARNICI_ROUTES.WORKING_REGIME]);
    const findItem = obj[id] as IGetWorkingRegime;
    if (findItem?.name_sr === 'Sezonski') {
      this.handleChange('showIfSezonski', true);
    } else {
      this.handleChange('showIfSezonski', false);
    }
  };

  // PROIZVODI
  submitProizvodiForm = (payload: IProizvodiObrazac1) => {
    let newPayload = payload;
    let sifarnikJedinicaMere = {};
    if (formStore.getCrudButtonType === 'submit' || formStore.getCrudButtonType === 'change') {
      if (newPayload.jedinica_mere_id) {
        sifarnikJedinicaMere = nrizService.addSifarnikInObject({
          exId: (this.proizvodi_form as IGetProizvodiObrazac1).jedinica_mere_id,
          exObj: (this.proizvodi_form as IGetProizvodiObrazac1).jedinica_mere,
          list: sifarniciStore.list[SIFARNICI_ROUTES.UNIT_CODE],
          name: 'jedinica_mere',
          id: newPayload.jedinica_mere_id,
        });
      } else {
        newPayload = { ...newPayload, jedinica_mere: {} } as IGetProizvodiObrazac1;
      }
    }
    newPayload = {
      id: (this.proizvodi_form as IGetProizvodiObrazac1).id,
      ...newPayload,
      ...sifarnikJedinicaMere,
    } as IGetProizvodiObrazac1;
    const newList = obrasciService.submitMicroServiceForm(this.initialState.proizvodi, newPayload);
    // handleSuccessNotify();
    this.handleInitialStateChange('proizvodi', newList);
    this.handleChange('proizvodi_form', PROIZVODI_OBRAZAC_1_INITIAL_STATE);
  };

  // SIROVINE
  submitSirovineForm = (payload: ISirovine) => {
    let newPayload = payload;
    let sifarnikJedinicaMere = {};
    if (formStore.getCrudButtonType === 'submit' || formStore.getCrudButtonType === 'change') {
      if (newPayload.jedinica_mere_id) {
        sifarnikJedinicaMere = nrizService.addSifarnikInObject({
          exId: (this.sirovine_form as IGetSirovine).jedinica_mere_id,
          exObj: (this.sirovine_form as IGetSirovine).jedinica_mere,
          list: sifarniciStore.list[SIFARNICI_ROUTES.UNIT_CODE],
          name: 'jedinica_mere',
          id: newPayload.jedinica_mere_id,
        });
      }
    }
    newPayload = {
      id: (this.sirovine_form as IGetSirovine).id,
      ...newPayload,
      ...sifarnikJedinicaMere,
    } as IGetSirovine;
    const newList = obrasciService.submitMicroServiceForm(this.initialState.sirovine, newPayload);
    // handleSuccessNotify();
    this.handleInitialStateChange('sirovine', newList);
    this.handleChange('sirovine_form', SIROVINE_INITIAL_STATE);
  };

  fetchUnitCode = async (pagination: IPagination, search: string): Promise<IPaginatedResponse<IGetUnitCode>> => {
    const response = await sifarniciStore.fetchSifarnikOptions<IGetUnitCode>(SIFARNICI_ROUTES.UNIT_CODE, {
      page: pagination.page,
      size: pagination.size,
      search: search,
      version: '1,3',
      order_by: '+name_sr',
    });
    return response;
  };
}
export const obrazac1Store = new Obrazac1Store();
