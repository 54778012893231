import { IEmptyResponse, IPostResponse } from 'typescript/NrizTypes';
import { unsanitaryLandfillRepo } from './unsanitarylandfill.repo';
import { IGetUnsanitaryLandfill, IPostUnsanitaryLandfill, RequestUnsanitaryLandfill } from './unsanitarylandfill.types';

class UnsanitaryLandfillService {
  fetchUnsanitaryLandfillList = (payload: RequestUnsanitaryLandfill): IPaginatedApiResponse<IGetUnsanitaryLandfill> => {
    return unsanitaryLandfillRepo.fetchUnsanitaryLandfillList(payload);
  };

  postUnsanitaryLandfill = (unsanitaryLandfill: IPostUnsanitaryLandfill): Promise<IPostResponse> => {
    return unsanitaryLandfillRepo.postUnsanitaryLandfill(unsanitaryLandfill);
  };
  putUnsanitaryLandfill = (unsanitaryLandfill: IGetUnsanitaryLandfill): Promise<IEmptyResponse> => {
    return unsanitaryLandfillRepo.putUnsanitaryLandfill(unsanitaryLandfill);
  };

  aktivirajNeasnitarnuDeponiju = (id: string): Promise<IEmptyResponse> => {
    return unsanitaryLandfillRepo.aktivirajNeasnitarnuDeponiju(id);
  };
  deaktivirajNeasnitarnuDeponiju = (id: string): Promise<IEmptyResponse> => {
    return unsanitaryLandfillRepo.deaktivirajNeasnitarnuDeponiju(id);
  };
}

export const unsanitaryLandfillService = new UnsanitaryLandfillService();
