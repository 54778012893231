import { axios } from 'modules/axios';
import queryString from 'query-string';
import { IPaginatedApiResponse } from 'typescript/NrizTypes';
import { IGetIstorijaPromena } from './istorijaPromena.types';

const fetchIstorijaPromenaList = (filters: any): IPaginatedApiResponse<IGetIstorijaPromena> => {
  const query = queryString.stringify(filters);
  return axios.get(`audit-log/?${query}`);
};
export const istorijaPromenaRepo = {
  fetchIstorijaPromenaList,
};
