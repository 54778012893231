import { ColumnsType } from 'antd/es/table';
import { EditButtonProps, RowEditPreviewButton } from 'components/RowEditPreviewButton/RowEditPreviewButton';
import TableData from 'components/TableData/TableData';
import { observer } from 'mobx-react-lite';
import { obrasciStore } from 'modules/obrasci/obrasci.store';
import { gveStore } from 'modules/obrasci/store/gve/gve.store';
import { IGetBilansGve } from 'modules/obrasci/store/gve/gve.types';
import { useTranslation } from 'react-i18next';

const EditGveEmisijeBilansButton: React.FC<EditButtonProps<IGetBilansGve>> = observer(({ record }) => {
  return (
    <RowEditPreviewButton
      name="gve-bilans"
      modalName="gve-bilans-modal"
      onClick={() => {
        gveStore.handleChange('filterNacinOdredjivanjaId', record.nacin_odredjivanja_normalan_rad_id);
        gveStore.handleChange('bilans_form', record);
      }}
      preview={obrasciStore.zakljucanObrazac}
    />
  );
});

export const GveTableEmisijaBilans: React.FC = observer(() => {
  const { t } = useTranslation();

  const baseColumns: ColumnsType<IGetBilansGve> = [
    {
      title: `${t(['OBRASCI.GVE.NAZIV_ZAGADJUJUCE_MATERIJE'])}`,
      width: 100,
      dataIndex: ['naziv_zagadjujuce_materije', 'name_sr'],
      key: 'name_sr',
    },
    {
      title: `${t('OBRASCI.GVE.SREDNJA_GODISNJA_VREDNOST')}`,
      dataIndex: 'srednja_godisnja_izmerena_vrednost',
      key: 'srednja_godisnja_izmerena_vrednost',
      width: 100,
    },
    {
      title: `${t('OBRASCI.GVE.EMITOVANE_G_H')}`,
      dataIndex: 'emitovana_kolicina_normalan_rad_g_h',
      key: 'emitovana_kolicina_normalan_rad_g_h',
      width: 100,
    },
    {
      title: `${t('OBRASCI.GVE.EMITOVANE_KG_GOD')}`,
      dataIndex: 'emitovana_kolicina_normalan_rad_kg_god',
      key: 'emitovana_kolicina_normalan_rad_kg_god',
      width: 100,
    },
    {
      title: `${t('OBRASCI.OBRAZAC3.NACIN_ODREDJIVANJA')}`,
      render: (_, _record) => <p>Merenje</p>,
      key: 'nacin_odredjivanja',
      width: 100,
    },

    {
      title: `${t(['OBRASCI.GVE.METODA_UTVRDJIVANJA'])}`,
      width: 100,
      // dataIndex: ['metod_utvrdjivanja', 'name_sr'],
      render: (_, record) => <p>{record.metod_utvrdjivanja.name_sr.substring(0, 10) ?? ''}</p>,
      key: 'name_sr',
    },
  ];

  const editButtonColumn: ColumnsType<IGetBilansGve> = [
    {
      title: `${t('COMPANY.ADDITIONALLY')}`,
      key: 'operation',
      fixed: 'right',
      width: 150,
      align: 'center',
      render: (_, record: IGetBilansGve) => <EditGveEmisijeBilansButton record={record} />,
    },
  ];

  const validColumns = gveStore.showEmisijeUVazduh ? baseColumns : [...baseColumns, ...editButtonColumn];

  return <TableData name="emisija-bilans-gve" columns={validColumns} dataSource={[...gveStore.initialState.bilans]} />;
});
