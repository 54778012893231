import React from 'react';
import { observer } from 'mobx-react-lite';
import { TableDivljaDeponija } from './components/TableDivljaDeponija/TableDivljaDeponija';
import { InsideContentWrapper } from 'components/InsideContentWrapper/InsideContentWrapper';
import { WizardObrazacListHeader } from 'components/WizardObrazacListHeader/WizardObrazacListHeader';

const HeaderDivljaDeponija: React.FC = observer(() => {
  return <WizardObrazacListHeader name={'divlje-deponije'} modalName={'divlja-deponija-modal'} />;
});

export const DivljeDeponijeWizardTab: React.FC = observer(() => {
  return (
    <InsideContentWrapper header={<HeaderDivljaDeponija />}>
      <TableDivljaDeponija />
    </InsideContentWrapper>
  );
});
