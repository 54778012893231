import { IBaseObrazacStore, IPaginatedResponse, IPagination } from 'typescript/NrizTypes';
import { IGetDep2, IDivljeDeponije, IGetDivljeDeponije, IPostDEP2Obrazac } from './dep2.types';
import { action, computed, makeAutoObservable, observable } from 'mobx';
import to from 'await-to-js';
import { obrasciService } from 'modules/obrasci/obrasci.service';
import { TIPOVI_OBRAZACA } from 'modules/obrasci/obrasci.types';
import { nrizService } from 'modules/nriz/nriz.service';

import { formStore } from 'store';
import { SIFARNICI_ROUTES } from 'modules/sifarnici/sifarnici.types';
import dayjs from 'dayjs';
import { sifarniciStore } from 'modules/sifarnici/sifarnici.store';
import { DEP2_INITIAL_STATE, DIVLJA_DEPONIJA_INITIAL_STATE } from './dep2.constants';
import { obrasciStore } from 'modules/obrasci/obrasci.store';
import { handleSuccessNotify } from 'utils/succesHandling';
import { IGetDepFormAnswers } from 'modules/sifarnici/store/depFormsAnswers/depFormsAnswers.types';
import { IGetMesto } from 'modules/sifarnici/store/mesto/mesto.types';

class DEP2ObrazacStore implements IBaseObrazacStore<IGetDep2, IPostDEP2Obrazac> {
  initialState: IPostDEP2Obrazac | IGetDep2 = DEP2_INITIAL_STATE;
  divlja_deponija: IDivljeDeponije | IGetDivljeDeponije = DIVLJA_DEPONIJA_INITIAL_STATE;

  constructor() {
    makeAutoObservable(this, {
      initialState: observable,
      divlja_deponija: observable,

      getInitialState: computed,

      submitDivljaDeponijaForm: action,
      handleInitialStateChange: action,
      handleChange: action,
    });
  }

  resetStates = () => {
    this.handleChange('initialState', DEP2_INITIAL_STATE);
  };

  get getInitialState() {
    return this.initialState;
  }

  get getDivljaDeponija() {
    return this.divlja_deponija;
  }

  // this is mutation state function

  handleChange(key: string, value: any) {
    (this as any)[key] = value;
  }

  handleInitialStateChange(key: string, value: any) {
    (this as any).initialState[key] = value;
  }

  loadData = async (id: string) => {
    const [err, res] = await to(obrasciService.fetchSingleObrazac<IGetDep2>(TIPOVI_OBRAZACA.DEP2, id));
    if (err) return;
    const newGodina = dayjs().year((res.godina as any) * 1);
    const newArray = { ...res, godina: newGodina };
    this.handleChange('initialState', newArray);

    return res;
  };

  async postData(payload: IPostDEP2Obrazac) {
    const [err, res] = await to(obrasciService.postSingleObrazac<IPostDEP2Obrazac>(TIPOVI_OBRAZACA.DEP2, payload));
    if (err || !res) return;
    formStore.handleChange('goBack', true);
    handleSuccessNotify();
  }

  async deleteData(id: string) {
    const [err, res] = await to(obrasciService.deleteSingleObrazac(TIPOVI_OBRAZACA.DEP2, id));
    if (err || !res) return;
    formStore.handleChange('goBack', true);
    handleSuccessNotify();
  }

  async putData(payload: any) {
    const [err, res] = await to(
      obrasciService.putSingleObrazac<IPostDEP2Obrazac>(TIPOVI_OBRAZACA.DEP2, payload.id, payload)
    );
    if (err || !res) return;
    formStore.handleChange('goBack', true);
    handleSuccessNotify();
  }

  submitData = (payload: IPostDEP2Obrazac) => {
    const newInitialState = {
      ...DEP2_INITIAL_STATE,
      id: (this.initialState as IGetDep2).id,
      ...payload,
      divlje_deponije: this.initialState.divlje_deponije,
      godina: obrasciStore.opste.godina,
    } as IGetDep2;

    obrasciService.submitFormObrazac({
      payload: newInitialState,
      postForm: this.postData,
      putForm: this.putData,
      deleteForm: this.deleteData,
    });
  };

  submitDivljaDeponijaForm = (payload: IDivljeDeponije) => {
    let newPayload = payload;
    let sifarnikMesto = {};
    let sifarnikFormAnswers = {};

    if (formStore.getCrudButtonType === 'submit' || formStore.getCrudButtonType === 'change') {
      if (newPayload.mesto_id) {
        sifarnikMesto = nrizService.addSifarnikInObject({
          exId: this.divlja_deponija.mesto_id,
          exObj: (this.divlja_deponija as IGetDivljeDeponije).mesto,
          list: sifarniciStore.list[SIFARNICI_ROUTES.MESTO],
          name: 'mesto',
          id: payload.mesto_id,
        });
      } else {
        newPayload = { ...newPayload, mesto: {} } as IGetDivljeDeponije;
      }
      if (newPayload.dep_forms_answers_id) {
        sifarnikFormAnswers = nrizService.addSifarnikInObject({
          exId: this.divlja_deponija.dep_forms_answers_id,
          exObj: (this.divlja_deponija as IGetDivljeDeponije).dep_forms_answers,
          list: sifarniciStore.list[SIFARNICI_ROUTES.DEP_FORMS_ANSWERS],
          name: 'dep_forms_answers',
          id: payload.dep_forms_answers_id,
        });
      } else {
        newPayload = { ...newPayload, dep_forms_answers: {} } as IGetDivljeDeponije;
      }
    }

    newPayload = {
      id: (this.divlja_deponija as IGetDivljeDeponije).id,
      ...newPayload,
      ...sifarnikMesto,
      ...sifarnikFormAnswers,
    } as IGetDivljeDeponije;

    const newList = obrasciService.submitMicroServiceForm(this.initialState.divlje_deponije, newPayload);
    // handleSuccessNotify();
    this.handleInitialStateChange('divlje_deponije', newList);
    this.handleChange('divlja_deponija', DIVLJA_DEPONIJA_INITIAL_STATE);
  };

  //  SIFARNICI

  fetchMesto = async (pagination: IPagination, search: string): Promise<IPaginatedResponse<IGetMesto>> => {
    const response = await sifarniciStore.fetchSifarnikOptions<IGetMesto>(SIFARNICI_ROUTES.MESTO, {
      page: pagination.page,
      size: pagination.size,
      search: search,
      location_code__neq: 999999,
      order_by: '+display_name',
    });
    return response;
  };

  fetchDepFormAnswers = async (
    pagination: IPagination,
    search: string
  ): Promise<IPaginatedResponse<IGetDepFormAnswers>> => {
    const response = await sifarniciStore.fetchSifarnikOptions<IGetDepFormAnswers>(SIFARNICI_ROUTES.DEP_FORMS_ANSWERS, {
      page: pagination.page,
      size: pagination.size,
      search: search,
      answercode__in: '004,104',
      order_by: '+answer_sr',
    });
    return response;
  };
}

export const dep2ObrazacStore = new DEP2ObrazacStore();
