import { Form } from 'antd';
import { FormInstance, useForm } from 'antd/es/form/Form';
import { FilterTableForm } from 'components/TableData/FilterTableForm/FilterTableForm';
import { observer } from 'mobx-react-lite';
import { paginationInitialState } from 'modules/nriz/nriz.constants';
import { readOnlySifarnikTypes } from 'modules/sifarnici/sifarnici.constants';
import { sifarniciService } from 'modules/sifarnici/sifarnici.service';
import { sifarniciStore } from 'modules/sifarnici/sifarnici.store';
import { ISifarniciFormData } from 'modules/sifarnici/sifarnici.types';
import { IS_ACTIVE_SWITCH } from 'modules/sifarnici/sifarniciFormObj.constants';

import React, { useEffect, useMemo } from 'react';
import { filtersStore } from 'store/filteri.store';

import { IFilterTableProps } from 'typescript/NrizTypes';

export const SifarniciFilters: React.FC = observer(() => {
  const [sifarniciFilterForm] = useForm();

  const components = useMemo(() => {
    return sifarniciService.filtersSifarniciFinder(sifarniciStore.sifarnik_type);
  }, [Form.useWatch([], sifarniciFilterForm), sifarniciStore.sifarnik_type]) as ISifarniciFormData;

  useEffect(() => {
    filtersStore.handleChange('sifarnici', {});
  }, [sifarniciStore.sifarnik_type]);

  const fetchSifarniciList = () => {
    return sifarniciStore.fetchSifarnikListTable(paginationInitialState);
  };

  const data = {
    form: sifarniciFilterForm as FormInstance<any>,
    filterOptions: !readOnlySifarnikTypes.includes(sifarniciStore.sifarnik_type)
      ? sifarniciService.removeRules([...components.components(sifarniciFilterForm), IS_ACTIVE_SWITCH])
      : components.components(sifarniciFilterForm),
    initialState: {
      ...components.initialState,
      order_by: '',
      is_active: '',
    },
    filterName: 'sifarnici',
    resetForm: components.initialState,
    fetchTableList: fetchSifarniciList,
  } as IFilterTableProps;
  return <FilterTableForm data={data} />;
});
