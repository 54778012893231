import { FormSearchInfiniteScroll } from 'components/FormSearchInfiniteScroll/FormSearchInfiniteScroll';
import { useTranslation } from 'react-i18next';
import { observer } from 'mobx-react-lite';
import { dep1Store } from 'modules/obrasci/store/dep1/dep1.store';
import { ISifarnikInitial } from 'modules/sifarnici/sifarnici.types';
import { IObrazacDep1Mesto } from 'modules/obrasci/store/dep1/dep1.types';
import { obrasciStore } from 'modules/obrasci/obrasci.store';

export const NewNaseljeGradoviForm: React.FC = observer(() => {
  const { t } = useTranslation();

  const defaMestoOption: ISifarnikInitial | undefined = dep1Store.mesto_form.mesto_id
    ? {
        label: (dep1Store.mesto_form as IObrazacDep1Mesto).mesto.display_name,
        value: dep1Store.mesto_form.mesto_id,
      }
    : undefined;

  const readOnly = obrasciStore.zakljucanObrazac;

  return (
    <FormSearchInfiniteScroll
      showSearch={true}
      formName={'mesto_id'}
      fetchOptinsList={dep1Store.fetchMesto}
      labelOptionAccessor="display_name"
      valueOptionAccessor="id"
      label={t('COMPANY.LOCATION')}
      labelCol={{ span: 12 }}
      wrapperCol={{ span: 12 }}
      inputName={'mesto_id'}
      defaultOption={defaMestoOption}
      placeholder={t('COMPANY.LOCATION')}
      rules={[
        {
          validator(_, value) {
            if (
              dep1Store.mesto_form.mesto_id !== value &&
              dep1Store.initialState.obrazac_dep1_mesta.some(item => {
                return value === item.mesto_id;
              })
            ) {
              return Promise.reject(new Error(t('VALIDATION.ALREADY_EXISTS')));
            }
            return Promise.resolve();
          },
        },
      ]}
      readOnly={readOnly}
    />
  );
});
