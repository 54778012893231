import { EditOutlined } from '@ant-design/icons';
import { Checkbox } from 'antd';
import { ColumnsType } from 'antd/es/table';
import { Button } from 'components/Button/Button';
import TableData from 'components/TableData/TableData';
import { observer } from 'mobx-react-lite';
import { soilStore } from 'modules/sourcesOfPollution/soil/soil.store';
import { IGetSoil } from 'modules/sourcesOfPollution/soil/soil.types';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { modalStore } from 'store';

export const SoilListTable: React.FC = observer(() => {
  const { t } = useTranslation();

  const columns: ColumnsType<IGetSoil> = [
    {
      title: `${t('COMPANY.ACTIVE')}`,
      width: '100px',
      key: 'aktivan',
      align: 'center',
      render: (_: any, record: IGetSoil) => <Checkbox checked={!!record?.aktivan} disabled={true} />,
    },
    {
      title: `${t('POLLUTION.SOIL.LOCATION_NUMBER')}`,
      width: '150px',
      dataIndex: 'broj_lokacije',
      key: 'broj_lokacije',
    },
    {
      title: `${t('POLLUTION.SOIL.LOCATION_NAME')}`,
      width: '300px',
      dataIndex: 'naziv_lokacije',
      key: 'naziv_lokacije',
    },
    {
      title: '',
      key: 'operation',
      fixed: 'right',
      width: '50px',
      render: (_, record) => {
        return (
          <Button
            icon={<EditOutlined />}
            name={'edit-soil'}
            onClick={() => {
              soilStore.setSoil(record);
              modalStore.changeModalName('soil-modal');
            }}
          />
        );
      },
    },
  ];
  return (
    <TableData
      name="soil"
      columns={columns}
      dataSource={[...soilStore.getSoilList]}
      refetchData={soilStore.fetchSoilList}
      storePagination={soilStore.soilListPagination}
    />
  );
});
