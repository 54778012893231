import { ColumnsType } from 'antd/es/table';
import { EditButtonProps, RowEditPreviewButton } from 'components/RowEditPreviewButton/RowEditPreviewButton';
import TableData from 'components/TableData/TableData';
import { observer } from 'mobx-react-lite';
import { obrasciStore } from 'modules/obrasci/obrasci.store';
import { gio3ObrazacStore } from 'modules/obrasci/store/gio3/gio3.store';
import { IGetPostrojenjeGio3 } from 'modules/obrasci/store/gio3/gio3.types';
import React from 'react';
import { useTranslation } from 'react-i18next';

const EditGio3PostrojenjaButton: React.FC<EditButtonProps<IGetPostrojenjeGio3>> = observer(({ record }) => {
  return (
    <RowEditPreviewButton
      name="gio3-postrojenja"
      modalName="gio3-postrojenja-modal"
      onClick={() => gio3ObrazacStore.handleChange('postrojenja_form', record)}
      preview={obrasciStore.zakljucanObrazac}
    />
  );
});

export const TablePostrojenjaGio3: React.FC = observer(() => {
  const { t } = useTranslation();

  const baseColumns: ColumnsType<IGetPostrojenjeGio3> = [
    {
      title: `${t('ADDRESS')}`,
      width: 200,
      dataIndex: 'adresa_postrojenja',
      key: 'waste',
    },
    {
      title: `${t('COMPANY.LOCATION')}`,
      width: 200,
      dataIndex: ['mesto_postrojenja', 'display_name'],
      key: 'display_name',
    },
    {
      title: `${t('COMPANY.LATITUDE')}`,
      dataIndex: 'geografska_sirina',
      key: 'geografska_sirina',
      width: 200,
    },
    {
      title: `${t('COMPANY.LONGITUDE')}`,
      width: 200,
      dataIndex: 'geografska_duzina',
      key: 'geografska_duzina',
    },

    {
      title: `${t('COMPANY.ADDITIONALLY')}`,
      key: 'operation',
      fixed: 'right',
      width: 150,
      align: 'center',
      render: (_, record: IGetPostrojenjeGio3) => <EditGio3PostrojenjaButton record={record} />,
    },
  ];

  return (
    <TableData
      name="postrojenja-gio3"
      scrollConfig={['#obrazacTableHeader', '#obrazacWizardHeader']}
      columns={baseColumns}
      dataSource={[...gio3ObrazacStore.initialState.postrojenja]}
    />
  );
});
