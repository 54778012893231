import { NewPibForm } from 'components/NewForms';
import { observer } from 'mobx-react-lite';
import styles from './StartPibForm.module.scss';
import { LogoWithForm } from 'components/LogoWithForm/LogoWithForm';
import { LogOutButton } from 'components/LogOutButton/LogOutButton';
import { PibFormInfoModal } from 'components/Modals/PibFormInfoModal/PibFormInfoModal';

export const StartPibForm = observer(() => {
  return (
    <LogoWithForm
      title="Registracija Preduzeća"
      component={
        <div className={styles.pibFormDiv}>
          <NewPibForm />
          <PibFormInfoModal />
          <div>
            <LogOutButton />
          </div>
        </div>
      }
    />
  );
});
