import { observer } from 'mobx-react-lite';
import { Col } from 'antd';
import styles from './Ptp2Opste.module.scss';
import { OpsteGodinaPreduzecePostrojenje } from '../../components/OpsteGodinaPreduzecePostrojenje/OpsteGodinaPreduzecePostrojenje';
import { useEffect } from 'react';
import { obrasciStore } from 'modules/obrasci/obrasci.store';
import { ptp2ObrazacStore } from 'modules/obrasci/store/ptp2/ptp2.store';
import { useObrazacExistsCheck } from 'hooks/useObrazacExistsCheck';

export const Ptp2OpsteWizardTab: React.FC = observer(() => {
  useEffect(() => {
    if (obrasciStore.opste.godina && obrasciStore.opste.preduzece_id !== '') {
      obrasciStore.setDisabledTabButton(false);
    } else obrasciStore.setDisabledTabButton(true);
  }, [obrasciStore.opste]);

  const checkIfObrazacExists = useObrazacExistsCheck();

  return (
    <Col span={12} className={styles.ptp2Opste}>
      <OpsteGodinaPreduzecePostrojenje
        withPostrojenje={false}
        store={ptp2ObrazacStore}
        onChangeGodina={() => checkIfObrazacExists()}
      />
    </Col>
  );
});
