import { ColumnsType } from 'antd/es/table';
import { EditButtonProps, RowEditPreviewButton } from 'components/RowEditPreviewButton/RowEditPreviewButton';
import TableData from 'components/TableData/TableData';
import { observer } from 'mobx-react-lite';
import { obrasciStore } from 'modules/obrasci/obrasci.store';
import { gveStore } from 'modules/obrasci/store/gve/gve.store';
import { IGetIzmereneVrednostiGve } from 'modules/obrasci/store/gve/gve.types';
import { useTranslation } from 'react-i18next';

const EditGveIzmereneVrednostiButton: React.FC<EditButtonProps<IGetIzmereneVrednostiGve>> = observer(({ record }) => {
  return (
    <RowEditPreviewButton
      name="gve-izmerene-vrednosti"
      modalName="gve-izmerene-vrednosti-modal"
      onClick={() => gveStore.handleChange('izmerene_vrednosti_form', record)}
      preview={obrasciStore.zakljucanObrazac}
    />
  );
});

export const GveTableIzmereneVrednosti: React.FC = observer(() => {
  const { t } = useTranslation();

  const baseColumns: ColumnsType<IGetIzmereneVrednostiGve> = [
    {
      title: `${t(['OBRASCI.GVE.NAZIV_ZAGADJUJUCE_MATERIJE'])}`,
      width: '150px',
      dataIndex: ['naziv_zagadjujuce_materije', 'name_sr'],
      key: 'name_sr',
    },
    {
      title: `${t(['OBRASCI.GVE.IZMERENA_VREDNOST'])}`,
      width: '100px',
      dataIndex: 'izmerene_vrednosti',
      key: 'izmerene_vrednosti',
    },
    {
      title: `${t(['OBRASCI.GVE.VREDNOST_ZA_PRORACUN_BILANSA'])}`,
      width: '100px',
      dataIndex: 'vrednost_za_proracun_bilansa',
      key: 'vrednost_za_proracun_bilansa',
      render: (_, record) =>
        record.predznak_izmerene_vrednosti === '=' ? record.izmerene_vrednosti : Number(record.izmerene_vrednosti) / 2,
    },
    {
      title: `${t(['OBRASCI.GVE.GVE_MG_NM'])}`,
      width: '100px',
      dataIndex: 'gve',
      key: 'gve',
    },
    {
      title: `${t(['OBRASCI.GVE.METODA_UTVRDJIVANJA'])}`,
      width: '100px',
      // dataIndex: ['metod_utvrdjivanja', 'name_sr'],
      render: (_, record) => <p>{record.metod_utvrdjivanja.name_sr.substring(0, 10) ?? ''}</p>,
      key: 'name_sr',
    },
    {
      title: `${t(['OBRASCI.GVE.PROTOK_OTPADNOG_GASA'])}`,
      width: '200px',
      dataIndex: 'protok_otpadnog_gasa',
      key: 'protok_otpadnog_gasa',
    },
    {
      title: `${t(['OBRASCI.GVE.MASENI_PROTOK_ZAGADJUJUCE'])}`,
      width: '100px',
      dataIndex: 'maseni_protok_zagadjujuce_materije',
      key: 'maseni_protok_zagadjujuce_materije',
    },
    {
      title: `${t(['OBRASCI.GVE.EMITOVANE_U_AKCIDENTNIM'])}`,
      width: '100px',
      dataIndex: 'emitovane_kolicine_u_akcidentnim_situacijama',
      key: 'emitovane_kolicine_u_akcidentnim_situacijama',
    },

    {
      title: `${t('COMPANY.ADDITIONALLY')}`,
      key: 'operation',
      fixed: 'right',
      width: '70px',
      align: 'center',
      render: (_, record: IGetIzmereneVrednostiGve) => <EditGveIzmereneVrednostiButton record={record} />,
    },
  ];

  return (
    <TableData
      name="izmerene-vrednosti-gve"
      columns={baseColumns}
      dataSource={[...gveStore.emisije_u_vazduh_form.merenja_izmerene_vrednosti_emisija_u_vazduhu]}
    />
  );
});
