import { IGetCompany } from 'modules/company/company.types';
import { IGetFacility } from 'modules/facility/facility.types';
import { IGetApr } from 'modules/sifarnici/store/apr/apr.types';
import { IGetCList } from 'modules/sifarnici/store/cList/cList.types';
import { IGetDRList } from 'modules/sifarnici/store/dRList/dRList.types';
import { IGetMesto } from 'modules/sifarnici/store/mesto/mesto.types';
import { IGetMethodOfPackagingWaste } from 'modules/sifarnici/store/methodOfPackaging/methodOfPackaging.types';
import { IGetNList } from 'modules/sifarnici/store/nList/nList.types';
import { IGetQList } from 'modules/sifarnici/store/qList/qList.types';
import { IGetTypeMeansTransport } from 'modules/sifarnici/store/typeMeansTransport/typeMeansTransport.types';
import { IGetTypeOfWaste } from 'modules/sifarnici/store/typeOfWaste/typeOfWaste.types';
import { IGetWasteCatalogue } from 'modules/sifarnici/store/wasteCatalogue/wasteCatalogue.types';
import { IGetWasteStateCategory } from 'modules/sifarnici/store/wasteStateCategory/wasteStateCategory.types';
import { IGetYList } from 'modules/sifarnici/store/yList/yList.types';
import { IGetUser } from 'modules/user/user.types';

export interface IPostDkoObrazac {
  potvrda_dokumenta: boolean;
  odjava_kretanja: boolean;
  admin_odjava_dokumenta: boolean;
  preduzece_id: string;
  postrojenje_id: string;
  indeksni_broj_otpada_id: string;
  napomena: string;
  admin_napomena: string;
  broj_dokumenta: string;
  type_of_waste_id: string;
  q_lista_id: string;
  dko_deo_a_y_lista_id: string;
  dko_deo_a_y_lista2_id: string;
  dko_deo_a_y_lista3_id: string;
  dko_deo_a_c_lista_id: string;
  dko_deo_a_c_lista2_id: string;
  dko_deo_a_c_lista3_id: string;
  un_broj_klasa: string;
  dko_deo_a_n_lista_id: string;
  dko_deo_a_n_lista2_id: string;
  dko_deo_a_n_lista3_id: string;
  masa_otpada: number | null;
  nacin_pakovanja_otpada_id: string;
  fizicko_stanje_otpada_id: string;
  broj_izvestaja: string;
  datum_izdavanja: string;
  vid_prevoza: string;
  mesto_id: string;
  adresa_odredista: string;
  napomena_deo_a: string;
  proizvodjac_vlasnik_otpada: string;
  predvidjeni_nacin_postupanja_id: string;
  broj_dozvole: string;
  datum_izdavanja_dozvole: string;
  datum_predaje_otpada: string;
  ime_prezime: string;
  broj_mobilnog_telefona: string;
  prevoznik_otpada_id: string;
  telefon_prevoznika: string;
  telefaks_prevoznika: string;
  email: string;
  vrsta_prevoznog_sredstva_id: string;
  registarski_broj_prevoznog_sredstva: string;
  lokacija_utovara_id: string;
  adresa_utovara: string;
  preko_id: string;
  preko2_id: string;
  preko3_id: string;
  lokacija_istovara_id: string;
  adresa_istovara: string;
  broj_dozvole_deo_c: string;
  datum_izdavanja_dozvole_deo_c: string;
  datum_preuzimanja_otpada: string;
  ime_prezime_prevoznika_preuzet: string;
  broj_mobilnog_telefona_preuzet: string;
  datum_predaje_otpada_deo_c: string;
  ime_prezime_prevoznika_predat: string;
  broj_mobilnog_telefona_predat: string;
  primalac_otpada_id: string;
  telefon_primaoca_otpada: string;
  telefaks_primaoca_otpada: string;
  email_deo_d: string;
  primalac_otpada_je: string;
  broj_dozvole_deo_d: string;
  datum_izdavanja_dozvole_deo_d: string;
  datum_preuzimanja_otpada_deo_d: string;
  ime_prezime_primaoca_otpada: string;
  broj_mobilnog_telefona_primaoca_otpada: string;
}

export interface IGetDkoObrazac extends IPostDkoObrazac {
  id: string;
  preduzece: IGetCompany;
  postrojenje: IGetFacility;
  indeksni_broj_otpada: IGetWasteCatalogue;
  type_of_waste: IGetTypeOfWaste;
  q_lista: IGetQList;
  dko_deo_a_y_lista: IGetYList;
  dko_deo_a_y_lista2: IGetYList;
  dko_deo_a_y_lista3: IGetYList;
  dko_deo_a_c_lista: IGetCList;
  dko_deo_a_c_lista2: IGetCList;
  dko_deo_a_c_lista3: IGetCList;
  dko_deo_a_n_lista: IGetNList;
  dko_deo_a_n_lista2: IGetNList;
  dko_deo_a_n_lista3: IGetNList;
  nacin_pakovanja_otpada: IGetMethodOfPackagingWaste;
  fizicko_stanje_otpada: IGetWasteStateCategory;
  mesto: IGetMesto;
  predvidjeni_nacin_postupanja: IGetDRList;
  prevoznik_otpada: Partial<IGetApr>;
  vrsta_prevoznog_sredstva: IGetTypeMeansTransport;
  lokacija_utovara: IGetMesto;
  preko: IGetMesto;
  preko2: IGetMesto;
  preko3: IGetMesto;
  lokacija_istovara: IGetMesto;
  // bice promenjeno na primalac otpada
  primalac_otpada: Partial<IGetApr>;
  kreirao: IGetUser;
  izmenio: IGetUser;
  datum_izmene: string;
  datum_kreiranja: string;
}

export enum VidPrevozaEnum {
  VODENI = 'vodeni',
  DRUMSKI = 'drumski',
  ZELEZNICKI = 'zeleznicki',
}

export enum ProizvodjacVlasnikOtpadaEnum {
  PROIZVODJAC = 'proizvodjac',
  VLASNIK = 'vlasnik',
  OPERATER = 'operater_postrojenja_za_upravljanje_otpadom',
}

export enum PrimalacOtpadaJeEnum {
  POSTROJENJE_ZA_SKLADISTENJE_OTPADA = 'postrojenje_za_skladiste_otpada',
  POSTROJENJE_ZA_TRETMAN_OTPADA = 'postrojenje_za_tretman_otpada',
  POSTROJENJE_ZA_ODLAGANJE_OTPADA = 'postrojenje_za_odlaganje_otpada',
}
