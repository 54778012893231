import { omit } from 'lodash';
import { ApiResponse, axios } from 'modules/axios';
import { ROUTES } from 'modules/nriz/nriz.constants';
import queryString from 'query-string';
import { ResponseUserList, IGetUser, IPostUser, IPostUserResponsibility } from './user.types';
import { IEmptyResponse, IPaginatedApiResponse, IPostResponse } from 'typescript/NrizTypes';
import { ITablePagination } from 'components/TableData/TableData';

const fetchUserList = (payload: ITablePagination): IPaginatedApiResponse<IGetUser> => {
  const query = queryString.stringify(payload);
  return axios.get(`${ROUTES.USER}/?${query}`);
};

const fetchUserCompanyList = (payload: any): IPaginatedApiResponse<IGetUser> => {
  const query = queryString.stringify(payload);
  return axios.get(`${ROUTES.COMPANY}${payload.preduzece_id}${ROUTES.USER}?${query}`);
};

const fetchUserFacilityList = (payload: any): ApiResponse<ResponseUserList> => {
  const query = queryString.stringify(payload);
  return axios.get(`${ROUTES.FACILITY}/${payload.id}${ROUTES.USER}?${query}`);
};

const exportUsersListExcel = (filters: any): ApiResponse<Blob> => {
  const query = queryString.stringify(filters);
  return axios.get(`${ROUTES.USER}/export?${query}`, { responseType: 'blob' });
};

const fetchUser = (id: string): ApiResponse<IGetUser> => {
  return axios.get(`${ROUTES.USER}/${id}`);
};

const putUserResponsible = (id: string, facilityId: { postrojenje_id: string }): ApiResponse<IEmptyResponse> => {
  return axios.put(`${ROUTES.USER}/${id}${ROUTES.FACILITY}`, facilityId);
};

const putUser = (user: IGetUser): ApiResponse<IEmptyResponse> => {
  const patchUserObj = omit(user, ['id']);
  return axios.put(`${ROUTES.USER}/${user.id}`, patchUserObj);
};

const postUser = (user: Partial<IPostUser>): ApiResponse<IPostResponse> => {
  return axios.post(`${ROUTES.USER}/`, user);
};

const postUserRegistration = (user: IPostUser): ApiResponse<IPostResponse> => {
  return axios.post(`${ROUTES.PUBLIC_REGISTRATION}`, user);
};

const postUserFacility = (userID: string, payload: any): ApiResponse<IEmptyResponse> => {
  return axios.post(`${ROUTES.USER}/${userID}${ROUTES.FACILITY}`, payload);
};

const postUserAdministrator = (user: IPostUser): ApiResponse<IPostResponse> => {
  return axios.post(`${ROUTES.USER}/${ROUTES.SISTEM_ADMIN}`, user);
};

const deleteUser = (id: string): ApiResponse<IEmptyResponse> => {
  return axios.delete(`${ROUTES.USER}/${id}`);
};
const deleteFacilityUser = (id: string, facilityId: string): ApiResponse<IEmptyResponse> => {
  return axios.delete(`${ROUTES.USER}/${id}${ROUTES.FACILITY}/${facilityId}`);
};

const aktivirajKorisnika = (id: string): ApiResponse<IEmptyResponse> => {
  return axios.put(`${ROUTES.USER}/${id}/aktiviraj`);
};
const deaktivirajKorisnika = (id: string): ApiResponse<IEmptyResponse> => {
  return axios.put(`${ROUTES.USER}/${id}/deaktiviraj`);
};
const putUserCompanyResponsibility = (id: string, obj: IPostUserResponsibility): ApiResponse<IEmptyResponse> => {
  return axios.put(`${ROUTES.USER}/${id}/odgovorna-lica-preduzeca`, obj);
};
const changeCompanyOwner = (id: string): ApiResponse<IEmptyResponse> => {
  return axios.put(`${ROUTES.USER}/${id}/rola`);
};

export const userRepo = {
  fetchUserFacilityList,
  postUserFacility,
  putUserCompanyResponsibility,
  deleteFacilityUser,
  putUserResponsible,
  putUser,
  postUserRegistration,
  changeCompanyOwner,
  deleteUser,
  fetchUserList,
  fetchUser,
  exportUsersListExcel,
  fetchUserCompanyList,
  postUserAdministrator,
  postUser,
  aktivirajKorisnika,
  deaktivirajKorisnika,
};
