import { Col, Row } from 'antd';
import { FormSearchInfiniteScroll } from 'components/FormSearchInfiniteScroll/FormSearchInfiniteScroll';
import { ICrudTypeModalProps } from 'components/Modals/CRUDModal/CRUDModal';
import { observer } from 'mobx-react-lite';
import React, { useEffect } from 'react';
import { MORE_OR_EQUILE_0_IS_REQUIRED, MORE_THEN_0, REQUIRED_FIELD_RULE } from 'modules/nriz/nriz.constants';
import { useTranslation } from 'react-i18next';
import { aao2Store } from 'modules/obrasci/store/aao2/aao2.store';
import { ISifarnikInitial } from 'modules/sifarnici/sifarnici.types';
import { IAao2KolicinaAmbalaze } from 'modules/obrasci/store/aao2/aao2.types';
import { FormInputNumber } from 'components/FormInputNumber/FormInputNumber';
import { obrasciStore } from 'modules/obrasci/obrasci.store';
import Decimal from 'decimal.js';

export const NewAao2KolicinaAmbalazeForm: React.FC<ICrudTypeModalProps> = observer(({ form }) => {
  const { t } = useTranslation();

  const defaultPackingTypeOption: ISifarnikInitial | undefined = aao2Store.getKolicinaAmbalaze.type_of_packing_id
    ? {
        value: aao2Store.getKolicinaAmbalaze.type_of_packing_id,
        label: (aao2Store.getKolicinaAmbalaze as IAao2KolicinaAmbalaze).type_of_packing.display_type_sr,
      }
    : undefined;

  const onKolicinaInputChange = () => {
    const proizvedenaKolicina = new Decimal(form?.getFieldValue('proizvedena_kolicina') ?? 0);
    const uvezenaKolicina = Number(form?.getFieldValue('uvezena_kolicina') ?? 0);
    const izvezenaKolicina = Number(form?.getFieldValue('izvezena_kolicina') ?? 0);

    const ukupnaKolicina = proizvedenaKolicina.plus(uvezenaKolicina).minus(izvezenaKolicina);

    !proizvedenaKolicina.toNumber() && !uvezenaKolicina && !izvezenaKolicina && !ukupnaKolicina.toNumber()
      ? form?.setFieldValue('ukupna_kolicina', 0)
      : form?.setFieldValue('ukupna_kolicina', ukupnaKolicina.toNumber());
  };

  useEffect(() => {
    onKolicinaInputChange();
  }, []);

  const readOnly = obrasciStore.zakljucanObrazac;

  return (
    <Col span={24}>
      <FormSearchInfiniteScroll
        showSearch={true}
        label={t('OBRASCI.AAO1.PACKAGING_AMOUNT.PACKAGING_TYPE')}
        labelCol={{ span: 10 }}
        formName={'type_of_packing_id'}
        inputName={'type_of_packing_id'}
        labelOptionAccessor={'display_type_sr'}
        valueOptionAccessor={'id'}
        fetchOptinsList={aao2Store.fetchTypeOfPackingOptions}
        placeholder={t('OBRASCI.AAO1.PACKAGING_AMOUNT.PLACEHOLDERS.PACKAGING_TYPE')}
        defaultOption={defaultPackingTypeOption}
        rules={[
          REQUIRED_FIELD_RULE(true),
          {
            validator(_, value) {
              if (
                aao2Store.kolicinaAmbalaze.type_of_packing_id !== value &&
                aao2Store.initialState.kolicina_ambalaze.some(item => {
                  return value === item.type_of_packing_id;
                })
              ) {
                return Promise.reject(new Error(t('OBRASCI.AAO1.PACKAGING_AMOUNT.ERROR.TYPE_OF_PACKAGING_EXISTS')));
              }
              return Promise.resolve();
            },
          },
        ]}
        readOnly={readOnly}
      />
      <Row>
        <Col span={24}>
          <FormInputNumber
            name="proizvedena_kolicina"
            label={t('OBRASCI.AAO2.PACKAGING_AMOUNT.MANUFACTURED_AMOUNT')}
            labelCol={{ span: 10 }}
            placeholder={''}
            inputName={'proizvedena_kolicina'}
            rules={MORE_THEN_0}
            onInputChange={() => {
              onKolicinaInputChange();
              form.validateFields(['ukupna_kolicina']);
            }}
            colon={true}
            readOnly={readOnly}
          />
        </Col>
      </Row>
      <Row>
        <Col span={24}>
          <FormInputNumber
            name="uvezena_kolicina"
            label={t('OBRASCI.AAO2.PACKAGING_AMOUNT.IMPORTED_AMOUNT')}
            labelCol={{ span: 10 }}
            onInputChange={() => {
              onKolicinaInputChange();
              form.validateFields(['ukupna_kolicina']);
            }}
            rules={MORE_THEN_0}
            placeholder={''}
            inputName={'uvezena_kolicina'}
            colon={true}
            readOnly={readOnly}
          />
        </Col>
      </Row>
      <Row>
        <Col span={24}>
          <FormInputNumber
            name="izvezena_kolicina"
            label={t('OBRASCI.AAO2.PACKAGING_AMOUNT.EXPORTED_AMOUNT')}
            labelCol={{ span: 10 }}
            onInputChange={() => {
              onKolicinaInputChange();
              form.validateFields(['ukupna_kolicina']);
            }}
            rules={MORE_THEN_0}
            placeholder={''}
            inputName={'izvezena_kolicina'}
            colon={true}
            readOnly={readOnly}
          />
        </Col>
      </Row>
      <Row>
        <Col span={24}>
          <FormInputNumber
            name="ukupna_kolicina"
            label={t('OBRASCI.AAO2.PACKAGING_AMOUNT.TOTAL_AMOUNT')}
            labelCol={{ span: 10 }}
            disabled={true}
            rules={MORE_OR_EQUILE_0_IS_REQUIRED}
            placeholder={''}
            inputName={'ukupna_kolicina'}
            colon={true}
            readOnly={readOnly}
          />
        </Col>
      </Row>
    </Col>
  );
});
