import { ColumnsType } from 'antd/es/table';
import { IGetApr, IPostApr } from './apr.types';
import { t } from 'i18next';
import { findLabelForTrueKey } from 'utils/findLabelForTrueKey';
import dayjs from 'dayjs';
import { FormInstance } from 'antd';
import {
  DateSifarniciFormProps,
  ISifarniciObjectProps,
  InputSifarniciFormProps,
  SIFARNICI_ROUTES,
  SearchScrollSifarniciFormProps,
  SifarniciLists,
  SwitchSifarniciFormProps,
  TableSifarniciFormProps,
  TextAreaSifarniciFormProps,
} from 'modules/sifarnici/sifarnici.types';
import { aprService } from './apr.service';
import {
  EMAIL_FIELD_RULE,
  IDENTIFICATION_NUMBER_FIELD_RULE,
  MAX_LENGTH_RULE,
  PIB_FIELD_RULE,
} from 'modules/nriz/nriz.constants';
import {
  APR_ADDRESS_DATA_INITIAL_STATE,
  APR_ADDRESS_DATA_TABLE_DATA,
} from '../aprAddressData/aprAddressData.constants';
import { FormListType } from 'typescript/NrizTypes';
import {
  APR_ASSIGNEE_FOUNDER_INITIAL_STATE,
  APR_ASSIGNEE_FOUNDER_TABLE_DATA,
} from '../aprAssigneeFounder/aprAssigneeFounder.constants';

export const APR_INITIAL_STATE: IPostApr = {
  entrepreneur: false,
  enterprise_id_number: '',
  tax_identification_number: '',
  status_id: '',
  full_company_name: '',
  short_company_name: '',
  date_founding: '',
  condition_of_work_end: '',
  legal_form_id: '',
  principalbusinessactivity_id: '',
  mail: '',
  name: '',
  commercial_company: false,
  end_of_work: '',
  deleted_date: '',
  the_others: false,
  data_source_id: '',
  entrepreneurcompanyispartnersh: false,
  temporaryinterruptionfrom: '',
  temporaryinterruptionto: '',
  association: false,
  bankruptcy_mass: false,
  foundation_endowment: false,
  sport_association: false,
  chamber: false,
  typeofshapeoforganizing_id: '',
  sizeofbusinessentity_id: '',
  commercialactivitycode_id: '',
  registrationnumberofbankruptcy: '',
  housing_community: false,
};

type NonBooleanKeys<T> = {
  [K in keyof T]: T[K] extends boolean ? never : K;
}[keyof T];

type BooleanKeys<T> = {
  [K in keyof T]: T[K] extends boolean ? K : never;
}[keyof T];

export const APR_DATES_ARRAY: NonBooleanKeys<IPostApr>[] = [
  'date_founding',
  'end_of_work',
  'deleted_date',
  'temporaryinterruptionfrom',
  'temporaryinterruptionto',
];

export const APR_PRIVREDNI_SUBJEKAT_KEYS: BooleanKeys<IPostApr>[] = [
  'commercial_company',
  'association',
  'bankruptcy_mass',
  'foundation_endowment',
  'sport_association',
  'chamber',
  'housing_community',
  'the_others',
  'entrepreneur',
];

export const APR_PRIVREDNI_SUBJEKAT_OPTIONS = [
  { label: t('COMPANY.BUSINESS_ENTITY_TYPES.BUSINESS_COMPANY'), value: 'commercial_company' },
  { label: t('COMPANY.BUSINESS_ENTITY_TYPES.ENTREPRENEUR'), value: 'enterpreneur' },
  { label: t('COMPANY.BUSINESS_ENTITY_TYPES.ASSOCIATION'), value: 'association' },
  { label: t('COMPANY.BUSINESS_ENTITY_TYPES.BANKRUPTCY_ESTATE'), value: 'bankruptcy_mass' },
  { label: t('COMPANY.BUSINESS_ENTITY_TYPES.FOUNDATION_ENDOWMENT'), value: 'foundation_endowment' },
  { label: t('COMPANY.BUSINESS_ENTITY_TYPES.SPORTS_ASSOCIATION'), value: 'sport_association' },
  { label: t('COMPANY.BUSINESS_ENTITY_TYPES.CHAMBER'), value: 'chamber' },
  { label: t('COMPANY.BUSINESS_ENTITY_TYPES.HOUSING_COMMUNITY'), value: 'housing_community' },
  { label: t('COMPANY.BUSINESS_ENTITY_TYPES.OTHER'), value: 'the_others' },
];

export const APR_TABLE_DATA: ColumnsType<IGetApr> = [
  {
    title: 'Tip subjekta',
    // align: 'center',
    width: '200px',
    render: (_, record: IGetApr) => <p>{findLabelForTrueKey(APR_PRIVREDNI_SUBJEKAT_OPTIONS, record as IGetApr)}</p>,
    key: 'entrepreneur',
  },
  {
    title: `${t('COMPANY.ENTERPRISE_ID_NUMBER')}`,
    // align: 'center',
    width: '200px',
    dataIndex: 'enterprise_id_number',
    key: 'enterprise_id_number',
  },
  {
    title: `${t('COMPANY.PIB')}`,
    // align: 'center',
    width: '200px',
    dataIndex: 'tax_identification_number',
    key: 'tax_identification_number',
  },
  {
    title: `${t('COMPANY.TITLE')}`,
    // align: 'center',
    width: '200px',
    dataIndex: 'full_company_name',
    key: 'full_company_name',
  },
  {
    title: `${t('COMPANY.STATUS')}`,
    // align: 'center',
    width: '200px',
    dataIndex: ['status', 'status_sr'],
    key: 'status',
  },
  {
    title: `${t('COMPANY.DATE_FOUNDING')}`,
    // align: 'center',
    width: '200px',
    render: (_, record: IGetApr) => {
      return record.date_founding ? <p>{dayjs(record.date_founding).format('DD-MM-YYYY')}</p> : <></>;
    },
    key: 'date_founding',
  },
  {
    title: `${t('COMPANY.PRINCIPAL_BUSINESS_ACTIVITY_CODE')}`,
    // align: 'center',
    width: '200px',
    dataIndex: ['principalbusinessactivity', 'name_sr'],
    key: 'business_activity_code',
  },
  {
    title: `${t('COMPANY.EMAIL')}`,
    // align: 'center',
    width: '200px',
    dataIndex: 'mail',
    key: 'mail',
  },
  // {
  //   title: `${t('COMPANY.DATE_FOUNDING')}`,
  // align: 'center',
  //   width: '200px',
  //   render: (_, record: IGetApr) => (record.end_of_work ? <p>{dayjs(record.end_of_work).format('DD-MM-YYYY')}</p> : <></>),
  //   key: 'date_founding',
  // },
];

export const APR_FORM_OPTIONS = (form: FormInstance<IGetApr>) => {
  const privredniSubjekat = aprService.makePrivredniSubjekat(form);
  return [
    {
      type_of_form: 'title',
      title: t('COMPANY.APR_TITLES.TYPE_BUSINESS_ENTITY'),
    } as ISifarniciObjectProps,
    ...privredniSubjekat,
    {
      type_of_form: 'title',
      title: t('COMPANY.APR_TITLES.BASIC_INFO'),
    } as ISifarniciObjectProps,
    {
      type_of_form: 'input',
      label: t('COMPANY.ENTERPRISE_ID_NUMBER'),
      name: 'enterprise_id_number',
      sortName: 'enterprise_id_number',
      inputName: 'enterprise_id_number',
      type: 'number',
      rules: [IDENTIFICATION_NUMBER_FIELD_RULE],
      // placeholder={t('COMPANY.PLACEHOLDER.ENTER_IDENTIFICATION_NUMBER')}
    } as InputSifarniciFormProps,
    {
      type_of_form: 'input',
      label: t('COMPANY.PIB'),
      name: 'tax_identification_number',
      sortName: 'tax_identification_number',
      inputName: 'tax_identification_number',
      precision: 0,
      rules: [PIB_FIELD_RULE],

      // placeholder={t('COMPANY.PLACEHOLDER.ENTER_PIB')}
    } as InputSifarniciFormProps,
    {
      type_of_form: 'searchScroll',
      label: t('COMPANY.STATUS'),
      formName: 'status_id',
      sortName: 'status_id',
      fetchOptinsList: SIFARNICI_ROUTES.APR_COMPANY_STATUS,
      filtersForFetch: {},
      labelOptionAccessor: ['status_sr'],
      valueOptionAccessor: 'id',
      disabledAccessor: 'is_active',
      hideInput: true,
      //
    } as SearchScrollSifarniciFormProps,
    {
      type_of_form: 'area',
      label: t('COMPANY.FULL_NAME'),
      name: 'full_company_name',
      hideInput: true,
      rules: [MAX_LENGTH_RULE(300)],
      rows: 4,
      labelCol: 10,
      wrapperCol: 14,
    } as TextAreaSifarniciFormProps,
    {
      type_of_form: 'input',
      // placeholder={t('COMPANY.PLACEHOLDER.ENTER_SHORT_NAME')}
      label: t('COMPANY.SHORT_NAME'),
      name: 'short_company_name',
      sortName: 'short_company_name',
      inputName: 'short_company_name',
      rules: [MAX_LENGTH_RULE(50)],
    } as InputSifarniciFormProps,
    {
      type_of_form: 'input',
      // placeholder={t('UNSANITARY_LANDFILL.NAME')}
      label: t('UNSANITARY_LANDFILL.NAME'),
      name: 'name',
      hideInput: true,
      sortName: 'name',
      inputName: 'name',
      rules: [MAX_LENGTH_RULE(50)],
    } as InputSifarniciFormProps,
    {
      type_of_form: 'date',
      name: 'date_founding',
      sortName: 'date_founding',
      label: t('COMPANY.DATE_FOUNDING'),
      format: 'DD-MM-YYYY',
      picker: 'date',
      disabledDate: (current: any) => {
        return current && current > dayjs().endOf('day');
      },
    } as DateSifarniciFormProps,
    {
      type_of_form: 'area',
      label: t('COMPANY.CONDITION_OF_WORK_END'),
      name: 'condition_of_work_end',
      hideInput: true,
      rules: [MAX_LENGTH_RULE(3000)],
      rows: 4,
      labelCol: 10,
      wrapperCol: 14,
    } as TextAreaSifarniciFormProps,
    {
      type_of_form: 'date',
      name: 'end_of_work',
      sortName: 'end_of_work',
      label: t('COMPANY.END_OF_WORK'),
      format: 'DD-MM-YYYY',
      picker: 'date',
      disabledDate: (current: any) => {
        return current && current > dayjs().endOf('day');
      },
    } as DateSifarniciFormProps,
    {
      type_of_form: 'date',
      name: 'deleted_date',
      sortName: 'deleted_date',
      label: t('COMPANY.DATE_DELETED'),
      format: 'DD-MM-YYYY',
      picker: 'date',
      disabledDate: (current: any) => {
        return current && current > dayjs().endOf('day');
      },
    } as DateSifarniciFormProps,
    {
      type_of_form: 'searchScroll',
      label: t('COMPANY.PRINCIPAL_BUSINESS_ACTIVITY_CODE'),
      formName: 'principalbusinessactivity_id',
      sortName: 'principalbusinessactivity_id',
      fetchOptinsList: SIFARNICI_ROUTES.SIFARNIK_PRETEZNE_DELATNOSTI,
      filtersForFetch: {},
      labelOptionAccessor: ['name_sr'],
      valueOptionAccessor: 'id',
      hideInput: true,
      disabledAccessor: 'is_active',
      //
    } as SearchScrollSifarniciFormProps,
    {
      type_of_form: 'searchScroll',
      label: t('COMPANY.TYPE_SHAPE_ORGANIZING'),
      formName: 'typeofshapeoforganizing_id',
      sortName: 'typeofshapeoforganizing_id',
      fetchOptinsList: SIFARNICI_ROUTES.APR_TYPE_OF_SHAPE_OF_ORGANIZING,
      filtersForFetch: {},
      labelOptionAccessor: ['type_sr'],
      valueOptionAccessor: 'id',
      hideInput: true,
      disabledAccessor: 'is_active',
      //
    } as SearchScrollSifarniciFormProps,
    {
      type_of_form: 'searchScroll',
      label: t('COMPANY.SIZE_BUSINESS_ENTITY'),
      formName: 'sizeofbusinessentity_id',
      hideInput: true,
      sortName: 'sizeofbusinessentity_id',
      // placeholder={t('COMPANY.TITLE')}
      fetchOptinsList: SIFARNICI_ROUTES.APR_SIZE_OF_BUSINESS_ENTITY,
      filtersForFetch: {},
      labelOptionAccessor: ['sizebusinessentity_sr'],
      valueOptionAccessor: 'id',
      disabledAccessor: 'is_active',
      //
    } as SearchScrollSifarniciFormProps,
    {
      type_of_form: 'input',
      label: t('COMPANY.EMAIL'),
      name: 'mail',
      inputName: 'mail',
      type: 'email',
      rules: [EMAIL_FIELD_RULE],
      // placeholder={t('COMPANY.PLACEHOLDER.ENTER_EMAIL')}
    } as InputSifarniciFormProps,
    {
      type_of_form: 'title',
      title: t('COMPANY.APR_TITLES.SPECIFIC_DATA_TYPE_BUSINESS_ENTITY'),
    } as ISifarniciObjectProps,
    {
      type_of_form: 'searchScroll',
      label: t('COMPANY.LEGAL_FORM'),
      formName: 'legal_form_id',
      fetchOptinsList: SIFARNICI_ROUTES.APR_LEGAL_FORM,
      filtersForFetch: {},
      hideInput: true,
      labelOptionAccessor: ['name_sr'],
      valueOptionAccessor: 'id',
      disabledAccessor: 'is_active',
      //
    } as SearchScrollSifarniciFormProps,
    {
      type_of_form: 'switch',
      name: 'entrepreneurcompanyispartnersh',
      label: t('SIFARNICI.ORTACKO_PREDUZECE'),
      hideInput: true,
    } as SwitchSifarniciFormProps,

    {
      type_of_form: 'date',
      name: 'temporaryinterruptionfrom',
      label: t('COMPANY.TEMPORARY_INTERUPTION_FROM'),
      format: 'DD-MM-YYYY',
      picker: 'date',
      disabledDate: (current: any) => {
        return current && current > dayjs().endOf('day');
      },
    } as DateSifarniciFormProps,
    {
      type_of_form: 'date',
      name: 'temporaryinterruptionto',
      label: t('COMPANY.TEMPORARY_INTERUPTION_TO'),
      format: 'DD-MM-YYYY',
      picker: 'date',
      disabledDate: (current: any) => {
        return current && current > dayjs().endOf('day');
      },
    } as DateSifarniciFormProps,
    {
      type_of_form: 'searchScroll',
      label: t('COMPANY.PRINCIPAL_BUSINESS_ACTIVITY_CODE'),
      formName: 'commercialactivitycode_id',
      hideInput: true,
      fetchOptinsList: SIFARNICI_ROUTES.SIFARNIK_PRETEZNE_DELATNOSTI,
      filtersForFetch: {
        version__neq: 0,
      },
      labelOptionAccessor: ['name_sr'],
      valueOptionAccessor: 'id',
      disabledAccessor: 'is_active',
      //
    } as SearchScrollSifarniciFormProps,
    {
      type_of_form: 'input',
      label: t('SIFARNICI.BANKRUPCY_NUMBER'),
      name: 'registrationnumberofbankruptcy',
      hideInput: true,
      inputName: 'registrationnumberofbankruptcy',
      type: 'number',
      // placeholder={t('COMPANY.PLACEHOLDER.ENTER_IDENTIFICATION_NUMBER')}
    } as InputSifarniciFormProps,
    {
      type_of_form: 'title',
      title: t('COMPANY.APR_TITLES.SOURCE'),
    } as ISifarniciObjectProps,
    {
      type_of_form: 'searchScroll',
      label: t('COMPANY.DATA_SOURCE'),
      formName: 'data_source_id',
      fetchOptinsList: SIFARNICI_ROUTES.APR_DATA_SOURCE,
      filtersForFetch: {},
      hideInput: true,
      labelOptionAccessor: ['source'],
      valueOptionAccessor: 'id',
      disabledAccessor: 'is_active',

      //
    } as SearchScrollSifarniciFormProps,
    {
      type_of_form: 'table',
      tableColumns: APR_ADDRESS_DATA_TABLE_DATA,
      listName: 'apr_address_data',
      configPath: { formListType: FormListType.Sifarnici, formListSubType: SifarniciLists.APR_ADDRESS_DATA },
      title: <div style={{ textDecoration: 'underline' }}>{t('SIFARNICI.APR_ADRESNI_PODACI')}</div>,
      formModalInitialValues: APR_ADDRESS_DATA_INITIAL_STATE,
    } as TableSifarniciFormProps,
    {
      type_of_form: 'table',
      tableColumns: APR_ASSIGNEE_FOUNDER_TABLE_DATA,
      listName: 'apr_assignee_founder',
      configPath: { formListType: FormListType.Sifarnici, formListSubType: SifarniciLists.APR_ASSIGNEE_FOUNDE },
      title: <div style={{ textDecoration: 'underline' }}>{t('SIFARNICI.APR_ZASTUPNICI_OSNIVACI')}</div>,
      formModalInitialValues: APR_ASSIGNEE_FOUNDER_INITIAL_STATE,
      modalWidth: '60%',
    } as TableSifarniciFormProps,
  ];
};
