import { IPagination } from 'typescript';
import { companyRepo } from './company.repo';
import { ITablePagination } from 'components/TableData/TableData';
import { IEmptyResponse, IPaginatedApiResponse } from 'typescript/NrizTypes';
import {
  ICompanyTipoviObrasca,
  IGetCompany,
  IPib,
  IPibMaticniCheckResponse,
  IPostCompany,
  PibMaticniCheckEnum,
  ResponseCheckCompany,
} from './company.types';

class CompanyService {
  checkPibMaticni = (params: IPib) => {
    return companyRepo.checkPibMaticni(params);
  };

  postPib = (payload: any) => {
    return companyRepo.postPib(payload);
  };

  companyRegistration = (payload: IPostCompany) => {
    return companyRepo.companyRegistration(payload);
  };

  fetchCompaniesList = (payload: ITablePagination): IPaginatedApiResponse<IGetCompany> => {
    return companyRepo.fetchCompaniesList(payload);
  };

  exportCompanyExcel = (payload: ITablePagination): Promise<Blob> => {
    return companyRepo.exportCompanyExcel(payload);
  };

  checkCompany = (): Promise<ResponseCheckCompany> => {
    return companyRepo.checkCompany();
  };

  companyApprove = (id: string): Promise<IEmptyResponse> => {
    return companyRepo.companyApprove(id);
  };
  companyReject = (id: string): Promise<IEmptyResponse> => {
    return companyRepo.companyReject(id);
  };

  fetchCompany = (id: string): Promise<IGetCompany> => {
    return companyRepo.fetchCompany(id);
  };

  fetchCompanyTipObrasca = (companyId: string) => {
    return companyRepo.fetchCompanyTipObrasca(companyId);
  };

  postCompanyTipObrasca = (companyId: string, tipoviObrazca: ICompanyTipoviObrasca) => {
    return companyRepo.postCompanyTipObrasca(companyId, tipoviObrazca);
  };

  putCompany = (company: IGetCompany): Promise<IEmptyResponse> => {
    return companyRepo.putCompany(company);
  };

  aktivirajPreduzece = (id: string): Promise<IEmptyResponse> => {
    return companyRepo.aktivirajPreduzece(id);
  };
  deaktivirajPreduzece = (id: string): Promise<IEmptyResponse> => {
    return companyRepo.deaktivirajPreduzece(id);
  };

  // todo: sifarnici prepakuj

  fetchLocationList = (payload: Partial<IPagination>) => {
    return companyRepo.fetchLocationList(payload);
  };

  fetchPretezneDelatnostiList = (payload: Partial<IPagination>) => {
    return companyRepo.fetchPretezneDelatnostiList(payload);
  };

  handleCheckPibMaticni = (
    response: IPibMaticniCheckResponse,
    params: IPib,
    type: 'approval' | 'registration' = 'registration'
  ) => {
    const { pib: pibArray, maticni_broj: maticniArray } = response;

    const { pib, maticni_broj } = params;

    // todo: refactor

    switch (type) {
      case 'registration':
        if (pib && maticni_broj) {
          if (!pibArray.length && !maticniArray.length) return PibMaticniCheckEnum.CheckSuccess;
          if (pibArray.length && !maticniArray.length) return PibMaticniCheckEnum.PibExists;
          if (!pibArray.length && maticniArray.length) return PibMaticniCheckEnum.MbExists;
          if (pibArray.some(preduzece => maticniArray.includes(preduzece))) return PibMaticniCheckEnum.CompanyExists;
        } else if (!pib) {
          if (!maticniArray.length) return PibMaticniCheckEnum.CheckSuccess;
          if (pibArray.some(preduzece => maticniArray.includes(preduzece))) return PibMaticniCheckEnum.CompanyExists;
          return PibMaticniCheckEnum.MbExists;
        } else {
          if (!pibArray.length) return PibMaticniCheckEnum.CheckSuccess;
          if (pibArray.some(preduzece => maticniArray.includes(preduzece))) return PibMaticniCheckEnum.CompanyExists;
          return PibMaticniCheckEnum.PibExists;
        }

        return PibMaticniCheckEnum.CompanyExists;

      case 'approval':
        if (pib && maticni_broj) {
          if (pibArray.length === 1 && maticniArray.length === 1) return PibMaticniCheckEnum.CheckSuccess;
          if (pibArray.length > 1) return PibMaticniCheckEnum.PibExists;
          if (maticniArray.length > 1) return PibMaticniCheckEnum.MbExists;
        }
        if (!pib) {
          if (maticniArray.length === 1) return PibMaticniCheckEnum.CheckSuccess;
          if (maticniArray.length > 1) return PibMaticniCheckEnum.MbExists;
        }
        if (!maticni_broj) {
          if (pibArray.length === 1) return PibMaticniCheckEnum.CheckSuccess;
          if (pibArray.length > 1) return PibMaticniCheckEnum.PibExists;
        }
    }
  };
}

export const companyService = new CompanyService();
