import { ColumnsType } from 'antd/es/table';
import { EditButtonProps, RowEditPreviewButton } from 'components/RowEditPreviewButton/RowEditPreviewButton';
import TableData from 'components/TableData/TableData';
import { observer } from 'mobx-react-lite';
import { obrasciStore } from 'modules/obrasci/obrasci.store';
import { obrazac5Store } from 'modules/obrasci/store/obrazac5/obrazac5.store';
import { IGetKomponenteObrazac5 } from 'modules/obrasci/store/obrazac5/obrazac5.types';
import React from 'react';
import { useTranslation } from 'react-i18next';

const EditObrazac5KomponenteButton: React.FC<EditButtonProps<IGetKomponenteObrazac5>> = observer(({ record }) => {
  return (
    <RowEditPreviewButton
      name="obrazac5-komponente"
      modalName="obrazac5-komponente-modal"
      onClick={() => obrazac5Store.handleChange('komponente_form', record)}
      preview={obrasciStore.zakljucanObrazac}
    />
  );
});

export const TableKomponenteObrazac5: React.FC = observer(() => {
  const { t } = useTranslation();

  const baseColumns: ColumnsType<IGetKomponenteObrazac5> = [
    /// responsekomponenteform
    {
      title: `${t('OBRASCI.OBRAZAC5.CAS_BROJ')}`,
      width: '100px',
      dataIndex: 'cas_broj',
      key: 'cas_broj',
    },
    {
      title: `${t('OBRASCI.OBRAZAC5.HEMIJSKI_NAZIV')}`,
      width: '300px',
      dataIndex: 'hemijski_naziv',
      key: 'hemijski_naziv',
    },
    {
      title: `${t('OBRASCI.OBRAZAC5.SADRZAJ_OPASNE_MATERIJE')}`,
      dataIndex: 'sadrzaj_opasne_materije',
      key: 'sadrzaj_opasne_materije',
      width: '200px',
    },

    {
      title: ``,
      key: 'operation',
      fixed: 'right',
      width: '70px',
      align: 'center',
      render: (_, record: IGetKomponenteObrazac5) => <EditObrazac5KomponenteButton record={record} />,
    },
  ];

  return (
    <TableData
      scrollConfig={['#obrazacTableHeader', '#obrazacWizardHeader']}
      name="komponente-obrazac5"
      columns={baseColumns}
      dataSource={[...obrazac5Store.initialState.komponente]}
    />
  );
  // resi store
});
