import { FormInputNumber } from 'components/FormInputNumber/FormInputNumber';
import { FormSearchInfiniteScroll } from 'components/FormSearchInfiniteScroll/FormSearchInfiniteScroll';
import { CRUDModal } from 'components/Modals/CRUDModal/CRUDModal';
import { observer } from 'mobx-react-lite';
import { MORE_THEN_0_IS_REQUIRED, REQUIRED_FIELD_RULE } from 'modules/nriz/nriz.constants';
import { obrasciStore } from 'modules/obrasci/obrasci.store';
import { deo6ObrazacStore } from 'modules/obrasci/store/deo6/deo6.store';
import { IGetRazrediEeOtpadaDeo6 } from 'modules/obrasci/store/deo6/deo6.types';
import { RAZREDI_EE_OTPADA_INITIAL_STATE } from 'modules/obrasci/store/gio3/gio3.constants';
import { ISifarnikInitial } from 'modules/sifarnici/sifarnici.types';
import { IGetWeeeCategories } from 'modules/sifarnici/store/weeeCategories/weeeCategories.types';

import { useTranslation } from 'react-i18next';

const NewRazredSkladisteDeo6Form: React.FC = observer(() => {
  const { t } = useTranslation();

  const defaultWeeeCategory: ISifarnikInitial | undefined = deo6ObrazacStore.getRazrediEeForm.razred_ee_otpada_id
    ? {
        value: deo6ObrazacStore.getRazrediEeForm.razred_ee_otpada_id,
        label: `${(deo6ObrazacStore.getRazrediEeForm as IGetRazrediEeOtpadaDeo6).razred_ee_otpada.the_order} ${
          (deo6ObrazacStore.getRazrediEeForm as IGetRazrediEeOtpadaDeo6).razred_ee_otpada.category_name_sr
        }`,
      }
    : undefined;

  const weeeCategoriesOptionMaker = (options: IGetWeeeCategories[]) => {
    return options.map(weeeCategory => ({
      label: `${weeeCategory.the_order} ${weeeCategory.category_name_sr}`,
      value: weeeCategory.id,
    }));
  };

  const readOnly = obrasciStore.zakljucanObrazac;

  return (
    <>
      <FormSearchInfiniteScroll
        showSearch={true}
        formName={'razred_ee_otpada_id'}
        fetchOptinsList={deo6ObrazacStore.fetchWeeeCategoriesList}
        labelOptionAccessor="category_name_sr"
        valueOptionAccessor="id"
        inputName={'razred_ee_otpada_id'}
        label={t('OBRASCI.GIO_3.CLASS_EE_WASTE')}
        colon={true}
        defaultOption={defaultWeeeCategory}
        placeholder={''}
        rules={[
          REQUIRED_FIELD_RULE(true),
          {
            validator(_, value) {
              if (
                deo6ObrazacStore.razredi_ee_form.razred_ee_otpada_id !== value &&
                deo6ObrazacStore.skladiste_form.razred.some(item => {
                  return value === item.razred_ee_otpada_id;
                })
              ) {
                return Promise.reject(new Error(t('OBRASCI.DEO_6.RAZREDI_ERROR')));
              }
              return Promise.resolve();
            },
          },
        ]}
        optionsMaker={weeeCategoriesOptionMaker}
        readOnly={readOnly}
      />
      <FormInputNumber
        name="kolicina"
        label={t('OBRASCI.GIO_3.QUANTITY_T')}
        rules={[...MORE_THEN_0_IS_REQUIRED]}
        placeholder={''}
        inputName={'kolicina'}
        colon={true}
        precision={4}
        readOnly={readOnly}
      />
    </>
  );
});

export const Deo6RazredSkladisteModal: React.FC = observer(() => {
  const { t } = useTranslation();

  return (
    <CRUDModal
      width="40%"
      name="deo6-razred-skladiste-modal"
      title={`${t('OBRASCI.GIO_3.CLASS_EE_WASTE')} ${t('OBRASCI.OBRAZAC5.SKLADISTE_OTPADA')}`}
      store={deo6ObrazacStore}
      data={deo6ObrazacStore.getRazrediEeForm}
      children={() => <NewRazredSkladisteDeo6Form />}
      submitForm={deo6ObrazacStore.submitRazredSkladiste}
      initialState={RAZREDI_EE_OTPADA_INITIAL_STATE}
      dataToChange={'razredi_ee_form'}
      labelCol={{ span: 14 }}
      wrapperCol={{ span: 10 }}
      closeOneModal={true}
    />
  );
});
