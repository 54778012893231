import { IPagination } from 'typescript';
import { facilityRepo } from './facility.repo';
import {
  IGetFacility,
  IPostFacility,
  IPostFacilityTipoviObrazaca,
  ISurvey,
  RequestFacilityList,
} from './facility.types';
import { IPaginatedApiResponse, IPostResponse } from 'typescript/NrizTypes';

class FacilityService {
  postSurvey = (payload: ISurvey) => {
    return facilityRepo.postSurvey(payload);
  };

  fetchFacilityList = (payload: RequestFacilityList): IPaginatedApiResponse<IGetFacility> => {
    return facilityRepo.fetchFacilityList(payload);
  };

  fetchFacilityListByUser = (payload: Partial<IPagination>): IPaginatedApiResponse<IGetFacility> => {
    return facilityRepo.fetchFacilityListByUser(payload);
  };

  fetchFacility = (id: string) => {
    return facilityRepo.fetchFacility(id);
  };

  fetchFacilityTipObrasca = (facilityId: string) => {
    return facilityRepo.fetchFacilityTipObrasca(facilityId);
  };

  postFacilityTipObrasca = (facilityId: string, tipoviObrazca: IPostFacilityTipoviObrazaca) => {
    return facilityRepo.postFacilityTipObrasca(facilityId, tipoviObrazca);
  };

  postFacility = (facility: IPostFacility): Promise<IPostResponse> => {
    return facilityRepo.postFacility(facility);
  };

  putFacility = (facility: IGetFacility) => {
    return facilityRepo.putFacility(facility);
  };

  aktivirajPostrojenje = (id: string) => {
    return facilityRepo.aktivirajPostrojenje(id);
  };

  deaktivirajPostrojenje = (id: string) => {
    return facilityRepo.deaktivirajPostrojenje(id);
  };

  toggleDeo6Validation = (id: string, isValidationDisabled: boolean) => {
    const params = { privremeno_ukinuta_validacija_za_deo6: isValidationDisabled };
    return facilityRepo.toggleDeo6Validation(id, params);
  };
}

export const facilityService = new FacilityService();
