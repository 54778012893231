import to from 'await-to-js';
import { makeAutoObservable } from 'mobx';
import { istorijaPromenaService } from './istorijaPromena.service';
import { filtersStore } from 'store/filteri.store';
import { IAuditLogs, IGetIstorijaPromena } from './istorijaPromena.types';
import { ITablePagination } from 'components/TableData/TableData';
import { IGetUser, IPostUser } from 'modules/user/user.types';
import { IPagination } from 'typescript';
import { paginationInitialState } from 'modules/nriz/nriz.constants';
import { IPaginatedResponse } from 'typescript/NrizTypes';

export interface INewAuditLogs {
  timestamp: string;
  kreirao: IGetUser | IPostUser;
  audit_logs: IAuditLogs[];
}

class IstorijaPromenaStore {
  constructor() {
    makeAutoObservable(this);
  }
  istorijaPromenaList: IGetIstorijaPromena[] = [];
  istorijaPromenaPagiantion: IPagination = paginationInitialState;

  get getIstorijaPromenaList(): IGetIstorijaPromena[] {
    return this.istorijaPromenaList;
  }

  handleChange(key: keyof IstorijaPromenaStore, value: any) {
    (this as any)[key] = value;
  }
  fetchIstorijuPromena = async (pagination?: ITablePagination) => {
    const newFilters = istorijaPromenaService.makeFiltersForIstorijaPromena(filtersStore.filters.istorijaPromena);

    const [err, res] = await to<IPaginatedResponse<IGetIstorijaPromena>>(
      istorijaPromenaService.fetchIstorijaPromenaList({
        session_grouped: true,
        page: pagination?.page || this.istorijaPromenaPagiantion.page,
        size: pagination?.size || this.istorijaPromenaPagiantion.size,
        ...newFilters,
      })
    );
    if (err || !res) return Promise.reject();
    const { items, page, pages, total, size } = res;

    const newTable = items.map((item: IGetIstorijaPromena) => {
      return {
        timestamp: item.audit_logs[0].timestamp,
        kreirao: item.audit_logs[0].kreirao,
        audit_logs: item.audit_logs,
      };
    });

    this.handleChange('istorijaPromenaList', newTable);
    this.handleChange('istorijaPromenaPagiantion', { total, page, size, pages });
    return res;
  };
}

export const istorijaPromenaStore = new IstorijaPromenaStore();
