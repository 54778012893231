import React, { useEffect } from 'react';
import { IstorijaPromenaTable } from './components/IstorijaPromenaTable/IstorijaPromenaTable';
import { IstorijaPromenaFilters } from './components/IstorijaPromenaFilters/IstorijaPromenaFilters';
import { Content } from 'components/Content/Content';
import { istorijaPromenaStore } from 'modules/istorijaPromena/istorijaPromena.store';

export const IstorijaPromena: React.FC = () => {
  useEffect(() => {
    istorijaPromenaStore.fetchIstorijuPromena();
  }, []);
  return (
    <Content>
      <IstorijaPromenaFilters />
      <IstorijaPromenaTable />
    </Content>
  );
};
