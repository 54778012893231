import { axios } from 'modules/axios';
import {
  IObrazacIzvestajFilters,
  ObrazacIzvestajiAllReports,
  RekalkulacijaIzvestajTableData,
  ReportTypeEnum,
} from './obrazacIzvestaji.types';

import { ITablePagination } from 'components/TableData/TableData';
import { IPaginatedApiResponse } from 'typescript/NrizTypes';

class ObrazacIzvestajiRepo {
  izvestajiBaseUrl = '/izvestaji-po-obrascima/';
  // Todo: check will excel and pdf be same api route but with different body param, or seperate route?
  downloadIzvestajUrl = `${this.izvestajiBaseUrl}stampa`;

  // Todo: add pagination
  getObrazacByType = (
    payload: IObrazacIzvestajFilters,
    pagination: ITablePagination
  ): IPaginatedApiResponse<ObrazacIzvestajiAllReports | Array<RekalkulacijaIzvestajTableData>> => {
    return axios.post(this.izvestajiBaseUrl, payload, { params: pagination });
  };

  exportToExcel = (payload: IObrazacIzvestajFilters): Promise<Blob> => {
    return axios.post(
      this.downloadIzvestajUrl,
      { ...payload, report_type: ReportTypeEnum.XLSX },
      { responseType: 'blob' }
    );
  };

  exportToPDF = (payload: IObrazacIzvestajFilters): Promise<Blob> => {
    return axios.post(
      this.downloadIzvestajUrl,
      { ...payload, report_type: ReportTypeEnum.PDF },
      { responseType: 'blob' }
    );
  };
}

export const obrazacIzvestajiRepo = new ObrazacIzvestajiRepo();
