import React from 'react';
import { useTranslation } from 'react-i18next';

type YesNoProps = {
  yesNo: boolean;
};

export const YesNoCell: React.FC<YesNoProps> = props => {
  const { t } = useTranslation();

  if (props.yesNo === undefined || props.yesNo === null) {
    return <></>;
  }

  return <p>{props.yesNo ? `${t('YES')}` : `${t('NO')}`}</p>;
};
