import { InsideContentWrapper } from 'components/InsideContentWrapper/InsideContentWrapper';
import { observer } from 'mobx-react-lite';
import { Row } from 'antd';
import { AddNewButton } from 'components/Buttons/AddNewButton/AddNewButton';
import { useTranslation } from 'react-i18next';
import { modalStore } from 'store';
import { SoilListTable } from './SoilListTable/SoilListTable';
import { facilityStore } from 'modules/facility/facility.store';
import { TIPOVI_OBRAZACA } from 'modules/obrasci/obrasci.types';
import { useEffect } from 'react';
import { soilStore } from 'modules/sourcesOfPollution/soil/soil.store';

export const FacilitySoilListHeader: React.FC = observer(() => {
  const { t } = useTranslation();
  return (
    <Row justify={'space-between'} align={'bottom'}>
      <h3>{t('POLLUTION.SOIL.LIST')}</h3>
      {facilityStore.getFacility.is_active &&
        facilityStore.getFacilityTipoviObrazaca.some(
          (item: any) =>
            item.skraceni_naziv === TIPOVI_OBRAZACA.OBRAZAC_4 || item.skraceni_naziv === TIPOVI_OBRAZACA.LRIZ_OBRAZAC_4
        ) &&
        facilityStore.readOnlyTipoviObrazacaForm && (
          <AddNewButton
            label="Dodaj izvor emisija u tlo"
            name={'soil'}
            onButtonClick={() => {
              modalStore.changeModalName('soil-modal');
            }}
          />
        )}
    </Row>
  );
});

export const FacilitySoilList: React.FC = observer(() => {
  useEffect(() => {
    soilStore.fetchSoilList();
  }, []);

  return (
    <InsideContentWrapper header={<FacilitySoilListHeader />}>
      <SoilListTable />
    </InsideContentWrapper>
  );
});
