import { Form } from 'antd';
import { Select } from 'components/Select/Select';
import React from 'react';
import { FormSelectProps } from 'typescript/NrizTypes';

export const FormSelect: React.FC<FormSelectProps> = ({
  labelCol,
  wrapperCol,
  name,
  rules,
  colon,
  labelAlign,
  label,
  showSearch,
  placeholder,
  allowClear = true,
  onChange,
  filterOption,
  value,
  options,
  className,
  mode,
}) => {
  return (
    <Form.Item
      labelCol={labelCol}
      wrapperCol={wrapperCol}
      name={name}
      rules={rules}
      colon={colon}
      labelAlign={labelAlign}
      label={label}
      className={className}
    >
      <Select
        mode={mode}
        name={name}
        allowClear={allowClear}
        showSearch={showSearch}
        placeholder={placeholder}
        optionFilterProp="children"
        onChange={onChange}
        value={value}
        filterOption={() => filterOption}
        options={options}
      />
    </Form.Item>
  );
};
