import { ColumnsType } from 'antd/es/table';
import { EditButtonProps, RowEditPreviewButton } from 'components/RowEditPreviewButton/RowEditPreviewButton';
import TableData from 'components/TableData/TableData';
import { observer } from 'mobx-react-lite';
import { obrasciStore } from 'modules/obrasci/obrasci.store';
import { obrazac3Store } from 'modules/obrasci/store/obrazac3/obrazac3.store';
import { IGetObrazac3IzmereneVrednostiOtpadneVode } from 'modules/obrasci/store/obrazac3/obrazac3.types';
import React from 'react';
import { useTranslation } from 'react-i18next';

const EditObrazac3AnalizaOtpadneVodeIzmereneVrednostiButton: React.FC<
  EditButtonProps<IGetObrazac3IzmereneVrednostiOtpadneVode>
> = observer(({ record }) => {
  return (
    <RowEditPreviewButton
      name="obrazac3-analiza-otpadne-vode-izmerene-vrednosti"
      modalName="obrazac3-analiza-otpadne-vode-izmerene-vrednosti-modal"
      onClick={() => obrazac3Store.handleChange('izmerene_vrednosti_otpadne_vode_form', record)}
      preview={obrasciStore.zakljucanObrazac}
    />
  );
});

export const Obrazac3AnalizaOtpadneVodeIzmereneVrednostiTable: React.FC = observer(() => {
  const { t } = useTranslation();

  const baseColumns: ColumnsType<IGetObrazac3IzmereneVrednostiOtpadneVode> = [
    {
      title: `${t('OBRASCI.OBRAZAC3.NAZIV_ZAGADJUJUCE_MATERIJE')}`,
      dataIndex: ['naziv_zagadjujuce_materije', 'name_sr'],
      key: 'naziv_zagadjujuce_materije_id',
      width: 200,
    },
    {
      title: `${t('OBRASCI.OBRAZAC3.JEDINICA_MERE')}`,
      dataIndex: ['jedinica_mere', 'code'],
      key: 'jedinica_mere_id',
      width: 200,
    },
    {
      title: `${t('OBRASCI.OBRAZAC3.IZMERENA_VREDNOST')}`,
      width: 200,
      dataIndex: 'izmerene_vrednosti',
      key: 'izmerene_vrednosti',
    },

    {
      title: `${t('OBRASCI.OBRAZAC3.METOD_ODREDJIVANJA')}`,
      dataIndex: ['metod_odredjivanja', 'name_sr'],
      key: 'metod_odredjivanja_id',
      width: 200,
    },
    {
      title: `${t('OBRASCI.OBRAZAC3.EMITOVANE_AKCIDENTNE')}`,
      width: 200,
      dataIndex: 'emitovana_kolicina_u_akcidentnoj_situaciji',
      key: 'emitovana_kolicina_u_akcidentnoj_situaciji',
    },
    {
      key: 'operation',
      fixed: 'right',
      width: 150,
      align: 'center',
      render: (_, record: IGetObrazac3IzmereneVrednostiOtpadneVode) => (
        <EditObrazac3AnalizaOtpadneVodeIzmereneVrednostiButton record={record} />
      ),
    },
  ];

  return (
    <TableData
      name="analiza-otpadne-vode-izmerene-vrednosti-obrazac3"
      columns={baseColumns}
      dataSource={[...obrazac3Store.otpadne_vode_merenje_form.izmerene_vrednosti_otpadne_vode]}
    />
  );
  // resi store
});
