import { obrazacIzvestajiTranslations } from './obrazacIzvestaji.srpski';
import { ITranslation } from './translation.types';
import { zakljucavanjeObrazacaTranslations } from './zakljucavanjeObrazaca/zakljucavanjeObrazaca.srpski';
const translations: ITranslation = {
  LOGIN_PAGE: {
    LOGIN: 'Uloguj se',
    SIGNUP: 'Kreiraj nalog',
  },

  IZJAVA_ODGOVORNOSTI: {
    TITLE: 'IZJAVA O ODGOVORNOSTI',
    NACIN_I_OBAVEZE:
      'Način i obaveze dostavljanja podataka o proizvodima koji posle upotrebe postaju posebni tokovi otpada propisani su članom 140 - 147 Zakon o naknadama za korišćenje javnih dobara i Uredbom o proizvodima koji posle upotrebe postaju posebni tokovi otpada, obrascu dnevne evidencije o količini i vrsti proizvedenih i uvezenih proizvoda i godišnjeg izveštaja, načinu i rokovima dostavljanja godišnjeg izveštaja, obveznicima plaćanja naknade, kriterijumima za obračun, visinu i način obračunavanja i plaćanja naknade. Kontrola dostavljanja podataka i kontrola tačnosti dostavljanja podataka za Nacionalni registar izvora zagađivanja propisana je članom 75a Zakon o zaštiti životne sredine.',
    DOSTAVLJENI_PODACI:
      'Dostavljeni podaci o proizvodima koji posle upotrebe postaju posebni tokovi otpada smatraju se tačnim pod krivičnom i materijalnom odgovornošću odgovornog lica u preduzeću.',
    PRIHVATANJE: 'Prihvatam uslove IZJAVE O ODGOVORNOSTI',
  },

  ISTORIJA_PROMENA: {
    TITLE: 'Istorija promena',
  },

  SIFARNICI: {
    IZVESTAJ_CARINE: 'PTP2 | Izveštaj carine',
    IZVESTAJ_CARINE_KATEGORIJE_PROIZVODA: 'PTP2 | Izveštaj carine / Kategorije proizvoda',
    PZV_PROIZVODI: 'PZV | Proizvodi',
    CARINA_I_PROIZVODJAC: 'PZV | Carina i proizvođač',
    CARINA_I_PROIZVODJAC_PROIZVODI: 'Proizvodi',
    LABORATORJE_VAZDUH_VODA: 'Laboratorije vazduh - voda',
    LISTA_PROIZVODA_PTP2_VRSTA: 'PTP2 | Lista proizvoda',
    LISTA_PROIZVODA_PTP2_PROIZVODI: 'PTP2 | Lista proizvoda / Proizvodi',
    APR: 'APR',
    APR_ADRESNI_PODACI: 'Adresni podaci',
    APR_ZASTUPNICI_OSNIVACI: 'Zastupnici - Osnivači',
    PROIZVOD: 'Proizvod',
    NETO_MASA: 'Neto masa',
    OPIS: 'Opis',
    OSLOBODJENI: 'Oslobođeni',
    NAZIV_STRUCNE_LABORATORIJE: 'Naziv stručne laboratorije',
    AIR: 'Vazduh',
    WATER: 'Voda',
    LOCATION: 'Lokacija',
    OD_GODINE: 'Od godine',
    DO_GODINE: 'Do godine',
    DETERDZENT: 'Deterdžent',
    DJUBRIVO: 'Đubrivo',
    SADRZAJ_FOSFATA_AZOTA: 'Sadržaj fosfata ili azota ili aktivne supstance (%)',
    DODATNE_INFORMACIJE: 'Dodatne informacije',
    PROIZVOD_VAZI_ZA_IZVESTAJNE_GODINE: 'Proizvod važi za izveštajne godine:',
    OD: 'Od',
    DO: 'Do',
    OD_DATUMA: 'Od datuma',
    DO_DATUMA: 'Do datuma',
    VRSTA_PROIZVODA: 'Vrsta proizvoda',
    ZA_PERIOD: 'Za period',
    NAZIV: 'Naziv',
    RAZRED: 'Razred',
    JEDINICA: 'Jedinica',
    CENA_ILI_PROCENAT_PDV: 'Cena ili procenat PDV-a',
    DINARA_ILI_PDV: 'Dinara ili % PDV-a',
    REDOSLED: 'Redosled',
    CARINA_PROIZVODJAC: 'Carina i proizvođač',
    IZVESTAJ_CARINE_KATEGORIJE: 'Izveštaj carine',
    UNESITE_NAZIV_PREDUZECA: 'Unesite naziv preduzeća',
    UNESITE_LOKACIJU: 'Unesite lokaciju',
    UNESITE_PRO_ID: 'Unesite pro_id',
    ORTACKO_PREDUZECE: 'Preduzetničko preduzeće je ortačko',
    BANKRUPCY_NUMBER: 'Matični broj stečajnog dužnika',
    OPERATERI_ZA_AMBALAZNI_OTPAD: 'Operateri za ambalažni otpad',
    SAMOSTALNO_UPRAVLJANJE_OTPADOM: 'Samostalno upravljaju ambalažnim otpadom ',
    SIFRA_TIPA_METODE: 'Šifra tipa metode',
    OZNAKA_METODE: 'Oznaka metode',
    SREDNJI_KOD: 'Srednja šifra',
    OSNOVA_METODE: 'Šifra osnove metoda',
  },

  REGISTRI: {
    TITLE: 'Registri',
    DOZVOLE: {
      TITLE: 'Dozvole za upravljanje otpadom',
    },
    SELECT_PLACEHOLDER: 'Izaberite registar',
  },

  NA_SRPSKOM: '(srp.)',
  YES: 'Da',
  NO: 'Ne',
  NAZIV: 'Naziv',
  AND: 'i',
  APPROVE: 'Odobri',
  SUBMIT: 'Sačuvaj',
  DELETE: 'Obriši',
  CHANGE: 'Izmeni',
  DISCARD: 'Odbaci',

  ENGLISH: 'Engleski',
  SERBIAN: 'Srpski',
  NO_DATA: 'Nema podataka',
  BACK: 'Nazad',
  YEAR: 'Godina',
  SELECT_YEAR: 'Izaberite godinu',
  SELECT_DATE: 'Izaberite datum',
  NO_OPTIONS_FOUND: 'Nema rezultata',
  ADDRESS: 'Adresa',
  SUCCESS: 'Uspešno!',
  AT_LEAST_ONE: 'Potrebno je uneti ili PIB ili matični broj',
  KOORDINATE_TEXT: 'Koordinate lokacije možete pronaći na adresi:',
  BROJ_MORA_BITI_IZMEDJU: 'Broj mora biti između',
  BROJ_CIFARA_NE_MOZE_BITI_VECI_OD: 'Broj cifara ne može biti veći od',
  BROJ_DOKUMENTA: 'Broj dokumenta',

  MONTHS: {
    JANUARY: 'Januar',
    FEBRUARY: 'Februar',
    MARCH: 'Mart',
    APRIL: 'April',
    MAY: 'Maj',
    JUNE: 'Jun',
    JULY: 'Jul',
    AUGUST: 'Avgust',
    SEPTEMBER: 'Seprembar',
    OCTOBER: 'Oktobar',
    NOVEMBER: 'Novembar',
    DECEMBER: 'Decembar',
  },

  COMPANY: {
    TITLE: 'Preduzeće',
    COMPANIES: 'Preduzeća',
    NAME: 'Ime preduzeća',
    SHORT_NAME: 'Kraći naziv preduzeća',
    FULL_NAME: 'Pun naziv preduzeća',
    STATUS: 'Status preduzeća',
    ACTIVE: 'Aktivan',
    ENTERPRISE_ID_NUMBER: 'Matični broj preduzeća',
    PIB: 'PIB',
    POST_NUMBER: 'Poštanski broj',
    PRINCIPAL_BUSINESS_ACTIVITY_CODE: 'Šifra pretežne delatnosti',
    LOCATION: 'Mesto',
    LOCATION_NAME: 'Naziv mesta',
    ADDRESS: 'Ulica',
    ADDRESS_NUMBER: 'Kućni broj',
    USER_NAME: 'Ime ovlašćenog korisnika',
    USER_SURNAME: 'Prezime ovlašćenog korisnika',
    PHONE: 'Broj telefona',
    EMAIL: 'Email preduzeća',
    DESIRED_LANGUAGE: 'Željeni jezik',
    LIST: 'Spisak preduzeća',
    LATITUDE: 'Geografska širina',
    LONGITUDE: 'Geografska dužina',
    NOTE: 'Napomena',
    GENERAL_INFORMATION: 'Opšte informacije',
    ADDITIONALLY: 'Dodatno',
    DATE_FOUNDING: 'Datum osnivanja',
    LEGAL_FORM: 'Pravna forma',
    CONDITION_OF_WORK_END: 'Uslov kraja postojanja',
    END_OF_WORK: 'Datum kraja postojanja',
    DATE_DELETED: 'Datum brisanja',
    ADDRESS_TYPE: 'Tip adrese',
    FLOOR: 'Sprat',
    APARTMENT: 'Stan',
    LETTER: 'Slovo',
    NAME_OF_POST_OFFICE: 'Naziv pošte',
    TYPE_SHAPE_ORGANIZING: 'Vrsta oblika organizovanja',
    SIZE_BUSINESS_ENTITY: 'Veličina privrednog subjekta',
    TEMPORARY_INTERUPTION_FROM: 'Privremeni prekid od',
    TEMPORARY_INTERUPTION_TO: 'Privremeni prekid do',
    DEBTOR_IN_POSSESSION: 'Matični broj stečajnog dužnika',
    DATA_SOURCE: 'Izvor podataka',
    COMPANY_PARTNERSHIP: 'Preduzetničko preduzeće je ortačko',
    ADDITIONAL_ADDRESS_INFO: 'Dodatni adresni podaci',
    BUSINESS_ENTITY_VALIDATION: 'Morate odrediti tip privrednog subjekta',

    BUSINESS_ENTITY_TYPES: {
      BUSINESS_COMPANY: 'Privredno društvo',
      ENTREPRENEUR: 'Preduzetnik',
      ASSOCIATION: 'Udruženje',
      BANKRUPTCY_ESTATE: 'Stečajna masa',
      FOUNDATION_ENDOWMENT: 'Fondacija/ zadužbina',
      SPORTS_ASSOCIATION: 'Sportsko udruženje',
      CHAMBER: 'Komora',
      HOUSING_COMMUNITY: 'Stambena zajednica',
      OTHER: 'Ostali',
    },

    APR_TITLES: {
      TITLE: 'APR',
      TYPE_BUSINESS_ENTITY: 'Tip privrednog subjekta',
      BASIC_INFO: 'Osnovni podaci',
      SPECIFIC_DATA_TYPE_BUSINESS_ENTITY: 'Specifični podaci za tip privrednog subjekta',
      SOURCE: 'Izvor',
      REPRESENTATIVE_FOUNDER: 'Zastupnik/Osnivač - Fizičko lice',
      REPRESENTATIVE_LEGAL_ENTITY: 'Zastupnik - Pravno lice',
      REPRESENTATIVE_ASSIGNEE: 'Predstavnik pravnog lica koje je zastupnik',
      FUNCTION: 'Funkcija',
      OTHER_INFO: 'Ostali podaci',
    },

    PLACEHOLDER: {
      ENTER_NAME: 'Unesite ime preduzeća',
      ENTER_SHORT_NAME: 'Unesite kraći naziv preduzeća',
      ENTER_PIB: 'Unesite PIB preduzeća',
      ENTER_IDENTIFICATION_NUMBER: 'Unesite matični broj preduzeća',
      ENTER_POST_NUMBER: 'Unesite poštanski broj',
      ENTER_CITY: 'Unesite mesto preduzeća',
      ENTER_ADDRESS: 'Unesite ulicu preduzeća',
      ENTER_ADDRESS_NUMBER: 'Unesite kućni broj',
      ENTER_USER_NAME: 'Unesite ime ovlašćenog korisnika',
      ENTER_USER_SURNAME: 'Unesite prezime ovlašćenog korisnika',
      ENTER_PHONE: 'Unesite broj telefona preduzeća',
      ENTER_EMAIL: 'Unesite email preduzeća',
      ENTER_PRINCIPAL_BUSINESS_ACTIVITY_CODE: 'Unesite šifru pretežne delatnosti',
      CHOOSE_FROM_LIST: 'Izaberite preduzeće',
      ENTER_YEAR: 'Unesite godinu',
      ENTER_PHONE_NUMBER: 'Unesite broj telefona',
    },
  },

  USER: {
    USERNAME: 'Korisničko ime',
    TITLE: 'Korisnik',
    USERS: 'Korisnici',
    LIST: 'Spisak korisnika',
    NAME: 'Ime',
    SURNAME: 'Prezime',
    EMAIL: 'Email',
    LANGUAGE: 'Jezik',
    PHONE: 'Telefon',
    PASSWORD: 'Šifra',
    DELETE: 'Obriši korisnika',
    ADD: 'Dodaj korisnika',
    ADD_BUTTON: 'Dodaj',
    SET_RESPONSIBLE: 'Odgovorna osoba postrojenja',
    ADD_TO_FACILITY: 'Dodaj korisnika postrojenju',
    JMBG: 'JMBG/ lični broj',
    NUMBER_OF_PASSPORT: 'Broj pasoša/ izbegličke dokumentacije',
    COUNTRY: 'Država',
    TYPE_ASSIGNEE: 'Tip zastupnika',
    TYPE_LEGAL_ENTITY: 'Tip lica pravnog lica',
    TYPE_PERSON: 'Tip lica',
    MASTER_NUMBER_ASSIGNEE: 'Matični broj zastupnika',
    ASSIGNEE_NAME: 'Naziv zastupnika',
    TYPE_REPRESENTATIVE: 'Tip lica predstavnika',
    REPRESENTATIVE_NAME: 'Ime predstavnika',
    REPRESENTATIVE_SURNAME: 'Prezime predstavnika',
    REPRESENTATIVE_JMBG: 'JMBG/ lični broj predstavnika',
    REPRESENTATIVE_PASSPORT: 'Broj pasoša/ izbegličke dokumentacije predstavnika',
    REPRESENTATIVE_COUNTRY: 'Država predstavnika',
    ASSIGNEE_FUNCTION: 'Funkcije zastupnika',
    ASSIGNEE_FUNCTION_OTHER: 'Druga funkcija zastupnika',
    UNIQUE_IDENTIFIER: 'Jedinstveni identifikator lica',
    REPRESENTS_INDEPENDANTLY: 'Samostalno zastupa',
    HAS_SIGNING_RESTRICTIONS: 'Ima ograničenja supotpisom',
    IME_ILI_PREZIME: 'Ime ili prezime',

    ALERTS: {
      INFO: 'Sačekajte da administrator odobri preduzeće, bićete obavešteni putem email-a preduzeća.',
      WARNING: 'Došlo je do greške. Kontaktirajte administratora na broj : 35246576879',
      REGISTRATION_SUCCESS:
        'Uspešno ste se registrovali. Postavite šifru klikom na link koji ste dobili na e-mail preduzeća.',
      DELETE: 'Da li ste sigurni da želite da obrišete korisnika?',
      DELETE_SUCCESS: 'Korisnik uspešno obrisan!',
      RESPONSIBLE: 'Da li ste sigurni da želite da promenite odgovornu osobu?',
      RESPONSIBLE_SUCCESS: 'Odgovorna osoba uspešno promenjena!',
    },
  },
  POLLUTION: {
    TITLE: 'Izvori zagađivanja',
    AIR: {
      TITLE: 'Izvor zagađenja / Vazduh',
      LIST: 'Izvor zagađujućih materija u vazduh',
      SOURCE_NUMBER: 'Broj izvora',
      SOURCE_NAME: 'Naziv izvora',
      ACTIVE: 'Aktivan',
      SOURCE_TYPE: 'Vrsta izvora',
      ELEVATION: 'Nadmorska visina (mnv)',
      INTALLED_HEAT_POWER_AT_ENTRANCE: 'Instalisana toplotna snaga na ulazu (MWth)²',
      SOURCE_HEIGHT: 'Visina izvora (m)',
      INNER_DIAMETER_OF_SOURCE_ON_TOP: 'Unutrašnji prečnik izvora na vrhu (m)',
      OBLIGATION_TO_MEASURE: 'Obaveza merenja:',
      COORDINATE_CONCLUSION: 'Zaključaj koordinate',
    },

    SOIL: {
      TITLE: 'Izvor zagađenja / Tlo',
      LIST: 'Lokacije zagađivanja u tlo',
      LOCATION_NUMBER: 'Broj lokacije',
      LOCATION_NAME: 'Naziv lokacije',
    },

    WATER: {
      TITLE: 'Izvor zagađenja / Voda',
      LIST: 'Ispusti zagadjujućih materija u vode',
      GENERAL_WATER: 'Voda - opšte ',
      SEWER_NUMBER: 'Broj ispusta',
      SEWER_NAME: 'Naziv ispusta',
      PROJECTED_CAPACITY_OF_SEWER: 'Projektovani kapacitet ispusta (l/s)',
      TYPE_OF_RECIPIENT: 'Vrsta recipijenta',
      NAME_RECIPIENT: 'Naziv recipijenta',
      BASIN: 'Sliv',

      TYPE_OF_DISCHARGED_WASTEWATER: ' Vrsta otpadne vode koja se ispušta:',
      SANITARY: 'Sanitarne',
      TECHNOLOGICAL: 'Tehnološke',
      COOLING: 'Rashladne',
      ATMOSPHERIC: 'Atmosferske',
      FACILITY_FOR_WASTEWATER_TREATMENT: 'Postrojenje za tretman otpadne vode',
      IS_THERE_A_TREATMENT_FACILITY_WASTEWATER: 'Da li imate postrojenje za prečišćavanje otpadnih voda?',

      DEVICES_IN_THE_INSTALLATION_OF_WASTEWATER_TREATMENT_FACILITY:
        ' Uređaji u postrojenju za prečišćavanje otpadnih voda (PPOV):',

      MECHANICAL_TREATMENT: 'Mehaničko prečišćavanje',
      GRID: 'Rešetka',
      SIEVE_MECHANICAL_FILTER: 'Sito, mehanički filter',
      SAND_CATCHER: 'Peskolov',
      AERATED_SAND_CATCHER: 'Aerisani peskolov',
      PRECIPITATOR_LONGITUDINAL: 'Taložnik - uzdužni',
      PRECIPITATOR_LAMINAR: 'Taložnik - laminarni',
      PRECIPITATOR_RADIAL: 'Taložnik - radijalni',
      GREASE_AND_OIL_SEPARATOR: 'Separator masti i ulja',
      FLOTATOR: 'Flotator',
      SAND_FILTER: 'Peščani filter',

      CHEMICAL_TREATMENT: 'Hemijsko prečišćavanje',
      NEUTRALISATION_DEVICES: 'Uređaj za neutralizaciju',
      DETOXIFICATION_DEVICES: 'Uređaj za detoksikaciju',
      ION_EXCHANGE: 'Jonska izmena',
      CHLORINATION: 'Hlorisanje',
      OZONATION: 'Ozonizacija',

      BIOLOGICAL_TREATMENT: 'Biološko prečišćavanje',
      LAGOON: 'Laguna',
      AERATION_POOL: 'Aeracioni bazen',
      BIO_FILTER: 'Bio-filter',
      BIO_DISC: 'Bio-disk',
      NITRIFICATION: 'Nitrifikacija',
      DENITRIFICATION: 'Denitrifikacija',
      SLUDGE_FERMENTATION: 'Fermentacija mulja',

      DEVICES_FOR_CHANGING_THE_HEAT: 'Uređaji za izmenu toplote',
      NATURAL_HEAT_INTERCHANGE_POOL_LAGOON: 'Prirodna izmena toplote - bazeni, lagune',
      COOLING_TOWER_NATURAL_AIR_CIRCULATION: 'Rashladni toranj - prirodna cirkulacija vazduha',
      COOLING_TOWER_FORCED_AIR_CIRCULATION: 'Rashladni toranj - prisilna cirkulacija vazduha',
      CLOSED_COOLING_DEVICES: 'Zatvoreni rashladni uređaji',
      WATER_BODY: 'Vodno telo',
    },
  },
  SURVEY: {
    TITLE: 'Upitnik',
    STEP_1_QUESTION_1: ' Da li vaše preduzeće u poslovnom procesu stvara otpad?',
    STEP_1_QUESTION_2:
      ' Da li vaše preduzeće preuzima otpad od fizičkih lica ili drugih pravnih lica ili preduzetnika?',
    STEP_1_QUESTION_3: ' Da li vaše preduzeće poseduje dozvolu za odlaganje otpada?',
    STEP_1_QUESTION_4: ' Da li vaše preduzeće poseduje dozvolu za ponovno iskorišćenje otpada?',
    STEP_1_QUESTION_5: ' Da li vaše preduzeće poseduje dozvolu za izvoz otpada?',
    STEP_1_QUESTION_6: ' Da li vaše preduzeće poseduje dozvolu za uvoz otpada?',
    STEP_2_QUESTION_1: ' Da li vaše preduzeće proizvodi ili uvozi proizvode iz sledećih kategorija:',
    CATEGORIES_1: 'Električni i elektronski proizvodi, Akumulatori i baterije, Ulja, Gume, Vozila, Kese',
    STEP_2_QUESTION_2: ' Da li vaše preduzeće proizvodi ili uvozi proizvode iz sledećih kategorija:',
    CATEGORIES_2: 'Mineralna đubriva, Deterdženti, Sredstva za zaštitu bilja',
    STEP_3_QUESTION_1:
      ' Da li je vaše preduzeće proizvođač, uvoznik, paker/punilac, isporučilac ambalaže ili upakovanog proizvoda i/ili preduzeće koje samostalno upravlja ambalažnim otpadom?',
    STEP_4_QUESTION_1: ' Da li vaše preduzeće emituje zagađujuće materije u vazduh i/ili vode i/ili tlo?',
  },

  FACILITY: {
    TITLE: 'Postrojenje',
    LIST: 'Lista postrojenja',
    NAME: 'Naziv postrojenja',
    VERIFICATION_CODE: 'Verifikacioni kod',
    PREVIOUS_NATIONAL_ID: 'Prethodni nacionalni ID',
    HISTORICAL_ID: 'Istorijski ID',
    NACIONAL_ID: 'Nacionalni ID',

    PLACEHOLDER: {
      ENTER_NAME: 'Unesite naziv postrojenja',
      ENTER_LATITUDE: 'Unesite geografsku širinu',
      ENTER_LONGITUDE: 'Unesite geografsku dužinu',
      ENTER_VERIFICATION_CODE: 'Unesite verifikacioni kod sa email-a preduzeća',
      ENTER_PREVIOUS_NATIONAL_ID: 'Unesite prethodni nacionalni ID',
      ENTER_HISTORICAL_ID: 'Unesite istorijski ID',
      ENTER_NATIONAL_ID: 'Unesite nacionalni ID',
      ENTER_PHONE_NUMBER: 'Unesite broj telefona',
      CHOOSE_FROM_LIST: 'Izaberite postrojenje',
      CHOOSE_POLLUTION_SOIL: 'Izaberite izvor zagađenja tla',
    },
  },

  ACTIVITIES: {
    TITLE: 'Lista aktivnosti',
    SUBTITLE: 'Lista aktivnosti',
    SUBTITLE1: 'Lista aktivnosti 1',
    SUBTITLE2: 'Lista aktivnosti 2',
    NEW_ACTIVITY_TITLE: 'Nova aktivnost',
    ECONOMIC_ACTIVITY: 'Privredna delatnost',
    MAIN: 'Glavna aktivnost',
    ACTIVITY: 'Aktivnost',
    ECONOMIC_ACTIVITY_PLACEHOLDER: 'Pretrazi privredne delatnosti',
    ACTIVITY_PLACEHOLDER: 'Pretrazi aktivnosti',
    ECONOMIC_ACTIVITY_VALIDATION: 'Izaberite privrednu delatnost',
    ACTIVITY_VALIDATION: 'Izaberite aktivnost',
    MODAL_INFO:
      'Pri izboru aktivnosti, obratiti pažnju da li izabrana aktivnost ispunjava uslove minimalne granične vrednosti kapaciteta postrojenja.',
    ALREADY_EXISTS: 'Aktivnost već postoji u listi',
    NO_ECONOMIC_ACTIVITY_CHOSEN: 'Morate izabrati privrednu delatnost',
  },

  UNSANITARY_LANDFILL: {
    TITLE: 'Nesanitarna deponija',
    LIST: 'Lista nesanitarne deponije',
    MARK_OF_UNSANITARY_LANDFILL: 'Oznaka',
    NAME: 'Naziv',
    OCCUPIED_PLOTS: 'Zauzete katastarske parcele',
    LANDFILL_PERIOD: 'Period deponovanja',
  },

  VALIDATION: {
    IS_REQUIRED: 'Obavezno polje',
    IS_NOT_VALID_EMAIL: 'Email nije validan',
    IS_NOT_VALID_NUMBER: 'Nije validan broj',
    IS_NOT_VALID_PIB: 'Nije validan PIB',
    IS_NOT_VALID_PAK: 'Nije validan PAK',
    IS_NOT_VALID_IDENTIFICATION_NUMBER: 'Nije validan matični broj',
    IS_NOT_VALID_PASSPORT: 'Nije validan broj pasoša',
    IS_NOT_VALID_YEAR: 'Nije validan unos za godinu',
    IS_NOT_VALID_VERIFICATION_CODE: 'Nije validan verifikacioni kod',
    IS_TOO_LONG: 'Predugačak unos',
    BETWEEN: 'Mora biti izmedju',
    SELECT_A_FIELD: 'Izaberite jedno polje',
    IS_MORE_THAN_0: 'Broj mora biti veći od 0',
    IS_MORE_THAN_OR_EQUALS_0: 'Broj mora biti veći ili jednak 0',
    ALREADY_EXISTS: 'Već postoji u listi',
    SADRZAJ_PROCENTI: 'Procenat sadržaja mora biti vrednost u rasponu 0-100 ne može biti 0',
  },
  POPCONFIRM: {
    APROVECOMPANY: 'Da li želite da odobrite ovo preduzeće?',
    REJECT_COMPANY: 'Da li želite da odbijete ovo preduzeće?',
  },

  OBRASCI: {
    TIPOVI_OBRAZACA: 'Tipovi obrazaca',
    OBRASCI: 'Obrasci',
    CHOSE_GIO_CHOSE_DEO: 'Potrebno je uključiti DEO6/GIO6',
    DEP_2: {
      TITLE: 'Divlja deponija',
      SETTLEMENT: 'Naselje',
      ESTIMATED_AMOUNT_OF_WASTE: 'Procenjena količina otpada (t)',
      ESTIMATED_OF_THE_AREA_OF_LANDFILLS: 'Procenjena površina smetilišta (m²)',
      HOW_MANY_TIMES_CLEANED: 'Koliko puta je čišćen prostor divlje deponije u toku izveštajne godine',
      IS_THERE_REPEATED_DUMPING: 'Da li se na istom mestu ponavlja divlje odlaganje otpada?',
      VALIDATION: {
        DODAJ_DEPONIJU: 'Potrebno je dodati bar jednu divlju deponiju da bi se sačuvao obrazac',
      },
    },
    PZV_1: {
      TITLE: 'PZV1 Obrazac',
      QUANTITY: 'Količina',
      DETERGENTS: {
        TITLE: 'Deterdženti',
        TYPE: 'Vrsta deterdženta',
        QUANTITY: 'Količina deterdženta stavljena na tržište (kg)',
        NAME: 'Naziv deterdženta',
        CONTENT: 'Sadržaj fosfata u deterdžentu (%)',
        PLACEHOLDERS: {
          TYPE: 'Izaberite vrstu deterdženta',
          NAME: 'Unesite naziv deterdženta',
          QUANTITY: 'Unesite potrošenu količinu',
          CONTENT: 'Unesite sadržaj fosfata',
        },
        ERROR: {
          TYPE: 'Deterdžent ove vrste već postoji u listi',
          NAME: 'Deterdžent pod ovim nazivom već postoji u listi',
        },
      },
      FERTILIZERS: {
        TITLE: 'Mineralna đubriva',
        TYPE: 'Naziv mineralnog đubriva',
        QUANTITY: 'Količina mineralnog đubriva stavljena na tržište (kg)',
        CONTENT: 'Sadržaj azota u mineralnom đubrivu (%)',
        PLACEHOLDERS: {
          TYPE: 'Izaberite vrstu mineralnog đubriva',
          QUANTITY: 'Unesite potrošenu količinu',
          CONTENT: 'Unesite sadržaj azota',
        },
        ERROR: {
          TYPE: 'Đubrivo ove vrste već postoji u listi',
        },
      },
      PLANT_PROTECTION: {
        TITLE: 'Zaštita bilja',
        TYPE: 'Naziv hemijskog sredstva za zaštitu bilja',
        QUANTITY: 'Količina hemijskog sredstva za zaštitu bilja stavljena na tržište (kg)',
        CONTENT: 'Sadržaj aktivne materije u sredstvu za zaštitu bilja (%)',
        PLACEHOLDERS: {
          TYPE: 'Izaberite vrstu sredstva za zaštitu bilja',
          QUANTITY: 'Unesite potrošenu količinu',
          CONTENT: 'Unesite sadržaj aktivne materije u sredstvu za zaštitu bilja',
        },
        ERROR: {
          TYPE: 'Sredstvo za zaštitu bilja ove vrste već postoji u listi',
        },
      },
      NOT_FOUND: 'Nema proizvoda za izabranu godinu',
      VREDNOST_VECA_OD_0: 'Vrednost polja mora biti veća od 0',
    },
    PDV: 'PDV',

    OBRAZAC_4: {
      TITLE: 'Obrazac 4: Emisije u tlo',
      ADDITIONAL_INFO: {
        TITLE: 'Podaci o lokaciji i operaciji odlaganja',
        WASTE_TYPE: 'Vrsta odloženog otpada',
        WASTE_INDEX: 'Indeksni broj otpada koji se odlaže',
        AMOUNT_YEAR: 'Godišnja količina odloženog otpada (t/god)',
        AMOUNT_TOTAL: 'Ukupna količina odloženog otpada (t)',
        OPERATION: 'Operacija odlaganja',
      },
      SOIL_EMISSION: {
        TITLE: 'Zagađujuća materija',
        WASTE_NAME: 'Naziv zagađujuće materije',
        CONCENTRATION: 'Koncentracija zagađujuće materije u otpadu (mg/kg s.m.)',
        QUANTITY: 'Količina zagađujuće materije u odloženom otpadu (kg/god)',
        WAY: 'Način određivanja',
        METHOD: 'Metod utvrđivanja',
      },
      VALIDATION: {
        DODATI_EMISIJU: 'Dodati emisiju',
      },
    },
    GIO_3: {
      // Ž ž Č č Ć ć Š š đ
      AMOUNT_OF_ACCEPTED_WASTE_FOR_REUSE_AND_REUSE_OF_ACCEPTED_WASTE:
        'Količina preuzetog otpada za ponovno iskorišćenje i ponovno iskorišćenje preuzetog otpada',
      AMOUNT_OF_COLLECTED_WASTE_IN_REPORTING_YEAR: 'Količina preuzetog otpada u izveštajnoj godini (t)',
      THE_TOTAL_AMOUNT_OF_THIS_TYPE_OF_WASTE_REUSED_IN_REPORTING_YEAR:
        'Ukupna količina ove vrste otpada ponovno iskorišćena u izveštajnoj godini (t)',
      BALANCE_OF_THE_TEMPORARY_WAREHOUSE_JANUARY: 'Stanje privremenog skladišta na dan 1. januar (t)',
      BALANCE_OF_THE_TEMPORARY_WAREHOUSE_DECEMBER: 'Stanje privremenog skladišta na dan 31. decembar (t)',
      RECYCLING_PROCESS: 'Postupak ponovnog iskorišćenja',
      ANOTHER_RECYCLING_PROCESS: 'Drugi postupak ponovnog iskorišćenja',
      TYPE_OF_ELECTRICAL_AND_ELECTRONIC_WASTE: 'Vrsta električnog i elektronskog otpada',
      CLASS_EE_WASTE: 'Razred EE otpada',
      QUANTITY_T: 'Količine (t)',
      R_MARK: 'R oznaka',
      D_MARK: 'D oznaka',
      QUANTITIES_OF_INCINERATED_WASTE_WITH_ENERGY: 'Količine spaljenog otpada uz proizvodju energije (oznaka R1) (t)',
      AMOUNT_OF_COMPOSTED_WASTE: 'Količina kompostiranja otpada (oznaka R3) (t)',
      DATA_ON_THE_WASTE_RECYCLING_FACILITY_OWNED_BY_COMPANY:
        ' Podaci o postrojenju za ponovno iskoriscenje otpada koje je u vlasnistvu preduzeca',
    },
    KOM_1: {
      TITLE: '',
      QUANTITY: 'Količine',
      QUANTITY_T: 'Količina (t)',
      MUNICIPALITY: 'Opština',
      COMPOSITION: 'Sastav',
      COMPOSITION_OF_MUNICIPAL_WASTE: 'Sastav komunalnog otpada',
      QUANTITY_FRACTIONS: 'Količina frakcije (t)',
      TYPE_OF_WASTE: 'Vrsta otpada',
      SPRING_ANALYSIS: 'Prolećna analiza (t/ned.)',
      AUTUMN_ANALYSIS: 'Jesenja analiza (t/ned.)',
      SUMMER_ANALYSIS: 'Letnja analiza (t/ned.)',
      WINTER_ANALYSIS: 'Zimska analiza (t/ned.)',
      MIDDLE_VALUE: 'Srednja godišnja vrednost (t/ned.)',
      KOLICINE_SAKUPLJENOG_OTPADA: 'Količine sakupljenog otpada tokom sezonskih analiza:',
      // Ž ž Č č Ć ć Š š đ
      TOTAL_NUMBER_OF_HOUSEHOLDS_IN_SETTLEMENT: 'Ukupan broj domaćinstava u naselju',
      NUMBER_OF_HOUSEHOLDS_COVERED_BY_WASTE_COLLECTION: 'Broj domaćinstava obuhvaćen prikupljanjem otpada',

      EQUIPMENT_AND_MECHANISATION: {
        TITLE: 'Oprema i mehanizacija',

        WASTE_COLLECTION_EQUIPMENT: 'Oprema za prikupljanje otpada',
        NUMBER: 'Broj',
        UTILITY_CONTAINER_OF_5_M3: 'Komunalni kontejner od 5 m³',
        UTILITY_CONTAINER_OF_7_M3: 'Komunalni kontejner od 7 m³',
        UTILITY_CONTAINER_OF_10_M3: 'Komunalni kontejner od 10 m³',
        PRESS_OR_ROLL_CONTAINERS: 'Prese ili rolo kontejneri',
        CONTAINERS_OF_1_1_M3: 'Kontejneri od 1,1 m³',
        LARGE_CONTAINERS: 'Veliki kontejneri',
        OTHER_TYPES_OF_CONTAINERS: 'Druge vrste kontejnera',
        BUCKETS: 'Kante',
        CONTAINERS_FOR_SEPARATE_COLLECTION_OF_SECONDARY_RAWARE: 'Kontejneri za odvojeno prikupljanje sek. sirovina',
        BAGS: 'Kese',

        WASTE_TRANSPORT_EQUIPMENT: 'Oprema za transport otpada',
        VEHICLE_NUMBER: 'Broj vozila',
        GARBAGE_TRUCK: 'Autosmećar (rotopres)',
        ROTO_PRESS: 'Rotopres',
        CAR_LIFTER: 'Autopodizač',
        TRUCK: 'Kamion',
        DUMP_TRACK: 'Kiper',
        TRACTOR_WITH_TRAILER: 'Traktor sa prikolicom',
        CARTED_VEHICLE: 'Zaprežno vozilo',

        NUMBER_OF_MACHINES: 'Broj mašina',

        EQUIPMENT_ON_LANDFILL: 'Oprema na deponiji',
        CRAWLER_TRACTOR: 'Traktor guseničar',
        COMPACTOR: 'Kompaktor',

        OTHER_EQUIPMENT: 'Druga oprema',
        DRINKING_WATER_TANKS: 'Cisterne pijaće vode',
        STREET_CLEANING_TANKS: 'Cisterne za čišćenje ulica',
        CONTAINER_WASHING_VEHICLES: 'Vozila za pranje kontejnera',
        VEHICLES_FOR_CLEANING_SEPTIC_TANKS: 'Vozila za čišćenje septičkih jama',
      },

      MANAGEMENT: {
        TITLE: 'Upravljanje',

        MUNICIPAL_WASTE_MANAGEMENT: 'Upravljanje komunalnim otpadom',
        WASTE_INDEX_NUMBER_FROM_THE_WASTE_CATALOG: 'Indeksni broj otpada iz kataloga otpada',
        THE_TOTAL_AMOUNT_OF_COLLECTED_OR_ACCEPTED_WASTE_IN_REPORTING_YEAR:
          'Ukupna količina sakupljenog ili preuzetog otpada u izveštajnoj godini (t)',
        WAY_OF_DETERMINING_THE_QUANTITY: 'Način određivanja količine',
        THE_AMOUNT_OF_WASTE_COLLECTED_FROM_HOUSEHOLDS: 'Količina sakupljenog otpada iz domaćinstava (t)',

        THE_AMOUNT_OF_COLLECTED_WASTE_FROM_PRIMARY_RECICLING: 'Količina sakupljenog otpada iz primarne reciklaže',
        FROM_THE_PRIMARY_SELECTION: 'Iz kontejnera za primarnu selekciju (t)',
        FROM_RECYCLING_YARDS: 'Iz reciklažnih dvorišta (t)',
        OTHER_COLLECTION_POINTS: 'Drugih sabirnih mesta',

        HANDED_OVER: 'Predato',
        WAY_OF_HAND_OVER: 'Način predaje',
        D_OR_R_MARK: 'D ili R oznaka',
        NAME_AND_ADDRESS_OF_THE_OPERATOR: 'Naziv i adresa operatera',
        OPERATOR_LICENSE_NUMBER: 'Broj dozvole operatera',
      },
      VALIDATION: {
        DODATI_NASELJE: 'Dodati naselje',
        DODATI_SASTAV: 'Dodati sastav',
        DODATI_KOLICINU: 'Dodati količinu',
        ISTO_NASELJE: 'Ne može se ponoviti naselje na nivou obrasca',
        BAR_JEDNO_POLJE: 'Popunite najmanje jedno polje',
      },
    },
    OBRAZAC_1: {
      TITLE: 'Obrazac 1 / Podaci o postrojenju',
      FUEL_NAME: 'Naziv goriva',

      CONSUMPTION_PER_DAY: 'Potrošnja po danu',
      METHOD_OF_STORAGE: 'Način lagerovanja',
      MAXIMUM_STORAGE_CAPACITY: 'Maksimalni kapacitet lagera',
      AVERAGE_AMOUNT_OF_STORAGE: 'Prosečna količina lagera',
      DATA_ON_THE_RESUME_OF_WORK_IN_FACILITY: 'Podaci o režimu rada u postrojenju',
      WORK_SCHEDULE: 'Radni režim',
      START_OF_SEASON: 'Početak sezone (mesec)',
      END_OF_SEASON: 'Kraj sezone (mesec)',
      NUMBERS_OF_SHIFTS_PER_DAY: 'Broj smena dnevno',
      NUMBER_OF_WORKING_DAYS_PER_WEEK: 'Broj radnih dana - nedeljno',
      NUMBER_OF_WORKING_DAYS_PER_YEAR: 'Broj radnih dana - godišnje',
      NUMBER_OF_WORKING_DAYS_PER_SEASON: 'Broj radnih dana - sezonski',
      NUMBER_OF_OPERATING_H0URS: 'Broj operativnih sati',

      DATA_ON_EMPLOYEES_IN_THE_FACILITY: 'Podaci o zaposlenima u postrojenju',
      NUMBER_OF_PERMANENT_EMPLOYEES: 'Broj stalno zaposlenih',
      NUMBER_OF_TEMPORARY_EMPLOYEES: 'Broj povremeno zaposlenih',
      NUMBER_OF_EMPLOYEES_IN_FIRST_SHIFT: 'Broj zaposlenih u prvoj smeni',
      NUMBER_OF_EMPLOYEES_IN_SECOND_SHIFT: 'Broj zaposlenih u drugoj smeni',
      NUMBER_OF_EMPLOYEES_IN_THIRD_SHIFT: 'Broj zaposlenih u trećoj smeni',

      RECAPITULATION_OF_WASTE_PRODUCED_IN_FACILITY: 'Rekapitulacija otpada proizvedenog u postrojenju',
      TOTAL_NUMBER_OF_TYPES_OF_WASTE: 'Ukupan broj vrsta otpada',
      RAW_MATERIALS: 'Sirovine u postrojenju',
      PRODUCT_KEY: 'Šifra proizvoda',
      PRODUCT_NAME: 'Naziv proizvoda',
      DESCRIPTION: 'Opis',
      UNIT_OF_MEASURE: 'Jedinica mere',
      ANNUAL_PRODUCTION: 'Godišnja proizvodnja',
      INSTALLED_CAPACITIES: 'Instalirani kapaciteti',
      AVERAGE_ENGAGED_CAPACITIES: 'Prosečno angažovani kapaciteti',

      CHEMICAL_NAME_IUPAC: 'Hemijski naziv (prema IUPAC-u)',
      COMMERCIAL_NAME: 'Komercijalni naziv',
      AGGREGATE_CONDITION_WHEN_IN_STORAGE: 'Agregatno stanje kada je na skladištu',
      KONTINUALAN: 'Kontinualan',
      SEMIKONTINUALAN: 'Semikontinualan',
      SEZONSKI: 'Sezonski',
      GORIVO_VEC_POSTOJI: 'Gorivo već postoji u listi',
    },

    AAO1: {
      ZBIR_PONOVNO_ISKORISCENJE_PORUKA:
        'Zbir preuzetih količina mora biti veći od 0 i ne sme biti manji od zbira Ukupne količine ponovno iskorišćenog otpada i Ukupne količine odloženog otpada',
      ZBIR_VRSTA_AMBALAZE_PORUKA:
        'Zbir preuzetih količina ne može biti veći od ukupne količine za odgovarajuću vrstu ambalaže iz taba Količina ambalaže',
      TITLE: 'Ambalaža',
      CHECKBOX: {
        TITLE: 'Pravno lice ili preduzetnik (označiti)',
        PACKAGING_MANUFACTURER: 'Proizvođač ambalaže',
        PACKER_OF_PRODUCTS: 'Paker proizvoda u ambalažu ili punilac ambalaže',
        PACKAGING_SUPPLIER: 'Isporučilac koji stavlja u promet ambalažu ili upakovani proizvod',
        IMPORTER_OF_PACKAGING: 'Uvoznik ambalaže',
        EXPORTER_OF_PACKAGING: 'Izvoznik ambalaže',
        END_USER: 'Krajnji korisnik',
      },
      PACKAGING_AMOUNT: {
        TITLE: 'Količina ambalaže',
        PACKAGING_TYPE: 'Vrsta ambalaže',
        NON_RETURNABLE_PACKAGING: 'Nepovratna ambalaža',
        NON_RETURNABLE_PACKAGING_AMOUNT: 'Količina nepovratne ambalaže (t)',
        RETURN_PACKAGING: 'Povratna ambalaža',
        RETURN_PACKAGING_AMOUNT: 'Količina povratne ambalaže (t)',
        MANUFACTURED: 'Proizvedena',
        MANUFACTURED_AMOUNT: 'Količina proizvedene (t)',
        IMPORTED: 'Uvezena',
        IMPORTED_AMOUNT: 'Količina uvezene (t)',
        EXPORTED: 'Izvezena',
        EXPORTED_AMOUNT: 'Količina izvezene (t)',
        TOTAL: 'Ukupna',
        TOTAL_AMOUNT: 'Ukupna količina (t)',
        OPERATOR_AMOUNT: 'Količina preneta na operatera',
        OPERATOR: 'Operater',
        OPERATORS: 'Operateri',
        IF_OPERATOR:
          'Označite ako ste u ovoj godini za ovu vrstu ambalaže svoje obaveze upravljanja ambalažom preneli na operatera',
        ADD_OPERATOR: 'Dodaj operatera',
        PERMIT_NUMBER: 'Broj dozvole',
        ERROR: {
          OPERATOR_AMOUNT: 'Preneta količina ne može biti veća od ukupne',
          OPERATOR_VALIDATION: 'Morate odrediti barem jednog operatera',
          LESS_THAN_0: 'Ukupna količina ne može biti manja od 0',
          TYPE_OF_PACKAGING_EXISTS: 'Vrsta pakovanja vec postoji u listi',
          OPERATOR_EXISTS: 'Operater već postoji u listi',
          OPERATOR_AMOUNT_SUM: 'Zbir količina u tabeli ne može biti veći od ukupne količine prenete na operatere',
        },
        PLACEHOLDERS: {
          PACKAGING_TYPE: 'Izaberite vrstu ambalaže',
        },
      },
      MANAGEMENT_OF_PACKAGING_WASTE: {
        TITLE: 'Upravljanje ambalažnim otpadom',
        PERMIT_NUMBER: 'Broj dozvole',
        DATE_OF_ISSUE: 'Datum izdavanja',
        PACKAGING_TYPE: 'Vrsta ambalaže',
        SELF_MANAGEMENT_AMOUNT: 'Količina za samostalno upravljanje (t)',
        AMOUNT_TAKEN: 'Preuzeta količina (t)',
        RECYCLED_PACKAGING_WASTE: 'Ponovno iskorišćeni ambalažni otpad',
        R1_OPERATION_AMOUNT: 'Količina za R1 operaciju (t)',
        SECOND_R_OPERATION: 'Druga R operacija',
        R2_OPERATION_AMOUNT: 'Količina za drugu R operaciju (t)',
        R3_OPERATION_AMOUNT: 'Količina za R3 operaciju (t)',
        TOTAL_RECYCLED: 'Ukupna količina ponovno iskorišćenog otpada (t)',
        DISCARDED_PACKAGING_WASTE: 'Odloženi ambalažni otpad',
        D1_OPERATION_AMOUNT: 'Količina za D1 operaciju (t)',
        SECOND_D_OPERATION: 'Druga D operacija',
        D2_OPERATION_AMOUNT: 'Količina za drugu D operaciju (t)',
        TOTAL_DISCARDED: 'Ukupna količina odloženog otpada (t)',
        PLACEHOLDERS: {
          PACKAGING_TYPE: 'Izaberite vrstu ambalaže',
          PERMIT_NUMBER: 'Unesite broj dozvole',
          DATE_OF_ISSUE: 'Izaberite datum izdavanja dozvole',
        },
      },
    },

    AAO2: {
      TITLE: 'Ambalažni otpad',
      PACKAGING_AMOUNT: {
        TITLE: 'Količina ambalaže (t)',
        MANUFACTURED_AMOUNT: 'Proizvedena količina (t)',
        IMPORTED_AMOUNT: 'Uvezena količina (t)',
        EXPORTED_AMOUNT: 'Izvezena količina (t)',
        TOTAL_AMOUNT: 'Ukupna količina (t)',
      },
      MANAGEMENT_OF_PACKAGING_WASTE: {
        MUNICIPAL_WASTE: 'Preuzeta količina komunalnog otpada (t)',
        NON_MUNICIPAL_WASTE: 'Preuzeta količina nekomunalnog otpada (t)',
      },
      PRAVNO_LICE_TITLE: 'Podaci o pravnom licu ili preduzetniku koji je preneo obavezu na operatera',

      VALIDATION: {
        DODATI_STAVKU: 'Morate dodati barem jednu stavku u tabu Količina Ambalaže',
        DODATI_STAVKU_PRAVNO_LICE: 'Morate dodati barem jednu stavku u tabu Pravno Lice ili Preduzetnik',
        UNESI_ODGOVARAJUCU_KOLICINU:
          'Za svaku vrstu ambalaže u tabu Količina Ambalaže moraju se uneti odgovarajuće količine u tabu Upravljanje Ambalažnim Otpadom',
      },
    },

    PLACEHOLDER: { CHOOSE_TIP_OBRASCA: 'Izaberi tip obrasca' },

    PTP2: {
      KOLICINA_PROIZVODA_STAVLJENIH_NA_TRZISTE: 'Količina proizvoda stavljenih na tržište',
      KOLICINA_PROIZVODA_KG: 'Količina proizvoda (kg)',
      VRSTE_PROIZVODA_PO_PERIODIMA: 'Vrste proizvoda po periodima',
      NAZIV_PROIZVODA: 'Naziv proizvoda',
      DODAJ_PROIZVOD: 'Dodaj proizvod',
      DOZVOLJENE_KOREKCIJE: 'Dozvoli korekcije',
      NE_POSTOJI_ZA_GODINU: 'Za odabranu godinu nema rezultata',
      VALIDATION: {
        MORA_IMATI_PROIZVOD: 'Lista proizvoda mora imati bar jedan podatak',
        NE_MOZE_BITI_ISTI_PROIZVOD:
          'Ne može se ponoviti ista kombinacija vrste proizvoda po periodima i naziv na nivou obrasca',
      },
      // Ž ž Č č Ć ć Š š đ

      KOLICINA_STAVLJENIH_NA_TRZISTE_PORUKA:
        "Količina proizvoda stavljenih na tržište izražava se u jedinici mere koja je navedena u polju 'Naziv proizvoda'",
      KOLICINA_PROIZVODA_KG_PORUKA:
        "Količina proizvoda (u kilogramima) unosi se samo za proizvode za koje jedinica mere u polju 'Naziv proizvoda' nije kilogram",
      KOLICINA_PDV_PORUKA: 'Vrednost PDV-a se unosi samo za proizvode koji su tako definisani uredbom',
    },

    DEP1: {
      PANELI: {
        KOLICINE_OTPADA: 'Količine otpada i obuhvat prikupljanja otpada na nesanitarnoj deponiji - smetlištu',
        GRADOVI_OPSTINE: 'Gradovi / opštine ili naselja čiji otpad se odlaže na nesanitarnu deponiju - smetlište',
        FIZICKA_ZASTITA: 'Fizička zaštita i opremljenost nesanitarne deponije - smetlišta',
        OSTALI_PODACI: 'Ostali podaci o nesanitarnoj deponiji',
        PROJEKAT_SANACIJE: 'Projekat sanacije, rekultivacije i zatvaranja nesanitarne deponije - smetlišta',
      },
      DELIMICNO: 'Delimično',
      PROSECNE_GODISNJE_KOLICINE_OTPADA:
        'Prosečne godišnje količine otpada, koji se odlaže na nesanitarnu deponiju - smetlište (t)',

      OGRADA_OKO_NESANITARNE_DEPONIJE: 'Ograda oko nesanitarne deponije - smetlišta',
      KAPIJA_RAMPA_NA_ULAZU: 'Kapija / rampa na ulazu',
      CUVARSKA_SLUZBA: 'Čuvarska služba',
      KOLSKA_VAGA: 'Kolska vaga',
      DRENAZNI_SISTEM_ZA_PRIKUPLJANJE: 'Drenažni sistem za prikupljanje procednih otpadnih voda',
      SISTEM_ZA_PRECISCAVANJE_PROCEDNIH_OTPADNIH_VODA: 'Sistem za prečišćavanje procednih otpadnih voda sa smetlišta',
      SISTEM_ZA_OTPLINJAVANJA_DEPONIJSKOG_GASA: 'Sistem za otplinjavanja deponijskog gasa',

      KOJEM_REGIONU_PRIPADA_OPSTINA: 'Kojem regionu za upravljanje otpadom pripada vaša opština?',
      SA_KOJIM_LOKALNIM_SAMOUPRAVAMA_IMATE_SPORAZUM:
        'Sa kojim lokalnim samoupravama imate potpisan sporazum o zajedničkom regionalnom upravljanju otpadom?',
      NESANITARNA_DEPONIJA_SMETLISTE_JE: 'Nesanitarna deponija - smetlište je:',
      OPERATIVNI_STATUS_NESANITARNE_DEPONIJE: 'Operativni status nesanitarne deponije - smetlišta',
      DA_LI_SE_NA_NESANITARNOJ_DEPONIJI_I_DALJE_ODLAZE_OTPAD:
        'Da li se na nesanitarnoj deponiji - smetlištu i dalje odlaže otpad?',
      NACIN_ODLAGANJA_OTPADA: 'Način odlaganja otpada',
      DA_LI_SE_VRSI_PREKRIVANJE_INERTNIM_MATERIJALOM: 'Da li se vrši prekrivanje internim materijalom?',
      PERIOD_ZA_KOJI_SE_PREDVIDJA_TRAJANJE_SMETLISTA:
        'Period za koji se predviđa trajanje i eksploatacija nesanitarne deponije - smetlišta',
      DA_LI_SE_VRSI_EVIDENCIJA_O_KOLICINAMA_OTPADA:
        'Da li se vrši i vodi evidencija o količinama i vrstama otpada, koji se odlaže?',
      DA_LI_SE_NESANITARNA_NALAZI_U_POPLAVNOM:
        'Da li se nesanitarna deponija - smetlište ili njen deo nalazi u poplavnom području?',

      DA_LI_JE_IZRADJEN_PROJEKAT_SANACIJE_ZATVARANJA:
        'Da li je za nesanitarno smetlište izrađen Projekat sanacije, zatvaranja i rekultivacije?',
      KOJE_GODINE: 'Koje godine?',
      DA_LI_JE_PRIBAVLJENA_SAGLASNOST_PROJEKAT_SANACIJE_ZATVARANJA:
        'Da li je pribavljena saglasnost na Projekat sanacije, zatvaranja i rekultivacije?',
      DA_LI_SE_IZVODE_RADOVI_PO_PROJEKTU_SANACIJE_ZATVARANJA:
        'Da li se izvode radovi po Projektu sanacije, zatvaranja i rekultivacije?',
      KOJI_RADOVI_SE_IZVODE_PO_PROJEKTU_SANACIJE_ZATVARANJA:
        'Koji radovi se izvode po Projektu sanacije, zatvaranja i rekultivacije?',
      DA_LI_SE_SPROVODE_MERE_ZASTITE_ZIVOTNE_SREDINE:
        'Da li se sprovode mere zaštite životne sredine propisane Projektom sanacije, zatvaranja i rekultivacije?',
      DA_LI_SE_SPROVODI_MONITORING:
        'Da li se sprovodi monitoring propisan Projektom sanacije, zatvaranja i rekultivacije?',
      DA_LI_JE_POTREBNA_IZRADA_NOVOG_ILI_AZURIRANJE:
        'Da li je potrebna izrada novog ili ažuriranje postojećeg Projekta sanacije, zatvaranja i rekultivacije nesanitarnog smetlišta?',
      MORA_IMATI_MESTO: 'Tabela mora imati bar jedno mesto',
    },

    GIO2: {
      PODACI_O_DOZVOLI_ZA_UPRAVLJANJE: 'Podaci o dozvoli za upravljanje otpadom',
      DOZVOLA_VAZI_ZA: 'Dozvola važi za:',
      BROJ_DOZVOLE: 'Broj dozvole',
      DATUM_IZDAVANJA: 'Datum izdavanja',
      DATUM_ISTEKA: 'Datum isteka',
      SAKUPLJANJE: 'Sakupljanje',
      TRANSPORT: 'Transport',
      SKLADISTENJE: 'Skladištenje',
      TRETMAN: 'Tretman',
      ODLAGANJE: 'Odlaganje',
      ADRESA_DEPONIJE: 'Adresa deponije',
      MESTO_DEPONIJE: 'Mesto deponije',
      VRSTA_DEPONIJE: 'Vrsta deponije',
      PODACI_O_DEPONIJI: 'Podaci o deponiji',
      VALIDACIJA_CHECKBOX: 'Morate izabrati bar jednu stavku',
      KOLICINA_OTPADA_ZA_ODLAGANJE: 'Količina otpada za odlaganje (oznaka D1) (t)',
      KOLICINA_OTPADA_ZA_SPALJIVANJE: 'Količina otpada za spaljivanje (bez proizvodnje energije) (oznaka D10) (t)',
      POSTUPAK_ODLAGANJA: 'Postupak odlaganja',
      DRUGI_POSTUPAK_ODLAGANJA: 'Drugi postupak odlaganja',
      KOLICINA_PREUZETOG_OTPADA: 'Količina preuzetog otpada i odlaganje preuzetog otpada',
      UKUPNA_KOLICINA_ODLOZENOG: 'Ukupna količina ove vrste otpada odloženog u izveštajnoj godini',
    },

    GIO4: {
      WASTE_EXPORT: 'Izvoz otpada',
      EXPORT_AMOUNT: 'Količina izvezenog otpada (t)',
      EXPORT_LICENSE: 'Broj dozvole za izvoz',
      IMPORT_COUNTRY: 'Zemlja uvoza',
      NAME_ADDRESS: 'Naziv i adresa postrojenja za odlaganje ili tretman otpada',
      WASTE_EXPORT_LISTS: 'Izvoz otpada - liste',
      Y_LIST: 'Y lista',
      C_LIST: 'C lista',
      N_LIST: 'Opasna karakteristika otpada (H lista)',
      SHIPPING_LIST: 'Lista otpada za prekogranični transport',
      IA_LIST: 'IA lista',
      IIB_LIST: 'IIB lista',
      R_LIST: 'Ponovno iskorišćavanje R oznaka',
      D_LIST: 'Odlaganje D oznaka',
      EE_TYPES: 'Vrste elektricnog i elektronskog otpada',
      EE_CLASS: 'Razred EE otpada',
      AMOUNT: 'Količine (t)',
      TRANSIT: 'Zemlje tranzita',
      VALIDATION: {
        WASTE_CATALOGUE_EXISTS: 'Indeks otpada već postoji u listi',
        COUNTRY_EXISTS: 'Zemlja je već u listi',
        WASTE_EXISTS: 'Indeks otpada već postoji u listi',
        TRANSIT: 'Ukoliko se zemlja uvoza ne graniči sa Republikom Srbijom morate navesti zemlje tranzita',
        TRANSIT_SAME: 'Zemlja uvoza ne može biti i zemlja tranzita',
        EE_AMOUNT: 'Zbir količina u tabeli mora biti jednak količini upisanoj u polje Količina izvezenog otpada',
        EE_EXISTS: 'Razred EE otpada je već u listi',
        DODAJTE_IZVOZ: 'Dodajte izvoz otpada',
      },
    },

    GIO5: {
      WASTE_IMPORT: 'Uvoz otpada',
      IMPORT_AMOUNT: 'Količina uvezenog otpada (t)',
      IMPORT_LICENSE: 'Broj dozvole za uvoz',
      EXPORT_COUNTRY: 'Zemlja izvoza',
      NAME_ADDRESS: 'Naziv i adresa preduzeća za čije je potrebe uvezen otpad',
      WASTE_IMPORT_LISTS: 'Uvoz otpada liste',
      VALIDATION: {
        TRANSIT: 'Ukoliko se zemlja izvoza ne graniči sa Republikom Srbijom morate navesti zemlje tranzita',
      },
    },
    OBRAZAC5: {
      TRANSPORT_OTPADA: 'Transport otpada',
      NAZIV_PREVOZNIKA_OTPADA: 'Naziv prevoznika otpada',
      BROJ_DOZVOLE_ZA_PREVOZ_OPASNIH_MATERIJA: 'Broj dozvole za prevoz opasnih materija',
      TIP_VOZILA: 'Tip vozila',
      INDEKSNI_BROJ: 'Indeksni broj otpada iz kataloga otpada',
      KLASIFIKACIJA_OTPADA: 'Klasifikacija otpada',
      MESTO_NASTANKA_OTPADA: 'Mesto nastanka otpada',
      VRSTA_OTPADA: 'Vrsta otpada',
      OPIS_OTPADA: 'Opis otpada',
      KARAKTER_OTPADA: 'Karakter otpada',
      KATEGORIJA_OTPADA_Q_LISTA: 'Kategorija otpada - Q lista',
      IZVESTAJ_O_ISPITIVANJU: 'Izveštaj o ispitivanju otpada',
      BROJ_IZVESTAJA: 'Broj izveštaja',
      DATUM_IZDAVANJA: 'Datum izdavanja',
      OPASAN_OTPAD_PORUKA:
        'Ako je otpad opasan, izabrati oznaku opasne karakteristike otpada (iz H liste) i izabrati oznaku opasnog otpada po poreklu i sastavu (iz Y liste)',
      OZNAKA_OPASNE_KARAKTERISTIKE: 'Oznaka opasne karakteristike otpada',
      KATEGORIJA_PO_POREKLU: 'Kategorija opasnog otpada prema poreklu i sastavu',
      FIZICKO_STANJE_OTPADA: 'Fizičko stanje otpada',
      KOMPONENTE_KOJE_OTPAD_CINE_OPASNIM: 'Komponente koje otpad čine opasnim',
      CAS_BROJ: 'CAS broj',
      HEMIJSKI_NAZIV: 'Hemijski naziv',
      SADRZAJ_OPASNE_MATERIJE: 'Sadržaj opasne materije (kg o.m. / kg otpada)',
      KOLICINA_OTPADA: 'Količina otpada',
      GODISNJA_KOLICINA: 'Godišnja količina proizvedenog otpada',
      STANJE_1_JANUAR: 'Stanje privremenog skladišta na dan 1. januara (t)',
      STANJE_31_DECEMBAR: 'Stanje privremenog skladišta na dan 31. decembra (t)',
      NACIN_ODREDJIVANJA: 'Način određivanja količina otpada',
      KOLICINA_NA_SKLADISTENJE: 'Količina isporučenog otpada na skladištenje (t)',
      KOLICINA_NA_ODLAGANJE: 'Količina isporučenog otpada na odlaganje (t)',
      KOLICINA_NA_PONOVNO_ISKORISCENJE: 'Količina isporučenog otpada na ponovno iskorišćenje (t)',
      KOLICINA_IZVEZENOG_OTPADA: 'Količina izvezenog otpada (t)',
      SKLADISTE_OTPADA: 'Skladište otpada',
      NAZIV_ZA_SKLADISTENJE: 'Naziv postrojenja za skladištenje otpada',
      BROJ_DOZVOLE: 'Broj dozvole',
      D_ILI_R_OZNAKA: 'D ili R oznaka',
      ODLAGANJE_OTPADA: 'Odlaganje otpada',
      PONOVNO_ISKORISCENJE_OTPADA: 'Ponovno iskorišćenje otpada',
      NAZIV_ZA_TRETMAN: 'Naziv postrojenja za tretman otpada',
      NAZIV_ZA_ODLAGANJE: 'Naziv postrojenja za odlaganje otpada',
      NAZIV_ZA_PONOVNO_ISKORISCENJE: 'Naziv postrojenja za ponovno iskorišćenje',
      D_OZNAKA: 'D oznaka',
      R_OZNAKA: 'R oznaka',
      IZVOZ_OTPADA: 'Izvoz otpada',
      ZEMLJA_IZVOZA: 'Zemlja izvoza',
      ADRESA_ZA_TRETMAN: 'Adresa postrojenja za tretman otpada',
      ULICA_ZA_TRETMAN: 'Ulica postrojenja za tretman otpada',
      KUCNI_BROJ_ZA_TRETMAN: 'Kućni broj postrojenja za tretman otpada',
      MESTO_ZA_TRETMAN: 'Mesto postrojenja za tretman otpada',
      POSTANSKI_ZA_TRETMAN: 'Poštanski broj postrojenja za tretman otpada',
      IZABERI_NACIN_UPRAVLJANJA: 'Izaberite način upravljanja otpadom',
      UPOZORENJE_OPASAN_OTPAD_LISTA: 'Ovu listu je potrebno popuniti samo ukoliko je opasan otpad u pitanju',
      VALIDATION: {
        DODATI_KOMPONENTE: 'Potrebno je dodati komponente da bi se sačuvao obrazac',
      },
    },

    DKO: {
      PODACI_O_OTPADU: 'Podaci o otpadu',
      KLASIFIKACIJA_OTPADA: 'Klasifikacija otpada',
      Q_LIST: 'Q lista',
      UN_BROJ_I_KLASA: 'UN broj i klasa',
      MASA_OTPADA: 'Masu otpada uneti izraženu u tonama (t)',
      NACIN_PAKOVANJA_OTPADA: 'Način pakovanja otpada',
      FIZICKO_STANJE_OTPADA: 'Fizičko stanje otpada',
      IZVESTAJ_O_ISPITIVANJU_OTPADA: 'Izveštaj o ispitivanju otpada',
      BROJ_IZVESTAJA: 'Broj izveštaja',
      VID_PREVOZA: 'Vid prevoza',
      ODREDISTE: 'Odredište',
      ADRESA_ODREDISTA: 'Adresa odredišta',
      POSEBNA_NAPOMENA: 'Posebna napomena za rukovanje i dodatne informacije',
      PODACI_O_PROIZVODJACU_VLASNIKU: 'Podaci o proizvođaču / vlasniku otpada',
      PREDUZECE_PROIZVODJAC: 'Preduzeće - proizvođač ili vlasnik otpada',
      PROIZVODJAC_VLASNIK_OTPADA_JE: 'Proizvođač / vlasnik otpada je',
      PREDVIDJENI_NACIN_POSTUPANJA: 'Predviđeni način postupanja',
      DOZVOLA_ZA_UPRAVLJANJE: 'Dozvola za upravljanje otpadom',
      BROJ_DOZVOLE: 'Broj dozvole',
      DATUM_IZDAVANJA_DOZVOLE: 'Datum izdavanja dozvole',
      DATUM_PREDAJE_OTPADA: 'Datum predaje otpada',
      ODGOVORNO_LICE_PROIZVODJAC: 'Odgovorno lice proizvođač / vlasnik otpada',
      IME_PREZIME: 'Ime i prezime',
      BROJ_MOBILNOG_TELEFONA: 'Broj mobilnog telefona',
      PODACI_O_PREVOZNIKU_OTPADA: 'Podaci o prevozniku otpada',
      PREDUZECE_PREVOZNIK: 'Preduzeće - prevoznik otpada',
      TELEFON_PREVOZNIKA: 'Telefon prevoznika otpada',
      TELEFAKS_PREVOZNIKA: 'Telefaks prevoznika otpada',
      EMAIL_PREVOZNIK: 'Email prevoznika otpada',
      VRASTA_PREVOZNOG_SREDSTVA: 'Vrsta prevoznog sredstva',
      REGISTARSKI_BROJ_PREVOZNOG_SREDSTVA: 'Registarski broj prevoznog sredstva',
      RUTA_KRETANJA_OTPADA: 'Ruta kretanja otpada',
      LOKACIJA_UTOVAR: 'Lokacija utovara',
      ADRESA_UTOVAR: 'Adresa utovara',
      PREKO: 'Preko',
      LOKACIJA_ISTOVARA: 'Lokacija istovara',
      ADRESA_ISTOVARA: 'Adresa istovara',
      DATUM_PREUZIMANJA: 'Datum preuzimanja otpada',
      ODGOVORNO_LICE_PREVOZNIKA_PREUZEO: 'Odgovorno lice prevoznika otpada (preuzeo otpad)',
      ODGOVORNO_LICE_PREVOZNIKA_PREDAO: 'Odgovorno lice prevoznika otpada (predao otpad)',
      PODACI_O_PRIMAOCU_OTPADA: 'Podaci o primaocu otpada',
      PREDUZECE_PRIMALAC: 'Preduzeće - primalac otpada',
      TELEFON_PRIMALAC: 'Telefon primaoca otpada',
      TELEFAKS_PRIMALAC: 'Telefaks primaoca otpada',
      EMAIL_PRIMALAC: 'Email primaoca otpada',
      PRIMALAC_OTPADA_JE: 'Primalac otpada je',
      ODGOVORNO_LICE_PRIMALAC: 'Odgovorno lice primalac otpada',
      POTREBNA_POTVRDA: 'Datum predaje otpada je prošao. Potrebno je potvrditi obrazac.',
      POTVRDA_PITANJE: 'Da li ste sigurni da želite da potvrdite obrazac?',
      ODJAVA_PITANJE: 'Da li ste sigurni da želite da odjavite obrazac?',
      MASA_OTPADA_PROVERA: 'Proverite da li je unos mase otpada dobar.',
      Y_LIST_REQUIRED: 'Obavezno je popuniti barem jedno polje Y liste',
      Y_LIST_EXISTS: 'Već postoji ova Y lista selektovana',
      N_LIST_REQUIRED: 'Obavezno je popuniti barem jedno polje N liste',
      N_LIST_EXISTS: 'Već postoji ova N lista selektovana',
      PREKO_REQUIRED: 'Obavezno je uneti barem jedno mesto',
      PREKO_EXISTS: 'Ovo mesto je već odabrano.',
      ODREDISTE_REQUIRED: 'Morate popuniti odredište u delu A',
      POSTROJENJE_SKLADISTE: 'Postrojenje za skladište otpada',
      POSTROJENJE_TRETMAN: 'Postrojenje za tretman otpada',
      POSTROJENJE_ODLAGANJE: 'Postrojenje za odlaganje otpada',
      DRUMSKI: 'Drumski',
      VODENI: 'Vodeni',
      ZELEZNICKI: 'Železnički',
      OPERATER: 'Operater',
      PROIZVODJAC: 'Proizvođač',
      VLASNIK: 'Vlasnik',
    },

    OBRAZAC3: {
      ISPUST_INFO: 'Podaci o ispustu',
      RADNI_REZIM_ISPUSTA: 'Radni režim ispusta',
      VREMENSKI_PERIOD_ISPUSTANJA: 'Vremenski period ispuštanja (dan/god)',
      UKUPNA_KOLICINA_ISPUSTENE: 'Ukupna količina ispuštene otpadne vode u izveštajnoj godini na ispustu (m3/god)',
      VODOVOD_ILI_KANALIZACIJA: 'Da li je vaše JKP preduzeće nadležno za',
      VODOVODNI_SISTEMI: 'Vodovodni sistem',
      KANALIZACIONI_SISTEMI: 'Kanalizacioni sistem',
      GODISNJI_BILANS: 'Godišnji bilans',
      GODISNJI_PITANJE: 'Da li želite da generišete godišnji bilans emisija?',
      GODISNJI_NE: 'Ne, za sada.',
      GODISNJI_DA: 'Da, uneta su sva izvršena merenja u izveštajnoj godini.',
      NAZIV_ZAGADJUJUCE_MATERIJE: 'Naziv zagađujuće materije',
      JEDINICA_MERE: 'Jedinica mere',
      PRE_ISPUSTA: 'Pre ispusta otpadne vode',
      POSLE_ISPUSTA: 'Posle ispusta otpadne vode',
      NACIN_ODREDJIVANJA: 'Način određivanja',
      METOD_ODREDJIVANJA: 'Metod određivanja',
      IZMERENA_VREDNOST: 'Izmerena vrednost (u jedinici mere)',
      EMITOVANE_KOLICINE: 'Emitovane količine (kg/god)',
      EMITOVANE_REDOVNI: 'Emitovane količine pri redovnom radu (kg/god)',
      EMITOVANE_AKCIDENTNE: 'Emitovana količina u akcidentnoj situaciji (kg)',
      EMITOVANE_AKCIDENTNE_GOD: 'Emitovana količina u akcidentnoj situaciji (kg/god)',
      SREDNJA_GODISNJA_IZMERENA: 'Srednja godišnja izmerena vrednost zagađujuće materije u otpadnoj vodi (mg/l)',
      DATUM_MERENJA: 'Datum merenja',
      IDENTIFIKACIONI_BROJ_IZVESTAJA: 'Identifikacioni broj izveštaja',
      NAZIV_LABORATORIJE: 'Naziv stručne laboratorije',
      UKUPNA_POVRSINA_OPSTINA: 'Ukupna teritorija povšine opštine (ha)',
      PROCENAT_UKUPNOG_STANOVNISTVA_VODOVOD: 'Procenat ukupnog stanovništva priključenog na vodovod (%)',
      KOLICINA_PROIZVEDENE_VODE: 'Količina proizvedene vode (m3/god)',
      UKUPNA_KOLICINA_POTROSACIMA: 'Ukupna količina isporučene vode svim potrošačima (m3/god)',
      UKUPNA_KOLICINA_STANOVNISTVU: 'Ukupna količina isporučene vode stanovništvu (m3/god)',
      UKUPNA_KOLICINA_INDUSTRIJI: 'Ukupna količina isporučene vode industriji i drugim institucijama (m3/god)',
      GLAVNI_INDUSTRIJSKI: 'Glavni industrijski potrošači vode',
      NAJVECI_INDUSTRIJSKI: 'Najveći industrijski izvori zagađivanja koji ispuštaju otpadnu vodu u kanalizacionu mrežu',
      PROCENAT_UKUPNOG_STANOVNISTVA_KANALIZACIJA: 'Procenat ukupnog stanovništva priključenog na kanalizaciju (%)',
      DA_LI_SE_VRSE_MERENJA: 'Da li se vrše merenja količina otpadnih voda na kanalizacionom sistemu?',
      NE_VRSE_SE: 'Ne vrše se',
      VOLUMETRIJSKI: 'Volumetrijski',
      MERNOM_OPREMOM: 'Mernom opremom',
      DA_LI_KONTROLA_KVALITETA: 'Da li se vrši kontrola kvaliteta otpadnih voda? (uneti broj puta godišnje)',
      NA_SISTEMU: 'Na sistemu',
      NA_IZLIVU: 'Na izlivu',
      KOLICINA_ISPORUCENE: 'Količina isporučene vode (m3/god)',
      KOLICINA_ISPUSTENE: 'Količina ispuštene vode (m3/god)',
      NAZIV_PREDUZECA: 'Naziv preduzeća',
      BAR_JEDNO_MERENJE: 'Morate uneti barem jedno merenje',
      NACIN_ODREDJIVANJA_ISPUSTENE: 'Način određivanja količine ispuštene vode',
      UKUPAN_KAPACITET_PROIZVODNJE: 'Ukupan kapacitet proizvodnje (t/god)',
      UKUPAN_KAPACITET_PROIZVODNJE_DESCRIPTION:
        'Ukupan kapacitet proizvodnje unosi se u skladu sa Uredbom o graničnim vrednostima emisije zagađujućih materija vode i rokovima za njihovo dostizanje („Sl. Glasnik RS“, br. 67/2011, 48/2012 i 1/2016)',
      VALIDACIJA_OTPADNE_VODE: 'Morate uneti barem jedno merenje u sekciji Analiza Otpadne Vode',
      VALIDACIJA_OTPADNE_VODE_MERENJE:
        'Morate uneti barem jedno merenje i jednu materiju unutar merenja u sekciji Analiza Otpadne Vode',
    },

    GVE: {
      IZVOR_ZAGADJIVANJA: 'Izvor zagađivanja',
      PODACI_O_IZVORU: 'Podaci o izvoru',
      GODISNJA_ISKORISCENOST_KAPACITETA: 'Godišnja iskorišćenost kapaciteta (%)',
      SREDNJA_GODISNJA_TEMPERATURA: 'Srednja godišnja temperatura izlaznih gasova na mernom mestu (℃)',
      SREDNJA_GODISNJA_BRZINA: 'Srednja godišnja brzina izlaznih gasova na mernom mestu (m/s)',
      SREDNJI_GODISNJI_PROTOK: 'Srednji godišnji protok na mernom mestu (Nm3/h)',
      RADNI_REZIM_IZVORA: 'Radni režim izvora',
      PODACI_O_RADU: 'Podaci o radu',
      BROJ_RADNIH_DANA: 'Broj radnih dana izvora godišnje',
      BROJ_RADNIH_SATA: 'Broj radnih sati izvora na dan',
      UKUPAN_BROJ_RADNIH_SATI: 'Ukupan broj radnih sati godišnje',
      RASPODELA_EMISIJA: 'Raspodela godišnjih emisija po sezonama (%)',
      ZIMA: 'Zima (Dec, Jan, Feb)',
      PROLECE: 'Proleće (Mar, Apr, Maj)',
      LETO: 'Leto (Jun, Jul, Avg)',
      JESEN: 'Jesen (Sep, Okt, Nov)',
      PODACI_O_KORISCENIM_GORIVIMA: 'Podaci o korišćenim gorivima',
      GORIVO: 'Gorivo',
      UKUPNA_GODISNJA_POTROSNJA: 'Ukupna godišnja potrošnja (t ili m3)',
      DONJA_TOPLOTNA_MOC_GORIVA: 'Donja toplotna moć goriva (KJ/kg ili KJ/m3)',
      SASTAV_GORIVA: 'Sastav goriva',
      SUMPOR: 'Sumpor',
      AZOT: 'Azot',
      HLOR: 'Hlor',
      NAZIV_DODATNOG_1: 'Naziv dodatnog sadržaja 1',
      DODATNI_1: 'Dodatni sadržaj 1',
      NAZIV_DODATNOG_2: 'Naziv dodatnog sadržaja 2',
      DODATNI_2: 'Dodatni sadržaj 2',
      REDNI_BROJ_MERENJA: 'Redni broj merenja',
      LOKACIJA_MERENJA: 'Lokacija merenja',
      DATUM_MERENJA: 'Datum merenja',
      IDENTIFIKACIONI_BROJ: 'Identifikacioni broj izveštaja',
      NAZIV_STRUCNE_LABO: 'Naziv stručne laboratorije',
      ZAGADJUJUCA_MATERIJA: 'Zagađujuća materija ',
      NAZIV_ZAGADJUJUCE_MATERIJE: 'Naziv zagađujuće materije',
      MASENA_KONCENTRACIJA_ZAGAJUJUCE: 'Masena koncetracija zagađujuće materije',
      IZMERENA_VREDNOST: 'Izmerena vrednost (mg/Nm3)',
      METODA_UTVRDJIVANJA: 'Metoda utvrđivanja',
      GVE_MG_NM: 'GVE (mg/Nm3)',
      PROTOK: 'Protok',
      PROTOK_OTPADNOG_GASA: 'Protok otpadnog gasa na mernom mestu (Nm3/h)',
      MASENI_PROTOK_ZAGADJUJUCE: 'Maseni protok zagađujuće materije',
      MASENI_PROTOK_MATERIJE_G_H: 'Maseni protok zagađujuće materije (g/h)',
      EMITOVANE_U_AKCIDENTNIM: 'Emitovane količine u AKCIDENTNIM situacijama',
      EMITOVANE_KOLICINE: 'Emitovane količine (kg)',
      KONCENTRACIJA_ZAGADJUJUCIH_MATERIJA: 'Koncentracija zagađujućih materija u dimnom gasu',
      SREDNJA_GODISNJA_VREDNOST: 'Srednja godišnja izmerena vrednost (mg/Nm3)',
      NACIN_ODREDJIVANJA: 'Način određivanja',
      EMITOVANE_KOLICINE_NORMALNI_RAD: 'Emitovane količine u toku NORMALNOG rada postrojenja',
      EMITOVANE_G_H: 'Emitovane količine (g/h)',
      EMITOVANE_KG_GOD: 'Emitovane količine (kg/god)',
      GODISNJI_BILANS_EMISIJA: 'Godišnji bilans zagađujućih materija',
      MERENJA: 'Merenja',
      MERENJA_VAZDUH: 'Merenja vazduh',
      IZMERENE_VREDNOSTI: 'Izmerene vrednosti',
      VREDNOST_ZA_PRORACUN_BILANSA: 'Vrednost za proračun bilansa (mg/Nm3)',
      ZBIR_UPOZORENJE: 'Zbir mora biti jednak 100%',
      ISTA_ZAGADJUJUCA_UPOZORENJE: 'Zagađujuća materija već postoji u listi',
      IZMERENE_VREDNOSTI_UPOZORENJE:
        'Ne može se dodati merenje ako nije dodata ni jedna izmerena vrednost zagađujuće materije u okviru merenja',
      MERENJE: 'Merenje',
      MERENJE_NASLOV: 'Merenja emisija zagađujućih materija',
      GODISNJI_NASLOV: 'Godišnji bilans emisija zagađujućih materija',
      REKALKULACIJA_BILANSA: 'Rekalkulacija godišnjeg bilansa izvršava se nakon čuvanja podatka',
      BILANS_GENERISI: 'Generiši godišnji bilans',
      BILANS_PITANJE: 'Da li ste sigurni? Biranjem ove opcije gubite podatke godišnjeg bilansa.',
      VALIDATION: {
        ZBIR_JEDNAK_100: 'Godišnja raspodela po sezonama mora biti 100%',
        MORA_IMATI_GORIVO: 'Lista gorivo mora sadržati bar jedan podatak',
        VERIFIKUJ_MERENJE: 'Izabrani izvor zagađivanja nije verifikovan. Kontaktirajte administratora',
        KREIRAJ_BILANS: 'Morate izabrati opciju da li želite da generišete godišnji bilans',
      },
    },
    // Ž ž Č č Ć ć Š š đ

    DEO_6: {
      // Ponovna upotreba
      KOLICINA_PONOVNO_UPOTREBLJENOG: 'Količina ponovno upotrebljenog otpada (t)',
      PONOVNA_UPOTREBA_ODNOSI_SE_SAMO:
        'Ponovna upotreba odnosi se samo na otpad koji se moze ponovno iskoristiti bez prethodnog tretmana',
      PONOVNA_UPOTREBA_PRIMER:
        'Primer: upotreba ambalaže koja može ponovno da se napuni u iste ili druge svrhe (upotreba staklene ambalaže za domaću zimnicu), ponovno napunjene otpadne toner kasete, upotreba starih novina kao dekorativnih rešenja itd...',

      // Predat otpad
      KOLICINA_PREDATOG_OTPADA: 'Količina predatog otpada (t)',
      NACIN_POSTUPANJA_OTPADOM: 'Način postupanja sa otpadom',
      OTPAD_JE_PREDAT_OPERATERU: 'Otpad je predat operateru koji je',
      BROJ_DOZVOLE_ZA_UPRAVLJANJE: 'Broj dozvole za upravljanjem otpadom',
      NOVE_VRSTE_OTPADA: 'Nove vrste otpada',
      BROJ_DOZVOLE_ZA_PONOVNO_ISKORISCENJE: 'Broj dozvole za ponovno iskorišćenje',
      BROJ_DOZVOLE_ZA_ODLAGANJE: 'Broj dozvole za odlaganje',
      PREDTRETMAN: 'Predtretmanom nastaju nove vrste otpada',
      BROJ_DOZVOLE_UPRAVLJANJE_OTPADOM: 'Broj dozvole za upravljanje otpadom',
      PREDAT_DRUGOM_OPERATERU: 'Predato drugom operateru',
      UKLJUCEN_U_SOPSTVENI_PROCES: 'Uključeno u sopstveni proces ponovnog iskorišćenja',
      ODLOZENO_NA_SOPSTVENOJ_LOKACIJI: 'Odloženo na sopstvenoj lokaciji',
      PREDATO_DRUGOM_POSTROJENJU: 'Predato drugom postrojenju sopstvenog preduzeća',
      IZVEZENO: 'Izvezeno van zemlje',
      //Preuzet
      PRAVNO_LICE_ILI_PREDUZETNIK: 'Pravno lice ili preduzetnik od kog je otpad preuzet',
      OTPAD_JE_PREUZET_OD_KOJE_JE: 'Otpad je preuzet od pravnog lica ili preduzetnika koji je',
      PROIZVODJAC_OTPADA: 'Proizvođač otpada',
      SAKUPLJAC: 'Sakupljač',
      OPERATER_NA_DEPONIJI: 'Operater na deponiji',
      OPERATER_TRETMANA_OTPADA: 'Operater tretmana otpada',
      SKLADISTAR: 'Skladištar',
      UVOZNIK_OTPADA: 'Uvoznik otpada',
      DRUGI_VLASNIK_OTPADA: 'Drugi vlasnik otpada',
      KOLICINA_PREUZETOG: 'Količina preuzetog otpada (t)',
      OTPAD_PREUZET_OD: 'Otpad preuzet od/iz',
      KOLICINA_IZ_DOMACINSTAVA: 'Količina prikupljena iz domaćinstava (t)',
      KOLICINA_IZ_DRUGIH_IZVORA: 'Količina prikupljena iz drugih izvora',
      UKUPNA_KOLICINA: 'Ukupna prikupljena količina',
      FIZICKO_LICE: 'Fizičko lice',
      PRAVNO_LICE: 'Pravnog lice ili preduzetnika',
      DRUGOG_POSTROJENJA: 'Drugog postrojenja sopstvenog preduzeća',
      SOPSTVENI_UVOZ: 'Sopstveni uvoz',

      //Skladiste
      UKUPNA_KOLICINA_OVD_VRSTE_OTPADA: 'Ukupna količina ove vrste otpada na skladištu 1. januara (t)',
      OVAJ_DEO_POPUNJAVA: 'OVAJ DEO SE POPUNJAVA SAMO ZA PREDUZEĆA KOJA IMAJU SKLADIŠTA OTPADA',
      ZATECENO_STANJE_NA_PRIVREMENOM:
        'Zatečeno stanje na privremenom skladištu, popunjava se samo jednom godišnje u dnevnom izveštaju na dan 1.1. tekuće godine sa količinama i vrstama otpada koje se tog dana nalaze na skladištu.',
      DATUM_IZVESTAJA: 'Datum izveštaja',
      RAZREDI_ERROR: 'Razred EE već postoji u listi',
      INDEX_ERROR: 'Indeksni broj već postoji u listi',
    },

    VALIDATION: {
      DODAJTE_DOZVOLU_ZA_UPRAVLJANJE: 'Dodajte dozvolu za upravljanje',
      DODATI_DEPONIJU_ZA_UPRAVLJANJE: 'Dodajte deponiju za upravljanje',
      DODATI_KOLICINU: 'Dodajte količinu',
      DODATI_POSTROJENJE: 'Dodajte postrojenje',
      POPUNITI_SVA_OBAVEZNA_POLJA: 'Potrebno je da popunite sva obavezna polja',
      DODATI_JEDAN_PZV_PROIZVOD: 'Dodati barem jedan od proizvoda',
      VECA_ILI_JEDNAKA_NOVE_VRSTE: 'Mora biti veća ili jednaka zbiru svih količina iz tabele nove vrste',
      JEDNAKA_RAZRED: 'Mora biti jednaka zbiru svih količina iz tabele vrste EE',
      JEDNAKA_RAZRED_UKUPNA: 'Mora biti jednaka zbiru svih ukupnih količina iz tabele',
      DODATI_KOLICINU_NA_TAB: 'Dodati količinu na jednom od tabova',
    },
  },
  // Ž ž Č č Ć ć Š š đ

  WIZARD_TABS: {
    OPSTE: 'Opšte',
    PROIZVODI: 'Proizvodi',
    NAPOMENA: 'Napomena',
    DIVLJE_DEPONIJE: 'Divlje deponije',
    DETERDZENTI: 'Deterdženti',
    DJUBRIVA: 'Mineralna đubriva',
    ZASTITA_BILJA: 'Zaštita bilja',
    NAPOMENA_ADMINISTRATORA: 'Napomena administratora',
    DODATNI_PODACI: 'Dodatni podaci',
    EMISIJE_U_TLO: 'Emisije u tlo',
    EMISIJE_U_VAZDUH: 'Emisije u vazduh',
    GORIVA: 'Goriva',
    SIROVINE: 'Sirovine',
    QUANTITY: 'Količine',
    NESANITARNA_DEPONIJA: 'Nesanitarna deponija',
    KOLICINA_AMBALAZE: 'Količina ambalaže',
    UPRAVLJANJE_AMBALAZNIM_OTPADOM: 'Upravljanje ambalažnim otpadom',
    PRAVNO_LICE_ILI_PREDUZETNIK: 'Pravno lice ili preduzetnik',
    DOZVOLE: 'Dozvole',
    DEPONIJE: 'Deponije',
    KLASIFIKACIJE: 'Klasifikacija',
    KOMPONENTE: 'Komponente',
    UPRAVLJANJE: 'Upravljanje',
    IZVOZ_OTPADA: 'Izvoz otpada',
    UVOZ_OTPADA: 'Uvoz otpada',
    DEO_A: 'Deo A',
    DEO_B: 'Deo B',
    DEO_C: 'Deo C',
    DEO_D: 'Deo D',
    ANALIZA_OTPADNE_VODE: 'Analiza otpadne vode',
    ANALIZA_RECIPIJENATA: 'Analiza recipijenata',
    VODOVODNI_SISTEMI: 'Vodovodni sistemi',
    KANALIZACIONI_SISTEMI: 'Kanalizacioni sistemi',
    STANJE_NA_SKLADISTU: 'Stanje na skladištu 1. januara',
    PREUZET_OTPAD: 'Preuzet otpad',
    PREDAT_OTPAD: 'Predat otpad',
    PONOVNA_UPOTREBA_OTPADA: 'Ponovna upotreba otpada',
    UKUPNA_KOLICINA_PO_AKTIVNOSTIMA: 'Ukupna količina po aktivnostima',
  },

  OBRAZAC_IZVESTAJI: obrazacIzvestajiTranslations,

  ZAKLJUCAVANJE_OBRAZACA: zakljucavanjeObrazacaTranslations,
};

export default translations;
