import { pzv1Repo } from './pzv1.repo';
import { Pzv1Korekcije } from './pzv1.types';

class Pzv1Service {
  submitKorekcije = async (payload: Pzv1Korekcije, id: string) => {
    return pzv1Repo.submitKorekcije(payload, id);
  };
}

export const pzv1Service = new Pzv1Service();
