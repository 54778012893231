import { Col, Row, Tooltip } from 'antd';
import { observer } from 'mobx-react-lite';
import { zakljucavanjeStore } from 'modules/zakljucavanje/zakljucavanje.store';
import { useEffect } from 'react';
import { ZakljucavanjePoGodiniObrazacRow } from '../ZakljucavanjePoGodiniObrazacRow/ZakljucavanjePoGodiniObrazacRow';
import styles from './ZakljucavanjePoGodiniObrasci.module.scss';
import { Button } from 'components/Button/Button';
import { OTKLJUCANI_OBRASCI } from 'modules/obrasci/obrasci.types';

export const ZakljucavanjePoGodiniObrasci = observer(() => {
  useEffect(() => {
    zakljucavanjeStore.fetchTipoviObrazacaPoGodini();
  }, [zakljucavanjeStore.selectedGodina]);

  const tipoviObrazacaComponentMap = zakljucavanjeStore.tipoviObrazacaPoGodini.length
    ? zakljucavanjeStore.tipoviObrazacaPoGodini.map(tip => {
        return <ZakljucavanjePoGodiniObrazacRow godinaTipObrasca={tip} key={tip.tip_obrasca_id} />;
      })
    : null;

  //todo: sredi stil
  const zakljucavanjeGodineTooltipTitle = (
    <div className={styles.zakljucavanjeGodineTooltip}>
      <h3 style={{ margin: 0 }}>Ovom akcijom se ne zaključavaju sledeći obrasci:</h3>
      <ul style={{ margin: 0 }}>
        {OTKLJUCANI_OBRASCI.map(tip => (
          <li key={tip}>
            <b>{`${tip}`}</b>
          </li>
        ))}
      </ul>
    </div>
  );

  return (
    <div className={styles.zakljucavanjeGodinaObrasciWrapper}>
      <Row className={styles.zakljucavanjeGodinaObrasciHeader}>
        <Col span={18}>
          <h2>Obrazac</h2>
        </Col>
        <Col span={6}>
          <Tooltip
            title={zakljucavanjeGodineTooltipTitle}
            arrow={{ pointAtCenter: true }}
            placement="left"
            mouseEnterDelay={0.2}
            color="white"
          >
            <Button
              name="zakljucaj-godinu"
              disabled={!zakljucavanjeStore.selectedGodina}
              onClick={() => zakljucavanjeStore.toggleLockTipObrasca(true)}
            >
              Zaključaj sve obrasce za godinu
            </Button>
          </Tooltip>
        </Col>
      </Row>
      {tipoviObrazacaComponentMap}
    </div>
  );
});
