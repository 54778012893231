import { IBaseObrazacStore, IPaginatedResponse, IPagination } from 'typescript/NrizTypes';
import {
  IPostGio4Dozvole,
  IGetDozvoleGio4,
  IGetGio4,
  IPostGio4Obrazac,
  IPostGio4IzvozOtpada,
  IGetIzvozOtpadaGio4,
  IPostGio4RazrediEeOtpada,
  IGio4Country,
  IPostGio4Country,
  IGio4RazrediEeOtpada,
} from './gio4.types';
import {
  GIO_4_IZVOZ_OTPADA_INITIAL_STATE,
  GIO_4_DOZVOLE_INITIAL_STATE,
  GIO_4_INITIAL_STATE,
  GIO_4_RAZREDI_EE_OTPADA_INITIAL_STATE,
  GIO_4_ZEMLJA_TRANZITA_INITIAL_STATE,
} from './gio4.constants';
import { makeAutoObservable } from 'mobx';
import { obrasciService } from 'modules/obrasci/obrasci.service';
import { sifarniciStore } from 'modules/sifarnici/sifarnici.store';
import { SIFARNICI_ROUTES } from 'modules/sifarnici/sifarnici.types';
import { nrizService } from 'modules/nriz/nriz.service';
import { formStore } from 'store';
import { obrasciStore } from 'modules/obrasci/obrasci.store';
import { TIPOVI_OBRAZACA } from 'modules/obrasci/obrasci.types';
import to from 'await-to-js';
import dayjs from 'dayjs';
import { sifarniciRepo } from 'modules/sifarnici/sifarnici.repo';
import { arrayToObject } from 'utils/arrayToObject';
import { handleSuccessNotify } from 'utils/succesHandling';
import { IGetCList } from 'modules/sifarnici/store/cList/cList.types';
import { IGetCountryCode } from 'modules/sifarnici/store/countryCode/countryCode.types';
import { IGetDRList } from 'modules/sifarnici/store/dRList/dRList.types';
import { IGetNList } from 'modules/sifarnici/store/nList/nList.types';
import { IGetWasteCatalogue } from 'modules/sifarnici/store/wasteCatalogue/wasteCatalogue.types';
import { IGetWeeeCategories } from 'modules/sifarnici/store/weeeCategories/weeeCategories.types';
import { IGetYList } from 'modules/sifarnici/store/yList/yList.types';
import { IGetIaList } from 'modules/sifarnici/store/iaList/iaList.types';
import { IGetIibList } from 'modules/sifarnici/store/iibList/iibList.types';

class Gio4ObrazacStore implements IBaseObrazacStore<IGetGio4, IPostGio4Obrazac> {
  initialState: IGetGio4 | IPostGio4Obrazac = GIO_4_INITIAL_STATE;
  dozvoleInitial: IGetDozvoleGio4 | IPostGio4Dozvole = GIO_4_DOZVOLE_INITIAL_STATE;
  izvozOtpada: IGetIzvozOtpadaGio4 | IPostGio4IzvozOtpada = GIO_4_IZVOZ_OTPADA_INITIAL_STATE;
  razredi_ee_form: IGio4RazrediEeOtpada | IPostGio4RazrediEeOtpada = GIO_4_RAZREDI_EE_OTPADA_INITIAL_STATE;
  zemljaTranzita: IGio4Country | IPostGio4Country = GIO_4_ZEMLJA_TRANZITA_INITIAL_STATE;
  index_id = '';
  dozvoleNizValidacija: string[] = [];
  imaTranzita = false;
  zemljaIzvoza: string | undefined = undefined;

  constructor() {
    makeAutoObservable(this);
  }

  get getImaTranzita() {
    return this.imaTranzita;
  }

  get getZemljaTranzita() {
    return this.zemljaTranzita;
  }

  get getRazrediEeForm() {
    return this.razredi_ee_form;
  }

  get getDozvoleInitial() {
    return this.dozvoleInitial;
  }

  get getIzvozOtpada() {
    return this.izvozOtpada;
  }

  get getInitialState() {
    return this.initialState;
  }

  handleChange(key: keyof Gio4ObrazacStore, value: any) {
    (this as any)[key] = value;
  }

  handleIzvozOtpada(key: string, value: any) {
    (this as any).izvozOtpada[key] = value;
  }

  handleInitialStateChange(key: string, value: any) {
    (this as any).initialState[key] = value;
  }

  resetMicro = () => {
    this.handleChange('imaTranzita', false);
    this.handleChange('izvozOtpada', GIO_4_IZVOZ_OTPADA_INITIAL_STATE);
  };

  resetStates = () => {
    this.handleChange('initialState', GIO_4_INITIAL_STATE);
  };

  loadData = async (id: string) => {
    const [err, res] = await to(obrasciService.fetchSingleObrazac<IGetGio4>(TIPOVI_OBRAZACA.GIO4, id));
    if (err) return;
    const newGodina = dayjs().year((res.godina as any) * 1);
    const newDozvole = this.makeNewDozvole((res as IGetGio4).dozvole);
    this.handleChange('initialState', { ...res, godina: newGodina, dozvole: newDozvole });
    return res;
  };

  setData?: ((res: IGetGio4) => void) | undefined;
  async postData(payload: IPostGio4Obrazac) {
    const [err, res] = await to(
      obrasciService.postSingleObrazac<IPostGio4Obrazac>(
        TIPOVI_OBRAZACA.GIO4,
        nrizService.setEmptyValuesToNull(payload)
      )
    );
    if (err || !res) return;
    formStore.handleChange('goBack', true);
    handleSuccessNotify();
  }

  putData = async (payload: any) => {
    const [err, res] = await to(
      obrasciService.putSingleObrazac<IPostGio4Obrazac>(TIPOVI_OBRAZACA.GIO4, payload.id, payload)
    );
    if (err || !res) return;
    formStore.handleChange('goBack', true);
    handleSuccessNotify();
  };

  async deleteData(id: string) {
    const [err, res] = await to(obrasciService.deleteSingleObrazac(TIPOVI_OBRAZACA.GIO4, id));
    if (err || !res) return;
    formStore.handleChange('goBack', true);
    handleSuccessNotify();
  }

  submitData = (payload: IPostGio4Obrazac) => {
    const newInitialState = {
      id: (this.initialState as IGetGio4).id,
      ...GIO_4_INITIAL_STATE,
      ...payload,
      godina: obrasciStore.opste.godina,
      dozvole: this.initialState.dozvole,
      izvoz_otpada: this.initialState.izvoz_otpada,
    } as IGetGio4;

    obrasciService.submitFormObrazac({
      payload: newInitialState,
      postForm: this.postData,
      putForm: this.putData,
      deleteForm: this.deleteData,
    });
  };

  // SIFARNICI

  fetchCountryOptions = async (
    pagination: IPagination,
    search: string
  ): Promise<IPaginatedResponse<IGetCountryCode>> => {
    const response = await sifarniciStore.fetchSifarnikOptions<IGetCountryCode>(SIFARNICI_ROUTES.COUNTRY_CODE, {
      page: pagination.page,
      size: pagination.size,
      search: search,
      code__neq: 'RS',
      order_by: '+name',
    });
    return response;
  };

  fetchGranica = async (id: string) => {
    this.handleIzvozOtpada('zemlje_tranzita', []);
    if (!id) {
      this.handleChange('imaTranzita', '');
      return;
    }
    const response = await sifarniciRepo.fetchIsSifarnikTrue(SIFARNICI_ROUTES.COUNTRY_CODE, id);
    //@ts-ignore
    this.handleChange('imaTranzita', !response.granici_se_srbija);
  };

  fetchWeeeCategoriesList = async (
    pagination: IPagination,
    search: string
  ): Promise<IPaginatedResponse<IGetWeeeCategories>> => {
    const response = await sifarniciStore.fetchSifarnikOptions<IGetWeeeCategories>(SIFARNICI_ROUTES.WEEE_CATEGORIES, {
      page: pagination.page,
      size: pagination.size,
      search: search,
      waste_catalogue_id: this.index_id,
      order_by: '+the_order',
    });
    return response;
  };

  fetchYListOptions = async (pagination: IPagination, search: string): Promise<IPaginatedResponse<IGetYList>> => {
    const response = await sifarniciStore.fetchSifarnikOptions<IGetYList>(SIFARNICI_ROUTES.Y_LIST, {
      page: pagination.page,
      size: pagination.size,
      search: search,
      v2: true,
      order_by: '+name',
    });
    return response;
  };

  fetchCListOptions = async (pagination: IPagination, search: string): Promise<IPaginatedResponse<IGetCList>> => {
    const response = await sifarniciStore.fetchSifarnikOptions<IGetCList>(SIFARNICI_ROUTES.C_LIST, {
      page: pagination.page,
      size: pagination.size,
      search: search,
      order_by: '+name',
    });
    return response;
  };

  fetchNListOptions = async (pagination: IPagination, search: string): Promise<IPaginatedResponse<IGetNList>> => {
    const response = await sifarniciStore.fetchSifarnikOptions<IGetNList>(SIFARNICI_ROUTES.N_LIST, {
      page: pagination.page,
      size: pagination.size,
      search: search,
      order_by: '+name',
    });
    return response;
  };

  fetchIaListOptions = async (pagination: IPagination, search: string): Promise<IPaginatedResponse<IGetIaList>> => {
    const response = await sifarniciStore.fetchSifarnikOptions<IGetIaList>(SIFARNICI_ROUTES.IA_LIST, {
      page: pagination.page,
      size: pagination.size,
      search: search,
      approved: true,
      order_by: '+name',
    });
    return response;
  };

  fetchIibListOptions = async (pagination: IPagination, search: string): Promise<IPaginatedResponse<IGetIibList>> => {
    const response = await sifarniciStore.fetchSifarnikOptions<IGetIibList>(SIFARNICI_ROUTES.IIB_LIST, {
      page: pagination.page,
      size: pagination.size,
      search: search,
      approved: true,
      order_by: '+name',
    });
    return response;
  };

  fetchWasteCatalogueList = async (
    pagination: IPagination,
    search: string
  ): Promise<IPaginatedResponse<IGetWasteCatalogue>> => {
    const response = await sifarniciStore.fetchSifarnikOptions<IGetWasteCatalogue>(SIFARNICI_ROUTES.WASTE_CATALOGUE, {
      page: pagination.page,
      size: pagination.size,
      search: search,
      level: 3,
      order_by: '+index_number',
    });
    return response;
  };

  fetchDOptions = async (pagination: IPagination, search: string): Promise<IPaginatedResponse<IGetDRList>> => {
    const response = await sifarniciStore.fetchSifarnikOptions<IGetDRList>(SIFARNICI_ROUTES.DR_LIST, {
      search: search,
      name__ilike: 'D',
      order_by: '+name',
    });
    return response;
  };
  fetchROptions = async (pagination: IPagination, search: string): Promise<IPaginatedResponse<IGetDRList>> => {
    const response = await sifarniciStore.fetchSifarnikOptions<IGetDRList>(SIFARNICI_ROUTES.DR_LIST, {
      search: search,
      name__ilike: 'R',
      order_by: '+name',
    });
    return response;
  };

  /// DOZVOLE

  promeniDozvoleNizValidacijaListu = (type: string, value: boolean) => {
    const indexOfType = this.dozvoleNizValidacija.indexOf(type);

    if (value === true && indexOfType === -1) {
      this.dozvoleNizValidacija.push(type);
    } else if (value === false && indexOfType !== -1) {
      this.dozvoleNizValidacija.splice(indexOfType, 1);
    }
  };

  submitDozvoleForm = (payload: IPostGio4Dozvole) => {
    if (!this.dozvoleNizValidacija.length) return;
    let newPayload = payload;
    newPayload = { id: (this.dozvoleInitial as IGetDozvoleGio4).id, ...newPayload } as IGetDozvoleGio4;

    const newList = obrasciService.submitMicroServiceForm(this.initialState.dozvole, newPayload);
    // handleSuccessNotify();
    this.handleInitialStateChange('dozvole', newList);
    this.handleChange('dozvoleInitial', GIO_4_DOZVOLE_INITIAL_STATE);
  };

  makeNewDozvole = (items: IPostGio4Dozvole[]) => {
    const newDozvoleArray = items.map((item: IPostGio4Dozvole) => {
      return {
        ...item,
        datum_isteka: dayjs(item.datum_isteka),
        datum_izdavanja: dayjs(item.datum_izdavanja),
      };
    });
    return newDozvoleArray;
  };

  // IZVOZ

  submitRazredEeOtpada = (payload: IPostGio4RazrediEeOtpada) => {
    let newPayload = payload;
    let sifarnikRazredEeOtapada = {};

    if (formStore.getCrudButtonType === 'submit' || formStore.getCrudButtonType === 'change') {
      if (payload.razred_ee_otpada_id) {
        sifarnikRazredEeOtapada = nrizService.addSifarnikInObject({
          exId: this.razredi_ee_form.razred_ee_otpada_id,
          exObj: (this.razredi_ee_form as IGio4RazrediEeOtpada).razred_ee_otpada,
          list: sifarniciStore.list[SIFARNICI_ROUTES.WEEE_CATEGORIES],
          name: 'razred_ee_otpada',
          id: payload.razred_ee_otpada_id,
        });
      } else {
        newPayload = { ...newPayload, razred_ee_otpada: {} } as IGio4RazrediEeOtpada;
      }
    }
    newPayload = {
      id: (this.razredi_ee_form as IGio4RazrediEeOtpada).id,
      ...newPayload,
      ...sifarnikRazredEeOtapada,
    } as IGio4RazrediEeOtpada;
    const newList = obrasciService.submitMicroServiceForm(
      this.izvozOtpada.razredi_ee_otpada,
      newPayload,
      true,
      'gio4-razredi-ee-modal'
    );
    // handleSuccessNotify();
    this.handleIzvozOtpada('razredi_ee_otpada', newList);
    this.handleChange('razredi_ee_form', GIO_4_RAZREDI_EE_OTPADA_INITIAL_STATE);
  };

  submitZemljeTranzita = (payload: IPostGio4Country) => {
    let newPayload = payload;
    let sifarnikCountryCode = {};

    if (formStore.getCrudButtonType === 'submit' || formStore.getCrudButtonType === 'change') {
      if (newPayload.zemlja_tranzita_id) {
        sifarnikCountryCode = nrizService.addSifarnikInObject({
          exId: (this.zemljaTranzita as IGio4Country).zemlja_tranzita_id,
          exObj: (this.zemljaTranzita as IGio4Country).zemlja_tranzita,
          list: sifarniciStore.list[SIFARNICI_ROUTES.COUNTRY_CODE],
          name: 'zemlja_tranzita',
          id: payload.zemlja_tranzita_id,
        });
      } else {
        newPayload = { ...newPayload, zemlja_tranzita: {} } as IGio4Country;
      }
    }
    newPayload = {
      id: (this.zemljaTranzita as IGio4Country).id,
      ...newPayload,
      ...sifarnikCountryCode,
    } as IGio4Country;
    const newList = obrasciService.submitMicroServiceForm(
      this.izvozOtpada.zemlje_tranzita,
      newPayload,
      true,
      'gio4-zemlje-tranzita-modal'
    );
    // handleSuccessNotify();
    this.handleIzvozOtpada('zemlje_tranzita', newList);
    this.handleChange('zemljaTranzita', GIO_4_ZEMLJA_TRANZITA_INITIAL_STATE);
  };

  submitIzvozOtpada = (payload: IPostGio4IzvozOtpada) => {
    if (this.imaTranzita && !this.izvozOtpada.zemlje_tranzita.length) return;

    let newPayload = {
      ...payload,
      zemlje_tranzita: this.izvozOtpada.zemlje_tranzita,
      razredi_ee_otpada: this.izvozOtpada.razredi_ee_otpada,
    };
    let sifarnikIndeksOtpada = {};
    let sifarnikZemljaIzvoza = {};
    let sifarnikYLista = {};
    let sifarnikYLista2 = {};
    let sifarnikYLista3 = {};
    let sifarnikCLista = {};
    let sifarnikNLista = {};
    let sifarnikNLista2 = {};
    let sifarnikNLista3 = {};
    let sifarnikIaLista = {};
    let sifarnikIibLista = {};
    let sifarnikRoznaka = {};
    let sifarnikDoznaka = {};

    if (formStore.getCrudButtonType === 'submit' || formStore.getCrudButtonType === 'change') {
      sifarnikIndeksOtpada = nrizService.addSifarnikInObject({
        exId: this.izvozOtpada.indeksni_broj_otpada_id,
        exObj: (this.izvozOtpada as IGetIzvozOtpadaGio4).indeksni_broj_otpada,
        list: sifarniciStore.list.WASTE_CATALOGUE,
        name: 'indeksni_broj_otpada',
        id: payload.indeksni_broj_otpada_id,
      });

      sifarnikZemljaIzvoza = nrizService.addSifarnikInObject({
        exId: this.izvozOtpada.zemlja_izvoza_id,
        exObj: (this.izvozOtpada as IGetIzvozOtpadaGio4).zemlja_izvoza,
        list: sifarniciStore.list.COUNTRY_CODE,
        name: 'zemlja_izvoza',
        id: payload.zemlja_izvoza_id,
      });

      sifarnikYLista = nrizService.addSifarnikInObject({
        exId: this.izvozOtpada.izvoz_otpada_y_lista_id,
        exObj: (this.izvozOtpada as IGetIzvozOtpadaGio4).izvoz_otpada_y_lista,
        list: sifarniciStore.list.Y_LIST,
        name: 'izvoz_otpada_y_lista',
        id: payload.izvoz_otpada_y_lista_id,
      });

      sifarnikYLista2 = nrizService.addSifarnikInObject({
        exId: this.izvozOtpada.izvoz_otpada_y_lista2_id,
        exObj: (this.izvozOtpada as IGetIzvozOtpadaGio4).izvoz_otpada_y_lista2,
        list: sifarniciStore.list.Y_LIST,
        name: 'izvoz_otpada_y_lista2',
        id: payload.izvoz_otpada_y_lista2_id,
      });

      sifarnikYLista3 = nrizService.addSifarnikInObject({
        exId: this.izvozOtpada.izvoz_otpada_y_lista3_id,
        exObj: (this.izvozOtpada as IGetIzvozOtpadaGio4).izvoz_otpada_y_lista3,
        list: sifarniciStore.list.Y_LIST,
        name: 'izvoz_otpada_y_lista3',
        id: payload.izvoz_otpada_y_lista3_id,
      });

      sifarnikCLista = nrizService.addSifarnikInObject({
        exId: this.izvozOtpada.izvoz_otpada_c_lista_id,
        exObj: (this.izvozOtpada as IGetIzvozOtpadaGio4).izvoz_otpada_c_lista,
        list: sifarniciStore.list.C_LIST,
        name: 'izvoz_otpada_c_lista',
        id: payload.izvoz_otpada_c_lista_id,
      });

      sifarnikNLista = nrizService.addSifarnikInObject({
        exId: this.izvozOtpada.izvoz_otpada_n_lista_id,
        exObj: (this.izvozOtpada as IGetIzvozOtpadaGio4).izvoz_otpada_n_lista,
        list: sifarniciStore.list.N_LIST,
        name: 'izvoz_otpada_n_lista',
        id: payload.izvoz_otpada_n_lista_id,
      });

      sifarnikNLista2 = nrizService.addSifarnikInObject({
        exId: this.izvozOtpada.izvoz_otpada_n_lista2_id,
        exObj: (this.izvozOtpada as IGetIzvozOtpadaGio4).izvoz_otpada_n_lista2,
        list: sifarniciStore.list.N_LIST,
        name: 'izvoz_otpada_n_lista2',
        id: payload.izvoz_otpada_n_lista2_id,
      });

      sifarnikNLista3 = nrizService.addSifarnikInObject({
        exId: this.izvozOtpada.izvoz_otpada_n_lista3_id,
        exObj: (this.izvozOtpada as IGetIzvozOtpadaGio4).izvoz_otpada_n_lista3,
        list: sifarniciStore.list.N_LIST,
        name: 'izvoz_otpada_n_lista3',
        id: payload.izvoz_otpada_n_lista3_id,
      });

      sifarnikIaLista = nrizService.addSifarnikInObject({
        exId: this.izvozOtpada.ia_lista_id,
        exObj: (this.izvozOtpada as IGetIzvozOtpadaGio4).ia_lista,
        list: sifarniciStore.list.IA_LIST,
        name: 'ia_lista',
        id: payload.ia_lista_id,
      });

      sifarnikIibLista = nrizService.addSifarnikInObject({
        exId: this.izvozOtpada.iib_lista_id,
        exObj: (this.izvozOtpada as IGetIzvozOtpadaGio4).iib_lista,
        list: sifarniciStore.list.IIB_LIST,
        name: 'iib_lista',
        id: payload.iib_lista_id,
      });

      sifarnikRoznaka = nrizService.addSifarnikInObject({
        exId: this.izvozOtpada.ponovno_iskoriscenje_r_oznaka_id,
        exObj: (this.izvozOtpada as IGetIzvozOtpadaGio4).ponovno_iskoriscenje_r_oznaka,
        list: sifarniciStore.list.DR_LIST,
        name: 'ponovno_iskoriscenje_r_oznaka',
        id: payload.ponovno_iskoriscenje_r_oznaka_id,
      });

      sifarnikDoznaka = nrizService.addSifarnikInObject({
        exId: this.izvozOtpada.odlaganje_d_oznaka_id,
        exObj: (this.izvozOtpada as IGetIzvozOtpadaGio4).odlaganje_d_oznaka,
        list: sifarniciStore.list.DR_LIST,
        name: 'odlaganje_d_oznaka',
        id: payload.odlaganje_d_oznaka_id,
      });
    }

    newPayload = {
      id: (this.izvozOtpada as IGetIzvozOtpadaGio4).id,
      ...newPayload,
      ...sifarnikIndeksOtpada,
      ...sifarnikZemljaIzvoza,
      ...sifarnikYLista,
      ...sifarnikYLista2,
      ...sifarnikYLista3,
      ...sifarnikCLista,
      ...sifarnikNLista,
      ...sifarnikNLista2,
      ...sifarnikNLista3,
      ...sifarnikIaLista,
      ...sifarnikIibLista,
      ...sifarnikRoznaka,
      ...sifarnikDoznaka,
      zemlje_tranzita: this.izvozOtpada.zemlje_tranzita,
      razredi_ee_otpada: this.izvozOtpada.razredi_ee_otpada,
    } as IGetIzvozOtpadaGio4;

    const newList = obrasciService.submitMicroServiceForm(this.initialState.izvoz_otpada, newPayload);
    // handleSuccessNotify();
    this.handleInitialStateChange('izvoz_otpada', newList);
    this.handleChange('izvozOtpada', GIO_4_IZVOZ_OTPADA_INITIAL_STATE);
    this.resetMicro();
  };

  indexMap = (id: string) => {
    const obj = arrayToObject(sifarniciStore.list.WASTE_CATALOGUE);
    const findItem = obj[id] as IGetWasteCatalogue;
    return findItem;
  };
}

export const gio4ObrazacStore = new Gio4ObrazacStore();
