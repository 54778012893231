import { ApiResponse, axios } from 'modules/axios';
import { ROUTES } from 'modules/nriz/nriz.constants';

const submitDozvoljeneKorekcije = (payload: any, id: string): ApiResponse<string> => {
  return axios.put(`${ROUTES.OBRAZAC}/ptp2/${id}/dozvoljene-korekcije`, payload);
};

export const ptp2Repo = {
  submitDozvoljeneKorekcije,
};
