import { FormInput } from 'components/FormInput/FormInput';
import { FormInputNumber } from 'components/FormInputNumber/FormInputNumber';
import { FormSearchInfiniteScroll } from 'components/FormSearchInfiniteScroll/FormSearchInfiniteScroll';
import { ICrudTypeModalProps } from 'components/Modals/CRUDModal/CRUDModal';
import { t } from 'i18next';
import { observer } from 'mobx-react-lite';
import { MORE_THEN_0, REQUIRED_FIELD_RULE } from 'modules/nriz/nriz.constants';
import { obrasciStore } from 'modules/obrasci/obrasci.store';
import { kom1ObrazacStore } from 'modules/obrasci/store/kom1/kom1.store';
import { IGetPredato } from 'modules/obrasci/store/kom1/kom1.types';
import { ISifarnikInitial } from 'modules/sifarnici/sifarnici.types';
import React, { useEffect, useState } from 'react';
import { drListOptions } from 'utils/optionMakers';
import { resetFormFields } from 'utils/resetFormFieldValues';

export const NewPredatoKom1Form: React.FC<ICrudTypeModalProps> = observer(props => {
  const [kolicina, setKolicina] = useState(kom1ObrazacStore.getPredatoForm.kolicina);

  const defaultWayOfHandOver: ISifarnikInitial | undefined = (kom1ObrazacStore.getPredatoForm as IGetPredato)
    .way_of_hand_over_id
    ? {
        label: (kom1ObrazacStore.predato_form as IGetPredato).way_of_hand_over.name_sr,
        value: (kom1ObrazacStore.predato_form as IGetPredato).way_of_hand_over_id,
      }
    : undefined;

  const defaultDrList: ISifarnikInitial | undefined = kom1ObrazacStore.getPredatoForm.dr_list_id
    ? {
        value: kom1ObrazacStore.getPredatoForm.dr_list_id,
        label: `${(kom1ObrazacStore.predato_form as IGetPredato).dr_list.name} ${
          (kom1ObrazacStore.predato_form as IGetPredato).dr_list.description_sr
        }`,
      }
    : undefined;

  useEffect(() => {
    if (!kolicina) {
      resetFormFields(props.form, ['dr_list_id']);
    }
  }, [kolicina]);

  const readOnly = obrasciStore.zakljucanObrazac;

  return (
    <div>
      <div style={{ fontWeight: 'bold' }}>{t('OBRASCI.KOM_1.MANAGEMENT.HANDED_OVER')}</div>
      <FormSearchInfiniteScroll
        showSearch={true}
        formName={'way_of_hand_over_id'}
        fetchOptinsList={kom1ObrazacStore.fetchWayOfHandOver}
        labelOptionAccessor="name_sr"
        valueOptionAccessor="id"
        inputName={'way_of_hand_over_id'}
        label={t('OBRASCI.KOM_1.MANAGEMENT.WAY_OF_HAND_OVER')}
        rules={[REQUIRED_FIELD_RULE(true)]}
        colon={true}
        defaultOption={defaultWayOfHandOver}
        placeholder={''}
        readOnly={readOnly}
      />
      <FormInputNumber
        name="kolicina"
        type="number"
        label={t('OBRASCI.KOM_1.QUANTITY_T')}
        rules={MORE_THEN_0}
        placeholder={''}
        inputName={'kolicina'}
        colon={true}
        onInputChange={e => setKolicina(e)}
        readOnly={readOnly}
      />
      {!!kolicina && kolicina > 0 && (
        <FormSearchInfiniteScroll
          showSearch={true}
          formName={'dr_list_id'}
          fetchOptinsList={kom1ObrazacStore.fetchDrList}
          labelOptionAccessor="name"
          valueOptionAccessor="id"
          inputName={'dr_list_id'}
          label={t('OBRASCI.KOM_1.MANAGEMENT.D_OR_R_MARK')}
          rules={[REQUIRED_FIELD_RULE(true)]}
          colon={true}
          defaultOption={defaultDrList}
          placeholder={''}
          optionsMaker={drListOptions}
          readOnly={readOnly}
        />
      )}
      <FormInput
        name="naziv_i_adresa_operatera"
        type="text"
        label={t('OBRASCI.KOM_1.MANAGEMENT.NAME_AND_ADDRESS_OF_THE_OPERATOR')}
        placeholder={''}
        inputName={'naziv_i_adresa_operatera'}
        colon={true}
        readOnly={readOnly}
      />
      <FormInput
        name="broj_dozvole_operatera"
        type="text"
        label={t('OBRASCI.KOM_1.MANAGEMENT.OPERATOR_LICENSE_NUMBER')}
        placeholder={''}
        inputName={'broj_dozvole_operatera'}
        colon={true}
        readOnly={readOnly}
      />
    </div>
  );
});
