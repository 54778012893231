import { Col, Form } from 'antd';
import { FormInputNumber } from 'components/FormInputNumber/FormInputNumber';
import { FormSearchInfiniteScroll } from 'components/FormSearchInfiniteScroll/FormSearchInfiniteScroll';
import { ICrudTypeModalProps } from 'components/Modals/CRUDModal/CRUDModal';
import { observer } from 'mobx-react-lite';
import { MORE_THEN_0, REQUIRED_FIELD_RULE } from 'modules/nriz/nriz.constants';
import { obrasciService } from 'modules/obrasci/obrasci.service';
import { obrasciStore } from 'modules/obrasci/obrasci.store';
import { GODISNJA_DOBA_FIELDS, KOLICINA_GODISNJA_DOBA } from 'modules/obrasci/store/kom1/kom1.constants';
import { kom1ObrazacStore } from 'modules/obrasci/store/kom1/kom1.store';
import { IGetKolicine } from 'modules/obrasci/store/kom1/kom1.types';
import { ISifarnikInitial } from 'modules/sifarnici/sifarnici.types';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { formWatchArray } from 'utils/formWatchArray';

export const NewKolicineForm: React.FC<ICrudTypeModalProps> = observer(props => {
  const { t } = useTranslation();
  const [vrstaOtpadaReq, setVrstaOtpadaReq] = useState(false);

  useEffect(() => {
    const fieldsValue = props.form.getFieldsValue(GODISNJA_DOBA_FIELDS);
    if (Object.values(fieldsValue).some(item => item !== null)) {
      props.form.validateFields(GODISNJA_DOBA_FIELDS);
      const middleValue = obrasciService.setMiddleValue(fieldsValue);
      props.form?.setFieldValue('srednja_vrednost', middleValue);
    }
  }, [formWatchArray(GODISNJA_DOBA_FIELDS)]);

  const defaultTypeOfWaste: ISifarnikInitial | undefined = kom1ObrazacStore.getKolicineForm.kom1_type_of_waste_id
    ? {
        label: (kom1ObrazacStore.getKolicineForm as IGetKolicine).kom1_type_of_waste.type_of_waste_sr,
        value: (kom1ObrazacStore.getKolicineForm as IGetKolicine).kom1_type_of_waste.id,
      }
    : undefined;

  const readOnly = obrasciStore.zakljucanObrazac;

  useEffect(() => {
    setVrstaOtpadaReq(props.form.getFieldValue('kom1_type_of_waste_id') === '2b4e4233-86e9-49ff-838b-8079d656da5b');
  }, [Form.useWatch('kom1_type_of_waste_id', props.form)]);

  return (
    <Col span={24}>
      <FormSearchInfiniteScroll
        showSearch={true}
        formName={'kom1_type_of_waste_id'}
        fetchOptinsList={kom1ObrazacStore.fetchKom1TypeOfWaste}
        labelOptionAccessor="type_of_waste_sr"
        valueOptionAccessor="id"
        rules={[
          REQUIRED_FIELD_RULE(true),
          {
            validator(_, value) {
              if (
                kom1ObrazacStore.getKolicineForm.kom1_type_of_waste_id !== value &&
                kom1ObrazacStore.initialState.kolicine.some(item => {
                  return value === item.kom1_type_of_waste_id;
                })
              ) {
                return Promise.reject(new Error(t('OBRASCI.GIO4.VALIDATION.WASTE_CATALOGUE_EXISTS')));
              }
              return Promise.resolve();
            },
          },
        ]}
        inputName={'kom1_type_of_waste_id'}
        label={t('OBRASCI.KOM_1.TYPE_OF_WASTE')}
        colon={true}
        defaultOption={defaultTypeOfWaste}
        placeholder={''}
        readOnly={readOnly}
      />
      <label style={{ fontWeight: 'bold' }}>{t('OBRASCI.KOM_1.KOLICINE_SAKUPLJENOG_OTPADA')}</label>
      {KOLICINA_GODISNJA_DOBA.map((item, key) => {
        return (
          <FormInputNumber
            key={key}
            name={item.name}
            label={item.label}
            rules={[
              {
                validator: (_, value) => {
                  const fields = Object.values(props.form.getFieldsValue(GODISNJA_DOBA_FIELDS)).some(
                    item => item !== null
                  );

                  if (!fields && value === null) {
                    return Promise.reject(t('OBRASCI.KOM_1.VALIDATION.BAR_JEDNO_POLJE'));
                  }
                  return Promise.resolve();
                },
              },
              ...MORE_THEN_0,
              REQUIRED_FIELD_RULE(vrstaOtpadaReq),
            ]}
            placeholder={''}
            inputName={item.name}
            colon={true}
            precision={4}
            readOnly={readOnly}
          />
        );
      })}
      <FormInputNumber
        name="srednja_vrednost"
        label={t('OBRASCI.KOM_1.MIDDLE_VALUE')}
        disabled={true}
        placeholder={''}
        inputName={'srednja_vrednost'}
        colon={true}
        readOnly={readOnly}
      />
    </Col>
  );
});
