import { makeAutoObservable } from 'mobx';
import { IGetProizvod, IGetPtp2Obrazac, IPostPtp2Obrazac, IProizvod } from './ptp2.types';
import to from 'await-to-js';
import { obrasciService } from '../../obrasci.service';
import { TIPOVI_OBRAZACA } from '../../obrasci.types';
import { IBaseObrazacStore, IPaginatedResponse, IPagination } from 'typescript/NrizTypes';
import { formStore } from 'store';
import { nrizService } from 'modules/nriz/nriz.service';
import dayjs from 'dayjs';
import { sifarniciStore } from 'modules/sifarnici/sifarnici.store';
import { obrasciStore } from 'modules/obrasci/obrasci.store';
import { PTP2_PROIZVODI_INITIAL_STATE, PTP2_INITIAL_STATE } from './ptp2.constants';
import { SIFARNICI_ROUTES } from 'modules/sifarnici/sifarnici.types';
import { handleSuccessNotify } from 'utils/succesHandling';
import { IGetProductsNr, IGetProductsType } from 'modules/sifarnici/store/productsNr/productsNr.types';
import { ptp2Service } from './ptp2.service';
import { arrayToObject } from 'utils/arrayToObject';

class PTP2ObrazacStore implements IBaseObrazacStore<IGetPtp2Obrazac, IPostPtp2Obrazac> {
  initialState: IPostPtp2Obrazac | IGetPtp2Obrazac = PTP2_INITIAL_STATE;
  proizvodi: IProizvod | IGetProizvod = PTP2_PROIZVODI_INITIAL_STATE;

  filterProductsTypeId = '';

  readOnly = false;

  dozvoljeneKorekcije = true;

  constructor() {
    makeAutoObservable(this);
  }

  resetStates = () => {
    this.handleChange('initialState', PTP2_INITIAL_STATE);
    this.handleChange('dozvoljeneKorekcije', true);
  };

  get getInitialState() {
    return this.initialState;
  }

  get getProizvodi() {
    return this.proizvodi;
  }

  get getDozvoljeneKorekcije() {
    return !obrasciStore.zakljucanObrazac || this.dozvoljeneKorekcije;
  }

  get getDozvoljeneKorekcijeBtn() {
    return !obrasciStore.zakljucanObrazac || this.initialState.dozvoljene_korekcije || this.dozvoljeneKorekcije;
  }

  handleChange(key: keyof PTP2ObrazacStore, value: any) {
    (this as any)[key] = value;
  }

  handleInitialStateChange(key: string, value: any) {
    (this as any).initialState[key] = value;
  }

  setReadOnly() {
    this.readOnly = true;
  }

  clearReadOnly() {
    this.readOnly = false;
  }

  loadData = async (id: string) => {
    const [err, res] = await to(obrasciService.fetchSingleObrazac<IGetPtp2Obrazac>(TIPOVI_OBRAZACA.PTP2, id));
    if (err) return;

    const newGodina = dayjs().year(Number(res.godina));
    const initialState = { ...res, godina: newGodina };
    this.handleChange('dozvoljeneKorekcije', res.dozvoljene_korekcije);
    this.handleChange('initialState', initialState);

    return res;
  };

  submitDozvoljenePtp2Korekcije = async () => {
    const [err, res] = await to(ptp2Service.submitDozvoljeneKorekcije(this.initialState as IGetPtp2Obrazac));
    if (err || !res) return;

    formStore.handleChange('goBack', true);
    handleSuccessNotify();
  };

  async postData(payload: IPostPtp2Obrazac) {
    const [err, res] = await to(
      obrasciService.postSingleObrazac<IPostPtp2Obrazac>(
        TIPOVI_OBRAZACA.PTP2,
        nrizService.setEmptyValuesToNull(payload)
      )
    );
    if (err || !res) return;
    formStore.handleChange('goBack', true);
    handleSuccessNotify();
  }

  async deleteData(id: string) {
    const [err, res] = await to(obrasciService.deleteSingleObrazac(TIPOVI_OBRAZACA.PTP2, id));
    if (err || !res) return;
    formStore.handleChange('goBack', true);
    handleSuccessNotify();
  }

  async putData(payload: IGetPtp2Obrazac) {
    const [err, res] = await to(
      obrasciService.putSingleObrazac<IGetPtp2Obrazac>(
        TIPOVI_OBRAZACA.PTP2,
        payload.id,
        nrizService.setEmptyValuesToNull(payload)
      )
    );
    if (err || !res) return;
    formStore.handleChange('goBack', true);
    handleSuccessNotify();
  }

  checkIfUlje = (id: string) => {
    const productNrObj: IGetProductsNr =
      id === this.proizvodi.products_nr_id
        ? (this.proizvodi as IGetProizvod).products_nr
        : arrayToObject(sifarniciStore.list[SIFARNICI_ROUTES.PRODUCTS_NR])[id];

    return productNrObj?.display_product_category?.toLowerCase()?.includes('ulja');
  };

  findProductTypeObj = (id: string) => {
    return id === this.proizvodi?.products_type_id
      ? (this.proizvodi as IGetProizvod)?.products_type
      : arrayToObject(sifarniciStore.list[SIFARNICI_ROUTES.PRODUCTS_TYPE])[id];
  };

  submitData = (payload: IPostPtp2Obrazac) => {
    const newInitialState = {
      ...payload,
      godina: obrasciStore.opste.godina,
      id: (this.initialState as IGetPtp2Obrazac).id,
      proizvodi: this.initialState.proizvodi,
    } as IGetPtp2Obrazac;

    obrasciService.submitFormObrazac({
      payload: newInitialState,
      postForm: this.postData,
      putForm: this.putData,
      deleteForm: this.deleteData,
    });
  };

  submitProizvodiForm = (payload: IProizvod) => {
    let newPayload = payload;
    let sifarnikProductNr = {};
    let sifarnikProductType = {};

    if (formStore.getCrudButtonType === 'submit' || formStore.getCrudButtonType === 'change') {
      if (newPayload.products_nr_id) {
        sifarnikProductNr = nrizService.addSifarnikInObject({
          exId: (this.proizvodi as IGetProizvod).products_nr_id,
          exObj: (this.proizvodi as IGetProizvod).products_nr,
          list: sifarniciStore.list[SIFARNICI_ROUTES.PRODUCTS_NR],
          name: 'products_nr',
          id: payload.products_nr_id,
        });
      }

      if (newPayload.products_type_id) {
        sifarnikProductType = nrizService.addSifarnikInObject({
          exId: (this.proizvodi as IGetProizvod).products_type_id,
          exObj: (this.proizvodi as IGetProizvod).products_type,
          list: sifarniciStore.list[SIFARNICI_ROUTES.PRODUCTS_TYPE],
          name: 'products_type',
          id: payload.products_type_id,
        });
      }
    }

    const dozvoljenaKorekcija = this.initialState.dozvoljene_korekcije
      ? {
          dozvoljena_korekcija_proizvoda: true,
        }
      : {};

    newPayload = {
      id: (this.proizvodi as IGetProizvod).id,
      ...dozvoljenaKorekcija,
      ...sifarnikProductNr,
      ...sifarnikProductType,
      ...payload,
    } as IGetProizvod;

    const newList = obrasciService.submitMicroServiceForm(this.initialState.proizvodi, newPayload);
    this.handleInitialStateChange('proizvodi', newList);
    this.handleChange('proizvodi', PTP2_PROIZVODI_INITIAL_STATE);
    this.handleChange('filterProductsTypeId', '');
  };

  //// SIFARNICI

  fetchProductsNr = async (pagination: IPagination, search: string): Promise<IPaginatedResponse<IGetProductsNr>> => {
    const response = await sifarniciStore.fetchSifarnikOptions<IGetProductsNr>(SIFARNICI_ROUTES.PRODUCTS_NR, {
      page: pagination.page,
      size: pagination.size,
      year: obrasciStore.opste.godina,
      search: search,
    });
    return response;
  };

  fetchProductsTypes = async (
    pagination: IPagination,
    search: string
  ): Promise<IPaginatedResponse<IGetProductsType>> => {
    const response = await sifarniciStore.fetchSifarnikOptions<IGetProductsType>(SIFARNICI_ROUTES.PRODUCTS_TYPE, {
      page: pagination.page,
      size: pagination.size,
      products_nr_id: this.filterProductsTypeId,
      search: search,
      order_by: '+display_product',
    });
    return response;
  };
}

export const ptp2ObrazacStore = new PTP2ObrazacStore();
