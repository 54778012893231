import { ColumnsType } from 'antd/es/table';
import { IGetRecipientType, IPostRecipientType } from './recipientType.types';
import { FormInstance } from 'antd';
import { InputSifarniciFormProps } from 'modules/sifarnici/sifarnici.types';

export const RECIPIENT_TYPE_INITIAL_STATE: IPostRecipientType = {
  type: '',
  type_sr: '',
  is_active: true,
};

export const RECIPIENT_TYPE_TABLE_DATA: ColumnsType<IGetRecipientType> = [
  {
    title: 'Tip primaoca',
    width: '300px',
    dataIndex: 'type',
    key: 'type',
  },
  {
    title: 'Tip primaoca (srp.)',
    width: '300px',
    dataIndex: 'type_sr',
    key: 'type_sr',
  },
];

export const RECIPIENT_TYPE_FORM_OPTIONS = (_: FormInstance<IGetRecipientType>) => {
  return [
    {
      type_of_form: 'input',
      name: 'type',
      // todo: prevod
      label: 'Tip primaoca',
      inputName: 'type',
    } as InputSifarniciFormProps,
    {
      type_of_form: 'input',
      name: 'type_sr',
      // todo: prevod
      label: 'Tip primaoca (srp.)',
      inputName: 'type_sr',
    } as InputSifarniciFormProps,
  ];
};
