import { ColumnsType } from 'antd/es/table';
import TableData from 'components/TableData/TableData';
import { observer } from 'mobx-react-lite';
import { aao2Store } from 'modules/obrasci/store/aao2/aao2.store';
import { useTranslation } from 'react-i18next';
import style from './Aao2KolicinaAmbalazeTable.module.scss';
import { IAao2KolicinaAmbalaze } from 'modules/obrasci/store/aao2/aao2.types';
import { EditButtonProps, RowEditPreviewButton } from 'components/RowEditPreviewButton/RowEditPreviewButton';
import { obrasciStore } from 'modules/obrasci/obrasci.store';

const EditAao2KolicinaAmbalazeButton: React.FC<EditButtonProps<IAao2KolicinaAmbalaze>> = observer(({ record }) => {
  return (
    <RowEditPreviewButton
      name="aao2-kolicina-ambalaze"
      modalName="aao2-kolicina-ambalaze-modal"
      onClick={() => aao2Store.handleChange('kolicinaAmbalaze', record)}
      preview={obrasciStore.zakljucanObrazac}
    />
  );
});

export const Aao2KolicinaAmbalazeTable: React.FC = observer(() => {
  const { t } = useTranslation();

  const columns: ColumnsType<IAao2KolicinaAmbalaze> = [
    {
      title: t('OBRASCI.AAO1.PACKAGING_AMOUNT.PACKAGING_TYPE'),
      width: 150,
      align: 'center',
      dataIndex: ['type_of_packing', 'display_type_sr'],
    },
    {
      title: t('OBRASCI.AAO2.PACKAGING_AMOUNT.TITLE'),
      children: [
        {
          title: t('OBRASCI.AAO1.PACKAGING_AMOUNT.MANUFACTURED'),
          width: 120,
          align: 'center',
          dataIndex: 'proizvedena_kolicina',
        },
        {
          title: t('OBRASCI.AAO1.PACKAGING_AMOUNT.IMPORTED'),
          width: 120,
          align: 'center',
          dataIndex: 'uvezena_kolicina',
        },
        {
          title: t('OBRASCI.AAO1.PACKAGING_AMOUNT.EXPORTED'),
          width: 120,
          align: 'center',
          dataIndex: 'izvezena_kolicina',
        },
        {
          title: t('OBRASCI.AAO1.PACKAGING_AMOUNT.TOTAL'),
          width: 120,
          align: 'center',
          dataIndex: 'ukupna_kolicina',
        },
      ],
    },
    {
      title: '',
      fixed: 'right',
      width: 50,
      render: (_, record: IAao2KolicinaAmbalaze) => <EditAao2KolicinaAmbalazeButton record={record} />,
    },
  ];

  return (
    <div className={style.tableDiv}>
      <TableData name="kolicine-aao2" columns={columns} dataSource={[...aao2Store.getInitialState.kolicina_ambalaze]} />
    </div>
  );
});
