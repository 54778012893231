import { Col } from 'antd';
import { FormInput } from 'components/FormInput/FormInput';
import { FormInputNumber } from 'components/FormInputNumber/FormInputNumber';
import { FormSearchInfiniteScroll } from 'components/FormSearchInfiniteScroll/FormSearchInfiniteScroll';
import { observer } from 'mobx-react-lite';
import { MORE_OR_EQUILE_0_IS_REQUIRED, MORE_THEN_0, REQUIRED_FIELD_RULE } from 'modules/nriz/nriz.constants';
import { obrasciStore } from 'modules/obrasci/obrasci.store';
import { gveStore } from 'modules/obrasci/store/gve/gve.store';
import { IGetGorivaGve } from 'modules/obrasci/store/gve/gve.types';
import { ISifarnikInitial } from 'modules/sifarnici/sifarnici.types';
import { useTranslation } from 'react-i18next';

export const NewGveGorivaForm: React.FC = observer(() => {
  const { t } = useTranslation();

  const defaultGorivoOption: ISifarnikInitial | undefined = gveStore.getGorivaForm.naziv_goriva_id
    ? {
        label: (gveStore.getGorivaForm as IGetGorivaGve).naziv_goriva.name_sr,
        value: gveStore.getGorivaForm.naziv_goriva_id,
      }
    : undefined;

  const readOnly = obrasciStore.zakljucanObrazac;

  return (
    <Col span={24}>
      <h4 style={{ fontWeight: 'bold' }}>{t('OBRASCI.GVE.PODACI_O_KORISCENIM_GORIVIMA')}</h4>
      <FormSearchInfiniteScroll
        showSearch={true}
        formName={'naziv_goriva_id'}
        fetchOptinsList={gveStore.fetchFuelType}
        labelOptionAccessor="name_sr"
        valueOptionAccessor="id"
        label={t('OBRASCI.GVE.GORIVO')}
        labelCol={{ span: 12 }}
        wrapperCol={{ span: 12 }}
        inputName={'naziv_goriva_id'}
        defaultOption={defaultGorivoOption}
        placeholder={t('OBRASCI.GVE.GORIVO')}
        rules={[
          REQUIRED_FIELD_RULE(true),
          {
            validator(_, value) {
              if (
                gveStore.goriva_form.naziv_goriva_id !== value &&
                gveStore.initialState.goriva.some(item => {
                  return value === item.naziv_goriva_id;
                })
              ) {
                return Promise.reject(new Error('Gorivo već postoji u listi'));
              }
              return Promise.resolve();
            },
          },
        ]}
        readOnly={readOnly}
      />
      <FormInputNumber
        name="ukupna_godisnja_potrosnja"
        label={t('OBRASCI.GVE.UKUPNA_GODISNJA_POTROSNJA')}
        type="number"
        placeholder={t('OBRASCI.GVE.UKUPNA_GODISNJA_POTROSNJA')}
        inputName={'ukupna_godisnja_potrosnja'}
        colon={true}
        labelCol={{ span: 12 }}
        wrapperCol={{ span: 12 }}
        rules={MORE_OR_EQUILE_0_IS_REQUIRED}
        readOnly={readOnly}
      />
      <FormInputNumber
        name="donja_toplotna_moc"
        label={t('OBRASCI.GVE.DONJA_TOPLOTNA_MOC_GORIVA')}
        type="number"
        placeholder={t('OBRASCI.GVE.DONJA_TOPLOTNA_MOC_GORIVA')}
        inputName={'donja_toplotna_moc'}
        colon={true}
        labelCol={{ span: 12 }}
        wrapperCol={{ span: 12 }}
        rules={MORE_THEN_0}
        readOnly={readOnly}
      />

      <h4 style={{ fontWeight: 'bold' }}>{t('OBRASCI.GVE.SASTAV_GORIVA')}</h4>
      <FormInputNumber
        name="sumpor"
        label={t('OBRASCI.GVE.SUMPOR')}
        type="number"
        placeholder={t('OBRASCI.GVE.SUMPOR')}
        inputName={'sumpor'}
        colon={true}
        labelCol={{ span: 12 }}
        wrapperCol={{ span: 12 }}
        rules={MORE_THEN_0}
        readOnly={readOnly}
      />
      <FormInputNumber
        name="azot"
        label={t('OBRASCI.GVE.AZOT')}
        type="number"
        placeholder={t('OBRASCI.GVE.AZOT')}
        inputName={'azot'}
        colon={true}
        labelCol={{ span: 12 }}
        wrapperCol={{ span: 12 }}
        rules={MORE_THEN_0}
        readOnly={readOnly}
      />
      <FormInputNumber
        name="hlor"
        label={t('OBRASCI.GVE.HLOR')}
        type="number"
        placeholder={t('OBRASCI.GVE.HLOR')}
        inputName={'hlor'}
        colon={true}
        labelCol={{ span: 12 }}
        wrapperCol={{ span: 12 }}
        rules={MORE_THEN_0}
        readOnly={readOnly}
      />
      <FormInput
        name="naziv_dodatnog_sadrzaja1"
        label={t('OBRASCI.GVE.NAZIV_DODATNOG_1')}
        type="text"
        placeholder={t('OBRASCI.GVE.NAZIV_DODATNOG_1')}
        inputName={'naziv_dodatnog_sadrzaja1'}
        colon={true}
        labelCol={{ span: 12 }}
        wrapperCol={{ span: 12 }}
        readOnly={readOnly}
      />
      <FormInputNumber
        name="dodatni_sadrzaj1"
        label={t('OBRASCI.GVE.DODATNI_1')}
        type="number"
        placeholder={t('OBRASCI.GVE.DODATNI_1')}
        inputName={'dodatni_sadrzaj1'}
        colon={true}
        labelCol={{ span: 12 }}
        wrapperCol={{ span: 12 }}
        rules={MORE_THEN_0}
        readOnly={readOnly}
      />
      <FormInput
        name="naziv_dodatnog_sadrzaja2"
        label={t('OBRASCI.GVE.NAZIV_DODATNOG_2')}
        type="text"
        placeholder={t('OBRASCI.GVE.NAZIV_DODATNOG_2')}
        inputName={'naziv_dodatnog_sadrzaja2'}
        colon={true}
        labelCol={{ span: 12 }}
        wrapperCol={{ span: 12 }}
        readOnly={readOnly}
      />
      <FormInputNumber
        name="dodatni_sadrzaj2"
        label={t('OBRASCI.GVE.DODATNI_2')}
        type="number"
        placeholder={t('OBRASCI.GVE.DODATNI_2')}
        inputName={'dodatni_sadrzaj2'}
        colon={true}
        labelCol={{ span: 12 }}
        wrapperCol={{ span: 12 }}
        rules={MORE_THEN_0}
        readOnly={readOnly}
      />
    </Col>
  );
});
