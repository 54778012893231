import { ColumnsType } from 'antd/es/table';
import { EditButtonProps, RowEditPreviewButton } from 'components/RowEditPreviewButton/RowEditPreviewButton';
import TableData from 'components/TableData/TableData';
import { observer } from 'mobx-react-lite';
import { obrasciStore } from 'modules/obrasci/obrasci.store';
import { obrazac3Store } from 'modules/obrasci/store/obrazac3/obrazac3.store';
import { IGetObrazac3AnalizaRecipijenata } from 'modules/obrasci/store/obrazac3/obrazac3.types';
import React from 'react';
import { useTranslation } from 'react-i18next';

const EditObrazac3AnalizaOtpadneVodeButton: React.FC<EditButtonProps<IGetObrazac3AnalizaRecipijenata>> = observer(
  ({ record }) => {
    return (
      <RowEditPreviewButton
        name="obrazac3-analiza-recipijenata"
        modalName="obrazac3-analiza-recipijenata-modal"
        onClick={() => {
          obrazac3Store.handleChange('analiza_recipijenata_form', record);
          obrazac3Store.handleChange('nacin_odredjivanja_id', record.nacin_odredjivanja_id);
        }}
        preview={obrasciStore.zakljucanObrazac}
      />
    );
  }
);

export const Obrazac3AnalizaRecipijenataTable: React.FC = observer(() => {
  const { t } = useTranslation();

  const baseColumns: ColumnsType<IGetObrazac3AnalizaRecipijenata> = [
    {
      title: `${t('OBRASCI.OBRAZAC3.NAZIV_ZAGADJUJUCE_MATERIJE')}`,
      width: 200,
      dataIndex: ['naziv_zagadjujuce_materije', 'name_sr'],
      key: 'naziv_zagadjujuce_materije_id',
    },
    {
      title: `${t('OBRASCI.OBRAZAC3.JEDINICA_MERE')}`,
      width: 200,
      dataIndex: ['jedinica_mere', 'code'],
      key: 'jedinica_mere_id',
    },
    {
      title: `${t('OBRASCI.OBRAZAC3.PRE_ISPUSTA')}`,
      dataIndex: 'pre_ispusta_otpadne_vode',
      key: 'pre_ispusta_otpadne_vode',
      width: 200,
    },
    {
      title: `${t('OBRASCI.OBRAZAC3.POSLE_ISPUSTA')}`,
      width: 200,
      dataIndex: 'posle_ispusta_otpadne_vode',
      key: 'posle_ispusta_otpadne_vode',
    },
    {
      title: `${t('OBRASCI.OBRAZAC3.NACIN_ODREDJIVANJA')}`,
      dataIndex: ['nacin_odredjivanja', 'name_sr'],
      key: 'nacin_odredjivanja_id',
      width: 200,
    },
    {
      title: `${t('OBRASCI.OBRAZAC3.METOD_ODREDJIVANJA')}`,
      width: 200,
      dataIndex: ['metod_odredjivanja', 'name_sr'],
      key: 'metod_odredjivanja_id',
    },

    {
      key: 'operation',
      fixed: 'right',
      width: 130,
      align: 'center',
      render: (_, record: IGetObrazac3AnalizaRecipijenata) => <EditObrazac3AnalizaOtpadneVodeButton record={record} />,
    },
  ];
  return (
    <TableData
      name="analiza-recepijenata-obrazac3"
      columns={baseColumns}
      dataSource={[...obrazac3Store.initialState.analiza_recipijenta]}
    />
  );
});
