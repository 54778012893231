import { ColProps, FormInstance } from 'antd';
import { DefaultOptionType } from 'antd/es/select';
import { Dayjs } from 'dayjs';
import { IPostFacility } from 'modules/facility/facility.types';
import { RegistriLists } from 'modules/registri/registri.types';
import { ISifarniciTypeOfForm, SIFARNICI_ROUTES, SifarniciLists } from 'modules/sifarnici/sifarnici.types';
import { CSSProperties, ChangeEvent } from 'react';
import { IFiltersBySection } from 'store/filteri.store';

export interface RuleConfig {
  required?: boolean;
  message?: string;
  type?: 'email' | 'number' | 'regexp';
  min?: number;
  max?: number;
  pattern?: any;
  validator?: (_: any, value: any) => Promise<any>;
  validateTrigger?: string[];
}

export interface FormInputProps {
  name?: string | string[];
  inputName: string;
  placeholder?: string;
  rules?: RuleConfig[];
  iconComponent?: JSX.Element;
  type?: string;
  label?: string | React.ReactNode;
  disabled?: boolean;
  colon?: boolean;
  step?: string;
  className?: string;
  style?: CSSProperties;
  onInputChange?: (e: ChangeEvent<HTMLInputElement>) => void;
  defaultValue?: string;
  readOnly?: boolean;
  value?: string;
  inputNumber?: boolean;
  labelAlign?: 'left' | 'right';
  labelCol?: ColProps;
  wrapperCol?: ColProps;
  precision?: number;
  invalidCharacters?: string[];
  min?: number;
}

export interface FormSelectProps {
  className?: string;
  labelCol?: ColProps;
  wrapperCol?: ColProps;
  allowClear?: boolean;
  name: string;
  rules?: RuleConfig[];
  colon?: boolean;
  labelAlign?: 'left' | 'right';
  label: string;
  showSearch?: boolean;
  placeholder?: string;
  onChange?: (e: string) => any;
  filterOption?: any;
  value?: any;
  options: any[];
  disabled?: boolean;
  mode?: 'tags' | 'multiple';
}

export interface RuleConfigText {
  required?: boolean;
  message?: string;
  type?: 'email' | 'number';
}

export interface FormTextAreaProps {
  name: string;
  onChange?: (e: string) => any;
  rules?: RuleConfigText[];
  label?: string;
  placeholder?: string;
  rows: number;
  labelCol?: number;
  wrapperCol?: number;
  disabled?: boolean;
  invalidCharacters?: string[];
  readOnly?: boolean;
  className?: string;
}
export interface FormNumberInputProps {
  name?: string | string[];
  inputName: string | string[];
  placeholder?: string;
  rules?: RuleConfig[];
  iconComponent?: JSX.Element;
  type?: string;
  label?: string | React.ReactNode;
  disabled?: boolean;
  colon?: boolean;
  step?: string;
  className?: string;
  style?: CSSProperties;
  onInputChange?: (e: number) => void;
  defaultValue?: string;
  readOnly?: boolean;
  value?: string;
  inputNumber?: boolean;
  labelAlign?: 'left' | 'right';
  labelCol?: ColProps;
  wrapperCol?: ColProps;
  precision?: number | undefined;
  invalidCharacters?: string[];
  min?: number;
  validateStatus?: '' | 'success' | 'warning' | 'error' | 'validating' | undefined;
  help?: string;
  hidden?: boolean;
}

export interface FormSearchScrollProps {
  formName: string | string[];
  labelOptionAccessor: string;
  valueOptionAccessor: string;
  disabledAccessor?: string;
  defaultOption?: DefaultOptionType;
  options?: any[];
  fetchOptinsList: (e: any, params?: any) => any;
  optionsMaker?: (e: any[]) => { label: any; value: any }[];
  label?: string;
  rules?: RuleConfig[];
  readOnly?: boolean;
  inputName?: string;
  colon?: boolean;
  type?: string;
  placeholder: string;
  labelCol?: ColProps;
  wrapperCol?: ColProps;
  onChange?: (value: any, option?: any) => void;
  wait?: boolean;
  disabled?: boolean;
  notFoundContent?: string;
  showSearch?: boolean;
  validateTrigger?: string | string[];
  resetOption?: any;
  name?: string;
  filtersForFetch?: any;
}

export interface IFormScrollWithObjProps {
  formName: string | string[];
  name?: string;
  labelOptionAccessor: string[];
  valueOptionAccessor: string;
  disabledAccessor?: string;
  defaultOption?: DefaultOptionType;
  options?: any[];
  filtersForFetch?: any;
  fetchOptinsList: SIFARNICI_ROUTES;
  optionsMaker?: (e: any[]) => { label: any; value: any }[];
  label?: string;
  rules?: RuleConfig[];
  readOnly?: boolean;
  inputName?: string;
  colon?: boolean;
  type?: string;
  placeholder?: string;
  labelCol?: ColProps;
  wrapperCol?: ColProps;
  onChange?: (value: any, option?: any, children?: any) => void;
  wait?: boolean;
  disabled?: boolean;
  notFoundContent?: string;
  showSearch?: boolean;
  validateTrigger?: string | string[];
  resetOption?: any;
  objName?: string;
  // form: FormInstance<any>;
  customParamsForSearch?: string;
}

export interface FormCheckboxProps {
  name: string;
  label?: string | React.ReactNode;
  labelCol?: ColProps;
  wrapperCol?: ColProps;
  rules?: RuleConfig[];
  onCheckBoxChange?: (e: any) => void;
  onClick?: (e?: React.MouseEvent<HTMLElement, MouseEvent>) => void;
  style?: CSSProperties;
  labelAlign?: 'left' | 'right';
  className?: string;
  disabled?: boolean;
  checked?: boolean;
  colon?: boolean;
  hidden?: boolean;
  defaultChecked?: boolean;
}

export interface FormSwitchProps {
  name: string;
  label?: string | React.ReactNode;
  labelCol?: ColProps;
  wrapperCol?: ColProps;
  rules?: RuleConfig[];
  onSwitchChange?: (e: any) => void;
  style?: CSSProperties;
  labelAlign?: 'left' | 'right';
  className?: string;
  disabled?: boolean;
  checked?: boolean;
  colon?: boolean;
  hidden?: boolean;
}

export interface FormRadioProps {
  name: string;
  label?: string | React.ReactNode;
  labelCol?: ColProps;
  wrapperCol?: ColProps;
  rules?: RuleConfig[];
  onRadioChange?: (e: any) => void;
  value: string;
  style?: CSSProperties;
  labelAlign?: 'left' | 'right';
  className?: string;
  disabled?: boolean;
  checked?: boolean;
}

export interface IRangePickerProps {
  name: string;
  label?: string;
  showTime?: boolean;
  defaultPickerValue?: any;
  labelCol?: ColProps;
  wrapperCol?: ColProps;
  style?: CSSProperties;
  rules?: RuleConfig[];
  className?: string;
  onChange?: (e: any, string?: string) => void;
  defaultValue?: any;
  disabled?: boolean;
  format?: string;
  picker?: any;
  disabledDate?: (date: Dayjs) => boolean;
  placeholder?: string;
  colon?: boolean;
  readOnly?: boolean;
}

export interface ContentProps {
  header?: React.ReactNode;
  children: React.ReactNode;
  className?: string;
  backBtn?: IBackButtonProps | undefined;
}

export interface IBackButtonProps {
  name: string;
  route: string;
  type?: 'link' | 'text' | 'ghost' | 'default' | 'primary' | 'dashed';
}

export interface ButtonProps {
  name: string;
  onButtonClick: () => void;
  label?: string;
  disabled?: boolean;
}

export interface IFormProps {
  readOnly?: boolean;
  formName?: string;
  passForm?: (form: FormInstance<any>) => any;
  showButtons?: boolean;
}

export interface CRUDButtonProps {
  showButton: boolean;
  withOutDelete?: boolean;
  withPopConfirm?: React.ReactNode;
  form: FormInstance;
}

export interface ListTableProps {
  fetchListTable: any;
  forceFetch?: any;
}

export interface FacilityListProps {
  readOnly?: boolean;
  passForm?: (form: FormInstance<IPostFacility>) => void;
}

export interface IAirProps {
  fetchAirList: () => void;
}

export interface IPaginationWithFilters extends IPagination {
  filters: any;
}

export interface IPageSizePagination {
  page: number;
  size: number;
}

export interface IPagination extends IPageSizePagination {
  total: number;
  pages: number;
}

export interface IPaginatedResponse<T = any> extends IPagination {
  items: T[];
}

export type IPaginatedApiResponse<T> = Promise<IPaginatedResponse<T>>;

export interface IFormSelectScroll extends IPagination {
  items: any[];
}

export interface IErrors {
  toJSON(): any;
  detail?: any;
  response: {
    status: number;
    statusText: string;
    data: {
      detail: {
        loc: string[];
        msg: string;
        type: string;
      }[];
    };
  };
}

export interface IBaseObrazacStore<GetResponse = any, PostPayload = any> {
  loadData: (id: string) => void;
  setData?: (res: GetResponse) => void;
  postData: (payload: PostPayload) => void;
  putData: (payload: GetResponse) => void;
  handleChange: (key: any, value: any) => void;
  resetStates: () => void;
  initialState: PostPayload | GetResponse;
  submitData: (payload: PostPayload) => void;
  deleteData: (id: string) => void;
  handleValidation?: () => any;
  getIsTabHidden?: boolean[] | boolean;
  readOnly?: boolean;
  setReadOnly?: () => void;
  clearReadOnly?: () => void;
}

export interface ISifarnikFindProps {
  list: any;
  name: string;
  id: string;
  exId?: string;
  exObj?: any;
}

export interface IBaseSifarnikStore<GetResponse = any, PostPayload = any> {
  initialState: any;
  handleChange: (key: string, value: any) => void;
  postData: (payload: PostPayload) => any;
  putData: (payload: GetResponse) => any;
  deleteData: (id: string) => any;
  submitData: (payload: PostPayload) => any;
  fetchSingleSifarnik: (id: string) => void;
  resetState: () => void;
}

export interface IFilterTableProps {
  form: FormInstance<any>;
  initialState: any;
  filterOptions: ISifarniciTypeOfForm[];
  filterName: keyof IFiltersBySection;
  resetForm?: any;
  dontAddIlike?: boolean;
  modifyPayload?: (e: any) => any;
  clearFilters?: () => void;
  fetchTableList: () => any;
}
export interface IFormComponents {
  components: ISifarniciTypeOfForm[];
}

export interface ISelectProps {
  label: string;
  value: string;
}

export interface IFilterObjectProps {
  type_of_form: string;
  hideInput?: boolean;
  sortName: string;
}

export type IFilterOptions =
  | (FormInputProps & IFilterObjectProps)
  | (FormSelectProps & IFilterObjectProps)
  | (FormSearchScrollProps & IFilterObjectProps)
  | (FormSwitchProps & IFilterObjectProps)
  | (FormCheckboxProps & IFilterObjectProps)
  | (FormRadioProps & IFilterObjectProps)
  | (IFormScrollWithObjProps & IFilterObjectProps);

export interface FormListConfig {
  formListType: FormListType;
  formListSubType: FormListSubType;
}

// za svaki unos enuma FormListType dodaje se odgovarajuci enum u FormListSubType

export enum FormListType {
  Registri = 1,
  Sifarnici,
}

export type FormListSubType = RegistriLists | SifarniciLists;

export interface IPostResponse {
  id: string;
}

export type IEmptyResponse = undefined;
