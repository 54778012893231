import { ColumnsType } from 'antd/es/table';
import { EditButtonProps, RowEditPreviewButton } from 'components/RowEditPreviewButton/RowEditPreviewButton';
import TableData from 'components/TableData/TableData';
import { observer } from 'mobx-react-lite';
import { obrasciStore } from 'modules/obrasci/obrasci.store';
import { kom1ObrazacStore } from 'modules/obrasci/store/kom1/kom1.store';
import { IGetNaselja } from 'modules/obrasci/store/kom1/kom1.types';
import { useTranslation } from 'react-i18next';

const EditNaseljaButton: React.FC<EditButtonProps<IGetNaselja>> = observer(({ record }) => {
  return (
    <RowEditPreviewButton
      name="kom1-naselja"
      modalName="naselja-modal"
      onClick={() => kom1ObrazacStore.handleChange('naselja_form', record)}
      preview={obrasciStore.zakljucanObrazac}
    />
  );
});

export const TableNaselja: React.FC = observer(() => {
  const { t } = useTranslation();

  const baseColumns: ColumnsType<IGetNaselja> = [
    {
      title: `${t('OBRASCI.DEP_2.SETTLEMENT')}`,
      width: '150px',
      dataIndex: ['mesto', 'display_name'],
      key: 'display_name',
    },
    {
      title: `${t('OBRASCI.KOM_1.TOTAL_NUMBER_OF_HOUSEHOLDS_IN_SETTLEMENT')}`,
      width: '150px',
      dataIndex: 'ukupan_broj_domacinstava_u_naselju',
      key: 'ukupan_broj_domacinstava_u_naselju',
    },
    {
      title: `${t('OBRASCI.KOM_1.NUMBER_OF_HOUSEHOLDS_COVERED_BY_WASTE_COLLECTION')}`,
      dataIndex: 'broj_domacinstava_obuhvacenih_prikupljanjem_otpada',
      key: 'broj_domacinstava_obuhvacenih_prikupljanjem_otpada',
      width: '200px',
    },
    {
      title: ``,
      key: 'operation',
      fixed: 'right',
      width: '50px',
      align: 'center',
      render: (_, record: IGetNaselja) => <EditNaseljaButton record={record} />,
    },
  ];

  return (
    <TableData
      scrollConfig={['#obrazacTableHeader', '#obrazacWizardHeader']}
      name="naselje-kom1"
      columns={baseColumns}
      dataSource={[...kom1ObrazacStore.initialState.naselja]}
    />
  );
});
