import { Form, Row } from 'antd';
import { useForm } from 'antd/es/form/Form';
import { Button } from 'components/Button/Button';
import { FormComponents } from 'components/FormComponents/FormComponents';
import { observer } from 'mobx-react-lite';
import { nrizService } from 'modules/nriz/nriz.service';
import { readOnlySifarnikTypes } from 'modules/sifarnici/sifarnici.constants';
import { sifarniciStore } from 'modules/sifarnici/sifarnici.store';
import { DateSifarniciFormProps, ISifarniciFormData, ISifarniciTypeOfForm } from 'modules/sifarnici/sifarnici.types';
import { IS_ACTIVE_SWITCH } from 'modules/sifarnici/sifarniciFormObj.constants';
import React, { useEffect, useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import { modalStore } from 'store';

type INewSifarnikFormProps = {
  formData: ISifarniciFormData;
};

export const NewSifarnikForm: React.FC<INewSifarnikFormProps> = observer(props => {
  const [form] = useForm();
  const { t } = useTranslation();
  const queryParams = new URLSearchParams(location.search);
  const sifarnik_id = queryParams.get('sifarnik_id');

  const components = useMemo(() => {
    if (sifarnik_id && !readOnlySifarnikTypes.includes(sifarniciStore.sifarnik_type)) {
      return [IS_ACTIVE_SWITCH, ...props.formData.components(form)];
    }
    return props.formData.components(form);
  }, [Form.useWatch([], form)]);

  const filteredArray = components.filter(item => item.type_of_form === 'date');
  const dateArray = filteredArray.map((item: DateSifarniciFormProps | any) => ({
    name: item.name,
    type: item.picker || '',
  }));

  useEffect(() => {
    if (sifarnik_id) {
      sifarniciStore.fetchSingleSifarnik(sifarnik_id, dateArray);
    }
    return () => {
      sifarniciStore.handleChange('initialState', {});
    };
  }, []);

  useEffect(() => {
    return form.setFieldsValue(sifarniciStore.initialState);
  }, [sifarniciStore.initialState]);

  return (
    <Form
      form={form}
      style={{ width: '100%' }}
      initialValues={props.formData.initialState}
      name={sifarniciStore.sifarnik_type}
      onFinish={_ => {
        const allValues = nrizService.setEmptyValuesToNull(form.getFieldsValue(true));
        if (sifarnik_id) {
          sifarniciStore.putSingleSifarnik(allValues, dateArray);
        } else {
          sifarniciStore.postSingleSifarnik(allValues, dateArray);
        }
      }}
      labelCol={props?.formData?.labelCol || { span: 10 }}
      wrapperCol={props?.formData?.wrapperCol || { span: 14 }}
      labelAlign="left"
    >
      <Row gutter={10}>
        {components.map((item: ISifarniciTypeOfForm, index) => (
          <FormComponents data={item} key={index} />
        ))}
      </Row>

      <Row justify={'end'}>
        {sifarnik_id ? (
          <Form.Item>
            <Button htmlType="submit" name="change-button" disabled={modalStore?.isLoading}>
              {t('SUBMIT')}
            </Button>
          </Form.Item>
        ) : (
          <Form.Item>
            <Button htmlType="submit" name="submit-button" disabled={modalStore?.isLoading}>
              {t('SUBMIT')}
            </Button>
          </Form.Item>
        )}
      </Row>
    </Form>
  );
});
