import { ColumnsType } from 'antd/es/table';
import { EditButtonProps, RowEditPreviewButton } from 'components/RowEditPreviewButton/RowEditPreviewButton';
import TableData from 'components/TableData/TableData';
import { observer } from 'mobx-react-lite';
import { obrasciStore } from 'modules/obrasci/obrasci.store';
import { gio2ObrazacStore } from 'modules/obrasci/store/gio2/gio2.store';
import { IGetKolicineGio2 } from 'modules/obrasci/store/gio2/gio2.types';
import { useTranslation } from 'react-i18next';

const EditGio2KolicineButton: React.FC<EditButtonProps<IGetKolicineGio2>> = observer(({ record }) => {
  return (
    <RowEditPreviewButton
      name="gio2-kolicine"
      modalName="gio2-kolicine-modal"
      onClick={() => gio2ObrazacStore.handleChange('kolicine_form', record)}
      preview={obrasciStore.zakljucanObrazac}
    />
  );
});

export const Gio2TableKolicine: React.FC = observer(() => {
  const { t } = useTranslation();

  const baseColumns: ColumnsType<IGetKolicineGio2> = [
    {
      title: `${t('OBRASCI.KOM_1.MANAGEMENT.WASTE_INDEX_NUMBER_FROM_THE_WASTE_CATALOG')}`,
      width: '250px',
      dataIndex: ['indeksni_broj_otpada', 'index_number'],
      key: 'waste',
      render: (_: any, record: IGetKolicineGio2) => (
        <>{`${record.indeksni_broj_otpada.index_number} ${record.indeksni_broj_otpada.waste_sr.toUpperCase()}`}</>
      ),
    },
    {
      title: `${t('OBRASCI.GIO_3.AMOUNT_OF_COLLECTED_WASTE_IN_REPORTING_YEAR')}`,
      width: '200px',
      dataIndex: 'kolicina_preuzetog_otpada',
      key: 'kolicina_preuzetog_otpada',
    },
    {
      title: `${t('OBRASCI.GIO2.UKUPNA_KOLICINA_ODLOZENOG')}`,
      dataIndex: 'ukupna_kolicina_ove_vrste_otpada',
      key: 'ukupna_kolicina_ove_vrste_otpada',
      width: '200px',
    },
    {
      title: `${t('COMPANY.ADDITIONALLY')}`,
      key: 'operation',
      fixed: 'right',
      width: '75px',
      align: 'center',
      render: (_, record: IGetKolicineGio2) => <EditGio2KolicineButton record={record} />,
    },
  ];

  return (
    <TableData
      scrollConfig={['#obrazacTableHeader', '#obrazacWizardHeader']}
      name="kolicine-gio2"
      columns={baseColumns}
      dataSource={[...gio2ObrazacStore.initialState.kolicine]}
    />
  );
});
