import { ColumnsType } from 'antd/es/table';
import { EditButtonProps, RowEditPreviewButton } from 'components/RowEditPreviewButton/RowEditPreviewButton';
import TableData from 'components/TableData/TableData';
import { observer } from 'mobx-react-lite';
import { obrasciStore } from 'modules/obrasci/obrasci.store';
import { kom1ObrazacStore } from 'modules/obrasci/store/kom1/kom1.store';
import { IGetUpravljanje } from 'modules/obrasci/store/kom1/kom1.types';
import { useTranslation } from 'react-i18next';

const EditUpravljanjeButton: React.FC<EditButtonProps<IGetUpravljanje>> = observer(({ record }) => {
  return (
    <RowEditPreviewButton
      name="kom1-upravljanje"
      modalName="upravljanje-modal"
      onClick={() => kom1ObrazacStore.handleChange('upravljanje_form', record)}
      preview={obrasciStore.zakljucanObrazac}
    />
  );
});
export const TableUpravljanje: React.FC = observer(() => {
  const { t } = useTranslation();

  const baseColumns: ColumnsType<IGetUpravljanje> = [
    {
      title: `${t('OBRASCI.KOM_1.MANAGEMENT.WASTE_INDEX_NUMBER_FROM_THE_WASTE_CATALOG')}`,
      width: '200px',
      // dataIndex: ['waste_catalogue', 'index_number'],
      key: 'index_number',
      render: (_: any, record: any) => (
        <>{`${record?.waste_catalogue?.index_number} - ${record?.waste_catalogue?.waste_sr?.toUpperCase()}`}</>
      ),
    },
    {
      title: `${t('OBRASCI.KOM_1.MANAGEMENT.THE_TOTAL_AMOUNT_OF_COLLECTED_OR_ACCEPTED_WASTE_IN_REPORTING_YEAR')}`,
      width: '200px',
      key: 'ukupna_kolicina_sakupljenog_ili_preuzetog_otpada_u_izvestajnoj_godini',
      dataIndex: 'ukupna_kolicina_sakupljenog_ili_preuzetog_otpada_u_izvestajnoj_godini',
    },
    {
      title: `${t('OBRASCI.KOM_1.MANAGEMENT.WAY_OF_DETERMINING_THE_QUANTITY')}`,
      width: '150px',
      dataIndex: ['method_basis_code', 'name_sr'],
      key: 'name',
    },
    {
      title: `${t('OBRASCI.KOM_1.MANAGEMENT.FROM_THE_PRIMARY_SELECTION')}`,
      width: '150px',
      dataIndex: 'iz_kontejnera_za_primarnu_selekciju',
      key: 'iz_kontejnera_za_primarnu_selekciju',
    },
    {
      title: `${t('OBRASCI.KOM_1.MANAGEMENT.FROM_RECYCLING_YARDS')}`,
      width: '150px',
      dataIndex: 'iz_reciklaznih_dvorista',
      key: 'iz_reciklaznih_dvorista',
    },
    {
      title: `${t('OBRASCI.KOM_1.MANAGEMENT.OTHER_COLLECTION_POINTS')}`,
      width: '150px',
      dataIndex: 'drugih_sabirnih_mesta',
      key: 'drugih_sabirnih_mesta',
    },
    // {
    //   title: `${t('OBRASCI.KOM_1.MANAGEMENT.WAY_OF_HAND_OVER')}`,
    //   width: 200,
    //   dataIndex: ['way_of_hand_over', 'name_sr'],
    //   key: 'name',
    // },
    // {
    //   title: `${t('OBRASCI.KOM_1.QUANTITY')}`,
    //   width: 200,
    //   dataIndex: 'kolicina',
    //   key: 'kolicina',
    // },
    // {
    //   title: `${t('OBRASCI.KOM_1.MANAGEMENT.D_OR_R_MARK')}`,
    //   width: 200,
    //   dataIndex: ['dr_list', 'name'],
    //   key: 'dr_list',
    // },
    // {
    //   title: `${t('OBRASCI.KOM_1.MANAGEMENT.NAME_AND_ADDRESS_OF_THE_OPERATOR')}`,
    //   width: 200,
    //   dataIndex: 'naziv_i_adresa_operatera',
    //   key: 'naziv_i_adresa_operatera',
    // },
    // {
    //   title: `${t('OBRASCI.KOM_1.MANAGEMENT.OPERATOR_LICENSE_NUMBER')}`,
    //   width: 200,
    //   dataIndex: 'broj_dozvole_operatera',
    //   key: 'broj_dozvole_operatera',
    // },
    {
      title: ``,
      key: 'operation',
      fixed: 'right',
      width: '70px',
      align: 'center',
      render: (_, record: IGetUpravljanje) => <EditUpravljanjeButton record={record} />,
    },
  ];

  return (
    <TableData
      scrollConfig={['#obrazacTableHeader', '#obrazacWizardHeader']}
      name="upravljanje-kom1"
      columns={baseColumns}
      dataSource={[...kom1ObrazacStore.initialState.upravljanje]}
    />
  );
});
