import { NewUserForm } from 'components/NewForms';
import { FacilityUsersModalTable } from 'components/Tables/FacilityUsersModalTable/FacilityUsersModalTable';
import { observer } from 'mobx-react-lite';
import { userStore } from 'modules/user/user.store';
import { AddFacilityUsers } from 'modules/user/user.types';
import { useEffect } from 'react';

export const FacilityAddUsers: React.FC = observer(() => {
  useEffect(() => {
    return () => {
      userStore.setAddFacilityUser(AddFacilityUsers.Existing);
      userStore.setUserTypeForm(null);
    };
  }, []);

  return (
    <>
      {userStore.addFacilityUsers === AddFacilityUsers.Existing ? (
        <FacilityUsersModalTable />
      ) : (
        <NewUserForm showButtons />
      )}
    </>
  );
});
