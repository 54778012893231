import { observer } from 'mobx-react-lite';
import { obrasciStore } from 'modules/obrasci/obrasci.store';
import { useEffect } from 'react';
import styles from './Aao2OpsteWizardTab.module.scss';
import { OpsteGodinaPreduzecePostrojenje } from 'pages/ObrazacWizard/components/components/OpsteGodinaPreduzecePostrojenje/OpsteGodinaPreduzecePostrojenje';
import { Col } from 'antd';
import { FormInput } from 'components/FormInput/FormInput';
import { FormDatePicker } from 'components/FormDatePicker/FormDatePicker';
import { REQUIRED_FIELD_RULE } from 'modules/nriz/nriz.constants';
import { t } from 'i18next';
import { aao2Store } from 'modules/obrasci/store/aao2/aao2.store';
import { disabledTodayAndFutureDates } from 'utils/formatDate';
import { useObrazacExistsCheck } from 'hooks/useObrazacExistsCheck';

export const Aao2OpsteWizardTab: React.FC = observer(() => {
  const checkIfObrazacExists = useObrazacExistsCheck();

  useEffect(() => {
    if (
      obrasciStore.opste.godina !== '' &&
      obrasciStore.opste.preduzece_id !== '' &&
      aao2Store.getInitialState.datum_izdavanja !== ''
    ) {
      obrasciStore.setDisabledTabButton(false);
    } else obrasciStore.setDisabledTabButton(true);
  }, [obrasciStore.opste, aao2Store.initialState.broj_dozvole, aao2Store.initialState.datum_izdavanja]);

  return (
    <Col span={12} className={styles.wrapper}>
      <OpsteGodinaPreduzecePostrojenje
        withPostrojenje={false}
        store={aao2Store}
        onChangeGodina={async () => {
          const obrazacExists = await checkIfObrazacExists();
          if (!obrazacExists) aao2Store.fetchOldPravnoLiceList();
        }}
      />
      <FormInput
        name={'broj_dozvole'}
        inputName={'broj_dozvole'}
        label={t('OBRASCI.AAO1.MANAGEMENT_OF_PACKAGING_WASTE.PERMIT_NUMBER')}
        type="text"
        labelCol={{ span: 9 }}
        wrapperCol={{ span: 15 }}
        disabled={
          obrasciStore.opste.godina === '' || obrasciStore.opste.preduzece_id === '' || obrasciStore.zakljucanObrazac
        }
        placeholder={t('OBRASCI.AAO1.MANAGEMENT_OF_PACKAGING_WASTE.PLACEHOLDERS.PERMIT_NUMBER')}
        rules={[REQUIRED_FIELD_RULE(true)]}
      />
      <FormDatePicker
        name="datum_izdavanja"
        label="Datum izdavanja"
        placeholder={t('OBRASCI.AAO1.MANAGEMENT_OF_PACKAGING_WASTE.PLACEHOLDERS.DATE_OF_ISSUE')}
        labelCol={{ span: 9 }}
        wrapperCol={{ span: 15 }}
        format="DD-MM-YYYY"
        picker={'date'}
        rules={[REQUIRED_FIELD_RULE(true)]}
        disabledDate={disabledTodayAndFutureDates}
        onChange={value => aao2Store.handleInitialStateChange('datum_izdavanja', value)}
        disabled={
          obrasciStore.opste.godina === '' || obrasciStore.opste.preduzece_id === '' || obrasciStore.zakljucanObrazac
        }
      />
    </Col>
  );
});
