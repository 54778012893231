import { FormInputNumber } from 'components/FormInputNumber/FormInputNumber';
import { FormSearchInfiniteScroll } from 'components/FormSearchInfiniteScroll/FormSearchInfiniteScroll';
import { observer } from 'mobx-react-lite';
import {
  MORE_THEN_0_IS_REQUIRED,
  PERCENTAGE_MORE_THAN_0_REQUIRED,
  REQUIRED_FIELD_RULE,
} from 'modules/nriz/nriz.constants';
import { pzv1ObrazacStore } from 'modules/obrasci/store/pzv1/pzv1.store';
import { IGetDjubrivo } from 'modules/obrasci/store/pzv1/pzv1.types';
import { ISifarnikInitial } from 'modules/sifarnici/sifarnici.types';
import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';

export const NewDjubrivaForm: React.FC = observer(() => {
  const { t } = useTranslation();

  const defaultDjubrivoOption: ISifarnikInitial | undefined = pzv1ObrazacStore.djubrivo.products_pzv_id
    ? {
        label: (pzv1ObrazacStore.djubrivo as IGetDjubrivo).products_pzv.name,
        value: pzv1ObrazacStore.djubrivo.products_pzv_id,
      }
    : undefined;

  const readOnly = pzv1ObrazacStore.readOnly;

  useEffect(() => {
    return () => pzv1ObrazacStore.clearReadOnly();
  }, []);

  return (
    <>
      <FormSearchInfiniteScroll
        showSearch={true}
        label={`${t('OBRASCI.PZV_1.FERTILIZERS.TYPE')}`}
        labelCol={{ span: 10 }}
        formName={'products_pzv_id'}
        inputName={'products_pzv_id'}
        labelOptionAccessor={'name'}
        valueOptionAccessor={'id'}
        defaultOption={defaultDjubrivoOption}
        fetchOptinsList={pzv1ObrazacStore.fetchDjubrivoOptions}
        placeholder={t('OBRASCI.PZV_1.FERTILIZERS.PLACEHOLDERS.TYPE')}
        notFoundContent={t('OBRASCI.PZV_1.NOT_FOUND')}
        rules={[
          REQUIRED_FIELD_RULE(true),
          {
            validator(_, value) {
              if (
                pzv1ObrazacStore.djubrivo.products_pzv_id !== value &&
                pzv1ObrazacStore.initialState.mineralna_djubriva.some(item => {
                  return value === item.products_pzv_id;
                })
              ) {
                return Promise.reject(new Error(t('OBRASCI.PZV_1.FERTILIZERS.ERROR.TYPE')));
              }
              return Promise.resolve();
            },
          },
        ]}
        readOnly={readOnly}
      />
      <FormInputNumber
        label={`${t('OBRASCI.PZV_1.FERTILIZERS.CONTENT')}`}
        labelCol={{ span: 10 }}
        inputName={'sadrzaj_azota'}
        name={'sadrzaj_azota'}
        placeholder={t('OBRASCI.PZV_1.FERTILIZERS.PLACEHOLDERS.CONTENT')}
        rules={PERCENTAGE_MORE_THAN_0_REQUIRED}
        readOnly={readOnly}
      />
      <FormInputNumber
        label={`${t('OBRASCI.PZV_1.FERTILIZERS.QUANTITY')}`}
        name={'kolicina'}
        labelCol={{ span: 10 }}
        inputName={'kolicina'}
        placeholder={t('OBRASCI.PZV_1.FERTILIZERS.PLACEHOLDERS.QUANTITY')}
        rules={MORE_THEN_0_IS_REQUIRED}
        readOnly={readOnly}
      />
    </>
  );
});
