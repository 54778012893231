import { EditOutlined } from '@ant-design/icons';
import { ColumnsType } from 'antd/es/table';
import { Button } from 'components/Button/Button';
import { YesNoCell } from 'components/CallRenderers/YesNoCell/YesNoCell';
import TableData from 'components/TableData/TableData';
import { observer } from 'mobx-react-lite';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { modalStore, authStore } from 'store';
import { IGetActivity1 } from 'modules/activityList1/activityList1.types';
import { activityList1Store } from 'modules/activityList1/activityList1.store';
import { activityList2Store } from 'modules/activityList2/activityList2.store';

export const ActivityList1Table: React.FC = observer(() => {
  const { t } = useTranslation();

  const baseColumns: ColumnsType<IGetActivity1> = [
    {
      title: `${t('ACTIVITIES.ECONOMIC_ACTIVITY')}`,
      width: '200px',
      key: `privredna_delatnost1_id`,
      render: (_, record: IGetActivity1) => {
        return <p>{`${record.privredna_delatnost1.code}. ${record.privredna_delatnost1.name_sr}`}</p>;
      },
    },
    {
      title: `${t('ACTIVITIES.ACTIVITY')}`,
      width: '400px',
      key: `aktivnost1_id`,
      render: (_, record: IGetActivity1) => {
        return <p>{`${record.aktivnost1.code} ${record.aktivnost1.name_sr}`}</p>;
      },
    },
    {
      title: `${t('ACTIVITIES.MAIN')}`,
      width: '100px',

      key: `glavna_aktivnost`,
      render: (_, record) => {
        return <YesNoCell yesNo={record.glavna_aktivnost} />;
      },
    },
  ];
  const adminColumns: ColumnsType<IGetActivity1> = [
    ...baseColumns,
    {
      title: '',
      key: `operation_1`,
      fixed: 'right',
      width: '50px',
      align: 'center',
      render: (_, record: IGetActivity1) => {
        return (
          <Button
            icon={<EditOutlined />}
            name={'edit-activity'}
            disabled={activityList2Store.activityList.length !== 0}
            onClick={() => {
              activityList1Store.setActivity(record);
              activityList1Store.handleChange('privredna_delatnost_id', record.privredna_delatnost_id);
              modalStore.changeModalName(`activity-list-modal-1`);
            }}
          />
        );
      },
    },
  ];
  let validColumns = adminColumns;

  authStore.isAdmin || authStore.isCompanyOwner ? (validColumns = adminColumns) : (validColumns = baseColumns);

  return (
    <TableData
      name="activity1"
      columns={validColumns}
      dataSource={activityList1Store.activityList}
      refetchData={activityList1Store.fetchActivityList}
      storePagination={activityList1Store.activityList1Pagination}
    />
  );
});
