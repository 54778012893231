import { observer } from 'mobx-react-lite';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { FormSearchInfiniteScroll } from 'components/FormSearchInfiniteScroll/FormSearchInfiniteScroll';

import { FormDatePicker } from 'components/FormDatePicker/FormDatePicker';
import { dep1Store } from 'modules/obrasci/store/dep1/dep1.store';
import { REQUIRED_FIELD_RULE } from 'modules/nriz/nriz.constants';
import { IGetDep1 } from 'modules/obrasci/store/dep1/dep1.types';
import { ISifarnikInitial } from 'modules/sifarnici/sifarnici.types';
import { Col, Form, Row, Select } from 'antd';
import { ICrudTypeModalProps } from 'components/Modals/CRUDModal/CRUDModal';
import { resetFormFields } from 'utils/resetFormFieldValues';
import { FormSwitch } from 'components/FormSwitch/FormSwitch';

export const NewDep1ProjekatSanacijeForm: React.FC<ICrudTypeModalProps> = observer(props => {
  const { t } = useTranslation();
  const [projekatSanacije, setProjekatSanacije] = useState(false);
  const [izvodjenjePoProjektuSanacije, setIzvodjenjePoProjektuSanacije] = useState<'da' | 'ne' | 'delimicno'>('ne');
  const [pribavljenaSaglasnost, setPribavljenaSaglasnost] = useState(false);

  const form = props.form;

  const defaultRadoviKojiSeIzvodePoProjektu: ISifarnikInitial | undefined = dep1Store.initialState
    .radovi_koji_se_izvode_po_projektu_id
    ? {
        label: (dep1Store.getInitialState as IGetDep1).radovi_koji_se_izvode_po_projektu.answer_sr,
        value: (dep1Store.getInitialState as IGetDep1).radovi_koji_se_izvode_po_projektu_id,
      }
    : undefined;

  const defaultSprovodjenjeMeraZastite: ISifarnikInitial | undefined = dep1Store.initialState
    .sprovodjenje_mera_zastite_zivotne_sredine_propisane_projektom_id
    ? {
        label: (dep1Store.getInitialState as IGetDep1).sprovodjenje_mera_zastite_zivotne_sredine_propisane_projektom
          .answer_sr,
        value: (dep1Store.getInitialState as IGetDep1).sprovodjenje_mera_zastite_zivotne_sredine_propisane_projektom_id,
      }
    : undefined;

  const defaultSprovodjenjeMonitoringa: ISifarnikInitial | undefined = dep1Store.initialState
    .sprovodjenje_monitoringa_propisanog_projektom_id
    ? {
        label: (dep1Store.getInitialState as IGetDep1).sprovodjenje_monitoringa_propisanog_projektom.answer_sr,
        value: (dep1Store.getInitialState as IGetDep1).sprovodjenje_monitoringa_propisanog_projektom_id,
      }
    : undefined;

  const defaultPotrebnoAzuriranje: ISifarnikInitial | undefined = dep1Store.initialState
    .potrebna_izrada_ili_azuriranje_projekta_id
    ? {
        label: (dep1Store.getInitialState as IGetDep1).potrebna_izrada_ili_azuriranje_projekta.answer_sr,
        value: (dep1Store.getInitialState as IGetDep1).potrebna_izrada_ili_azuriranje_projekta_id,
      }
    : undefined;

  useEffect(() => {
    setProjekatSanacije(form.getFieldValue('izradjen_projekat_sanacije_zatvaranja_i_rekultivacije'));
  }, [Form.useWatch('izradjen_projekat_sanacije_zatvaranja_i_rekultivacije')]);

  useEffect(() => {
    setIzvodjenjePoProjektuSanacije(
      form.getFieldValue('izvodjenje_radova_po_projektu_sanacije_zatvaranja_i_rekultivacije')
    );
  }, [Form.useWatch('izvodjenje_radova_po_projektu_sanacije_zatvaranja_i_rekultivacije')]);

  useEffect(() => {
    setPribavljenaSaglasnost(form.getFieldValue('pribavljena_saglasnost_na_projekat'));
  }, [Form.useWatch('pribavljena_saglasnost_na_projekat')]);

  return (
    <>
      <h4 style={{ fontWeight: 'bold' }}>{t('OBRASCI.DEP1.PANELI.PROJEKAT_SANACIJE')}</h4>

      <FormSwitch
        label={t('OBRASCI.DEP1.DA_LI_JE_IZRADJEN_PROJEKAT_SANACIJE_ZATVARANJA')}
        name="izradjen_projekat_sanacije_zatvaranja_i_rekultivacije"
        labelCol={{ span: 16 }}
        wrapperCol={{ span: 8 }}
        colon={false}
        onSwitchChange={() => {
          const resetValues = [
            'godina_izrade_projekta_sanacije',
            'pribavljena_saglasnost_na_projekat',
            'godina_pribavljanja_saglasnosti_na_projekat',
            'izvodjenje_radova_po_projektu_sanacije_zatvaranja_i_rekultivacije',
            'radovi_koji_se_izvode_po_projektu_id',
            'sprovodjenje_mera_zastite_zivotne_sredine_propisane_projektom_id',
            'sprovodjenje_monitoringa_propisanog_projektom_id',
            'potrebna_izrada_ili_azuriranje_projekta_id',
          ];

          form.setFieldValue('godina_izrade_projekta_sanacije', null);
          resetFormFields(form, resetValues);
        }}
      />

      {projekatSanacije && (
        <>
          <FormDatePicker
            name="godina_izrade_projekta_sanacije"
            label={t('OBRASCI.DEP1.KOJE_GODINE')}
            placeholder={t('SELECT_YEAR')}
            labelCol={{ span: 16 }}
            wrapperCol={{ span: 8 }}
            format="YYYY"
            colon={false}
            picker={'year' as any}
            rules={[REQUIRED_FIELD_RULE(true)]}
          />
          <FormSwitch
            label={t('OBRASCI.DEP1.DA_LI_JE_PRIBAVLJENA_SAGLASNOST_PROJEKAT_SANACIJE_ZATVARANJA')}
            name="pribavljena_saglasnost_na_projekat"
            labelCol={{ span: 16 }}
            wrapperCol={{ span: 8 }}
            colon={false}
            onSwitchChange={() => {
              form.setFieldValue('godina_pribavljanja_saglasnosti_na_projekat', null);
            }}
            rules={[REQUIRED_FIELD_RULE(true)]}
          />
          {pribavljenaSaglasnost && (
            <FormDatePicker
              name="godina_pribavljanja_saglasnosti_na_projekat"
              label={t('OBRASCI.DEP1.KOJE_GODINE')}
              labelCol={{ span: 16 }}
              wrapperCol={{ span: 8 }}
              picker={'year' as any}
              placeholder={t('SELECT_YEAR')}
              format="YYYY"
              colon={false}
              rules={[REQUIRED_FIELD_RULE(true)]}
            />
          )}

          <Row>
            <Col span={24}>
              <Form.Item
                labelCol={{ span: 16 }}
                wrapperCol={{ span: 8 }}
                name={'izvodjenje_radova_po_projektu_sanacije_zatvaranja_i_rekultivacije'}
                label={t('OBRASCI.DEP1.DA_LI_SE_IZVODE_RADOVI_PO_PROJEKTU_SANACIJE_ZATVARANJA')}
                rules={[REQUIRED_FIELD_RULE(true)]}
                colon={false}
              >
                <Select
                  onChange={(value: string) => {
                    dep1Store.handleInitialStateChange(
                      'izvodjenje_radova_po_projektu_sanacije_zatvaranja_i_rekultivacije',
                      value
                    );
                    form.setFieldValue('radovi_koji_se_izvode_po_projektu_id', '');
                  }}
                  options={[
                    { value: 'da', label: t('YES') },
                    { value: 'ne', label: t('NO') },
                    { value: 'delimicno', label: t('OBRASCI.DEP1.DELIMICNO') },
                  ]}
                />
              </Form.Item>
            </Col>
          </Row>

          {(izvodjenjePoProjektuSanacije !== 'ne' ?? false) && (
            <FormSearchInfiniteScroll
              showSearch={true}
              formName={'radovi_koji_se_izvode_po_projektu_id'}
              fetchOptinsList={dep1Store.fetchDepFormAnswersPitanje9}
              labelOptionAccessor="answer_sr"
              valueOptionAccessor="id"
              label={t('OBRASCI.DEP1.KOJI_RADOVI_SE_IZVODE_PO_PROJEKTU_SANACIJE_ZATVARANJA')}
              readOnly={false}
              labelCol={{ span: 16 }}
              wrapperCol={{ span: 8 }}
              inputName={'radovi_koji_se_izvode_po_projektu_id'}
              defaultOption={defaultRadoviKojiSeIzvodePoProjektu}
              placeholder={t('OBRASCI.DEP1.KOJI_RADOVI_SE_IZVODE_PO_PROJEKTU_SANACIJE_ZATVARANJA')}
              rules={[REQUIRED_FIELD_RULE(true)]}
              colon={false}
            />
          )}
          <FormSearchInfiniteScroll
            formName={'sprovodjenje_mera_zastite_zivotne_sredine_propisane_projektom_id'}
            fetchOptinsList={dep1Store.fetchDepFormAnswersPitanje5And10}
            labelOptionAccessor="answer_sr"
            valueOptionAccessor="id"
            showSearch
            label={t('OBRASCI.DEP1.DA_LI_SE_SPROVODE_MERE_ZASTITE_ZIVOTNE_SREDINE')}
            readOnly={false}
            labelCol={{ span: 16 }}
            wrapperCol={{ span: 8 }}
            inputName={'sprovodjenje_mera_zastite_zivotne_sredine_propisane_projektom_id'}
            defaultOption={defaultSprovodjenjeMeraZastite}
            placeholder={t('OBRASCI.DEP1.DA_LI_SE_SPROVODE_MERE_ZASTITE_ZIVOTNE_SREDINE')}
            rules={[REQUIRED_FIELD_RULE(true)]}
            colon={false}
          />

          <FormSearchInfiniteScroll
            formName={'sprovodjenje_monitoringa_propisanog_projektom_id'}
            fetchOptinsList={dep1Store.fetchDepFormAnswersPitanje11}
            labelOptionAccessor="answer_sr"
            valueOptionAccessor="id"
            showSearch
            label={t('OBRASCI.DEP1.DA_LI_SE_SPROVODI_MONITORING')}
            readOnly={false}
            labelCol={{ span: 16 }}
            wrapperCol={{ span: 8 }}
            inputName={'sprovodjenje_monitoringa_propisanog_projektom_id'}
            defaultOption={defaultSprovodjenjeMonitoringa}
            placeholder={t('OBRASCI.DEP1.DA_LI_SE_SPROVODI_MONITORING')}
            rules={[REQUIRED_FIELD_RULE(true)]}
            colon={false}
          />

          <FormSearchInfiniteScroll
            formName={'potrebna_izrada_ili_azuriranje_projekta_id'}
            fetchOptinsList={dep1Store.fetchDepFormAnswersPitanje7And8And12}
            labelOptionAccessor="answer_sr"
            valueOptionAccessor="id"
            showSearch
            label={t('OBRASCI.DEP1.DA_LI_JE_POTREBNA_IZRADA_NOVOG_ILI_AZURIRANJE')}
            readOnly={false}
            labelCol={{ span: 20 }}
            wrapperCol={{ span: 4 }}
            inputName={'potrebna_izrada_ili_azuriranje_projekta_id'}
            defaultOption={defaultPotrebnoAzuriranje}
            placeholder={t('OBRASCI.DEP1.DA_LI_JE_POTREBNA_IZRADA_NOVOG_ILI_AZURIRANJE')}
            rules={[REQUIRED_FIELD_RULE(true)]}
            colon={false}
          />
        </>
      )}
    </>
  );
});
