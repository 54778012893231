import { makeAutoObservable } from 'mobx';
import { otvService } from './otv.service';
import { IOTVRegistar, OtvOpsteInfo } from './otv.types';
import { IPagination } from 'typescript';
import { nrizService } from 'modules/nriz/nriz.service';
import { filtersStore } from 'store/filteri.store';
import { IGetCompany } from 'modules/company/company.types';
import { SIFARNICI_ROUTES } from 'modules/sifarnici/sifarnici.types';
import { sifarniciStore } from 'modules/sifarnici/sifarnici.store';
import { IGetFacility } from 'modules/facility/facility.types';
import { OTV_OPSTE_INITIAL } from './otv.constants';
import { ITablePagination } from 'components/TableData/TableData';
import { IPaginatedResponse } from 'typescript/NrizTypes';
import { IGetWater } from 'modules/sourcesOfPollution/water/water.types';
import { IGetPollutantCode } from 'modules/sifarnici/store/pollutantCode/pollutantCode.types';
import { paginationInitialState } from 'modules/nriz/nriz.constants';
import { IBaseRegistriStore } from '../registri.types';

class OTVStore implements IBaseRegistriStore<IOTVRegistar> {
  dataSource: IOTVRegistar[] = [];
  otvRegistar: IOTVRegistar | undefined = undefined;

  otvOpsteInfo: OtvOpsteInfo = OTV_OPSTE_INITIAL;
  pagination: IPagination = paginationInitialState;
  filters: any = {};

  constructor() {
    makeAutoObservable(this);
  }

  handleOtvOpsteChange = (key: keyof OtvOpsteInfo, value: string) => {
    this.otvOpsteInfo = { ...this.otvOpsteInfo, [key]: value };
  };

  setOtvOpste = (opsteInfo: OtvOpsteInfo) => {
    this.otvOpsteInfo = opsteInfo;
  };

  setOtvList = (newOtvList: IOTVRegistar[]) => {
    this.dataSource = newOtvList;
  };

  setOtvRegistar = (newRegistarValue: IOTVRegistar | undefined) => {
    this.otvRegistar = newRegistarValue;
  };

  setFilters = (newFilters: any) => {
    this.filters = { ...this.filters, ...newFilters };
  };

  setPagination = (pagination: IPagination) => {
    this.pagination = pagination;
  };

  fetchDataList = async (pagination?: ITablePagination) => {
    const data = await otvService.fetchOtvList({
      ...pagination,
      ...nrizService.pickFields(filtersStore.filters.registri),
    });
    this.setOtvList(data.items);
    const { page, size, total, pages } = data;
    this.setPagination({ page, size, total, pages });
    return data;
  };

  resetStates = () => {
    this.setOtvRegistar(undefined);
    this.setOtvOpste(OTV_OPSTE_INITIAL);
  };

  loadData = async (id: string) => {
    const otvRegistar = await otvService.fetchOtvById(id);
    this.setOtvRegistar(otvRegistar);
    if (!otvRegistar) return;
    const { preduzece_id, postrojenje_id, izvor_zagadjenja_vode_id } = otvRegistar;
    this.setOtvOpste({
      preduzece_id,
      postrojenje_id,
      izvor_zagadjenja_vode_id,
    });
    return otvRegistar;
  };

  fetchPreduzecaOtv = async (pagination: IPagination, search: string): Promise<IPaginatedResponse<IGetCompany>> => {
    const response = await sifarniciStore.fetchSifarnikOptions<IGetCompany>(SIFARNICI_ROUTES.PREDUZECE, {
      page: pagination.page,
      size: pagination.size,
      search: search,
    });

    return response;
  };

  fetchPostrojenjaOtv = async (pagination: IPagination, search: string): Promise<IPaginatedResponse<IGetFacility>> => {
    const response = await sifarniciStore.fetchSifarnikOptions<IGetFacility>(SIFARNICI_ROUTES.POSTROJENJE, {
      preduzece_id: this.otvOpsteInfo.preduzece_id,
      page: pagination.page,
      size: pagination.size,
      search: search,
    });

    return response;
  };

  fetchIzvoriZagadjenjaVodeOtv = async (
    pagination: IPagination,
    search: string
  ): Promise<IPaginatedResponse<IGetWater>> => {
    const response = await sifarniciStore.fetchSifarnikOptions<IGetWater>(SIFARNICI_ROUTES.IZVOR_ZAGADJENJA_VODE, {
      postrojenje_id: this.otvOpsteInfo.postrojenje_id,
      page: pagination.page,
      size: pagination.size,
      search: search,
    });

    return response;
  };

  fetchPollutantCode = async (
    pagination: IPagination,
    search: string
  ): Promise<IPaginatedResponse<IGetPollutantCode>> => {
    const response = await sifarniciStore.fetchSifarnikOptions<IGetPollutantCode>(SIFARNICI_ROUTES.POLLUTANT_CODE, {
      page: pagination.page,
      size: pagination.size,
      search: search,
      code: 'WATER',
      order_by: '+name_sr',
    });

    return response;
  };

  postData = async (payload: IOTVRegistar) => {
    return await otvService.postOtv(payload);
  };

  putData = async (payload: IOTVRegistar) => {
    return await otvService.updateOtv(payload);
  };
}
export const otvStore = new OTVStore();
