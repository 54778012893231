import { userRepo } from './user.repo';
import { IGetUser, IPostUser, IPostUserResponsibility } from './user.types';
import { cloneDeep } from 'lodash';
import { ApiResponse } from 'modules/axios';
import { IEmptyResponse, IPaginatedApiResponse, IPostResponse } from 'typescript/NrizTypes';
import { ITablePagination } from 'components/TableData/TableData';

class UserService {
  fetchUserList = (payload: ITablePagination): IPaginatedApiResponse<IGetUser> => {
    return userRepo.fetchUserList(payload);
  };

  fetchUserCompanyList = (payload: any): IPaginatedApiResponse<IGetUser> => {
    return userRepo.fetchUserCompanyList(payload);
  };

  fetchUserFacilityList = (payload: any): IPaginatedApiResponse<IGetUser> => {
    return userRepo.fetchUserFacilityList(payload);
  };

  exportUsersListExcel = (filters: any) => {
    return userRepo.exportUsersListExcel(filters);
  };

  fetchUser = (id: string): ApiResponse<IGetUser> => {
    return userRepo.fetchUser(id);
  };

  putUserResponsible = (id: string, facilityId: { postrojenje_id: string }): ApiResponse<IEmptyResponse> => {
    return userRepo.putUserResponsible(id, facilityId);
  };

  putUser = (user: IGetUser): ApiResponse<IEmptyResponse> => {
    return userRepo.putUser(user);
  };

  postUser = (user: Partial<IPostUser>): ApiResponse<IPostResponse> => {
    return userRepo.postUser(user);
  };

  postUserRegistration = (user: IPostUser): ApiResponse<IPostResponse> => {
    return userRepo.postUserRegistration(user);
  };

  postUserFacility = (userID: string, payload: any): ApiResponse<IEmptyResponse> => {
    return userRepo.postUserFacility(userID, payload);
  };

  postUserAdministrator = (user: IPostUser): ApiResponse<IPostResponse> => {
    return userRepo.postUserAdministrator(user);
  };

  deleteUser = (id: string): ApiResponse<IEmptyResponse> => {
    return userRepo.deleteUser(id);
  };
  deleteFacilityUser = (id: string, facilityId: string): ApiResponse<IEmptyResponse> => {
    return userRepo.deleteFacilityUser(id, facilityId);
  };

  aktivirajKorisnika = (id: string): ApiResponse<IEmptyResponse> => {
    return userRepo.aktivirajKorisnika(id);
  };
  deaktivirajKorisnika = (id: string): ApiResponse<IEmptyResponse> => {
    return userRepo.deaktivirajKorisnika(id);
  };
  putUserCompanyResponsibility = (id: string, obj: IPostUserResponsibility): ApiResponse<IEmptyResponse> => {
    return userRepo.putUserCompanyResponsibility(id, obj);
  };
  changeCompanyOwner = (id: string): ApiResponse<IEmptyResponse> => {
    return userRepo.changeCompanyOwner(id);
  };

  toggleBooleanToFalseInList = (items: any[], key: string) => {
    return items.map((item: any) => {
      return cloneDeep({ ...item, [key]: false });
    });
  };

  deactivateUserList = (items: IGetUser[]): IGetUser[] => {
    return items.map((item: IGetUser) => {
      if (item.main_company_user) {
        return item;
      }
      return { ...item, is_active: false };
    });
  };
}

export const userService = new UserService();
