import { ColumnsType } from 'antd/es/table';
import TableData from 'components/TableData/TableData';
import { observer } from 'mobx-react-lite';
import { useTranslation } from 'react-i18next';
import dayjs from 'dayjs';
import { gio3ObrazacStore } from 'modules/obrasci/store/gio3/gio3.store';
import { IGetDozvoleGio3 } from 'modules/obrasci/store/gio3/gio3.types';
import { Checkbox } from 'antd';
import { EditButtonProps, RowEditPreviewButton } from 'components/RowEditPreviewButton/RowEditPreviewButton';
import { obrasciStore } from 'modules/obrasci/obrasci.store';

const EditGio3DozvoleButton: React.FC<EditButtonProps<IGetDozvoleGio3>> = observer(({ record }) => {
  return (
    <RowEditPreviewButton
      name="gio3-dozvole"
      modalName="gio3-dozvole-modal"
      onClick={() => gio3ObrazacStore.handleChange('dozvole_form', record)}
      preview={obrasciStore.zakljucanObrazac}
    />
  );
});

export const Gio3TableDozvole: React.FC = observer(() => {
  const { t } = useTranslation();

  const baseColumns: ColumnsType<IGetDozvoleGio3> = [
    {
      title: `${t(['OBRASCI.GIO2.BROJ_DOZVOLE'])}`,
      width: 100,
      dataIndex: 'broj_dozvole',
      key: 'broj_dozvole',
    },
    {
      title: `${t('OBRASCI.GIO2.DATUM_IZDAVANJA')}`,
      width: 100,
      render: (_, record) => <span>{dayjs(record.datum_izdavanja).format('DD. MM. YYYY.')}</span>,
      key: 'datum_izdavanja',
    },
    {
      title: `${t('OBRASCI.GIO2.DATUM_ISTEKA')}`,
      key: 'datum_isteka',
      width: 100,
      render: (_, record) => <span>{dayjs(record.datum_isteka).format('DD. MM. YYYY.')}</span>,
    },
    {
      title: `${t('OBRASCI.GIO2.SAKUPLJANJE')}`,
      render: (_, record) => <Checkbox checked={!!record.sakupljanje} disabled={true} />,
      key: 'sakupljanje',
      width: 100,
      align: 'center',
    },
    {
      title: `${t('OBRASCI.GIO2.TRANSPORT')}`,
      render: (_, record) => <Checkbox checked={!!record.transport} disabled={true} />,
      key: 'transport',
      width: 100,
      align: 'center',
    },
    {
      title: `${t('OBRASCI.GIO2.SKLADISTENJE')}`,
      render: (_, record) => <Checkbox checked={!!record.skladistenje} disabled={true} />,
      key: 'skladistenje',
      width: 100,
      align: 'center',
    },
    {
      title: `${t('OBRASCI.GIO2.TRETMAN')}`,
      render: (_, record) => <Checkbox checked={!!record.tretman} disabled={true} />,
      key: 'tretman',
      width: 100,
      align: 'center',
    },
    {
      title: `${t('OBRASCI.GIO2.ODLAGANJE')}`,
      render: (_, record) => <Checkbox checked={!!record.odlaganje} disabled={true} />,
      key: 'odlaganje',
      width: 100,
      align: 'center',
    },
    {
      title: `${t('COMPANY.ADDITIONALLY')}`,
      key: 'operation',
      fixed: 'right',
      width: '100px',
      align: 'center',
      render: (_, record: IGetDozvoleGio3) => <EditGio3DozvoleButton record={record} />,
    },
  ];

  return (
    <TableData
      name="dozvole-gio3"
      scrollConfig={['#obrazacTableHeader', '#obrazacWizardHeader']}
      columns={baseColumns}
      dataSource={[...gio3ObrazacStore.initialState.dozvole]}
    />
  );
});
