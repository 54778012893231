import { ApiResponse } from 'modules/axios';
import { airRepo } from './air.repo';
import { IPostAir, IGetAir } from './air.types';
import { IEmptyResponse, IPaginatedApiResponse, IPostResponse } from 'typescript/NrizTypes';

class AirService {
  fetchAirList = (payload: any): IPaginatedApiResponse<IGetAir> => {
    return airRepo.fetchAirList(payload);
  };

  postAir = (payload: IPostAir): ApiResponse<IPostResponse> => {
    return airRepo.postAir(payload);
  };

  putAir = (payload: IGetAir): ApiResponse<IEmptyResponse> => {
    return airRepo.putAir(payload);
  };

  aktivirajVazduh = (id: string): ApiResponse<IEmptyResponse> => {
    return airRepo.aktivirajVazduh(id);
  };
  deaktivirajVazduh = (id: string): ApiResponse<IEmptyResponse> => {
    return airRepo.deaktivirajVazduh(id);
  };
}

export const airService = new AirService();
