import { makeAutoObservable } from 'mobx';
import { IPagination } from 'typescript';
import { facilityStore } from 'modules/facility/facility.store';
import { nrizService } from 'modules/nriz/nriz.service';
import to from 'await-to-js';
import { formStore } from 'store/FormStore';
import { modalStore } from 'store/ModalStore';
import { handleSuccessNotify } from 'utils/succesHandling';
import { SIFARNICI_ROUTES } from 'modules/sifarnici/sifarnici.types';
import { sifarniciStore } from 'modules/sifarnici/sifarnici.store';
import { airInitialState } from './air.constants';
import { IGetAir, IPostAir } from 'modules/sourcesOfPollution/air/air.types';
import { IGetFacility } from 'modules/facility/facility.types';
import { airService } from './air.service';
import { IGetSourceType } from 'modules/sifarnici/store/sourceType/sourceType.types';
import { authStore } from 'store';
import { omit } from 'lodash';
import { filtersStore } from 'store/filteri.store';
import { IEmptyResponse, IPaginatedResponse, IPostResponse } from 'typescript/NrizTypes';
import { paginationInitialState } from 'modules/nriz/nriz.constants';
import { ITablePagination } from 'components/TableData/TableData';

class AirStore {
  constructor() {
    makeAutoObservable(this);
  }

  air: IPostAir | IGetAir = airInitialState;
  airList: IGetAir[] = [];
  airListPagination: IPagination = paginationInitialState;

  get getAir(): IGetAir {
    return this.air as IGetAir;
  }

  get getAirList(): IGetAir[] {
    return this.airList;
  }

  handleChange = (type: keyof AirStore, value: any) => {
    (this as any)[type] = value;
  };

  setLockCoordinates = () => {
    if (!this.air) return;
    this.air.zakljucane_koordinate = !this.air?.zakljucane_koordinate;
  };

  setAir(air: IGetAir | IPostAir) {
    this.air = air;
  }

  setAirList = (values: IGetAir[]) => {
    this.airList = [...values];
  };

  postAir = async (payload: IPostAir) => {
    const newAir = { ...payload, postrojenje_id: (facilityStore.getFacility as IGetFacility).id };
    const [err, res] = await to<IPostResponse>(airService.postAir(nrizService.setEmptyValuesToNull(newAir)));
    if (err || !res) return Promise.reject();
    const { id } = res;
    let sifarnikVrstaIzvora = {};
    if (newAir.source_type_id) {
      sifarnikVrstaIzvora = nrizService.addSifarnikInObject({
        list: sifarniciStore.list[SIFARNICI_ROUTES.SOURCE_TYPE],
        name: 'source_type',
        id: newAir.source_type_id,
      });
    }
    const newObjectWithSifarnik = { ...newAir, aktivan: true, id, ...sifarnikVrstaIzvora } as IGetAir;
    this.setAirList([newObjectWithSifarnik, ...this.airList]);
    this.handleChange('airListPagination', nrizService.increaseTotalPagination(this.airListPagination));
    this.setAir(airInitialState);
    handleSuccessNotify();
    modalStore.removeAllModals();
  };

  putAir = async (air: IGetAir) => {
    const [err, res] = await to<IEmptyResponse>(airService.putAir(nrizService.setEmptyValuesToNull(air)));
    if (err || !res) return;
    let sifarnikVrstaIzvora = {};

    if (air.source_type_id) {
      sifarnikVrstaIzvora = nrizService.addSifarnikInObject({
        exId: this.air.source_type_id,
        exObj: (this.air as IGetAir).source_type,
        list: sifarniciStore.list[SIFARNICI_ROUTES.SOURCE_TYPE],
        name: 'source_type',
        id: air.source_type_id,
      });
    }
    const newAir = { ...air, ...sifarnikVrstaIzvora };
    this.setAirList(nrizService.changeItemInList(this.airList, newAir));
    this.setAir(airInitialState);
    handleSuccessNotify();

    modalStore.removeAllModals();
  };

  fetchAirList = async (pagination?: ITablePagination) => {
    const [err, res] = await to<IPaginatedResponse<IGetAir>>(
      airService.fetchAirList({
        page: pagination?.page || this.airListPagination.page,
        size: pagination?.size || this.airListPagination.size,
        postrojenje_id: (facilityStore.facility as IGetFacility).id,
        ...nrizService.pickFields(filtersStore.filters.izvor_vazduh),
      })
    );

    if (err || !res) return;
    const { items, page, size, total, pages } = res;
    this.setAirList(items);
    this.handleChange('airListPagination', { page, size, total, pages });
    return res;
  };

  fetchTypeOfSource = async (pagination: IPagination, search: string) => {
    const response = await sifarniciStore.fetchSifarnikOptions<IPaginatedResponse<IGetSourceType>>(
      SIFARNICI_ROUTES.SOURCE_TYPE,
      {
        page: pagination.page,
        size: pagination.size,
        search: search,
      }
    );
    return response;
  };

  submitAir = (air: IPostAir) => {
    let newObj = {
      ...air,
      id: (this.air as IGetAir).id,
      postrojenje_id: (facilityStore.getFacility as IGetFacility).id,
    };

    if (!authStore.isAdmin) {
      omit(newObj, ['obaveza_merenja']);
    }

    if (formStore.getCrudButtonType === 'submit') {
      return this.postAir(newObj);
    }

    newObj = {
      ...airInitialState,
      ...newObj,
      aktivan: this.air.aktivan,
    } as IGetAir;

    if (formStore.getCrudButtonType === 'change') {
      return this.putAir(newObj);
    }
  };

  aktivirajVazduh = async () => {
    const [err, res] = await to<IEmptyResponse>(airService.aktivirajVazduh((this.air as IGetAir).id));
    if (err || !res) return;
    const newObj = { ...this.air, aktivan: true } as IGetAir;
    this.setAir(newObj);
    nrizService.changeItemInList(this.airList, newObj);
    handleSuccessNotify();
  };

  deaktivirajVazduh = async () => {
    const [err, res] = await to<IEmptyResponse>(airService.deaktivirajVazduh((this.air as IGetAir).id));
    if (err || !res) return;
    const newObj = { ...this.air, aktivan: false };

    this.setAir(newObj);
    nrizService.changeItemInList(this.airList, newObj);
    handleSuccessNotify();
  };
}

export const airStore = new AirStore();
