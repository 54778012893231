import { IBaseObrazacStore, IPaginatedResponse, IPagination } from 'typescript/NrizTypes';
import {
  Deo6KalkulacijaMaps,
  Deo6KalulacijaModalData,
  IGetDeo6Obrazac,
  IGetNoveVrsteOtpada,
  IGetPonovnaUpotrebaDeo6,
  IGetPredatOtpad,
  IGetPreuzetOtpad,
  IGetRazrediEEPreuzet,
  IGetRazrediEeOtpadaDeo6,
  IGetSkladistaDeo6,
  IPostDeo6Obrazac,
  IPostNoveVrsteOtpada,
  IPostPonovnaUpotrebaDeo6,
  IPostPredatOtpad,
  IPostPreuzetOtpad,
  IPostRazrediEEPreuzet,
  IPostRazrediEeOtpadaDeo6,
  IPostSkladistaDeo6,
} from './deo6.types';
import {
  DEO_6_NOVE_VRSTE_OTPADA_INITIAL_STATE,
  DEO_6_PONOVNA_UPOTREBA_INITIAL_STATE,
  DEO_6_SKLADISTE_INITIAL_STATE,
  DEO_6_INITIAL_STATE,
  DEO_6_PREDAT_OTPAD_INITIAL_STATE,
  DEO_6_PREUZET_OTPAD_INITIAL_STATE,
  DEO_6_RAZREDI_EE_OTPADA_INITIAL_STATE,
  DEO_6_RAZREDI_PREUZET_INITIAL_STATE,
  FILTER_PREUZET_OTPAD,
  FILTER_PREDAT_OTPAD,
} from './deo6.constants';
import { makeAutoObservable, observable } from 'mobx';
import { TIPOVI_OBRAZACA } from 'modules/obrasci/obrasci.types';
import { obrasciService } from 'modules/obrasci/obrasci.service';
import to from 'await-to-js';
import { formStore, modalStore } from 'store';
import { handleSuccessNotify } from 'utils/succesHandling';
import { nrizService } from 'modules/nriz/nriz.service';
import { SIFARNICI_ROUTES } from 'modules/sifarnici/sifarnici.types';
import { sifarniciStore } from 'modules/sifarnici/sifarnici.store';
import { obrasciStore } from 'modules/obrasci/obrasci.store';
import { arrayToObject } from 'utils/arrayToObject';
import dayjs from 'dayjs';
import { IGetFacility } from 'modules/facility/facility.types';
import { IGetActivitiesOfWasteOperators } from 'modules/sifarnici/store/activitiesOfWasteOperators/activitiesOfWasteOperators.types';
import { IGetCountryCode } from 'modules/sifarnici/store/countryCode/countryCode.types';
import { IGetDRList } from 'modules/sifarnici/store/dRList/dRList.types';
import { IGetWasteCatalogue } from 'modules/sifarnici/store/wasteCatalogue/wasteCatalogue.types';
import { IGetWeeeCategories } from 'modules/sifarnici/store/weeeCategories/weeeCategories.types';
import { deo6Service } from './deo6.service';
import { IGetApr } from 'modules/sifarnici/store/apr/apr.types';

class Deo6ObrazacStore implements IBaseObrazacStore<IGetDeo6Obrazac, IPostDeo6Obrazac> {
  initialState: IPostDeo6Obrazac | IGetDeo6Obrazac = DEO_6_INITIAL_STATE;
  skladiste_form: IPostSkladistaDeo6 | IGetSkladistaDeo6 = DEO_6_SKLADISTE_INITIAL_STATE;
  preuzet_form: IPostPreuzetOtpad | IGetPreuzetOtpad = DEO_6_PREUZET_OTPAD_INITIAL_STATE;
  razred_preuzet_form: IPostRazrediEEPreuzet | IGetRazrediEEPreuzet = DEO_6_RAZREDI_PREUZET_INITIAL_STATE;
  predat_form: IPostPredatOtpad | IGetPredatOtpad = DEO_6_PREDAT_OTPAD_INITIAL_STATE;
  ponovna_upotreba_otpada_form: IPostPonovnaUpotrebaDeo6 | IGetPonovnaUpotrebaDeo6 =
    DEO_6_PONOVNA_UPOTREBA_INITIAL_STATE;
  razredi_ee_form: IPostRazrediEeOtpadaDeo6 | IGetRazrediEeOtpadaDeo6 = DEO_6_RAZREDI_EE_OTPADA_INITIAL_STATE;
  index_id = '';

  index_nove_vrste_id = '';
  nove_vrste_form: IPostNoveVrsteOtpada | IGetNoveVrsteOtpada = DEO_6_NOVE_VRSTE_OTPADA_INITIAL_STATE;
  razredi_nove_vrste_form: IPostRazrediEeOtpadaDeo6 | IGetRazrediEeOtpadaDeo6 = DEO_6_RAZREDI_EE_OTPADA_INITIAL_STATE;

  weee_categories = false;
  weee_categories_nove_vrste = false;
  datum = '';
  godina = '';

  skladisteKalkulacija: Deo6KalkulacijaMaps | null = null;

  kalkulacijaNotificationModalData: Deo6KalulacijaModalData | null = null;

  dateValidationDisabled = false;

  constructor() {
    makeAutoObservable(this, {
      initialState: observable,
      skladiste_form: observable,
      preuzet_form: observable,
      predat_form: observable,
      ponovna_upotreba_otpada_form: observable,
      razredi_ee_form: observable,
      nove_vrste_form: observable,
      razredi_nove_vrste_form: observable,
      weee_categories: observable,
      weee_categories_nove_vrste: observable,
      datum: observable,
      godina: observable,
      index_id: observable,
      index_nove_vrste_id: observable,
    });
  }

  get getPonovnaUpotrebaForm() {
    return this.ponovna_upotreba_otpada_form;
  }

  get getPredatForm() {
    return this.predat_form;
  }

  get getPreuzetForm() {
    return this.preuzet_form;
  }

  get getSkladisteForm() {
    return this.skladiste_form;
  }

  get getNoveVrsteOtpadaForm() {
    return this.nove_vrste_form;
  }

  get getRazrediEeForm() {
    return this.razredi_ee_form;
  }

  get getIsTabHidden() {
    return [this.datum !== ''];
  }

  get getDatum() {
    return this.datum;
  }

  handleChange(key: keyof Deo6ObrazacStore, value: any) {
    (this as any)[key] = value;
  }

  handleInitialStateChange(key: string, value: any) {
    (this as any).initialState[key] = value;
  }
  handleSkladisteForm(key: string, value: any) {
    (this as any).skladiste_form[key] = value;
  }
  handlePonovnaUpotrebaForm(key: string, value: any) {
    (this as any).ponovna_upotreba_otpada_form[key] = value;
  }
  handlePredatForm(key: string, value: any) {
    (this as any).predat_form[key] = value;
  }
  handlePreuzetForm(key: string, value: any) {
    (this as any).preuzet_form[key] = value;
  }

  handleNoveVrsteOtpadaForm(key: string, value: any) {
    (this as any).nove_vrste_form[key] = value;
  }

  resetStates = () => {
    this.handleChange('initialState', DEO_6_INITIAL_STATE);
    this.handleChange('godina', '');
    this.handleChange('datum', '');
    this.setSkladisteKalkulacija(null);
    this.setKalkulacijaNotificationModalData(null);
    this.setDateValidationDisabled(false);
  };

  setDateValidationDisabled = (isDisabled: boolean) => {
    this.dateValidationDisabled = isDisabled;
  };

  setSkladisteKalkulacija = (skladisteKalkulacija: Deo6KalkulacijaMaps | null) => {
    return (this.skladisteKalkulacija = skladisteKalkulacija);
  };

  setKalkulacijaNotificationModalData = (modalData: Deo6KalulacijaModalData | null) => {
    return (this.kalkulacijaNotificationModalData = modalData);
  };

  checkSkladisteTabVisible = () => {
    const date = dayjs(this.datum, { format: 'DD-MM-YYYY' });
    const firstJanuary = dayjs(`01-01-${date.year()}`, { format: 'DD-MM-YYYY' });
    const isDateFirstOfJanuary = date.isSame(firstJanuary, 'day');
    return !isDateFirstOfJanuary;
  };

  checkPonovnoUpotrebaTabVisible = () => {
    if (Number(this.godina) >= 2020) {
      return false;
    }
    return true;
  };

  loadData = async (id: string) => {
    const [err, res] = await to(obrasciService.fetchSingleObrazac<IGetDeo6Obrazac>(TIPOVI_OBRAZACA.DEO6_GIO6, id));
    if (err || !res) return;
    if (res.postrojenje.privremeno_ukinuta_validacija_za_deo6) this.setDateValidationDisabled(true);
    const datumDayjs = dayjs(res.datum);
    this.handleChange('initialState', { ...res, datum: datumDayjs });
    this.handleChange('datum', res.datum);
    const godina = res.datum.split('-')[0];
    this.handleChange('godina', godina);
    const [calculationError, calculationResponse] = await to(
      deo6Service.getDeo6SkladisteInfo({
        preduzece_id: res.preduzece_id,
        postrojenje_id: res.postrojenje_id,
        datum: res.datum,
      })
    );
    if (calculationError || !calculationResponse) return;
    const validResponse = deo6Service.mapDeo6KalkulacijaResponse(calculationResponse);

    const skladisteKalkulacija = deo6Service.calculateSkladiste({
      obrazac: res,
      skladiste: validResponse,
      operation: '-',
    });
    this.setSkladisteKalkulacija(skladisteKalkulacija);
    return res;
  };

  async postData(payload: IPostDeo6Obrazac) {
    const dateString = dayjs(payload.datum).format('YYYY-MM-DD');
    const newPayload = { ...payload, datum: dateString };
    const [err, res] = await to(
      obrasciService.postSingleObrazac<IPostDeo6Obrazac>(
        TIPOVI_OBRAZACA.DEO6_GIO6,
        nrizService.setEmptyValuesToNull(newPayload)
      )
    );
    if (err || !res) return;
    formStore.handleChange('goBack', true);
    handleSuccessNotify();
  }
  putData = async (payload: IGetDeo6Obrazac) => {
    const newPayload = { ...payload, datum: this.datum };
    const [err, res] = await to(
      obrasciService.putSingleObrazac<IGetDeo6Obrazac>(
        TIPOVI_OBRAZACA.DEO6_GIO6,
        payload.id,
        nrizService.setEmptyValuesToNull(newPayload)
      )
    );
    if (err || !res) return;
    formStore.handleChange('goBack', true);
    handleSuccessNotify();
  };

  async deleteData(id: string) {
    const [err, res] = await to(obrasciService.deleteSingleObrazac(TIPOVI_OBRAZACA.DEO6_GIO6, id));
    if (err || !res) return;
    formStore.handleChange('goBack', true);
    handleSuccessNotify();
  }

  submitData = (payload: IPostDeo6Obrazac) => {
    if (!this.skladisteKalkulacija) return;

    const newInitialState = {
      id: (this.initialState as IGetDeo6Obrazac).id,
      ...payload,
      skladiste: this.initialState.skladiste,
      predat_otpad: this.initialState.predat_otpad,
      preuzet_otpad: this.initialState.preuzet_otpad,
      ponovna_upotreba_otpada: this.initialState.ponovna_upotreba_otpada,
    } as IGetDeo6Obrazac;

    const { indeksni_brojevi, razredi_ee_otpada } = deo6Service.calculateSkladiste({
      obrazac: newInitialState,
      skladiste: this.skladisteKalkulacija,
    });

    const invalidSkladisteItems = deo6Service.validateSkladista(indeksni_brojevi, razredi_ee_otpada);

    if (invalidSkladisteItems.indeksni_brojevi.length || invalidSkladisteItems.razredi_ee_otpada.length) {
      this.setKalkulacijaNotificationModalData({
        obrazacData: newInitialState,
        kalkulacijaArrays: invalidSkladisteItems,
      });
      return modalStore.changeModalName('deo6-kalkulacija-modal');
    }

    // validation of skladiste items is executed here in order to keep the current obrazac submitData flow

    obrasciService.submitFormObrazac({
      payload: newInitialState,
      postForm: this.postData,
      putForm: this.putData,
      deleteForm: this.deleteData,
    });
  };

  // SKLADISTA ////////

  submitSkladisteForm = (payload: IPostSkladistaDeo6) => {
    let newPayload = payload;
    let sifarnikIndex = {};

    if (formStore.getCrudButtonType === 'submit' || formStore.getCrudButtonType === 'change') {
      sifarnikIndex = nrizService.addSifarnikInObject({
        exId: this.skladiste_form.indeksni_broj_otpada_id,
        exObj: (this.skladiste_form as IGetSkladistaDeo6).indeksni_broj_otpada,
        list: sifarniciStore.list[SIFARNICI_ROUTES.WASTE_CATALOGUE],
        name: 'indeksni_broj_otpada',
        id: payload.indeksni_broj_otpada_id,
      });
    }

    newPayload = {
      id: (this.skladiste_form as IGetSkladistaDeo6).id,
      ...newPayload,
      ...sifarnikIndex,
      razred: this.skladiste_form.razred,
    } as IGetSkladistaDeo6;
    const newList = obrasciService.submitMicroServiceForm(this.initialState.skladiste, newPayload);
    // handleSuccessNotify();
    this.handleInitialStateChange('skladiste', newList);
    this.handleChange('skladiste_form', DEO_6_SKLADISTE_INITIAL_STATE);
    this.resetWeeeCategories();
  };

  submitRazredSkladiste = (payload: IPostRazrediEeOtpadaDeo6) => {
    let newPayload = payload;
    let sifarnikRazredEeOtapada = {};

    if (formStore.getCrudButtonType === 'submit' || formStore.getCrudButtonType === 'change') {
      sifarnikRazredEeOtapada = nrizService.addSifarnikInObject({
        exId: (this.razredi_ee_form as IGetRazrediEeOtpadaDeo6).razred_ee_otpada_id,
        exObj: (this.razredi_ee_form as IGetRazrediEeOtpadaDeo6).razred_ee_otpada,
        list: sifarniciStore.list[SIFARNICI_ROUTES.WEEE_CATEGORIES],
        name: 'razred_ee_otpada',
        id: newPayload.razred_ee_otpada_id,
      });
    }

    newPayload = {
      id: (this.razredi_ee_form as IGetRazrediEeOtpadaDeo6).id,
      ...newPayload,
      ...sifarnikRazredEeOtapada,
    } as IGetRazrediEeOtpadaDeo6;
    const newList = obrasciService.submitMicroServiceForm(
      this.skladiste_form.razred,
      newPayload,
      true,
      'deo6-razred-skladiste-modal'
    );

    // handleSuccessNotify();
    this.handleSkladisteForm('razred', newList);
    this.handleChange('razredi_ee_form', DEO_6_RAZREDI_EE_OTPADA_INITIAL_STATE);
  };

  // PREUZET ////////

  submitPreuzetForm = (payload: IPostPreuzetOtpad) => {
    let newPayload = payload;
    let sifarnikIndex = {};
    let sifarnikZemljaIzvoza = {};
    let sifarnikPreduzece = {};
    let sifarnikPostrojenje = {};

    if (formStore.getCrudButtonType === 'submit' || formStore.getCrudButtonType === 'change') {
      sifarnikIndex = nrizService.addSifarnikInObject({
        exId: this.preuzet_form.indeksni_broj_otpada_id,
        exObj: (this.preuzet_form as IGetPreuzetOtpad).indeksni_broj_otpada,
        list: sifarniciStore.list[SIFARNICI_ROUTES.WASTE_CATALOGUE],
        name: 'indeksni_broj_otpada',
        id: payload.indeksni_broj_otpada_id,
      });

      if (payload.zemlja_izvoza_id) {
        sifarnikZemljaIzvoza = nrizService.addSifarnikInObject({
          exId: this.preuzet_form.zemlja_izvoza_id,
          exObj: (this.preuzet_form as IGetPreuzetOtpad).zemlja_izvoza,
          list: sifarniciStore.list[SIFARNICI_ROUTES.COUNTRY_CODE],
          name: 'zemlja_izvoza',
          id: payload.zemlja_izvoza_id,
        });
      } else {
        this.handlePreuzetForm('zemlja_izvoza', {});
      }
      if (payload.preduzece_id) {
        sifarnikPreduzece = nrizService.addSifarnikInObject({
          exId: this.preuzet_form.preduzece_id,
          exObj: (this.preuzet_form as IGetPreuzetOtpad).preduzece,
          list: sifarniciStore.list[SIFARNICI_ROUTES.APR],
          name: 'preduzece',
          id: payload.preduzece_id,
        });
      } else {
        this.handlePreuzetForm('preduzece', {});
      }

      if (payload.postrojenje_id) {
        sifarnikPostrojenje = nrizService.addSifarnikInObject({
          exId: this.preuzet_form.postrojenje_id,
          exObj: (this.preuzet_form as IGetPreuzetOtpad).postrojenje,
          list: sifarniciStore.list[SIFARNICI_ROUTES.POSTROJENJE],
          name: 'postrojenje',
          id: payload.postrojenje_id,
        });
      } else {
        this.handlePreuzetForm('postrojenje', {});
      }
    }

    newPayload = {
      id: (this.preuzet_form as IGetPreuzetOtpad).id,
      ...newPayload,
      ...sifarnikIndex,
      ...sifarnikZemljaIzvoza,
      ...sifarnikPreduzece,
      ...sifarnikPostrojenje,
      razred: this.preuzet_form.razred,
    } as IGetPreuzetOtpad;

    const newList = obrasciService.submitMicroServiceForm(this.initialState.preuzet_otpad, newPayload);
    // handleSuccessNotify();
    this.handleInitialStateChange('preuzet_otpad', newList);
    this.handleChange('preuzet_form', DEO_6_PREUZET_OTPAD_INITIAL_STATE);
    this.resetWeeeCategories();
  };

  submitRazredPreuzet = (payload: IPostRazrediEEPreuzet) => {
    let newPayload = payload;
    let sifarnikRazredEeOtapada = {};

    if (formStore.getCrudButtonType === 'submit' || formStore.getCrudButtonType === 'change') {
      sifarnikRazredEeOtapada = nrizService.addSifarnikInObject({
        exId: (this.razred_preuzet_form as IGetRazrediEEPreuzet).razred_ee_otpada_id,
        exObj: (this.razred_preuzet_form as IGetRazrediEEPreuzet).razred_ee_otpada,
        list: sifarniciStore.list[SIFARNICI_ROUTES.WEEE_CATEGORIES],
        name: 'razred_ee_otpada',
        id: newPayload.razred_ee_otpada_id,
      });
    }

    newPayload = {
      id: (this.razred_preuzet_form as IGetRazrediEEPreuzet).id,
      ...newPayload,
      ...sifarnikRazredEeOtapada,
    } as IGetRazrediEEPreuzet;
    const newList = obrasciService.submitMicroServiceForm(
      this.preuzet_form.razred,
      newPayload,
      true,
      'deo6-razred-preuzet-otpad-modal'
    );
    // handleSuccessNotify();
    this.handlePreuzetForm('razred', newList);
    this.handleChange('razred_preuzet_form', DEO_6_RAZREDI_PREUZET_INITIAL_STATE);
  };

  // PREDAT

  submitPredatForm = (payload: IPostPredatOtpad) => {
    let newPayload = payload;
    let sifarnikIndex = {};
    let sifarnikZemljaIzvoza = {};
    let sifarnikPreduzece = {};
    let sifarnikPostrojenje = {};
    let sifarnikROznaka = {};
    let sifarnikDOznaka = {};
    let sifarnikOperatorJe = {};

    if (formStore.getCrudButtonType === 'submit' || formStore.getCrudButtonType === 'change') {
      sifarnikIndex = nrizService.addSifarnikInObject({
        exId: this.predat_form.indeksni_broj_otpada_id,
        exObj: (this.predat_form as IGetPredatOtpad).indeksni_broj_otpada,
        list: sifarniciStore.list[SIFARNICI_ROUTES.WASTE_CATALOGUE],
        name: 'indeksni_broj_otpada',
        id: payload.indeksni_broj_otpada_id,
      });

      if (payload.zemlja_izvoza_id) {
        sifarnikZemljaIzvoza = nrizService.addSifarnikInObject({
          exId: this.predat_form.zemlja_izvoza_id,
          exObj: (this.predat_form as IGetPredatOtpad).zemlja_izvoza,
          list: sifarniciStore.list[SIFARNICI_ROUTES.COUNTRY_CODE],
          name: 'zemlja_izvoza',
          id: payload.zemlja_izvoza_id,
        });
      } else {
        this.handlePredatForm('zemlja_izvoza', {});
      }
      if (payload.operater_je_id) {
        sifarnikOperatorJe = nrizService.addSifarnikInObject({
          exId: this.predat_form.operater_je_id,
          exObj: (this.predat_form as IGetPredatOtpad).operater_je,
          list: sifarniciStore.list[SIFARNICI_ROUTES.ACTIVITIES_OF_WASTE_OPERATORS],
          name: 'operater_je',
          id: payload.operater_je_id,
        });
      } else {
        this.handlePredatForm('operater_je', {});
      }
      if (payload.d_oznaka_id) {
        sifarnikDOznaka = nrizService.addSifarnikInObject({
          exId: this.predat_form.d_oznaka_id,
          exObj: (this.predat_form as IGetPredatOtpad).d_oznaka,
          list: sifarniciStore.list[SIFARNICI_ROUTES.DR_LIST],
          name: 'd_oznaka',
          id: payload.d_oznaka_id,
        });
      } else {
        this.handlePredatForm('d_oznaka', {});
      }
      if (payload.r_oznaka_id) {
        sifarnikROznaka = nrizService.addSifarnikInObject({
          exId: this.predat_form.r_oznaka_id,
          exObj: (this.predat_form as IGetPredatOtpad).r_oznaka,
          list: sifarniciStore.list[SIFARNICI_ROUTES.DR_LIST],
          name: 'r_oznaka',
          id: payload.r_oznaka_id,
        });
      } else {
        this.handlePredatForm('r_oznaka', {});
      }
      if (payload.preduzece_id) {
        sifarnikPreduzece = nrizService.addSifarnikInObject({
          exId: this.predat_form.preduzece_id,
          exObj: (this.predat_form as IGetPredatOtpad).preduzece,
          list: sifarniciStore.list[SIFARNICI_ROUTES.APR],
          name: 'preduzece',
          id: payload.preduzece_id,
        });
      } else {
        this.handlePredatForm('preduzece', {});
      }
      if (payload.postrojenje_id) {
        sifarnikPostrojenje = nrizService.addSifarnikInObject({
          exId: this.predat_form.postrojenje_id,
          exObj: (this.predat_form as IGetPredatOtpad).postrojenje,
          list: sifarniciStore.list[SIFARNICI_ROUTES.POSTROJENJE],
          name: 'postrojenje',
          id: payload.postrojenje_id,
        });
      } else {
        this.handlePredatForm('postrojenje', {});
      }
    }

    newPayload = {
      id: (this.predat_form as IGetPredatOtpad).id,
      ...sifarnikIndex,
      ...sifarnikZemljaIzvoza,
      ...sifarnikPreduzece,
      ...sifarnikPostrojenje,
      ...sifarnikROznaka,
      ...sifarnikDOznaka,
      ...sifarnikOperatorJe,
      ...payload,
      razred: this.predat_form.razred,
      nove_vrste_otpada_lista: this.predat_form.nove_vrste_otpada_lista,
    } as IGetPredatOtpad;

    const newList = obrasciService.submitMicroServiceForm(this.initialState.predat_otpad, newPayload);
    // handleSuccessNotify();
    this.handleInitialStateChange('predat_otpad', newList);
    this.handleChange('predat_form', DEO_6_PREDAT_OTPAD_INITIAL_STATE);
    this.resetWeeeCategories();
  };

  submitRazredPredat = (payload: IPostRazrediEeOtpadaDeo6) => {
    let newPayload = payload;
    let sifarnikRazredEeOtapada = {};

    if (formStore.getCrudButtonType === 'submit' || formStore.getCrudButtonType === 'change') {
      sifarnikRazredEeOtapada = nrizService.addSifarnikInObject({
        exId: (this.razredi_ee_form as IGetRazrediEeOtpadaDeo6).razred_ee_otpada_id,
        exObj: (this.razredi_ee_form as IGetRazrediEeOtpadaDeo6).razred_ee_otpada,
        list: sifarniciStore.list[SIFARNICI_ROUTES.WEEE_CATEGORIES],
        name: 'razred_ee_otpada',
        id: newPayload.razred_ee_otpada_id,
      });
    }

    newPayload = {
      id: (this.razredi_ee_form as IGetRazrediEeOtpadaDeo6).id,
      ...newPayload,
      ...sifarnikRazredEeOtapada,
    } as IGetRazrediEeOtpadaDeo6;
    const newList = obrasciService.submitMicroServiceForm(
      this.predat_form.razred,
      newPayload,
      true,
      'deo6-razred-predat-otpad-modal'
    );
    // handleSuccessNotify();
    this.handlePredatForm('razred', newList);
    this.handleChange('razredi_ee_form', DEO_6_RAZREDI_EE_OTPADA_INITIAL_STATE);
  };

  submitNoveVrsteOtpada = (payload: IPostNoveVrsteOtpada) => {
    let newPayload = payload;
    let sifarnikIndex = {};

    if (formStore.getCrudButtonType === 'submit' || formStore.getCrudButtonType === 'change') {
      sifarnikIndex = nrizService.addSifarnikInObject({
        exId: this.nove_vrste_form.indeksni_broj_otpada_id,
        exObj: (this.nove_vrste_form as IGetNoveVrsteOtpada).indeksni_broj_otpada,
        list: sifarniciStore.list[SIFARNICI_ROUTES.WASTE_CATALOGUE],
        name: 'indeksni_broj_otpada',
        id: payload.indeksni_broj_otpada_id,
      });
    }

    newPayload = {
      id: (this.nove_vrste_form as IGetNoveVrsteOtpada).id,
      ...newPayload,
      ...sifarnikIndex,
      razred: this.nove_vrste_form.razred,
    } as IGetNoveVrsteOtpada;

    const newList = obrasciService.submitMicroServiceForm(
      this.predat_form.nove_vrste_otpada_lista,
      newPayload,
      true,
      'deo6-nove-vrste-otpad-modal'
    );
    // handleSuccessNotify();
    this.handlePredatForm('nove_vrste_otpada_lista', newList);
    this.handleChange('nove_vrste_form', DEO_6_NOVE_VRSTE_OTPADA_INITIAL_STATE);
    this.resetWeeeCategoriesNoveVrste();
  };

  submitRazredNoveVrsteOtpada = (payload: IPostRazrediEeOtpadaDeo6) => {
    let newPayload = payload;
    let sifarnikRazredEeOtapada = {};

    if (formStore.getCrudButtonType === 'submit' || formStore.getCrudButtonType === 'change') {
      sifarnikRazredEeOtapada = nrizService.addSifarnikInObject({
        exId: (this.razredi_ee_form as IGetRazrediEeOtpadaDeo6).razred_ee_otpada_id,
        exObj: (this.razredi_ee_form as IGetRazrediEeOtpadaDeo6).razred_ee_otpada,
        list: sifarniciStore.list[SIFARNICI_ROUTES.WEEE_CATEGORIES],
        name: 'razred_ee_otpada',
        id: newPayload.razred_ee_otpada_id,
      });
    }

    newPayload = {
      id: (this.razredi_nove_vrste_form as IGetRazrediEeOtpadaDeo6).id,
      ...newPayload,
      ...sifarnikRazredEeOtapada,
    } as IGetRazrediEeOtpadaDeo6;
    const newList = obrasciService.submitMicroServiceForm(
      this.nove_vrste_form.razred,
      newPayload,
      true,
      'deo6-razred-nove-vrste-otpada-modal'
    );
    // handleSuccessNotify();
    this.handleNoveVrsteOtpadaForm('razred', newList);
    this.handleChange('razredi_nove_vrste_form', DEO_6_RAZREDI_EE_OTPADA_INITIAL_STATE);
  };

  fetchWasteCatalougeList = async (
    pagination: IPagination,
    search: string
  ): Promise<IPaginatedResponse<IGetWasteCatalogue>> => {
    const response = await sifarniciStore.fetchSifarnikOptions<IGetWasteCatalogue>(SIFARNICI_ROUTES.WASTE_CATALOGUE, {
      page: pagination.page,
      size: pagination.size,
      search: search,
      level: 3,
      order_by: '+index_number',
      is_active: true,
    });
    return response;
  };

  fetchDList = async (pagination: IPagination, search: string): Promise<IPaginatedResponse<IGetDRList>> => {
    const response = await sifarniciStore.fetchSifarnikOptions<IGetDRList>(SIFARNICI_ROUTES.DR_LIST, {
      search: search,
      name__ilike: 'D',
      order_by: '+name',
    });
    return response;
  };

  fetchRList = async (_: IPagination, search: string): Promise<IPaginatedResponse<IGetDRList>> => {
    const index = (this.predat_form as IGetPredatOtpad)?.indeksni_broj_otpada
      ? (this.predat_form as IGetPredatOtpad)?.indeksni_broj_otpada?.index_number
      : sifarniciStore.getSifarniciIdMap(SIFARNICI_ROUTES.WASTE_CATALOGUE)[this.index_id]?.index_number;

    let RNames = 'R7,R8,R4,R9,R1,R6,R2,R3,R11,R5,R10';

    if (index && index.startsWith('18') && Number(this.godina) >= 2020) {
      RNames += ',D9';
    }

    const response = await sifarniciStore.fetchSifarnikOptions<IGetDRList>(
      SIFARNICI_ROUTES.DR_LIST,
      nrizService.pickFields({
        search: search,
        name__in: RNames,
      })
    );

    return response;
  };

  fetchCountryCode = async (pagination: IPagination, search: string): Promise<IPaginatedResponse<IGetCountryCode>> => {
    const response = await sifarniciStore.fetchSifarnikOptions<IGetCountryCode>(SIFARNICI_ROUTES.COUNTRY_CODE, {
      page: pagination.page,
      size: pagination.size,
      search: search,
      code__neq: 'RS',
      order_by: '+name',
    });
    return response;
  };

  fetchActivitiesOfWasteOperators = async (
    pagination: IPagination,
    search: string
  ): Promise<IPaginatedResponse<IGetActivitiesOfWasteOperators>> => {
    const response = await sifarniciStore.fetchSifarnikOptions<IGetActivitiesOfWasteOperators>(
      SIFARNICI_ROUTES.ACTIVITIES_OF_WASTE_OPERATORS,
      {
        page: pagination.page,
        size: pagination.size,
        search: search,
        activities_operator__neq: 'Waste generator',
        order_by: '+activities_operator_sr',
      }
    );
    return response;
  };

  fetchAprPreuzetOtpad = async (pagination: IPagination, search: string): Promise<IPaginatedResponse<IGetApr>> => {
    const response = await sifarniciStore.fetchSifarnikOptions<IGetApr>(SIFARNICI_ROUTES.APR, {
      page: pagination.page,
      size: pagination.size,
      search: search,
      status_id__in: FILTER_PREUZET_OTPAD,
      // order_by: '+full_company_name',
    });
    return response;
  };

  fetchAprPredatOtpad = async (pagination: IPagination, search: string): Promise<IPaginatedResponse<IGetApr>> => {
    const response = await sifarniciStore.fetchSifarnikOptions<IGetApr>(SIFARNICI_ROUTES.APR, {
      page: pagination.page,
      size: pagination.size,
      search: search,
      status_id__in: FILTER_PREDAT_OTPAD,
      // order_by: '+full_company_name',
    });
    return response;
  };

  fetchPostrojenje = async (pagination: IPagination, search: string): Promise<IPaginatedResponse<IGetFacility>> => {
    const response = await sifarniciStore.fetchSifarnikOptions<IGetFacility>(SIFARNICI_ROUTES.POSTROJENJE, {
      page: pagination.page,
      size: pagination.size,
      preduzece_id: obrasciStore.opste.preduzece_id,
      search: search,
      order_by: '+naziv_postrojenja',
    });
    const newList = response.items.filter((item: IGetFacility) => item.id !== obrasciStore.opste.postrojenje_id);
    const newResponse = { ...response, items: newList };

    return newResponse;
  };

  fetchWeeeCategoriesList = async (
    pagination: IPagination,
    search: string
  ): Promise<IPaginatedResponse<IGetWeeeCategories>> => {
    const response = await sifarniciStore.fetchSifarnikOptions<IGetWeeeCategories>(SIFARNICI_ROUTES.WEEE_CATEGORIES, {
      page: pagination.page,
      size: pagination.size,
      search: search,
      waste_catalogue_id: this.index_id,
      order_by: '+the_order',
    });
    return response;
  };
  fetchWeeeCategoriesNoveVrste = async (
    pagination: IPagination,
    search: string
  ): Promise<IPaginatedResponse<IGetWeeeCategories>> => {
    const response = await sifarniciStore.fetchSifarnikOptions<IGetWeeeCategories>(SIFARNICI_ROUTES.WEEE_CATEGORIES, {
      page: pagination.page,
      size: pagination.size,
      search: search,
      waste_catalogue_id: this.index_nove_vrste_id,
      order_by: '+the_order',
    });
    return response;
  };

  // PONOVNA UPOTREBA

  submitPonovnaUpotrebaForm = (payload: IPostPonovnaUpotrebaDeo6) => {
    let newPayload = payload;
    let sifarnikIndex = {};

    if (formStore.getCrudButtonType === 'submit' || formStore.getCrudButtonType === 'change') {
      sifarnikIndex = nrizService.addSifarnikInObject({
        exId: this.ponovna_upotreba_otpada_form.indeksni_broj_otpada_id,
        exObj: (this.ponovna_upotreba_otpada_form as IGetPonovnaUpotrebaDeo6).indeksni_broj_otpada,
        list: sifarniciStore.list[SIFARNICI_ROUTES.WASTE_CATALOGUE],
        name: 'indeksni_broj_otpada',
        id: payload.indeksni_broj_otpada_id,
      });
    }

    newPayload = {
      id: (this.ponovna_upotreba_otpada_form as IGetPonovnaUpotrebaDeo6).id,
      ...newPayload,
      ...sifarnikIndex,
      razred: this.ponovna_upotreba_otpada_form.razred,
    } as IGetPonovnaUpotrebaDeo6;
    const newList = obrasciService.submitMicroServiceForm(this.initialState.ponovna_upotreba_otpada, newPayload);
    // handleSuccessNotify();
    this.handleInitialStateChange('ponovna_upotreba_otpada', newList);
    this.handleChange('ponovna_upotreba_otpada_form', DEO_6_PONOVNA_UPOTREBA_INITIAL_STATE);
    this.resetWeeeCategories();
  };

  submitRazredPonovnaUpotreba = (payload: IPostRazrediEeOtpadaDeo6) => {
    let newPayload = payload;
    let sifarnikRazredEeOtapada = {};

    if (formStore.getCrudButtonType === 'submit' || formStore.getCrudButtonType === 'change') {
      sifarnikRazredEeOtapada = nrizService.addSifarnikInObject({
        exId: (this.razredi_ee_form as IGetRazrediEeOtpadaDeo6).razred_ee_otpada_id,
        exObj: (this.razredi_ee_form as IGetRazrediEeOtpadaDeo6).razred_ee_otpada,
        list: sifarniciStore.list[SIFARNICI_ROUTES.WEEE_CATEGORIES],
        name: 'razred_ee_otpada',
        id: newPayload.razred_ee_otpada_id,
      });
    }

    newPayload = {
      id: (this.razredi_ee_form as IGetRazrediEeOtpadaDeo6).id,
      ...newPayload,
      ...sifarnikRazredEeOtapada,
    } as IGetRazrediEeOtpadaDeo6;
    const newList = obrasciService.submitMicroServiceForm(
      this.ponovna_upotreba_otpada_form.razred,
      newPayload,
      true,
      'deo6-razred-ponovna-upotreba-modal'
    );
    // handleSuccessNotify();
    this.handlePonovnaUpotrebaForm('razred', newList);
    this.handleChange('razredi_ee_form', DEO_6_RAZREDI_EE_OTPADA_INITIAL_STATE);
  };

  resetWeeeCategories = () => {
    this.index_id = '';
    this.weee_categories = false;
  };

  resetWeeeCategoriesNoveVrste = () => {
    this.index_nove_vrste_id = '';
    this.weee_categories_nove_vrste = false;
  };

  checkWeeeCategories = (id: string, listToChange: string) => {
    const obj = this.indexMap(id) as IGetWasteCatalogue;
    if (obj.weee && Number(this.godina) >= 2020) {
      this.handleChange('weee_categories', true);
    } else {
      (this as any)[listToChange]('razred', []);
      this.handleChange('weee_categories', false);
    }
    return obj;
  };

  checkWeeeCategoriesNoveVrste = (id: string) => {
    const obj = arrayToObject(sifarniciStore.list.WASTE_CATALOGUE);
    const findItem = obj[id] as IGetWasteCatalogue;
    if (findItem.weee && Number(this.godina) >= 2020) {
      this.handleChange('weee_categories_nove_vrste', true);
    } else {
      this.handleNoveVrsteOtpadaForm('razred', []);
      this.handleChange('weee_categories_nove_vrste', false);
    }
  };

  indexMap = (id: string) => {
    const obj = arrayToObject(sifarniciStore.list.WASTE_CATALOGUE);
    const findItem = obj[id] as IGetWasteCatalogue;
    return findItem;
  };

  getDeo6SkladisteInfo = async (datum: string): Promise<Deo6KalkulacijaMaps | undefined> => {
    const validPayload = {
      preduzece_id: obrasciStore.opste.preduzece_id,
      postrojenje_id: obrasciStore.opste.postrojenje_id,
      datum,
    };
    const [err, res] = await to(deo6Service.getDeo6SkladisteInfo(validPayload));
    if (err || !res) return;

    const validResponse = deo6Service.mapDeo6KalkulacijaResponse(res);
    const skladisteKalkulacija = deo6Service.calculateSkladiste({ skladiste: validResponse, operation: '+' });

    this.setSkladisteKalkulacija(skladisteKalkulacija);
    return validResponse;
  };
}
export const deo6ObrazacStore = new Deo6ObrazacStore();
