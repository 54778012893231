import { IBaseObrazacStore, IPaginatedResponse, IPagination } from 'typescript/NrizTypes';
import { IFiltersDep1, IGetDep1, IObrazacDep1Mesto, IPostDep1, IPostObrazacDep1Mesto } from './dep1.types';
import { DEP1_FILTERS_INITIAL_STATE, DEP1_INITIAL_STATE, DEP1_MESTO_INITIAL_STATE } from './dep1.constants';
import { computed, makeAutoObservable, observable } from 'mobx';
import to from 'await-to-js';
import { obrasciService } from 'modules/obrasci/obrasci.service';
import { TIPOVI_OBRAZACA } from 'modules/obrasci/obrasci.types';
import dayjs from 'dayjs';
import { sifarniciStore } from 'modules/sifarnici/sifarnici.store';
import { SIFARNICI_ROUTES } from 'modules/sifarnici/sifarnici.types';
import { formStore } from 'store';
import { nrizService } from 'modules/nriz/nriz.service';
import { obrasciStore } from 'modules/obrasci/obrasci.store';
import { INITIAL_RESPONSE_SIFARNIK } from 'modules/sifarnici/sifarnici.constants.tsx';
import { handleSuccessNotify } from 'utils/succesHandling';
import { IGetUnsanitaryLandfill } from 'modules/unsanitarylandfill/unsanitarylandfill.types';
import {
  IGetDepFormAnswers,
  ResponseDepFormAnswers,
} from 'modules/sifarnici/store/depFormsAnswers/depFormsAnswers.types';
import { IGetMesto } from 'modules/sifarnici/store/mesto/mesto.types';

class Dep1Store implements IBaseObrazacStore<IGetDep1, IPostDep1> {
  initialState: IPostDep1 | IGetDep1 = DEP1_INITIAL_STATE;
  mesto_form: IPostObrazacDep1Mesto | IObrazacDep1Mesto = DEP1_MESTO_INITIAL_STATE;

  filters: IFiltersDep1 = DEP1_FILTERS_INITIAL_STATE;

  constructor() {
    makeAutoObservable(this, {
      initialState: observable,
      mesto_form: observable,
      filters: observable,

      getInitialState: computed,
      getMestoForm: computed,
    });
  }

  get getInitialState() {
    return this.initialState;
  }

  get getMestoForm() {
    return this.mesto_form;
  }

  get getFilters() {
    return this.filters;
  }

  handleChange(key: string, value: any) {
    (this as any)[key] = value;
  }

  handleInitialStateChange(key: string, value: any) {
    (this as any).initialState[key] = value;
  }

  setFilters = (type: string, value: string) => {
    this.filters = { ...this.filters, [type]: value };
  };

  loadData = async (id: string) => {
    const [err, res] = await to(obrasciService.fetchSingleObrazac<IGetDep1>(TIPOVI_OBRAZACA.DEP1, id));
    if (err) return;
    const newGodina = dayjs().year((res.godina as any) * 1);
    const newGodinaIzrade = dayjs().year((res.godina_izrade_projekta_sanacije as any) * 1);
    const newGodinaPribavljanja = dayjs().year((res.godina_pribavljanja_saglasnosti_na_projekat as any) * 1);

    this.handleChange('initialState', {
      ...res,
      godina: newGodina,
      godina_izrade_projekta_sanacije: newGodinaIzrade,
      godina_pribavljanja_saglasnosti_na_projekat: newGodinaPribavljanja,
    });

    return res;
  };

  async postData(payload: IPostDep1) {
    const [err, res] = await to(
      obrasciService.postSingleObrazac<IPostDep1>(TIPOVI_OBRAZACA.DEP1, nrizService.setEmptyValuesToNull(payload))
    );
    if (err || !res) return;
    formStore.handleChange('goBack', true);
    handleSuccessNotify();
  }

  async deleteData(id: string) {
    const [err, res] = await to(obrasciService.deleteSingleObrazac(TIPOVI_OBRAZACA.DEP1, id));
    if (err || !res) return;
    formStore.handleChange('goBack', true);
    handleSuccessNotify();
  }

  putData = async (payload: any) => {
    const [err, res] = await to(
      obrasciService.putSingleObrazac<IPostDep1>(
        TIPOVI_OBRAZACA.DEP1,
        payload.id,
        nrizService.setEmptyValuesToNull(payload)
      )
    );
    if (err || !res) return;
    formStore.handleChange('goBack', true);
    handleSuccessNotify();
  };

  resetStates = () => {
    this.handleChange('filters', DEP1_FILTERS_INITIAL_STATE);
    this.handleChange('initialState', DEP1_INITIAL_STATE);
  };

  submitData = (payload: IPostDep1) => {
    const newInitialState = {
      ...DEP1_INITIAL_STATE,
      id: (this.initialState as IGetDep1).id,
      ...payload,
      godina: obrasciStore.opste.godina,
      godina_izrade_projekta_sanacije: payload.godina_izrade_projekta_sanacije
        ? dayjs(payload.godina_izrade_projekta_sanacije).format('YYYY')
        : '',
      godina_pribavljanja_saglasnosti_na_projekat: payload.godina_pribavljanja_saglasnosti_na_projekat
        ? dayjs(payload.godina_pribavljanja_saglasnosti_na_projekat).format('YYYY')
        : '',
      obrazac_dep1_mesta: this.initialState.obrazac_dep1_mesta,
    } as IGetDep1;

    obrasciService.submitFormObrazac({
      payload: newInitialState,
      postForm: this.postData,
      putForm: this.putData,
      deleteForm: this.deleteData,
    });
  };

  //// VALIDATIONN

  /// OPSTE

  fetchNesanitarnaDeponije = async (
    pagination: IPagination,
    search: string
  ): Promise<IPaginatedResponse<IGetUnsanitaryLandfill>> => {
    if (obrasciStore.opste.preduzece_id === '') return INITIAL_RESPONSE_SIFARNIK;
    const response = await sifarniciStore.fetchSifarnikOptions<IGetUnsanitaryLandfill>(
      SIFARNICI_ROUTES.NESANITARNA_DEPONIJA,
      {
        page: pagination.page,
        size: pagination.size,
        preduzece_id: obrasciStore.opste.preduzece_id,
        search: search,
      }
    );
    return response;
  };

  ///// MESTO

  submitMestoForm = (payload: IPostObrazacDep1Mesto) => {
    let newPayload = payload;
    let sifarnikMesto = {};
    if (formStore.getCrudButtonType === 'submit' || formStore.getCrudButtonType === 'change') {
      if (payload.mesto_id) {
        sifarnikMesto = nrizService.addSifarnikInObject({
          exId: (this.mesto_form as IObrazacDep1Mesto).mesto_id,
          exObj: (this.mesto_form as IObrazacDep1Mesto).mesto,
          list: sifarniciStore.list[SIFARNICI_ROUTES.MESTO],
          name: 'mesto',
          id: payload.mesto_id,
        });
      } else {
        newPayload = { ...newPayload, mesto: {} } as IObrazacDep1Mesto;
      }
    }
    newPayload = {
      id: (this.mesto_form as IObrazacDep1Mesto).id,
      ...newPayload,
      ...sifarnikMesto,
    } as IObrazacDep1Mesto;

    const newList = obrasciService.submitMicroServiceForm(this.initialState.obrazac_dep1_mesta, newPayload);
    // handleSuccessNotify();
    this.handleInitialStateChange('obrazac_dep1_mesta', newList);
    this.handleChange('mesto_form', DEP1_MESTO_INITIAL_STATE);
  };

  fetchMesto = async (pagination: IPagination, search: string): Promise<IPaginatedResponse<IGetMesto>> => {
    const response = await sifarniciStore.fetchSifarnikOptions<IGetMesto>(SIFARNICI_ROUTES.MESTO, {
      page: pagination.page,
      size: pagination.size,
      search: search,
      location_code__neq: 999999,
      order_by: '+display_name',
    });
    return response;
  };

  fetchDepFormAnswersPitanje1 = async (
    pagination: IPagination,
    search: string
  ): Promise<IPaginatedResponse<IGetDepFormAnswers>> => {
    const response = await sifarniciStore.fetchSifarnikOptions<IGetDepFormAnswers>(SIFARNICI_ROUTES.DEP_FORMS_ANSWERS, {
      page: pagination.page,
      size: pagination.size,
      search: search,
      answercode__in: '001,002',
      order_by: '+answer_sr',
    });
    return response;
  };

  fetchDepFormAnswersPitanje2 = async (
    pagination: IPagination,
    search: string
  ): Promise<IPaginatedResponse<IGetDepFormAnswers>> => {
    const response = await sifarniciStore.fetchSifarnikOptions<IGetDepFormAnswers>(SIFARNICI_ROUTES.DEP_FORMS_ANSWERS, {
      page: pagination.page,
      size: pagination.size,
      search: search,
      answercode__in: '003,103',
      order_by: '+answer_sr',
    });
    return response;
  };

  fetchDepFormAnswersPitanje3 = async (
    pagination: IPagination,
    search: string
  ): Promise<IPaginatedResponse<IGetDepFormAnswers>> => {
    const response = await sifarniciStore.fetchSifarnikOptions<IGetDepFormAnswers>(SIFARNICI_ROUTES.DEP_FORMS_ANSWERS, {
      page: pagination.page,
      size: pagination.size,
      search: search,
      answercode__in: '004,005,104',
      order_by: '+answer_sr',
    });
    return response;
  };

  fetchDepFormAnswersPitanje4 = async (
    pagination: IPagination,
    search: string
  ): Promise<IPaginatedResponse<IGetDepFormAnswers>> => {
    const response = await sifarniciStore.fetchSifarnikOptions<IGetDepFormAnswers>(SIFARNICI_ROUTES.DEP_FORMS_ANSWERS, {
      page: pagination.page,
      size: pagination.size,
      search: search,
      answercode__in: '006,007,008,009',
      order_by: '+answer_sr',
    });
    return response;
  };

  fetchDepFormAnswersPitanje5And10 = async (
    pagination: IPagination,
    search: string
  ): Promise<ResponseDepFormAnswers> => {
    const response = await sifarniciStore.fetchSifarnikOptions<IGetDepFormAnswers>(SIFARNICI_ROUTES.DEP_FORMS_ANSWERS, {
      page: pagination.page,
      size: pagination.size,
      search: search,
      answercode__in: '004,010,104',
      order_by: '+answer_sr',
    });
    return response;
  };

  fetchDepFormAnswersPitanje6 = async (
    pagination: IPagination,
    search: string
  ): Promise<IPaginatedResponse<IGetDepFormAnswers>> => {
    const response = await sifarniciStore.fetchSifarnikOptions<IGetDepFormAnswers>(SIFARNICI_ROUTES.DEP_FORMS_ANSWERS, {
      page: pagination.page,
      size: pagination.size,
      search: search,
      answercode__in: '011,012,013,014',
      order_by: '+answer_sr',
    });
    return response;
  };

  fetchDepFormAnswersPitanje7And8And12 = async (
    pagination: IPagination,
    search: string
  ): Promise<IPaginatedResponse<IGetDepFormAnswers>> => {
    const response = await sifarniciStore.fetchSifarnikOptions<IGetDepFormAnswers>(SIFARNICI_ROUTES.DEP_FORMS_ANSWERS, {
      page: pagination.page,
      size: pagination.size,
      search: search,
      answercode__in: '004,104',
      order_by: '+answer_sr',
    });
    return response;
  };

  fetchDepFormAnswersPitanje9 = async (
    pagination: IPagination,
    search: string
  ): Promise<IPaginatedResponse<IGetDepFormAnswers>> => {
    const response = await sifarniciStore.fetchSifarnikOptions<IGetDepFormAnswers>(SIFARNICI_ROUTES.DEP_FORMS_ANSWERS, {
      page: pagination.page,
      size: pagination.size,
      search: search,
      answercode__in: '015,016,017',
      order_by: '+answer_sr',
    });
    return response;
  };

  fetchDepFormAnswersPitanje11 = async (
    pagination: IPagination,
    search: string
  ): Promise<IPaginatedResponse<IGetDepFormAnswers>> => {
    const response = await sifarniciStore.fetchSifarnikOptions<IGetDepFormAnswers>(SIFARNICI_ROUTES.DEP_FORMS_ANSWERS, {
      page: pagination.page,
      size: pagination.size,
      search: search,
      answercode__in: '004,010,104,105',
      order_by: '+answer_sr',
    });
    return response;
  };
}

export const dep1Store = new Dep1Store();
