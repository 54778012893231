import { action, makeAutoObservable, observable } from 'mobx';
import { IBaseObrazacStore, IPaginatedResponse, IPagination } from 'typescript/NrizTypes';
import to from 'await-to-js';
import { obrasciService } from 'modules/obrasci/obrasci.service';
import { TIPOVI_OBRAZACA } from 'modules/obrasci/obrasci.types';
import { IEmisijeUTlo, IObrazac4Response, IPostEmisijeUTlo, IPostObrazac4 } from './obrazac4.types';
import { EMISIJE_U_TLO_INITIAL_STATE, OBRAZAC_4_INITIAL_STATE } from './obrazac4.constants';

import { SIFARNICI_ROUTES } from 'modules/sifarnici/sifarnici.types';
import { formStore } from 'store';
import { nrizService } from 'modules/nriz/nriz.service';
import dayjs from 'dayjs';
import { obrasciStore } from 'modules/obrasci/obrasci.store';
import { sifarniciStore } from 'modules/sifarnici/sifarnici.store';
import { handleSuccessNotify } from 'utils/succesHandling';
import { IGetSoil } from 'modules/sourcesOfPollution/soil/soil.types';
import { IGetDRList } from 'modules/sifarnici/store/dRList/dRList.types';
import { IGetMethodBasisCode } from 'modules/sifarnici/store/methodBasisCode/methodBasisCode.types';
import { IGetMethodTypes } from 'modules/sifarnici/store/methodTypes/methodTypes.types';
import { IGetPollutantCode } from 'modules/sifarnici/store/pollutantCode/pollutantCode.types';
import { IGetTypeOfWaste } from 'modules/sifarnici/store/typeOfWaste/typeOfWaste.types';
import { IGetWasteCatalogue } from 'modules/sifarnici/store/wasteCatalogue/wasteCatalogue.types';

class Obrazac4Store implements IBaseObrazacStore<IObrazac4Response, IPostObrazac4> {
  initialState: IPostObrazac4 = OBRAZAC_4_INITIAL_STATE;

  emisija: IPostEmisijeUTlo | IEmisijeUTlo = EMISIJE_U_TLO_INITIAL_STATE;
  izvor_zagadjenja_tla_id = '';

  filterNacinOdredjivanjaId = '';

  constructor() {
    makeAutoObservable(this, {
      izvor_zagadjenja_tla_id: observable,
      emisija: observable,
      handleChange: action,
      handleInitialStateChange: action,
      filterNacinOdredjivanjaId: observable,
    });
  }

  get getEmisija() {
    return this.emisija;
  }

  get getInitialState() {
    return this.initialState;
  }

  // DOHVATANJE SIFARNIKA

  // OPSTE

  fetchSourceSoil = async (pagination: IPagination, search: string): Promise<IPaginatedResponse<IGetSoil>> => {
    const response = await sifarniciStore.fetchSifarnikOptions<IGetSoil>(SIFARNICI_ROUTES.IZVOR_ZAGADJENJA_TLA, {
      page: pagination.page,
      size: pagination.size,
      postrojenje_id: obrasciStore.opste.postrojenje_id,
      search: search,
      order_by: '+naziv_lokacije',
    });
    return response;
  };

  // DODATNI PODACI

  fetchTypeOfWasteOptions = async (
    pagination: IPagination,
    search: string
  ): Promise<IPaginatedResponse<IGetTypeOfWaste>> => {
    const response = await sifarniciStore.fetchSifarnikOptions<IGetTypeOfWaste>(SIFARNICI_ROUTES.TYPE_OF_WASTE, {
      page: pagination.page,
      size: pagination.size,
      search: search,
      // order_by: '+name_sr', nema order
    });
    return response;
  };

  fetchDROptions = async (pagination: IPagination, search: string): Promise<IPaginatedResponse<IGetDRList>> => {
    const response = await sifarniciStore.fetchSifarnikOptions<IGetDRList>(SIFARNICI_ROUTES.DR_LIST, {
      search: search,
      name__in: 'D3,D2',
      order_by: '+name',
    });
    return response;
  };

  fetchWasteCatalogueOptions = async (
    pagination: IPagination,
    search: string
  ): Promise<IPaginatedResponse<IGetWasteCatalogue>> => {
    const response = await sifarniciStore.fetchSifarnikOptions<IGetWasteCatalogue>(SIFARNICI_ROUTES.WASTE_CATALOGUE, {
      page: pagination.page,
      size: pagination.size,
      search: search,
      level: 3,
      order_by: '+index_number',
    });
    return response;
  };

  // EMISIJE U TLO

  fetchPollutantOptions = async (
    pagination: IPagination,
    search: string
  ): Promise<IPaginatedResponse<IGetPollutantCode>> => {
    const response = await sifarniciStore.fetchSifarnikOptions<IGetPollutantCode>(SIFARNICI_ROUTES.POLLUTANT_CODE, {
      page: pagination.page,
      size: pagination.size,
      search: search,
      code: 'LAND',
      order_by: '+name_sr',
    });
    return response;
  };

  fetchMethodTypes = async (pagination: IPagination, search: string): Promise<IPaginatedResponse<IGetMethodTypes>> => {
    const response = await sifarniciStore.fetchSifarnikOptions<IGetMethodTypes>(SIFARNICI_ROUTES.METHOD_TYPES, {
      page: pagination.page,
      size: pagination.size,
      search: search,
      medium_code: 'LAND',
      method_basis_code_id: this.filterNacinOdredjivanjaId,
      order_by: '+name_sr',
    });
    return response;
  };

  fetchMethodBasisCode = async (
    pagination: IPagination,
    search: string
  ): Promise<IPaginatedResponse<IGetMethodBasisCode>> => {
    const response = await sifarniciStore.fetchSifarnikOptions<IGetMethodBasisCode>(
      SIFARNICI_ROUTES.METHOD_BASIS_CODE,
      {
        page: pagination.page,
        size: pagination.size,
        search: search,
        /// nema order ide name_sr
      }
    );
    return response;
  };

  // HELPERS AND CRUD FUNCTIONS

  handleChange(key: keyof Obrazac4Store, value: any) {
    (this as any)[key] = value;
  }

  handleInitialStateChange(key: keyof IPostObrazac4, value: any) {
    (this as any).initialState[key] = value;
  }

  loadData = async (id: string) => {
    const [err, res] = await to(obrasciService.fetchSingleObrazac<IObrazac4Response>(TIPOVI_OBRAZACA.OBRAZAC_4, id));
    if (err) return;
    const newGodina = dayjs().year(Number(res.godina));
    const initialState = { ...res, godina: newGodina };
    this.handleChange('initialState', initialState);
    this.handleChange('izvor_zagadjenja_tla_id', res.izvor_zagadjenja_tla_id);
    return res;
  };

  async postData(payload: IPostObrazac4) {
    const validObrazac = obrasciService.filtersSameObrazac(obrasciStore.real_obrazac_type as TIPOVI_OBRAZACA);
    const validPayload = { ...payload, ...validObrazac };
    const [err, res] = await to(
      obrasciService.postSingleObrazac<IPostObrazac4>(
        TIPOVI_OBRAZACA.OBRAZAC_4,
        nrizService.setEmptyValuesToNull(validPayload)
      )
    );
    if (err || !res) return;
    formStore.handleChange('goBack', true);
    handleSuccessNotify();
  }

  async putData(payload: any) {
    const [err, res] = await to(
      obrasciService.putSingleObrazac<IPostObrazac4>(TIPOVI_OBRAZACA.OBRAZAC_4, payload.id, payload)
    );
    if (err || !res) return;
    formStore.handleChange('goBack', true);
    handleSuccessNotify();
  }

  async deleteData(id: string) {
    const [err, res] = await to(obrasciService.deleteSingleObrazac(TIPOVI_OBRAZACA.OBRAZAC_4, id));
    if (err || !res) return;
    formStore.handleChange('goBack', true);
    handleSuccessNotify();
    // Only show succes message and redirect to Obrazac page
  }

  resetStates = () => {
    this.handleChange('izvor_zagadjenja_tla_id', '');
    this.handleChange('filterNacinOdredjivanjaId', '');
    this.handleChange('initialState', OBRAZAC_4_INITIAL_STATE);
  };

  submitEmisijeForm = (payload: IEmisijeUTlo) => {
    let newPayload = payload;
    let sifarnikPollutant = {};
    let sifarnikMethodType = {};
    let sifarnikMethodBasisCode = {};

    if (formStore.getCrudButtonType === 'submit' || formStore.getCrudButtonType === 'change') {
      sifarnikPollutant = nrizService.addSifarnikInObject({
        exId: (this.emisija as IEmisijeUTlo).pollutant_code_id,
        exObj: (this.emisija as IEmisijeUTlo).pollutant_code,
        list: sifarniciStore.list[SIFARNICI_ROUTES.POLLUTANT_CODE],
        name: 'pollutant_code',
        id: payload.pollutant_code_id,
      });
      sifarnikMethodType = nrizService.addSifarnikInObject({
        exId: (this.emisija as IEmisijeUTlo).method_types_id,
        exObj: (this.emisija as IEmisijeUTlo).method_types,
        list: sifarniciStore.list[SIFARNICI_ROUTES.METHOD_TYPES],
        name: 'method_types',
        id: payload.method_types_id,
      });
      sifarnikMethodBasisCode = nrizService.addSifarnikInObject({
        exId: (this.emisija as IEmisijeUTlo).method_basis_code_id,
        exObj: (this.emisija as IEmisijeUTlo).method_basis_code,
        list: sifarniciStore.list[SIFARNICI_ROUTES.METHOD_BASIS_CODE],
        name: 'method_basis_code',
        id: payload.method_basis_code_id,
      });
    }
    newPayload = {
      ...this.emisija,
      ...newPayload,
      ...sifarnikPollutant,
      ...sifarnikMethodType,
      ...sifarnikMethodBasisCode,
    };
    const newList = obrasciService.submitMicroServiceForm(this.initialState.emisije_u_tlo, newPayload);
    // handleSuccessNotify();
    this.handleChange('filterNacinOdredjivanjaId', '');
    this.handleInitialStateChange('emisije_u_tlo', newList);
    this.handleChange('emisija', EMISIJE_U_TLO_INITIAL_STATE);
  };

  submitData = (payload: IPostObrazac4) => {
    const newInitialState = {
      ...OBRAZAC_4_INITIAL_STATE,
      ...payload,
      id: (this.initialState as IObrazac4Response).id,
      godina: obrasciStore.opste.godina,
      emisije_u_tlo: this.initialState.emisije_u_tlo,
    } as IObrazac4Response;
    obrasciService.submitFormObrazac({
      payload: newInitialState,
      postForm: this.postData,
      putForm: this.putData,
      deleteForm: this.deleteData,
    });
  };
}

export const obrazac4Store = new Obrazac4Store();
