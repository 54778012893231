import { Alert, Col, Form } from 'antd';
import { FormInstance, useForm } from 'antd/es/form/Form';
import { Button } from 'components/Button/Button';
import { FormSwitch } from 'components/FormSwitch/FormSwitch';
import { t } from 'i18next';
import { observer } from 'mobx-react-lite';
import { USER_COMPANY_RESPONSIBILITY_INITIAL_STATE } from 'modules/user/user.constants';
// import { userInitialState } from 'modules/user/user.constants';
import { userStore } from 'modules/user/user.store';
import { IGetUser, IPostUserResponsibility } from 'modules/user/user.types';
import React, { useEffect } from 'react';
import { modalStore } from 'store';

interface IPropsUserCompanyRole {
  readOnly: boolean;
  passForm?: (form: FormInstance<IPostUserResponsibility>) => any;
  showAlert: boolean;
  showButton?: boolean;
}

export const NewUserCompanyResponsibilityForm: React.FC<IPropsUserCompanyRole> = observer(props => {
  const [form] = useForm();

  useEffect(() => {
    if (props.passForm) {
      props.passForm(form);
    }
    form.setFieldsValue({
      odgovorna_osoba_za_izvestavanje: (userStore.getUser as IGetUser).odgovorna_osoba_za_izvestavanje,
      odgovorna_osoba_preduzeca: (userStore.getUser as IGetUser).odgovorna_osoba_preduzeca,
      odgovorna_osoba_za_saradnju_sa_agencijom: (userStore.getUser as IGetUser)
        .odgovorna_osoba_za_saradnju_sa_agencijom,
    });
  }, [userStore.getUser]);

  // ubaciti ovaj return na nekom drugom mestu. zbog ove !userStore.isUserAdmin provere
  // kad se izabere korisnik koji jeste admin ovaj return setuje usera na initialState i ako ne treba

  // useEffect(() => {
  //   return () => {
  //     userStore.setUser(userInitialState);
  //   };
  // }, []);

  return (
    <Form
      form={form}
      initialValues={USER_COMPANY_RESPONSIBILITY_INITIAL_STATE}
      name={'user-responsibility'}
      labelCol={{ span: 12 }}
      wrapperCol={{ span: 12 }}
      layout="horizontal"
      onFinish={(values: IPostUserResponsibility) => {
        userStore.putUserCompanyResponsibility(values);
      }}
      labelAlign="left"
    >
      <FormSwitch
        labelCol={{ span: 22 }}
        wrapperCol={{ span: 2 }}
        name={'odgovorna_osoba_preduzeca'}
        label="Odgovorno lice preduzeća"
        disabled={props.readOnly || (userStore.getUser as IGetUser).odgovorna_osoba_preduzeca}
      />
      <FormSwitch
        labelCol={{ span: 22 }}
        wrapperCol={{ span: 2 }}
        name={'odgovorna_osoba_za_saradnju_sa_agencijom'}
        label="Odgovorno lice preduzeća za saradnju sa Agencijom"
        disabled={props.readOnly || (userStore.getUser as IGetUser).odgovorna_osoba_za_saradnju_sa_agencijom}
      />
      <FormSwitch
        labelCol={{ span: 22 }}
        wrapperCol={{ span: 2 }}
        label="Odgovorno lice za izveštavanje"
        name={'odgovorna_osoba_za_izvestavanje'}
        disabled={props.readOnly || (userStore.getUser as IGetUser).odgovorna_osoba_za_izvestavanje}
      />

      {!props.readOnly && props.showAlert && (
        <Alert
          style={{ width: '100%' }}
          showIcon={true}
          message="Ukoliko želite da promenite pozitivnu vrednost, potrebno je novom korisniku dodeliti pozitivnu vrednost za tu odgovornost, a sistem će dodeliti negativnu vrednost trenutnom korisniku "
          type="info"
        />
      )}

      {props.showButton && (
        <Col style={{ display: 'flex', justifyContent: 'flex-end', marginTop: '20px' }}>
          <Button disabled={modalStore.isLoading} htmlType="submit" name="submit-button-korisnik-responsibility">
            {t('SUBMIT')}
          </Button>
        </Col>
      )}
    </Form>
  );
});
