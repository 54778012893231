import { makeAutoObservable } from 'mobx';
import {
  IGetDeterdzent,
  IGetDjubrivo,
  IGetPzv1Obrazac,
  IPostDeterdzent,
  IPostDjubrivo,
  IPostZastitaBilja,
  IGetZastitaBilja,
  IPostPzv1Obrazac,
  Pzv1Korekcije,
} from './pzv1.types';

import to from 'await-to-js';
import { TIPOVI_OBRAZACA } from 'modules/obrasci/obrasci.types';
import { obrasciService } from 'modules/obrasci/obrasci.service';
import { IBaseObrazacStore, IEmptyResponse, IPaginatedResponse, IPagination } from 'typescript/NrizTypes';
import { formStore } from 'store';
import { nrizService } from 'modules/nriz/nriz.service';
import dayjs from 'dayjs';
import {
  DETERDZENTI_INITIAL_STATE,
  DJUBRIVA_INITIAL_STATE,
  PZV1_INITIAL_STATE,
  ZASTITA_BILJA_INITIAL_STATE,
} from './pzv1.constants';
import { obrasciStore } from 'modules/obrasci/obrasci.store';
import { sifarniciStore } from 'modules/sifarnici/sifarnici.store';
import { SIFARNICI_ROUTES } from 'modules/sifarnici/sifarnici.types';
import { handleSuccessNotify } from 'utils/succesHandling';
import { IGetProductsPzv } from 'modules/sifarnici/store/productsPzv/productsPzv.types';
import { pzv1Service } from './pzv1.service';

class PZV1ObrazacStore implements IBaseObrazacStore<IGetPzv1Obrazac, IPostPzv1Obrazac> {
  djubrivo: IPostDjubrivo | IGetDjubrivo = DJUBRIVA_INITIAL_STATE;
  deterdzent: IPostDeterdzent | IGetDeterdzent = DETERDZENTI_INITIAL_STATE;
  zastitaBilja: IPostZastitaBilja | IGetZastitaBilja = ZASTITA_BILJA_INITIAL_STATE;

  initialState: IPostPzv1Obrazac | IGetPzv1Obrazac = PZV1_INITIAL_STATE;

  readOnly = false;

  constructor() {
    makeAutoObservable(this);
  }

  get getInitialState() {
    return this.initialState;
  }

  get getDozvoljeneKorekcije() {
    const { korekcija_deterdzenta, korekcija_mineralnog_djubriva, korekcija_zastite_bilja } = this.initialState;
    return korekcija_deterdzenta || korekcija_mineralnog_djubriva || korekcija_zastite_bilja;
  }

  get getDozvoljeneKorekcijePoTipu() {
    return (type: 'korekcija_deterdzenta' | 'korekcija_mineralnog_djubriva' | 'korekcija_zastite_bilja') => {
      return this.initialState[type] ?? true;
    };
  }

  setReadOnly() {
    this.readOnly = true;
  }

  clearReadOnly() {
    this.readOnly = false;
  }

  handleChange(key: string, value: any) {
    (this as any)[key] = value;
  }

  handleInitialStateChange(key: string, value: any) {
    (this as any).initialState[key] = value;
  }

  // OBRAZAC DATA

  loadData = async (id: string) => {
    const [err, res] = await to(obrasciService.fetchSingleObrazac<IGetPzv1Obrazac>(TIPOVI_OBRAZACA.PZV1, id));
    if (err) return;
    const newGodina = dayjs().year(Number(res.godina));
    this.handleChange('initialState', { ...res, godina: newGodina });
    return res;
  };

  async postData(payload: IPostPzv1Obrazac) {
    const [err, res] = await to(obrasciService.postSingleObrazac<IPostPzv1Obrazac>(TIPOVI_OBRAZACA.PZV1, payload));
    if (err || !res) return;
    formStore.handleChange('goBack', true);
    handleSuccessNotify();
  }

  async putData(payload: IGetPzv1Obrazac) {
    const [err, res] = await to(
      obrasciService.putSingleObrazac<IGetPzv1Obrazac>(TIPOVI_OBRAZACA.PZV1, payload.id, payload)
    );
    if (err || !res) return;
    formStore.handleChange('goBack', true);
    handleSuccessNotify();
  }

  async deleteData(id: string) {
    const [err, res] = await to<IEmptyResponse>(obrasciService.deleteSingleObrazac(TIPOVI_OBRAZACA.PZV1, id));
    if (err || !res) return;
    formStore.handleChange('goBack', true);
    handleSuccessNotify();
  }

  resetStates = () => {
    this.handleChange('initialState', PZV1_INITIAL_STATE);
  };

  submitKorekcije = async (payload: Pzv1Korekcije, id: string) => {
    const [err, res] = await to(pzv1Service.submitKorekcije(payload, id));
    if (err || !res) return;

    formStore.handleChange('goBack', true);
    handleSuccessNotify();
  };

  submitData = (payload: IPostPzv1Obrazac) => {
    const newInitialState = {
      ...PZV1_INITIAL_STATE,
      ...payload,
      id: (this.initialState as IGetPzv1Obrazac).id,
      godina: obrasciStore.opste.godina,
      deterdzenti: this.initialState.deterdzenti,
      mineralna_djubriva: this.initialState.mineralna_djubriva,
      zastita_bilja: this.initialState.zastita_bilja,
    } as IGetPzv1Obrazac;

    obrasciService.submitFormObrazac({
      payload: newInitialState,
      postForm: this.postData,
      putForm: this.putData,
      deleteForm: this.deleteData,
    });
  };

  // DETERDZENTI

  get getDeterdzent() {
    return this.deterdzent;
  }

  setDeterdzent(value: any) {
    this.deterdzent = value;
  }

  fetchDeterdzentOptions = async (
    pagination: IPagination,
    search: string
  ): Promise<IPaginatedResponse<IGetProductsPzv>> => {
    const response = await sifarniciStore.fetchSifarnikOptions<IGetProductsPzv>(SIFARNICI_ROUTES.PRODUCTS_PZV, {
      page: pagination.page,
      size: pagination.size,
      detergent: true,
      validity_from_year__lte: obrasciStore.opste.godina,
      validity_to_year__gte: obrasciStore.opste.godina,
      search: search,
      order_by: '+name',
    });
    return response;
  };

  submitDeterdzentiForm = (payload: IGetDeterdzent) => {
    let newPayload = payload;
    let sifarnikPZVProizvod = {};

    if (formStore.getCrudButtonType === 'submit' || formStore.getCrudButtonType === 'change') {
      sifarnikPZVProizvod = nrizService.addSifarnikInObject({
        exId: (this.deterdzent as IGetDeterdzent).products_pzv_id,
        exObj: (this.deterdzent as IGetDeterdzent).products_pzv,
        list: sifarniciStore.list[SIFARNICI_ROUTES.PRODUCTS_PZV],
        name: 'products_pzv',
        id: payload.products_pzv_id,
      });
    }

    newPayload = { ...this.deterdzent, ...newPayload, ...sifarnikPZVProizvod };
    const newList = obrasciService.submitMicroServiceForm(this.initialState.deterdzenti, newPayload);
    // handleSuccessNotify();
    this.handleInitialStateChange('deterdzenti', newList);
    this.handleChange('deterdzent', DETERDZENTI_INITIAL_STATE);
  };

  // DJUBRIVA

  get getDjubrivo() {
    return this.djubrivo;
  }

  setDjubrivo(value: any) {
    this.djubrivo = value;
  }

  fetchDjubrivoOptions = async (
    pagination: IPagination,
    search: string
  ): Promise<IPaginatedResponse<IGetProductsPzv>> => {
    const response = await sifarniciStore.fetchSifarnikOptions<IGetProductsPzv>(SIFARNICI_ROUTES.PRODUCTS_PZV, {
      page: pagination.page,
      size: pagination.size,
      fertilizer: true,
      validity_from_year__lte: obrasciStore.opste.godina,
      validity_to_year__gte: obrasciStore.opste.godina,
      search: search,
      order_by: '+name',
    });
    return response;
  };

  submitDjubrivaForm = (payload: IGetDjubrivo) => {
    let newPayload = payload;
    let sifarnikPZVProizvod = {};

    if (formStore.getCrudButtonType === 'submit' || formStore.getCrudButtonType === 'change') {
      sifarnikPZVProizvod = nrizService.addSifarnikInObject({
        exId: (this.djubrivo as IGetDjubrivo).products_pzv_id,
        exObj: (this.djubrivo as IGetDjubrivo).products_pzv,
        list: sifarniciStore.list[SIFARNICI_ROUTES.PRODUCTS_PZV],
        name: 'products_pzv',
        id: payload.products_pzv_id,
      });
    }
    newPayload = { ...this.djubrivo, ...newPayload, ...sifarnikPZVProizvod };
    const newList = obrasciService.submitMicroServiceForm(this.initialState.mineralna_djubriva, newPayload);
    // handleSuccessNotify();
    this.handleInitialStateChange('mineralna_djubriva', newList);
    this.handleChange('djubrivo', DJUBRIVA_INITIAL_STATE);
  };

  // ZASTITA BILJA

  get getZastitaBilja() {
    return this.zastitaBilja;
  }

  setZastitaBilja(value: any) {
    this.zastitaBilja = value;
  }

  fetchZastitaBiljaOptions = async (
    pagination: IPagination,
    search: string
  ): Promise<IPaginatedResponse<IGetProductsPzv>> => {
    const response = await sifarniciStore.fetchSifarnikOptions<IGetProductsPzv>(SIFARNICI_ROUTES.PRODUCTS_PZV, {
      page: pagination.page,
      size: pagination.size,
      plant_protection: true,
      validity_from_year__lte: obrasciStore.opste.godina,
      validity_to_year__gte: obrasciStore.opste.godina,
      search: search,
      order_by: '+name',
    });
    return response;
  };

  submitZastitaBiljaForm = (payload: IPostZastitaBilja) => {
    let newPayload = payload;
    let sifarnikPZVProizvod = {};

    if (formStore.getCrudButtonType === 'submit' || formStore.getCrudButtonType === 'change') {
      sifarnikPZVProizvod = nrizService.addSifarnikInObject({
        exId: (this.zastitaBilja as IGetZastitaBilja).products_pzv_id,
        exObj: (this.zastitaBilja as IGetZastitaBilja).products_pzv,
        list: sifarniciStore.list[SIFARNICI_ROUTES.PRODUCTS_PZV],
        name: 'products_pzv',
        id: payload.products_pzv_id,
      });
    }
    newPayload = {
      ...newPayload,
      ...sifarnikPZVProizvod,
      id: (this.zastitaBilja as IGetZastitaBilja).id,
    } as IGetZastitaBilja;
    const newList = obrasciService.submitMicroServiceForm(this.initialState.zastita_bilja, newPayload);
    // handleSuccessNotify();
    this.handleInitialStateChange('zastita_bilja', newList);
    this.handleChange('zastitaBilja', ZASTITA_BILJA_INITIAL_STATE);
  };
}

export const pzv1ObrazacStore = new PZV1ObrazacStore();
