import dayjs from 'dayjs';
import { istorijaPromenaRepo } from './istorijaPromena.repo';
import { omit } from 'lodash';
import { nrizService } from 'modules/nriz/nriz.service';
import { IPaginatedApiResponse } from 'typescript/NrizTypes';
import { IGetIstorijaPromena } from './istorijaPromena.types';

class IstorijaPromenaService {
  fetchIstorijaPromenaList = (filters: any): IPaginatedApiResponse<IGetIstorijaPromena> => {
    return istorijaPromenaRepo.fetchIstorijaPromenaList(filters);
  };

  parseNestedJSON = (input: any): any => {
    if (typeof input === 'string') {
      try {
        const parsed = JSON.parse(input);
        return this.parseNestedJSON(parsed);
      } catch (e) {
        return input;
      }
    } else if (Array.isArray(input)) {
      return input.map(item => this.parseNestedJSON(item));
    } else if (typeof input === 'object' && input !== null) {
      const result: { [key: string]: any } = {};
      for (const key in input) {
        // eslint-disable-next-line no-prototype-builtins
        if (input.hasOwnProperty(key)) {
          result[key] = this.parseNestedJSON(input[key]);
        }
      }
      return result;
    }
    return input;
  };

  makeFiltersForIstorijaPromena = (filters: any) => {
    const newFilters = {
      ...filters,
      timestamp__gte: filters.timestamp_od ? dayjs(filters.timestamp_od).second(0).millisecond(0).toISOString() : '',
      timestamp__lte: filters.timestamp_do ? dayjs(filters.timestamp_do).second(0).millisecond(0).toISOString() : '',
    };

    const filterdObj = omit(newFilters, ['timestamp_od', 'timestamp_do']);
    return nrizService.pickFields(filterdObj);
  };
}

export const istorijaPromenaService = new IstorijaPromenaService();
