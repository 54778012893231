import { EditOutlined, EyeOutlined } from '@ant-design/icons';
import { Button } from 'antd';
import { observer } from 'mobx-react-lite';
import { modalStore } from 'store';
import { IBaseObrazacStore } from 'typescript/NrizTypes';

export interface EditButtonProps<T> {
  record: T;
}

interface PreviewBtnProps {
  preview: boolean;
  name: string;
  modalName: string;
  onClick: () => void;
  store?: IBaseObrazacStore;
}

export const RowEditPreviewButton: React.FC<PreviewBtnProps> = observer(
  ({ preview, name, modalName, onClick, store }) => {
    const onBtnClick = () => {
      modalStore.changeModalName(modalName);
      onClick();
      preview && store && store.setReadOnly && store.setReadOnly();
    };

    const renderIcon = () => {
      return preview ? <EyeOutlined /> : <EditOutlined />;
    };

    return <Button name={`${name}-preview-edit-btn`} icon={renderIcon()} onClick={onBtnClick} disabled={false} />;
  }
);
