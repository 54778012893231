import { LeftOutlined } from '@ant-design/icons';
import { Button } from 'components/Button/Button';
import { observer } from 'mobx-react-lite';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import styles from './BackButton.module.scss';
import { IBackButtonProps } from 'typescript/NrizTypes';

export const BackButton: React.FC<IBackButtonProps> = observer(props => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  return (
    <Button
      className={styles.btn}
      type={props.type ?? 'ghost'}
      onClick={() => navigate(props.route)}
      name={`back-${props.name}`}
    >
      <LeftOutlined />
      {t('BACK')}
    </Button>
  );
});
