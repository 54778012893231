import React from 'react';
import { InsideContentWrapper } from 'components/InsideContentWrapper/InsideContentWrapper';
import { WizardObrazacListHeader } from 'components/WizardObrazacListHeader/WizardObrazacListHeader';
import { observer } from 'mobx-react-lite';
import { ColumnsType } from 'antd/es/table';
import TableData from 'components/TableData/TableData';
import { useTranslation } from 'react-i18next';
import { gio4ObrazacStore } from 'modules/obrasci/store/gio4/gio4.store';
import { IGio4RazrediEeOtpada } from 'modules/obrasci/store/gio4/gio4.types';
import { EditButtonProps, RowEditPreviewButton } from 'components/RowEditPreviewButton/RowEditPreviewButton';
import { obrasciStore } from 'modules/obrasci/obrasci.store';

const EditGio4ElektricniOtpadButton: React.FC<EditButtonProps<IGio4RazrediEeOtpada>> = observer(({ record }) => {
  return (
    <RowEditPreviewButton
      name="gio4-razredi-ee"
      modalName="gio4-razredi-ee-modal"
      onClick={() => gio4ObrazacStore.handleChange('razredi_ee_form', record)}
      preview={obrasciStore.zakljucanObrazac}
    />
  );
});

export const Gio4ElektricniOtpadTable: React.FC = observer(() => {
  const { t } = useTranslation();

  // todo proveriti koji label se mapira za razrede
  // TODO Proveriti razrede option maker-e tabela forma default

  const baseColumns: ColumnsType<IGio4RazrediEeOtpada> = [
    {
      title: `${t('OBRASCI.GIO_3.CLASS_EE_WASTE')}`,
      width: 100,
      key: 'category_name',
      render: (_: any, record: IGio4RazrediEeOtpada) => (
        <>{`${record.razred_ee_otpada.the_order} ${record.razred_ee_otpada.category_name_sr}`}</>
      ),
    },
    {
      title: `${t('OBRASCI.GIO_3.QUANTITY_T')}`,
      width: 100,
      dataIndex: 'kolicina',
      key: 'kolicina',
    },
    {
      title: '',
      key: 'operation',
      fixed: 'right',
      width: 40,
      render: (_, record) => <EditGio4ElektricniOtpadButton record={record} />,
    },
  ];

  return (
    <InsideContentWrapper
      header={
        <WizardObrazacListHeader
          title={t('OBRASCI.GIO4.EE_TYPES')}
          name={'gio4-razredi-ee-form'}
          modalName="gio4-razredi-ee-modal"
        />
      }
    >
      <TableData
        name="elektricni-otad-gio4"
        columns={baseColumns}
        dataSource={[...gio4ObrazacStore.izvozOtpada.razredi_ee_otpada]}
      />
    </InsideContentWrapper>
  );
});
