import { observer } from 'mobx-react-lite';
import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import styles from './FacilitySurvey.module.scss';
import { FormInput } from 'components/FormInput/FormInput';
import { FormSearchInfiniteScroll } from 'components/FormSearchInfiniteScroll/FormSearchInfiniteScroll';
import { facilityStore } from 'modules/facility/facility.store';
import {
  REQUIRED_FIELD_RULE,
  MAX_LENGTH_RULE,
  POST_NUMBER_FIELD_RULE,
  invalidCharactersForInputNumber,
  MORE_THEN_0_IS_REQUIRED,
  LATITUDE_RULE,
  LONGITUDE_RULE,
} from 'modules/nriz/nriz.constants';
import { IGetCompany } from 'modules/company/company.types';
import { companyStore } from 'modules/company/company.store';
import useFormInstance from 'antd/es/form/hooks/useFormInstance';
import { FormInputNumber } from 'components/FormInputNumber/FormInputNumber';

export const FacilitySurvey: React.FC = observer(() => {
  const form = useFormInstance();

  const { t } = useTranslation();

  const { mesto } = companyStore.company as IGetCompany;

  useEffect(() => {
    const fieldsToDestructure: (keyof IGetCompany)[] = [
      'naziv',
      'mesto_id',
      'postanski_broj',
      'ulica',
      'kucni_broj',
      'telefon',
    ];
    fieldsToDestructure.forEach(field => {
      const { [field]: fieldValue } = companyStore.company as IGetCompany;
      if (field === 'naziv') form.setFieldValue(['postrojenje', 'naziv_postrojenja'], fieldValue);
      else form.setFieldValue(['postrojenje', field], fieldValue);
    });
  }, []);

  const defaultMestoOption = mesto ? { label: mesto.display_name, value: mesto.id } : undefined;
  return (
    <div className={styles.facility_survey_form}>
      <h1>{t('FACILITY.TITLE')}:</h1>
      <>
        <FormInput
          name={['postrojenje', 'naziv_postrojenja']}
          rules={[REQUIRED_FIELD_RULE(true), MAX_LENGTH_RULE(100)]}
          type="text"
          placeholder={t('FACILITY.PLACEHOLDER.ENTER_NAME')}
          label={t('FACILITY.NAME')}
          inputName={'naziv_postrojenja'}
          colon={true}
        />

        <FormSearchInfiniteScroll
          showSearch={true}
          formName={['postrojenje', 'mesto_id']}
          fetchOptinsList={facilityStore.fetchMesto}
          labelOptionAccessor="display_name"
          valueOptionAccessor="id"
          placeholder={t('COMPANY.PLACEHOLDER.ENTER_CITY')}
          label={t('COMPANY.LOCATION')}
          inputName={'mesto_id'}
          rules={[REQUIRED_FIELD_RULE(true)]}
          colon={true}
          defaultOption={defaultMestoOption}
        />
        <FormInput
          name={['postrojenje', 'postanski_broj']}
          rules={[...MORE_THEN_0_IS_REQUIRED, POST_NUMBER_FIELD_RULE]}
          type="number"
          placeholder={t('COMPANY.PLACEHOLDER.ENTER_POST_NUMBER')}
          label={t('COMPANY.POST_NUMBER')}
          inputName={'postanski_broj'}
          colon={true}
          className={styles.removeArrow}
          invalidCharacters={invalidCharactersForInputNumber}
        />
        <FormInput
          name={['postrojenje', 'ulica']}
          rules={[REQUIRED_FIELD_RULE(true), MAX_LENGTH_RULE(50)]}
          type="text"
          placeholder={t('COMPANY.PLACEHOLDER.ENTER_ADDRESS')}
          label={t('COMPANY.ADDRESS')}
          inputName={'ulica'}
          colon={true}
        />
        <FormInput
          name={['postrojenje', 'kucni_broj']}
          rules={[REQUIRED_FIELD_RULE(true), MAX_LENGTH_RULE(20)]}
          type="text"
          placeholder={t('COMPANY.PLACEHOLDER.ENTER_ADDRESS_NUMBER')}
          label={t('COMPANY.ADDRESS_NUMBER')}
          inputName={'kucni_broj'}
          colon={true}
          className={styles.removeArrow}
        />
        <FormInput
          rules={[REQUIRED_FIELD_RULE(true)]}
          label={t('USER.PHONE')}
          name={['postrojenje', 'telefon']}
          inputName={'telefon'}
          colon={true}
          type={'text'}
          placeholder={t('COMPANY.PLACEHOLDER.ENTER_PHONE_NUMBER')}
          className={styles.removeArrow}
        />
        <FormInputNumber
          name={['postrojenje', 'koordinate_lat']}
          placeholder={t('FACILITY.PLACEHOLDER.ENTER_LATITUDE')}
          label={t('COMPANY.LATITUDE')}
          inputName={'koordinate_lat'}
          colon={true}
          className={styles.removeArrow}
          rules={[REQUIRED_FIELD_RULE(true), ...LATITUDE_RULE()]}
        />
        <FormInputNumber
          name={['postrojenje', 'koordinate_long']}
          placeholder={t('FACILITY.PLACEHOLDER.ENTER_LONGITUDE')}
          label={t('COMPANY.LONGITUDE')}
          inputName={'koordinate_long'}
          colon={true}
          className={styles.removeArrow}
          rules={[REQUIRED_FIELD_RULE(true), ...LONGITUDE_RULE()]}
        />
        {true && (
          <h4 style={{ marginBlock: '30px', fontWeight: 'bold' }}>
            {t('KOORDINATE_TEXT')}
            <a target="_blank" href="http://www.nrizgis.sepa.gov.rs/nrizgis/kor/">
              http//www.nrizgis.sepa.gov.rs/nrizgis/kor/
            </a>
          </h4>
        )}
      </>
    </div>
  );
});
