import { makeAutoObservable } from 'mobx';

class FormStore {
  constructor() {
    makeAutoObservable(this);
  }

  readOnly = false;
  crudButtonType = '';
  goBack = false;
  error: string[] = [];

  get getCrudButtonType() {
    return this.crudButtonType;
  }
  get getGoBack() {
    return this.goBack;
  }

  handleChange = (type: string, value: any) => {
    (this as any)[type] = value;
  };

  setCrudButtonType = (value: string) => {
    this.crudButtonType = value;
  };
}

export const formStore = new FormStore();
