import { Content } from 'components/Content/Content';
import { observer } from 'mobx-react-lite';
import { ZakljucavanjePoTipuHeader } from './components/ZakljucavanjePoTipuHeader/ZakljucavanjePoTipuHeader';
import { useForm } from 'antd/es/form/Form';
import { Form } from 'antd';
import { useEffect, useMemo } from 'react';
import { zakljucavanjeStore } from 'modules/zakljucavanje/zakljucavanje.store';
import { ZakljucavanjePoTipuForm } from './components/ZakljucavanjePoTipuForm/ZakljucavanjePoTipuForm';
import { ZakljucavanjePoTipuTable } from './components/ZakljucavanjePoTipuTable/ZakljucavanjePoTipuTable';
import { zakljucavanjeService } from 'modules/zakljucavanje/zakljucavanje.service';
import { ColumnType } from 'antd/es/table';
import { IFormScrollWithObjProps } from 'typescript';
import { zakljucavanjeFormInitialState } from 'modules/zakljucavanje/zakljucavanje.constants';
import { paginationInitialState } from 'modules/nriz/nriz.constants';

export const ZakljucavanjePoTipu = observer(() => {
  const [form] = useForm();
  useEffect(() => {
    zakljucavanjeStore.fetchGodine();
    return () => {
      zakljucavanjeStore.setObrasciZaZakljucavanje([]);
      zakljucavanjeStore.setSelectedTipObrasca(null);
      zakljucavanjeStore.setPagination(paginationInitialState);
      form.resetFields();
    };
  }, []);

  const selectedTipObrascaConfig: { fields: IFormScrollWithObjProps[]; columns: ColumnType<any>[] } = useMemo(() => {
    const { fields, columns } = zakljucavanjeService.getConfigByTipObrasca(zakljucavanjeStore.selectedTipObrasca, form);
    return { fields, columns };
  }, [Form.useWatch([], form), zakljucavanjeStore.selectedTipObrasca]);

  return (
    <Content header={<ZakljucavanjePoTipuHeader form={form} />}>
      <Form form={form} initialValues={zakljucavanjeFormInitialState}>
        <ZakljucavanjePoTipuForm selectedTipObrascaFields={selectedTipObrascaConfig.fields} />
        <ZakljucavanjePoTipuTable selectedTipObrascaColumns={selectedTipObrascaConfig.columns} />
      </Form>
    </Content>
  );
});
