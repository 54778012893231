import { Form, FormInstance } from 'antd';
import { FormComponents } from 'components/FormComponents/FormComponents';
import { observer } from 'mobx-react-lite';
import { ISifarniciTypeOfForm } from 'modules/sifarnici/sifarnici.types';
import { PRODUCTS_TYPE_FORM_OPTIONS } from 'modules/sifarnici/store/productsNr/productsNr.constants';
import { IGetProductsType } from 'modules/sifarnici/store/productsNr/productsNr.types';
import React, { useMemo } from 'react';

interface ProductsTypeProps {
  parentForm: FormInstance<any>;
  formListName: string;
}

export const ProductsTypeFormModal: React.FC<ProductsTypeProps> = observer(() => {
  const form = Form.useFormInstance<IGetProductsType>();

  const components = useMemo(() => {
    return PRODUCTS_TYPE_FORM_OPTIONS(form);
  }, [Form.useWatch([], form)]);

  return (
    <div>
      {components.map((item: ISifarniciTypeOfForm, index) => {
        return <FormComponents data={item} key={index} />;
      })}
    </div>
  );
});
