import TableData from 'components/TableData/TableData';
import { observer } from 'mobx-react-lite';
import { INewAuditLogs, istorijaPromenaStore } from 'modules/istorijaPromena/istorijaPromena.store';
import {
  ISTORIJA_PROMENA_HEADER_DATA,
  ISTORIJA_PROMENA_TABLE_DATA,
} from 'modules/istorijaPromena/istroijaPromena.constants';

import React from 'react';

export const IstorijaPromenaTable: React.FC = observer(() => {
  const expandedRowRender = (record: INewAuditLogs) => {
    return (
      <TableData
        // virtual={true}
        columns={ISTORIJA_PROMENA_TABLE_DATA}
        dataSource={record.audit_logs}
        name={'istorija-expanded'}
      />
    );
  };
  return (
    <TableData
      columns={ISTORIJA_PROMENA_HEADER_DATA}
      scrollConfig={['#istorijaPromenaFilters']}
      dataSource={istorijaPromenaStore.getIstorijaPromenaList}
      name={'istorija-promena-table'}
      refetchData={istorijaPromenaStore.fetchIstorijuPromena}
      storePagination={istorijaPromenaStore.istorijaPromenaPagiantion}
      expandable={{ expandedRowRender }}
    />
  );
});
