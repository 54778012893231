import { Row } from 'antd';
import { Button } from 'components/Button/Button';
import { FormInput } from 'components/FormInput/FormInput';
import { ICrudTypeModalProps } from 'components/Modals/CRUDModal/CRUDModal';
import { observer } from 'mobx-react-lite';
import { MAX_AND_MIN_NUMBER, REQUIRED_FIELD_RULE } from 'modules/nriz/nriz.constants';
import { gveStore } from 'modules/obrasci/store/gve/gve.store';
import { useTranslation } from 'react-i18next';
import { modalStore } from 'store';

export const NewGveGodisnjiPitanjeForm: React.FC<ICrudTypeModalProps> = observer(props => {
  const { t } = useTranslation();
  const form = props.form;

  const handleGodisnjiSubmit = async () => {
    const ukupna = Number(form.getFieldValue('ukupan_broj_radnih_sati_godisnje'));
    try {
      await form.validateFields(['ukupan_broj_radnih_sati_godisnje']);
      gveStore.calculateGodisnjiBilans(gveStore.initialState.emisije_u_vazduh, ukupna);
      modalStore.removeAllModals();
    } catch {
      return;
    }
  };

  const isUkupanBrojSatiRequired = gveStore.showEmisijeUVazduh
    ? !!gveStore.initialState.kreiranje_godisnjeg_bilansa
    : !!gveStore.initialState.emisije_u_vazduh.length;

  return (
    <>
      <FormInput
        name="ukupan_broj_radnih_sati_godisnje"
        label={t('OBRASCI.GVE.UKUPAN_BROJ_RADNIH_SATI')}
        type="number"
        placeholder={''}
        inputName={'ukupan_broj_radnih_sati_godisnje'}
        colon={true}
        rules={[REQUIRED_FIELD_RULE(isUkupanBrojSatiRequired), ...MAX_AND_MIN_NUMBER(1, 8784)]}
        labelCol={{ span: 12 }}
        wrapperCol={{ span: 12 }}
      />

      <Row justify="end">
        <Button onClick={handleGodisnjiSubmit} disabled={modalStore.isLoading} name={'generate_bilans'}>
          {t('OBRASCI.GVE.BILANS_GENERISI')}
        </Button>
      </Row>
    </>
  );
});
