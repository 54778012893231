import { Tabs } from 'antd';
import { observer } from 'mobx-react-lite';
import { filtersStore } from 'store/filteri.store';
import { nrizService } from 'modules/nriz/nriz.service';
import { obrasciStore } from 'modules/obrasci/obrasci.store';
import { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { IGetCompany } from 'modules/company/company.types';
import { IGetFacility } from 'modules/facility/facility.types';
import styles from './ObrasciCompanyUserFilters.module.scss';
import { Select } from 'components/Select/Select';
import storage, { StorageEnum } from 'store/storage';
import { companyStore } from 'modules/company/company.store';
import { facilityStore } from 'modules/facility/facility.store';
import { authStore } from 'store';
import { DefaultOptionType } from 'antd/es/select';

export const ObrasciCompanyUserFilters: React.FC = observer(() => {
  enum ActiveObrasciTabEnum {
    Preduzece = '1',
    Postrojenje = '2',
  }

  const [activeTab, setActiveTab] = useState<ActiveObrasciTabEnum>(ActiveObrasciTabEnum.Preduzece);
  const [defaultPostrojenje, setDefaultPostrojenje] = useState<IGetFacility>();

  const { t } = useTranslation();

  const handlePostrojenjeChange = async (facility: IGetFacility) => {
    obrasciStore.resetTipObrasca();
    storage.setData(StorageEnum.POSTROJENJE, facility);
    obrasciStore.handleChange('opste', {
      ...obrasciStore.opste,
      postrojenje_id: facility.id,
      postrojenje: facility,
    });
    setDefaultPostrojenje(obrasciStore.opste.postrojenje as IGetFacility);
    await obrasciStore.fetchTipoviObrazacaPoPostrojenju();
    filtersStore.setFilters('obrasci', {});
  };

  const handleFacilityUserUseEffect = async () => {
    setActiveTab(ActiveObrasciTabEnum.Postrojenje);
    await facilityStore.fetchFacilityListByUser({
      page: 1,
      size: 100,
    });
    if (facilityStore.facilityList.length) {
      obrasciStore.handleChange('opste', {
        ...obrasciStore.opste,
        postrojenje_id: (facilityStore.facilityList[0] as IGetFacility).id,
        postrojenje: facilityStore.facilityList[0],
      });
      setDefaultPostrojenje(obrasciStore.opste.postrojenje as IGetFacility);
      storage.setData(StorageEnum.POSTROJENJE, obrasciStore.opste.postrojenje);
      obrasciStore.fetchTipoviObrazacaPoPostrojenju();
    } else {
      obrasciStore.setTipoviObrazaca([]);
      obrasciStore.resetTipObrasca();
    }
  };

  useEffect(() => {
    obrasciStore.handleChange('opste', {
      ...obrasciStore.opste,
      preduzece_id: (companyStore.company as IGetCompany).id,
      preduzece: companyStore.company as IGetCompany,
    });

    storage.setData(StorageEnum.PREDUZECE, companyStore.getCompany as IGetCompany);

    if (obrasciStore.opste.postrojenje_id) {
      setActiveTab(ActiveObrasciTabEnum.Postrojenje);
      setDefaultPostrojenje(obrasciStore.opste.postrojenje as IGetFacility);
      obrasciStore.fetchTipoviObrazacaPoPostrojenju();
    } else if (authStore.isOnlyFacilityUser) {
      handleFacilityUserUseEffect();
    } else {
      setActiveTab(ActiveObrasciTabEnum.Preduzece);
      obrasciStore.fetchTipoviObrazacaPoPreduzecu();
      storage.removeData(StorageEnum.POSTROJENJE);
      if (!authStore.isOnlyCompanyUser) {
        facilityStore.fetchFacilityListByUser({
          page: 1,
          size: 100,
        });
      }
    }
  }, []);

  const onTabChange = (tab: string) => {
    filtersStore.setFilters('obrasci', {});
    obrasciStore.setTipoviObrazaca([]);
    obrasciStore.resetTipObrasca();
    if (tab === ActiveObrasciTabEnum.Preduzece) {
      setActiveTab(ActiveObrasciTabEnum.Preduzece);
      obrasciStore.fetchTipoviObrazacaPoPreduzecu();
      storage.removeData(StorageEnum.POSTROJENJE);
      obrasciStore.handleChange('opste', {
        ...obrasciStore.opste,
        postrojenje_id: '',
        postrojenje: '',
      });
    }

    if (tab === ActiveObrasciTabEnum.Postrojenje) {
      setActiveTab(ActiveObrasciTabEnum.Postrojenje);
      obrasciStore.handleChange('opste', {
        ...obrasciStore.opste,
        postrojenje_id: (facilityStore.facilityList[0] as IGetFacility).id,
        postrojenje: facilityStore.facilityList[0],
      });
      setDefaultPostrojenje(obrasciStore.opste.postrojenje as IGetFacility);
      storage.setData(StorageEnum.POSTROJENJE, obrasciStore.opste.postrojenje);
      obrasciStore.fetchTipoviObrazacaPoPostrojenju();
    }
  };

  const postrojenjaOptions = (facilityStore.getFacilityList as IGetFacility[]).map(
    (item: IGetFacility): DefaultOptionType => ({
      label: item.naziv_postrojenja,
      value: item.id,
      item,
      // disabled: item.is_active,
    })
  );

  const tabsUserCompanyFacility = [
    {
      key: ActiveObrasciTabEnum.Preduzece,
      label: t('COMPANY.TITLE'),
      disabled: authStore.isOnlyFacilityUser,
    },
    {
      key: ActiveObrasciTabEnum.Postrojenje,
      label: t('FACILITY.TITLE'),
      disabled: authStore.isOnlyCompanyUser,
    },
  ];

  return (
    <div id="obrasciUserFilters" className={styles.wrapper}>
      <Tabs
        className={styles.tabs}
        activeKey={activeTab}
        onChange={onTabChange}
        type="card"
        items={tabsUserCompanyFacility}
        tabBarExtraContent={
          activeTab === ActiveObrasciTabEnum.Postrojenje && (
            <Select
              className={styles.postrojenjeSelect}
              showSearch
              placeholder={t('FACILITY.PLACEHOLDER.CHOOSE_FROM_LIST')}
              optionFilterProp="children"
              onChange={(_, option: any) => handlePostrojenjeChange(option.item)}
              value={defaultPostrojenje?.id}
              filterOption={nrizService.handleSearchOptions}
              options={postrojenjaOptions}
              name={'postrojenje'}
            />
          )
        }
      />
    </div>
  );
});
