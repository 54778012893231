import { TIPOVI_OBRAZACA } from './obrasci.types';
import { t } from 'i18next';

import {
  ProizvodiWizardTab,
  Ptp2OpsteWizardTab,
  Ptp2NapomenaWizardTab,
  Ptp2MicroServices,
} from 'pages/ObrazacWizard/components/ptp2';

import { ptp2ObrazacStore } from './store/ptp2/ptp2.store';
import { pzv1ObrazacStore } from './store/pzv1/pzv1.store';
import { dep2ObrazacStore } from './store/dep2/dep2.store';
import {
  Dep2MicroServices,
  Dep2NapomenaWizardTab,
  Dep2OpsteWizardTab,
  DivljeDeponijeWizardTab,
} from 'pages/ObrazacWizard/components/dep2';

import {
  ZastitaBiljaWizardTab,
  DjubrivaWizardTab,
  DeterdzentiWizardTab,
  PZVNapomenaWizardTab,
  PZVOpsteWizardTab,
} from 'pages/ObrazacWizard/components/pzv1';
import { PZV1MicroServices } from 'pages/ObrazacWizard/components/pzv1/PZV1MicroServices/PZV1MicroServices';
import {
  Kom1KolicineWizardTab,
  Kom1MicroServices,
  Kom1NapomenaWizardTab,
  Kom1NaseljaWizardTab,
  Kom1OpremaIMehanizacijaWizardTab,
  Kom1OpsteWizardTab,
  Kom1SastavWizardTab,
  Kom1UpravljanjeWizardTab,
} from 'pages/ObrazacWizard/components/kom1';
import { kom1ObrazacStore } from './store/kom1/kom1.store';
import {
  EmisijeUTloWizardTab,
  Obrazac4DodatniPodaciWizardTab,
  Obrazac4NapomenaWizardTab,
  Obrazac4OpsteWizardTab,
} from 'pages/ObrazacWizard/components/obrazac4';
import { Obrazac4MicroServices } from 'pages/ObrazacWizard/components/obrazac4/Obrazac4MicroServices/Obazac4MicroServices';
import { obrazac4Store } from './store/obrazac4/obrazac4.store';
import { FormInstance } from 'antd';
import { IKom1ObrazacResponse, IPostKom1Obraza } from './store/kom1/kom1.types';
import { IGetDep2, IPostDEP2Obrazac } from './store/dep2/dep2.types';
import { IObrazac4Response, IPostObrazac4 } from './store/obrazac4/obrazac4.types';
import { Obrazac1OpsteWizardTab } from 'pages/ObrazacWizard/components/obrazac1/components/Obrazac1OpsteWizardTab/Obrazac1OpsteWizardTab';
import { Obrazac1GorivaWizardTab } from 'pages/ObrazacWizard/components/obrazac1/components/Obrazac1GorivaWizardTab/Obrazac1GorivaWizardTab';
import { Obrazac1ProizvodiWizardTab } from 'pages/ObrazacWizard/components/obrazac1/components/Obrazac1ProizvodiWizardTab/Obrazac1ProizvodiWizardTab';
import { Obrazac1SirovineWizardTab } from 'pages/ObrazacWizard/components/obrazac1/components/Obrazac1SirovineWizardTab/Obrazac1SirovineWizardTab';
import { Obrazac1NapomenaWizardTab } from 'pages/ObrazacWizard/components/obrazac1/components/Obrazac1NapomenaWizardTab/Obrazac1NapomenaWizardTab';
import { obrazac1Store } from './store/obrazac1/obrazac1.store';
import { Obrazac1MicroServices } from 'pages/ObrazacWizard/components/obrazac1/Obrazac1MicroServices/Obrazac1MicroServices';
import { Obrazac1DodatniPodaciWizardTab } from 'pages/ObrazacWizard/components/obrazac1/components/Obrazac1DodatniPodaciWizardTab/Obrazac1DodatniPodaciWizardTab';
import { IGetObrazac1, IPostObrazac1 } from './store/obrazac1/obrazac1.types';
import { Dep1OpsteWizardTab } from 'pages/ObrazacWizard/components/dep1/components/Dep1OpsteWizardTab/Dep1OpsteWizardTab';
import { Dep1NesanitarnaDeponijaWizardTab } from 'pages/ObrazacWizard/components/dep1/components/Dep1NesanitarnaDeponijaWizardTab/Dep1NesanitarnaDeponijaTab';
import { Dep1NapomenaWizardTab } from 'pages/ObrazacWizard/components/dep1/components/Dep1NapomenaWizardTab/Dep1NapomenaWizardTab';
import { dep1Store } from './store/dep1/dep1.store';
import { Dep1MicroServices } from 'pages/ObrazacWizard/components/dep1/Dep1MicroServices/Dep1MicroServices';
import { aao1Store } from './store/aao1/aao1.store';
import { Aao1MicroServices } from 'pages/ObrazacWizard/components/aao1/Aao1MicroServices/Aao1MicroServices';
import {
  Aao1KolicinaAmbalazeWizardTab,
  Aao1NapomenaWizardTab,
  Aao1OpsteWizardTab,
  Aao1UpravljanjeAmbalaznimOtpadomWizardTab,
} from 'pages/ObrazacWizard/components/aao1';
import { Gio3OpsteWizardTab } from 'pages/ObrazacWizard/components/gio3/components/Gio3OpsteWizardTab/Gio3OpsteWizardTab';
import { IGetGio3Obrazac, IPostGio3Obrazac } from './store/gio3/gio3.types';
import { gio3ObrazacStore } from './store/gio3/gio3.store';
import { Gio3MicroServices } from 'pages/ObrazacWizard/components/gio3/Gio3MicroServices/Gio3MicroServices';
import { Gio3KolicineWizardTab } from 'pages/ObrazacWizard/components/gio3/components/Gio3KolicineWizardTab/Gio3KolicineWizardTab';
import { Gio3NapomenaWizardTab } from 'pages/ObrazacWizard/components/gio3/components/Gio3NapomenaWizardTab/Gio3NapomenaWizardTab';
import { Gio3PostrojenjaWizardTab } from 'pages/ObrazacWizard/components/gio3/components/Gio3PostrojenjaWizardTab/Gio3PostrojenjaWizardTab';
import {
  Aao2KolicinaAmbalazeWizardTab,
  Aao2NapomenaWizardTab,
  Aao2OpsteWizardTab,
  Aao2UpravljanjeAmbalaznimOtpadomWizardTab,
} from 'pages/ObrazacWizard/components/aao2';
import { Aao2PravnoLiceWizardTab } from 'pages/ObrazacWizard/components/aao2/components/Aao2PravnoLiceWizardTab/Aao2PravnoLiceWizardTab';
import { aao2Store } from './store/aao2/aao2.store';
import { Aao2MicroServices } from 'pages/ObrazacWizard/components/aao2/Aao2MicroServices/Aao2MicroServices';
import { gio2ObrazacStore } from './store/gio2/gio2.store';
import {
  Gio2DeponijeWizardTab,
  Gio2DozvoleWizardTab,
  Gio2KolicineWizardTab,
  Gio2MicroServices,
  Gio2NapomenaWizardTab,
  Gio2OpsteWizardTab,
} from 'pages/ObrazacWizard/components/gio2';
import {
  Obrazac5KlasifikacijaWizardTab,
  Obrazac5KolicineWizardTab,
  Obrazac5KomponenteWizardTab,
  Obrazac5MicroServices,
  Obrazac5NapomenaWizardTab,
  Obrazac5OpsteWizardTab,
  Obrazac5UpravljanjeWizardTab,
} from 'pages/ObrazacWizard/components/obrazac5';
import { obrazac5Store } from './store/obrazac5/obrazac5.store';
import {
  Gio4DozvoleWizardTab,
  Gio4IzvozOtpadaWizardTab,
  Gio4MicroServices,
  Gio4NapomenaWizardTab,
  Gio4OpsteWizardTab,
} from 'pages/ObrazacWizard/components/gio4';
import { gio4ObrazacStore } from './store/gio4/gio4.store';
import {
  Gio5DozvoleWizardTab,
  Gio5UvozOtpadaWizardTab,
  Gio5MicroServices,
  Gio5NapomenaWizardTab,
  Gio5OpsteWizardTab,
} from 'pages/ObrazacWizard/components/gio5';
import { gio5ObrazacStore } from './store/gio5/gio5.store';
import { Gio3DozvoleWizardTab } from 'pages/ObrazacWizard/components/gio3/components/Gio3DozvoleWizardTab/Gio3DozvoleWizardTab';
import {
  DkoDeoAWizardTab,
  DkoDeoBWizardTab,
  DkoDeoCWizardTab,
  DkoDeoDWizardTab,
  DkoNapomenaWizardTab,
  DkoOpsteWizardTab,
} from 'pages/ObrazacWizard/components/dko';
import { dkoObrazacStore } from './store/dko/dko.store';
import {
  Obrazac3AnalizaOtpadneVodeWizardTab,
  Obrazac3AnalizaRecipijenataWizardTab,
  Obrazac3DodatniPodaciWizardTab,
  Obrazac3KanalizacioniSistemiWizardTab,
  Obrazac3NapomenaWizardTab,
  Obrazac3OpsteWizardTab,
  Obrazac3VodovodniSistemiWizardTab,
} from 'pages/ObrazacWizard/components/obrazac3';
import { obrazac3Store } from './store/obrazac3/obrazac3.store';
import { Obrazac3MicroServices } from 'pages/ObrazacWizard/components/obrazac3/Obrazac3MicroServices/Obrazac3MicroServices';
import { IGetObrazac3, IPostObrazac3 } from './store/obrazac3/obrazac3.types';
import { IGetDep1, IPostDep1 } from './store/dep1/dep1.types';
import {
  GveDodatniPodaciWizardTab,
  GveEmisijeUVazduhWizardTab,
  GveGorivaWizardTab,
  GveMicroServices,
  GveNapomenaWizardTab,
  GveOpsteWizardTab,
} from 'pages/ObrazacWizard/components/gve';
import { gveStore } from './store/gve/gve.store';
import { IGetDkoObrazac, IPostDkoObrazac } from './store/dko/dko.types';
import { deo6ObrazacStore } from './store/deo6/deo6.store';
import {
  Deo6MicroServices,
  Deo6NapomenaWizardTab,
  Deo6OpsteWizardTab,
  Deo6PonovnaUpotrebaOtpadWizardTab,
  Deo6PredatOtpadWizardTab,
  Deo6PreuzetOtpadWizardTab,
  Deo6SkladistaWizardTab,
} from 'pages/ObrazacWizard/components/deo6';
import { IWizardTabConfig } from 'pages/ObrazacWizard/ObrazacWizard';
import { IGetGve, IPostGve } from './store/gve/gve.types';
import { companyInitialState } from 'modules/company/company.constants';
import { facilityFormInitialState } from 'modules/facility/facility.constants';
import { ROUTES } from 'modules/nriz/nriz.constants';
import { Obrazac1UkupnaKolicinaPoAktivnostimaWizardTab } from 'pages/ObrazacWizard/components/obrazac1/components/Obrazac1UkupnaKolicinaPoAktivnostimaWizardTab/Obrazac1UkupnaKolicinaPoAktivnostimaWizardTab';
import { obrazac1Service } from './store/obrazac1/obrazac1.service';
import { IGetObrazac5, IPostObrazac5 } from './store/obrazac5/obrazac5.types';

export const OBRAZAC_TYPE_API_URL_MAP: { [key: string]: string } = {
  [TIPOVI_OBRAZACA.PTP2]: 'ptp2',
  [TIPOVI_OBRAZACA.DEP2]: 'dep2',
  [TIPOVI_OBRAZACA.PZV1]: 'pzv1',
  [TIPOVI_OBRAZACA.KOM1]: 'kom1',
  [TIPOVI_OBRAZACA.OBRAZAC_4]: 'emisije-u-tlo',
  [TIPOVI_OBRAZACA.OBRAZAC_1]: 'obrazac1',
  [TIPOVI_OBRAZACA.DEP1]: 'dep1',
  [TIPOVI_OBRAZACA.AAO1]: 'aao1',
  [TIPOVI_OBRAZACA.GIO3]: 'gio3',
  [TIPOVI_OBRAZACA.AAO2]: 'aao2',
  [TIPOVI_OBRAZACA.GIO2]: 'gio2',
  [TIPOVI_OBRAZACA.OBRAZAC_5]: 'obrazac5',
  [TIPOVI_OBRAZACA.GIO4]: 'gio4',
  [TIPOVI_OBRAZACA.GIO5]: 'gio5',
  [TIPOVI_OBRAZACA.DKO]: 'dko',
  [TIPOVI_OBRAZACA.OBRAZAC_3]: 'obrazac3',
  [TIPOVI_OBRAZACA.DEO6_GIO6]: 'deo6',
  [TIPOVI_OBRAZACA.OBRAZAC_2]: 'obrazac2',
};

export const OBRAZAC_TYPE_WIZARD_URL_MAP: { [key: string]: string } = {
  [TIPOVI_OBRAZACA.PTP2]: `/${ROUTES.OBRAZAC}/ptp-2/`,
  [TIPOVI_OBRAZACA.DEP2]: `/${ROUTES.OBRAZAC}/dep-2/`,
  [TIPOVI_OBRAZACA.PZV1]: `/${ROUTES.OBRAZAC}/pzv-1/`,
  [TIPOVI_OBRAZACA.KOM1]: `/${ROUTES.OBRAZAC}/kom-1/`,
  [TIPOVI_OBRAZACA.OBRAZAC_1]: `/${ROUTES.OBRAZAC}/podaci-o-postrojenju/`,
  [TIPOVI_OBRAZACA.OBRAZAC_3]: `/${ROUTES.OBRAZAC}/obrazac3/`,
  [TIPOVI_OBRAZACA.OBRAZAC_4]: `/${ROUTES.OBRAZAC}/emisije-u-tlo/`,
  [TIPOVI_OBRAZACA.OBRAZAC_5]: `/${ROUTES.OBRAZAC}/obrazac5/`,
  [TIPOVI_OBRAZACA.LRIZ_OBRAZAC_1]: `/${ROUTES.OBRAZAC}/lriz1/`,
  [TIPOVI_OBRAZACA.LRIZ_OBRAZAC_2]: `/${ROUTES.OBRAZAC}/lriz2/`,
  [TIPOVI_OBRAZACA.LRIZ_OBRAZAC_3]: `/${ROUTES.OBRAZAC}/lriz3/`,
  [TIPOVI_OBRAZACA.LRIZ_OBRAZAC_4]: `/${ROUTES.OBRAZAC}/lriz4/`,
  [TIPOVI_OBRAZACA.LRIZ_OBRAZAC_5]: `/${ROUTES.OBRAZAC}/lriz5/`,
  [TIPOVI_OBRAZACA.DEP1]: `/${ROUTES.OBRAZAC}/dep-1/`,
  [TIPOVI_OBRAZACA.AAO1]: `/${ROUTES.OBRAZAC}/aao-1/`,
  [TIPOVI_OBRAZACA.AAO2]: `/${ROUTES.OBRAZAC}/aao-2/`,
  [TIPOVI_OBRAZACA.GIO1]: `/${ROUTES.OBRAZAC}/gio-1/`,
  [TIPOVI_OBRAZACA.GIO2]: `/${ROUTES.OBRAZAC}/gio-2/`,
  [TIPOVI_OBRAZACA.GIO3]: `/${ROUTES.OBRAZAC}/gio-3/`,
  [TIPOVI_OBRAZACA.GIO4]: `/${ROUTES.OBRAZAC}/gio-4/`,
  [TIPOVI_OBRAZACA.GIO5]: `/${ROUTES.OBRAZAC}/gio-5/`,
  [TIPOVI_OBRAZACA.DKO]: `/${ROUTES.OBRAZAC}/dko/`,
  [TIPOVI_OBRAZACA.GVE]: `/${ROUTES.OBRAZAC}/gve/`,
  [TIPOVI_OBRAZACA.DEO6_GIO6]: `/${ROUTES.OBRAZAC}/deo6/`,
  [TIPOVI_OBRAZACA.OBRAZAC_2]: `/${ROUTES.OBRAZAC}/obrazac2/`,
  [TIPOVI_OBRAZACA.OBRAZAC_2_GVE]: `/${ROUTES.OBRAZAC}/obrazac2/gve/`,
  [TIPOVI_OBRAZACA.LRIZ_OBRAZAC_2_GVE]: `/${ROUTES.OBRAZAC}/lriz2/gve/`,
  [TIPOVI_OBRAZACA.OTV]: `/${ROUTES.OBRAZAC}/otv/`,
  [TIPOVI_OBRAZACA.OBRAZAC_3_OTV]: `/${ROUTES.OBRAZAC}/lriz3/otv/`,
  [TIPOVI_OBRAZACA.LRIZ_OBRAZAC_3_OTV]: `/${ROUTES.OBRAZAC}/obrazac3/otv/`,
  [TIPOVI_OBRAZACA.LRIZ_OBRAZAC_5_GIO1]: `/${ROUTES.OBRAZAC}/lriz5/gio1/`,
};

export const PTP_WIZARD_CONFIG = [
  { component: () => <Ptp2OpsteWizardTab />, key: '1', label: t('WIZARD_TABS.OPSTE') },
  { component: () => <ProizvodiWizardTab />, key: '2', label: t('WIZARD_TABS.PROIZVODI') },
  { component: () => <Ptp2NapomenaWizardTab />, key: '3', label: t('WIZARD_TABS.NAPOMENA') },
];

export const DEP_2_WIZARD_CONFIG = [
  {
    component: ({ form }: { form: FormInstance<IPostDEP2Obrazac | IGetDep2> }) => <Dep2OpsteWizardTab form={form} />,
    key: '1',
    label: t('WIZARD_TABS.OPSTE'),
  },
  { component: () => <DivljeDeponijeWizardTab />, key: '2', label: t('WIZARD_TABS.DIVLJE_DEPONIJE') },
  { component: () => <Dep2NapomenaWizardTab />, key: '3', label: t('WIZARD_TABS.NAPOMENA') },
];
export const PZV_WIZARD_CONFIG = [
  { component: () => <PZVOpsteWizardTab />, key: '1', label: t('WIZARD_TABS.OPSTE') },
  { component: () => <DeterdzentiWizardTab />, key: '2', label: t('WIZARD_TABS.DETERDZENTI') },
  { component: () => <DjubrivaWizardTab />, key: '3', label: t('WIZARD_TABS.DJUBRIVA') },
  { component: () => <ZastitaBiljaWizardTab />, key: '4', label: t('WIZARD_TABS.ZASTITA_BILJA') },
  { component: () => <PZVNapomenaWizardTab />, key: '5', label: t('WIZARD_TABS.NAPOMENA') },
];

export const OBRAZAC_1_WIZARD_CONFIG = [
  {
    component: ({ form }: { form: FormInstance<IPostObrazac1 | IGetObrazac1> }) => (
      <Obrazac1OpsteWizardTab form={form} />
    ),
    key: '1',
    label: t('WIZARD_TABS.OPSTE'),
  },
  {
    component: ({ form }: { form: FormInstance<IPostObrazac1 | IGetObrazac1> }) => (
      <Obrazac1DodatniPodaciWizardTab form={form} />
    ),
    key: '2',
    label: t('WIZARD_TABS.DODATNI_PODACI'),
  },
  { component: () => <Obrazac1GorivaWizardTab />, key: '3', label: t('WIZARD_TABS.GORIVA') },
  { component: () => <Obrazac1ProizvodiWizardTab />, key: '4', label: t('WIZARD_TABS.PROIZVODI') },
  {
    component: () => <Obrazac1UkupnaKolicinaPoAktivnostimaWizardTab />,
    key: '5',
    label: t('WIZARD_TABS.UKUPNA_KOLICINA_PO_AKTIVNOSTIMA'),
    isTabHidden: () => obrazac1Service.hideUkupnaKolicinaPoAktivnostima(),
  },
  { component: () => <Obrazac1SirovineWizardTab />, key: '6', label: t('WIZARD_TABS.SIROVINE') },
  { component: () => <Obrazac1NapomenaWizardTab />, key: '7', label: t('WIZARD_TABS.NAPOMENA') },
];

export const KOM_1_WIZARD_CONFIG = [
  {
    component: ({ form }: { form: FormInstance<IPostKom1Obraza | IKom1ObrazacResponse> }) => (
      <Kom1OpsteWizardTab form={form} />
    ),
    key: '1',
    label: t('WIZARD_TABS.OPSTE'),
  },
  {
    component: () => <Kom1NaseljaWizardTab />,
    key: '2',
    label: t('OBRASCI.DEP_2.SETTLEMENT'),
  },
  {
    component: () => <Kom1KolicineWizardTab />,
    key: '3',
    label: t('OBRASCI.KOM_1.QUANTITY'),
  },
  {
    component: () => <Kom1SastavWizardTab />,
    key: '4',
    label: t('OBRASCI.KOM_1.COMPOSITION'),
  },
  {
    component: () => <Kom1UpravljanjeWizardTab />,
    key: '5',
    label: t('OBRASCI.KOM_1.MANAGEMENT.TITLE'),
  },
  {
    component: () => <Kom1OpremaIMehanizacijaWizardTab />,
    key: '6',
    label: t('OBRASCI.KOM_1.EQUIPMENT_AND_MECHANISATION.TITLE'),
  },
  {
    component: () => <Kom1NapomenaWizardTab />,
    key: '7',
    label: t('WIZARD_TABS.NAPOMENA'),
  },
];

export const OBRAZAC_4_CONFIG = [
  {
    component: ({ form }: { form: FormInstance<IPostObrazac4 | IObrazac4Response> }) => (
      <Obrazac4OpsteWizardTab form={form} />
    ),
    key: '1',
    label: t('WIZARD_TABS.OPSTE'),
  },
  { component: () => <Obrazac4DodatniPodaciWizardTab />, key: '2', label: t('WIZARD_TABS.DODATNI_PODACI') },
  { component: () => <EmisijeUTloWizardTab />, key: '3', label: t('WIZARD_TABS.EMISIJE_U_TLO') },
  { component: () => <Obrazac4NapomenaWizardTab />, key: '4', label: t('WIZARD_TABS.NAPOMENA') },
];

export const DEP_1_WIZARD_CONFIG = [
  { component: () => <Dep1OpsteWizardTab />, key: '1', label: t('WIZARD_TABS.OPSTE') },
  {
    component: ({ form }: { form: FormInstance<IPostDep1 | IGetDep1> }) => (
      <Dep1NesanitarnaDeponijaWizardTab form={form} />
    ),
    key: '2',
    label: t('WIZARD_TABS.NESANITARNA_DEPONIJA'),
  },
  { component: () => <Dep1NapomenaWizardTab />, key: '3', label: t('WIZARD_TABS.NAPOMENA') },
];

export const AAO_1_WIZARD_CONFIG = [
  { component: () => <Aao1OpsteWizardTab />, key: '1', label: t('WIZARD_TABS.OPSTE') },
  { component: () => <Aao1KolicinaAmbalazeWizardTab />, key: '2', label: t('WIZARD_TABS.KOLICINA_AMBALAZE') },
  {
    component: () => <Aao1UpravljanjeAmbalaznimOtpadomWizardTab />,
    key: '3',
    label: t('WIZARD_TABS.UPRAVLJANJE_AMBALAZNIM_OTPADOM'),
    isTabHidden: () => !aao1Store.isCompanyWithSelfManagment,
  },
  { component: () => <Aao1NapomenaWizardTab />, key: '4', label: t('WIZARD_TABS.NAPOMENA') },
];
export const GIO_3_WIZARD_CONFIG = [
  {
    component: ({ form }: { form: FormInstance<IPostGio3Obrazac | IGetGio3Obrazac> }) => (
      <Gio3OpsteWizardTab form={form} />
    ),
    key: '1',
    label: t('WIZARD_TABS.OPSTE'),
  },
  { component: () => <Gio3DozvoleWizardTab />, key: '2', label: t('WIZARD_TABS.DOZVOLE') },
  { component: () => <Gio3PostrojenjaWizardTab />, key: '3', label: t('FACILITY.TITLE') },
  { component: () => <Gio3KolicineWizardTab />, key: '4', label: t('WIZARD_TABS.QUANTITY') },
  { component: () => <Gio3NapomenaWizardTab />, key: '5', label: t('WIZARD_TABS.NAPOMENA') },
];

export const AAO_2_WIZARD_CONFIG = [
  { component: () => <Aao2OpsteWizardTab />, key: '1', label: t('WIZARD_TABS.OPSTE') },
  { component: () => <Aao2PravnoLiceWizardTab />, key: '2', label: t('WIZARD_TABS.PRAVNO_LICE_ILI_PREDUZETNIK') },
  { component: () => <Aao2KolicinaAmbalazeWizardTab />, key: '3', label: t('WIZARD_TABS.KOLICINA_AMBALAZE') },
  {
    component: () => <Aao2UpravljanjeAmbalaznimOtpadomWizardTab />,
    key: '4',
    label: t('WIZARD_TABS.UPRAVLJANJE_AMBALAZNIM_OTPADOM'),
  },
  { component: () => <Aao2NapomenaWizardTab />, key: '5', label: t('WIZARD_TABS.NAPOMENA') },
];

export const GIO_2_WIZARD_CONFIG = [
  { component: () => <Gio2OpsteWizardTab />, key: '1', label: t('WIZARD_TABS.OPSTE') },
  { component: () => <Gio2DozvoleWizardTab />, key: '2', label: t('WIZARD_TABS.DOZVOLE') },
  { component: () => <Gio2DeponijeWizardTab />, key: '3', label: t('WIZARD_TABS.DEPONIJE') },
  { component: () => <Gio2KolicineWizardTab />, key: '4', label: t('WIZARD_TABS.QUANTITY') },
  { component: () => <Gio2NapomenaWizardTab />, key: '5', label: t('WIZARD_TABS.NAPOMENA') },
];

export const OBRAZAC_5_WIZARD_CONFIG = [
  {
    component: ({ form }: { form: FormInstance<IPostObrazac5 | IGetObrazac5> }) => (
      <Obrazac5OpsteWizardTab form={form} />
    ),
    key: '1',
    label: t('WIZARD_TABS.OPSTE'),
  },
  {
    component: ({ form }: { form: FormInstance<IPostObrazac4 | IObrazac4Response> }) => (
      <Obrazac5KlasifikacijaWizardTab form={form} />
    ),
    key: '2',
    label: t('WIZARD_TABS.KLASIFIKACIJE'),
  },
  { component: () => <Obrazac5KomponenteWizardTab />, key: '3', label: t('WIZARD_TABS.KOMPONENTE') },
  { component: () => <Obrazac5KolicineWizardTab />, key: '4', label: t('WIZARD_TABS.QUANTITY') },
  { component: () => <Obrazac5UpravljanjeWizardTab />, key: '5', label: t('WIZARD_TABS.UPRAVLJANJE') },
  { component: () => <Obrazac5NapomenaWizardTab />, key: '6', label: t('WIZARD_TABS.NAPOMENA') },
];
export const GIO_4_WIZARD_CONFIG = [
  { component: () => <Gio4OpsteWizardTab />, key: '1', label: t('WIZARD_TABS.OPSTE') },
  { component: () => <Gio4DozvoleWizardTab />, key: '2', label: t('WIZARD_TABS.DOZVOLE') },
  { component: () => <Gio4IzvozOtpadaWizardTab />, key: '3', label: t('WIZARD_TABS.IZVOZ_OTPADA') },
  {
    component: () => <Gio4NapomenaWizardTab />,
    key: '4',
    label: t('WIZARD_TABS.NAPOMENA'),
  },
];

export const GIO_5_WIZARD_CONFIG = [
  { component: () => <Gio5OpsteWizardTab />, key: '1', label: t('WIZARD_TABS.OPSTE') },
  { component: () => <Gio5DozvoleWizardTab />, key: '2', label: t('WIZARD_TABS.DOZVOLE') },
  { component: () => <Gio5UvozOtpadaWizardTab />, key: '3', label: t('WIZARD_TABS.UVOZ_OTPADA') },
  { component: () => <Gio5NapomenaWizardTab />, key: '4', label: t('WIZARD_TABS.NAPOMENA') },
];

export const DKO_WIZARD_CONFIG = [
  {
    component: ({ form }: { form: FormInstance<IPostDkoObrazac | IGetDkoObrazac> }) => (
      <DkoOpsteWizardTab form={form} />
    ),
    key: '1',
    label: t('WIZARD_TABS.OPSTE'),
  },
  {
    component: ({ form }: { form: FormInstance<IPostDkoObrazac | IGetDkoObrazac> }) => <DkoDeoAWizardTab form={form} />,
    key: '2',
    label: t('WIZARD_TABS.DEO_A'),
  },
  {
    component: ({ form }: { form: FormInstance<IPostDkoObrazac | IGetDkoObrazac> }) => <DkoDeoBWizardTab form={form} />,
    key: '3',
    label: t('WIZARD_TABS.DEO_B'),
  },
  {
    component: ({ form }: { form: FormInstance<IPostDkoObrazac | IGetDkoObrazac> }) => <DkoDeoCWizardTab form={form} />,
    key: '4',
    label: t('WIZARD_TABS.DEO_C'),
  },
  {
    component: ({ form }: { form: FormInstance<IPostDkoObrazac | IGetDkoObrazac> }) => <DkoDeoDWizardTab form={form} />,
    key: '5',
    label: t('WIZARD_TABS.DEO_D'),
  },
  { component: () => <DkoNapomenaWizardTab />, key: '6', label: t('WIZARD_TABS.NAPOMENA') },
];

export const OBRAZAC_3_WIZARD_CONFIG = [
  { component: () => <Obrazac3OpsteWizardTab />, key: '1', label: t('WIZARD_TABS.OPSTE') },
  {
    component: ({ form }: { form: FormInstance<IPostObrazac3 | IGetObrazac3> }) => (
      <Obrazac3DodatniPodaciWizardTab form={form} />
    ),
    key: '2',
    label: t('WIZARD_TABS.DODATNI_PODACI'),
  },
  {
    component: ({ form }: { form: FormInstance<IPostObrazac3 | IGetObrazac3> }) => (
      <Obrazac3AnalizaOtpadneVodeWizardTab form={form} />
    ),
    key: '3',
    label: t('WIZARD_TABS.ANALIZA_OTPADNE_VODE'),
  },
  { component: () => <Obrazac3AnalizaRecipijenataWizardTab />, key: '4', label: t('WIZARD_TABS.ANALIZA_RECIPIJENATA') },
  {
    component: () => <Obrazac3VodovodniSistemiWizardTab />,
    key: '5',
    label: t('WIZARD_TABS.VODOVODNI_SISTEMI'),
    isTabHidden: () => {
      const isHidden = obrazac3Store.checkVodovodniSistemiTab();
      return isHidden;
    },
  },
  {
    component: ({ form }: { form: FormInstance<IPostObrazac3 | IGetObrazac3> }) => (
      <Obrazac3KanalizacioniSistemiWizardTab form={form} />
    ),
    key: '6',
    label: t('WIZARD_TABS.KANALIZACIONI_SISTEMI'),
    isTabHidden: () => {
      const isHidden = obrazac3Store.checkKanalizacioniSistemiTab();
      return isHidden;
    },
  },
  { component: () => <Obrazac3NapomenaWizardTab />, key: '7', label: t('WIZARD_TABS.NAPOMENA') },
];

export const GVE_WIZARD_CONFIG: IWizardTabConfig[] = [
  { component: () => <GveOpsteWizardTab />, key: '1', label: t('WIZARD_TABS.OPSTE') },
  {
    component: ({ form }: { form: FormInstance<IPostGve | IGetGve> }) => <GveDodatniPodaciWizardTab form={form} />,
    key: '2',
    label: t('WIZARD_TABS.DODATNI_PODACI'),
  },
  { component: () => <GveGorivaWizardTab />, key: '3', label: t('WIZARD_TABS.GORIVA') },
  {
    component: ({ form }: { form: FormInstance<IPostGve | IGetGve> }) => <GveEmisijeUVazduhWizardTab form={form} />,
    key: '4',
    label: t('WIZARD_TABS.EMISIJE_U_VAZDUH'),
  },
  { component: () => <GveNapomenaWizardTab />, key: '5', label: t('WIZARD_TABS.NAPOMENA') },
];

export const DEO_6_WIZARD_CONFIG: IWizardTabConfig[] = [
  { component: () => <Deo6OpsteWizardTab />, key: '1', label: t('WIZARD_TABS.OPSTE') },
  {
    component: () => <Deo6SkladistaWizardTab />,
    key: '2',
    label: t('WIZARD_TABS.STANJE_NA_SKLADISTU'),
    isTabHidden: () => {
      return deo6ObrazacStore.checkSkladisteTabVisible();
    },
  },
  { component: () => <Deo6PreuzetOtpadWizardTab />, key: '3', label: t('WIZARD_TABS.PREUZET_OTPAD') },
  { component: () => <Deo6PredatOtpadWizardTab />, key: '4', label: t('WIZARD_TABS.PREDAT_OTPAD') },
  {
    component: () => <Deo6PonovnaUpotrebaOtpadWizardTab />,
    key: '5',
    label: t('WIZARD_TABS.PONOVNA_UPOTREBA_OTPADA'),
    isTabHidden: () => {
      return deo6ObrazacStore.checkPonovnoUpotrebaTabVisible();
    },
  },
  { component: () => <Deo6NapomenaWizardTab />, key: '6', label: t('WIZARD_TABS.NAPOMENA') },
];

export const OBRASCI_ROUTES = [
  {
    path: OBRAZAC_TYPE_WIZARD_URL_MAP[TIPOVI_OBRAZACA.PTP2],
    wizardConfig: PTP_WIZARD_CONFIG,
    store: ptp2ObrazacStore,
    modals: <Ptp2MicroServices />,
  },
  {
    path: OBRAZAC_TYPE_WIZARD_URL_MAP[TIPOVI_OBRAZACA.DEP2],
    wizardConfig: DEP_2_WIZARD_CONFIG,
    store: dep2ObrazacStore,
    modals: <Dep2MicroServices />,
  },
  {
    path: OBRAZAC_TYPE_WIZARD_URL_MAP[TIPOVI_OBRAZACA.PZV1],
    wizardConfig: PZV_WIZARD_CONFIG,
    store: pzv1ObrazacStore,
    modals: <PZV1MicroServices />,
  },
  {
    path: OBRAZAC_TYPE_WIZARD_URL_MAP[TIPOVI_OBRAZACA.KOM1],
    wizardConfig: KOM_1_WIZARD_CONFIG,
    store: kom1ObrazacStore,
    modals: <Kom1MicroServices />,
  },
  {
    path: OBRAZAC_TYPE_WIZARD_URL_MAP[TIPOVI_OBRAZACA.OBRAZAC_4],
    wizardConfig: OBRAZAC_4_CONFIG,
    store: obrazac4Store,
    modals: <Obrazac4MicroServices />,
  },
  {
    path: OBRAZAC_TYPE_WIZARD_URL_MAP[TIPOVI_OBRAZACA.OBRAZAC_1],
    wizardConfig: OBRAZAC_1_WIZARD_CONFIG,
    store: obrazac1Store,
    modals: <Obrazac1MicroServices />,
  },
  {
    path: OBRAZAC_TYPE_WIZARD_URL_MAP[TIPOVI_OBRAZACA.LRIZ_OBRAZAC_4],
    wizardConfig: OBRAZAC_4_CONFIG,
    store: obrazac4Store,
    modals: <Obrazac4MicroServices />,
  },
  {
    path: OBRAZAC_TYPE_WIZARD_URL_MAP[TIPOVI_OBRAZACA.LRIZ_OBRAZAC_1],
    wizardConfig: OBRAZAC_1_WIZARD_CONFIG,
    store: obrazac1Store,
    modals: <Obrazac1MicroServices />,
  },
  {
    path: OBRAZAC_TYPE_WIZARD_URL_MAP[TIPOVI_OBRAZACA.DEP1],
    wizardConfig: DEP_1_WIZARD_CONFIG,
    store: dep1Store,
    modals: <Dep1MicroServices />,
  },
  {
    path: OBRAZAC_TYPE_WIZARD_URL_MAP[TIPOVI_OBRAZACA.AAO1],
    wizardConfig: AAO_1_WIZARD_CONFIG,
    store: aao1Store,
    modals: <Aao1MicroServices />,
  },
  {
    path: OBRAZAC_TYPE_WIZARD_URL_MAP[TIPOVI_OBRAZACA.GIO3],
    wizardConfig: GIO_3_WIZARD_CONFIG,
    store: gio3ObrazacStore,
    modals: <Gio3MicroServices />,
  },
  {
    path: OBRAZAC_TYPE_WIZARD_URL_MAP[TIPOVI_OBRAZACA.AAO2],
    wizardConfig: AAO_2_WIZARD_CONFIG,
    store: aao2Store,
    modals: <Aao2MicroServices />,
  },
  {
    path: OBRAZAC_TYPE_WIZARD_URL_MAP[TIPOVI_OBRAZACA.GIO2],
    wizardConfig: GIO_2_WIZARD_CONFIG,
    store: gio2ObrazacStore,
    modals: <Gio2MicroServices />,
  },
  {
    path: OBRAZAC_TYPE_WIZARD_URL_MAP[TIPOVI_OBRAZACA.OBRAZAC_5],
    wizardConfig: OBRAZAC_5_WIZARD_CONFIG,
    store: obrazac5Store,
    modals: <Obrazac5MicroServices />,
  },
  {
    path: OBRAZAC_TYPE_WIZARD_URL_MAP[TIPOVI_OBRAZACA.GIO1],
    wizardConfig: OBRAZAC_5_WIZARD_CONFIG,
    store: obrazac5Store,
    modals: <Obrazac5MicroServices />,
  },
  {
    path: OBRAZAC_TYPE_WIZARD_URL_MAP[TIPOVI_OBRAZACA.LRIZ_OBRAZAC_5],
    wizardConfig: OBRAZAC_5_WIZARD_CONFIG,
    store: obrazac5Store,
    modals: <Obrazac5MicroServices />,
  },
  {
    path: OBRAZAC_TYPE_WIZARD_URL_MAP[TIPOVI_OBRAZACA.LRIZ_OBRAZAC_5_GIO1],
    wizardConfig: OBRAZAC_5_WIZARD_CONFIG,
    store: obrazac5Store,
    modals: <Obrazac5MicroServices />,
  },
  {
    path: OBRAZAC_TYPE_WIZARD_URL_MAP[TIPOVI_OBRAZACA.GIO4],
    wizardConfig: GIO_4_WIZARD_CONFIG,
    store: gio4ObrazacStore,
    modals: <Gio4MicroServices />,
  },
  {
    path: OBRAZAC_TYPE_WIZARD_URL_MAP[TIPOVI_OBRAZACA.GIO5],
    wizardConfig: GIO_5_WIZARD_CONFIG,
    store: gio5ObrazacStore,
    modals: <Gio5MicroServices />,
  },
  {
    path: OBRAZAC_TYPE_WIZARD_URL_MAP[TIPOVI_OBRAZACA.DKO],
    wizardConfig: DKO_WIZARD_CONFIG,
    store: dkoObrazacStore,
    modals: <></>,
  },
  {
    path: OBRAZAC_TYPE_WIZARD_URL_MAP[TIPOVI_OBRAZACA.OBRAZAC_3],
    wizardConfig: OBRAZAC_3_WIZARD_CONFIG,
    store: obrazac3Store,
    modals: <Obrazac3MicroServices />,
  },
  {
    path: OBRAZAC_TYPE_WIZARD_URL_MAP[TIPOVI_OBRAZACA.OTV],
    wizardConfig: OBRAZAC_3_WIZARD_CONFIG,
    store: obrazac3Store,
    modals: <Obrazac3MicroServices />,
  },
  {
    path: OBRAZAC_TYPE_WIZARD_URL_MAP[TIPOVI_OBRAZACA.LRIZ_OBRAZAC_3],
    wizardConfig: OBRAZAC_3_WIZARD_CONFIG,
    store: obrazac3Store,
    modals: <Obrazac3MicroServices />,
  },
  {
    path: OBRAZAC_TYPE_WIZARD_URL_MAP[TIPOVI_OBRAZACA.LRIZ_OBRAZAC_3_OTV],
    wizardConfig: OBRAZAC_3_WIZARD_CONFIG,
    store: obrazac3Store,
    modals: <Obrazac3MicroServices />,
  },
  {
    path: OBRAZAC_TYPE_WIZARD_URL_MAP[TIPOVI_OBRAZACA.OBRAZAC_3_OTV],
    wizardConfig: OBRAZAC_3_WIZARD_CONFIG,
    store: obrazac3Store,
    modals: <Obrazac3MicroServices />,
  },
  {
    path: OBRAZAC_TYPE_WIZARD_URL_MAP[TIPOVI_OBRAZACA.OBRAZAC_2],
    wizardConfig: GVE_WIZARD_CONFIG,
    store: gveStore,
    modals: <GveMicroServices />,
  },

  {
    path: OBRAZAC_TYPE_WIZARD_URL_MAP[TIPOVI_OBRAZACA.GVE],
    wizardConfig: GVE_WIZARD_CONFIG,
    store: gveStore,
    modals: <GveMicroServices />,
  },
  {
    path: OBRAZAC_TYPE_WIZARD_URL_MAP[TIPOVI_OBRAZACA.LRIZ_OBRAZAC_2],
    wizardConfig: GVE_WIZARD_CONFIG,
    store: gveStore,
    modals: <GveMicroServices />,
  },
  {
    path: OBRAZAC_TYPE_WIZARD_URL_MAP[TIPOVI_OBRAZACA.LRIZ_OBRAZAC_2_GVE],
    wizardConfig: GVE_WIZARD_CONFIG,
    store: gveStore,
    modals: <GveMicroServices />,
  },
  {
    path: OBRAZAC_TYPE_WIZARD_URL_MAP[TIPOVI_OBRAZACA.OBRAZAC_2_GVE],
    wizardConfig: GVE_WIZARD_CONFIG,
    store: gveStore,
    modals: <GveMicroServices />,
  },
  {
    path: OBRAZAC_TYPE_WIZARD_URL_MAP[TIPOVI_OBRAZACA.DEO6_GIO6],
    wizardConfig: DEO_6_WIZARD_CONFIG,
    store: deo6ObrazacStore,
    modals: <Deo6MicroServices />,
  },
];

export const opsteInitial = {
  preduzece_id: '',
  preduzece: companyInitialState,
  godina: '',
  postrojenje_id: '',
  postrojenje: facilityFormInitialState,
};

export const disabledObrasci: TIPOVI_OBRAZACA[] = [TIPOVI_OBRAZACA.VOC];

// export const obrasciPreduzece: TIPOVI_OBRAZACA[] = [
//   TIPOVI_OBRAZACA.AAO1,
//   TIPOVI_OBRAZACA.AAO2,
//   TIPOVI_OBRAZACA.DEP1,
//   TIPOVI_OBRAZACA.DEP2,
//   TIPOVI_OBRAZACA.PTP2,
//   TIPOVI_OBRAZACA.PZV1,
// ];

// export const obrasciPostrojenje: TIPOVI_OBRAZACA[] = [
//   TIPOVI_OBRAZACA.DEO6_GIO6,
//   TIPOVI_OBRAZACA.DKO,
//   TIPOVI_OBRAZACA.GIO1,
//   TIPOVI_OBRAZACA.GIO2,
//   TIPOVI_OBRAZACA.GIO3,
//   TIPOVI_OBRAZACA.GIO4,
//   TIPOVI_OBRAZACA.GIO5,
//   TIPOVI_OBRAZACA.OBRAZAC_1,
//   TIPOVI_OBRAZACA.OBRAZAC_2,
//   TIPOVI_OBRAZACA.OBRAZAC_3,
//   TIPOVI_OBRAZACA.OBRAZAC_4,
//   TIPOVI_OBRAZACA.OBRAZAC_5,
//   TIPOVI_OBRAZACA.LRIZ_OBRAZAC_1,
//   TIPOVI_OBRAZACA.LRIZ_OBRAZAC_2,
//   TIPOVI_OBRAZACA.LRIZ_OBRAZAC_3,
//   TIPOVI_OBRAZACA.LRIZ_OBRAZAC_4,
//   TIPOVI_OBRAZACA.LRIZ_OBRAZAC_5,
//   TIPOVI_OBRAZACA.KOM1,
//   TIPOVI_OBRAZACA.LRIZ_OBRAZAC_2_GVE,
//   TIPOVI_OBRAZACA.LRIZ_OBRAZAC_3_OTV,
//   TIPOVI_OBRAZACA.LRIZ_OBRAZAC_5_GIO1,
//   TIPOVI_OBRAZACA.OBRAZAC_2_GVE,
//   TIPOVI_OBRAZACA.OBRAZAC_3_OTV,
//   TIPOVI_OBRAZACA.GVE,
//   TIPOVI_OBRAZACA.OTV,
// ];
