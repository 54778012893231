import { observer } from 'mobx-react-lite';
import { useEffect } from 'react';
import { obrasciStore } from 'modules/obrasci/obrasci.store';
import styles from './Obrazac5Opste.module.scss';
import { Col, Form } from 'antd';
import { OpsteGodinaPreduzecePostrojenje } from 'pages/ObrazacWizard/components/components/OpsteGodinaPreduzecePostrojenje/OpsteGodinaPreduzecePostrojenje';
import { obrazac5Store } from 'modules/obrasci/store/obrazac5/obrazac5.store';
import { FormSearchInfiniteScroll } from 'components/FormSearchInfiniteScroll/FormSearchInfiniteScroll';
import { useTranslation } from 'react-i18next';
import { REQUIRED_FIELD_RULE } from 'modules/nriz/nriz.constants';
import { IGetObrazac5 } from 'modules/obrasci/store/obrazac5/obrazac5.types';
import { ISifarnikInitial, SIFARNICI_ROUTES } from 'modules/sifarnici/sifarnici.types';
import { IGetWasteCatalogue } from 'modules/sifarnici/store/wasteCatalogue/wasteCatalogue.types';
import { useObrazacExistsCheck } from 'hooks/useObrazacExistsCheck';
import { ICrudTypeModalProps } from 'components/Modals/CRUDModal/CRUDModal';
import { sifarniciStore } from 'modules/sifarnici/sifarnici.store';

export const Obrazac5OpsteWizardTab: React.FC<ICrudTypeModalProps> = observer(({ form }) => {
  const { t } = useTranslation();

  const checkIfObrazacExists = useObrazacExistsCheck();

  const wasteCatalogueOptions = (options: IGetWasteCatalogue[]) => {
    return options.map(wasteOption => ({
      label: `${wasteOption.index_number} ${wasteOption.waste_sr.toUpperCase()}`,
      value: wasteOption.id,
    }));
  };

  const defaultIndeksniBrojOtpada: ISifarnikInitial | undefined = obrazac5Store.initialState.indeksni_broj_otpada_id
    ? {
        label: (obrazac5Store.getInitialState as IGetObrazac5).indeksni_broj_otpada?.index_number,
        value: (obrazac5Store.getInitialState as IGetObrazac5).indeksni_broj_otpada_id,
      }
    : undefined;

  useEffect(() => {
    const indexNumber = form.getFieldValue('indeksni_broj_otpada_id');

    if (indexNumber) {
      if (indexNumber === obrazac5Store.initialState.indeksni_broj_otpada_id) {
        obrazac5Store.handleChange(
          'waste_catalogue',
          (obrazac5Store.initialState as IGetObrazac5).indeksni_broj_otpada
        );
      } else {
        obrazac5Store.handleChange(
          'waste_catalogue',
          sifarniciStore.getSifarniciIdMap(SIFARNICI_ROUTES.WASTE_CATALOGUE)[indexNumber]
        );
      }
    }
  }, [Form.useWatch('indeksni_broj_otpada_id')]);

  useEffect(() => {
    if (
      obrasciStore.opste.godina !== '' &&
      obrasciStore.opste.preduzece_id !== '' &&
      obrasciStore.opste?.postrojenje_id !== '' &&
      obrazac5Store.waste_catalogue.index_number !== ''
    ) {
      obrasciStore.setDisabledTabButton(false);
    } else obrasciStore.setDisabledTabButton(true);
  }, [obrasciStore.opste, Form.useWatch('indeksni_broj_otpada_id')]);

  return (
    <Col span={20} className={styles.wrapper}>
      <OpsteGodinaPreduzecePostrojenje withPostrojenje={true} store={obrazac5Store} />

      <FormSearchInfiniteScroll
        showSearch={true}
        label={t('OBRASCI.OBRAZAC5.INDEKSNI_BROJ')}
        labelCol={{ span: 9 }}
        wrapperCol={{ span: 15 }}
        placeholder={t('OBRASCI.OBRAZAC5.INDEKSNI_BROJ')}
        formName={'indeksni_broj_otpada_id'}
        fetchOptinsList={obrazac5Store.fetchWasteCatalougeList}
        labelOptionAccessor="index_number"
        valueOptionAccessor="id"
        readOnly={false}
        optionsMaker={wasteCatalogueOptions}
        defaultOption={defaultIndeksniBrojOtpada}
        onChange={async value => {
          const obrazacExists = await checkIfObrazacExists({ indeksni_broj_otpada_id: value });
          if (!obrazacExists) {
            obrazac5Store.fetchInfoLastYear(
              obrasciStore.opste.godina as string,
              obrasciStore.opste.preduzece_id,
              obrasciStore.opste.postrojenje_id,
              value
            );
          }
        }}
        rules={[REQUIRED_FIELD_RULE(true)]}
        disabled={
          obrasciStore.opste.godina === '' ||
          obrasciStore.opste.postrojenje_id === '' ||
          obrazac5Store.waste_catalogue.index_number !== '' ||
          !obrasciStore.disabled_tab_button
        }
      />
    </Col>
  );
});
