import { ColProps, Form } from 'antd';
import { Select } from 'components/Select/Select';
import { observer } from 'mobx-react-lite';
import { REQUIRED_FIELD_RULE } from 'modules/nriz/nriz.constants';

type EqualsProps = {
  name: string;
  onChange?: (e: any) => any;
  label: string;
  labelCol?: ColProps;
  wrapperCol?: ColProps;
  readOnly?: boolean;
};

export const EqualsOrLessThan: React.FC<EqualsProps> = observer(props => {
  enum Equals {
    JEDNAKO = '=',
    MANJE = '<',
  }

  const options = [
    {
      label: Equals.JEDNAKO,
      value: Equals.JEDNAKO,
    },
    {
      label: Equals.MANJE,
      value: Equals.MANJE,
    },
  ];

  return (
    <>
      <Form.Item
        name={props.name}
        rules={[REQUIRED_FIELD_RULE(true)]}
        label={props.label}
        labelCol={props.labelCol}
        wrapperCol={props.wrapperCol}
      >
        <Select name={props.name} onChange={props.onChange} options={options} readOnly={props.readOnly}>
          {/* <Select.Option value={Equals.JEDNAKO}>{Equals.JEDNAKO}</Select.Option>
          <Select.Option value={Equals.MANJE}>{Equals.MANJE}</Select.Option> */}
        </Select>
      </Form.Item>
    </>
  );
});
