import { ColumnsType } from 'antd/es/table';
import { FormInputNumber } from 'components/FormInputNumber/FormInputNumber';
import { FormSearchInfiniteScroll } from 'components/FormSearchInfiniteScroll/FormSearchInfiniteScroll';
import { InsideContentWrapper } from 'components/InsideContentWrapper/InsideContentWrapper';
import { CRUDModal, ICrudTypeModalProps } from 'components/Modals/CRUDModal/CRUDModal';
import { EditButtonProps, RowEditPreviewButton } from 'components/RowEditPreviewButton/RowEditPreviewButton';
import TableData from 'components/TableData/TableData';
import { WizardObrazacListHeader } from 'components/WizardObrazacListHeader/WizardObrazacListHeader';
import Decimal from 'decimal.js';
import { observer } from 'mobx-react-lite';
import { MORE_THEN_0_IS_REQUIRED, REQUIRED_FIELD_RULE } from 'modules/nriz/nriz.constants';
import { obrasciStore } from 'modules/obrasci/obrasci.store';
import { DEO_6_PONOVNA_UPOTREBA_INITIAL_STATE } from 'modules/obrasci/store/deo6/deo6.constants';
import { deo6ObrazacStore } from 'modules/obrasci/store/deo6/deo6.store';
import { IGetPonovnaUpotrebaDeo6, IGetRazrediEeOtpadaDeo6 } from 'modules/obrasci/store/deo6/deo6.types';
import { ISifarnikInitial } from 'modules/sifarnici/sifarnici.types';
import { IGetWasteCatalogue } from 'modules/sifarnici/store/wasteCatalogue/wasteCatalogue.types';
import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';

const EditDeo6PonovnaUpotrebaOtpadaRazredButton: React.FC<EditButtonProps<IGetRazrediEeOtpadaDeo6>> = observer(
  ({ record }) => {
    return (
      <RowEditPreviewButton
        name="deo6-razred-ponovna-upotreba"
        modalName="deo6-razred-ponovna-upotreba-modal"
        onClick={() => {
          deo6ObrazacStore.handleChange('razredi_ee_form', record);
        }}
        preview={obrasciStore.zakljucanObrazac}
      />
    );
  }
);

export const TablePonovnaUpotrebaRazred: React.FC = observer(() => {
  const { t } = useTranslation();

  const baseColumns: ColumnsType<IGetRazrediEeOtpadaDeo6> = [
    {
      title: `${t('OBRASCI.GIO_3.CLASS_EE_WASTE')}`,
      width: 100,
      key: 'category_name',
      render: (_: any, record: IGetRazrediEeOtpadaDeo6) => (
        <>{`${record.razred_ee_otpada.the_order} ${record.razred_ee_otpada.category_name_sr}`}</>
      ),
    },
    {
      title: `${t('OBRASCI.GIO_3.QUANTITY_T')}`,
      width: 100,
      dataIndex: 'kolicina',
      key: 'kolicina',
    },
    {
      title: '',
      key: 'operation',
      fixed: 'right',
      width: 40,
      render: (_, record: IGetRazrediEeOtpadaDeo6) => <EditDeo6PonovnaUpotrebaOtpadaRazredButton record={record} />,
    },
  ];

  return (
    <TableData
      name="ponovna-upotreba-otpad-deo6"
      columns={baseColumns}
      dataSource={[...deo6ObrazacStore.ponovna_upotreba_otpada_form.razred]}
    />
  );
});

export const NewPonovnaUpotrebaDeo6Form: React.FC<ICrudTypeModalProps> = observer(props => {
  const { t } = useTranslation();

  const wasteCatalogueOptions = (options: IGetWasteCatalogue[]) => {
    return options.map(wasteOption => ({
      label: `${wasteOption.index_number} ${wasteOption.waste_sr.toUpperCase()}`,
      value: wasteOption.id,
    }));
  };

  const defaultTypeOfWaste: ISifarnikInitial | undefined = deo6ObrazacStore.getPonovnaUpotrebaForm
    .indeksni_broj_otpada_id
    ? {
        value: deo6ObrazacStore.getPonovnaUpotrebaForm.indeksni_broj_otpada_id,
        label: `${
          (deo6ObrazacStore.getPonovnaUpotrebaForm as IGetPonovnaUpotrebaDeo6).indeksni_broj_otpada.index_number
        } ${(
          deo6ObrazacStore.getPonovnaUpotrebaForm as IGetPonovnaUpotrebaDeo6
        ).indeksni_broj_otpada.waste_sr.toUpperCase()}`,
      }
    : undefined;

  useEffect(() => {
    if (deo6ObrazacStore.weee_categories) {
      props.form.validateFields(['kolicina']);
    }
  }, [deo6ObrazacStore.razredi_ee_form]);

  const readOnly = obrasciStore.zakljucanObrazac;

  return (
    <>
      <FormSearchInfiniteScroll
        showSearch={true}
        formName={'indeksni_broj_otpada_id'}
        fetchOptinsList={deo6ObrazacStore.fetchWasteCatalougeList}
        labelOptionAccessor="index_number"
        valueOptionAccessor="id"
        rules={[
          REQUIRED_FIELD_RULE(true),
          {
            validator(_, value) {
              if (
                deo6ObrazacStore.ponovna_upotreba_otpada_form.indeksni_broj_otpada_id !== value &&
                deo6ObrazacStore.initialState.ponovna_upotreba_otpada.some(item => {
                  return value === item.indeksni_broj_otpada_id;
                })
              ) {
                return Promise.reject(new Error(t('Indeksni broj već postoji u listi')));
              }
              return Promise.resolve();
            },
          },
        ]}
        inputName={'indeksni_broj_otpada_id'}
        label={t('OBRASCI.KOM_1.MANAGEMENT.WASTE_INDEX_NUMBER_FROM_THE_WASTE_CATALOG')}
        colon={true}
        defaultOption={defaultTypeOfWaste}
        placeholder={''}
        disabled={deo6ObrazacStore.index_id !== ''}
        onChange={e => {
          deo6ObrazacStore.handleChange('index_id', e);
          const obj = deo6ObrazacStore.indexMap(e);
          if (!obj.weee && deo6ObrazacStore.weee_categories) {
            props.form.validateFields(['kolicina']);
          }
          deo6ObrazacStore.checkWeeeCategories(e, 'handlePonovnaUpotrebaForm');
        }}
        optionsMaker={wasteCatalogueOptions}
        readOnly={readOnly}
      />
      <FormInputNumber
        name="kolicina"
        label={t('OBRASCI.DEO_6.KOLICINA_PONOVNO_UPOTREBLJENOG')}
        placeholder={''}
        inputName={'kolicina'}
        colon={true}
        precision={4}
        rules={[
          ...MORE_THEN_0_IS_REQUIRED,
          {
            validator(_, value) {
              if (deo6ObrazacStore.weee_categories) {
                let sum = new Decimal(0);
                (deo6ObrazacStore.ponovna_upotreba_otpada_form as IGetPonovnaUpotrebaDeo6).razred.map(
                  (item: IGetRazrediEeOtpadaDeo6) => (sum = sum.plus(item.kolicina ?? 0))
                );
                if (sum.toNumber() !== value) {
                  return Promise.reject(new Error(t('OBRASCI.VALIDATION.JEDNAKA_RAZRED')));
                }
              }
              return Promise.resolve();
            },
          },
        ]}
        readOnly={readOnly}
      />
      {deo6ObrazacStore.weee_categories && (
        <InsideContentWrapper
          header={
            <WizardObrazacListHeader
              title={t('OBRASCI.GIO_3.TYPE_OF_ELECTRICAL_AND_ELECTRONIC_WASTE')}
              name={'deo6-ponovna-upotreba-razredi-ee-form'}
              modalName="deo6-razred-ponovna-upotreba-modal"
            />
          }
        >
          <TablePonovnaUpotrebaRazred />
        </InsideContentWrapper>
      )}
    </>
  );
});

export const Deo6PonovnaUpotrebaModal: React.FC = observer(() => {
  const { t } = useTranslation();

  return (
    <CRUDModal
      width="50%"
      name="deo6-ponovna-upotreba-modal"
      title={t('WIZARD_TABS.PONOVNA_UPOTREBA_OTPADA')}
      store={deo6ObrazacStore}
      data={deo6ObrazacStore.getPonovnaUpotrebaForm}
      children={({ form }) => <NewPonovnaUpotrebaDeo6Form form={form} />}
      submitForm={deo6ObrazacStore.submitPonovnaUpotrebaForm}
      initialState={DEO_6_PONOVNA_UPOTREBA_INITIAL_STATE}
      dataToChange={'ponovna_upotreba_otpada_form'}
      labelCol={{ span: 12 }}
      wrapperCol={{ span: 12 }}
      onCancel={deo6ObrazacStore.resetWeeeCategories}
    />
  );
});
