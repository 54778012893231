import { FormInputNumber } from 'components/FormInputNumber/FormInputNumber';
import { FormSearchInfiniteScroll } from 'components/FormSearchInfiniteScroll/FormSearchInfiniteScroll';
import { ICrudTypeModalProps } from 'components/Modals/CRUDModal/CRUDModal';
import { t } from 'i18next';
import { observer } from 'mobx-react-lite';
import { MORE_THAN_OR_EQUAL_0_RULE, REQUIRED_FIELD_RULE } from 'modules/nriz/nriz.constants';
import { obrasciStore } from 'modules/obrasci/obrasci.store';
import { aao1Store } from 'modules/obrasci/store/aao1/aao1.store';
import { IOperateri } from 'modules/obrasci/store/aao1/aao1.types';
import { ISifarnikInitial } from 'modules/sifarnici/sifarnici.types';
import { IGetOperatorsPackageWasteManage } from 'modules/sifarnici/store/operatorsPackageWasteManage/operatorsPackageWasteManage.types';

export const NewAao1OperateriForm: React.FC<ICrudTypeModalProps> = observer(() => {
  const defaultOperaterOption: ISifarnikInitial | undefined = aao1Store.getOperater.operators_package_waste_manage_id
    ? {
        value: aao1Store.getOperater.operators_package_waste_manage_id,
        label: (aao1Store.getOperater as IOperateri).operators_package_waste_manage.preduzece.naziv,
      }
    : undefined;

  const operatorOptionsMaker = (options: IGetOperatorsPackageWasteManage[]) => {
    const nacinMerenje = options.filter(entity => entity.company_id !== obrasciStore.opste.preduzece_id);
    return nacinMerenje.map(entity => ({
      label: entity?.preduzece?.naziv,
      value: entity.id,
    }));
  };

  return (
    <>
      <FormSearchInfiniteScroll
        showSearch={true}
        label={t('OBRASCI.AAO1.PACKAGING_AMOUNT.ADD_OPERATOR')}
        labelCol={{ span: 8 }}
        wrapperCol={{ span: 16 }}
        formName={'operators_package_waste_manage_id'}
        labelOptionAccessor={'naziv'}
        valueOptionAccessor={'id'}
        optionsMaker={operatorOptionsMaker}
        defaultOption={defaultOperaterOption}
        rules={[
          REQUIRED_FIELD_RULE(true),
          {
            validator(_, value) {
              if (
                aao1Store.operater_form.operators_package_waste_manage_id !== value &&
                aao1Store.kolicinaAmbalaze.operateri.some(item => {
                  return value === item.operators_package_waste_manage_id;
                })
              ) {
                return Promise.reject(new Error(t('OBRASCI.AAO1.PACKAGING_AMOUNT.ERROR.OPERATOR_EXISTS')));
              }
              return Promise.resolve();
            },
          },
        ]}
        fetchOptinsList={aao1Store.fetchOperateriOptions}
        placeholder={''}
      />
      <FormInputNumber
        name="kolicina"
        label={t('OBRASCI.GIO_3.QUANTITY_T')}
        labelCol={{ span: 8 }}
        wrapperCol={{ span: 16 }}
        rules={[
          REQUIRED_FIELD_RULE(true),
          MORE_THAN_OR_EQUAL_0_RULE,
          // {
          // validator(_, value) {
          //   let operatorSum = 0;
          //   const prenetaKolicina = aao1Store.prenetaKolicina;
          //   const operater = form.getFieldValue('operators_package_waste_manage_id');
          //   if (
          //     aao1Store.kolicinaAmbalaze.operateri.length &&
          //     aao1Store.operater_form.operators_package_waste_manage_id !== operater
          //   )
          //     aao1Store.kolicinaAmbalaze.operateri.forEach((operator: IPostOperateri) => {
          //       operatorSum += Number(operator.kolicina);
          //     });
          //   if (value < 0) return Promise.reject(new Error(t('OBRASCI.AAO1.PACKAGING_AMOUNT.ERROR.LESS_THAN_0')));
          //   if (Number(value) + Number(operatorSum) > prenetaKolicina) {
          //     return Promise.reject(new Error(t('OBRASCI.AAO1.PACKAGING_AMOUNT.ERROR.OPERATOR_AMOUNT_SUM')));
          //   }
          //   return Promise.resolve();
          // },
          // },
        ]}
        placeholder={''}
        inputName={'kolicina'}
        colon={true}
      />
    </>
  );
});
