import { Content } from 'components/Content/Content';
import { ExcelAndAddButton } from 'components/ExcelAndAddButton/ExcelAndAddButton';
import { UsersListTable } from 'components/Tables';
import { observer } from 'mobx-react-lite';
import { useTranslation } from 'react-i18next';
import { authStore, modalStore } from 'store';
import { userStore } from 'modules/user/user.store';
import { useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import { UserFormType } from 'modules/user/user.types';
import { RoutePathEnum } from 'router/router.constants';

const UserListHeader = () => {
  const { t } = useTranslation();
  const location = useLocation();
  return (
    <div id="userHeader">
      <ExcelAndAddButton
        component={<h1 className="user-list-header">{t('USER.LIST')}</h1>}
        excelName={'user'}
        excelFunc={
          authStore.isAdmin || (authStore.isCompanyOwner && location.pathname.includes(RoutePathEnum.LISTA_KORISNIKA))
            ? () => {
                if (authStore.isAdmin) {
                  return userStore.exportUsersListExcel();
                }
              }
            : undefined
        }
        // todo: prevod
        addButtonLabel={`${authStore.isAdmin ? 'Dodaj administratora' : 'Dodaj korisnika na preduzeće'}`}
        addButtonOnChange={
          authStore.isAdmin || authStore.isCompanyOwner
            ? () => {
                if (authStore.isAdmin) {
                  modalStore.changeModalName('user-modal');
                  userStore.setUserTypeForm(UserFormType.UserAdministrator);
                  return;
                }

                if (authStore.isCompanyOwner) {
                  modalStore.changeModalName('user-modal');
                  userStore.setUserTypeForm(UserFormType.UserCompany);
                  return;
                }
              }
            : undefined
        }
      />
    </div>
  );
};

const UsersList: React.FC = observer(() => {
  useEffect(() => {
    authStore.isAdmin ? userStore.fetchUserList() : userStore.fetchUserCompanyList();
  }, []);

  return (
    <Content header={<UserListHeader />}>
      <UsersListTable
        isFacility={false}
        fetchListTable={authStore.isAdmin ? userStore.fetchUserList : userStore.fetchUserCompanyList}
        storePagination={userStore.userListPagination}
      />
    </Content>
  );
});

export default UsersList;
