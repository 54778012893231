import { Col } from 'antd';
import { FormDatePicker } from 'components/FormDatePicker/FormDatePicker';
import { observer } from 'mobx-react-lite';
import { REQUIRED_FIELD_RULE } from 'modules/nriz/nriz.constants';
import { deo6ObrazacStore } from 'modules/obrasci/store/deo6/deo6.store';
import { OpsteGodinaPreduzecePostrojenje } from 'pages/ObrazacWizard/components/components/OpsteGodinaPreduzecePostrojenje/OpsteGodinaPreduzecePostrojenje';
import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import styles from './Deo6OpsteWizardTab.module.scss';
import { obrasciStore } from 'modules/obrasci/obrasci.store';
import dayjs from 'dayjs';
import { disabledBefore30DayAfterNow, disabledTodayAndFutureDates } from 'utils/formatDate';
import { IGetFacility } from 'modules/facility/facility.types';
import { useObrazacExistsCheck } from 'hooks/useObrazacExistsCheck';

export const Deo6OpsteWizardTab: React.FC = observer(() => {
  const { t } = useTranslation();

  useEffect(() => {
    if (obrasciStore.opste.postrojenje_id && obrasciStore.opste.preduzece_id && deo6ObrazacStore.datum) {
      obrasciStore.setDisabledTabButton(false);
    }
  }, [deo6ObrazacStore.datum, obrasciStore.opste]);

  const onDeo6PostrojenjeChange = (postrojenje: IGetFacility) => {
    if (postrojenje.privremeno_ukinuta_validacija_za_deo6) deo6ObrazacStore.setDateValidationDisabled(true);
  };

  const checkIfObrazacExists = useObrazacExistsCheck();

  return (
    <Col className={styles.wrapper} span={12}>
      <OpsteGodinaPreduzecePostrojenje
        withPostrojenje={true}
        onChangePostrojenje={(_, option) => {
          onDeo6PostrojenjeChange(option.item);
        }}
        store={deo6ObrazacStore}
        withoutGodina={true}
      />
      <FormDatePicker
        name="datum"
        label={t('OBRASCI.DEO_6.DATUM_IZVESTAJA')}
        labelCol={{ span: 9 }}
        wrapperCol={{ span: 15 }}
        format="DD-MM-YYYY"
        picker={'date'}
        rules={[REQUIRED_FIELD_RULE(true)]}
        disabledDate={
          deo6ObrazacStore.dateValidationDisabled ? disabledTodayAndFutureDates : disabledBefore30DayAfterNow
        }
        onChange={async date => {
          const dateString = dayjs(date).format('YYYY-MM-DD');
          const obrazacExists = await checkIfObrazacExists({ datum: dateString });
          if (!obrazacExists) {
            deo6ObrazacStore.handleChange('godina', date.$y);
            deo6ObrazacStore.handleChange('datum', dateString);
            deo6ObrazacStore.getDeo6SkladisteInfo(dateString);
          }
        }}
        disabled={!!deo6ObrazacStore.datum || !obrasciStore.opste.preduzece_id || !obrasciStore.opste.postrojenje_id}
      />
    </Col>
  );
});
