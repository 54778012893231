import { IGetUser, IPostUser } from 'modules/user/user.types';

export interface IAuditLogs {
  session_id: string;
  table_name: string;
  action: ITipPromenaEnum | null;
  timestamp: string;
  before_payload: any;
  after_payload: any;
  kreirao: IGetUser | IPostUser | null;
  model_config: {
    from_attributes: boolean;
  };
}

export interface IGetIstorijaPromena {
  session_id: string;
  audit_logs: IAuditLogs[];
}

export enum ITipPromenaEnum {
  INSERT = 'INSERT',
  DELETE = 'DELETE',
  UPDATE = 'UPDATE',
}
