import { useForm } from 'antd/es/form/Form';
import { FilterTableForm } from 'components/TableData/FilterTableForm/FilterTableForm';
import { observer } from 'mobx-react-lite';
import { paginationInitialState } from 'modules/nriz/nriz.constants';
import { IS_ACTIVE_SWITCH } from 'modules/sifarnici/sifarniciFormObj.constants';
import { userStore } from 'modules/user/user.store';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useLocation } from 'react-router-dom';
import { RoutePathEnum } from 'router/router.constants';
import { authStore } from 'store';
import { FormInputProps } from 'typescript';
import { IFilterObjectProps, IFilterTableProps } from 'typescript/NrizTypes';

interface IUserFiltersProps {
  isFacility: boolean;
}

export const UserListFilters: React.FC<IUserFiltersProps> = observer(props => {
  const { t } = useTranslation();

  const location = useLocation();

  const [form] = useForm();

  const fetchUserList = () => {
    return props.isFacility
      ? userStore.fetchUserFacilityList(paginationInitialState)
      : location.pathname.includes('/users') && authStore.isAdmin
      ? userStore.fetchUserList(paginationInitialState)
      : userStore.fetchUserCompanyList(paginationInitialState);
  };

  const dataFilters = {
    form: form,
    initialState: {
      sistem_administrator: false,
      ime: '',
      prezime: '',
      username: '',
      preduzece__pib__ilike: '',
      preduzece__maticni_broj__ilike: '',
      preduzece__naziv__ilike: '',
      order_by: '',
      is_active: '',
    },
    filterName: props.isFacility ? 'korisnik_postrojenje' : 'korisnik',
    dontAddIlike: location.pathname.includes(RoutePathEnum.LISTA_KORISNIKA) && authStore.isAdmin,
    filterOptions: [
      {
        type_of_form: 'input',
        inputName: 'username',
        name: 'username',
        label: 'Korisničko ime',
        sortName: 'username',
      },
      {
        type_of_form: 'input',
        inputName: 'ime',
        name: 'ime',
        label: 'Ime korisnika',
        sortName: 'ime',
      },
      {
        type_of_form: 'input',
        inputName: 'prezime',
        name: 'prezime',
        label: 'Prezime korisnika',
        sortName: 'prezime',
      },

      {
        label: t('COMPANY.NAME'),
        name: 'preduzece__naziv__ilike',
        type_of_form: 'input',
        inputName: 'preduzece__naziv__ilike',
        // sortName: 'preduzece__naziv__ilike',
        hideInput: !(location.pathname.includes(RoutePathEnum.LISTA_KORISNIKA) && authStore.isAdmin),
      } as FormInputProps & IFilterObjectProps,
      {
        label: `${t('COMPANY.PIB')} preduzeća`,
        name: 'preduzece__pib__ilike',
        type_of_form: 'input',
        inputName: 'preduzece__pib__ilike',
        // sortName: 'preduzece__pib__ilike',
        hideInput: !(location.pathname.includes(RoutePathEnum.LISTA_KORISNIKA) && authStore.isAdmin),
      } as FormInputProps & IFilterObjectProps,
      {
        label: t('COMPANY.ENTERPRISE_ID_NUMBER'),
        name: 'preduzece__maticni_broj__ilike',
        type_of_form: 'input',
        inputName: 'preduzece__maticni_broj__ilike',
        // sortName: 'preduzece__maticni_broj__ilike',
        hideInput: !(location.pathname.includes(RoutePathEnum.LISTA_KORISNIKA) && authStore.isAdmin),
      } as FormInputProps & IFilterObjectProps,
      {
        type_of_form: 'switch',
        label: 'Lista administratora',
        name: 'sistem_administrator',
        hideInput: !(location.pathname.includes(RoutePathEnum.LISTA_KORISNIKA) && authStore.isAdmin),
      },
      IS_ACTIVE_SWITCH,
    ],
    fetchTableList: fetchUserList,
  } as IFilterTableProps;

  return (
    <div id="userFilters">
      <FilterTableForm data={dataFilters} />
    </div>
  );
});
