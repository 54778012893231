import { IBaseObrazacStore, IPaginatedResponse, IPagination } from 'typescript/NrizTypes';
import {
  IGetDeponijeGio2,
  IGetDozvoleGio2,
  IGetGio2Obrazac,
  IGetKolicineGio2,
  IGetRazrediEeOtpadaGio2,
  IPostDeponijeGio2,
  IPostDozvoleGio2,
  IPostGio2Obrazac,
  IPostKolicineGio2,
  IPostRazrediEeOtpadaGio2,
} from './gio2.types';
import {
  GIO_2_DEPONIJE_INITIAL_STATE,
  GIO_2_DOZVOLE_INITIAL_STATE,
  GIO_2_INITIAL_STATE,
  GIO_2_KOLICINE_INITIAL_STATE,
  GIO_2_RAZREDI_EE_OTPADA_INITIAL_STATE,
} from './gio2.constants';
import { computed, makeAutoObservable, observable } from 'mobx';
import { obrasciService } from 'modules/obrasci/obrasci.service';
import { sifarniciStore } from 'modules/sifarnici/sifarnici.store';
import { SIFARNICI_ROUTES } from 'modules/sifarnici/sifarnici.types';
import { formStore } from 'store';
import { nrizService } from 'modules/nriz/nriz.service';
import { arrayToObject } from 'utils/arrayToObject';
import { obrasciStore } from 'modules/obrasci/obrasci.store';

import to from 'await-to-js';
import dayjs from 'dayjs';
import { TIPOVI_OBRAZACA } from 'modules/obrasci/obrasci.types';
import { handleSuccessNotify } from 'utils/succesHandling';
import { IGetDRList } from 'modules/sifarnici/store/dRList/dRList.types';
import { IGetMesto } from 'modules/sifarnici/store/mesto/mesto.types';
import { IGetWasteCatalogue } from 'modules/sifarnici/store/wasteCatalogue/wasteCatalogue.types';
import { IGetWeeeCategories } from 'modules/sifarnici/store/weeeCategories/weeeCategories.types';
import { IGetLandfillType } from 'modules/sifarnici/store/landfillType/landfillType.types';

class Gio2ObrazacStore implements IBaseObrazacStore<IGetGio2Obrazac, IPostGio2Obrazac> {
  initialState: IPostGio2Obrazac = GIO_2_INITIAL_STATE;
  dozvole_form: IPostDozvoleGio2 | IGetDozvoleGio2 = GIO_2_DOZVOLE_INITIAL_STATE;
  deponije_form: IPostDeponijeGio2 | IGetDeponijeGio2 = GIO_2_DEPONIJE_INITIAL_STATE;
  kolicine_form: IPostKolicineGio2 | IGetKolicineGio2 = GIO_2_KOLICINE_INITIAL_STATE;
  razredi_ee_form: IPostRazrediEeOtpadaGio2 | IGetRazrediEeOtpadaGio2 = GIO_2_RAZREDI_EE_OTPADA_INITIAL_STATE;
  index_id = '';

  dozvoleNizValidacija: string[] = [];

  constructor() {
    makeAutoObservable(this, {
      initialState: observable,
      dozvole_form: observable,
      deponije_form: observable,
      kolicine_form: observable,
      razredi_ee_form: observable,
      index_id: observable,

      getInitialState: computed,
      getDozvoleForm: computed,
      getDeponijeForm: computed,
      getKolicineForm: computed,
      getRazrediForm: computed,
    });
  }

  get getDozvoleForm() {
    return this.dozvole_form;
  }
  get getDeponijeForm() {
    return this.deponije_form;
  }
  get getRazrediForm() {
    return this.razredi_ee_form;
  }
  get getKolicineForm() {
    return this.kolicine_form;
  }

  get getRazrediEeForm() {
    return this.razredi_ee_form;
  }

  get getInitialState() {
    return this.initialState;
  }

  handleChange(key: string, value: any) {
    (this as any)[key] = value;
  }

  handleInitialStateChange(key: string, value: any) {
    (this as any).initialState[key] = value;
  }

  handleKolicineForm(key: string, value: any) {
    (this as any).kolicine_form[key] = value;
  }

  resetStates = () => {
    this.handleChange('initialState', GIO_2_INITIAL_STATE);
  };

  resetMicro = () => {
    this.handleChange('kolicine_form', GIO_2_KOLICINE_INITIAL_STATE);
  };

  /// DOZVOLE

  promeniDozvoleNizValidacijaListu = (type: string, value: boolean) => {
    const indexOfType = this.dozvoleNizValidacija.indexOf(type);

    if (value === true && indexOfType === -1) {
      this.dozvoleNizValidacija.push(type);
    } else if (value === false && indexOfType !== -1) {
      this.dozvoleNizValidacija.splice(indexOfType, 1);
    }
  };

  submitDozvoleForm = (payload: IPostDozvoleGio2) => {
    if (!this.dozvoleNizValidacija.length) return;
    let newPayload = payload;
    newPayload = { id: (this.dozvole_form as IGetDozvoleGio2).id, ...newPayload } as IGetDozvoleGio2;

    const newList = obrasciService.submitMicroServiceForm(this.initialState.dozvole, newPayload);
    // handleSuccessNotify();
    this.handleInitialStateChange('dozvole', newList);
    this.handleChange('dozvole_form', GIO_2_DOZVOLE_INITIAL_STATE);
  };

  makeNewDozvole = (items: IPostDozvoleGio2[]) => {
    const newDozvoleArray = items.map((item: IPostDozvoleGio2) => {
      return {
        ...item,
        datum_isteka: dayjs(item.datum_isteka),
        datum_izdavanja: dayjs(item.datum_izdavanja),
      };
    });
    return newDozvoleArray;
  };

  /// DEPONIJE

  fetchMesto = async (pagination: IPagination, search: string): Promise<IPaginatedResponse<IGetMesto>> => {
    const response = await sifarniciStore.fetchSifarnikOptions<IGetMesto>(SIFARNICI_ROUTES.MESTO, {
      page: pagination.page,
      size: pagination.size,
      search: search,
      location_code__neq: 999999,
      order_by: '+display_name',
    });
    return response;
  };

  fetchLandfillType = async (
    pagination: IPagination,
    search: string
  ): Promise<IPaginatedResponse<IGetLandfillType>> => {
    const response = await sifarniciStore.fetchSifarnikOptions<IGetLandfillType>(SIFARNICI_ROUTES.LANDFILL_TYPE, {
      page: pagination.page,
      size: pagination.size,
      search: search,
      ///////
    });
    return response;
  };

  submitDeponijeForm = (payload: IPostDeponijeGio2) => {
    let newPayload = payload;
    let sifarniMestoDeponije = {};
    let sifarnikVrstaDeponije = {};

    if (formStore.getCrudButtonType === 'submit' || formStore.getCrudButtonType === 'change') {
      sifarniMestoDeponije = nrizService.addSifarnikInObject({
        exId: this.deponije_form.mesto_deponije_id,
        exObj: (this.deponije_form as IGetDeponijeGio2).mesto_deponije,
        list: sifarniciStore.list[SIFARNICI_ROUTES.MESTO],
        name: 'mesto_deponije',
        id: payload.mesto_deponije_id,
      });

      sifarnikVrstaDeponije = nrizService.addSifarnikInObject({
        exId: this.deponije_form.vrsta_deponije_id,
        exObj: (this.deponije_form as IGetDeponijeGio2).vrsta_deponije,
        list: sifarniciStore.list[SIFARNICI_ROUTES.LANDFILL_TYPE],
        name: 'vrsta_deponije',
        id: payload.vrsta_deponije_id,
      });
    }
    newPayload = {
      id: (this.deponije_form as IGetDeponijeGio2).id,
      ...newPayload,
      ...sifarniMestoDeponije,
      ...sifarnikVrstaDeponije,
    } as IGetDeponijeGio2;

    const newList = obrasciService.submitMicroServiceForm(this.initialState.deponije, newPayload);
    // handleSuccessNotify();
    this.handleInitialStateChange('deponije', newList);
    this.handleChange('deponije_form', GIO_2_DEPONIJE_INITIAL_STATE);
  };

  // KOLICINE

  submitKolicineForm = (payload: IPostKolicineGio2) => {
    let newPayload = { ...payload, razredi_ee_otpada: this.kolicine_form.razredi_ee_otpada };
    let sifarnikIndex = {};
    let sifarnikPostupak = {};
    let sifarnikDrugiPostupak = {};

    if (formStore.getCrudButtonType === 'submit' || formStore.getCrudButtonType === 'change') {
      sifarnikIndex = nrizService.addSifarnikInObject({
        exId: this.kolicine_form.indeksni_broj_otpada_id,
        exObj: (this.kolicine_form as IGetKolicineGio2).indeksni_broj_otpada,
        list: sifarniciStore.list[SIFARNICI_ROUTES.WASTE_CATALOGUE],
        name: 'indeksni_broj_otpada',
        id: payload.indeksni_broj_otpada_id,
      });

      sifarnikPostupak = nrizService.addSifarnikInObject({
        exId: this.kolicine_form.postupak_odlaganja_d_oznaka_id,
        exObj: (this.kolicine_form as IGetKolicineGio2).postupak_odlaganja_d_oznaka,
        list: sifarniciStore.list[SIFARNICI_ROUTES.DR_LIST],
        name: 'postupak_odlaganja_d_oznaka',
        id: payload.postupak_odlaganja_d_oznaka_id,
      });

      sifarnikDrugiPostupak = nrizService.addSifarnikInObject({
        exId: this.kolicine_form.drugi_postupak_odlaganja_d_oznaka_id,
        exObj: (this.kolicine_form as IGetKolicineGio2).drugi_postupak_odlaganja_d_oznaka,
        list: sifarniciStore.list[SIFARNICI_ROUTES.DR_LIST],
        name: 'drugi_postupak_odlaganja_d_oznaka',
        id: payload.drugi_postupak_odlaganja_d_oznaka_id,
      });
    }

    newPayload = {
      id: (this.kolicine_form as IGetKolicineGio2).id,
      ...newPayload,
      ...sifarnikIndex,
      ...sifarnikDrugiPostupak,
      ...sifarnikPostupak,
      razredi_ee_otpada: (this.kolicine_form as IGetKolicineGio2).razredi_ee_otpada,
    } as IGetKolicineGio2;
    const newList = obrasciService.submitMicroServiceForm(this.initialState.kolicine, newPayload);
    // handleSuccessNotify();
    this.handleInitialStateChange('kolicine', newList);
    this.handleChange('kolicine_form', GIO_2_KOLICINE_INITIAL_STATE);
    this.handleChange('index_id', '');
  };

  submitRazredEeOtpada = (payload: IPostRazrediEeOtpadaGio2) => {
    let newPayload = payload;
    let sifarnikRazredEeOtapada = {};

    if (formStore.getCrudButtonType === 'submit' || formStore.getCrudButtonType === 'change') {
      sifarnikRazredEeOtapada = nrizService.addSifarnikInObject({
        exId: this.razredi_ee_form.razred_ee_otpada_id,
        exObj: (this.razredi_ee_form as IGetRazrediEeOtpadaGio2).razred_ee_otpada,
        list: sifarniciStore.list[SIFARNICI_ROUTES.WEEE_CATEGORIES],
        name: 'razred_ee_otpada',
        id: newPayload.razred_ee_otpada_id,
      });
    }

    newPayload = { ...this.razredi_ee_form, ...newPayload, ...sifarnikRazredEeOtapada };
    const newList = obrasciService.submitMicroServiceForm(
      this.kolicine_form.razredi_ee_otpada,
      newPayload,
      true,
      'gio2-razredi-ee-modal'
    );
    // handleSuccessNotify();
    this.handleKolicineForm('razredi_ee_otpada', newList);
    this.handleChange('razredi_ee_form', GIO_2_RAZREDI_EE_OTPADA_INITIAL_STATE);
  };

  fetchWeeeCategoriesList = async (
    pagination: IPagination,
    search: string
  ): Promise<IPaginatedResponse<IGetWeeeCategories>> => {
    const response = await sifarniciStore.fetchSifarnikOptions<IGetWeeeCategories>(SIFARNICI_ROUTES.WEEE_CATEGORIES, {
      page: pagination.page,
      size: pagination.size,
      search: search,
      waste_catalogue_id: this.index_id,
      order_by: '+the_order',
    });
    return response;
  };

  fetchWasteCatalougeList = async (
    pagination: IPagination,
    search: string
  ): Promise<IPaginatedResponse<IGetWasteCatalogue>> => {
    const response = await sifarniciStore.fetchSifarnikOptions<IGetWasteCatalogue>(SIFARNICI_ROUTES.WASTE_CATALOGUE, {
      page: pagination.page,
      size: pagination.size,
      search: search,
      level: 3,
      order_by: '+index_number',
    });
    return response;
  };

  fetchDrList = async (pagination: IPagination, search: string): Promise<IPaginatedResponse<IGetDRList>> => {
    const response = await sifarniciStore.fetchSifarnikOptions<IGetDRList>(SIFARNICI_ROUTES.DR_LIST, {
      search: search,
      name__ilike: 'D',
      name__not_in: Number(obrasciStore.opste.godina) > 2019 ? 'D14,D15' : 'D1,D10',
      order_by: '+name',
    });
    return response;
  };

  loadData = async (id: string) => {
    const [err, res] = await to(obrasciService.fetchSingleObrazac<IGetGio2Obrazac>(TIPOVI_OBRAZACA.GIO2, id));
    if (err) return;
    const newGodina = dayjs().year((res.godina as any) * 1);
    const newDozvole = this.makeNewDozvole((res as IGetGio2Obrazac).dozvole);
    this.handleChange('initialState', { ...res, godina: newGodina, dozvole: newDozvole });
    return res;
  };

  async postData(payload: IPostGio2Obrazac) {
    const [err, res] = await to(
      obrasciService.postSingleObrazac<IPostGio2Obrazac>(
        TIPOVI_OBRAZACA.GIO2,
        nrizService.setEmptyValuesToNull(payload)
      )
    );
    if (err || !res) return;
    formStore.handleChange('goBack', true);
    handleSuccessNotify();
  }
  putData = async (payload: IGetGio2Obrazac) => {
    const [err, res] = await to(
      obrasciService.putSingleObrazac<IGetGio2Obrazac>(TIPOVI_OBRAZACA.GIO2, payload.id, payload)
    );
    if (err || !res) return;
    formStore.handleChange('goBack', true);
    handleSuccessNotify();
  };

  async deleteData(id: string) {
    const [err, res] = await to(obrasciService.deleteSingleObrazac(TIPOVI_OBRAZACA.GIO2, id));
    if (err || !res) return;
    formStore.handleChange('goBack', true);
    handleSuccessNotify();
  }
  submitData = (payload: IPostGio2Obrazac) => {
    GIO_2_INITIAL_STATE;
    const newInitialState = {
      ...GIO_2_INITIAL_STATE,
      ...payload,
      id: (this.initialState as IGetGio2Obrazac).id,
      deponije: this.initialState.deponije,
      kolicine: this.initialState.kolicine,
      dozvole: this.initialState.dozvole,
      godina: obrasciStore.opste.godina,
    } as IGetGio2Obrazac;

    obrasciService.submitFormObrazac({
      payload: newInitialState,
      postForm: this.postData,
      putForm: this.putData,
      deleteForm: this.deleteData,
    });
  };
  indexMap = (id: string) => {
    const obj = arrayToObject(sifarniciStore.list.WASTE_CATALOGUE);
    const findItem = obj[id] as IGetWasteCatalogue;
    return findItem;
  };
}

export const gio2ObrazacStore = new Gio2ObrazacStore();
