import { Modal } from 'components/Modal/Modal';
import { observer } from 'mobx-react-lite';
import styles from './PibFormInfoModal.module.scss';
import { modalStore } from 'store';
import { companyStore } from 'modules/company/company.store';
import { PibMaticniCheckEnum } from 'modules/company/company.types';

export const PibFormInfoModal = observer(() => {
  if (!companyStore.pibMbInfoModalData) return null;

  const { type, values } = companyStore.pibMbInfoModalData;

  const onOkClick = () => {
    companyStore.postPib(values);
  };

  const onCancel = () => {
    companyStore.setPibMbInfoModalData(null);
    modalStore.removeAllModals();
  };

  //todo: prevod

  const infoMessage = `Preduzeće sa unetim ${
    type === PibMaticniCheckEnum.PibExists ? 'PIB-om' : 'matičnim brojem'
  } već postoji. Da li želite da nastavite sa registracijom?`;

  return (
    <Modal
      width="50%"
      name="pib-mb-info-modal"
      destroyOnClose={true}
      open={modalStore.getCurrentOpenedModal.includes('pib-mb-info-modal')}
      okText={'Nastavi registraciju'}
      okButtonProps={{ onClick: onOkClick }}
      cancelText={'Odustani'}
      cancelButtonProps={{ onClick: onCancel }}
      closable={false}
    >
      <div className={styles.wrapper}>
        <p>{infoMessage}</p>
      </div>
    </Modal>
  );
});
