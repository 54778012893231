import { ColumnsType } from 'antd/es/table';
import TableData from 'components/TableData/TableData';
import { observer } from 'mobx-react-lite';
import { useTranslation } from 'react-i18next';
import style from './Aao2UpravljanjeAmbalaznimOtpadomTable.module.scss';
import { aao2Store } from 'modules/obrasci/store/aao2/aao2.store';
import { IAao2UpravljanjeAmbalaznimOtpadom } from 'modules/obrasci/store/aao2/aao2.types';
import { EditButtonProps, RowEditPreviewButton } from 'components/RowEditPreviewButton/RowEditPreviewButton';
import { obrasciStore } from 'modules/obrasci/obrasci.store';

const EditAao2UpravljanjeAmbalaznimOtpadomButton: React.FC<EditButtonProps<IAao2UpravljanjeAmbalaznimOtpadom>> =
  observer(({ record }) => {
    return (
      <RowEditPreviewButton
        name="aao2-upravljanje-ambalaznim-otpadom"
        modalName="aao2-upravljanje-ambalaznim-otpadom-modal"
        onClick={() => aao2Store.handleChange('upravljanjeAmbalaznimOtpadom', record)}
        preview={obrasciStore.zakljucanObrazac}
      />
    );
  });

export const Aao2UpravljanjeAmbalaznimOtpadomTable: React.FC = observer(() => {
  const { t } = useTranslation();

  const columns: ColumnsType<IAao2UpravljanjeAmbalaznimOtpadom> = [
    {
      title: t('OBRASCI.AAO1.MANAGEMENT_OF_PACKAGING_WASTE.PACKAGING_TYPE'),
      width: 150,
      align: 'center',
      dataIndex: ['type_of_packing', 'display_type_sr'],
      key: 'display_type',
    },
    {
      title: t('OBRASCI.AAO2.MANAGEMENT_OF_PACKAGING_WASTE.MUNICIPAL_WASTE'),
      width: 150,
      align: 'center',
      dataIndex: 'preuzeta_kolicina_komunalnog_otpada',
      key: 'preuzeta_kolicina_komunalnog_otpada',
    },
    {
      title: t('OBRASCI.AAO2.MANAGEMENT_OF_PACKAGING_WASTE.NON_MUNICIPAL_WASTE'),
      width: 150,
      align: 'center',
      dataIndex: 'preuzeta_kolicina_nekomunalnog_otpada',
      key: 'preuzeta_kolicina_nekomunalnog_otpada',
    },
    {
      title: t('OBRASCI.AAO1.MANAGEMENT_OF_PACKAGING_WASTE.R1_OPERATION_AMOUNT'),
      width: 150,
      align: 'center',
      dataIndex: 'kolicina_za_r1_operaciju',
      key: 'kolicina_za_r1_operaciju',
    },
    {
      title: t('OBRASCI.AAO1.MANAGEMENT_OF_PACKAGING_WASTE.SECOND_R_OPERATION'),
      width: 150,
      align: 'center',
      key: 'druga_operacija_r',
      render: (_: any, record: IAao2UpravljanjeAmbalaznimOtpadom) =>
        record.druga_operacija_r ? (
          <>{`${record.druga_operacija_r.name} ${record.druga_operacija_r.description_sr}`}</>
        ) : (
          <></>
        ),
    },
    {
      title: t('OBRASCI.AAO1.MANAGEMENT_OF_PACKAGING_WASTE.R2_OPERATION_AMOUNT'),
      width: 150,
      align: 'center',
      dataIndex: 'kolicina_za_drugu_r_operaciju',
      key: 'kolicina_za_drugu_r_operaciju',
    },
    {
      title: t('OBRASCI.AAO1.MANAGEMENT_OF_PACKAGING_WASTE.D1_OPERATION_AMOUNT'),
      width: 150,
      align: 'center',
      dataIndex: 'kolicina_za_d1_operaciju',
      key: 'kolicina_za_d1_operaciju',
    },
    {
      title: t('OBRASCI.AAO1.MANAGEMENT_OF_PACKAGING_WASTE.SECOND_D_OPERATION'),
      width: 150,
      align: 'center',
      key: 'druga_operacija_d',
      render: (_: any, record: IAao2UpravljanjeAmbalaznimOtpadom) =>
        record.druga_operacija_d ? (
          <>{`${record.druga_operacija_d.name} ${record.druga_operacija_d.description_sr}`}</>
        ) : (
          <></>
        ),
    },
    {
      title: t('OBRASCI.AAO1.MANAGEMENT_OF_PACKAGING_WASTE.D2_OPERATION_AMOUNT'),
      width: 150,
      align: 'center',
      dataIndex: 'kolicina_za_drugu_d_operaciju',
      key: 'kolicina_za_drugu_d_operaciju',
    },

    {
      title: '',
      fixed: 'right',
      width: 50,
      render: (_, record: IAao2UpravljanjeAmbalaznimOtpadom) => (
        <EditAao2UpravljanjeAmbalaznimOtpadomButton record={record} />
      ),
    },
  ];

  return (
    <>
      <div className={style.tableDiv}>
        <TableData
          name="upravljanje-aao2"
          columns={columns}
          dataSource={[...aao2Store.getInitialState.upravljanje_ambalaznim_otpadom]}
        />
      </div>
    </>
  );
});
