import { ApiResponse, axios } from 'modules/axios';
import { ROUTES } from 'modules/nriz/nriz.constants';
import queryString from 'query-string';
import { IGetAir, IPostAir } from './air.types';
import { IEmptyResponse, IPaginatedApiResponse, IPostResponse } from 'typescript/NrizTypes';

const fetchAirList = (payload: any): IPaginatedApiResponse<IGetAir> => {
  const query = queryString.stringify(payload);
  return axios.get(`${ROUTES.AIR}?${query}`);
};

const postAir = (payload: IPostAir): ApiResponse<IPostResponse> => {
  return axios.post(`${ROUTES.AIR}`, payload);
};

const putAir = (payload: IGetAir): ApiResponse<IEmptyResponse> => {
  return axios.put(`${ROUTES.AIR}${payload.id}`, payload);
};

const aktivirajVazduh = (id: string): ApiResponse<IEmptyResponse> => {
  return axios.put(`${ROUTES.AIR}${id}/aktiviraj`);
};
const deaktivirajVazduh = (id: string): ApiResponse<IEmptyResponse> => {
  return axios.put(`${ROUTES.AIR}${id}/deaktiviraj`);
};

export const airRepo = {
  postAir,
  putAir,
  fetchAirList,
  aktivirajVazduh,
  deaktivirajVazduh,
};
