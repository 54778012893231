import { EditOutlined } from '@ant-design/icons';
import { Button } from 'antd';
import { ColumnsType } from 'antd/es/table';
import { YesNoCell } from 'components/CallRenderers/YesNoCell/YesNoCell';
import TableData from 'components/TableData/TableData';
import { observer } from 'mobx-react-lite';
import { useTranslation } from 'react-i18next';
import React from 'react';

import { modalStore, authStore } from 'store';
import { IGetActivity2 } from 'modules/activityList2/activityList2.types';
import { activityList1Store } from 'modules/activityList1/activityList1.store';
import { activityList2Store } from 'modules/activityList2/activityList2.store';

export const ActivityList2Table: React.FC = observer(() => {
  const { t } = useTranslation();

  const baseColumns: ColumnsType<IGetActivity2> = [
    {
      title: `${t('ACTIVITIES.ECONOMIC_ACTIVITY')}`,
      width: '200px',
      dataIndex: [`privredna_delatnost2`, 'name'],
      key: `privredna_delatnost2_id`,
      render: (_, record: IGetActivity2) => {
        return <p>{`${record.privredna_delatnost2.code}. ${record.privredna_delatnost2.name}`}</p>;
      },
    },
    {
      title: `${t('ACTIVITIES.ACTIVITY')}`,
      width: '400px',
      // dataIndex: [`aktivnost2`, 'name'],
      key: `aktivnost2_id`,
      render: (_, record: IGetActivity2) => {
        return <p>{`${record.aktivnost2.code} ${record.aktivnost2.name}`}</p>;
      },
    },
    {
      title: `${t('ACTIVITIES.MAIN')}`,
      width: '100px',
      key: `glavna_aktivnost`,
      render: (_, record) => {
        return <YesNoCell yesNo={record.glavna_aktivnost} />;
      },
    },
  ];

  const adminColumns: ColumnsType<IGetActivity2> = [
    ...baseColumns,
    {
      title: '',
      key: `operation_2`,
      fixed: 'right',
      width: '50px',
      align: 'center',
      render: (_, record: IGetActivity2) => {
        return (
          <Button
            icon={<EditOutlined />}
            name={'edit-activity'}
            disabled={activityList1Store.activityList.length !== 0}
            onClick={() => {
              activityList2Store.setActivity(record);
              activityList2Store.handleChange('privredna_delatnost_id', record.privredna_delatnost_id);
              modalStore.changeModalName(`activity-list-modal-2`);
            }}
          />
        );
      },
    },
  ];

  let validColumns = baseColumns;

  authStore.isAdmin || authStore.isCompanyOwner ? (validColumns = adminColumns) : (validColumns = baseColumns);

  return (
    <TableData
      name="activity2"
      columns={validColumns}
      dataSource={activityList2Store.getActivityList}
      refetchData={activityList2Store.fetchActivityList}
      storePagination={activityList2Store.activityList2Pagination}
    />
  );
});
