import { Form } from 'antd';
import { useForm } from 'antd/es/form/Form';
import { CRUDButton } from 'components/Buttons/CRUDButton/CRUDButton';
import { FormInput } from 'components/FormInput/FormInput';
import { FormTextArea } from 'components/FormTextArea/FormTextArea';
import { FormRangePicker } from 'components/FormRangePicker/FormRangePicker';
import { observer } from 'mobx-react-lite';
import { LATITUDE_RULE, LONGITUDE_RULE, REQUIRED_FIELD_RULE } from 'modules/nriz/nriz.constants';
import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { authStore, formStore } from 'store';
import { IFormProps } from 'typescript/NrizTypes';

import { FormSearchInfiniteScroll } from 'components/FormSearchInfiniteScroll/FormSearchInfiniteScroll';

import { IGetUnsanitaryLandfill } from 'modules/unsanitarylandfill/unsanitarylandfill.types';
import { unsanitaryLandfillStore } from 'modules/unsanitarylandfill/unsanitarylandfill.store';
import { FormInputNumber } from 'components/FormInputNumber/FormInputNumber';

export const NewUnsanitaryLandfill: React.FC<IFormProps> = observer(props => {
  const [form] = useForm();
  const { t } = useTranslation();

  const onFinishFailed = () => {
    const newValues = form.validateFields().then(() => {
      form.setFields(
        form.getFieldsValue().reduce((acc: any, value: any, key: any) => {
          acc[key] = {
            value,
            error: [],
            rules: [],
          };
          return acc;
        }, {})
      );
    });
    return unsanitaryLandfillStore.submitUnsanitaryLandfill(newValues as any);
  };

  useEffect(() => {
    form.setFieldsValue(unsanitaryLandfillStore.unsanitaryLandfill);
  }, [unsanitaryLandfillStore.getUnsanitaryLandfill]);

  const defaultMestoOption = unsanitaryLandfillStore.unsanitaryLandfill.mesto_id
    ? {
        label: (unsanitaryLandfillStore.unsanitaryLandfill as IGetUnsanitaryLandfill).mesto.display_name,
        value: unsanitaryLandfillStore.unsanitaryLandfill.mesto_id,
      }
    : undefined;

  return (
    <Form
      form={form}
      initialValues={unsanitaryLandfillStore.unsanitaryLandfill}
      name={props.formName}
      labelCol={{ span: 12 }}
      wrapperCol={{ span: 12 }}
      layout="horizontal"
      onFinish={values => {
        unsanitaryLandfillStore.submitUnsanitaryLandfill(values);
      }}
      labelAlign="left"
      onFinishFailed={() => {
        if (formStore.crudButtonType === 'delete') {
          onFinishFailed();
        }
      }}
    >
      <FormInput
        label={t('UNSANITARY_LANDFILL.MARK_OF_UNSANITARY_LANDFILL')}
        name="oznaka_nesanitarne_deponije"
        inputName={'oznaka_nesanitarne_deponije'}
        type={'text'}
        rules={[REQUIRED_FIELD_RULE(!props.readOnly)]}
        readOnly={props.readOnly}
        disabled={
          unsanitaryLandfillStore.unsanitaryLandfill.oznaka_nesanitarne_deponije !== '' && authStore.isCompanyUser
        }
      />
      <FormInput
        label={t('UNSANITARY_LANDFILL.NAME')}
        name="naziv"
        inputName={'naziv'}
        type={'text'}
        rules={[REQUIRED_FIELD_RULE(!props.readOnly)]}
        readOnly={props.readOnly}
      />

      <FormSearchInfiniteScroll
        showSearch={true}
        placeholder={t('COMPANY.PLACEHOLDER.ENTER_CITY')}
        formName={'mesto_id'}
        fetchOptinsList={unsanitaryLandfillStore.fetchMesto}
        labelOptionAccessor="display_name"
        valueOptionAccessor="id"
        label={t('COMPANY.LOCATION')}
        readOnly={props.readOnly}
        inputName={'mesto_id'}
        rules={[REQUIRED_FIELD_RULE(!props.readOnly)]}
        defaultOption={defaultMestoOption}
      />
      <FormInputNumber
        name="koordinate_lat"
        rules={[REQUIRED_FIELD_RULE(!props.readOnly), ...LATITUDE_RULE()]}
        type="number"
        readOnly={props.readOnly}
        placeholder={t('FACILITY.PLACEHOLDER.ENTER_LATITUDE')}
        label={t('COMPANY.LATITUDE')}
        inputName={'koordinate_lat'}
        colon={true}
      />

      <FormInputNumber
        name="koordinate_long"
        type="number"
        rules={[REQUIRED_FIELD_RULE(!props.readOnly), ...LONGITUDE_RULE()]}
        placeholder={t('FACILITY.PLACEHOLDER.ENTER_LONGITUDE')}
        label={t('COMPANY.LONGITUDE')}
        readOnly={props.readOnly}
        inputName={'koordinate_long'}
        colon={true}
      />
      <h4 style={{ marginBlock: '30px', fontWeight: 'bold' }}>
        {t('KOORDINATE_TEXT')}
        <a target="_blank" href="http://www.nrizgis.sepa.gov.rs/nrizgis/kor/">
          http//www.nrizgis.sepa.gov.rs/nrizgis/kor/
        </a>
      </h4>
      <FormInput
        name="zauzete_katastarske_parcele"
        type="text"
        label={t('UNSANITARY_LANDFILL.OCCUPIED_PLOTS')}
        rules={[REQUIRED_FIELD_RULE(!props.readOnly)]}
        readOnly={props.readOnly}
        inputName={'zauzete_katastarske_parcele'}
        colon={true}
      />
      <FormRangePicker
        name="period_deponovanja"
        rules={[REQUIRED_FIELD_RULE(!props.readOnly)]}
        label={t('UNSANITARY_LANDFILL.LANDFILL_PERIOD')}
      />
      <FormTextArea
        disabled={unsanitaryLandfillStore.unsanitaryLandfill.napomena !== '' && authStore.isCompanyUser}
        labelCol={12}
        wrapperCol={12}
        name="napomena"
        rows={2}
        label={t('COMPANY.NOTE')}
      />

      {(unsanitaryLandfillStore.getUnsanitaryLandfill.is_active &&
        (unsanitaryLandfillStore.getUnsanitaryLandfill as IGetUnsanitaryLandfill).id) ||
      !(unsanitaryLandfillStore.getUnsanitaryLandfill as IGetUnsanitaryLandfill).id ? (
        <CRUDButton
          showButton={!!(unsanitaryLandfillStore.unsanitaryLandfill as IGetUnsanitaryLandfill).id}
          form={form}
          withOutDelete={true}
        />
      ) : undefined}
    </Form>
  );
});
