import { ColumnsType } from 'antd/es/table';
import TableData from 'components/TableData/TableData';
import { observer } from 'mobx-react-lite';
import { gio5ObrazacStore } from 'modules/obrasci/store/gio5/gio5.store';
import { IGio5UvozOtpada } from 'modules/obrasci/store/gio5/gio5.types';
import { useTranslation } from 'react-i18next';
import styles from './Gio5UvozOtpadaTable.module.scss';
import { obrasciStore } from 'modules/obrasci/obrasci.store';
import { EditButtonProps, RowEditPreviewButton } from 'components/RowEditPreviewButton/RowEditPreviewButton';

const EditGio5UvozOtpadaButton: React.FC<EditButtonProps<IGio5UvozOtpada>> = observer(({ record }) => {
  return (
    <RowEditPreviewButton
      name="gio5-uvoz-otpada"
      modalName="gio5-uvoz-otpada-modal"
      onClick={() => {
        gio5ObrazacStore.handleChange('uvozOtpada', record);
        gio5ObrazacStore.handleChange('zemljaIzvoza', record.zemlja_izvoza_id);
      }}
      preview={obrasciStore.zakljucanObrazac}
    />
  );
});

export const Gio5UvozOtpadaTable: React.FC = observer(() => {
  styles;
  const { t } = useTranslation();

  const baseColumns: ColumnsType<IGio5UvozOtpada> = [
    {
      title: `${t('OBRASCI.KOM_1.MANAGEMENT.WASTE_INDEX_NUMBER_FROM_THE_WASTE_CATALOG')}`,
      width: 100,
      dataIndex: ['indeksni_broj_otpada', 'index_number'],
      align: 'center',
      key: 'indeksni_broj_otpada',
      render: (_: any, record: IGio5UvozOtpada) => (
        <>{`${record.indeksni_broj_otpada.index_number} ${record.indeksni_broj_otpada.waste_sr.toUpperCase()}`}</>
      ),
    },
    {
      title: 'Kolicina uvezenog otpada',
      width: 100,
      dataIndex: 'kolicina_uvezenog_otpada',
      align: 'center',
      key: 'kolicina_uvezenog_otpada',
    },
    {
      title: 'Zemlja izvoza',
      width: 100,
      dataIndex: ['zemlja_izvoza', 'name'],
      align: 'center',
      key: 'zemlja_izvoza',
    },
    {
      key: 'operation',
      fixed: 'right',
      width: 50,
      align: 'center',
      render: (_, record: IGio5UvozOtpada) => <EditGio5UvozOtpadaButton record={record} />,
    },
  ];
  return (
    <TableData
      name="uvoz-otpada-gio5"
      columns={baseColumns}
      scrollConfig={['#obrazacTableHeader', '#obrazacWizardHeader']}
      dataSource={[...gio5ObrazacStore.initialState.uvoz_otpada]}
    />
  );
});
